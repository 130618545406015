export default Object.freeze({
    FEED: {
        permissionName: 'Feed',
        groupName: 'Discussion Board',
        category: 'General'
    },
    QOD: {
        permissionName: 'QOD',
        groupName: 'Discussion Board',
        category: 'General'
    },
    MY_DISCUSSION: {
        permissionName: 'My Discussion',
        groupName: 'Discussion Board',
        category: 'General'
    },
    MY_ANSWERS: {
        permissionName: 'My Answers',
        groupName: 'Discussion Board',
        category: 'General'
    },
    FLAGGED_DISCUSSION: {
        permissionName: 'Flagged Discussions',
        groupName: 'Discussion Board',
        category: 'General'
    },
    PINNED_DISCUSSION: {
        permissionName: 'Pinned Discussions',
        groupName: 'Discussion Board',
        category: 'General'
    },
    CREATE_DISCUSSION: {
        permissionName: 'Create Discussion',
        groupName: 'Discussion Board',
        category: 'Discussion'
    },
    SEARCH: {
        permissionName: 'Search',
        groupName: 'Discussion Board',
        category: 'Discussion'
    },
    SORT_AND_FILTER: {
        permissionName: 'Sort & Filter',
        groupName: 'Discussion Board',
        category: 'Discussion'
    },
    UNREPORT: {
        permissionName: 'Un Report',
        groupName: 'Discussion Board',
        category: 'Discussion'
    }
});