// import React from "react";
// import ReactDOM from "react-dom";
 import ShowcaseLayout from "./ShowcaseLayout";
import "./../../../node_modules/react-grid-layout/css/styles.css";
import "./../../../node_modules/react-resizable/css/styles.css"


 import './style.scss'
// class ExampleLayout extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { layout: [] };

//   }


//   render() {
//     return (
//       <div>
//         {/* <div className="layoutJSON">
//           Displayed as <code>[x, y, w, h]</code>:
//           <div className="columns">{this.stringifyLayout()}</div>
//         </div> */}
//         <ShowcaseLayout
//           onLayoutChange={this.onLayoutChange}
//         />
//       </div>
//     );
//   }
// }

// // const contentDiv = document.getElementById("root");
// // const gridProps = window.gridProps || {};
// // ReactDOM.render(React.createElement(ExampleLayout, gridProps), contentDiv);




// export default ExampleLayout;

import React from "react";
import GridLayout from "react-grid-layout";
import Layout from "./layout";
import ExampleLayout from "./lay";

export default class MyFirstGrid extends React.Component {



  render() {
    // layout is an array of objects, see the demo for more complete usage
    const layout = [
      { i: "a", x: 4, y: 3, w: 1, h: 2, },
      // { i: "b", x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4 },
      // { i: "c", x: 4, y: 0, w: 1, h: 2 }
    ];
    return (<>
      {/* <GridLayout
        className="layout"
        layout={layout}
        cols={12}
        rowHeight={30}
        onLayoutChange={this.onLayoutChange}
        width={1200}
      >
        <div key="a" style={{backgroundColor:'red'}}>a</div>

      </GridLayout>


      <br />
      <hr /> */}
      {/* <ShowcaseLayout
        
      /> */}
      <Layout />

      {/* <ExampleLayout /> */}
    </>);
  }
}


