import * as ActionTypes from './../actionTypes';



export default (state={}, action) =>{

    switch(action.type){
        case ActionTypes.REQUEST_ORGANIZATION:
                
            return {
                ...state,
                loading:true,
                error:false,
                errorInfo:null
            }
        
        case ActionTypes.RECEIVED_ORGANIZATION:

            return {
                ...state,
                loading:false,
                response:action.res,
                error:false,
                errorInfo:null,
                etag: action.etag
            }

        case ActionTypes.ERROR_ORGANIZATION:
            return{
                ...state,
                loading:false,
                error:true,
                errorInfo:action.err
            }
        case ActionTypes.SELECTED_CLASS_FROM_BOARD:
            return {
                ...state,
                selectedBoard: action.selectedBoard
            }
        case ActionTypes.SELECT_GROUP_FROM_CLASS:
            return {
                ...state,
                selectedClass: action.selectedClass
            }

        case ActionTypes.CLEAR_ORGANIZATION_DATA:
            return {}   
        default: return state;

    }
} 