import React, { Component } from 'react';
import { Button } from "primereact/button";
import GridLayout from "react-grid-layout";
import _ from "lodash";
import { Dialog } from "primereact/dialog";
import { warningDailogInit } from "../../utile";
import { Dropdown } from "primereact/dropdown";



let dropdownItems = [
    { label: 'usage report', value: 'usagereport', i: "a", x: 0, y: 0, w: 8, h: 8, minW: 8, maxW: 8, maxH: 8, minH: 8 },
    { label: 'trending chapter', value: 'trendingchapter', i: "b", x: 0, y: 8, w: 4, h: 8, minW: 4, maxW: 4, maxH: 8, minH: 8 },
    { label: 'discusson board', value: 'discussonboard', i: "c", x: 4, y: 16, w: 4, h: 8, minW: 4, maxW: 4, maxH: 8, minH: 8 },
    { label: 'ask a doubt', value: 'askadoubt', i: "d", x: 0, y: 16, w: 4, h: 8, minW: 4, maxW: 4, maxH: 8, minH: 8 },
    { label: 'Notifications', value: 'notifications', i: "e", x: 8, y: 0, w: 4, h: 24, minW: 4, maxW: 4, maxH: 24, minH: 24 },
    { label: 'User Stats', value: 'usersstats', i: "f", x: 8, y: Infinity, w: 4, h: 8, minW: 4, maxW: 4, maxH: 8, minH: 8 },
]

export default class Layout extends Component {
    constructor(props) {
        super(props);
        this.items = [...dropdownItems];
        this.items.length = 5
        this.state = {
            layouts: this.items,
            // [
            //     // { i: "a", x: 0, y: 0, w: 12, h: 4, },
            //     // { i: "b", x: 1, y: 0, w: 4, h: 8, minW: 4, maxW: 4, maxH: 8, minH: 8 },
            //     // { i: "c", x: 4, y: 0, w: 1, h: 2 }
            // ],
            warningDialog: warningDailogInit,
        }

    }











    addNewGrid = () => {



        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: '',
                message: ''
            },
        })



    }


    onConfirmChange = () => {
        
        let layouts = this.state.layouts;
        


        let gridSelected =null//  dropdownItems.find(a => a.value === this.state.compId)
        const uuidv4 = require('uuid/v4');

        if (!gridSelected) {
            gridSelected = {w: 100, h: 160, minW: 1000, maxW: 100, maxH: 100, minH: 100,i: uuidv4(), x: 10, y: Infinity,moved:false  }
        }

        let {
            label, value,
            ...rest
        } = gridSelected;
        layouts.push({ ...rest })

        console.log(layouts, 'oncofirm');
        
        this.setState({
            compId: null,
            warningDialog: warningDailogInit,
            layouts
        })

        // localStorage.setItem('layoutsData', JSON.stringify(layoutsData));
    }

    generateDOM = () => {
        let thisObj = this;
        return _.map(this.state.layouts, function (l, i) {
            return (<div key={l.i} className='widget-s1' >
                {l.i}
                {/* {thisObj.renderComp(thisObj.state.layoutsData[l.i], l)} */}
            </div>
            );
        });
    }


    onLayoutChange = (layout, allLayouts) => {
        
        this.setState((prevState) => {
            return {
                layouts: layout,
            };
        });
    }

    render() {
        const { layouts } = this.state;


        console.log(layouts, 'state')
        return (
            <div>
                <Button label="add new grid" onClick={this.addNewGrid} />
                <GridLayout
                    className="layout"
                    // isResizable={false}
                    layout={layouts}
                    cols={12}
                    onLayoutChange={this.onLayoutChange}
                    // autoSize={true}
                    // breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                    // cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                    rowHeight={30}
                    width={Math.round((window.screen.width * 90 / 100))}
                >

                    {this.generateDOM()}
                </GridLayout>


                <Dialog
                    header={<></>}
                    draggable={false}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    blockScroll={true}
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="Cancel" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, selectedGridId: null }) }} />
                            <Button label="Save" className='ma-m-lr10' onClick={this.onConfirmChange} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, selectedGridId: null })}>
                    <div>
                        <Dropdown value={this.state.compId}
                            className='ma-w200'
                            options={dropdownItems}
                            onChange={(e) => this.setState({
                                compId: e.value
                            })}
                            placeholder="Select Component" />


                    </div>
                </Dialog>


            </div >
        )
    }
}
