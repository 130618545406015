import * as ActionType from "../actionTypes";

export default (state = { examsList: [], examsResult: [], examsListByFilter: [] }, action) => {
    switch (action.type) {
        case ActionType.REQUEST_GET_EXAMINATION_DETAILS:
            return {
                ...state,
                loading: true,
                errorInfo: null,
                error: false

            }
        case ActionType.RECEIVED_GET_EXAMINATION_DETAILS:
            return {
                ...state,
                loading: false,
                errorInfo: null,
                error: false,
                response: action.res

            }
        case ActionType.ERROR_GET_EXAMINATION_DETAILS:
            return {
                ...state,
                loading: false,
                errorInfo: action.err,
                error: true
            }
        case ActionType.CLEAR_GET_EXAMINATION_DETAILS:
            return {
            }
        case ActionType.REQUEST_GET_EXAMS_ID_NAME_LIST:
            return {
                ...state,
                loading: true,
                errorInfo: null,
                error: false
            }
        case ActionType.RECEIVED_GET_EXAMS_ID_NAME_LIST:
            return {
                ...state,
                loading: false,
                errorInfo: null,
                error: false,
                examsList: action.res.Items ? action.res.Items : []
            }
        case ActionType.ERROR_GET_EXAMS_ID_NAME_LIST:
            return {
                ...state,
                loading: false,
                errorInfo: action.err,
                error: true
            }
        case ActionType.CLEAR_GET_EXAMS_ID_NAME_LIST:
            return {
                examsList: []
            }
        case ActionType.REQUEST_GET_EXAM_RESULT_REPORT:
            return {
                ...state,
                loading: true,
                errorInfo: null,
                error: false,
                examsResult: []
            }
        case ActionType.RECEIVED_GET_EXAM_RESULT_REPORT:
            return {
                ...state,
                loading: false,
                errorInfo: null,
                error: false,
                examsResult: action.res.examResult ? action.res.examResult : []
            }
        case ActionType.ERROR_GET_EXAM_RESULT_REPORT:
            return {
                ...state,
                loading: false,
                errorInfo: action.err,
                error: true,
                examsResult: []
            }
        case ActionType.CLEAR_GET_EXAM_RESULT_REPORT:
            return {
                examsResult: []
            }
        case ActionType.REQUEST_GET_EXAM_RESULT:
            return {
                ...state,
                loading: true,
                errorInfo: null,
                error: false,
                examResult: []
            }
        case ActionType.RECEIVED_GET_EXAM_RESULT:
            return {
                ...state,
                loading: false,
                errorInfo: null,
                error: false,
                examResult: action.res.examResult ? action.res.examResult : []
            }
        case ActionType.ERROR_GET_EXAM_RESULT:
            return {
                ...state,
                loading: false,
                errorInfo: action.err,
                error: true,
                examResult: []
            }
        case ActionType.CLEAR_GET_EXAM_RESULT:
            return {
                examResult: []
            }
        case ActionType.REQUEST_GET_EXAMINATION_BY_FILTER:
            return {
                ...state,
                loading: true,
                errorInfo: null,
                error: false

            }
        case ActionType.RECEIVED_GET_EXAMINATION_BY_FILTER:
            return {
                ...state,
                loading: false,
                errorInfo: null,
                error: false,
                examsListByFilter: action.res.Items

            }
        case ActionType.ERROR_GET_EXAMINATION_BY_FILTER:
            return {
                ...state,
                loading: false,
                errorInfo: action.err,
                error: true
            }
        case ActionType.CLEAR_GET_EXAMINATION_BY_FILTER:
            return {
                examsListByFilter: [],
                loading: false
            }


        default:
            return state
    }
}