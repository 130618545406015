import moment from 'moment';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react'
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import LoadingComponent from '../loadingComponent';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { connect } from 'react-redux';
import { I18Next } from './../../internationalization';


class EnquirySearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '',
      studentName: '',
      searchBy: 'phonenumber',
      searchData: [],
      showSearch: false,
      columns: [
        { columnName: 'Total Time on VIDEOS', isVisible: true, id: 'mp4' },
      ],

    }

    this.service = new Service();
  }


  componentDidMount() {
    this.getIncompleteForms()
  }


  getIncompleteForms = () => {
    this.setState({
      isLoading : true
    })    
    const url = `${baseUrlAdmin}/admissions/all`;
    this.service.get(url,true).then(res => {
      if (res && res.status && res.res.status) {
        if (res.res.data && res.res.data.admissionRecords.length) {
          
          this.setState({
            isLoading: false,
            searchData: res.res.data.admissionRecords,
          });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      } else {
        this.setState({
          isLoading: false
        });
        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
      }
    }).catch(e => {
      this.setState({
        isLoading: false
      });
      console.log(e);
    })
  }



  onColumnClick = (column) => {
    let columns = this.state.columns.map(c => {
      if (column.id == c.id) {
        c.isVisible = !c.isVisible;
      }
      return c;
    });
    this.setState({
      columns
    });
  }
  isColumnVisible = (id) => {
    return this.state.columns.filter(c => c.id == id)[0].isVisible;
  }
  exportCSV = () => {
    this.dt.exportCSV();
  }




  // onSearch = () => {


    
  //   if (this.state.searchBy == 'phonenumber') {
  //     if (this.state.phoneNumber && this.state.phoneNumber.length != 10) {
  //       this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Invalid phone number', life: 3000 });
  //       return true;
  //     }
  //   } else {
  //     if (this.state.studentName && this.state.studentName?.length < 2) {
  //       this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Min 2 chars required for name search.', life: 3000 });

  //       return true;
  //     }

  //   }

  //   this.setState({
  //     isLoading: true,
  //     searchData: []
  //   });
    
  //   const url = `${baseUrlAdmin}/admissions/search`;
  //   this.service.post(url, {
  //     searchBy: this.state.searchBy,
  //     searchValue: this.state.searchBy == 'phonenumber' ? this.state.phoneNumber : this.state.studentName,
  //   }, true).then(res => {
      
  //     if (res && res.status && res.res.status) {
  //       if (res.res.data && res.res.data.length) {
  //         this.setState({
  //           isLoading: false,
  //           searchData: res.res.data,
  //         });
  //       } else {
  //         this.setState({
  //           isLoading: false,
  //         }, this.props.showCreate);
  //       }
  //     } else {
  //       this.setState({
  //         isLoading: false
  //       });
  //       this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
  //     }
  //   }).catch(e => {
  //     this.setState({
  //       isLoading: false
  //     });
  //     console.log(e);
  //   })
  // }


  onNumberChange = (value) => {
    if (isNaN(value) || parseInt(value) < 0) {
      this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Invalid phone number', life: 3000 });
    } else {

      this.setState({
        phoneNumber: value
      })
    }
  }


  render() {
    const { defaultLanguage } = this.props
    const langType = defaultLanguage
    const { searchBy, phoneNumber, studentName } = this.state;
    const header = (
      <div className="table-header ma-tbl-header">
        <div className="ma-tbl-left">
          <h2 className='ma-table-title'>{I18Next('allRecords', langType).COMMON_KEY}</h2>
          {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={(e) => this.setState({ globalFilter: e.target.value })} />}

        </div>

        <div className="p-input-icon-left ma-tbl-filter">
          <ul className="ma-tbl-li">
            <Authorizer permId={PERMISSIONS.BOARDS_SEARCH} >
              <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
              <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
            </Authorizer>
            {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                  <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
            <Authorizer permId={PERMISSIONS.BOARDS_VIEWCOLUMNS} >
              <li><i data-pr-tooltip="View Columns"
                onClick={(event) => this.menu.toggle(event)}
                data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
              <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
            </Authorizer>
            <Authorizer permId={PERMISSIONS.BOARDS_DOWNLOADCSV} >
              <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
              <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
            </Authorizer>
          </ul>
        </div>
        <Menu
          className='table-filter-w'
          model={[
            {
              template: (<div className='table-filter'>
                <h4 className="filter-title">View Columns</h4>
                <ul>
                  {this.state.columns.map((column) => {
                    return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                      checked={column.isVisible}
                    ></Checkbox>
                      <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                    </div>
                  })}
                </ul>
              </div>)
            }]
          }
          popup
          ref={el => this.menu = el} />
      </div>
    );

    return (
      <div>
        {/* <div>
          <p className="p-checkbox-label">Search By</p>

          <RadioButton className="radio-inline" value='phonenumber' inputId="phonenumber" name="userType" onChange={(e) => {
            this.setState({
              searchBy: 'phonenumber',
              studentName: ''
            })
          }} checked={searchBy === 'phonenumber'} />
          <label className='ma-label-s1' htmlFor="phonenumber">Phone Number</label>
          <RadioButton className="radio-inline ma-ml10" value='studentname' inputId="studentname" name="userType"
            onChange={(e) => {
              this.setState({
                searchBy: 'studentname',
                phoneNumber: ''
              })
            }}
            checked={searchBy === 'studentname'} />
          <label className='ma-label-s1' htmlFor="studentname">Student Name</label>
        </div> */}
        <div>
          {/* {
            searchBy == 'phonenumber' ? <>
              <InputText value={phoneNumber} onChange={(e) => { this.onNumberChange(e.target.value) }} className='p-inputtext-style1 ma-mt10' />
            </> : <>
              <InputText value={studentName} onChange={(e) => {
                this.setState({
                  studentName: e.target.value
                })
              }} className='p-inputtext-style1 ma-mt10' />
            </>
          } 
          <Button label="Search" className='ma-ml10' onClick={this.onSearch} />*/}
          <Button label={I18Next('newEnquiryForm', langType).COMMON_KEY} className='ma-ml10' onClick={this.props.showCreate} />



          <div className="card datatable-crud-demo ma-m30">
            <DataTable ref={(el) => this.dt = el}
              value={this.state.searchData}
              dataKey="_id"
              paginator
              rows={10}
              responsiveLayout="scroll"
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Enquiry Forms"
              globalFilter={this.state.globalFilter}
              header={header}>
              <Column field="studentFirstName" header={I18Next('firstName', langType).COMMON_KEY}/>
              <Column field="studentMiddleName" header={I18Next('middleName', langType).COMMON_KEY} />
              <Column field="studentLastName" header= {I18Next('lastName', langType).COMMON_KEY}/>
              <Column field="" header={I18Next('dob', langType).COMMON_KEY} body={(rowData) => {
                return (
                  <>
                    <p>{moment(rowData.dateOfBirth).format("DD-MM-YYYY")}</p>
                  </>
                )
              }} />
              <Column field="fatherName" header={I18Next('fatherName', langType).COMMON_KEY} />
              <Column field="aadharNumber" header={I18Next('aadharNo', langType).COMMON_KEY} />
              <Column field="fatherMobileNo" header={I18Next('fatherNo', langType).COMMON_KEY} />
              <Column field="motherMobileNo" header={I18Next('motherNo', langType).COMMON_KEY} />
              <Column field="stage" header={I18Next('currentStage', langType).COMMON_KEY} />
              <Column headerClassName='option' header={I18Next('actions', langType).COMMON_KEY} body={(rowData, { rowIndex }) => {
                return (<>
                  {
                    <Button label={I18Next('continue', langType).COMMON_KEY} className='' onClick={() => this.props.onContinueClick(rowData)} />
                  }
                </>
                );
              }} ></Column>
            </DataTable>


          </div>
        </div>

        {(this.state.isLoading) && <LoadingComponent />}

        <Toast ref={(el) => this.toast = el} position="bottom-right" />
      </div>
    )
  }
}

const mapStatesToProps = (state) => ({
  defaultLanguage: state.languageReducer.language,

});

export default connect(mapStatesToProps, {})(EnquirySearch);
