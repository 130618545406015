import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
const reportReasonConstants = [
    { key: 'abuse', value: 'Abusive' },
    { key: 'inappropriate', value: 'Inappropriate' },
    { key: 'noclearinputs', value: 'No clear inputs' },
    { key: 'notinteresting', value: 'Not interesting' },
    { key: 'outofcurriculum', value: 'Out of curriculum' },
    { key: 'other', value: 'Other' },
]

class ReportDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            otherReason: '',
            otherReasonErr: '',
            errorMsg: ''
        }
    }


    handleChange = (event) => {
        this.setState({
            reason: event.target.value
        });

    }


    submit = () => {


        let reason = this.state.reason;


        if (reason == 'other') {
            if (this.state.otherReason.length < 10) {
                this.setState({
                    otherReasonErr: 'Please enter atleast 10 characters'
                });
                return;
            } else {
                reason = this.state.otherReason;
            }
        }


        if (reason == '') {

            this.setState({
                errorMsg: 'Select reason'
            });


        } else {
            this.props.reportThis(reason);
        }



    }



    render() {
        const { item } = this.state;
        return (
            <>
                <Dialog
                    visible={this.props.open}
                    onHide={this.props.onClose}
                    closable={false}
                    showHeader={false}
                    blockScroll={true}
                    footer={() => {
                        return (<>
                            <Button label="Cancel" className='p-button-outlined'
                                onClick={() => { this.props.onClose() }}
                            />
                            <Button label="Submit" className='ma-m-lr10'
                                disabled={this.state.reason == ''}
                                onClick={this.submit}
                            />




                        </>)
                    }}
                >
                    <div className="report-dialog">
                        <p className="report-title">Please select the reason for reporting</p>
                        {reportReasonConstants.map((c) => {
                            return <div className="p-field-radiobutton p-col-6">
                                <RadioButton inputId={c.key} name="sort" value={c.key} onChange={this.handleChange} checked={this.state.reason === c.key} />
                                <label htmlFor={c.key}>{c.value}</label>
                            </div>
                        })}
                        <div>
                            {this.state.reason == 'other' && <><textarea placeholder="If other reason, please type here" className="report-textarea" rows="3" onChange={(e) => { this.setState({ otherReason: e.target.value }) }} cols="50" />

                            </>}
                        </div>
                        <div>
                            <span className="err-msg ma-ml30">{this.state.otherReasonErr}</span>
                            <span className="err-msg ma-ml30">{this.state.errorMsg}</span>
                        </div>
                    </div>
                </Dialog>

            </>
        )
    }
}

export default ReportDialog;