import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import _ from 'lodash';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import Authentication from './../../session';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import {
   
    getBoardsData,
    getBranchesLatest
} from './../../../store/actions';
import Authorizer, { PERMISSIONS } from './../../session/authorizer';
import { userAssignedBoards } from './../../../store/selectors/userAssignedBoards';
import { getServerTime } from './../../../utile';

const getInitialState = (data) => {
    let initialState = {
        boardId: null,
        classId: null,
        groupId: null,
        sectionIds: [],
        boards: [],
        classes: [],
        isDateSearch: false,
        isUserNameSearch: false,
        username: '',
        ...data,
        branchLevels: {}
    }
    if (typeof initialState.sectionIds == 'string') {
        initialState.sectionIds = initialState.sectionIds.split(',')
    }


    return initialState;
}

class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = getInitialState(this.props.filterData);
    }



    formState = () => {
        if (this.props.filterData) {
            const { boardId, classId, groupId } = this.props.filterData;
            const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === this.props.filterData.boardId);
            if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                let _classes = [];
                selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));

                this.setState((prevState) => {
                    return {
                        classes: _classes,
                        boardId, classId, groupId
                    };
                });
            }

        }


    }


    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        } else {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });
                this.setState({
                    boards: boards
                });
            }
        }

        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }
        this.formState();





    }

    componentDidUpdate(prevProps) {
        if (prevProps.boards !== this.props.boards) {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });
                this.setState({
                    boards: boards
                });
            }
        }

        if (prevProps.branchData !== this.props.branchData) {
            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }


    }

    onChangeBoard = (boardId) => {
        this.setState({ classes: [], groups: [], classId: null, groupId: null });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
        });

    };

    onChangeClass = (classId) => {
        this.setState({ groups: [], groupId: null });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);

        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            let selectedGroup = _groups[0];

            this.setState({
                groups: _groups,
                classId,
                groupId: selectedGroup.value
            });
        }
    };


    onChangeSection = (key, level) => {
        let { sectionsData } = this.state;
        let data = this.getSelectedLevelData(this.props.branchData, key)
        let emptyBeforeLevels = {};
        for (let i = (level + 1); i <= this.props.branchLevels.length; i++) {
            emptyBeforeLevels = {
                ...emptyBeforeLevels,
                ['level' + i]: [],
                ['levelSelected' + i]: null
            }
        }
        let newSectionData = {
            ...sectionsData,
            ...emptyBeforeLevels,
            ['level' + (level + 1)]: data.items,
            ['levelSelected' + (level)]: key
        }

        this.setState({
            sectionsData: newSectionData,
            sectionIds: []
        });
    };

    onMultiChange = (value) => {
        this.setState((prevState) => {
            return {
                sectionIds: value
            };
        });

    }

    clearSearch = () => {


    }


    getSelectedLevelData(data, key) {
        let items = [];
        let selectedNode = data.find(a => a.key == key);
        if (selectedNode && selectedNode.children) {
            selectedNode.children.forEach((c) => {
                items.push(data.find(a => a.key == c))
            })

        }
        return { items, selectedNode };

    }

    getSectionsData = (nodes, key, totalLevels) => {
        let data = _.cloneDeep(nodes)
        let sectionsData = {};
        let selectedKey = key;
        for (let k = totalLevels; k > 1; k--) {
            for (let j = 0; j < data.length; j++) {
                const i = data[j];
                if (i.children) {
                    if (i.children.includes(selectedKey)) {
                        sectionsData['level' + k] = i.children;
                        sectionsData = {
                            ...sectionsData,
                            ['level' + k]: i.children.map(c => data.find(d => d.key == c)),
                            ['levelSelected' + k]: selectedKey
                        }
                        selectedKey = _.cloneDeep(i.key)
                        break;
                    }
                }
            }
        }
        sectionsData = {
            ...sectionsData,
            ['level1']: data.filter(nod => nod.level == 1),
            ['levelSelected1']: selectedKey
        }
        return sectionsData
    }




    onSearchClick = () => {
        const { isDateSearch, isUserNameSearch, fromDate, toDate, username, boardId, classId, groupId, branchId, courseId, sectionIds, startDate, endDate } = this.state;
        if (isUserNameSearch) {
            if (username == '') {
                this.toast.show({ severity: 'error', summary: 'Plese enter username.', detail: 'The username should not be empty. please enter the one registered ', life: 3000 });
                return;
            }
            if (username.length < 5) {
                this.toast.show({ severity: 'error', summary: 'Invalid username.', detail: 'Please enter the valid user name', life: 3000 });
                return;
            }
        } else {
            if (!boardId) {
                this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Board , Class.', life: 3000 });
                return;
            } else if (boardId && (!classId || !groupId)) {
                this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                return;
            }
        }

        this.props.filterUsersData({
            boardId,
            classId,
            groupId,
            sectionId: sectionIds.length > 0 ? sectionIds : null,
            username,
            isUserNameSearch,
            startDate,
            endDate,
            isDateSearch,
            fromDate: new Date(fromDate).getTime(),
            toDate: new Date(toDate).getTime(),

        });

    };


    handleClearSearch = () => {


        let sectionsData = {};
        if (this.props.branchData && this.props.branchData.length) {
            sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
        }

        this.setState(
            {
                boardId: null,
                classId: null,
                groupId: null,
                branchId: null,
                courseId: [],
                sectionIds: [],
                username: '',
                startDate: getServerTime().setDate(getServerTime().getDate() - 5),
                endDate: getServerTime(),
                classes: [],
                sectionsData

            }
        );
    };

    handleUserNameSearchChange = (event) => {

        this.setState({
            isUserNameSearch: event.target.checked,
            isDateSearch: false
        }, this.handleClearSearch());
    };

    // handleDateSearchChange = (event) => {
    //     this.setState({
    //         isDateSearch: event.target.checked,
    //         isUserNameSearch: false
    //     }, this.handleClearSearch());
    // };


    render() {
        const { isUserNameSearch, isDateSearch, username } = this.state;

        //  console.log(this.state)
        return (
            <>
                <div className="ma-mt20">
                    <div>
                        <Authorizer permId={PERMISSIONS.USER_SEARCH_BY_USERNAME} >
                            <Checkbox inputId="usearch" value="true"
                                onChange={this.handleUserNameSearchChange}
                                checked={isUserNameSearch}
                            ></Checkbox>
                            <label htmlFor="usearch" className="p-checkbox-label">Search User By Username</label>
                        </Authorizer>
                        {/* <Checkbox inputId="usearch" value="true"
                            className='ma-ml30'
                            onChange={this.handleDateSearchChange}
                            checked={isUserNameSearch}
                        ></Checkbox>
                        <label htmlFor="usearch" className="p-checkbox-label">Search User By Username</label> */}
                    </div> <Authorizer permId={PERMISSIONS.USER_SEARCH} ><>
                        <div className='ma-mt20'>
                            {
                                !isUserNameSearch && !isDateSearch && <>
                                    <Dropdown value={this.state.boardId}
                                        className='ma-w200'
                                        options={this.state.boards}
                                        onChange={(e) => this.onChangeBoard(e.value)}
                                        placeholder="--Select Board--" />
                                    <span className='ma-required-mark '>*</span>
                                    <Dropdown value={this.state.classId}
                                        options={this.state.classes}
                                        className='ma-w200'
                                        onChange={(e) => this.onChangeClass(e.value)} placeholder="--Select Class--" />
                                    <span className='ma-required-mark '>*</span>
                                    {
                                        this.props.branchLevels ? this.props.branchLevels.map((level, index) => {
                                            return (!(this.props.branchLevels.length == index + 1) ? <Dropdown
                                                className='ma-mr20 ma-mt10 ma-w200'
                                                value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                                optionLabel={"name"}
                                                optionValue={"key"}
                                                options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}
                                                onChange={(e) => this.onChangeSection(e.value, index + 1)}
                                                placeholder={`--Select ${level.levelName}--`} /> : <MultiSelect
                                                className='ma-mr20 ma-mt10 ma-w200'
                                                value={this.state.sectionIds ? this.state.sectionIds : []}
                                                onChange={(e) => this.onMultiChange(e.value)}
                                                options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}
                                                optionLabel={"name"}
                                                optionValue={"key"}
                                                placeholder={`--Select ${level.levelName}--`}
                                            />
                                            )
                                        }) : null
                                    }

                                </>
                            }
                            {
                                isUserNameSearch && <>
                                    <InputText value={username} onChange={(e) => {
                                        this.setState({
                                            username: e.target.value
                                        })
                                    }} className='p-inputtext-style1 ' />
                                </>
                            }
                        </div>
                        <div className='ma-mt10'>
                            <Button label="Search" className='' onClick={this.onSearchClick} />
                            <Button label="Clear" className='ma-m-lr10 p-button-outlined' onClick={this.handleClearSearch} />
                        </div>
                    </>
                    </Authorizer>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    isLoading: state.boardsData.isLoading,
    isUpdated: state.boardsData.isUpdated,
    boardsData: state.boardsData,
    updateError: state.boardsData.error,
    updateErrorMessage: state.boardsData.errorMessage,
    branches: state.branchDataLatest.branches,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
});


export default connect(mapStateToProps, {
    // getCurriculum,
    // getActiveOrganizationData,
    // selectBoard,
    // dispatchClearCurriculumData,
    // selectClass,
    // selectGroup,
    // updateSubject,
    // getSubject,
    // clearSelectedOrganizationData,
    // startExportToOffline,
    // getImageUrl,
    getBoardsData,
    getBranchesLatest
})(Authentication(withRouter(SearchBar)));