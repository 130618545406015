import * as ActionTypes from './../actionTypes';

const initialState = {
  isLoading: false,
  isCreateRoleRequested: false,
  isCreateRoleSuccessful: false,
  isCreateRoleFailed: false,
  isUpdateRoleSuccessful: false,
  isUpdateRoleFailed: false,
  loading: false,
  data: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ROLES_REQUESTED:
      return {
        ...state,
        isLoading: true
      };

    case ActionTypes.GET_ROLES_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
          data: action.data
      };

    case ActionTypes.GET_ROLES_FAILED:
      return {
        ...state,
        isLoading: false
      };
    case ActionTypes.ADD_ROLES_REQUESTED:
      return {
        ...state,
        isCreateRoleRequested: true,
          isCreateRoleSuccessful: false
      };
    case ActionTypes.ADD_ROLES_SUCCEEDED:
      return {
        ...state,
        isCreateRoleSuccessful: true,
          isCreateRoleRequested: false
      };
    case ActionTypes.ADD_ROLES_FAILED:
      return {
        ...state,
        isCreateRoleRequested: false,
          isCreateRoleFailed: true

      };
    case ActionTypes.UPDATE_ADD_ROLES:
      return {
        ...state,
        isCreateRoleFailed: false

      };
    case ActionTypes.UPDATE_ROLES_REQUESTED:
      return {
        ...state,
        loading: true,
          errorInfo: null,
          error: false,
          isUpdateRoleFailed: false,
          isUpdateRoleSuccessful: false,
      };
    case ActionTypes.UPDATE_ROLES_SUCCEEDED:
      return {
        ...state,
        loading: false,
          errorInfo: null,
          error: false,
          isUpdateRoleSuccessful: true,
          isUpdateRoleFailed: false,
      };
    case ActionTypes.UPDATE_ROLES_FAILED:
      return {
        ...state,
        loading: false,
          errorInfo: null,
          error: true,
          isUpdateRoleFailed: true,
          isUpdateRoleSuccessful: false,
      };
    default:
      return state;
  }
};