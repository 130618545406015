import * as ActionType from "../actionTypes";
import axios from 'axios';
import { baseUrlAdmin, baseUrlForLongRunningTask } from "../apiConstants";

const dispatchRequestCreateNewUser = (data) => ({
    type: ActionType.REQUEST_CREATE_NEW_USER,
    data,
});

const dispatchReceivedCreateNewUser = (res) => ({
    type: ActionType.RECEIVED_CREATE_NEW_USER,
    res
});

const dispatchErrorCreateNewUser = (err) => ({
    type: ActionType.ERROR_CREATE_NEW_USER,
    err

})

const dispatchClearCreateNewUser = () => ({
    type: ActionType.CLEAR_CREATE_NEW_USER,

});
const dispatchErrorMessage = (errorMessage) => ({
    type: ActionType.THROW_ERROR_MESSAGE,
    errorMessage
});

const dispatchRequestSaveBulkUsers = () => ({
    type: ActionType.REQUEST_SAVE_BULK_USERS
});

const dispatchReceivedSaveBulkUsers = (res) => ({
    type: ActionType.RECEIVED_SAVE_BULK_USERS,
    res
});

const dispatchErrorSaveBulkUsers = err => ({
    type: ActionType.ERROR_SAVE_BULK_USERS,
    err
});

const dispatchRequestDeleteUser = () => ({
    type: ActionType.REQUEST_DELETE_USER
});

const dispatchReceivedDeleteUser = (res) => ({
    type: ActionType.RECEIVED_DELETE_USER,
    res
});

const dispatchErrorDeleteUser = err => ({
    type: ActionType.ERROR_DELETE_USER,
    err
});

const dispatchRequestBulkUploadTransactions = () => ({
    type: ActionType.REQUEST_BULK_UPLOAD_TRANSACTIONS
});

const dispatchReceivedBulkUploadTransactions = (res) => ({
    type: ActionType.RECEIVED_BULK_UPLOAD_TRANSACTIONS,
    res
});

const dispatchErrorBulkUploadTransactions = err => ({
    type: ActionType.ERROR_BULK_UPLOAD_TRANSACTIONS,
    err
});

const dispatchRequestBulkUploadTransactionDetails = () => ({
    type: ActionType.REQUEST_BULK_UPLOAD_TRANSACTION_DETAILS
});

const dispatchReceivedBulkUploadTransactionDetails = (res) => ({
    type: ActionType.RECEIVED_BULK_UPLOAD_TRANSACTION_DETAILS,
    res
});

const dispatchErrorBulkUploadTransactionDetails = err => ({
    type: ActionType.ERROR_BULK_UPLOAD_TRANSACTION_DETAILS,
    err
});

const dispatchClearBulkUserUploadData = () => ({
    type: ActionType.CLEAR_BULK_USERS_TRANSACTION_DATA,

});



export const createNewUserFromAdmin = (info) => (dispatch, getState) => {
    dispatch(dispatchErrorMessage(null));
    let data;
    const store = getState();
    const { selectedOrganizationDataIds = {} } = store || {}
    const { boardId, boardName, classId, className, groupId, groupName } = selectedOrganizationDataIds || {};
    const { mobileNo, userName, branchId, courseId, sectionId, branchName, courseName, sectionName } = info;

    data = {
        "mobile": `+91${mobileNo}`,
        "given_name": userName,
        "grade": className,
        "group": groupName,
        "board": boardName,
        "gradeId": classId,
        "groupId": groupId,
        "boardId": boardId,
        branchId,
        courseId,
        sectionId,
        branchName,
        courseName,
        sectionName
    }
    dispatch(dispatchRequestCreateNewUser(data));
    axios({
        method: 'post',
        url: `${baseUrlAdmin}/user/addUser`,
        data: data

    }).then((res) => {

        dispatch(dispatchReceivedCreateNewUser(res.data));

    }).catch(e => {
        if (e.response && e.response.status == 401) {
            localStorage.clear();
            window.location.reload();
        } else {
            dispatch(dispatchErrorCreateNewUser(e));
        }
    });
}

export const updateUserFromAdmin = (userData, info) => (dispatch, getState) => {
    dispatch(dispatchErrorMessage(null));
    let data;

    const store = getState();
    const { selectedOrganizationDataIds = {} } = store || {}
    const { boardId, boardName, classId, className, groupId, groupName } = selectedOrganizationDataIds || {};
    const { mobileNo, userName, branchId, courseId, sectionId, branchName, courseName, sectionName } = info;

    data = {
        ...userData,
        "user_info": {
            ...userData.user_info,
            "mobile": `+91${mobileNo}`,
            "given_name": userName,
            "grade": className,
            "custom:grade": className,
            "group": groupName,
            "custom:group": groupName,
            "board": boardName,
            "custom:board": boardName,
            "classId": classId,
            "custom:gradeId": classId,
            "groupId": groupId,
            "custom:groupId": groupId,
            "boardId": boardId,
            "custom:boardId": boardId,
            branchId,
            courseId,
            sectionId,
            branchName,
            courseName,
            sectionName
        },
    }
    dispatch(dispatchRequestCreateNewUser(data));
    axios({
        method: 'PUT',
        url: `${baseUrlAdmin}/user/update/${data.user_id}/details`,
        data: data

    }).then((res) => {
        dispatch(dispatchReceivedCreateNewUser(res.data));
    }).catch(e => {
        if (e.response && e.response.status == 401) {
            localStorage.clear();
            window.location.reload();
        } else {
            dispatch(dispatchErrorCreateNewUser(e));
        }
    });
}

export const clearCreateNewUser = () => dispatch => {
    dispatch(dispatchClearCreateNewUser());
}

export const saveBulkUploadUsers = (data) => dispatch => {
    dispatch(dispatchRequestSaveBulkUsers());
    axios({
        method: 'POST',
        url: `${baseUrlForLongRunningTask}/user/bulk/usersupload`,
        data: data,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        }
    }).then(({ data }) => {
        dispatch(dispatchReceivedSaveBulkUsers(data));
    }).catch(e => {
        if (e.response && e.response.status == 401) {
            localStorage.clear();
            window.location.reload();
        } else {
            dispatch(dispatchErrorSaveBulkUsers(e));
        }
    });
}

export const getBulkUploadTransactions = (data, filter) => dispatch => {
    dispatch(dispatchRequestBulkUploadTransactions());
    const userId = data.userId ? data.userId : null;
    const url = `${baseUrlAdmin}/user/allbulk/userdata/${userId}?limit=${filter.rows}&page=${filter.page + 1}&search=${filter.globalSearch}`;

    axios({
        method: 'get',
        url: url,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        }
    }).then(({ data }) => {
        dispatch(dispatchReceivedBulkUploadTransactions(data));
    }).catch(e => {
        if (e.response && e.response.status == 401) {
            localStorage.clear();
            window.location.reload();
        } else {
            dispatch(dispatchErrorBulkUploadTransactions(e));
        }
    });
}

export const getBulkUploadTransactionDetails = (data) => dispatch => {
    dispatch(dispatchRequestBulkUploadTransactionDetails());
    const { userId, transactionId } = data;
    axios({
        method: 'get',
        url: `${baseUrlAdmin}/user/${userId}/view/transcations/${transactionId}`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        }
    }).then(({ data }) => {
        dispatch(dispatchReceivedBulkUploadTransactionDetails(data));
    }).catch(e => {
        if (e.response && e.response.status == 401) {
            localStorage.clear();
            window.location.reload();
        } else {
            dispatch(dispatchErrorBulkUploadTransactionDetails(e));
        }
    });
}

export const clearBulkUserUploadData = () => dispatch => {
    dispatch(dispatchClearBulkUserUploadData());
}

export const deleteUserFromAdmin = (userId) => dispatch => {
    dispatch(dispatchRequestDeleteUser());
    axios({
        method: 'DELETE',
        url: `${baseUrlAdmin}/user/delete/user/${userId}`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        }
    }).then(({ data }) => {
        dispatch(dispatchReceivedDeleteUser(data));
    }).catch(e => {
        if (e.response && e.response.status == 401) {
            localStorage.clear();
            window.location.reload();
        } else {
            dispatch(dispatchErrorDeleteUser(e));
        }
    });
}





////added by shashi

export const addNewGradeToUser = (userData, info) => (dispatch, getState) => {
    dispatch(dispatchErrorMessage(null));
    let data;
   
    // const { selectedOrganizationDataIds = {} } = store || {}
    //const { boardId, boardName, classId, className, groupId, groupName } = selectedOrganizationDataIds || {};

    data = {
        user_id: userData.user_id,
        isBaseAccount: false,
        "user_info": {
            "phone_number": userData.user_info.phone_number,
            "given_name": userData.user_info.given_name,
            "custom:board": userData.user_info["custom:board"],
            "custom:boardId": userData.user_info["custom:boardId"],
            "custom:grade": info.className,
            "custom:gradeId": info.classId,
            "custom:groupId": info.groupId,
            "custom:group": info.groupName,
            "classId": info.classId,
            "groupId": info.groupId,
            "boardId": userData.user_info.boardId,
            branchId: null,
            courseId: null,
            sectionId: null,
            "profilePicStorageKey": userData.user_info.profilePicStorageKey,
            "shippingAddress": userData.user_info.shippingAddress,
            isBaseAccount: false,
        },
    }
    dispatch(dispatchRequestCreateNewUser(data));
    axios({
        method: 'PUT',
        url: `${baseUrlAdmin}/user/addgradetouser`,
        data: data,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        }

    }).then((res) => {
        dispatch(dispatchReceivedCreateNewUser(res.data));
    }).catch(e => {
        if (e.response && e.response.status == 401) {
            localStorage.clear();
            window.location.reload();
        } else {
            dispatch(dispatchErrorCreateNewUser(e));
        }
    });
}

export const getUserGrades = (user_id) => (dispatch, getState) => {
    dispatch(dispatchErrorMessage(null));

    dispatch(dispatchRequestCreateNewUser({}));
    axios({
        method: 'GET',
        url: `${baseUrlAdmin}/user/grades/getUserGrades/${user_id}`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        }
    }).then((res) => {
        dispatch(dispatchReceivedCreateNewUser(res.data));
    }).catch(e => {

        if (e.response && e.response.status == 401) {
            localStorage.clear();
            window.location.reload();
        } else {
            dispatch(dispatchErrorCreateNewUser(e));
        }
    });
}