import * as ActionTypes  from '../actionTypes';


const dispatchSelectContentForAssign = data => ({
    type: ActionTypes.SELECT_CONTENT_FOR_ASSIGN,
    data
})

const dispatchAddContentToAnotherTopic = (content) => ({
    type: ActionTypes.ADD_CONTENT_TO_ANOTHER_TOPIC,
    content
})

const dispatchRemoveContentFromTopic = (content) => ({
    type: ActionTypes.REMOVE_CONTENT_FROM_ANOTHER_TOPIC,
    content
})


const dispatchChangeContentPriority = (content) => ({
    type: ActionTypes.CHANGE_CONTENT_PRIORITY,
    content
})

const dispatchClearSelctedContent = () => ({
    type: ActionTypes.CLEAR_SELECTED_CONTENT
})

const dispatchClearSelectContentForAssignData = () => ({
    type: ActionTypes.CLEAR_SELECT_CONTENT_FOR_ASSIGN_DATA
})


export const clearSelecteAssignData = () => dispatchClearSelctedContent();
export const clearAssignContentData = () => dispatchClearSelectContentForAssignData();


export const selectContetDataForAssign = (selectedContent) => dispatch => {
    dispatch(dispatchSelectContentForAssign(selectedContent));
}

export const addContentToTopic = contentIds => (dispatch, getState) => {
    const dataForAssign = getState().assignContent && getState().assignContent;
    const { selectedContentDataForAssign } = dataForAssign;

    const listOfSelectedContent = [];
    selectedContentDataForAssign && selectedContentDataForAssign.length && 
        selectedContentDataForAssign.map(content => {
            let isAssign;
            contentIds.map(id => {
                if(content.contentId === id) {
                    isAssign = true;
                }
            })
            if(isAssign) {
                listOfSelectedContent.push(content);
            }
        })

    dispatch(dispatchAddContentToAnotherTopic(listOfSelectedContent));
}

export const removeContentFromTopic = contentId => (dispatch, getState) => {
    dispatch(dispatchRemoveContentFromTopic(contentId));
}

export const changeContentPrioriry  = (contentId, priorityValue) => (dispatch, getState) => {
    const dataForAssign = getState().assignContent && getState().assignContent;
    const { selectedContent } = dataForAssign;
    const listOfSelectedContent = [];
    selectedContent && selectedContent.length && 
    selectedContent.map(content => {
        if(content.contentId === contentId) {
            let _content = {
                ...content,
                priority: priorityValue
            }
            listOfSelectedContent.push(_content);
        }else{
            listOfSelectedContent.push(content);
        }

    })

    dispatch(dispatchChangeContentPriority(listOfSelectedContent));
}



