import * as ActionTypes from './../actionTypes';

export default (state={}, action) =>{
    switch(action.type){
        case ActionTypes.SELECT_CONTENT_FOR_ASSIGN:                
            return {
                ...state,
                selectedContentDataForAssign: action.data
            }
        
        case ActionTypes.ADD_CONTENT_TO_ANOTHER_TOPIC:
            return {
                ...state,
                selectedContent: action.content              
            }

        case ActionTypes.REMOVE_CONTENT_FROM_ANOTHER_TOPIC:
            return{
                ...state,
                selectedContent: action.content 
            }
        case ActionTypes.CLEAR_SELECTED_CONTENT:
            return {
                ...state, 
                selectedContent: []
            }

        case ActionTypes.CHANGE_CONTENT_PRIORITY:
            return{
                ...state,
                selectedContent: action.content 
            }

        case ActionTypes.CLEAR_SELECT_CONTENT_FOR_ASSIGN_DATA:
        return {}
        default: return state;
    }
} 