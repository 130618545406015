import React from 'react';
import { connect } from 'react-redux';

import './styles.scss'
import DoubtsList from './doubtsList';
import Conversation from './conversation';
import { askADoubtSocketEndpoint, getTicketSoketEndPoint } from '../../store/apiConstants';
import SocketService from './socketService';
import Authentication from '../session';
import Navbar from '../navbar';
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import InputTextB from './../customComponents/inputTextB';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import FileUpload from '../fileUpload/index';
import _, { cloneDeep } from 'lodash'
import Service from '../discussionBoard/service';
import { baseUrlAdmin, ticketMangementEndpoint } from '../../store/apiConstants';

import TicketmangementTable from './TicketmangementTable'
const TEACHER_ID = localStorage.getItem('discussion_uId');

const items = [
  { label: "Academics" },
  { label: "Ticket Managment", className: "ma-active-item" },
  // { label: 'Role Creation', className: 'ma-active-item' }
];

const home = { icon: "pi pi-home" };

class AskADoubt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      selectedDoubt: null,
      currentSocketMessage: null,
      eventUID: 1,
      ticketModalState: false,
      ticketData: {
        url: '',
        description: '',
        uploadedFilesInfo: [],
        phoneNumber: "",
        emailId: localStorage.getItem('emailId'),
        phoneNumber: localStorage.getItem('phone_number')
      },
      errors: {
        // email: false,
        url: false,
        // phoneNumber: false
      },
      isCallRefresh: false
    };
    this.socketService = new SocketService(getTicketSoketEndPoint(), this.onConnectionOpen, this.onMessageRecieve);
    this.service = new Service()
    this.orgId = localStorage.getItem("orgId")
    this.boardId = localStorage.getItem("boardId")
    this.roleId = localStorage.getItem("currentUserRole")
    this.userId = localStorage.getItem("userId")

  }

  componentWillUnmount() {
    this.socketService.closeConnection();
  }

  onConnectionOpen = (data) => {
    this.connectToOnline();
  };

  connectToOnline = async () => {
    if (this.props.discussionUser) {
      let payload = {
        action: "online",
        data: {
          user: this.props.discussionUser ? this.props.discussionUser._id : null,
          isOnline: true
        },
      };
      await this.socketService.socketSend(payload)
    }
  }

  onMessageRecieve = (data) => {
    let response = JSON.parse(data.data);
    //  console.log('onMessageRecieve', response)
    let eventUID = this.state.eventUID + 1;
    this.setState({
      currentSocketMessage: { ...response, eventUID },
      eventUID
    });
  }

  onTabChange = (newValue) => {
    const cloneselectedDoubt = cloneDeep(this.state)
    cloneselectedDoubt.selectedDoubt = null
    cloneselectedDoubt.currentTab = newValue

    this.setState((prev) => ({
      ...prev, ...cloneselectedDoubt
    }));
  }

  onDoubtRespond = (tab) => {
    setTimeout(() => {
      this.setState({
        currentTab: tab
      });
    }, 2000);
  }

  // onDoubtClosed = () => {
  //     setTimeout(() => {
  //         this.setState({
  //             currentTab: 1
  //         });
  //     }, 2000);
  // }


  onDoubtSelect = (doubt) => {
    this.setState({
      selectedDoubt: doubt
    });
  }

  connectToOnline2 = async (user) => {
    let payload = {
      action: "online",
      data: {
        user,
        isOnline: true
      },
    };
    await this.socketService.socketSend()
  }

  componentWillReceiveProps(newProps) {
    if (this.props.discussionUser != newProps.discussionUser) {
      this.connectToOnline2(newProps.discussionUser._id)
    }
  }
  rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <h2 className='ma-tab-title'>Ticket Management</h2>
      </React.Fragment>
    )
  }

  leftToolbarTemplate() {
    return (
      <React.Fragment>
        <BreadCrumb model={items} home={home} />
      </React.Fragment>
    )
  }

  createNewTicket = () => {
    this.setState((prev) => ({ ...prev, ticketModalState: true }));
  }

  onTextChange = (e, field) => {
    // if (field === 'emailId') {
    //   this.setState((prev) => ({
    //     ...prev, errors: { email: !this.validateEmail(e.target.value) }
    //   }));
    // }
    if (field === 'url') {
      this.setState((prev) => ({
        ...prev, errors: { url: !this.validateLink(e.target.value) }
      }));
    }
    // if (field === 'phoneNumber') {
    //   this.setState((prev) => ({
    //     ...prev, errors: { phoneNumber: !this.validatePhoneNumber(e.target.value) }
    //   }));
    // }
    let data = this.state.ticketData
    data[field] = e.target.value;
    this.setState((prev) => ({
      ...prev,
      ticketData: data,
    }));
  }

  _onProgress = (filesInfo) => {
    let files = this.state.ticketData?.uploadedFilesInfo;
    files = files.map((file) => {
      if (file.name == filesInfo.name) {
        file = filesInfo;
      }
      return file;
    })

    this.setState((prev) => (
      {
        ...prev,
        ticketData: {
          ...prev.ticketData,
          uploadedFilesInfo: files
        }
      }))
  }

  _onSuccess = (fileName, data) => {
    let files = this.state.ticketData?.uploadedFilesInfo;
    files = files.map((file) => {
      if (file.name == fileName) {
        file.s3Info = data;
        file.uri = data.Location;
      }
      return file;
    })
    this.setState((prev) => (
      {
        ...prev,
        ticketData: {
          ...prev.ticketData,
          uploadedFilesInfo: files
        }
      }))
  }

  _onFilsUploadError = (errMsg) => {
    console.log("_onFilsUploadError", errMsg)
  }

  _afterFilesUploaded = (filess) => {
    console.log("filessfiless", filess)
    let files = _.cloneDeep(Array.from(filess));
    for (let i = 0; i < files.length; i++) {
      this.setState((prev) => (
        {
          ...prev,
          ticketData: {
            ...prev.ticketData,
            uploadedFilesInfo: files
          }
        }))
    }
  }

  checkValidation = () => {
    let hasError = false
    const cloneState = this.state.ticketData
    let errors = {}
    // if (!this.validateEmail(cloneState?.emailId?.trim())) {
    //   hasError = true
    //   errors["email"] = true
    // }
    // if (!this.validateLink(cloneState.url.trim())) {
    //   hasError = true
    //   errors["url"] = true
    // }
    // if (!this.validatePhoneNumber(cloneState?.phoneNumber?.trim())) {
    //   hasError = true
    //   errors["phoneNumber"] = true
    // }
    this.setState((prev) => ({
      ...prev, errors
    }));
    return hasError
  }

  submitTicket = () => {
    // callapu to add ticket
    if (!this.checkValidation()) {
      let url = `${ticketMangementEndpoint}/tickets/createTicket`
      const resBody = {
        askedBy: this.props.discussionUser._id,
        orgId: this.orgId,
        roleId: this.roleId,
        boardId: this.boardId,
        organizationName: this?.props?.orgName,
        "conversations": [
          {
            "for": "622b2f750a4785a4562ecc41",
            "type": "text",
            "content": "",
            "from": this.props.discussionUser._id,
            "to": "622b2f750a4785a4562ecc41",
            "direction": 1,
            "ticket": this?.state?.ticketData
          }
        ],
        "user": this.props.discussionUser._id,
        ticketStatus:"UNDER SCRUTINY"
      }
      console.log("resBodyresBody", resBody)
      this.service.post(url, resBody, true).then(res => {
        console.log("createNewTicketcreateNewTicket", res)

        if (res?.status && res?.res?.status) {
          this.setState({
            ticketModalState: false,
            ticketData: {


              url: '',
              description: '',
              uploadedFilesInfo: [],
              emailId: "",
              phoneNumber: ""
            },
            isCallRefresh: true
          })
        } else {
          this.setState({
            ticketModalState: false,
            ticketData: {


              url: '',
              description: '',
              uploadedFilesInfo: [],
              emailId: "",
              phoneNumber: ""
            }
          })
          this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
        }
      }).catch(err => {
        console.log(err, 'err')
        this.setState({
          isLoading: false
        })
        // this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
      })
    }

  }

  closeTicketModal = () => {
    this.setState((prev) => ({
      ...prev, ticketModalState: false, ticketData: {
        url: '',
        description: '',
        uploadedFilesInfo: [],
        emailId: localStorage.getItem('emailId'),
        phoneNumber: localStorage.getItem('phone_number')
      }
    }));
  }

  validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  validateLink = (link) => {
    const linkRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return linkRegex.test(link);
  };

  validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  disabledOnrefresh = () => {
    this.setState({
      isCallRefresh: false
    })
  }

  onCallRefreshTicketList =(value)=>{
    this.setState({
      isCallRefresh: value
    })
  }
  render() {
    const { ticketData, errors, isCallRefresh,selectedDoubt } = this.state
    console.log("selectedDoubtselectedDoubt",selectedDoubt)
    return (
      <>
        <Navbar >
          <div className="p-grid ma-toolbar">
            <div className="p-col-12 p-md-1 ma-no-p">
            </div>
            <div className="p-col-12 p-md-11 ma-no-pm">
              <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
            </div>
          </div>
          <div className="ask-a-doubt ma-main">
            <div className="p-grid ">
              <div className="p-col-12 p-md-6 p-lg-4 p-xl-4">
                <div className="p-grid doubts-tabs">
                  <div className={`p-col-4 p-xs-4 doubts-tab-w ${this.state.currentTab == 0 ? 'tab-active' : ''}`}>
                    <p className={`ma-pointer tab-p  ${this.state.currentTab == 0 ? 'tab-active-p' : ''}`} onClick={() => { this.onTabChange(0) }}>New</p>
                  </div>
                  <div className={`p-col-4 p-xs-4 doubts-tab-w ${this.state.currentTab == 1 ? 'tab-active' : ''}`}>
                    <p className={`ma-pointer tab-p ${this.state.currentTab == 1 ? 'tab-active-p' : ''}`} onClick={() => { this.onTabChange(1) }}>Pending</p>
                  </div>
                  <div className={`p-col-4 p-xs-4 doubts-tab-w ${this.state.currentTab == 2 ? 'tab-active' : ''}`}>
                    <p className={`ma-pointer tab-p ${this.state.currentTab == 2 ? 'tab-active-p' : ''}`} onClick={() => { this.onTabChange(2) }}>Completed</p>
                  </div>
                </div>
              </div>
              {this?.props?.userInfo?.isSuperAdmin ? null : <div >
                <Button label={`New Ticket`} className='rol-add-btn' onClick={this.createNewTicket} />
              </div>}
            </div>
            <div className="p-grid ">
              <div className={`p-col-4 p-xs-4 ma-no-pm`}>
                <DoubtsList
                  currentSocketMessage={this.state.currentSocketMessage}
                  socketSend={this.socketService.socketSend}
                  currentTab={this.state.currentTab}
                  selectedDoubt={this.state.selectedDoubt}
                  onDoubtSelect={this.onDoubtSelect}
                  callRefresh={this.state.isCallRefresh}
                  disabledOnrefresh={this.disabledOnrefresh}
                />
              </div>
              <div className={`p-col-8 p-xs-8 ma-no-pm`}>
                <Conversation currentSocketMessage={this.state.currentSocketMessage}
                  socketSend={this.socketService.socketSend}
                  currentTab={this.state.currentTab}
                  selectedDoubt={this.state.selectedDoubt}
                  onDoubtRespond={this.onDoubtRespond}
                  onCallRefreshTicketList = {this.onCallRefreshTicketList}
                   />
              </div>
            </div>
          </div>
          {/* <TicketmangementTable /> */}

        </Navbar>
        <Dialog
          visible={this.state.ticketModalState}
          style={{ width: '80%' }}
          header={"Raise Ticket"}
          modal
          footer={() => {
            return (
              <>
                <Button label="Save" className='ma-m-lr10' onClick={this.submitTicket} />
                <Button label="Cancel" className='ma-m-lr10' onClick={this.closeTicketModal} />
              </>
            )
          }}
          draggable={false}
          blockScroll={true}
          closeOnEscape={false}
          dismissableMask={false}
          closable={false}
          onHide={this.props.hideRoleCreateDialog}>
          <div className="p-col-12 p-md-12">
            <div className="p-grid ma-box-style1">
              <div className="p-col-12 p-md-12">
                <div className="p-grid">
                  <div className="p-col-12 p-md-2">
                    <p className='ma-label-s1'>Contact Number<span className='ma-required'></span></p>
                  </div>
                  <div className="p-col-12 p-md-10">
                    {/* <InputText value={ticketData?.phoneNumber} className='p-inputtext-style1' onChange={(e) => { this.onTextChange(e, 'phoneNumber') }} /> */}
                    {/* {errors?.phoneNumber && <div style={{ color: 'red' }}>Invalid phone number</div>} */}
                    {ticketData.phoneNumber}
                  </div>
                </div>

                <div className="p-grid">
                  <div className="p-col-12 p-md-2">
                    <p className='ma-label-s1'>Email ID<span className='ma-required'></span></p>
                  </div>
                  <div className="p-col-12 p-md-10">
                    {/* <InputText value={ticketData?.emailId} onChange={(e) => { this.onTextChange(e, 'emailId') }} className='p-inputtext-style1' /> */}
                    {/* {errors?.email && <div style={{ color: 'red' }}>Invalid email</div>} */}
                    {ticketData.emailId}
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-12 p-md-2">
                    <p className='ma-label-s1'>URL<span className='ma-required'></span></p>
                  </div>
                  <div className="p-col-12 p-md-10">
                    <InputText value={ticketData?.url} onChange={(e) => { this.onTextChange(e, 'url') }} className='p-inputtext-style1 ma-w60' rows={5} cols={30} />
                    {errors?.url && <div style={{ color: 'red' }}>Invalid link</div>}
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-12 p-md-2">
                    <p className='ma-label-s1'>Description<span className='ma-required'></span></p>
                  </div>
                  <div className="p-col-12 p-md-10">
                    <InputTextarea value={ticketData?.description} onChange={(e) => { this.onTextChange(e, 'description') }} className='p-inputtext-style1 ma-w60' rows={5} cols={30} />
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-12 p-md-2">
                    <p className='ma-label-s1'>Attachments<span className='ma-required'></span></p>
                  </div>
                  {/* <div className="p-col-12 p-md-10"> */}
                  <FileUpload id={"file" + (new Date().getTime().toString().substring(5) + 30)}
                    multiple={true}
                    onProgress={this._onProgress}
                    onSucess={this._onSuccess}
                    maxFileSize={20000000}
                    onError={this._onFilsUploadError}
                    accept={'all'}
                    title={'add files'}
                    afterFilesUploaded={this._afterFilesUploaded}
                  >
                    <span className={`outlined-s1 ma-right ma-pointer ma-ml20`}><i className='pi pi-upload ma-mr10'></i>Upload</span>
                  </FileUpload><br />
                  <p>Note:Upload upto 20MB</p>
                  {/* </div> */}
                  {ticketData?.uploadedFilesInfo?.map((vl) => <p>{vl?.name}</p>)}
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

const mapStatesToProps = (state) => ({
  discussionUser:
    state.currentUserPerms &&
    state.currentUserPerms.discussionUser &&
    state.currentUserPerms.discussionUser,
  userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
  orgName: state.orgData.name,

});

export default connect(mapStatesToProps, {})(Authentication(AskADoubt));
