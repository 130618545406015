import * as ActionTypes  from '../actionTypes';
import axios from 'axios';
import { baseUrlAdmin } from '../apiConstants';


const dispatchRequestContentPreSignedUrl = data => ({
    type: ActionTypes.REQUEST_CONTENT_PRE_SIGNED_URL,
    data
})

const dispatchReceivedContentPreSignedUrl = res => ({
    type: ActionTypes.RECEIVED_CONTENT_PRE_SIGNED_URL,
    res
})

const dispatchErrorContentPreSignedUrl = err => ({
    type: ActionTypes.ERROR_CONTENT_PRE_SIGNED_URL,
    err
})

const dispatchClearContentPreSignedUrl = () => ({
    type: ActionTypes.CLEAR_CONTENT_PRE_SIGNED_URL_DATA
})

export const clearContentPreSignedUrlData = () => dispatchClearContentPreSignedUrl();


export const getContentPreSignedUrl = (filename, isImage) => dispatch => {
    const endPoint = isImage ? 'uploadImage' : 'contenturl';
    
    dispatch(dispatchRequestContentPreSignedUrl());
    axios({
        method: 'get',      
        url:  `${baseUrlAdmin}/${endPoint}?filename=${filename}`,
    }).then(({data}) => {
        dispatch(dispatchReceivedContentPreSignedUrl(data));
    }).catch(e => {
        dispatch(dispatchErrorContentPreSignedUrl(e));
    });
}


