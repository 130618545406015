import * as ActionTypes from '../actionTypes';
import axios from 'axios';
import {
    baseUrlAdmin
} from '../apiConstants';

const dispatchRequestOrgData = data => ({
    type: ActionTypes.REQUEST_ORG,
    data
});

const dispatchReceivedOrgData = (data) => ({
    type: ActionTypes.RECEIVED_ORG,
    data
});

const dispatchErrorOrgData = err => ({
    type: ActionTypes.ERROR_ORG,
    err
});

const dispatchClearOrgData = () => ({
    type: ActionTypes.CLEAR_ORG_DATA
});



export const getOrgData = (withoutMeluhaBoard) => dispatch => {
    let _data;

    dispatch(dispatchRequestOrgData(withoutMeluhaBoard));
    axios({
        method: 'get',
        url: `${baseUrlAdmin}/organization/data`


        // headers: {
        //     'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        // }
    }).then((res) => {
        const etag = res.headers && res.headers.etag;
        _data = res.data
        //localStorage.setItem('orgEtag', etag);

        if (res && res.data && res.data.data && res.data.data) {

            const {
                assignedModules,
                logoRectangle,
                logoSquare,
                _id,
                name,
                isSuper,
                orgCode,
                emailConfig,
                smsConfig,
                syncEnabled,
                whatsAppConfig,
                address,
                mobileOperational
            } = res.data.data;

            dispatch(dispatchReceivedOrgData({
                assignedModules,
                logoRectangle,
                logoSquare,
                _id,
                name,
                isSuper,
                orgCode,
                emailConfig,
                smsConfig,
                whatsAppConfig,
                syncEnabled,
                address,
                mobileOperational
            }));
        } else {
            dispatch(dispatchErrorOrgData({
                message: 'Some error occured'
            }));
        }



    }).catch(e => {
        dispatch(dispatchErrorOrgData(e));
    });
}


export const getOrgDataByCode = (orgCode) => dispatch => {
    let _data;

    dispatch(dispatchRequestOrgData(orgCode));
    axios({
        method: 'get',
        url: `${baseUrlAdmin}/organization/databycode/${orgCode}`

    }).then((res) => {
        const etag = res.headers && res.headers.etag;
        _data = res.data
        //localStorage.setItem('orgEtag', etag);

        if (res && res.data && res.data.data && res.data.data) {

            const {
                assignedModules,
                logoRectangle,
                logoSquare,
                _id,
                name,
                isSuper,
                orgCode,
                emailConfig,
                smsConfig,
                whatsAppConfig,
                syncEnabled,
                address,
                mobileOperational
            } = res.data.data;

            dispatch(dispatchReceivedOrgData({
                assignedModules,
                logoRectangle,
                logoSquare,
                _id,
                name,
                isSuper,
                orgCode,
                emailConfig,
                smsConfig,
                whatsAppConfig,
                syncEnabled,
                address,
                mobileOperational
            }));
        } else {
            dispatch(dispatchErrorOrgData({
                message: 'Some error occured'
            }));
        }



    }).catch(e => {
        dispatch(dispatchErrorOrgData(e));
    });
}


export const clearOrgData = () => dispatchClearOrgData();