import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import React, { Component } from 'react'
import { Column } from 'react-formio/lib/types';

export default class Payfee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 1,
            enteredAmount: '',
            paymentMode: '',
            feeTypeWise: [
                {
                    feeType: 1,
                    actualfee: 1000
                },
                {
                    feeType: 1,
                    actualfee: 1000
                },
                {
                    feeType: 1,
                    actualfee: 1000
                }
            ],
            installmentWise: [
                {
                    feeType: 2,
                    actualfee: 2000
                },
                {
                    feeType: 1,
                    actualfee: 1000
                },
                {
                    feeType: 1,
                    actualfee: 1000
                }
            ],
        }
    }

    componentDidMount() {
        console.log(this.props)
    }

    changeTab = (num) => {
        this.setState({
            selectedTab: num
        })
    }


    render() {
        const { selectedTab } = this.state

        return (
            <>
                <div className='p-grid white-bg tab-active-body ma-p10'>
                    <div className='p-mt-3 p-mb-3'>
                        <div className='qb-tabs p-pb-3'>
                            <span onClick={() => { this.changeTab(1) }} className={selectedTab == 1 ? 'qb-active-tab' : 'qb-inactive-tab'}>Fee Type Wise</span>
                            <span onClick={() => { this.changeTab(2) }} className={selectedTab == 2 ? 'qb-active-tab' : 'qb-inactive-tab'}>Installement Wise</span>
                        </div>
                    </div>
                    {
                        selectedTab == 1 &&
                        <DataTable className='w-90 p-ml-5'
                            value={this.state.feeTypeWise}
                        >
                            <Column header='SNo' body={(rowData, index) => {
                                return (
                                    <p>{index.rowIndex + 1}</p>
                                )
                            }} />
                            <Column header='Fee Type' field='feeType' />
                            <Column header='Actual Fee' field='actualfee' />
                            <Column header='Concession' />
                            <Column header='Net Fee' />
                            <Column header='Paid' />
                            <Column header='Balance' />
                            <Column header='' />
                        </DataTable>
                    }
                    {
                        selectedTab == 2 &&
                        <DataTable className='w-90 p-ml-5'
                            value={this.state.installmentWise}
                        >
                            <Column header='SNo' body={(rowData, index) => {
                                return (
                                    <p>{index.rowIndex + 1}</p>
                                )
                            }} />
                            <Column header='Fee Type' field='feeType' />
                            <Column header='InstallMent Name' />
                            <Column header='Actual Fee' field='actualfee' />
                            <Column header='Concession' />
                            <Column header='Net Fee' />
                            <Column header='Paid' />
                            <Column header='Balance' />
                            <Column header='' />
                        </DataTable>
                    }

                    {selectedTab == 1 &&
                        <div className='p-mt-5'>
                            <label className='ma-table-title p-mr-3'>Enter Amount :</label>
                            <InputNumber className=''
                                value={this.state.enteredAmount}
                                onChange={(e) => this.setState({ enteredAmount: e.target.value })}
                            />
                        </div>
                    }

                    <div className="p-mt-3">
                        <p className='ma-label-s2 ma-table-title'>Payment Mode<span className='ma-required'>*</span></p>
                        <div className="p-grid p-mt-1">
                            <div className="p-col-1 p-md-1 p-ml-5">
                                <RadioButton className='p-pt-0'
                                    inputId="paymentMode1" name="paymentMode" value="cash"
                                    onChange={(e) => this.setState({ paymentMode: e.value })}
                                    checked={this.state.paymentMode === 'cash'} />
                                <label htmlFor="Cash" className="p-ml-2  radio-Buttons">Cash</label>
                            </div>
                            <div className="p-col-1 p-md-1">
                                <RadioButton
                                    inputId="paymentMode2" name="paymentMode" value="cheque"
                                    onChange={(e) => this.setState({ paymentMode: e.value })}
                                    checked={this.state.paymentMode === 'cheque'} />
                                <label htmlFor="Cheque" className="p-ml-2 radio-Buttons">Cheque</label>
                            </div>
                            <div className="p-col-4 p-md-2">
                                <RadioButton
                                    inputId="paymentMode3" name="paymentMode" value="credit/debit"
                                    onChange={(e) => this.setState({ paymentMode: e.value })}
                                    checked={this.state.paymentMode === 'credit/debit'} />
                                <label htmlFor="Credit/Debit" className="p-ml-2 radio-Buttons">Credit/Debit</label>
                            </div>
                        </div>
                        <div className='p-mt-3 p-ml-5'>
                            <Button label='Save' />
                        </div>
                    </div>

                </div>
            </>
        )
    }
}
