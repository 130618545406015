import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Service from '../../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { getFormFields, isFormValid } from '../../../utile/formHelper';
import InputTextB from './../../customComponents/inputTextB';
import { FIELDS_INFO } from '../../../constants';
import { trimObj } from '../../../utile';
import uuidv4 from 'uuid/v4';
import './styles.scss'
import { I18Next } from './../../../internationalization';

const formFields = [
    {
        "Type": "Text",
        "Label": "Class Name",
        "FieldName": "className",
        "Required": true,
        "MaxLength": 20
    },
    {
        "Type": "Toggle",
        "Label": "Active Status",
        "FieldName": "isActivity",
        "Required": false
    }

]

class CreateClass extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, this.props.editClassData || { classId: uuidv4() });
        this.state = {
            classData: this.formFields.data,
            formValidations: this.formFields.formValidations
        }
    }

    footer = () => {
        const { defaultLanguage } = this.props
        const langType = defaultLanguage
        return (<div>
            <Button label={I18Next('cancel', langType).COMMON_KEY} className='p-button-outlined' onClick={this.onCloseDialog} />
            <Button label={`${this.props.isAdd ? (I18Next('classesList', langType).COMMON_KEY) : (I18Next('update', langType).COMMON_KEY)}`} className='ma-m-lr10' onClick={this.onSubmitClick} />
        </div>)
    }

    onSubmitClick = () => {
        const formStatus = isFormValid(formFields, this.formFields.formValidations, trimObj(this.state.classData));
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {
            this.props.addClass(trimObj(this.state.classData));
            // this.setState({
            //     formValidations: formStatus.formValidations,
            // });
        }
    }

    onCloseDialog = () => {
        this.props.hideCreateDialog();

    }

    onTextChange = (e, field) => {
        let data = JSON.parse(JSON.stringify(this.state.classData));
        let formValidations = this.state.formValidations;
        data[field] = e.target.value;
        let fieldInfo = formFields.filter((f) => f.FieldName == field)
        if (formValidations.fields[field].isRequired) {
            if (data[field] == '' || data[field] == null) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
        }

        this.setState({
            classData: data,
            formValidations
        });
    }
    onToggleChange = (e, field) => {
        let data = JSON.parse(JSON.stringify(this.state.classData));
        let formValidations = this.state.formValidations;
        data[field] = e.value;
        let fieldInfo = formFields.filter((f) => f.FieldName == field)
        if (formValidations.fields[field].isRequired) {
            if (!data[field]) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
        }
        this.setState({
            classData: data,
            formValidations
        });
    }

    render() {
        const { defaultLanguage } = this.props
        const langType = defaultLanguage
        const { classData, formValidations } = this.state;
        return (
            <Dialog header={`${this.props.isAdd ? (I18Next('add', langType).COMMON_KEY) : (I18Next('edit', langType).COMMON_KEY)} ${I18Next('class', langType).COMMON_KEY}`} blockScroll={true} draggable={false} footer={this.footer} closeOnEscape={true} dismissableMask={true} visible={true} closable={false} onHide={() => this.setState({ showCreateDialog: false })}>
                <div className="p-grid subject-add-dialog">
                    <div className="p-col-4 p-md-4 p-lg-4">
                        <p className='text-label'>{I18Next('className', langType).COMMON_KEY}<span className='ma-required'>*</span></p>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                        <InputTextB info={FIELDS_INFO.CLASS_NAME} id="topicn">
                            <InputText value={classData.className} onChange={(e) => { this.onTextChange(e, 'className') }} className='p-inputtext-style1 ' />
                        </InputTextB>
                        {formValidations && !formValidations.fields['className'].isValid && <p className="p-error">{formValidations.fields['className'].errorMsg}</p>}
                    </div>
                </div>
                <div className="p-grid subject-add-dialog">
                    <div className="p-col-4 p-md-4 p-lg-4">
                        <p className='text-label'>{I18Next('status', langType).COMMON_KEY}</p>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                        <InputTextB info={FIELDS_INFO.CLASS_STATUS} id="topicn">
                            <InputSwitch checked={classData.isActivity} onChange={(e) => { this.onToggleChange(e, 'isActivity') }} />
                        </InputTextB>
                    </div>
                </div>
            </Dialog >
        )
    }
}
const mapStatesToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,

});

export default connect(mapStatesToProps, {})(CreateClass);
