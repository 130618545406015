import * as ActionType from '../actionTypes';

const initialState = {
  loading: false,
  response: null,
  error: false,
  errorInfo: null,
  isCreateUserSucceeded: false
};

export default (state = {}, action) => {
  switch (action.type) {
    case ActionType.UPDATE_ADMIN_USER_REQUESTED:
      return {
        ...state,
        loading: true,
        error: false,
        isUpdateUserSucceeded: false,
        errorInfo: null
      };
    case ActionType.UPDATE_ADMIN_USER_SUCCEEDED:
      return {
        ...state,
        loading: false,
        response: action.res,
        isUpdateUserSucceeded: true,
        errorInfo: null
      };
    case ActionType.ERROR_UPDATE_ADMIN_USER:
      return {
        ...state,
        loading: false,
        errorInfo: action.err,
        error: true
      };
    case ActionType.UPDATE_ADMIN_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };
    case ActionType.ADD_ADMIN_USER_REQUESTED:
      return {
        ...state,
        loading: true,
        error: false,
        isCreateUserSucceeded: false,
        errorInfo: null
      };
    case ActionType.ADD_ADMIN_USER_SUCCEEDED:
      return {
        ...state,
        loading: false,
        response: action.res,
        isCreateUserSucceeded: true,
        errorInfo: null
      };
    case ActionType.ERROR_CREATE_NEW_ADMIN_USER:
      return {
        ...state,
        loading: false,
        errorInfo: action.err,
        error: true
      };
    case ActionType.REQUEST_SAVE_BULK_ADMINS:
      return {
        ...state,
        bulkUserUploadRequested: true,
        loading: true,
        errorInfo: null,
        error: false,
      }
    case ActionType.RECEIVED_SAVE_BULK_ADMINS:
      return {
        ...state,
        loading: false,
        bulkUserUploadRequested: false,
        errorInfo: null,
        error: false,
        bulkUserUploadSaveResponse: action.res
      }
    case ActionType.REQUEST_BULK_UPLOAD_TRANSACTIONS_ADMINS:
      return {
        ...state,
        loading: true,
        errorInfo: null,
        error: false,
      }
    case ActionType.RECEIVED_BULK_UPLOAD_TRANSACTIONS_ADMINS:
      return {
        ...state,
        loading: false,
        errorInfo: null,
        error: false,
        bulkUserUploadTransactionDetails: [],
        bulkUserUploadTransactions: action.res
      }
    case ActionType.ERROR_BULK_UPLOAD_TRANSACTIONS_ADMINS:
      return {
        ...state,
        loading: false,
        errorInfo: action.err,
        error: true,
        response: null,
        errorMessage: null
      }
    case ActionType.REQUEST_BULK_UPLOAD_TRANSACTION_DETAILS_ADMINS:
      return {
        ...state,
        loading: true,
        errorInfo: null,
        error: false,
      }
    case ActionType.RECEIVED_BULK_UPLOAD_TRANSACTION_DETAILS_ADMINS:
      return {
        ...state,
        loading: false,
        errorInfo: null,
        error: false,
        bulkUserUploadTransactionDetails: action.res
      }
    case ActionType.ERROR_BULK_UPLOAD_TRANSACTION_DETAILS_ADMINS:
      return {
        ...state,
        loading: false,
        errorInfo: action.err,
        error: true,
      }
    case ActionType.ERROR_SAVE_BULK_ADMINS:
      return {
        ...state,
        loading: false,
        errorInfo: action.err,
        bulkUserUploadRequested: false,
        error: true,
        response: null,
        errorMessage: null
      }
    case ActionType.CLEAR_BULK_ADMINS_TRANSACTION_DATA:
      return {
        ...state,
        bulkUserUploadTransactions: [],
        bulkUserUploadTransactionDetails: [],
        bulkUserUploadSaveResponse: null
      }
    case ActionType.ADD_ADMIN_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };
      return {};
    default:
      return state;
  }
};
