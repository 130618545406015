import React, { Component } from 'react'
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { ResponsiveBar } from '@nivo/bar';
import InLoadingComponent from '../../loadingComponent/inlineLoading';
import { I18Next } from './../../../internationalization';
import { connect } from 'react-redux';
import Authentication from './../../session';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getLanguageNameByCode } from '../../../utile';

const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: '',
    sortField: null,
    sortOrder: null,
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'country.name': { value: '', matchMode: 'contains' },
        'company': { value: '', matchMode: 'contains' },
        'representative.name': { value: '', matchMode: 'contains' },
    }
}
const arr = [
    { no: 1, type: "Telugu", count: "" },
    { no: 2, type: "English", count: "" },
    { no: 3, type: "Hindhi", count: "" },
]
const dummy = [
    {
        contentLanguage: 'en',
        video: 5,
        ppt: 6,
        zip: 9,
        quiz: 22,
        pdf: 34
    },
    {
        contentLanguage: 'te',
        video: 15,
        ppt: 5,
        zip: 10,
        quiz: 16,
        pdf: 19

    },
]
class LanguageWiseResources extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inlineLoading: false,
            inlineLoadingD: false,
            langWiseResources: [],
            dialogHeader: "",
            showDiolog: false,
            arrToDataTable: [],
            totalRecords: 11,
            globalSearch: '',
            lazyParams: BasicLazyParams
        }
        this.service = new Service();
    }

    componentDidMount() {
    }

    onClickMore = (h) => {
        this.setState({
            showDiolog: true,
            dialogHeader: h
        })
    }

    onPage = (event) => {
        this.setState({ lazyParams: event });
    }
    render() {
        const { defaultLanguage } = this.props;
        const { inlineLoading, langWiseResource } = this.state;
        const langType = defaultLanguage


        return (
            <div className='ma-relative'>
                <div className='resource-usage-report' style={{ background: "#dce7f7" }}>
                    <h3 className="resource-title ma-mt10">{I18Next('languageWiseResources', langType).COMMON_KEY}</h3>
                    <div className='p-grid'>
                        <div className='p-col-12 p-md-11'>
                            <div className='trending-table-rup' style={{ width: "120%" }}>
                                <table className='trending-table'>
                                    <tr>
                                        <th style={{ color: "#ba55d3", font: "normal normal bold 14px/16px Roboto" }}>{I18Next('number', langType).COMMON_KEY}</th>
                                        <th style={{ color: "#00ff00", font: "normal normal bold 14px/16px Roboto" }}>{I18Next('type', langType).COMMON_KEY}</th>
                                        <th style={{ color: "#6b8e23", font: "normal normal bold 14px/16px Roboto" }}>videos</th>
                                        <th style={{ color: "#98fb98", font: "normal normal bold 14px/16px Roboto" }}>ppt</th>
                                        <th style={{ color: "#ff0000", font: "normal normal bold 14px/16px Roboto" }}>pdf</th>
                                        <th style={{ color: "#87ceeb", font: "normal normal bold 14px/16px Roboto" }}>quiz</th>
                                        <th style={{ color: "#87ceeb", font: "normal normal bold 14px/16px Roboto" }}>zip</th>

                                    </tr>
                                    {
                                        this.props?.langWiseResource.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{getLanguageNameByCode(item.contentLanguage)}</td>
                                                    <td>{item.video ? item.video : 0}</td>
                                                    <td>{item.ppt ? item.ppt : 0}</td>
                                                    <td>{item.pdf ? item.pdf : 0}</td>
                                                    <td>{item.quiz ? item.quiz : 0}</td>
                                                    <td>{item.zip ? item.zip : 0}</td>
                                                </tr>
                                            )
                                        })
                                    }


                                </table>
                                {/* <div className='flex-reverse ma-mr100'>
                                <span className='ma-mt10 ma-pointer' onClick={() => this.onClickMore("Language Wise Resources")}
                                    style={{color:"#004cff",font:"normal normal bold 13px Roboto"}}>{`<< ${I18Next('more', langType).COMMON_KEY} >>`}</span>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog className='ma-alert-box p-dialog-dash p-overflow-hidden'
                    header={<h4>{this.state.dialogHeader}</h4>}
                    draggable={false}
                    closeOnEscape={true}
                    blockScroll={true}
                    dismissableMask={false}
                    closable={true}
                    visible={this.state.showDiolog} style={{ maxWidth: '50vw', minWidth: '50vw' }}
                    onHide={() => this.setState({ showDiolog: false })}
                >
                    <div className='card datatable-crud-demo'>
                        <DataTable stripedRows className='asdf'
                            ref={(el) => this.dt = el}
                            value={this.props?.langWiseResource}
                            // selection={this.state.selectedProducts}
                            // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                            dataKey="id"
                            paginator
                            // lazy
                            onPage={this.onPage}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            responsiveLayout="scroll"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Languages"
                            globalFilter={this.state.globalFilter}
                            header={''}
                        >
                            <Column header='Sl No.' body={(rowdata, rowIndex) => {
                                return (
                                    <p>{rowIndex.rowIndex + 1}</p>
                                )
                            }} />
                            <Column header='Type' field='type' />
                            <Column header='Videos' field='count' />
                            <Column header='PPT' field='count' />
                            <Column header='PDF' field='count' />
                            <Column header='Quizzes' field='count' />
                        </DataTable>
                    </div>
                    {
                        this.state.inlineLoadingD && <InLoadingComponent />
                    }
                </Dialog>
                {
                    inlineLoading && <InLoadingComponent />
                }
            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,
});

export default connect(mapStatesToProps, {})(Authentication(LanguageWiseResources));
