import { Tooltip } from 'primereact/tooltip';
import './styles.scss';

function SvgViewer(props) {
    return (
        <>
            <svg width={props.width} height={props.height} {...props}>
                <image xlinkHref={props.src} width={props.width} height={props.height} />
            </svg>
        </>
    );
}



export default SvgViewer;