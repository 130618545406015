import React from 'react';
import moment from "moment";

import DeleteMessage from './deleteMessage';
import { MESSAGE_STATUS } from '../../../utile';
//import DoneAllIcon from '@mui/icons-material/DoneAll';
const TEACHER_ID = localStorage.getItem('discussion_uId');


class TextView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        
        const { conversation } = this.props;
        return (
            <div className={`ask-wr message-text-c converse-w ${conversation.direction == 2 ? 'ma-right border-rad-super' : 'ma-left1 ma-left-b border-rad-normal'}`} >
                <p className='message-text'>{conversation.content}</p>
                <span className={`message-time  ${conversation.direction == 2 ? '' : 'ma-right'}`}>{moment(conversation.createdAt).format('lll')} {conversation.direction == 2 && (conversation.status == MESSAGE_STATUS.sent ? <i className="sent-icon" /> : conversation.status == MESSAGE_STATUS.sending ? <i className="pi pi-spinner sent-icon" /> : ' ')}</span>
                {/* <div className={`${conversation.direction == 2 ? 'delete-wrapper' : 'ma-hide'} `}>
                    <img src="./images/down_arrow.svg" className="down-arrow" />
                </div> */}
                {(conversation.direction == 2 && conversation.from._id == this.props.discussionUser._id) && <DeleteMessage conversation={conversation} deleteMessage={this.props.deleteMessage} />}

            </div>
        );
    }
}


export default TextView;



// import React from 'react';
// import moment from "moment";
// import DeleteMessage from './deleteMessage';
// import { getFileType, MESSAGE_STATUS } from './../../../utile';
// const TEACHER_ID = localStorage.getItem('discussion_uId');
// class TextView extends React.Component {
//     constructor(props) {
//         super(props);
//     }

//     render() {
//         const { conversation } = this.props;
//         return (
//             <div className={`ask-wr message-text-c converse-w ${conversation.direction == 2 ? 'ma-right' : 'ma-left ma-left-b'}`} >
//                 <div style={{ width: '100%', overflow: 'hidden' }}>
//                     {conversation.status && conversation.status == MESSAGE_STATUS.sending && <div style={{ width: '20px', float: 'left' }}>
//                         {/* <CircularProgress /> */}
//                     </div>
//                     }
//                     <div style={{ marginLeft: '40px', display: '' }}>
//                         <p className='message-text'>{conversation.content} </p>
//                         <span className={`message-time  ${conversation.direction == 2 ? '' : 'ma-right'}`}>{moment(conversation.createdAt).format('lll')} {conversation.status}</span>
//                     </div>
//                 </div>
//                 {(conversation.direction == 2 && conversation.from._id == this.props.discussionUser._id) && <DeleteMessage conversation={conversation} deleteMessage={this.props.deleteMessage} />}
//             </div>
//         );
//     }
// }


// export default TextView;
