import React from 'react';
import Service from './../../../services/index';

class ColearnersVideo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentInfo: this.props.contentInfo,
            playerInfo: null
        };
    }

    getPlayerInfo = (contentInfo) => {
        //console.log(contentInfo,"contentInfo")
        const service = new Service();
        service.get(contentInfo.contentFile, false).then((data) => {
            if (data && data.status) {
                //console.log(data,"data")
                this.setState({
                    playerInfo: data.res?.ContentDetails?.ContentDetails[0]
                });
            }
        });
    }

    componentDidMount() {
      
        let contentInfo = this.state.contentInfo;
        // if (contentInfo) {
        //     this.getPlayerInfo(contentInfo);
        // }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.contentInfo !== newProps.contentInfo) {
            // this.setState({
            //     contentInfo: newProps.contentInfo
            // }, this.getPlayerInfo(newProps.contentInfo));
        }
    }

    render() {
        
       
        return (
            <>  {this.state?.contentInfo &&

                <iframe src={encodeURI(this.state?.contentInfo?.contentFile)} width="100%" height="100%" />

                /* //<iframe src={`https://mymicroschool.in/${this.state.playerInfo && this.state.playerInfo.DATA_PATH}`} width="100%" height="100%" /> */
                           
                            
                            
            }
                
                
            
            </>
        );
    }
}


export default ColearnersVideo;
