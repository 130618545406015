import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react'
import { Column } from 'react-formio/lib/types';
import { BasicLazyParams, getServerTime, warningDailogInit } from '../../../../../utile';
import SvgViewer from '../../../../customComponents/svgViewer';
import { DeleteIcon, WarningIcon } from '../../../../svgIcons';
import AssignStudent from './assignStudent';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { baseUrlAdmin } from '../../../../../store/apiConstants';
import Service from '../../../../../services';


export default class Students extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            globalFilter: null,
            showSearch: false,
            columns: [
                { columnName: 'Distance From School', isVisible: false, id: 'distanceFromSchool' },
                { columnName: '', isVisible: false, id: '' }
            ],
            warningDialog: warningDailogInit,
            warningDialogForDelete: warningDailogInit,
            showAddStudent: false,
            editStudentDetails: '',
            studentDetails: [
                {
                    admissionNo: 123456,
                    studentName: 'asddf'
                },
            ],
            studAttendance: [
                { routeName: 'C16' }
            ],
            deleteStudFromStop: null,
            studRouteInfo: false,
            lazyParams: BasicLazyParams,
            studAttendanceStartDate: '',
            studAttendanceEndDate: ''
        }

        this.service = new Service()
    }

    componentDidMount() {
        console.log(this.props)
        this.getStudentsDetails()
    }

    getStudentsDetails = () => {
        this.setState({
            isLoading: true
        })

        let url = `${baseUrlAdmin}/transport-route/students/${this.props.selectedStop._id}`

        this.service.get(url, true).then(res => {
            if (res?.status && res?.res?.status) {
                this.setState({
                    isLoading: false,
                    studentDetails: res?.res?.data?.students,
                    totalRecords: res?.res?.data?.totalRecords
                })
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(err => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

        })
    }

    onClickAddStudent = (rowData) => {
        console.log('students', rowData)
        this.setState({
            showAddStudent: true,
            routeId: rowData
        })
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }
    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    handleDelete = async (rowData) => {
        console.log(rowData)
        this.setState({
            warningDialogForDelete: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `Do you want to delete the ${rowData.studentName} from the transporting students.`
            },
            deleteStudFromStop: rowData
        });
    }

    deleteStudentFromStop = () => {
        let { deleteStudFromStop } = this.state;
        const url = `${baseUrlAdmin}/transport-route/student/${deleteStudFromStop._id}`;

        this.setState({
            warningDialogForDelete: warningDailogInit,
            isLoading: true
        })
        this.service.delete(url, true).then((res) => {
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false
                },this.getStudentsDetails)
                this.toast.show({ severity: 'success', summary: 'Student Deleted', detail: res.res.message, life: 3000 });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    }


    showEditRoleDialog = (editData) => {
        this.setState({
            editStudentDetails: editData,
            showAddStudnet: true,
        });
    }

    onCloseAddStudent = (create) => {
        this.setState({
            routeData: '',
            editStudentDetails: '',
            showAddStudent: false
        }, this.getStudentsDetails)
        if (create) {
            this.toast.show({ severity: 'success', summary: 'Assigned', detail: 'Student assigned successfully.', life: 3000 });
        }
    }


    editTemplate = (rowData, { rowIndex }) => {
        return (<>
            <div className="p-d-flex user-edit-t">

                {/* <span data-pr-tooltip="Edit" data-pr-position="bottom" className={` ar${rowIndex}`} onClick={() => this.showEditRoleDialog(rowData)} >
                    <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="15" height="15" />
                </span>
                <Tooltip className="table-li-tooltip" autoHide={false} target={`.ar${rowIndex}`} /> */}

                <span data-pr-tooltip="Delete" data-pr-position="bottom" className={`dlete${rowIndex} p-ml-3`}
                    onClick={() => { this.handleDelete(rowData) }}
                >
                    <DeleteIcon height={15} width={15} />
                </span>
                <Tooltip className="table-li-tooltip" autoHide={false} target={`.dlete${rowIndex}`} />
            </div>
        </>
        );
    }

    onClickAdmssionNumber = (rowData) => {
        // console.log('onClickAdmssionNumber', rowData)
        this.setState({
            studRouteInfo: true
        })
    }

    onClickShowAttendance = () => {
        let validDates = true
        
        if (new Date(this.state.studAttendanceStartDate).getTime() > new Date(this.state.studAttendanceEndDate).getTime()) {
            this.toast.show({ severity: 'error', summary: 'Attendance Start and End Dates', detail: 'Please Check Attendance Start and End Date', life: 3000 });
            validDates = false
        }

        if (validDates) {
            this.setState({
                studAttendance: [
                    {
                        routeName: '156',
                        stopName: '10'
                    }
                ]
            })
            console.log('attendance')
        }
    }

    render() {
        console.log('SU', this.state.selectedUser)
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'>Students</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={(e) => this.setState({ globalFilter: e.target.value })} />}

                </div>

                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* s */}
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        return (
            <>

                <p className='back-arrow' onClick={this.props.onGoBack}><span><i className="pi pi-arrow-left"></i> </span> Back</p>

                <div className=''>
                    <div className='ma-mtb25 '>
                        <Button label="+ Assign Student" className='ma-m-lr10' onClick={this.onClickAddStudent} />
                    </div>
                    <div className='card datatable-crud-demo ma-m30'>
                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.studentDetails || []}
                            dataKey="id"
                            paginator
                            rows={10}
                            responsiveLayout="scroll"
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                            header={header}
                        >
                            <Column header="S.No." className='w-5' body={(rowData, index) => {
                                return (
                                    <>
                                        <p>{(this.state.lazyParams.page * this.state.lazyParams.rows)  + index.rowIndex + 1}</p>
                                    </>
                                )
                            }} />
                            <Column sortable field="" className='' header="Admission No" body={(rowData) => {
                                return (
                                    <>
                                        <span className='text-primary ma-pointer' 
                                        // onClick={() => this.onClickAdmssionNumber(rowData)}
                                        >{rowData?.admission?.admissionNo}
                                        </span>
                                    </>
                                )
                            }} />
                            <Column body={(rowData) => {
                                return (
                                    <>
                                        {rowData?.admission?.studentFirstName} {rowData?.admission?.studentMiddleName} {rowData?.admission?.studentLastName}
                                    </>
                                )
                            }} header="Student Name" />
                            <Column field="admission.boardName" header="Board Name" />
                            <Column field="admission.className" header="Class Name" />
                            <Column field="admission.section" header="Section" />
                            <Column field="" body={(rowData) => {
                                return (
                                    <>
                                        {rowData.createdAt ? moment(rowData.createdAt).format('lll') : ''}
                                    </>
                                )
                            }} header="Assigned on" />
                            <Column field="" header="Actions" className='' body={this.editTemplate} />
                        </DataTable>

                        {/* <div className='continue-btn p-mt-3'>
                            <Button label='Continue' onClick={this.props.onClickContinueFormStudent} />
                        </div> */}
                    </div>

                    <Dialog
                        draggable={false}
                        blockScroll={true}
                        closeOnEscape={true}
                        className='ma-alert-box'
                        dismissableMask={false}
                        closable={true}
                        visible={this.state.studRouteInfo} style={{ maxWidth: '60%', minWidth: '60%' }}
                        onHide={() => this.setState({
                            studRouteInfo: false,
                            studAttendanceStartDate: '',
                            studAttendanceEndDate: '',
                            studAttendance: []
                        })}
                        header='Student Attendance'
                    >
                        <div className=''>
                            <Calendar showIcon placeholder='Start Date'
                                value={this.state.studAttendanceStartDate}
                                onChange={(e) => this.setState({ studAttendanceStartDate: e.target.value })}
                            />
                            <Calendar showIcon placeholder='End Date' className='p-ml-3'
                                minDate={new Date(this.state.studAttendanceStartDate)}
                                value={this.state.studAttendanceEndDate}
                                onChange={(e) => this.setState({ studAttendanceEndDate: e.target.value })}
                            />
                            <Button label='Show Attendance' className='p-ml-3' onClick={this.onClickShowAttendance} />
                        </div>
                        <div className="card datatable-crud-demo ma-mt20">
                            <DataTable ref={(el) => this.dt = el}
                                lazy
                                value={this.state.studAttendance}
                                dataKey="_id"
                                paginator
                                onPage={this.onPage}
                                onSort={this.onSort}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                globalFilter={this.state.globalFilter2}
                                selection={this.state.selectedUser}
                                onSelectionChange={this.onSelectUser}
                                paginatorLeft={() => {
                                    return <>
                                        <p className='avail-q'> Selected Users:  <span>{this.state.selectedUser && this.state.selectedUser.length}</span> </p>
                                    </>
                                }}
                                selectionMode="checkbox"
                            >
                                <Column header="S.No." className='w-5' body={(rowData, index) => {
                                    return (
                                        <>
                                            <p>{index.rowIndex + 1}</p>
                                        </>
                                    )
                                }} />

                                <Column field="" header="Date" />
                                <Column field="routeName" header="Route Name" />
                                <Column field="stopName" header="Stop Name" />
                                <Column field="" header="Pick Up Time" />
                                <Column field="" header="Drop Time" />
                                {/* <Column header="Actions" body={(rowData) => {
                                    return (<>
                                        <Button label="Assign Assessment" onClick={() => this.assignSubjects(false, [rowData.sectionId])} />
                                    </>
                                    );
                                }} ></Column> */}
                            </DataTable>

                        </div>
                    </Dialog>

                    <Dialog
                        draggable={false}
                        blockScroll={true}
                        closeOnEscape={true}
                        className='ma-alert-box'
                        dismissableMask={true}
                        closable={false}
                        visible={this.state.warningDialogForDelete.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                        footer={() => {
                            return (<div>
                                <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialogForDelete: warningDailogInit, deleteStudFromStop: null }) }} />
                                <Button label="Yes" className='ma-m-lr10' onClick={this.deleteStudentFromStop} />

                            </div>)
                        }}
                        onHide={() => this.setState({ warningDialogForDelete: warningDailogInit, deleteStudFromStop: null })}>
                        <div>
                            <span className="warning-icon" >
                                <WarningIcon width={"100%"} height={"100%"} />
                            </span>
                        </div>
                        <p className='ma-alert-head'>{this.state.warningDialogForDelete.headerMsg}</p>
                        <p className='ma-alert-msg'>{this.state.warningDialogForDelete.message}</p>
                    </Dialog>

                    {this.state.showAddStudent &&
                        <AssignStudent
                            transportStop={this.props.selectedStop._id}
                            transportRoute={this.props.selectedStop.transportRoute}
                            showAddStudent={this.state.showAddStudent}
                            // routeData={this.state.routeData}
                            onCloseAddStudent={this.onCloseAddStudent}
                        />
                    }
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />

            </>
        )
    }
}
