import React from 'react';
import { Constants } from './constants';
import TextView from './TextView';
import ListView from './ListView';
import ImageView from './imageView';
import VideoView from './videoView';
import PDFView from './pdfView';

class Common extends React.Component {
    constructor() {
        super();
    }

    render() {
        const { conversation } = this.props;
        return (
            <div className="chat-c" >
                {conversation.type == Constants.Text && <TextView discussionUser={this.props.discussionUser} conversation={conversation} deleteMessage={this.props.deleteMessage} />}

                {(conversation.type == Constants.Button || conversation.type == Constants.List) && <ListView discussionUser={this.props.discussionUser} deleteMessage={this.props.deleteMessage} conversation={conversation} />}
                {conversation.type == Constants.AlertBlock && <> <div className="chat-alert-c" ><span className="chat-alert">{conversation.content}</span> </div>
                    {/* <div className='ma-clearFix'></div> */}
                </>}
                {conversation.type == Constants.AlertDanger && <> <div className="chat-alert-danger" ><span className="chat-alert">{conversation.content}</span> </div>
                    {/* <div className='ma-clearFix'></div> */}
                </>}
                {conversation.type == Constants.Image && <ImageView discussionUser={this.props.discussionUser} conversation={conversation} deleteMessage={this.props.deleteMessage} />}
                {conversation.type == Constants.Video && <VideoView discussionUser={this.props.discussionUser} conversation={conversation} deleteMessage={this.props.deleteMessage} />}
                {conversation.type == Constants.PDF && <PDFView discussionUser={this.props.discussionUser} conversation={conversation} deleteMessage={this.props.deleteMessage} />}
                {conversation.type == Constants.ZOOM && <> <p>Please click <a target="_blank" href={conversation.content.meetingStartURL}>here</a> start the zoom meeting.</p>
                </>}
            </div>
        );
    }
}

export default Common;