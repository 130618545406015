import React, { Component } from 'react';
import Navbar from './../navbar/index';
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { withRouter } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Authentication from './../session';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import LoadingComponent from '../loadingComponent';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import {
    getContentRepoBoards
} from './../../store/actions';
import './styles.scss'
import ContentPlayer from './contentPlayers';
import { QuestionTargets } from '../questionBank/constants';
import { BasicLazyParams } from '../../utile';
import SingleQuestion from '../questionBank/common/singleQuestion';
import PreviewQuestion from '../questionBank/common/previewQuestion';
import QuestionInTable from '../questionBank/common/questionInTable';
import { PreviewIcon } from '../svgIcons';
import _ from 'lodash';



class AddFromRepo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            boardId: null,
            classId: null,
            groupId: null,
            boards: [],
            classes: [],
            groups: [],
            subjects: [],
            subjectId: null,
            chapterId: null,
            topicId: null,
            content: null,
            isShowAddDialog: false,
            resourseOrigin: 'upload',
            globalFilter: '',
            selectedTopicResourceTypes: this.props.selectedTopicResourceTypes,
            showSearch: false,
            questionTarget: null,
            globalSearch: '',
            totalRecords: 0,
            lazyParams: BasicLazyParams,
            columns: [
                { columnName: 'Question Type', isVisible: true, id: 'questionType' },
                { columnName: 'Video Linked', isVisible: true, id: 'videoLinking' },
                { columnName: 'Difficulty', isVisible: false, id: 'difficulty' },
                { columnName: 'Skill Type', isVisible: false, id: 'skillType' },
                { columnName: 'Exam Type', isVisible: false, id: 'examType' },
                { columnName: 'Source', isVisible: false, id: 'source' },
                { columnName: 'Status', isVisible: false, id: 'status' },

            ],
            questions: [],
            selectedQuestions: []

        }
        this.service = new Service();
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }


    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }


    exportCSV = () => {
        this.dt.exportCSV();
    }


    componentDidMount() {
        // if (!this.props.boards || !this.props.boards.length) {


        if (this.props.resourseOrigin == 'repo') {
            this.props.getContentRepoBoards();
        } else {

        }

        // } else {
        //     const boardId = this.props.boards[0].boardId;
        //     const _classes = [];
        //     const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        //     if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
        //         selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        //     }
        //     this.setState({
        //         classes: _classes,
        //         boardId,
        //     });
        // }
    }

    componentDidUpdate(prevProps) {
        // if (prevProps.boards !== this.props.boards) {
        //     if (this.props.boards && this.props.boards.length) {
        //         const boardId = this.props.boards[0].boardId;
        //         const _classes = [];
        //         const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        //         if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
        //             selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        //         }
        //         this.setState({
        //             classes: _classes,
        //             boardId,
        //         });
        //     }
        // }


    }

    // grades selection --- start
    onChangeBoard = (boardId) => {
        const { resourseOrigin } = this.props;
        this.setState({
            classes: [], classId: null,
            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            questions: [],
            totalRecords: 0,
            content: null,
            filteredContentDetails: []
        });
        const _classes = [];
        const selectedBoard = this.props[resourseOrigin == 'repo' ? 'contentRepoBoards' : 'boards'] && this.props[resourseOrigin == 'repo' ? 'contentRepoBoards' : 'boards'].find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
        });

    };

    onChangeClass = (classId) => {
        const { resourseOrigin } = this.props;
        this.setState({
            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            questions: [],
            totalRecords: 0,
            content: null,
            filteredContentDetails: []
        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props[resourseOrigin == 'repo' ? 'contentRepoBoards' : 'boards'] && this.props[resourseOrigin == 'repo' ? 'contentRepoBoards' : 'boards'].find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                groupId: _groups[0].value
            }, () => {
                this.getCurriculumData();
            });
        }
    };

    onChangeGroup = (groupId) => {
        this.setState(
            {
                groupId,
                content: null,
                filteredContentDetails: [],
                questions: [],
                totalRecords: 0,
            },
            () => {
                this.getCurriculumData();
            }
        );
    };
    // grades selection --- end


    //subjects --- start


    getCurriculumData = () => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })

            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, false).then((data) => {

                if (data && data.res && data.res.Item) {
                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
        } else {
            this.setState({
                isLoading: false
            })
        }
    };


    onChangeSubject = (subjectId) => {
        let selectedSubject = this.state.subjects.filter((subject) => subject.subjectId == subjectId);

        this.setState((prevState) => {
            return {
                subjectId,
                chapterId: null,
                topicId: null,
                chapters: selectedSubject[0].chapters.sort((a,b) => a.chapterNumber - b.chapterNumber),
                topics: [],
                content: null,
                filteredContentDetails: [],
                questions: [],
                totalRecords: 0,

            };
        });
    };

    onChangeChapter = (chapterId) => {
        let selectedChapter = this.state.chapters.filter((chapter) => chapter.chapterId == chapterId);
        this.setState(
            {
                topics: selectedChapter[0].topics,
                chapterId,
                topicId: null,
                content: null,
                filteredContentDetails: [],
                questions: [],
                totalRecords: 0,
            });
    };

    onChangeTopic = (topicId) => {
        this.setState(
            {
                topicId,
                questions: [],
                totalRecords: 0,
                content: null
            });
    };

    checkIsAlreadyAssigned = (contentDetails) => {
        let assignedContents = [];
        if (this.props.contentInfo && this.props.contentInfo.length) {
            this.props.contentInfo.forEach((con) => {
                let inde1;
                let assignedCont = contentDetails.find((a, index) => {
                    if (a.contentId == con.contentId) {
                        inde1 = index
                        return a;
                    }
                });
                if (inde1) {
                    contentDetails[inde1].localName = con.contentName;
                }

                if (assignedCont) {
                    assignedContents.push(con);
                }
            });
        }
        return assignedContents;
    }


    filterResource = (contents, selectedTopicResourceTypes) => {
        let filter = selectedTopicResourceTypes.find((a) => a.isSelected);
        if (filter && filter.value) {
            contents = contents.filter((c) => c.contentFileType == filter.value)
            return contents.sort((a, b) => parseInt(a.priority) - parseInt(b.priority))
        } else {
            return []
        }
    }

    filterBySelected = (contents) => {
        return this.filterResource(contents, this.state.selectedTopicResourceTypes);
    }






    onSelectResourceType = (type) => {


        let selectedTopicResourceTypes = this.state.selectedTopicResourceTypes;
        let selectedTopicResourceType;
        selectedTopicResourceTypes = selectedTopicResourceTypes.map(r => {
            if (type.value == r.value) {
                r.isSelected = true;

                selectedTopicResourceType = r.value
            } else {
                r.isSelected = false
            }
            return r;
        });

        let contentDetails = (this.state.content && this.state.content.contentDetails) ? this.state.content.contentDetails : [];
        contentDetails = this.filterResource(contentDetails, selectedTopicResourceTypes)
        this.setState({
            selectedTopicResourceTypes,
            filteredContentDetails: contentDetails
        });
    }

    onQuestionTargetChange = (questionTarget) => {
        this.setState({
            questionTarget
        }, () => {
        })
    }

    isGradeValid = () => {
        let { boardId, classId, groupId, subjectId, chapterId, topicId } = this.state;

        let { questionTarget } = this.props;;

        if (!boardId || !classId || !groupId || !subjectId || (chapterId && !topicId)) {
            return false
        } else if (questionTarget == 'Normal' && (!chapterId || !topicId)) {
            return false
        } else {
            return true
        }
    }



    questionsAvailableR = () => {
        return <>
            <p className='avail-q'> Available Questions:  <span>{this.state.totalRecords}</span> </p>
        </>
    }


    fetchQuestions = () => {


        if (!this.isGradeValid()) {
            this.toast.show({ severity: 'error', summary: 'Grades Required', detail: 'Please select grades', life: 3000 });
        } else {

            //  this.toast.show({ severity: 'success', summary: 'Success', detail: 'valid', life: 3000 });


            // return true;
            const { questionTarget } = this.props;

            const {
                boardId, classId,
                groupId, subjectId,
                chapterId, topicId,

                lazyParams, globalSearch
            } = this.state;


            let filters = { questionTarget };

            // if (!questionTarget) {
            //     this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select question Target', life: 3000 });
            //     return

            // }


            if (questionTarget == 'Normal') {
                if (topicId) {
                    filters = { ...filters, topicId: [topicId] }
                } else if (chapterId) {
                    filters = { ...filters, chapterId: [chapterId] }
                } else if (subjectId) {
                    filters = { ...filters, subjectId: [subjectId] }
                } else if (groupId) {
                    filters = { ...filters, groupId: [groupId] }
                } else if (classId) {
                    filters = { ...filters, gradeId: [classId] }
                } else if (boardId) {
                    filters = { ...filters, boardId: [boardId] }
                }
            } else {
                if (subjectId) {
                    filters = { ...filters, subjectId: [subjectId] }
                } else if (groupId) {
                    filters = { ...filters, groupId: [groupId] }
                } else if (classId) {
                    filters = { ...filters, gradeId: [classId] }
                } else if (boardId) {
                    filters = { ...filters, boardId: [boardId] }
                }
            }


            this.setState({
                isLoading: true,
                questions: [],
                totalRecords: 0
            });


            this.service.post(`${baseUrlAdmin}/questionlinking/filter/questions/page?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`, filters, true).then((data) => {

                if (data && data.status && data?.res?.data) {
                    this.setState({
                        totalRecords: data.res.data.totalRecords,
                        questions: data.res.data.questions,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false,
                    });
                }


            }).catch(e => {
                this.setState({
                    isLoading: false,

                });
                console.log(e);

            });



        }

    }



    onFetchQuestions = () => {

        this.setState((prevState) => {
            return {
                exam: {
                    ...prevState.exam,
                    questions: []
                }
            };
        }, this.onSearch);
    }

    onSearch = () => {

        const { topicId,
            lazyParams, globalSearch,
            exam,
            formValidations
        } = this.state;




        if (topicId && topicId != '') {
            let { questionType, difficulty,
                skillType, status, } = exam;

            let filters = {};


            if (topicId) {
                filters = { ...filters, topicId: topicId }
            }





            if (questionType && questionType.length) {
                filters = { ...filters, questionType: questionType }
            }
            if (difficulty && difficulty.length) {
                filters = { ...filters, difficulty: difficulty }
            }
            if (skillType && skillType.length) {
                filters = { ...filters, skillType: skillType }
            }
            if (status && status.length) {
                filters = { ...filters, status: status }
            }

            this.setState((prevState) => {
                return {
                    isLoading: true,
                    questions: [],
                    totalRecords: 0,

                };
            });


            this.service.post(`${baseUrlAdmin}/question/getTopicLinkedQuestions`, filters, true).then((data) => {

                if (data && data.status && data?.res?.questions) {
                    this.setState({
                        questions: data?.res?.questions,
                        isLoading: false
                    });

                } else {
                    this.setState({
                        isLoading: false
                    })
                }


            }).catch(e => {
                this.setState({
                    isLoading: false
                })
                console.log(e);

            });


        } else {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select board, class, subject, chapter, topic', life: 3000 });
        }




    }


    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.fetchQuestions);
    }



    onSort = (event) => {


        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.fetchQuestions);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.fetchQuestions);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }



    onSelectContent = (e) => {
        this.setState({ selectedQuestions: e.value }, this.props.onSelectQuestion(e.value, this.state.content))
    }

    isSelectedAllItems = (tableItems, selectedItems, uniqField) => {
        if (tableItems && tableItems.length) {
            let d = tableItems.every(a => selectedItems.find(b => a[uniqField] == b[uniqField]) ? true : false);

            return d;
        } else {
            return false;
        }
    }

    isSelected = (rowData, selectedItems, uniqField) => {
        return selectedItems.find(a => a[uniqField] == rowData[uniqField]) ? true : false;
    }

    selectOrdeselect = (type, selectedItems, items, isSelect, selectedItemsField, uniqField) => {
        let selectedIts = _.cloneDeep(selectedItems);
        if (type == 'list') {
            if (isSelect) {
                items.forEach(i => {
                    let data = selectedIts.find(a => a[uniqField] == i[uniqField]);
                    if (!data) {
                        selectedIts.push(i);
                    }
                })
            } else {
                items.forEach(i => {
                    selectedIts = selectedIts.filter(a => a[uniqField] != i[uniqField]);
                })
            }
        } else {
            if (isSelect) {
                selectedIts.push(items)
            } else {
                selectedIts = selectedIts.filter((i) => i[uniqField] != items[uniqField]);
            }
        }

        this.setState({
            [selectedItemsField]: selectedIts
        }, () => {
            this.props.onSelectQuestion(selectedIts)
        })





    }


    render() {

        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'>Questions List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" value={this.state.globalSearch} className="ma-tbl-search p-inputtext-md" onChange={this.onGlobalSearch} />}

                </div>

                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        {/* <Authorizer permId={PERMISSIONS.ORG_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch, globalSearch: '' }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer> */}
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.ORG_VIEW_COLUMNS} > */}
                        <li><i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        {/* </Authorizer> */}
                        {/* <Authorizer permId={PERMISSIONS.ORG_DOWNLOAD} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer> */}
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <li onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </li>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );


        const { resourseOrigin } = this.props;
        //  console.log('boardsc', this.props[resourseOrigin == 'repo' ? 'contentRepoBoards' : 'boards'])
        return <div >
            <div>
                <Dropdown value={this.props.questionTarget}
                    className='ma-w200 ma-mr20 ma-mt10'
                    options={QuestionTargets}
                    optionLabel="value"
                    disabled={true}

                    optionValue="id"
                    onChange={(e) => this.onQuestionTargetChange(e.value)}
                    placeholder="Question Target" />
                <Dropdown value={this.state.boardId}
                    className='ma-w200 ma-mt10'
                    options={this.props[resourseOrigin == 'repo' ? 'contentRepoBoards' : 'boards']}
                    optionLabel='boardName'
                    optionValue='boardId'
                    onChange={(e) => this.onChangeBoard(e.value)}
                    placeholder="Select Board" />
                <span className='ma-required-mark '>*</span>
                <Dropdown value={this.state.classId}
                    options={this.state.classes}
                    className='ma-w200  ma-mt10'
                    onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                <span className='ma-required-mark '>*</span>
                <Dropdown value={this.state.subjectId}
                    options={this.state.subjects}
                    optionLabel='subjectName'
                    optionValue='subjectId'
                    className='ma-w200 ma-mt10'
                    onChange={(e) => this.onChangeSubject(e.value)} placeholder="Select Subject" />
                <span className='ma-required-mark '>*</span>
                <Dropdown value={this.state.chapterId}
                    options={this.state.chapters}
                    optionLabel='chapterName'
                    optionValue='chapterId'
                    className='ma-w200 ma-mt10'
                    onChange={(e) => this.onChangeChapter(e.value)} placeholder="Select Chapter" />
                {this.props.questionTarget == 'Normal' ? <span className='ma-required-mark '>*</span> : <span className='ma-required-mark '></span>}
                <Dropdown value={this.state.topicId}
                    options={this.state.topics}
                    optionLabel='topicName'
                    optionValue='topicId'
                    className='ma-w200 ma-mt10'
                    onChange={(e) => this.onChangeTopic(e.value)} placeholder="Select Topic" />
                {this.props.questionTarget == 'Normal' ? <span className='ma-required-mark '>*</span> : <span className='ma-required-mark '></span>}
                {/* <Button label="Fetch Resources" className=' ma-m-lr10' /> */}
            </div>
            <Button label='Fetch Questions' className='ma-mt20' onClick={this.fetchQuestions} />

            <div className="card datatable-crud-demo fetch-q-tabled fetch-q-table ma-mt20">
                <DataTable ref={(el) => this.dt = el}
                    scrollable scrollHeight="500px"
                    responsiveLayout="scroll"
                    dataKey="_id"
                    value={this.state.questions}
                    filters={this.state.lazyParams.filters}
                    // loading={this.props.isLoading}

                    className="ma-table-d"
                    tableClassName='ma-table-tbl'

                    paginatorLeft={this.questionsAvailableR}


                    paginator
                    lazy
                    onPage={this.onPage}
                    onSort={this.onSort}
                    onFilter={this.onFilter}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    sortField={this.state.lazyParams.sortField}
                    sortOrder={this.state.lazyParams.sortOrder}
                    totalRecords={this.state.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    selectionMode="checkbox"
                    // selection={this.state.selectedQuestions}

                    // onSelectionChange={this.onSelectQuestions}
                    // selection={this.state.selectedQuestions}
                    // onSelectionChange={this.onSelectContent}
                    //paginatorTemplate={paginatorTemplate}
                    //paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    // globalFilter={this.state.globalFilter}
                    header={header}>

                    <Column headerStyle={{ width: '4em' }} field='_id' header={<Checkbox inputId={'item.id'}
                        checked={this.isSelectedAllItems(this.state.questions, this.state.selectedQuestions, '_id')}
                        onChange={(e) => { this.selectOrdeselect('list', this.state.selectedQuestions, this.state.questions, e.checked, 'selectedQuestions', '_id') }}
                    ></Checkbox>}
                        body={(rowData) => {
                            return <Checkbox inputId={'item.id'}
                                checked={this.isSelected(rowData, this.state.selectedQuestions, '_id')}
                                onChange={(e) => { this.selectOrdeselect('single', this.state.selectedQuestions, rowData, e.checked, 'selectedQuestions', '_id') }}
                            ></Checkbox>
                        }}>
                    </Column>


                    <Column headerClassName='question-id' field="questionId" header="Question ID" />

                    <Column headerClassName='question' header="Question and solution" body={(rowData) => {
                        return (<>
                            <QuestionInTable question={rowData.question} />
                        </>)
                    }} />
                    {
                        this.isColumnVisible('questionType') && <Column headerClassName='difficulty' field="question.questionType" header="Question Type" />
                    }

                    {
                        this.isColumnVisible('videoLinking') && <Column headerClassName='difficulty' field="videoLinking" header="Video Linked"
                            body={(rowData) => {
                                return (<>
                                    <InputSwitch
                                        checked={(rowData.question && rowData.question.videoLinking && rowData.question.videoLinking.length) ? true : false} />

                                </>)
                            }}

                        />
                    }
                    {
                        this.isColumnVisible('difficulty') && <Column headerClassName='difficulty' field="question.difficulty" header="Difficulty"

                        />}
                    {
                        this.isColumnVisible('skillType') && <Column headerClassName='difficulty' field="question.skillType" header="Skill Type"

                        />}
                    {
                        this.isColumnVisible('examType') && <Column headerClassName='difficulty' field="question.examType" header="Exam Type"
                            body={(rowData) => {
                                return (<>
                                    {rowData.examType && rowData.examType.length ? rowData.examType.join(', ') : ''}
                                </>)
                            }}
                        />}
                    {
                        this.isColumnVisible('source') && <Column headerClassName='difficulty' field="question.source" header="Source"
                        />}
                    {
                        this.isColumnVisible('status') && <Column headerClassName='difficulty' field="question.status" header="Status"
                        />}
                    <Column headerClassName='difficulty' headerStyle={{ width: '140px' }} header="Actions" body={(rowData) => {
                        return (<>
                            {/* <Authorizer permId={PERMISSIONS.EDIT_ORGANIZATION} > */}
                            {/* <span data-pr-tooltip="Edit Question" data-pr-position="bottom" className={`edit${rowData._id} ma-mr20`} onClick={() => { this.props.onEditQuestion(rowData) }}>
                                    <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" />
                                </span>
                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData._id}`} /> */}
                            {/* </Authorizer> */}

                            <span data-pr-tooltip="Preview Question" data-pr-position="bottom" className={`preview${rowData._id}`}
                                onClick={() => {
                                    this.setState({
                                        isShowQuestionPreview: true,
                                        questionToPreview: rowData.question
                                    })
                                }}>
                                {/* <i className='pi pi-eye ' /> */}
                                <PreviewIcon height={13} width={13} />
                                {/* <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" /> */}
                                {/* <i className="pi pi-folder-open"></i>  */}
                            </span>
                            <Tooltip className="table-li-tooltip2" autoHide={false} target={`.preview${rowData._id}`} />
                        </>
                        );
                    }} ></Column>
                    {/* <Column header="Topics" body={this.showChaptersTemplate}></Column> */}
                </DataTable>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div>


            {
                (this.state.isLoading || this.props.isLoading) && <LoadingComponent />
            }
            {this.state.isShowPreview && <ContentPlayer onCloseDialog={() => {
                this.setState({
                    isShowPreview: false
                })
            }} contentInfo={this.state.previewContent} />}
            <Toast ref={(el) => this.toast = el} position="bottom-right" />

            {
                this.state.isShowQuestionPreview && <PreviewQuestion
                    question={this.state.questionToPreview}
                    onHide={() => {
                        this.setState({
                            isShowQuestionPreview: false
                        })
                    }}
                />
            }
        </div >
    }
}



const mapStateToProps = (state) => ({
    contentRepoBoards: state.boardsData.contentRepoBoards,
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});

export default connect(mapStateToProps, {
    getContentRepoBoards
})(Authentication(withRouter(AddFromRepo)));