import React, { Component } from 'react';
import _ from 'lodash';
import { selectAllRoles } from '../../../store/selectors/authorize';
import { updateRole } from '../../../store/actions/rolesAction';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { selectUsers } from '../../../store/selectors/adminUserList';
import { getAdminUserList } from '../../../store/actions/adminUserListActions';
import { updateAdminUser } from '../../../store/actions/createAdminUser';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import Authorizer, { PERMISSIONS, isAuthorized } from '../../session/authorizer';
import { getOrganizationData, getActiveOrganizationData } from '../../../store/actions';
import { selectOrganizationData } from '../../../store/selectors/organization';
import CreateUser from './createUser';
import Service from './../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';

import { BasicLazyParams, getUsersWithRoles } from './../../../utile'
import LoadingComponent from '../../loadingComponent';
import LazyTable from '../../lazyTable';
import { WarningIcon } from '../../svgIcons';

const warningDailogP = {
    visible: false,
    headerMsg: '',
    message: ''
}

class UsersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //  users: [],
            isShowCreate: false,
            editUserData: null,
            showSearch: false,
            isAdd: false,
            isLoading: false,
            ///adminData: null,
            warningDialog: warningDailogP,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams

        }

        this.service = new Service();

    }



    componentDidMount() {
        //this.toast.show({ severity: 'success', summary: 'Success', detail: 'Password reset and sent to mail successfully.', life: 3000000 });
        this.getUsers();
        // if (!this.props.userAdminList || !this.props.userAdminList.length) {
        //     this.props.getAdminUserList();
        // }

    }

    changeStatus = (e, rowData) => {
        let data = { ...rowData }
        if (e.value == true) {
            data.status = 'Active'
        } else {
            data.status = 'InActive'
        }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You are changing this User status ${e.value ? 'InActive' : 'Active'} to ${e.value ? 'Active' : 'InActive'}`
            },
            // editUserData: data,
            editUserData: this.transFormEditUserData(data)
        });

    }


    onConfirmChange = () => {

        this.setState({
            warningDialog: warningDailogP,
            isLoading: true
        });

        let user = this.state.editUserData;

        this.setState({
            isLoading: true
        });

        const url = `${baseUrlAdmin}/admin/changestatus?_id=${user._id}&status=${user.status}`;
        this.service.put(url, {}, true).then((res) => {


            if (res && res.status && res.res.success) {

                this.setState({
                    isLoading: false
                }, () => {
                    this.onUserCreate(false);
                })

            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }


        }).catch(e => {

            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })


    }

    resetPassword = (_id) => {



        this.setState({
            isLoading: true
        });

        const url = `${baseUrlAdmin}/admin/resetPassword?_id=${_id}`;
        this.service.put(url, {}, true).then((res) => {
            if (res && res.status && res.res.success) {
                this.setState({
                    isLoading: false,
                    isShowResetSuccess: true
                })
                //   this.toast.show({ severity: 'success', summary: 'Success', detail: 'Password reset and sent to mail successfully.', life: 3000 });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })




    }

    editTemplate = (rowData) => {
        return (<>
            <div className="p-d-flex ma-mlr10 user-edit-t">
                <div className="p-mr-2">
                    <Authorizer permId={PERMISSIONS.ADMIN_RESET_PASSWORD} >
                        <Button label="Reset Password" className='p-button-outlined' onClick={() => { this.resetPassword(rowData._id) }} />
                    </Authorizer>
                </div>
                <Authorizer permId={PERMISSIONS.EDIT_ADMIN_USER} >
                    <div className="p-mr-2"><Button label={`Edit`} className='ma-ml15 ' onClick={() => this.showEditUserDialog(rowData)} /></div>
                </Authorizer>
                <Authorizer permId={PERMISSIONS.ADMIN_SHOW_LOGS} >
                    <div className="p-mr-2"><Button label="Show Log" className='ma-ml15 ' /></div>
                </Authorizer>
            </div>
            {/* <span onClick={() => this.showEditUserDialog(rowData)}><i className="pi pi-folder-open"></i> </span> */}
        </>
        );
    }


    showEditUserDialog = (editUserData) => {

        this.setState({
            editUserData: this.transFormEditUserData(editUserData),
            isShowCreate: true,
            isAdd: false
        });
    }

    showUserCreateDialog = () => {

        this.setState({
            isShowCreate: true,
            editUserData: null,
            isAdd: true
        });

    }
    exportCSV = () => {
        this.dt.exportCSV();
    }

    hideUserCreateDialog = () => {
        this.setState({
            isShowCreate: false,
            editUserData: null,
            isAdd: false
        });
    }

    onUserCreate = (isCreate) => {
        this.toast.show({ severity: 'success', summary: isCreate ? 'User Created' : 'User Updated', detail: `User Successfully ${isCreate ? 'Created' : 'Updated'}`, life: 3000 });
        this.setState({
            isShowCreate: false,
            editUserData: null,

        }, () => {
            this.getUsers();
            //this.props.getAdminUserList();
        });

    }






    getUsers = () => {
        this.setState({
            isLoading: true
        });
        let { lazyParams, globalSearch } = this.state;

        const url = `${baseUrlAdmin}/admin/getAllUserAdmin?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        //const url = `${baseUrlAdmin}/admin/getAllUserAdmin`;
        this.service.get(url, true).then(res => {

            if (res && res.res && res.res) {

                this.setState({
                    users: getUsersWithRoles(res.res),
                    totalRecords: res.res.totalRecords,
                    adminData: res.res,
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
            console.log(res);


        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });

            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }

    getRoleName = (roleId) => {

        let roleName = '';
        let assignedRole = this.props.rolesList.find((role) => role._id == roleId);
        return (assignedRole && assignedRole.roleName) ? assignedRole.roleName : ''

    }


    getRoleId = (roleName) => {
        const { adminData } = this.state;
        let roleData = adminData.roleData;
        const _role = roleData && roleData.data.length && roleData.data.find((b) => b.roleName === roleName);
        if (_role) {
            return _role._id;
        }
        return '';
    };
    transFormEditUserData = (editData) => {

        const userData = _.cloneDeep(editData);

        userData.roles = this.getRoleId(userData.roles);

        let assignedSubjects = [];
        if (userData.assignedSubjects && userData.assignedSubjects.length) {
            assignedSubjects = userData.assignedSubjects.map((i, index) => {
                return {
                    id: index + 1,
                    assigned: true,
                    data: i
                };
            })
        }

        let assignedSections = [];
        if (userData.assignedSections && userData.assignedSections.length) {
            assignedSections = userData.assignedSections.map((i, index) => {
                return {
                    id: index + 1,
                    assigned: true,
                    data: i
                };
            })
        }

        userData.assignedSubjects = assignedSubjects;
        userData.assignedSections = assignedSections;
        userData.phone_number = userData.phone_number.substring(3);
        userData.boardId = userData.boardId.split(',');
        return userData;
    }


    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getUsers);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getUsers);
    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getUsers);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.getUsers);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }



    render() {
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >Users</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                        onChange={this.onGlobalSearch}
                        value={this.state.globalSearch}
                    />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_VIWECOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer> */}
                        <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
            </div>
        );
        

        return (
            <div className="p-grid white-bg tab-active-body">
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {
                    (this.state.isLoading || this.props.isLoading) && <>
                        <LoadingComponent />
                    </>
                }
                <Authorizer permId={PERMISSIONS.CREATE_ADMIN_USER} >
                    <Button label={`+ Add`} className='rol-add-btn' onClick={this.showUserCreateDialog} />
                </Authorizer>
                <div className="card datatable-crud-demo ma-m30 fetch-q-tabled">
                    {/* <LazyTable ></LazyTable> */}
                    <DataTable ref={(el) => this.dt = el}
                        //  lazy
                        scrollable
                        responsiveLayout="scroll"
                        //  autoLayout={true}
                        value={this.state.users}
                        // selection={this.state.selectedProducts}
                        // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                        dataKey="id"
                        //</div>={true}
                        lazy
                        onSort={this.onSort}
                        onPage={this.onPage}
                        onFilter={this.onFilter}
                        first={this.state.lazyParams.first}
                        last={this.state.totalRecords}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        paginator
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                        header={header}

                    >
                        <Column headerClassName='difficulty' field="slNo" header="Sr. No" body={(rowData) => {
                            return <span>
                                {(this.state.lazyParams.page * this.state.lazyParams.rows) + rowData.slNo}
                            </span>
                        }} />
                        <Column headerClassName='difficulty' sortField='username' field="username" header="Name"
                            body={(rowData) => {
                                return (<span> {rowData.username ? rowData.username.substring(3) : ''}</span>

                                )
                            }}
                        />
                        <Column headerClassName='difficulty' sortField='user_info.name' field="name" header="Name" />
                        <Column headerClassName='difficulty' field="roles" header="Role" />
                        <Column headerClassName='difficulty' sortField='user_info.userType' field="userType" header="User Type" />
                        <Column headerClassName='difficulty' sortField='user_info.phone_number' style={{ flex: '0 0 4rem' }} field="phone_number" header="Contact Number" />
                        <Column headerClassName='difficulty' body={(rowData) => {
                            return (
                                <div className="ma-status">
                                    <span className="ma-status-text">{rowData.status} </span>
                                    <Authorizer permId={PERMISSIONS.EDIT_ADMIN_USER} >
                                        <InputSwitch onChange={(e) => { this.changeStatus(e, rowData) }} checked={rowData.status == 'Active'} />
                                    </Authorizer>
                                </div>
                            )
                        }} field="status" header="Status" />
                        {/* <Column sortable field="status" header="Status" /> */}
                        {/* <Column field="isChapterActive" header="Status" body={this.statusTemplate} /> */}
                        <Column
                            // headerClassName='option-2'
                            //headerStyle={{ width: "520px",  }}
                            //headerStyle={{ display: 'inline-block   ' }}
                            sortabl body={this.editTemplate} header="Actions" ></Column>
                        {/* <Column body={this.showChaptersTemplate}></Column> */}
                    </DataTable>
                    {/* {this.state.isShowCreate && <CreateChapter isAdd={this.state.isAdd} hideCreateDialog={this.hideCreateChapterDialog} editChapterData={this.state.editChapterData} addChapter={this.addChapter} />} */}

                    {
                        this.state.isShowCreate && <CreateUser
                            onUserCreate={this.onUserCreate}
                            isAdd={this.state.isAdd}
                            hideUserCreateDialog={this.hideUserCreateDialog}
                            editUserData={this.state.editUserData}
                            roleData={this.props.adminData.roleData}
                        />
                    }
                </div>
                <Dialog
                    header={<></>}
                    blockScroll={true}
                    draggable={false}
                    closeOnEscape={false}
                    className='ma-alert-box'
                    dismissableMask={false}
                    closable={false}
                    visible={this.state.warningDialog.visible}
                    style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogP, editUserData: null }) }} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmChange} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogP, editUserData: null })}>
                    <div>

                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>
                    <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <p className='ma-alert-msg'>{this.state.warningDialog.message}</p>
                </Dialog>
                <Dialog
                    blockScroll={true}
                    visible={this.state.isShowResetSuccess}
                    // style={{ width: '80%' }}

                    // modal
                    footer={() => {
                        return (<div className='ma-mt20'>
                            <Button label="Done" onClick={() => {
                                this.setState({
                                    isShowResetSuccess: false
                                })

                            }} />
                            {/* <Button label={`Send Notification`} className='ma-m-lr10' onClick={() => { this.setState({ isShowCreateSuccess: false }) }} /> */}

                        </div>)
                    }}
                    draggable={false}
                    // footer={this.footer}
                    closeOnEscape={true}
                    dismissableMask={true}
                    // visible={true}
                    closable={false}
                    onHide={() => {
                        this.setState({
                            isShowResetSuccess: false
                        })
                    }}>
                    <div className='success-dialog'>
                        <img src="./images/success-icon.PNG" className='img-center' alt="success" />
                        <p className='success-title'>Success!</p>
                        <p className='success-msg'>
                            Your Password is Reset and Send a mail to your email Id.
                        </p>
                    </div>
                </Dialog>

            </div>
        )
    }
}




const mapStateToProps = (state) => ({
    //rolesList: selectAllUsers(state),
    isUpdateRoleSuccessful: state.roles.isUpdateRoleSuccessful,
    userAdminList: selectUsers(state),
    rolesList: state && state.adminUserList && state.adminUserList.data && state.adminUserList.data.roleData && state.adminUserList.data.roleData.data,
    adminData: state && state.adminUserList && state.adminUserList.data && state.adminUserList.data,
    boardsInfo: selectOrganizationData(state),
    isLoading: state.adminUserList && state.adminUserList.isLoading,
    isUpdateUserSucceeded: state.createNewAdminUserInfo.isUpdateUserSucceeded,
    updateUserError:
        state.createNewAdminUserInfo &&
        state.createNewAdminUserInfo.error
});

const mapDispatchToProps = (dispatch) => ({
    //  updateRoleReq: (data) => dispatch(updateRole(data)),
    // getAdminUserList: () => dispatch(getAdminUserList()),
    // getOrganizationData: () => dispatch(getOrganizationData()),
    // getActiveOrganizationData: () => dispatch(getActiveOrganizationData()),
    // updateAdminUser: (data) => dispatch(updateAdminUser(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);