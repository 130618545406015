import * as ActionType from "../actionTypes";

export default (state = {}, action) => {
  switch(action.type){
    case ActionType.REQUEST_GET_IMAGE_URL :

        return{
            ...state,
            loading:true,
            errorInfo:null,
            error:false
            
            }
    case ActionType.RECEIVED_GET_IMAGE_URL :
        return{
            ...state,
            loading:false,
            errorInfo:null,
            error:false,
            response:action.res,
            allImageUrls: {
                ...state.allImageUrls,
                [action.storageKey]: action.res
            }

        }
    case ActionType.ERROR_GET_IMAGE_URL :
        return{
            ...state,
            loading:false,
            errorInfo:action.err,
            error:true
        }
    case ActionType.CLEAR_GET_IMAGE_URL :
        return{
        }
    default : 
      return state    

  }
}