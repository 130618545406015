import { Button } from 'primereact/button'
import React, { Component } from 'react'

export default class IntegerKey extends Component {
    render() {
        return (<div>
            <Button className={`key-btn ${this.props.value == '0' ? 'key-btn-active' : 'p-button-outlined p-button-secondary'}`} onClick={() => { this.props.onClick(0) }} label='0' />
            <Button className={`key-btn ${this.props.value == '1' ? 'key-btn-active' : 'p-button-outlined p-button-secondary'}`} onClick={() => { this.props.onClick(1) }} label='1' />
            <Button className={`key-btn ${this.props.value == '2' ? 'key-btn-active' : 'p-button-outlined p-button-secondary'}`} onClick={() => { this.props.onClick(2) }} label='2' />
            <Button className={`key-btn ${this.props.value == '3' ? 'key-btn-active' : 'p-button-outlined p-button-secondary'}`} onClick={() => { this.props.onClick(3) }} label='3' />
            <Button className={`key-btn ${this.props.value == '4' ? 'key-btn-active' : 'p-button-outlined p-button-secondary'}`} onClick={() => { this.props.onClick(4) }} label='4' />
            <Button className={`key-btn ${this.props.value == '5' ? 'key-btn-active' : 'p-button-outlined p-button-secondary'}`} onClick={() => { this.props.onClick(5) }} label='5' />
            <Button className={`key-btn ${this.props.value == '6' ? 'key-btn-active' : 'p-button-outlined p-button-secondary'}`} onClick={() => { this.props.onClick(6) }} label='6' />
            <Button className={`key-btn ${this.props.value == '7' ? 'key-btn-active' : 'p-button-outlined p-button-secondary'}`} onClick={() => { this.props.onClick(7) }} label='7' />
            <Button className={`key-btn ${this.props.value == '8' ? 'key-btn-active' : 'p-button-outlined p-button-secondary'}`} onClick={() => { this.props.onClick(8) }} label='8' />
            <Button className={`key-btn ${this.props.value == '9' ? 'key-btn-active' : 'p-button-outlined p-button-secondary'}`} onClick={() => { this.props.onClick(9) }} label='9' />
        </div>


        )
    }
}
