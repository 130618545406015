import * as ActionTypes from './../actionTypes';



export default (state={}, action) =>{

    switch(action.type){
        case ActionTypes.REQUEST_BRANCH:
                
            return {
                ...state,
                loading:true,
                error:false,
                errorInfo:null
            }
        
        case ActionTypes.RECEIVED_BRANCH:

            return {
                ...state,
                loading:false,
                response:action.res,
                error:false,
                errorInfo:null,
                etag: action.etag
            }

        case ActionTypes.ERROR_BRANCH:
            return{
                ...state,
                loading:false,
                error:true,
                errorInfo:action.err
            }
        case ActionTypes.SELECTED_COURSE_FROM_BRANCH:
            return {
                ...state,
                selectedBranch: action.selectedBranch
            }
        case ActionTypes.SELECT_SECTION_FROM_COURSE:
            return {
                ...state,
                selectedCourse: action.selectedCourse
            }

        case ActionTypes.CLEAR_BRANCH:
            return {}   
        default: return state;

    }
} 