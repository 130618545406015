import * as ActionType from "../actionTypes";

export default (state = {}, action) => {
  switch(action.type){
    case ActionType.REQUEST_EDIT_EXAMINATION_DETAILS :

        return{
            ...state,
            loading:true,
            errorInfo:null,
            error:false
            
            }
    case ActionType.RECEIVED_EDIT_EXAMINATION_DETAILS :
        return{
            ...state,
            loading:false,
            errorInfo:null,
            error:false,
            response:action.res

        }
    case ActionType.ERROR_EDIT_EXAMINATION_DETAILS :
        return{
            ...state,
            loading:false,
            errorInfo:action.err,
            error:true
        }
    case ActionType.CLEAR_EDIT_EXAMINATION_DETAILS :
        return{
        }
    default : 
      return state    

  }
}