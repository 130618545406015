import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { Calendar } from 'primereact/calendar';

import React, { Component } from 'react'
import { getFormFields, isFormValid, onTextChange } from '../../../utile/formHelper';
import { trimObj } from '../../../utile';
import LoadingComponent from '../../loadingComponent';
import Service from '../../../services';
import moment from 'moment';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';




const formFields = [
    {
        "Type": "Text",
        "Label": "Start Date",
        "FieldName": "startDate",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "End Date",
        "FieldName": "endDate",
        "Required": true
    }

]

export default class CreateAcademicYear extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields);
        this.state = {
            year: this.props.editData ? this.props.editData : this.formFields.data,
            formValidations: this.formFields.formValidations,
            isLoading: false

        }

        this.service = new Service()
    }


    footer = () => {
        return (<div>
            <Button label="Cancel" className='p-button-outlined' onClick={this.props.onClose} />
            <Button label={`${this.props.editData ? 'Update' : 'Add'}`} className='ma-m-lr10' onClick={this.onSubmitClick} />

        </div>)
    }

    onSubmitClick = () => {
        const formStatus = isFormValid(formFields, this.formFields.formValidations, trimObj(this.state.year));
        
        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        } else if (new Date(this.state.year.startDate).getTime() > new Date(this.state.year.endDate).getTime()) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'End date should be greater than the start date.', life: 5000 });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {

            let startDate = new Date(this.state.year.startDate)
            let endDate = new Date(this.state.year.endDate)
            let startYear = startDate.getFullYear()
            let endYear = endDate.getFullYear()
            let body = this.state.year
            let academicCode = `${startYear}-${endYear}`;
            body.academicCode = academicCode;


            let academicR = this.props.academicYearsData.find(a => a.academicCode == academicCode)
            if (academicR && academicR._id != this.props.editData?._id) {
                return this.toast.show({ severity: 'error', summary: 'Duplicate academic years', detail: 'Already academic year saved with current selected years.', life: 5000 });
            }




            if (this.props.editData) {
                this.setState({
                    isLoading: true
                })
                let url = `${baseUrlAdmin}/academic-years`

                this.service.put(url, body, true).then(res => {
                    if (res?.status && res?.res?.status) {
                        // console.log(res, 'resy');
                        this.setState({
                            isLoading: false,
                        }, () => this.props.onSaveOrUpdate(true))

                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(err => {
                    console.log(err, 'err')
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
                })

            } else {
                this.setState({
                    isLoading: true
                })
                let url = `${baseUrlAdmin}/academic-years`

                this.service.post(url, body, true).then(res => {
                    if (res?.status && res?.res?.status) {
                        // console.log(res, 'resy');
                        this.setState({
                            isLoading: false,
                        }, () => this.props.onSaveOrUpdate())
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(err => {
                    console.log(err, 'err')
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
                })
            }
        }

    }



    render() {
        const { formValidations, year } = this.state
        return (
            <Dialog
                draggable={false}
                blockScroll={true}
                closeOnEscape={true}
                className='ma-alert-box'
                dismissableMask={true}
                closable={false}
                visible={true}
                style={{ maxWidth: '30%', minWidth: '30%' }}
                footer={this.footer}
                header={`${this.props.editData ? 'Update' : 'Add'} Academic Year`}
            >
                <div className="p-grid subject-add-dialog">
                    <div className="p-col-4 p-md-4 p-lg-4">
                        <p className='text-label'>Start Date<span className='ma-required'>*</span></p>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                        <Calendar showIcon
                            value={year.startDate ? new Date(year.startDate) : null}
                            onChange={(e) => { onTextChange(new Date(e.value).toString(), 'startDate', this, formFields, year, formValidations, 'year', 'formValidations') }}
                        />
                        {formValidations && !formValidations.fields['startDate'].isValid && <p className="p-error">{formValidations.fields['startDate'].errorMsg}</p>}
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-4">
                        <p className='text-label'>End Date<span className='ma-required'>*</span></p>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                        <Calendar showIcon
                            minDate={year.startDate ? new Date(year.startDate) : null}
                            value={year.endDate ? new Date(year.endDate) : null}
                            onChange={(e) => { onTextChange(new Date(e.value).toString(), 'endDate', this, formFields, year, formValidations, 'year', 'formValidations') }}
                        />
                        {formValidations && !formValidations.fields['endDate'].isValid && <p className="p-error">{formValidations.fields['endDate'].errorMsg}</p>}
                    </div>
                </div>

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </Dialog>

        )
    }
}
