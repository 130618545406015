import React, { Component } from 'react';
import Service from './../service';
import AttachmentPlayer from './attachmentPlayer';


class AttachmentsU extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attachments: this.props.attachments,
            showAttachment: false,
            selectedAttachment: null
        }
        this.service = new Service();
    }

    showAttachment = (a) => {
        if (a.uri) {
            this.setState({
                showAttachment: true,
                selectedAttachment: a
            });
        }
    }


    getControl = (attachment) => {



        if (attachment.type && attachment.uri) {

            if (attachment.type.includes('image')) {
                return <div className="ma-attach-wrapper">
                    <img className="ma-attch-image" src={attachment.uri} />
                    <div class="ma-top-right">
                        <span onClick={() => { this.props.removeAttachment(attachment) }}>
                            <i className="pi pi-times"></i>
                        </span>
                    </div>
                </div>
            } else if (attachment.type.includes('video')) {
                return <div className="ma-attach-wrapper">
                    <video className="ma-attch-video" width="100%" height="100%" autoPlay={false} controls
                        controlsList="nodownload"
                    >
                        <source src={attachment.uri} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div class="ma-top-right">
                        <span onClick={() => { this.props.removeAttachment(attachment) }}>
                            <i className="pi pi-times"></i>
                        </span>
                    </div>
                </div>

            } else if (attachment.type.includes('audio')) {
                return <div className="ma-attach-wrapper">
                    <audio className="ma-attch-audio" width="80%" height="100%" autoPlay={false} controls
                        controlsList="nodownload"
                    >
                        <source src={attachment.uri} />
                        Your browser does not support the audio tag.
                    </audio>
                    <div class="ma-top-right">

                        <span onClick={() => { this.props.removeAttachment(attachment) }}>
                            <i className="pi pi-times"></i>
                        </span>
                    </div>
                </div>

            } else if (attachment.type.includes('pdf')) {
                return <>
                    {this.getView(attachment)}
                </>

            } else {
                return <>
                    Not Sopported  <span onClick={() => { this.props.removeAttachment(attachment) }}>
                        <i className="pi pi-times"></i>
                    </span>
                </>
            }
        } else {
            return <>{this.getView(attachment)} </>
        }


    }

    getView = (attachment) => {
        return <div className="ma-attachment">
            <div style={{ width: '20px', float: 'right', marginTop: '5px' }}>

                {
                    attachment.uri && <span onClick={() => { this.props.removeAttachment(attachment) }}>
                        <i className="pi pi-times"></i>
                    </span>
                }

            </div>
            <div style={{ marginRight: '40px' }}>
                <div style={{ width: '100%', overflow: 'hidden' }} onClick={() => { this.showAttachment(attachment) }}>
                    <div style={{ width: '20px', float: 'left' }}>
                        <img src="./images/PDF_Doc_icon.svg" className="ma-attach-icon" />
                        {/* <img src="./images/Voice_recorder.svg" className="ma-attach-icon" onClick={() => { }} /> */}
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <p  > {attachment.name}   {attachment.progressStatus < 100 && <></>
                            // <LinearProgress variant="determinate" value={attachment.progressStatus} />
                        } </p>
                    </div>
                </div>
            </div>
        </div>
    }



    render() {
        const { attachments } = this.props;
        return (
            <>
                {
                    attachments && attachments.length ? attachments.map((attachment, index) => {
                        return <div key={index} id={attachment._id}> {this.getControl(attachment)} </div>
                    }) : <></>
                }
                {this.state.showAttachment && <AttachmentPlayer onCloseDialog={() => { this.setState({ showAttachment: false }) }} attachment={this.state.selectedAttachment} />}
            </>
        )
    }
}

export default AttachmentsU;