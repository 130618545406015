import React from 'react';
import Service from './../../../services/index';
import jwt from 'jwt-simple';
import { StaticConstants } from '../../../constants';
import { getServerTime } from '../../../utile';

class ErudexVideo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentInfo: this.props.contentInfo,
            playerInfo: null
        };
    }


    getPlayerInfo = (contentInfo) => {
        let payload = {
            apiKey: StaticConstants.ERUDEX_API_KEY,
            exp: getServerTime().getTime() + 100000
        };

        let authToken = jwt.encode(payload, StaticConstants.ERUDEX_SECRET_KEY, 'HS256');
        const service = new Service();
        service.postWithCustonAuthToken(StaticConstants.ERUDEX_CONTENT_URL, { file: contentInfo.contentFile }, true, authToken).then((data) => {
            if (data && data.status) {
                this.setState({
                    playerInfo: data.res
                });
            }
        });
    }

    componentDidMount() {
        let contentInfo = this.state.contentInfo;
        if (contentInfo) {
            this.getPlayerInfo(contentInfo);
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.state.contentInfo !== newProps.contentInfo) {
            this.setState({
                contentInfo: newProps.contentInfo
            }, this.getPlayerInfo(newProps.contentInfo));
        }
    }

    render() {
        return (
            <>
                {this.state.playerInfo && <video width="100%" height="100%" autoPlay controls>
                    <source src={this.state.playerInfo.contentUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                    </video>
                }
            </>
        );
    }
}


export default ErudexVideo;
