import * as ActionTypes from '../actionTypes';



export default (state = {}, action) => {
    switch(action.type) {
        case(ActionTypes.REQUEST_SIGN_IN): 
            return {
                ...state,
                loading: true,
                error: false,
                reqData: action.data
            }
        case(ActionTypes.RECEIVED_SIGN_IN):
            return {
                ...state,
                loading: false,
                error: false,
                successData: action.data
            }
        case(ActionTypes.ERROR_SIGN_IN):
            return {
                ...state,
                loading: false,
                error: true,
                errMsg: action.err
            }
        case(ActionTypes.CLEAR_SIGN_IN_DATA):
            return {}
        default: return state
    }    
}