import { Tooltip } from 'primereact/tooltip';
import './styles.scss';

function ValidateOTP(props) {

    return (
        <>
            <div style={{ display: 'flex' }} className='validate-otp'>{props.otpVerified ? <p className='ma-no-pm otp-verify-txt'>{props.verifiedText}</p> : <>
                <input type="text" disabled={props.otpVerified} value={props.value} className='input-text' onChange={props.onChange} />
                {!props.otpVerified && <span className='right ma-pointer  action-text' onClick={props.onActionClick}>{props.rightText}</span>}</>}
            </div>

        </>
    );
}



export default ValidateOTP;