import * as ActionType from "../actionTypes";

export default (state = {}, action) => {
  switch(action.type){
    case ActionType.REQUEST_DELETE_QUESTION :

        return{
            ...state,
            loading:true,
            error:false,
            errorInfo:null,
            response:null
            }
    case ActionType.RECEIVED_DELETE_QUESTION :
        return{
            ...state,
            loading:false,
            response:action.res,
            error:false,
            errorInfo:null
        }
    case ActionType.ERROR_DELETE_QUESTION :
        return{
            ...state,
            loading:false,
            error:true,
            errorInfo:action.err,
            response:null
        }
    case ActionType.CLEAR_DELETE_QUESTION :
        return{
            state: {}
        }
    default : 
      return state    

  }
}