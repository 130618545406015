import React from 'react';
import { Toolbar } from 'primereact/toolbar';
import XLSX from 'xlsx';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { BreadCrumb } from 'primereact/breadcrumb';
import { DataTable } from 'primereact/datatable';
import moment from "moment";
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {
    getBoardsData
} from './../../../store/actions';
import Navbar from '../../navbar';
import Service from '../../../services';
import './styles.scss'
import { BasicLazyParams } from '../../../utile';
import BulkUploadDetails from './bulkUploadDetails';
import Authentication from './../../session';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../../store/apiConstants';
const home = { icon: 'pi pi-home' }
const items = [
    { label: 'Academics' },
    { label: 'Yearly Planner' },
    { label: 'Bulk Planner Upload', className: 'ma-active-item' }
];

/* list of supported file types */
const SheetJSFT = ['xlsx', 'xlsb', 'xlsm', 'xls', 'csv']
    .map(function (x) {
        return '.' + x;
    })
    .join(',');

/* generate an array of column objects */
const make_cols = (refstr) => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
};

const SampleBulkUplodData = [];
class BulkUpload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            openSnackBar: false,
            yearlyPlanner: [


            ],
            showMissedListTable: false,
            showUploadBtn: false,
            showLoader: false,
            transactionId: null,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            bulkSampleData: [],
            boardId: null,
            classId: null,
            groupId: null,
            boardName: null,
            className: null,
            groupName: null,
            boards: [],
            classes: [],
            groups: [],

            totalRecords: 0,
            globalSearch: '',
        };
        this.service = new Service();
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };
    handleClickOpenDelete = () => {
        this.setState({ openDelete: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleDeleteClose = () => {
        this.setState({ openDelete: false });
    };


    getBulkUploadTransactions = () => {
        this.setState({
            isLoading: true,
            organizations: [],
            totalRecords: 0
        });

        let thisObj = this;
        let { lazyParams, globalSearch } = this.state;

        let url = `${baseUrlAdmin}/yearlyplanner/allbulk?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.service.get(url, true).then(res => {
            if (res && res.status && res.res.status) {
                this.setState((prevState) => {
                    return {
                        bulkTransaction: res.res.bulkTransaction,
                        totalRecords: res.res.totalRecords,
                        isLoading: false
                    };
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            thisObj.setState({
                isLoading: false
            });
            console.log(e);
        })
    }

    componentDidMount = () => {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }

        this.getBulkUploadTransactions();
    };





    handleFile = (file) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        let data = [];
        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            data = XLSX.utils.sheet_to_json(ws, { raw: false });
            this.setState({ data: data, cols: make_cols(ws['!ref']) });

            const newJsonSheet = [];
            for (let i = 0; i < data.length; i++) {
                let excelRowData = {
                    ...data[i],
                    __rowNum__: undefined
                };

                excelRowData = JSON.parse(JSON.stringify(excelRowData));

                newJsonSheet.push(excelRowData);
            }

            const yearlyPlanner = newJsonSheet;
            this.setState({ yearlyPlanner });
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    };

    handleChange = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            this.setState({ fileSelected: files[0]['name'] });
            this.handleFile(files[0]);
            e.target.value = null;
        }
    };
    exportCSV = () => {
        this.dt.exportCSV();
    }

    exportCSV2 = () => {

        if (this.state.bulkSampleData && this.state.bulkSampleData.length) {
            this.dt2.exportCSV();
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'No data to download', life: 5000 });
        }


    }

    onPage = (event) => {
        this.setState({ lazyParams: event }, this.getBulkUploadTransactions);
    }

    refresh = (event) => {
        this.setState({ lazyParams: BasicLazyParams }, this.getBulkUploadTransactions);
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getBulkUploadTransactions);
    }


    uploadBulkUsers = () => {
        const payload = {
            //   user_id: this.props.userId,
            yearlyPlanner: this.state.yearlyPlanner
        };
        this.setState({
            yearlyPlanner: [],
            fileSelected: null
        }, () => {
            this.uplodaPlanner(payload);
        });
    };


    uplodaPlanner = (payload) => {
        this.setState({
            isLoading: true
        })
        const url = `${baseUrlForLongRunningTask}/yearlyplanner/uploadNewPlanner`;
        this.service.post(url, payload, true).then((data) => {

            if (data && data.res && data.res.status) {
                this.setState({
                    isLoading: false
                }, () => {
                    this.getBulkUploadTransactions();
                });
                this.toast.show({ severity: 'success', summary: 'success', detail: `Transacion initiated, please check reference no. ${data.res.transaction_id}`, life: 15000 });
            } else {
                this.setState({
                    isLoading: false
                })
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            })
        });

    };



    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [],
            classId: null, groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
            boardName: selectedBoard.boardName,
            resourseOrigin: selectedBoard.isBase ? 'upload' : null

        });

    };

    onChangeClass = (classId) => {
        this.setState({

            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                className: selectedClass.className,
                groupName: _groups[0].label,
                groupId: _groups[0].value
            }, () => {
                this.getSamplePlanner();
            });
        }
    };

    getSamplePlanner = () => {
        const { boardId, classId, groupId, boardName, className, groupName } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })
            const url = `${baseUrlAdmin}/yearlyplanner/downloadsample`;
            this.service.post(url, { boardId, classId, groupId, boardName, className, groupName }, true).then((data) => {

                if (data && data.res && data.res.status) {
                    this.setState({
                        bulkSampleData: data.res.data,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                })
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    };


    render() {
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >Transactions List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                        value={this.state.globalSearch}

                        onChange={this.onGlobalSearch} />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
            </div>
        );

        const header2 = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >Sample Bulk Upload Sheet</h2>
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV2}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
            </div>
        );

        return (
            <div className='bulk-upload'>
                <Navbar >
                    <Authorizer permId={PERMISSIONS.USER_BULK_USERS_UPLOAD} >
                        <div className=''>
                            <div className="p-grid ma-toolbar">
                                <div className="p-col-12 p-md-1 ma-no-p">
                                </div>
                                <div className="p-col-12 p-md-11 ma-no-pm">
                                    <Toolbar className="ma-toolbard" left={() => {
                                        return (
                                            <React.Fragment>
                                                <BreadCrumb model={items} home={home} />
                                            </React.Fragment>
                                        )
                                    }}
                                        right={() => {
                                            return (
                                                <React.Fragment>
                                                    <h2 className='ma-tab-title'>Bulk Planner Upload</h2>
                                                </React.Fragment>
                                            )
                                        }}></Toolbar>
                                </div>
                            </div>
                            <div className="ask-a-doubt ma-main">
                                <p className='spread-title'>Planner Spreadsheet</p>
                                <InputText
                                    className='p-inputtext-style1'
                                    value={this.state.fileSelected} disabled />
                                <label htmlFor="file" className='select-file'>Select File</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="file"
                                    hidden={true}
                                    accept={SheetJSFT}
                                    onChange={this.handleChange}
                                />
                                <div className='ma-clearFix'></div>

                                <Button label="Upload data" className='ma-mt10'
                                    disabled={!this.state.yearlyPlanner.length}
                                    onClick={() => {
                                        if (this.state.yearlyPlanner && this.state.yearlyPlanner.length) {
                                            this.uploadBulkUsers();
                                        }
                                    }}
                                    icon="pi pi-cloud-upload"
                                />

                                <Button label="Sample Sheet"
                                    className='p-button-outlined ma-mt10 ma-ml10 ma-bg-white'
                                    onClick={() => {
                                        this.setState({
                                            isShowSampleFormat: true
                                        })
                                    }}
                                />
                            </div>

                            {(this.state.bulkTransaction && this.state.bulkTransaction.length) ? (
                                <Toolbar className="ma-toolbar"
                                    right={() => {
                                        return (
                                            <Button label="Refresh" className='ma-mlr30'
                                                icon="pi pi-refresh"
                                                onClick={() => {
                                                    this.refresh()
                                                }} />
                                        )
                                    }}></Toolbar>
                            ) : null}
                            <div className="p-grid ma-toolbar">
                                <div className="card datatable-crud-demo ma-m30">
                                    <DataTable ref={(el) => this.dt = el}
                                        value={this.state.bulkTransaction}
                                        dataKey="id"
                                        paginator
                                        lazy
                                        onPage={this.onPage}
                                        first={this.state.lazyParams.first}
                                        last={this.state.totalRecords}
                                        rows={this.state.lazyParams.rows}
                                        totalRecords={this.state.totalRecords}
                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                        header={header}>
                                        <Column filterField='transaction_id' field="transaction_id" header="Transaction Id" />
                                        <Column filterField='bulkStatus' field="bulkStatus" header="Status" />
                                        <Column filterField='statusMessage' field="statusMessage" header="Status Message" />
                                        <Column field="uploadedAt" header="Date" body={(rowData) => {
                                            return (<>
                                                <span>{moment(rowData.uploadedAt).format('lll')}</span>
                                            </>)
                                        }} />
                                    </DataTable>
                                </div>
                            </div>

                        </div>
                        {this.props.isLoading || this.state.isLoading ? <LoadingComponent /> : null}
                        <Dialog
                            draggable={false}
                            closeOnEscape={true}
                            className='ma-alert-box'
                            blockScroll={true}
                            dismissableMask={false}
                            closable={true}

                            header="Download Sample Sheet"
                            style={{ minHeight: '90vh', minWidth: '90%', maxWidth: '90%' }}
                            visible={this.state.isShowSampleFormat}
                            onHide={() => this.setState({ isShowSampleFormat: false })}>
                            <div>

                                <Dropdown value={this.state.boardId}
                                    className='ma-w200'
                                    //options={this.state.boards}
                                    options={this.props.boards}
                                    optionLabel="boardName"
                                    optionValue="boardId"
                                    onChange={(e) => this.onChangeBoard(e.value)}
                                    placeholder="Select Board" />
                                <span className='ma-required-mark '>*</span>
                                <Dropdown value={this.state.classId}
                                    options={this.state.classes}
                                    className='ma-w200'
                                    onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                                <span className='ma-required-mark '>*</span>
                                {(this.state.bulkSampleData && this.state.bulkSampleData.length) ? <>
                                    <div className="card datatable-crud-demo ma-m30 fetch-q-tabled">
                                        <DataTable ref={(el) => this.dt2 = el}
                                            value={this.state.bulkSampleData}
                                            dataKey="id"
                                            scrollable
                                            responsiveLayout="scroll"
                                            paginator
                                            rows={100}

                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                            header={header2}
                                        >
                                            <Column headerClassName='difficulty' field="title" header="title" />
                                            <Column headerClassName='difficulty' field="plannerStartDate" header="plannerStartDate" />
                                            <Column headerClassName='difficulty' field="plannerEndDate" header="plannerEndDate" />
                                            <Column headerClassName='difficulty' field="description" header="description" />
                                            <Column headerClassName='difficulty' field="boardId" header="boardId" />
                                            <Column headerClassName='difficulty' field="classId" header="classId" />
                                            <Column headerClassName='difficulty' field="groupId" header="groupId" />
                                            <Column headerClassName='difficulty' field="boardName" header="boardName" />
                                            <Column headerClassName='difficulty' field="className" header="className" />
                                            <Column headerClassName='difficulty' field="groupName" header="groupName" />
                                            <Column headerClassName='difficulty' field="subjectName" header="subjectName" />
                                            <Column headerClassName='difficulty' field="subjectId" header="subjectId" />
                                            <Column headerClassName='difficulty' field="chapterName" header="chapterName" />
                                            <Column headerClassName='difficulty' field="chapterId" header="chapterId" />
                                            <Column headerClassName='difficulty' field="startDate" header="startDate" />
                                            <Column headerClassName='difficulty' field="endDate" header="endDate" />
                                            <Column headerClassName='difficulty' field="preKnowledge" header="preKnowledge" />
                                            <Column headerClassName='difficulty' field="learningObjective" header="learningObjective" />
                                            <Column headerClassName='difficulty' field="learningOutcome" header="learningOutcome" />
                                        </DataTable>
                                    </div>
                                </> : null}
                            </div>

                        </Dialog>
                    </Authorizer>
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                    {this.state.isLoading && <LoadingComponent />}
                </Navbar>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
});

export default connect(mapStateToProps, {
    getBoardsData,
})(Authentication(BulkUpload));
