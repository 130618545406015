import React, { Component } from 'react'
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';




export default class DownloadAanalytics extends Component {
    render() {
        const { allGraphs, questionWiseAllGraphs, userWiseReport } = this.props;
        //const allGraphs =[];
        console.log(this.props, 'ddddd')
        let count = 0;
        return (
            <div className='graph-view download-graph'>{
                userWiseReport && <>
                    <h3 className='ma-poppins'>Exam Details</h3>

                    <p className='ma-poppins'>Exam Name : <b>{userWiseReport.examName}</b>
                        <span className='ma-ml30 ma-poppins'>Type :<b>{userWiseReport.examType}</b></span>
                    </p>
                    <p className='ma-poppins'>Total Marks :<b>{userWiseReport.totalExamMarks} </b>
                        <span className='ma-ml30 ma-poppins'>Total Questions :<b>{userWiseReport.totalNoOfQuestions}</b></span>
                    </p>
                    <p className='ma-poppins'>Board Name :<b>{userWiseReport.boardName}</b></p>
                    <p className='ma-poppins'>Class Name :<b>{userWiseReport.className}</b></p>


                    <h3 className='h-heading ma-poppins ma-mt30'>Overall Test Wise Analytics</h3>
                    {allGraphs?.map((g, index) => {
                        return <div key={g.key} >
                            {(index && index % 2 !== 0) ? <div className='page-break ' /> : ''}
                            <div className='each-subject' >
                                <Toolbar className="ma-toolbard ma-mt30 ma-pt30"
                                    style={{ marginLeft: "40px", marginRight: "40px" }}
                                    left={() => {
                                        return < >
                                            <h2>{g.key}</h2>
                                        </>
                                    }}
                                    right={() => {
                                        return < >

                                            {
                                                g.totalStats && g.totalStats['Highest Marks'] != undefined ? <>
                                                    <div className='exam-stats highest'>
                                                        <p className='label'>Highest Marks</p>
                                                        <p className='marks'>{g.totalStats['Highest Marks']}</p>
                                                    </div>
                                                </> : <></>

                                            }
                                            {
                                                g.totalStats && g.totalStats['Average Marks'] != undefined ? <>
                                                    <div className='exam-stats average'>
                                                        <p className='label'>Average Marks</p>
                                                        <p className='marks'>{g.totalStats['Average Marks']}</p>
                                                    </div>
                                                </> : <></>

                                            }
                                            {
                                                g.totalStats && g.totalStats['User Marks'] != undefined ? <>
                                                    <div className='exam-stats user'>
                                                        <p className='label'>User Marks</p>
                                                        <p className='marks'>{g.totalStats['User Marks']}</p>
                                                    </div>
                                                </> : <></>

                                            }


                                        </>
                                    }}
                                ></Toolbar>
                                <div className='p-grid sp-no-pm highlights-tabs '>

                                </div>


                                <div className='p-grid analytics-graph '>
                                    <div className="p-col-12 p-md-6 ">
                                        <div className='hours-graph-pie' >
                                            <div style={{ height: '100%', width: '100%' }}>
                                                <ResponsivePie
                                                    enableArcLinkLabels={false}
                                                    data={g.pieData}
                                                    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                                    innerRadius={0.6}
                                                    padAngle={0}
                                                    cornerRadius={0}
                                                    activeOuterRadiusOffset={8}
                                                    borderWidth={0}
                                                    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                                    // arcLinkLabelsSkipAngle={10}
                                                    //enableSliceLabels={false}
                                                    // enableRadialLabels={false}
                                                    // arcLinkLabelsTextColor="#333333"
                                                    colors={['#20C2C8', '#FF8373', '#FFDA83', '#A3A0FB']}
                                                    // arcLinkLabelsThickness={2}
                                                    // arcLinkLabelsColor={{ from: 'color' }}
                                                    // arcLabelsSkipAngle={10}
                                                    // arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}

                                                    valueFormat={value =>
                                                        `${value} %`
                                                    }
                                                    legends={[
                                                        {
                                                            anchor: 'bottom',
                                                            direction: 'row',
                                                            justify: false,
                                                            translateX: 0,
                                                            translateY: 56,
                                                            itemsSpacing: 0,
                                                            itemWidth: 70,
                                                            itemHeight: 16,
                                                            itemTextColor: '#999',
                                                            itemDirection: 'left-to-right',
                                                            itemOpacity: 1,
                                                            symbolSize: 16,
                                                            symbolShape: 'circle',
                                                            effects: [
                                                                {
                                                                    on: 'hover',
                                                                    style: {
                                                                        itemTextColor: '#000'
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]}
                                                //  layers={[ CenteredMetric]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6 ">
                                        <div className='hours-graph-bar'>
                                            <ResponsiveBar
                                                data={g.barData}
                                                keys={g.barData.map(a => a.key)}
                                                indexBy="key"
                                                margin={{ top: 50, right: 330, bottom: 80, left: 60 }}
                                                padding={0.7}
                                                valueScale={{ type: 'linear' }}
                                                indexScale={{ type: 'band', round: true }}
                                                borderRadius={6}
                                                enableLabel={true}
                                                colors={['#20C2C8', '#FFDA83', '#FF8373', '#A3A0FB']}
                                                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                defs={[
                                                    {
                                                        id: 'dots',
                                                        type: 'patternDots',
                                                        background: 'inherit',
                                                        color: '#38bcb2',
                                                        size: 4,
                                                        padding: 1,
                                                        stagger: true
                                                    },
                                                    {
                                                        id: 'lines',
                                                        type: 'patternLines',
                                                        background: 'inherit',
                                                        color: '#eed312',
                                                        rotation: -45,
                                                        lineWidth: 6,
                                                        spacing: 10
                                                    }
                                                ]}
                                                fill={[
                                                    {
                                                        match: {
                                                            id: 'fries'
                                                        },
                                                        id: 'dots'
                                                    },
                                                    {
                                                        match: {
                                                            id: 'sandwich'
                                                        },
                                                        id: 'lines'
                                                    }
                                                ]}
                                                axisTop={null}
                                                axisRight={null}
                                                // axisBottom={{
                                                //     tickSize: 5,
                                                //     tickPadding: 5,
                                                //     tickRotation: 0,
                                                //     legend: 'country',
                                                //     legendPosition: 'middle',
                                                //     legendOffset: 32
                                                // }}
                                                axisLeft={{
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 0,
                                                    legend: 'Exam Marks',
                                                    legendPosition: 'middle',
                                                    legendOffset: -40,
                                                    max: g.maxValue,
                                                }}
                                                maxValue={g.maxValue}
                                                // labelSkipWidth={12}
                                                // labelSkipHeight={12}
                                                // labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                legends={[
                                                    {
                                                        anchor: 'bottom',
                                                        direction: 'row',
                                                        justify: false,
                                                        translateX: 0,
                                                        translateY: 56,
                                                        itemsSpacing: 0,
                                                        itemWidth: 110,
                                                        itemHeight: 16,
                                                        itemTextColor: '#999',
                                                        itemDirection: 'left-to-right',
                                                        itemOpacity: 1,
                                                        symbolSize: 16,
                                                        symbolShape: 'circle',
                                                        effects: [
                                                            {
                                                                on: 'hover',
                                                                style: {
                                                                    itemTextColor: '#000'
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]}
                                                role="application"
                                                ariaLabel="Nivo bar chart demo"
                                            //   barAriaLabel={function (e) { return e.id + ": " + e.formattedValue + " in country: " + e.indexValue }}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    })}

                    <div className='page-break ' />
                    <h3 className='h-heading ma-mt20 ma-poppins'>Questions And Subject Wise Analysis</h3>
                    {
                        questionWiseAllGraphs?.map((g, index) => {

                            return <div key={g.key} >
                                {/* {(count && count % 2 === 0) ? <div className='page-break ' /> : ''} */}
                                <div className='each-subject' >
                                    <div className='ma-mt10'>
                                        <h2 className='h2-s1'>{g.subjectName}</h2>

                                        {
                                            g.graphTabs?.map((gt, ind) => {
                                                let temp = count;
                                                count += 1;
                                                console.log(temp, count, 'c')
                                                return <div key={ind}>

                                                    <div className='graph-c' key={ind}>
                                                        <div className="">

                                                            <Toolbar className="ma-toolbard"
                                                                left={() => {
                                                                    return <>
                                                                        <span className='ma-label-s3'>Q No: {gt.label}</span>
                                                                    </>
                                                                }}
                                                            ></Toolbar>
                                                        </div>
                                                        <div className='questions-graph'>
                                                            {(gt.values) && <>
                                                                <ResponsiveBar

                                                                    data={gt.values}
                                                                    keys={['Correct Perfect Time', 'Correct Over Time']}
                                                                    indexBy="qNo"
                                                                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                                                    valueScale={{ type: 'linear' }}
                                                                    indexScale={{ type: 'band', round: true }}
                                                                    padding={0.3}
                                                                    borderRadius={4}
                                                                    colors={['var(--theme-70)', 'var(--theme-85)', '#FFDA83', '#A3A0FB']}

                                                                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                                    axisTop={null}
                                                                    axisRight={null}
                                                                    axisBottom={{
                                                                        tickSize: 5,
                                                                        tickPadding: 5,
                                                                        tickRotation: 0,
                                                                        legend: 'Question No',
                                                                        legendPosition: 'middle',
                                                                        legendOffset: 32
                                                                    }}
                                                                    axisLeft={{
                                                                        tickSize: 5,
                                                                        tickPadding: 5,
                                                                        tickRotation: 0,
                                                                        legend: 'Percentage (%)',
                                                                        legendPosition: 'middle',
                                                                        legendOffset: -40
                                                                    }}
                                                                    labelSkipWidth={12}
                                                                    labelSkipHeight={12}
                                                                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                                    legends={[
                                                                        {
                                                                            dataFrom: 'keys',
                                                                            anchor: 'bottom-right',
                                                                            direction: 'row',
                                                                            justify: false,
                                                                            translateX: 89,
                                                                            translateY: 50,
                                                                            itemsSpacing: 9,
                                                                            itemWidth: 130,
                                                                            itemHeight: 29,
                                                                            itemDirection: 'left-to-right',
                                                                            itemOpacity: 0.85,
                                                                            symbolSize: 20,
                                                                            effects: [
                                                                                {
                                                                                    on: 'hover',
                                                                                    style: {
                                                                                        itemOpacity: 1
                                                                                    }
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]}
                                                                    animate={true}
                                                                    motionStiffness={70}
                                                                    motionDamping={15}
                                                                // onClick={(a) => { this.showQuestion(a.data.questionId) }}
                                                                />
                                                            </>
                                                            }
                                                        </div>

                                                    </div>
                                                    {(count && count % 2 === 0) ? <div className='page-break ' /> : ''}

                                                </div>
                                            })
                                        }


                                    </div>
                                </div>
                            </div>

                        })
                    }
                </>
            }
            </div>
        )
    }
}
