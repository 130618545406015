import React from 'react';
import { Toolbar } from 'primereact/toolbar';
import XLSX from 'xlsx';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { BreadCrumb } from 'primereact/breadcrumb';
import { DataTable } from 'primereact/datatable';
import moment from "moment";
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {
    getBulkUploadTransactionDetails,
    getBulkUploadTransactions,
    saveBulkUploadUsers,
    clearBulkUserUploadData
} from './../../../store/actions';
import Navbar from '../../navbar';

import './styles.scss'
import { BasicLazyParams } from '../../../utile';
import BulkUploadDetails from './bulkUploadDetails';
import Authentication from './../../session';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { Dialog } from 'primereact/dialog';
const home = { icon: 'pi pi-home' }
const items = [
    { label: 'Management' },
    { label: 'User Management' },
    { label: 'Bulk User Upload', className: 'ma-active-item' }
];

/* list of supported file types */
const SheetJSFT = ['xlsx', 'xlsb', 'xlsm', 'xls', 'csv']
    .map(function (x) {
        return '.' + x;
    })
    .join(',');

/* generate an array of column objects */
const make_cols = (refstr) => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
};

const SampleBulkUplodData = [];
class BulkUpload extends React.Component {
    state = {
        open: false,
        openSnackBar: false,
        users: [],
        showMissedListTable: false,
        showUploadBtn: false,
        showLoader: false,
        transactionId: null,
        totalRecords: 0,
        globalSearch: '',
        lazyParams: BasicLazyParams
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };
    handleClickOpenDelete = () => {
        this.setState({ openDelete: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleDeleteClose = () => {
        this.setState({ openDelete: false });
    };

    getBulkUploadTransactionsData = () => {
        this.props.getBulkUploadTransactions({ userId: this.props.userId }, { ...this.state.lazyParams, globalSearch: this.state.globalSearch });
    };

    componentDidMount = () => {
        this.getBulkUploadTransactionsData();
    };

    componentWillUnmount() {
        this.props.clearBulkUserUploadData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error) {
            if (this.props.error) {
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Failed to upload bulk users data', life: 5000 });
            }
        }

        if (prevProps.bulkUserUploadSaveResponse !== this.props.bulkUserUploadSaveResponse) {
            if (this.props.bulkUserUploadSaveResponse) {
                const transactionId = this.props.bulkUserUploadSaveResponse.transaction_id ? this.props.bulkUserUploadSaveResponse.transaction_id : '';
                this.toast.show({ severity: 'success', summary: 'Process is initiated', detail: `Bulk users upload process initiated, kindly refer the transaction ${transactionId} details in below table`, life: 10000 });
                this.getBulkUploadTransactionsData();
            }
        }
    }

    handleFile = (file) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        let data = [];
        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            data = XLSX.utils.sheet_to_json(ws, { raw: false });
            this.setState({ data: data, cols: make_cols(ws['!ref']) });

            const newJsonSheet = [];
            for (let i = 0; i < data.length; i++) {
                let excelRowData = {
                    ...data[i],
                    __rowNum__: undefined
                };

                excelRowData = JSON.parse(JSON.stringify(excelRowData));

                newJsonSheet.push(excelRowData);
            }

            const users = newJsonSheet.map((record) => ({
                ...record,
                mobile: `+91${record.mobile}`
            }));

            this.setState({ users });
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    };

    handleChange = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            this.setState({ fileSelected: files[0]['name'] });
            this.handleFile(files[0]);
            e.target.value = null;
        }
    };
    exportCSV = () => {
        this.dt.exportCSV();
    }
    exportCSV2 = () => {
        this.dt2.exportCSV();
    }

    onPage = (event) => {
        this.setState({ lazyParams: event }, this.getBulkUploadTransactionsData);
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getBulkUploadTransactionsData);
    }


    uploadBulkUsers = () => {
        const payload = {
            user_id: this.props.userId,
            users: this.state.users
        };
        this.setState({
            users: [],
            fileSelected: null,
            fileSelected: ''
        }, () => {
            this.props.saveBulkUploadUsers(payload);
        });
    };

    getTransactionDetails = (transactionId) => {

        this.setState({ transactionId });
        const payload = {
            userId: this.props.userId,
            transactionId
        };
        this.props.getBulkUploadTransactionDetails(payload)
    };




    render() {
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >Transactions List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                        value={this.state.globalSearch}

                        onChange={this.onGlobalSearch} />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <li><i data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" /> */}
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
            </div>
        );

        const header2 = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >Sample Bulk Upload Sheet</h2>
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV2}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
            </div>
        );

        return (
            <div className='bulk-upload'>
                <Navbar >
                    <Authorizer permId={PERMISSIONS.USER_BULK_USERS_UPLOAD} >
                        <div className=''>

                            <div className="p-grid ma-toolbar">
                                <div className="p-col-12 p-md-1 ma-no-p">
                                </div>
                                <div className="p-col-12 p-md-11 ma-no-pm">
                                    <Toolbar className="ma-toolbard" left={() => {
                                        return (
                                            <React.Fragment>
                                                <BreadCrumb model={items} home={home} />
                                            </React.Fragment>
                                        )
                                    }}
                                        right={() => {
                                            return (
                                                <React.Fragment>
                                                    <h2 className='ma-tab-title'>Bulk User Upload</h2>
                                                </React.Fragment>
                                            )
                                        }}></Toolbar>
                                </div>
                            </div>
                            <div className="ask-a-doubt ma-main">
                                <p className='spread-title'>Users Spreadsheet</p>
                                <InputText
                                    className='p-inputtext-style1'
                                    value={this.state.fileSelected} disabled />
                                {/* <span className='file-attach-name'>{this.state.fileSelected} </span> */}
                                <label htmlFor="file" className='select-file'>Select File</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="file"
                                    hidden={true}
                                    accept={SheetJSFT}
                                    onChange={this.handleChange}
                                    disabled={this.props.bulkUserUploadRequested}
                                />
                                <div className='ma-clearFix'></div>

                                <Button label="Upload data" className='ma-mt10'
                                    disabled={this.props.bulkUserUploadRequested}
                                    onClick={() => {
                                        if (this.state.users && this.state.users.length) {
                                            this.uploadBulkUsers();
                                        }
                                    }}
                                    icon="pi pi-cloud-upload"
                                />

                                <Button label="Sample Sheet"
                                    className='p-button-outlined ma-mt10 ma-ml10 ma-bg-white'
                                    disabled={this.props.bulkUserUploadRequested}
                                    onClick={() => {
                                        this.setState({
                                            isShowSampleFormat: true
                                        })
                                    }}
                                //  icon="pi pi-cloud-upload"
                                />
                            </div>

                            {(this.props.bulkUserUploadTransactions && this.props.bulkUserUploadTransactions.length) ? (
                                <Toolbar className="ma-toolbar"
                                    right={() => {
                                        return (
                                            <Button label="Refresh" className='ma-mlr30'
                                                icon="pi pi-refresh"
                                                onClick={() => {
                                                    this.getBulkUploadTransactionsData()
                                                }} />
                                        )
                                    }}></Toolbar>
                            ) : null}
                            <div className="p-grid ma-toolbar">
                                <div className="card datatable-crud-demo ma-m30">
                                    <DataTable ref={(el) => this.dt = el}
                                        //  lazy
                                        value={this.props.bulkUserUploadTransactions}
                                        // selection={this.state.selectedProducts}
                                        // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                                        dataKey="id"
                                        paginator
                                        lazy
                                        onPage={this.onPage}
                                        first={this.state.lazyParams.first}
                                        last={this.props.totalRecords}
                                        rows={this.state.lazyParams.rows}
                                        totalRecords={this.props.totalRecords}
                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                        // globalFilter={this.state.globalFilter}
                                        header={header}>
                                        <Column filterField='transaction_id' field="transaction_id" header="Transaction Id" />
                                        <Column filterField='bulkStatus' field="bulkStatus" header="Status" />
                                        <Column field="uploadedAt" header="Date" body={(rowData) => {
                                            return (<>
                                                <span>{moment(rowData.uploadedAt).format('LL')}</span>
                                            </>)
                                        }} />
                                        <Column header='Actions' body={(rowData) => {
                                            return (<>
                                                <Button label="Show Details" onClick={() => this.getTransactionDetails(rowData.transaction_id)} className='p-button-outlined' />
                                            </>
                                            );
                                        }} ></Column>
                                    </DataTable>
                                </div>
                            </div>
                            {this.props.bulkUserUploadTransactionDetails && this.props.bulkUserUploadTransactionDetails.length && (
                                <BulkUploadDetails transactionId={this.state.transactionId} data={this.props.bulkUserUploadTransactionDetails} />
                            )}
                        </div>

                        {this.props.loading || this.state.showLoader ? <LoadingComponent /> : null}
                        <Toast ref={(el) => this.toast = el} position="bottom-right" />

                        <Dialog
                            draggable={false}
                            closeOnEscape={true}
                            className='ma-alert-box'
                            blockScroll={true}
                            dismissableMask={false}
                            closable={true}

                            style={{ minHeight: '90vh' }}
                            visible={this.state.isShowSampleFormat}
                            onHide={() => this.setState({ isShowSampleFormat: false })}>
                            <div>
                                <div className="card datatable-crud-demo ma-m30">
                                    <DataTable ref={(el) => this.dt2 = el}
                                        value={SampleBulkUplodData}
                                        dataKey="id"
                                        header={header2}
                                        emptyMessage=""
                                        scrollable
                                        responsiveLayout="scroll"
                                    >
                                        <Column field="mobile" header="mobile" />
                                        <Column field="username" header="username" />
                                        <Column field="given_name" header="given_name" />
                                        <Column field="email" header="email" />
                                        <Column field="password" header="password" />
                                        <Column field="grade" header="grade" />
                                        <Column field="board" header="board" />
                                        <Column field="gradeId" header="gradeId" />
                                        <Column field="boardId" header="boardId" />
                                        <Column field="sectionId" header="sectionId" />
                                        <Column field="sectionName" header="sectionName" />
                                        <Column field="gender" header="gender" />
                                        <Column field="admissionDate" header="Admission Date" />
                                        <Column field="aadharNo" header="aadhar No" />
                                        <Column field="dateOfBirth" header="Date Of Birth" />
                                        <Column field="age" header="age" />
                                        <Column field="fatherName" header="Father Name" />
                                        <Column field="fatherOccupation" header="Father Occupation" />
                                        <Column field="fatherQualification" header="Father Qualification" />
                                        <Column field="fatherMobileNum" header="Father Mobile Number" />
                                        <Column field="fatherEmailId" header="Father EmailId" />
                                        <Column field="motherName" header="Mother Name" />
                                        <Column field="motherOccupation" header="Mother Occupation" />
                                        <Column field="motherQualification" header="Mother Qualification" />
                                        <Column field="motherMobileNum" header="Mother Mobile Number" />
                                        <Column field="motherEmailId" header="Mother EmailId" />
                                        <Column field="guardianName" header="Guardian Name" />
                                        <Column field="guardianOccupation" header="Guardian Occupation" />
                                        <Column field="guardianQualification" header="Guardian Qualification" />
                                        <Column field="guardianMobileNum" header="Guardian Mobile Number" />
                                        <Column field="guardianEmailId" header="Guardian EmailId" />
                                        <Column field="houseNum" header="House Number" />
                                        <Column field="streetOrVillage" header="Street Or Village" />
                                        <Column field="mandal" header="mandal" />
                                        <Column field="cityOrDist" header="city Or Dist" />
                                        <Column field="state" header="state" />
                                        <Column field="pinCode" header="pinCode" />
                                        <Column field="siblingsName" header="Sibling Name" />
                                        <Column field="siblingsGender" header="Sibling Gender" />
                                        <Column field="siblingsClassStudying" header="Class Studying" />
                                        <Column field="siblingsSchoolStudying" header="School Studying" />
                                        <Column field="prevAcademicDetailsName" header="prevAcademicDetailsName" />
                                        <Column field="prevAcadamicDetailsClassStudied" header="prevAcadamicDetailsClassStudied" />
                                        <Column field="prevAcademicDetailsSchoolStudied" header="prevAcademicDetailsSchoolStudied" />
                                        <Column field="prevAcademicDetailsPercentageOrGpa" header="prevAcademicDetailsPercentageOrGpa" />

                                    </DataTable>
                                </div>
                            </div>

                        </Dialog>
                    </Authorizer>
                </Navbar>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    loading: state.createNewUserInfo && state.createNewUserInfo.loading,
    error: state.createNewUserInfo && state.createNewUserInfo.error,
    bulkUserUploadTransactions: state.createNewUserInfo && state.createNewUserInfo.bulkUserUploadTransactions && state.createNewUserInfo.bulkUserUploadTransactions && state.createNewUserInfo.bulkUserUploadTransactions.bulkTransaction,
    totalRecords: state.createNewUserInfo && state.createNewUserInfo.bulkUserUploadTransactions && state.createNewUserInfo.bulkUserUploadTransactions && state.createNewUserInfo.bulkUserUploadTransactions.totalRecords,
    bulkUserUploadTransactionDetails: state.createNewUserInfo && state.createNewUserInfo.bulkUserUploadTransactionDetails && state.createNewUserInfo.bulkUserUploadTransactionDetails.bulkTransaction && state.createNewUserInfo.bulkUserUploadTransactionDetails.bulkTransaction[0] && state.createNewUserInfo.bulkUserUploadTransactionDetails.bulkTransaction[0].output_json,
    bulkUserUploadSaveResponse: state.createNewUserInfo && state.createNewUserInfo.bulkUserUploadSaveResponse && state.createNewUserInfo.bulkUserUploadSaveResponse,
    bulkUserUploadRequested: state.createNewUserInfo && state.createNewUserInfo.bulkUserUploadRequested && state.createNewUserInfo.bulkUserUploadRequested,
    userId: localStorage.getItem('username'),
});

export default connect(mapStateToProps, {
    getBulkUploadTransactionDetails,
    getBulkUploadTransactions,
    saveBulkUploadUsers,
    clearBulkUserUploadData
})(Authentication(BulkUpload));
