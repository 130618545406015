import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Service from '../../../services';
import { DataTable } from 'primereact/datatable';
import SvgViewer from './../../customComponents/svgViewer';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { warningDailogInit } from '../../../utile';
import CreateSubject from './createSubject';

import cloneDeep from 'lodash/cloneDeep';
import './styles.scss'
import _ from 'lodash';
import { WarningIcon } from '../../svgIcons';
import LoadingComponent from '../../loadingComponent';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../../store/apiConstants';
class SubjectsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearch: false,
            editSubject: null,
            globalFilter: null,
            columns: [{ columnName: 'Subject Id', isVisible: false, id: 'subjectId' }],
            warningDialog: warningDailogInit,
            warningDialog2: warningDailogInit
        }

        this.service = new Service()
    }

    chaptersCountTemplate = (rowData) => {
        return (
            <p >{rowData.chapters.length}</p>
        );
    }

    statusTemplate = (rowData) => {
        return (
            <div className="ma-status">
                <span className="ma-status-text">{rowData.isSubjectActive ? 'Active' : 'InActive'} </span>
                <Authorizer permId={PERMISSIONS.CURRICULUM_EDIT_SUBJECT} >

                    <InputSwitch onChange={(e) => { this.changeStatus(e, rowData) }} checked={rowData.isSubjectActive} />
                </Authorizer>
            </div>

        );


    }

    showSyncData = (rowData) => {
        this.setState({
            warningDialog2: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `It will sync data with content repository and any deleted resources or questions will added again from content repository.`
            },
            syncData: rowData
        });

    }

    onSyncConfirm = () => {
        this.setState({
            warningDialog2: warningDailogInit,
            isLoading: true
        });



        let obj = {
            level: 'subject',
            subjectId: this.state.syncData.subjectId,
            parentSubjectId: this.state.syncData.parentSubjectId,
            ...this.props.boardsHeirarchy
        }

        console.log(obj, 'syncdata')

        const url = `${baseUrlForLongRunningTask}/content/synccontent`;
        this.service.post(url, obj, true, { "If-Match": this.props.etag }).then((res) => {

            if (res && res.status && res.res.status) {

                this.setState({
                    isLoading: false
                }, () => {

                    this.props.getCurriculumData()
                })

            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })


    }
    editTemplate = (rowData) => {
        return (<><Authorizer permId={PERMISSIONS.CURRICULUM_EDIT_SUBJECT} >
            <span data-pr-tooltip="Edit Subject" data-pr-position="bottom" className={`edit${rowData.subjectId.substr(0, 5)}`}
                onClick={() => this.props.showEditSubjectDialog(rowData)}
            >
                <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" />
            </span>
            <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData.subjectId.substr(0, 5)}`} />
        </Authorizer>
            {
                this.props.syncEnabled && <Authorizer permId={PERMISSIONS.CURRICULUM_EDIT_SUBJECT} >
                    <span data-pr-tooltip="Sync Data" data-pr-position="bottom" className={`ma-ml10 sync${rowData.subjectId.substr(0, 5)}`}
                        onClick={() => this.showSyncData(rowData)}
                    >
                        <i className='pi pi-replay ma-icon-svg ma-pointer' width="20" height="20" />
                    </span>
                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.sync${rowData.subjectId.substr(0, 5)}`} />
                </Authorizer>
            }

        </>);
    }

    showChaptersTemplate = (rowData) => {

        return (<Authorizer permId={PERMISSIONS.CURRICULUM_GOTO_CHAPTERS} ><Button label="Show Chapters" className='p-button-outlined' onClick={() => { this.props.showChapters(rowData) }} /></Authorizer>
        );
    }

    checkIsDuplicate(subjects, subjectInfo, isEdit) {
        let err, errMsg;
        subjects &&
            subjects.length &&
            subjects.forEach((subject) => {
                if (isEdit) {
                    if (subject.subjectId != subjectInfo.subjectId) {
                        if (
                            subject.subjectCode.toLowerCase() === subjectInfo.subjectCode.toLowerCase() ||
                            subject.subjectName.toLowerCase() === subjectInfo.subjectName.toLowerCase()
                        ) {
                            err = true;
                            errMsg =
                                subject.subjectCode.toLowerCase() === subjectInfo.subjectCode.toLowerCase()
                                    ? 'Duplicate Subject Code'
                                    : subject.subjectName.toLowerCase() === subjectInfo.subjectName.toLowerCase()
                                        ? 'Duplicate Subject Name'
                                        : 'Enter Valid Details';
                            return;
                        }
                    }
                } else {
                    if (
                        subject.subjectCode.toLowerCase() === subjectInfo.subjectCode.toLowerCase() ||
                        subject.subjectName.toLowerCase() === subjectInfo.subjectName.toLowerCase()
                    ) {
                        err = true;
                        errMsg =
                            subject.subjectCode.toLowerCase() === subjectInfo.subjectCode.toLowerCase()
                                ? 'Duplicate Subject Code'
                                : subject.subjectName.toLowerCase() === subjectInfo.subjectName.toLowerCase()
                                    ? 'Duplicate Subject Name'
                                    : 'Enter Valid Details';
                        return;
                    }
                }

            });

        return { err, errMsg };
    }

    addSubject = (subjectInfo) => {
        if (this.props.isSubjectAdd) {
            let { err, errMsg } = this.checkIsDuplicate(this.props.subjects, subjectInfo);
            if (err) {
                this.toast.show({ severity: 'error', summary: 'Error occured', detail: errMsg, life: 3000 });
            } else {
                let subjects = _.cloneDeep(this.props.subjects);
                subjects.push({
                    subjectId: subjectInfo.subjectId,
                    subjectName: subjectInfo.subjectName,
                    isSubjectActive: subjectInfo.isSubjectActive,
                    subjectCode: subjectInfo.subjectCode,
                    chapters: []
                });
                this.props.addOrUpdateCurriculumInfo(subjects);
            }

        } else {
            let subjects = [];
            if (this.props.subjects && this.props.subjects.length) {
                let oldSubjects = _.cloneDeep(this.props.subjects);


                subjects = oldSubjects.map(sub => {
                    if (sub.subjectId === subjectInfo.subjectId) {
                        sub.subjectName = subjectInfo.subjectName;
                        sub.subjectCode = subjectInfo.subjectCode;
                        sub.isSubjectActive = subjectInfo.isSubjectActive;
                    }
                    return sub
                });
            }

            let { err, errMsg } = this.checkIsDuplicate(this.props.subjects, subjectInfo, true);
            if (err) {
                this.toast.show({ severity: 'error', summary: 'Error occured', detail: errMsg, life: 3000 });
            } else {
                this.props.addOrUpdateCurriculumInfo(subjects);
            }

        }
    }

    changeStatus = (e, rowData) => {
        let data = { ...rowData }
        if (e.value == true) {
            data.isSubjectActive = true
        } else {
            data.isSubjectActive = false
        }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You are changing this Subject status ${e.value ? 'InActive' : 'Active'} to ${e.value ? 'Active' : 'InActive'}`
            },
            editSubject: data
        });

    }

    onConfirmChange = () => {
        this.setState({
            warningDialog: warningDailogInit
        });

        if (this.props.subjects && this.props.subjects.length) {
            let oldSubjects = _.cloneDeep(this.props.subjects);


            const { editSubject } = this.state;
            let subjects = oldSubjects.map(sub => {
                if (sub.subjectId === editSubject.subjectId) {
                    sub.isSubjectActive = editSubject.isSubjectActive;
                }

                return sub
            });
            this.props.addOrUpdateCurriculumInfo(subjects);
        }



    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    render() {
        const { subject, formValidations } = this.state;

        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'>Subjects List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={(e) => this.setState({ globalFilter: e.target.value })} />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.CURRICULUM_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <Authorizer permId={PERMISSIONS.CURRICULUM_VIEWCOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" data-pr-position="bottom" onClick={(event) => this.menu.toggle(event)}
                                className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.CURRICULUM_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        return (


            <div className="card datatable-crud-demo ma-m30">
                <DataTable ref={(el) => this.dt = el}
                    //  lazy
                    value={this.props.subjects}
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={this.state.globalFilter}
                    header={header}>

                    <Column filter filterMatchMode='contains' sortable field="subjectName" header="Subject Name" />
                    <Column filter filterMatchMode='contains' sortable filterField="subjectCode" field="subjectCode" header="Subject Code" />
                    {
                        this.isColumnVisible('subjectId') && <Column filter filterMatchMode='contains' sortable field="subjectId" header="Subject Id" />
                    }
                    <Column filter filterMatchMode='contains' sortable field="chapters.length" header="Chapters"
                    //body={this.chaptersCountTemplate}
                    />
                    <Column field="isSubjectActive" header="Status" body={this.statusTemplate} />
                    <Column header="Edit" body={this.editTemplate} ></Column>
                    <Column header="Show Chapters" body={this.showChaptersTemplate}></Column>

                </DataTable>
                {this.props.isShowSubjectCreate && <CreateSubject isAdd={this.props.isSubjectAdd} hideCreateSubjectDialog={this.props.hideCreateSubjectDialog} editSubject={this.props.editSubject} addSubject={this.addSubject} />}

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                <Dialog
                    header={<></>}
                    blockScroll={true}
                    draggable={false}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editRoleData: null }) }} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmChange} />
                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, editRoleData: null })}>
                    <div>
                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>
                    <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <p className='ma-alert-msg'>{this.state.warningDialog.message}</p>
                </Dialog>
                <Dialog
                    header={<></>}
                    blockScroll={true}
                    draggable={false}
                    closeOnEscape={false}
                    className='ma-alert-box'
                    dismissableMask={false}
                    closable={false}
                    visible={this.state.warningDialog2.visible}
                    style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog2: warningDailogInit, syncData: null }) }} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.onSyncConfirm} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog2: warningDailogInit, syncData: null })}>
                    <div>

                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>
                    <p className='ma-alert-head'>{this.state.warningDialog2.headerMsg}</p>
                    <p className='ma-alert-msg'>{this.state.warningDialog2.message}</p>
                </Dialog>
                {this.state.isLoading && <LoadingComponent />}
            </div >
        )
    }
}




export default SubjectsList;