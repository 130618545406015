import React, { Component } from 'react'

export default class QuizPreview extends Component {
    render() {


        // console.log('quiz-info', this.props.contentInfo);
        return (
            <div>No Preview available for Quiz</div>
        )
    }
}
