import React, { Component } from 'react';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { discussionBoardEndpoint } from './../../../store/apiConstants';
import Service from './../service';
import { Tooltip } from 'primereact/tooltip';
import ReportDialog from './report';
import Answers from '../allAnswers';
import { getProfileURL } from './../../../utile';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Attachments from './attachments';
import CreateDiscussion from './../allDiscussions/createDiscussion';
import PERM_CONSTANTS from './../permissionConstants';
import { isUserAuthorized } from '../../../utile/isAuthorized';
import { Menu } from 'primereact/menu';
import { CommentIcon, DisLikeIcon, DisLike_HighlightIcon, Edit2Icon, FavouriteHighlightIcon, FavouriteIcon, LikeIcon, MuteIcon, PinIcon, ReportingHighlightIcon, UnmuteIcon, WarningIcon } from '../../svgIcons';

const TEACHER_ID = localStorage.getItem('discussion_uId');




class Discussion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            discussion: this.props.discussion,
            showReport: false,
            showAnswers: false,
            open: false,
            moreOptionsRef: React.createRef(null),
            showEdit: false,
            isDeleted: false,
            isLoading: false
        }
        this.service = new Service();
    }

    likeOrDislike = (id, liked, event) => {
        if (!this.state.isLoading) {


            if (liked && event == 'like') {
                return;
            }
            if (liked == false && event == 'dislike') {
                return;
            }

            let likeCount = 0;
            let dislikeCount = 0;
            if (liked) {
                if (event == 'dislike') {
                    likeCount = -1;
                    dislikeCount = 1;
                }
            } else if (liked == null) {
                if (event == 'dislike') {
                    dislikeCount = 1;
                } else if (event == 'like') {
                    likeCount = 1;
                }
            } else if (liked == false) {
                if (event == 'like') {
                    likeCount = 1;
                    dislikeCount = -1;
                }
            }

            this.setState({
                isLoading: true,
            });

            const url = `${discussionBoardEndpoint}/posts/${id}/${event}`;

            this.service.post(url, {}, true).then(data => {
                if (data && data.status) {
                    if (data.res.status) {
                        this.setState((prevState) => ({
                            discussion: {
                                ...prevState.discussion,
                                liked: event == 'like' ? true : false,
                                likeCount: prevState.discussion.likeCount + likeCount,
                                dislikeCount: prevState.discussion.dislikeCount + dislikeCount,
                            }
                            ,
                            isLoading: false
                        }));
                    } else {
                        this.setState({
                            isLoading: false
                        })
                    }
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
        }
    }

    addToFavourite = (id, favourite, favouriteId) => {
        if (!favourite) {
            const url = `${discussionBoardEndpoint}/posts/${id}/favourite`;
            this.service.post(url, {}, true).then(data => {
                if (data && data.status) {
                    if (data.res.status) {
                        this.setState((prevState) => ({
                            discussion: { ...prevState.discussion, favourite: true, favouriteId: data.res.data.doc.id }
                        }));
                    }
                }
            });
        } else {
            const url = `${discussionBoardEndpoint}/favourites/${favouriteId}`;
            this.service.delete(url, {}, true).then(data => {
                if (data && data.status) {
                    if (data.res.status) {
                        this.setState((prevState) => ({
                            discussion: { ...prevState.discussion, favourite: false, favouriteId: undefined }
                        }));
                    }
                }
            });
        }
    }


    reportThis = (reason) => {
        const url = `${discussionBoardEndpoint}/reports`;
        this.service.post(url, {
            for: 'Post',
            doc: this.state.discussion._id,
            content: reason,
            postId: this.state.discussion._id

        }, true).then(data => {
            if (data && data.status) {
                if (data.res.status) {
                    this.setState((prevState) => ({
                        discussion: { ...prevState.discussion, reported: true, isFlagged: true },
                        showReport: false
                    }));
                } else {
                    this.setState((prevState) => ({
                        showReport: false
                    }));
                }
            } else {
                this.setState((prevState) => ({
                    showReport: false
                }));
            }
        });
    }

    handleToggle = () => {
        this.setState({
            open: !this.state.open
        });
    };

    handleClose = () => {
        this.setState({
            open: !this.state.open
        });
    }

    muteNotification = (id, isMute) => {
        
        this.setState({
            open: false
        });
        const url = `${discussionBoardEndpoint}/posts/${id}`;
        this.service.put(url, { muteNotifications: isMute ? false : true }, true).then(data => {
            if (data && data.status) {
                if (data.res.status) {
                    this.setState((prevState) => ({
                        discussion: { ...prevState.discussion, muteNotifications: isMute ? false : true },
                    }));
                }
            }
        });


    }

    onPinClick = (discussionId, isPinned) => {
        const url = `${discussionBoardEndpoint}/posts/${discussionId}`;
        this.service.put(url, { isPinned: isPinned ? false : true }, true).then(data => {
            if (data && data.status) {
                if (data.res.status) {
                    this.setState((prevState) => ({
                        discussion: { ...prevState.discussion, isPinned: isPinned ? false : true },
                    }));
                }
            }
        });
    }

    unReportPost = () => {
        const url = `${discussionBoardEndpoint}/posts/${this.state.discussion._id}`;
        this.service.put(url, { isFlagged: false }, true).then(data => {
            if (data && data.status) {
                if (data.res.status) {
                    this.setState((prevState) => ({
                        discussion: { ...prevState.discussion, isFlagged: false, reported: false },
                        showUnReport: false
                    }));
                }
            }
        });
    }

    onEditClick = () => {

        this.setState({
            open: false,
            showEdit: true
        });
    }




    onMoreClick = (event) => {
        this.setState({
            open: true,
            moreOptionsRef: event.currentTarget
        });
    }

    onEditComplete = () => {
        this.setState({
            open: false,
            showEdit: false
        });
    }

    onDeleteClick = () => {
        this.setState({
            open: false
        });
        const url = `${discussionBoardEndpoint}/posts/${this.state.discussion._id}`;
        this.service.delete(url, {}, true).then(data => {
            if (data && data.status) {
                if (data.res.status) {
                    this.setState((prevState) => ({
                        isDeleted: true
                    }));
                }
            }
        });

    }

    onEditDiscusssion = (data) => {

        this.setState((prevState) => ({
            discussion: data,
            showEdit: false
        }));
    }




    render() {

        const { discussion, isDeleted } = this.state;
     //   console.log(discussion)
        let items = [];

        if (this.props.discussionUser && this.props.discussionUser._id != discussion.postedBy._id && !discussion.reported && !discussion.isFlagged) {
            items.push({
                template: (<div className='action-item' onClick={(event) => { this.setState({ showReport: true, open: false }); this.menu.toggle(event) }}><span className="sort-report-icon"><ReportingHighlightIcon width={'16px'} /> </span> <span className='li-item-text'> Report this post </span></div>)
            })
        }


        if (this.props.discussionUser && this.props.discussionUser._id == discussion.postedBy._id) {
            items.push({
                template: (<div className='action-item' onClick={(event) => { this.onEditClick(discussion); this.menu.toggle(event) }}> <span className="li-img-icon ma-pointer" ><Edit2Icon height={"16"} width="16" /></span><span className='li-item-text'>Edit</span></div>)
            },
                {
                    template: (<div className='action-item' onClick={(event) => { this.onDeleteClick(discussion); this.menu.toggle(event) }}> <i className="pi pi-trash" /> <span className='li-item-text'>Delete</span></div>)
                },
                {
                    template: (<div className='action-item' onClick={(event) => {
                            
                        
                        
                        
                        this.muteNotification(discussion._id, discussion.muteNotifications);
                        
                        
                        
                        
                        
                        this.menu.toggle(event) }}> {discussion.muteNotifications ? <> <span className="li-img-icon" ><UnmuteIcon height={"16"} width="16" /></span><span className='li-item-text'>UnMute notification for this post </span></> : <><span className="li-img-icon" ><MuteIcon height={"16"} width="16" /></span> <span className='li-item-text'>Mute notification for this post </span> </>} </div>)
                }
            );

        }

        // if (this.props.discussionUser && this.props.discussionUser._id != discussion.postedBy._id && discussion.isFlagged) {

        //     items.push({
        //         template: (<div className='action-item' onClick={(event) => { this.setState({ showUnReport: true, open: false }); this.menu.toggle(event) }}><span className="sort-report-icon" ><ReportingHighlightIcon width="16px" /></span><span className='li-item-text'>Unreport this post</span></div>)
        //     })
        // }





        return (<>

            {!isDeleted && !(!discussion.isFlagged && this.props.currentTab == 7) &&
                <div className="discussion-item">
                    <div className="ma-box discussion">
                        <div className='p-grid'>
                            <div className='p-col-6'>

                                <div style={{ width: '100%', overflow: 'hidden' }}>
                                    <div style={{ width: '40px', float: 'left' }}>
                                        <img src={getProfileURL(discussion?.postedBy?.photo)} alt="n" className="profile-img" />
                                    </div>
                                    <div style={{ marginLeft: '40px' }}>
                                        <p className="profile-name">{discussion.postedBy.name}</p>
                                        {discussion.postedBy?.role == 'teacher' && <p className="teacher-label"> <span>Teacher</span>
                                        </p>}
                                        <p className="created-at">{moment(discussion.createdAt).fromNow()} </p>

                                    </div>
                                </div>
                            </div>
                            <div className='p-col-6'>

                                <ul className="discussion-menu" >
                                    {this.props.discussionUser && discussion.postedBy._id == this.props.discussionUser && this.props.discussionUser._id && discussion.muteNotifications && <li>
                                        <UnmuteIcon width={"16px"} />
                                    </li>
                                    }
                                    {discussion.isFlagged && <li>
                                        <span> <ReportingHighlightIcon width={"16px"} /></span>
                                    </li>}
                                    <li data-pr-tooltip={discussion.isPinned ? 'Unpin this discussion' : 'Pin this discussion'} data-pr-position="bottom" className={`c${this.props.id} "ma-pointer`} onClick={() => { this.onPinClick(discussion._id, discussion.isPinned) }}>
                                        <span className="ma-pointer" >
                                            <PinIcon width={"21px"} height={"21px"} color={discussion.isPinned ? 'var(--primary-color)' : '#b1b1b1'} />
                                        </span>

                                        <Tooltip className="table-li-tooltip2" target={`.c${this.props.id}`} />
                                    </li>
                                    {items && items.length ? <li onClick={(event) => this.menu.toggle(event)}  >
                                        <i className="pi pi-ellipsis-v ma-pointer" />
                                    </li> : <li style={{ paddingLeft: '22px' }}  >

                                    </li>}




                                </ul>
                                <Menu className='answer-menu' model={items} popup ref={el => this.menu = el} />
                            </div>
                        </div>
                        {/* <p className="subject-name"> <span>Grade:</span>{discussion.subjectName}-{discussion.subjectName}-{discussion.subjectName} {discussion.subjectName}</p> */}
                        <p className="subject-name"> <span>Subject: </span>{discussion.boardName ? `${discussion.boardName}, ` : ''}{discussion.className ? `${discussion.className}, ` : ''}{discussion.subjectName}</p>
                        <p className="question">Q: {discussion.content}</p>
                        {discussion.attachments && discussion.attachments.length && discussion.attachments[0] && discussion.attachments[0].length ? <Attachments attachments={discussion.attachments[0]} /> : <></>
                        }




                        {/* <ReadMoreReact text={`Q: ${discussion.content}`}
                        min={3}
                        ideal={5}
                        max={10}
                        readMoreText="read more" /> */}
                        <hr className='ma-hr2' />
                        <div className='p-grid'>
                            <div className='p-col-6'>
                                <ul className="discussion-actions" style={{ marginTop: '5px' }} >
                                    <li onClick={() => { this.likeOrDislike(discussion._id, discussion.liked, 'like') }}>
                                        <span className="ma-pointer">
                                            <LikeIcon width={16} height={16} color={discussion.liked ? 'var(--primary-color' : '#9D9A9A'} />
                                        </span>
                                        {discussion.likeCount}</li>
                                    <li onClick={() => { this.likeOrDislike(discussion._id, discussion.liked, 'dislike') }}  >
                                        <span className="ma-pointer">
                                            {/* {discussion.liked == false ? <span><DisLike_HighlightIcon height={"16px"} width={"16px"} /> </span> : <span><DisLikeIcon height={"16px"} width={"16px"} /> </span>} */}
                                            <DisLikeIcon width={16} height={16} color={discussion.liked == false ? "#ec8888" : '#9d9a9a'} />
                                        </span>

                                        {discussion.dislikeCount}</li>
                                    <li onClick={() => { this.setState({ showAnswers: !this.state.showAnswers }) }}>
                                        <span className="ma-pointer" ><CommentIcon height={"16px"} width={"16px"} /> </span>
                                        {discussion.answerCount}</li>
                                </ul>
                            </div>
                            <div className='p-col-6'>
                                <span className="favourite-icon ma-pointer" title="Add to Favourite" onClick={() => { this.addToFavourite(discussion._id, discussion.favourite, discussion.favouriteId) }}>  {!discussion.favourite ? <span className="li-img-icon" > <FavouriteIcon width={"16"} height={"16"} /> </span> : <span className="li-img-icon" > <FavouriteHighlightIcon width={"16"} height={"16"} /> </span>}</span>
                            </div>
                        </div>
                        {
                            this.state.showAnswers && <Answers postId={discussion._id} />
                        }
                    </div>
                    {
                        this.state.showReport && <ReportDialog open={true} onClose={() => { this.setState({ showReport: false }) }} reportThis={this.reportThis} />
                    }

                    {
                        this.state.showEdit && <CreateDiscussion onCreateDiscussion={this.onEditDiscusssion} discussion={discussion} onEditComplete={this.onEditComplete} />
                    }

                    {this.state.showUnReport && <Dialog
                        visible={this.state.showUnReport}
                        onHide={this.onClose}
                        blockScroll={true}
                        closable={false}
                        showHeader={true}
                        footer={() => {
                            return (<div>
                                <Button label="Cancel" className='p-button-outlined'
                                    onClick={() => { this.setState({ showUnReport: false }) }}
                                />
                                <Button label="Submit" className='ma-m-lr10'
                                    onClick={() => { this.unReportPost() }}
                                />
                            </div>)
                        }}
                    >
                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                        <p className="warning-title">Are you sure?</p>
                        <p className="warning-txt">You are UnReporting this Discussion </p>

                    </Dialog>
                    }
                </div>
            }
        </>)
    }
}

const mapStateToProps = state => {
    return {

        discussionUser: state.currentUserPerms && state.currentUserPerms.discussionUser && state.currentUserPerms.discussionUser,
    }
}

export default connect(mapStateToProps, {})(withRouter(Discussion));