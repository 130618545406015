import { Dialog } from 'primereact/dialog';
import React, { Component } from 'react'
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';




export default class GraphicalView extends Component {
    render() {
        const { hideGraphicalView, graphAnalytics, barData, pieData, totalStats } = this.props;
        console.log('barData', this.props)
        return (<Dialog
            //visible={this.state.isShowCreate}
            className='graph-view'
            style={{ width: '90%', height: '90vh' }}
            header='Overall Test Wise Analytics'
            modal
            //footer={this.createRoleDialogFooter}
            draggable={false}
            // footer={this.footer}
            blockScroll={true}
            closeOnEscape={false}
            dismissableMask={false}
            visible={true}
            closable={true}
            onHide={hideGraphicalView}>
            <div>

                {/* <Toolbar className="ma-toolbard"
                    //  left={() => {
                    //     return <>
                    //         <i className='pi pi-refresh refresh-analytics' /></>
                    // }}
                    right={() => {
                        return <> <Button label='Download PDF' /> </>
                    }}
                ></Toolbar> */}
                <Toolbar className="ma-toolbard"
                    style={{ marginLeft: "40px", marginRight: "40px" }}
                    left={() => {
                        return < >
                            {/* {
                    label: 'All',
                    value: 'all'
                }, */}
                            <Dropdown value={this.props.selectedSubject}
                                className='ma-w200'
                                options={["All", ...(this.props.subjectsForGraph || [])]}
                                onChange={(e) => this.props.onSelectSubject(e.value)}
                                placeholder="--Select--" />
                        </>
                    }}
                    right={() => {
                        return < >

                            {
                                totalStats && totalStats['Highest Marks'] != undefined ? <>
                                    <div className='exam-stats highest'>
                                        <p className='label'>Highest Marks</p>
                                        <p className='marks'>{totalStats['Highest Marks']}</p>
                                    </div>
                                </> : <></>

                            }
                            {
                                totalStats && totalStats['Average Marks'] != undefined ? <>
                                    <div className='exam-stats average'>
                                        <p className='label'>Average Marks</p>
                                        <p className='marks'>{totalStats['Average Marks']}</p>
                                    </div>
                                </> : <></>

                            }
                            {
                                totalStats && totalStats['User Marks'] != undefined ? <>
                                    <div className='exam-stats user'>
                                        <p className='label'>User Marks</p>
                                        <p className='marks'>{totalStats['User Marks']}</p>
                                    </div>
                                </> : <></>

                            }


                        </>
                    }}
                ></Toolbar>
                <div className='p-grid sp-no-pm highlights-tabs '>

                </div>


                <div className='p-grid analytics-graph '>
                    <div className="p-col-12 p-md-6 ">
                        <div className='hours-graph-pie' >
                            <div style={{ height: '100%', width: '100%' }}>
                                <ResponsivePie
                                    enableArcLinkLabels={false}
                                    data={pieData}
                                    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                    innerRadius={0.6}
                                    padAngle={0}
                                    cornerRadius={0}
                                    activeOuterRadiusOffset={8}
                                    borderWidth={0}
                                    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                    // arcLinkLabelsSkipAngle={10}
                                    //enableSliceLabels={false}
                                    // enableRadialLabels={false}
                                    // arcLinkLabelsTextColor="#333333"
                                    colors={['#20C2C8', '#FF8373', '#FFDA83', '#A3A0FB']}
                                    // arcLinkLabelsThickness={2}
                                    // arcLinkLabelsColor={{ from: 'color' }}
                                    // arcLabelsSkipAngle={10}
                                    // arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                                    valueFormat={value =>
                                        `${value} %`
                                    }

                                    legends={[
                                        {
                                            anchor: 'bottom',
                                            direction: 'row',
                                            justify: false,
                                            translateX: 0,
                                            translateY: 56,
                                            itemsSpacing: 0,
                                            itemWidth: 70,
                                            itemHeight: 16,
                                            itemTextColor: '#999',
                                            itemDirection: 'left-to-right',
                                            itemOpacity: 1,
                                            symbolSize: 16,
                                            symbolShape: 'circle',
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemTextColor: '#000'
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                //  layers={[CenteredMetric]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6 ">
                        <div className='hours-graph-bar'>
                            <ResponsiveBar
                                data={barData}
                                keys={barData.map(a => a.key)}
                                indexBy="key"
                                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                padding={0.7}
                                valueScale={{ type: 'linear' }}
                                indexScale={{ type: 'band', round: true }}
                                borderRadius={6}
                                enableLabel={false}
                                colors={['#20C2C8', '#FFDA83', '#FF8373', '#A3A0FB']}
                                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                defs={[
                                    {
                                        id: 'dots',
                                        type: 'patternDots',
                                        background: 'inherit',
                                        color: '#38bcb2',
                                        size: 4,
                                        padding: 1,
                                        stagger: true
                                    },
                                    {
                                        id: 'lines',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: '#eed312',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    }
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: 'fries'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'sandwich'
                                        },
                                        id: 'lines'
                                    }
                                ]}
                                axisTop={null}
                                axisRight={null}
                                // axisBottom={{
                                //     tickSize: 5,
                                //     tickPadding: 5,
                                //     tickRotation: 0,
                                //     legend: 'country',
                                //     legendPosition: 'middle',
                                //     legendOffset: 32
                                // }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'Time in Hours (H:M)',
                                    legendPosition: 'middle',
                                    legendOffset: -40
                                }}
                                // labelSkipWidth={12}
                                // labelSkipHeight={12}
                                // labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                legends={[
                                    {
                                        dataFrom: 'keys',
                                        anchor: 'bottom-right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: 120,
                                        translateY: 0,
                                        itemsSpacing: 2,
                                        itemWidth: 100,
                                        itemHeight: 20,
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 0.85,
                                        symbolSize: 20,
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemOpacity: 1
                                                }
                                            }
                                        ]
                                    }
                                ]}
                                role="application"
                                ariaLabel="Nivo bar chart demo"
                            //   barAriaLabel={function (e) { return e.id + ": " + e.formattedValue + " in country: " + e.indexValue }}
                            />
                        </div>
                    </div>

                </div>
            </div >
        </Dialog >


        )
    }
}
