import * as ActionType from "../actionTypes";

export default (state = {}, action) => {
    switch (action.type) {

        case ActionType.REQUEST_GET_VIDEO_URL:

            return {
                ...state,
                loading: true,
                errorInfo: null,
                error: false

            }
        case ActionType.RECEIVED_GET__VIDEO_URL:
            return {
                ...state,
                loading: false,
                errorInfo: null,
                error: false,
                response: action.res,
                allVideoUrls: {
                    ...state.allVideoUrls,
                    [action.storageKey]: action.res
                }

            }
        case ActionType.ERROR_GET_VIDEO_URL:
            return {
                ...state,
                loading: false,
                errorInfo: action.err,
                error: true
            }
        case ActionType.CLEAR_GET__VIDEO_URL:
            return {
            }
        default:
            return state

    }
}