import * as ActionType from '../actionTypes'
import axios from 'axios'

import { baseUrlAdmin } from '../apiConstants'

const dispatchRequestGetAllCourseDetails = (data) => ({
    type: ActionType.REQUEST_GET_ALL_COURSE_DETAILS,
    data,
})

const dispatchRecievedGetAllCourseDetails = (res) => ({
    type: ActionType.RECIVED_GET_COURSE_DETAILS,
    res,
})

const dispatchErrorGetCourseDetails = (err) => ({
    type: ActionType.ERROR_GET_COURSE_DETAILS,
    err,
})

const dispatchClearAllCourseDetails = () => ({
    type: ActionType.CLEAR_ALL_COURSE_DETAILS
})

export const getAllCourseDetails = (data) => dispatch => {
    dispatch(dispatchRequestGetAllCourseDetails(data))
    axios({
        method: "GET",
        url: `${baseUrlAdmin}/admission-courses`,
        data: data,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        }
    }).then((res) => {
        dispatch(dispatchRecievedGetAllCourseDetails(res.data))
    }).catch(e => {
        dispatch(dispatchErrorGetCourseDetails(e))
    })
}

export const clearAllCourseDetails = () => dispatch => {
    dispatch(dispatchClearAllCourseDetails())
}