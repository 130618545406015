import React, { Component } from 'react'
import { ProgressSpinner } from 'primereact/progressspinner';

export default class InLoadingComponent extends Component {
    render() {
        return (
            <div
                style={{
                    position: 'absolute',
                    display: "inline-block",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    //   backgroundColor: 'rgba(255,255,255,0.5)',
                    //  zIndex: 10000,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <span

                    style={{
                        // backgroundColor: 'whitesmoke',
                        // borderRadius:'25px'
                    }}>
                    <ProgressSpinner className='ui-progress-spinner-color' />
                </span>
            </div>
        )
    }
}

