import * as ActionTypes from './../actionTypes';

const initialState = {
    authenticated: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case (ActionTypes.AUTH_USER):
            return {
                ...state,
                authenticated: true,
                userData: action.userData,
            }
        case (ActionTypes.UN_AUTH_USER):
            return {
                ...state,
                authenticated: false,
                userData: null
            }
        default: return state

    }

}