import { flushCalls } from '@react-spring/shared';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react'
import { Column } from 'react-formio/lib/types';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import { BasicLazyParams, warningDailogInit } from '../../utile';
import LoadingComponent from '../loadingComponent';
import Navbar from '../navbar';
import { WarningIcon } from '../svgIcons';
import { connect } from 'react-redux';
import Authentication from '../session'
import withRouter from 'react-router-dom/withRouter';
import Authorizer,{PERMISSIONS} from '../session/authorizer'
import moment from 'moment/moment';


const items = [
    { label: 'Academics' },
    { label: 'QR Code Manager', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

class QrCodeManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            qrCodes: [],
            globalFilter: '',
            showSearch: false,
            selectedCustomers: [],
            columns: [{ columnName: '', isVisible: false, id: '' }],
            isGenerateQR: false,
            isLoading: false,
            lazyParams: BasicLazyParams,
            numberToGenQR: null,
            globalSearch: '',
            numErrMsg: false,
            warningDialog: warningDailogInit,




        }
        this.service = new Service()
    }

    componentDidMount() {
        this.getQrCodesData()
    }

    getQrCodesData = () => {
        this.setState({
            isLoading: false
        })

        let lazyParams = this.state.lazyParams;


        let url = `${baseUrlAdmin}/qrcodes?limit=${lazyParams.rows}&sort=-createdAt&page=${lazyParams.page + 1}&search=${this.state.globalSearch}`
        this.service.get(url, true).then(res => {
            if (res?.status && res?.res?.status) {
                this.setState({
                    isLoading: false,
                    qrCodes: res?.res?.data?.qrCodes,
                    totalRecords: res?.res?.data?.totalRecords
                })
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(err => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

        })
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>QR Code Manager</h2>
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    onGenerateQrCodes = () => {
        const { numberToGenQR } = this.state
        let isValid = true
        if (!(numberToGenQR >= 1 && numberToGenQR < 20)) {
            this.setState({
                numErrMsg: true
            })
            isValid = false
        }

        if (isValid) {
            let count = this.state.numberToGenQR
            this.setState({
                isLoading: false
            })
            let url = `${baseUrlAdmin}/qrcodes/generate`
            this.service.post(url, { count }, true).then(res => {
                if (res?.status && res?.res?.status) {
                    this.setState({
                        isLoading: false,
                        isGenerateQR: false
                    }, this.getQrCodesData)
                    this.toast.show({ severity: 'success', summary: 'Saved', detail: `QR Code Created successfuly`, life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            }).catch(err => {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

            })
        }
    }

    footer = () => {
        return (
            <>
                <Button label='Generate' onClick={this.onGenerateQrCodes} />
                <Button label='Cancel' className='p-button-outlined' onClick={() => {
                    this.setState({
                        isGenerateQR: false,
                        numErrMsg: false,
                        numberToGenQR: ''
                    })
                }} />
            </>
        )
    }

    onPage = (event) => {
        this.setState({ lazyParams: event }, this.getQrCodesData);
    }


    onToggleChage = (e, rowData) => {
        
        let data = { ...rowData }
        if (e.value == true) {
            data.isActive = true
        } else {
            data.isActive = false
        }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You are changing this QR Status ${e.value ? 'InActive' : 'Active'} to ${e.value ? 'Active' : 'InActive'}`
            },
            editQrData: data
        });

    }

    onConfirmChange = () => {
        
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        });
        const { editQrData } = this.state

        let url = `${baseUrlAdmin}/qrcodes/`
        this.service.put(url, { '_id': editQrData._id, 'isActive': editQrData.isActive }, true).then(res => {
            if (res?.status && res?.res?.status) {
                this.setState({
                    isLoading: false,
                    isGenerateQR: false
                }, this.getQrCodesData)
                this.toast.show({ severity: 'success', summary: 'Saved', detail: `QR Code Updated successfuly`, life: 3000 });
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(err => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

        })

    }





    render() {
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'>QR Codes</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={(e) => {
                        this.setState({ globalFilter: e.target.value })
                    }} />}

                </div>

                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* s */}
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        return (
            <>
                <Navbar>
                    <div className="p-grid ma-toolbar">
                        <div className="p-col-12 p-md-1 ma-no-p">

                        </div>
                        <div className="p-col-12 p-md-11 ma-no-pm">
                            <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        </div>
                    </div>
                    <div className='ma-main'>
                        <Authorizer permId={PERMISSIONS.CREATE_ORGANIZATION} >  
                            <Button label='Generate New QR Codes' onClick={() => { this.setState({ isGenerateQR: true }) }} />
                        </Authorizer>
                        <div className='card datatable-crud-demo ma-m30'>
                        <Authorizer permId={PERMISSIONS.VIEW_ORGANIZATION} >
                            <DataTable ref={(el) => this.dt = el}
                                value={this.state.qrCodes}
                                dataKey="_id"
                                paginator
                                lazy
                                onPage={this.onPage}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                responsiveLayout="scroll"
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                globalFilter={this.state.globalFilter}
                                selection={this.state.selectedCustomers}
                                onSelectionChange={e => this.setState({ selectedCustomers: e.value })}
                                header={header}
                            >
                                <Column field="qrId" header="Qr Id" />
                                <Column field="isAssigned" header="Assigned" body={(rowData) => {
                                    return <>
                                        <InputSwitch
                                            checked={rowData.isAssigned}
                                        />
                                    </>
                                }} />
                                <Column field="isActive" header="Active/Inactive" body={(rowData) => {
                                    return <>
                                        <InputSwitch
                                            checked={rowData.isActive}
                                            onChange={(e) => {
                                                this.onToggleChage(e, rowData)
                                            }}
                                        />
                                    </>
                                }} />

                            <Column  header="Created At" body={(rowData) => {
                                    return <>
                                      <p>{moment(rowData.createdAt).format('DD-MM-YYYY')}</p>
                                    </>
                                }} />

                                {/* <Column field="" header="Actions" /> */}
                            </DataTable>
                        </Authorizer>
                        </div>
                    </div>


                    <Dialog
                        visible={this.state.isGenerateQR}
                        draggable={false}
                        blockScroll={true}
                        closeOnEscape={true}
                        className='ma-alert-box'
                        dismissableMask={false}
                        closable={false}
                        style={{ maxWidth: '20%', minWidth: '25%' }}
                        footer={this.footer}
                        header={`Generate QR Codes`}
                        onHide={() => { this.setState({ isGenerateQR: false }) }}
                    >
                        <div className="p-grid subject-add-dialog">
                            <div className="p-col-4 p-md-4 p-lg-4">
                                <p className='text-label ma-mt10'>Enter a number <span className='ma-required'>*</span></p>
                            </div>
                            <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                                <Dropdown
                                    options={[5, 10, 15, 20]}
                                    value={this.state.numberToGenQR}
                                    onChange={(e) => {
                                        this.setState({ numberToGenQR: e.value, numErrMsg: false })
                                    }} className='ma-w200 ma-mr20' />
                                {this.state.numErrMsg && <p className='p-error'>Select a Number</p>}
                            </div>
                        </div>
                    </Dialog>
                </Navbar>

                <Dialog
                    //header={<></>}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editQrData: null }) }} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmChange} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, editQrData: null })}>
                    <div>

                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>
                    <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <p className='ma-alert-msg'>{this.state.warningDialog.message}</p>
                </Dialog>
                {
                    this.state.isLoading && <LoadingComponent />
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />

            </>
        )

    }
}

const mapStateToProps = (state) => ({

});



export default connect(mapStateToProps, {})(Authentication(withRouter(QrCodeManager)));