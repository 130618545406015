import * as ActionTypes from '../actionTypes';



export default (state = {
    yearlyPlanners: [],
    isLoading: true,
    error: false,
    errorMessage: '',
}, action) => {

    // console.log("actionactionactionactionactionaction", action)
    switch (action.type) {
        case ActionTypes.REQUEST_PLANNER:
            return {
                ...state,
                isLoading: true,
                error: false,
            }

        case ActionTypes.RECEIVED_PLANNER:
            return {
                ...state,
                isLoading: false,
                yearlyPlanners: action.res.yearlyPlanners || [],
                error: false,
                errorMessage: null,
            }

        case ActionTypes.ERROR_PLANNER:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: action.err,
                yearlyPlanners: null
            }
        case ActionTypes.CLEAR_PLANNER_DATA:
            return {}

        default: return state;

    }
}