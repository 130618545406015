import * as ActionTypes  from '../actionTypes';
import axios from 'axios';
import { baseUrlAdmin } from '../apiConstants';


const dispatchRequestContent = data => ({
    type: ActionTypes.REQUEST_CONTENT,
    data
})

const dispatchReceivedContent = (res, status) => ({
    type: ActionTypes.RECEIVED_CONTENT,
    res,
    status
})

const dispatchErrorContent = err => ({
    type: ActionTypes.ERROR_CONTENT,
    err
})

const dispatchClearContent = () => ({
    type: ActionTypes.CLEAR_CONTENT_DATA
})

export const clearGetContentData = () => dispatchClearContent();


export const getContent = (boardId, classId, groupId, subjectId, chapterId, topicId) => dispatch => {
    dispatch(dispatchRequestContent());
    axios({
        method: 'get',      
        url: `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/subject/${subjectId}/chapter/${chapterId}/topic/${topicId}`,       
    }).then((res) => {
        const etag = res.headers && res.headers.etag;
        
        localStorage.setItem('etag', etag);
        const status = res && res.status;
        dispatch(dispatchReceivedContent(res.data, status));
    }).catch(e => {
        dispatch(dispatchErrorContent(e));
    });
}


