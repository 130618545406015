import React, { Component } from 'react';
import Service from './../service';
import { discussionBoardEndpoint } from './../../../store/apiConstants';
import Discussion from '../common/discussion';
import CreateDiscussion from './createDiscussion';
import SearchBar from '../common/searchBar';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PERM_CONSTANTS from './../permissionConstants';
import { isUserAuthorized } from '../../../utile/isAuthorized';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';

class AllDiscussions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort: '-createdAt',
            limit: 10,
            page: 1,
            search: '',
            discussions: [],
            stopRequests: false,
            isLoading: false,
            currentScrollPosition: 0,
            gradeFilter: this.props.assignedSubjects


        }
        this.service = new Service();
    }


    getDiscussions = (sort, limit, page, search, isSearch, gradeFilter) => {


        if (!this.state.isLoading && !this.state.stopRequests) {
            if (isSearch) {
                this.setState({
                    isLoading: true,
                    discussions: []
                });
            } else {
                this.setState({
                    isLoading: true
                });
            }


            let url = '';
            if (this.props.myQuestions) {
                url = `${discussionBoardEndpoint}/posts/posted-by-me?sort=${sort}&limit=${limit}&page=${page}&search=${search}`;
            } else if (this.props.answeredAndCommentedPosts) {
                url = `${discussionBoardEndpoint}/ans-comnt/get-ans-cmnts-posts?sort=${sort}&limit=${limit}&page=${page}&search=${search}`;
            } else if (this.props.favourites) {
                url = `${discussionBoardEndpoint}/favourites/all?sort=${sort}&limit=${limit}&page=${page}&search=${search}`;
            } else if (this.props.flagged) {
                url = `${discussionBoardEndpoint}/posts/flagged?sort=${sort}&limit=${limit}&page=${page}&search=${search}`;
            } else if (this.props.pinned) {
                url = `${discussionBoardEndpoint}/posts/pinned?sort=${sort}&limit=${limit}&page=${page}&search=${search}`;
            } else {
                url = `${discussionBoardEndpoint}/posts/all?sort=${sort}&limit=${limit}&page=${page}&search=${search}`;
            }

            let reqBody = {
                isQuestionOfTheDay: this.props.isQuestionsOfTheDayTab ? true : false,
                grades: gradeFilter
            };

            if (this.props.myQuestions || this.props.currentTab == 7 || this.props.pinned) {
                delete reqBody.isQuestionOfTheDay;
            }


            this.service.post(url, reqBody, true).then(data => {
                let re = isSearch ? [] : this.state.discussions;
                if (data && data.status) {
                    if (data.res && data.res.data && data.res.data.length > 0) {
                        re = re.concat(data.res.data)
                        this.setState((prevState) => {
                            return {
                                discussions: re,
                                currentScrollPosition: window.scrollY,
                                page: data.res.page + 1,
                                isLoading: false,
                            }
                        });
                    } else {
                        this.setState({ isLoading: false, stopRequests: isSearch ? false : true, discussions: re });
                    }
                } else {
                    this.setState({ isLoading: false, stopRequests: isSearch ? false : true, discussions: re });
                }
            });
        }
    }


    componentDidMount() {
        // localStorage.setItem('discussion_token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxYTYwNGY0NGQwN2I5NTNmYzViYjdjMiIsImlhdCI6MTYzODMyNTA1MSwiZXhwIjoxNjY5ODYxMDUxfQ.G8dkOH0vwlkUrqMlyDrf-onLBpdLcgvIfNN4OUbzzak')
        this.getDiscussions(this.state.sort, this.state.limit, this.state.page, this.state.search, false, this.state.gradeFilter);
    }

    _onScrollEvent = () => {
        const totalHeight = document.documentElement.scrollHeight;
        if (window.pageYOffset > totalHeight - 2000 && !this.state.isLoading && !this.state.stopRequests) {
            this.getDiscussions(this.state.sort, this.state.limit, this.state.page, this.state.search, false, this.state.gradeFilter)
        }
    }

    componentDidUpdate() {
        window.addEventListener('scroll', this._onScrollEvent);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this._onScrollEvent, { capture: false });
        this.setState({
            discussions: []
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.currentTab != this.props.currentTab) {

            this.setState({
                page: 1,
                stopRequests: false,
                search: '',
                page: 1,
                limit: 10,
                sort: '-createdAt'
            }, () => {
                this.getDiscussions(this.state.sort, this.state.limit, this.state.page, this.state.search, true, this.state.gradeFilter);
            })


        }
    }


    onsearchApply = (search, sort, gradeFilter) => {
        this.setState({
            search,
            sort,
            gradeFilter,
            stopRequests: false,
        }, () => { this.getDiscussions(sort, this.state.limit, 1, search, true, gradeFilter) });
    }

    onCreateDiscussion = (data) => {
        if (!data.isQuestionOfTheDay) {
            this.setState((prevState) => ({
                discussions: [data, ...prevState.discussions].flat(),
            }));
        }
    }


    render() {
        return (
            <>
                <div >
                    <SearchBar key={this.props.currentTab} onsearchApply={this.onsearchApply} />
                </div>
                <div>
                    <Authorizer permId={PERMISSIONS.DISCUSSION_BOARD_CERATE_DISCUSSION} >
                        {this.props.currentTab == 1 && <CreateDiscussion onCreateDiscussion={this.onCreateDiscussion} />}
                    </Authorizer>
                </div>
                {this.state.discussions.map(discussion => {
                    return <div key={discussion._id} ><Discussion id={discussion._id} discussion={discussion} currentTab={this.props.currentTab} /></div>
                })}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        discussionUser: state.currentUserPerms && state.currentUserPerms.discussionUser && state.currentUserPerms.discussionUser,
        assignedSubjects: state.currentUserPerms && state.currentUserPerms.userInfo && state.currentUserPerms.userInfo.assignedSubjects,
    }
}

export default connect(mapStateToProps, {})(withRouter(AllDiscussions));