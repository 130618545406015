import React, { Component } from 'react'

import { connect } from 'react-redux';
import ImageEditor from '@toast-ui/react-image-editor';
import { Button } from 'primereact/button';
import { withRouter } from 'react-router-dom';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Form, } from "react-formio";
import './styles.scss';
import FileUploadLambda from '../fileUpload/fileUploadLambda';
import axios from 'axios';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import uuidv4 from 'uuid/v4';


const Abc = () => {
    return <>ddd</>

}



const myTheme = {
    "header.display": "none"
};

const myjson = {
    display: "pdf",
    settings: {
        pdf: {
            url: "https://s3-ap-south-1.amazonaws.com/classet-profiles-old/uploads%2FGet_Started_With_Smallpdf.pdf"
        }
    }
};




export class Practise extends Component {

    constructor(props) {
        super(props);
        this.state = {

            index: 0,

        }

    }
    _onFilsUploadError = (errMsg) => {
        //   this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    }
    _afterFilesUploaded = (files) => {

    }

    goToAnswerImage = (isNext) => {

        // let index = this.state.index;
        // if (isNext) {
        //     index += 1;

        // } else {
        //     index -= 1;
        // }

        // let urls = ['https://s3-ap-south-1.amazonaws.com/classet-profiles-old/uploads%2FIMG20220727104315.jpg',
        //     //   'https://classet-profiles-old.s3.ap-south-1.amazonaws.com/uploads/2022-05-06T09%3A59%3A46.964Z2091665.png',
        //     'https://classet-profiles-old.s3.ap-south-1.amazonaws.com/uploads/2022-07-22T14%3A45%3A06.326Zboats-night.jpeg',
        //     'https://classet-profiles-old.s3.ap-south-1.amazonaws.com/uploads/2022-07-22T14%3A55%3A11.034ZSampleImage%20%281%29.png',
        //     //   'https://classet-profiles-old.s3.ap-south-1.amazonaws.com/uploads/2022-04-16T09%3A07%3A13.089Z96270e80-7d80-11eb-bfa4-fd5341ef1f00-12.JPG',
        //     'https://classet-profiles-old.s3.ap-south-1.amazonaws.com/uploads/2022-07-22T14%3A59%3A23.095Z360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg'
        // ];


        // let abc = this.editorRef;

        // abc.imageEditorInst.loadImageFromURL(urls[index], 'test');
        // //  abc.imageEditorInst.addImageObject(urls[index]);


        // this.setState({
        //     index: index
        // })

    }



    onSelect2 = async (e) => {
        let url = 'https://t1bj3xkma3.execute-api.ap-south-1.amazonaws.com/prod/v1/data/presign/url';
        let cloudFrontEndpoint = 'https://d77cp301psyf3.cloudfront.net/';
        let uploadedFiles = [];
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {

            let { data } = await axios.post(url, {
                "type": "insert",
                "key": files[i].name.replace(/\s/g, "")
            });
            let uploadStatus = await axios.put(data.data.presignedURL, files[i], {
                headers: {
                    "Content-Type": "binary/octet-stream",
                    "Access-Control-Allow-Origin": "*",
                }
            });
            uploadedFiles.push({
                url: cloudFrontEndpoint + data.data.contentStorageKey,
                name: files[i].name,
                size: files[i].size,
                type: files[i].type,
                status: uploadStatus.status == 200 ? true : false
            });
        }


        //  console.log(uploadedFiles, 'uploadedFiles')
    }
    convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    onSelect = async (e) => {

        const file = e.target.files[0];
        const base64 = await this.convertToBase64(file);
        console.log({ myFile: base64 });

        this.setState({
            base64
        })

        axios.post('http://localhost:3009/v1/data/files/upload', {
            base64Image: base64,
        }, {
            headers: {

                "Access-Control-Allow-Origin": "*",
            }
        }).then(({ status }) => {
            console.log(status)

        })



        // let url = 'https://o19bipw0h1.execute-api.ap-south-1.amazonaws.com/prod/v1/data/presign/url';
        // let cloudFrontEndpoint = 'https://d77cp301psyf3.cloudfront.net/';
        // let uploadedFiles = [];
        // let files = e.target.files;

        // let promises = [];
        // for (let i = 0; i < files.length; i++) {
        //     uploadedFiles.push({
        //         name: files[i].name,
        //         size: files[i].size,
        //         type: files[i].type,
        //     });
        //     promises.push(Promise.resolve(axios.post(url, {
        //         "type": "insert",
        //         "key": files[i].name.replace(/\s/g, "")
        //     }).then(({ data }) => data)));
        // }

        // Promise.all(promises).then((data) => {
        //     let fileUploadPromises = [];
        //     for (let i = 0; i < files.length; i++) {
        //         uploadedFiles[i].s3StorageKey = data[i].data.contentStorageKey;
        //         uploadedFiles[i].s3Url = cloudFrontEndpoint + data[i].data.contentStorageKey;
        //         fileUploadPromises.push(Promise.resolve(axios.put(data[i].data.presignedURL, files[i], {
        //             headers: {
        //                 "Content-Type": "binary/octet-stream",
        //                 "Access-Control-Allow-Origin": "*",
        //             }
        //         }).then(({ status }) => status)))
        //     }

        //     Promise.all(fileUploadPromises).then((responses) => {
        //         
        //         for (let i = 0; i < uploadedFiles.length; i++) {
        //             uploadedFiles[i].status = responses == 200 ? true : false
        //         }
        //         console.log(uploadedFiles, 'up');


        //     });
        // });


        // this.saveProjDocFilesByFolder({}, e.target.files).then(function (data) {

        //     console.log(data)

        // })
    }


    uploadFilesToS3 = (files, callBack) => {
        let url = 'https://t1bj3xkma3.execute-api.ap-south-1.amazonaws.com/prod/v1/data/presign/url';
        let cloudFrontEndpoint = 'https://d77cp301psyf3.cloudfront.net/';

        let uploadedFiles = [];

        let promises = [];
        for (let i = 0; i < files.length; i++) {
            uploadedFiles.push({
                name: files[i].name,
                size: files[i].size,
                type: files[i].type,
            });
            promises.push(Promise.resolve(axios.post(url, {
                "type": "insert",
                "key": files[i].name.replace(/\s/g, "")
            }).then(({ data }) => data)));
        }

        Promise.all(promises).then((data) => {
            let fileUploadPromises = [];
            for (let i = 0; i < files.length; i++) {
                uploadedFiles[i].s3StorageKey = data[i].data.contentStorageKey;
                uploadedFiles[i].s3Url = cloudFrontEndpoint + data[i].data.contentStorageKey;
                fileUploadPromises.push(Promise.resolve(axios.put(data[i].data.presignedURL, files[i], {
                    headers: {
                        "Content-Type": "binary/octet-stream",
                        "Access-Control-Allow-Origin": "*",
                    }
                }).then(({ status }) => status)))
            }

            Promise.all(fileUploadPromises).then((responses) => {

                for (let i = 0; i < uploadedFiles.length; i++) {
                    uploadedFiles[i].status = responses[i] == 200 ? true : false
                }
                console.log(uploadedFiles, 'up');

                callBack(uploadedFiles)
            });
        });


    }


    saveProjDocFilesByFolder = (req, filesToUpload) => {
        return Promise.resolve(
            this.uploadFilesToS3(filesToUpload, (response) => {
                if (response && response.length && response.every(a => a.status)) {
                    req.projFileTo.forEach(file => {
                        let uploadedFile = response.find(f => f.name == file.name);
                        file.s3StorageKey = uploadedFile.s3StorageKey;
                        file.s3Url = uploadedFile.s3Url;
                    })
                    var result = Promise.resolve(axios.post('https://t1bj3xkma3.execute-api.ap-south-1.amazonaws.com/prod/v1/data/presign/url', {
                        "type": "insert",
                        "key": 'dfdfdf.png'
                    }));
                    return result;
                } else {
                    throw new Error('Some error occured.')
                }
            })
        );



    };



    uploadFilesToS3 = async (files) => {
        let url = 'https://t1bj3xkma3.execute-api.ap-south-1.amazonaws.com/prod/v1/data/presign/url';
        let cloudFrontEndpoint = 'https://d77cp301psyf3.cloudfront.net/';
        let uploadedFiles = [];
        for (let i = 0; i < files.length; i++) {

            let { data } = await axios.post(url, {
                "type": "insert",
                "key": files[i].name.replace(/\s/g, "")
            });
            let uploadStatus = await axios.put(data.data.presignedURL, files[i], {
                headers: {
                    "Content-Type": "binary/octet-stream",
                    "Access-Control-Allow-Origin": "*",
                }
            });
            uploadedFiles.push({
                url: cloudFrontEndpoint + data.data.contentStorageKey,
                name: files[i].name,
                size: files[i].size,
                type: files[i].type,
                status: uploadStatus.status == 200 ? true : false
            });
        }


        return uploadedFiles;
    }


    handleDownload = (url, filename) => {
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                const url = window.URL.createObjectURL(
                    new Blob([res.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    filename,
                );
                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                // fileDownload(res.data, filename)
            })
    }

    downloadFile = () => {
        let url = 'https://t1bj3xkma3.execute-api.ap-south-1.amazonaws.com/prod/v1/data/presign/url';
        let s3Url = 'https://d77cp301psyf3.cloudfront.net/ae3805c0-3999-11ed-a3bc-b71ad4b464b8-Screenshot(5).png';
        let s3StorageKey = 'ae3805c0-3999-11ed-a3bc-b71ad4b464b8-Screenshot(5).png';
        let fileName = 'test.png';
        let isSecure = false;


        if (isSecure) {
            if (s3StorageKey && fileName) {
                axios.post(url, {
                    "type": "read",
                    "key": s3StorageKey
                }).then(({ data }) => {
                    if (data && data.data) {

                        this.handleDownload(data.data, fileName)
                    }
                })
            }
        } else {
            if (s3Url && fileName) {
                this.handleDownload(s3Url, fileName)
            }
        }
    }
    uploadFile = () => {
        axios.post('http://localhost:3009/v1/data/files/upload', {
            base64Image: this.state.base64,
        }, {
            headers: {

                "Access-Control-Allow-Origin": "*",
            }
        }).then(({ status }) => {
            console.log(status)

        })
    }

    render() {
        // const { index } = this.state;
        // let urls = ['https://s3-ap-south-1.amazonaws.com/classet-profiles-old/uploads%2FIMG20220727104315.jpg',
        //     'https://classet-profiles-old.s3.ap-south-1.amazonaws.com/uploads/2022-05-06T09%3A59%3A46.964Z2091665.png',
        //     'https://classet-profiles-old.s3.ap-south-1.amazonaws.com/uploads/2022-07-22T14%3A45%3A06.326Zboats-night.jpeg',
        //     'https://classet-profiles-old.s3.ap-south-1.amazonaws.com/uploads/2022-07-22T14%3A55%3A11.034ZSampleImage%20%281%29.png',
        //     'https://classet-profiles-old.s3.ap-south-1.amazonaws.com/uploads/2022-04-16T09%3A07%3A13.089Z96270e80-7d80-11eb-bfa4-fd5341ef1f00-12.jpg',
        //     'https://classet-profiles-old.s3.ap-south-1.amazonaws.com/uploads/2022-07-22T14%3A59%3A23.095Z360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg'
        // ];

        return (
            <div>

                <label for="myfile">Select a file:</label>
                <input type="file" id="myfile" name="myfile" multiple={true} onChange={this.onSelect}></input>
                {/* <img src={this.state.base64 || ''} /> */}

                <button onClick={this.uploadFile}>
                    cccd
                </button>
                {/* <CKEditor
                // config={{
                //     plugins : [Abc]
                // }}
                    editor={ClassicEditor}
                    data="<p>Hello from CKEditor 5!</p>"
                    // onReady={editor => {
                    //     // You can store the "editor" and use when it is needed.
                    //     console.log('Editor is ready to use!', editor);
                    // }}
                    // onChange={(event, editor) => {
                    //     const data = editor.getData();
                    //     console.log({ event, editor, data });
                    // }}
                    // onBlur={(event, editor) => {
                    //     console.log('Blur.', editor);
                    // }}
                    // onFocus={(event, editor) => {
                    //     console.log('Focus.', editor);
                    // }}
                /> */}


                {/* <ImageEditor
                    includeUI={{
                        loadImage: {
                            path: '',
                            name: 'tst',
                        },
                        theme: myTheme,
                        menu: ['draw', 'text', 'shape',],
                        initMenu: 'draw',
                        uiSize: {
                            width: '100%',
                            height: '700px',
                        },
                        menuBarPosition: 'bottom',
                    }}
                    cssMaxHeight={500}
                    cssMaxWidth={700}
                    selectionStyle={{
                        cornerSize: 20,
                        rotatingPointOffset: 70,
                    }}
                    usageStatistics={false}
                    // ref={this.editorRef}
                    ref={(el) => this.editorRef = el}
                />
                {index}
                <p><span onClick={() => { this.goToAnswerImage(false) }}>P </span><span onClick={() => { this.goToAnswerImage(true) }}> N </span></p> */}
                {/* 
                <div className="container">
                    <Form form={myjson} onSubmit={console.log} />
                </div>
                 */}

                {/* <FileUploadLambda id={'edd'}
                    multiple={false}
                      onProgress={this._afterFilesUploaded}
                    onSucess={(fileName, data) => { }}
                    maxFileSize={33333307200}
                    onError={this._onFilsUploadError}
                    accept={'imagevideo'}
                    title={'videos'}
                    dirName=''
                  afterFilesUploaded={this._afterFilesUploaded}
                >
                    <label className='attachment-label' htmlFor={'edd'}>
                        Attachment <i className='pi pi-file' />
                    </label>
                </FileUploadLambda> */}
            </div >
        )
    }
}




const mapStateToProps = (state) => ({

});


export default connect(mapStateToProps, {

})((withRouter(Practise)));
