import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import Service from '../../services';
import { getOrgDataByCode, userSignIn, getUserData } from '../../store/actions';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../store/apiConstants';
import { isDigit, trimObj } from '../../utile';
import { getFormFields, isFormValid, onNumberChange, onTextChange } from '../../utile/formHelper';
import LoadingComponent from '../loadingComponent';
import './styles.scss'

const orgFormFields = [
    {
        "Type": "Text",
        "Label": "Title",
        "FieldName": "title",
        "Required": true,
    },
    {
        "Type": "Number",
        "Label": "OTP",
        "FieldName": "otp",
        "MinLength": 4,
        "MaxLength": 4,
        "Required": true,
    },
    {
        "Type": "Number",
        "Label": "Mobile",
        "FieldName": "mobile",
        "MinLength": 10,
        "MaxLength": 10,
        "Required": true,
    },
    {
        "Type": "Email",
        "Label": "Email",
        "FieldName": "email",
        "Required": true,
    },
    {
        "Type": "Text",
        "Label": "Username",
        "FieldName": "username",
        "MinLength": 5,
        "MaxLength": 20,
        "Required": true,
    },
    {
        "Type": "Password",
        "Label": "Password",
        "FieldName": "password",
        "Required": true,
        "MinLength": 8,
    },
    {
        "Type": "Password",
        "Label": "Confirm Password",
        "FieldName": "confirmPassword",
        "Required": true,
        "MinLength": 8,
    },
]


function encodeObj(obj) {
    Object.keys(obj).forEach(key => {
        obj[key] = btoa(obj[key])
    })
    return obj;
}

function decodeObj(obj) {
    Object.keys(obj).forEach(key => {
        obj[key] = atob(obj[key])
    })
    return obj;
}


class RegisterOrganization extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(orgFormFields, {});
        this.state = {
            otpCount: 0,
            org: this.formFields.data,
            formValidations: this.formFields.formValidations,

        }
        this.service = new Service();
    }

    componentDidMount() {

        if (localStorage.getItem('orgCode')) {
            return this.props.history.push('/');
        }

        let queryParams = new URLSearchParams(window.location.search);
        let email = queryParams.get("email");
        let title = queryParams.get("title");
        let mobile = queryParams.get("mobile");
        if (email && title && mobile) {
            this.setState((prevState) => {
                return {
                    org: {
                        ...prevState.org,
                        email,
                        title,
                        mobile
                    }
                };
            });

            // http://localhost:3001/register?title=s2ptech&email=shashi2puppy@gmail.com&mobile=7893916941&username=shashidhar&password=Meluha@321
            const url = `${baseUrlAdmin}/organization/initiate`;
            this.service.post(url, {
                email: email
            }, true).then(res => {
                if (res && res.status) {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'success', summary: 'Success', detail: 'Please enter OTP recieved on email.', life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                console.log(e);
                window.location.replace('https://macademy.in/');
            })
        } else {
            window.location.replace('https://macademy.in/');
        }
    }


    resendOTP = () => {

        if (this.state.otpCount < 2) {
            this.setState({
                isLoading: true
            });
            let url = `${baseUrlAdmin}/otp/mail/send/${this.state.email}`;


            this.service.post(url, {}, false).then(res => {

                if (res && res.status && res.res.success) {
                    this.setState((prevState) => {
                        return {
                            isLoading: false,
                            otpCount: prevState.otpCount + 1
                        };
                    });
                    this.toast.show({ severity: 'success', summary: 'OTP Sent successfully', detail: 'OTP sent. Please verify', life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }

            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            })
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'OTP resend limit exceeded', life: 3000 });
        }
    }


    // isFormValid = () => {
    //     const { org,
    //     } = this.state;




    // }

    isAdditionalValidOfStep3 = () => {
        let org = JSON.parse(JSON.stringify(this.state.org));
        let formValidations = this.state.formValidations

        let isValid = true;
        if (org.password != org.confirmPassword) {
            isValid = false;
            formValidations.fields['confirmPassword'] = { ...formValidations.fields['confirmPassword'], isValid: false, errorMsg: `Password and Confirm password is not matched.` }
        }

        this.setState((prevState) => {
            return {
                formValidations
            };
        });

        return isValid


    }
    verifyOTPAndCreateOrg = () => {
        const { org } = this.state;

        const formStatus1 = isFormValid(orgFormFields, this.formFields.formValidations, trimObj(org));

        let isCompleteFormValid = true;
        if (!formStatus1.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus1.formValidations,
            });
            isCompleteFormValid = false;
        } else if (!this.isAdditionalValidOfStep3()) {
            isCompleteFormValid = false;
        }


        if (isCompleteFormValid) {
            alert('sss')
            return;
            this.setState({
                isLoading: true
            });



            let url = `${baseUrlForLongRunningTask}/organization/orgdemocreate`;
            this.service.post(url, org, false).then(res => {


                if (res && res.status && res.res.status) {
                    this.setState((prevState) => {
                        return {
                            isLoading: false,

                        };

                    });

                    // let orgCode = res.res.data;
                    let orgCode = 'bea';

                    localStorage.setItem('orgCode', orgCode);
                    this.props.getOrgDataByCode(orgCode);
                    this.props.userSignIn((orgCode ? orgCode : '') + org.username, org.password);

                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage ? res.errMessage : res.res && res.res.message, life: 3000 });
                }

            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            })


        }
    }


    componentDidUpdate(prevProps) {
        if (prevProps.authenticated !== this.props.authenticated) {
            if (this.props.authenticated) {

                this.props.getUserData();
                this.props.history.push('/');

            }
        }
    }
    changePassword = (value, field) => {
        this.setState({
            [field]: value
        })
    }



    render() {
        const { org, formValidations } = this.state;
        return (
            <div>
                <div className='register-verify'>
                    <h2>Create Organization</h2>
                    <p className='p-txt'>Thank you for choosing MAcademy. Please fill the below details to complete registration.</p>
                    <p className='p-txt'>We sent a verification code to <b>{org.email}</b> email address. Please enter below</p>
                    <p><b>Organization name:</b> {org.title}</p>
                    <p><b>Phone Number:</b> {org.mobile}</p>
                    <p className='ma-label-s1 ma-no-pm'>OTP<span className='ma-required'>*</span></p>
                    <InputText
                        className='ma-w200 p-inputtext-style1'
                        value={org.otp}
                        onChange={(e) => { onNumberChange(e.target.value, 'otp', this, orgFormFields, org, formValidations, 'org', 'formValidations') }}
                    />
                    {formValidations && !formValidations.fields['otp'].isValid && <p className="p-error">{formValidations.fields['otp'].errorMsg}</p>}

                    <p className='ma-label-s2'>User Name<span className='ma-required'>*</span></p>
                    <InputText value={org.username}
                        onChange={(e) => { onTextChange(e.target.value, 'username', this, orgFormFields, org, formValidations, 'org', 'formValidations') }}
                        className='ma-w200 p-inputtext-style1' />
                    {formValidations && !formValidations.fields['username'].isValid && <p className="p-error">{formValidations.fields['username'].errorMsg}</p>}
                    <p className='ma-label-s2'>Password<span className='ma-required'>*</span></p>
                    <Password
                        autocomplete="new-password"
                        toggleMask
                        className='ma-w200'
                        value={org.password}
                        onChange={(e) => { onTextChange(e.target.value, 'password', this, orgFormFields, org, formValidations, 'org', 'formValidations') }}
                    />
                    {formValidations && !formValidations.fields['password'].isValid && <p className="p-error">{formValidations.fields['password'].errorMsg}</p>}
                    <p className='ma-label-s2'>Confirm Password<span className='ma-required'>*</span></p>
                    <Password
                        autocomplete="new-password"
                        toggleMask
                        className='ma-w200'
                        value={org.confirmPassword}
                        onChange={(e) => { onTextChange(e.target.value, 'confirmPassword', this, orgFormFields, org, formValidations, 'org', 'formValidations') }}
                    />
                    {formValidations && !formValidations.fields['confirmPassword'].isValid && <p className="p-error">{formValidations.fields['confirmPassword'].errorMsg}</p>}
                    <Toolbar className="ma-toolbard ma-mt20" left={() => {
                        return <Button label="Resend OTP" className=' p-button-outlined' onClick={this.resendOTP} />
                    }} right={() => {
                        return <Button label="Verify & Create" className='' onClick={this.verifyOTPAndCreateOrg} />
                    }}></Toolbar>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {(this.state.isLoading) && <LoadingComponent />}
            </div>
        )
    }
}



const mapStateToProps = state => ({
    authenticated: state.auth && state.auth.authenticated,
});

export default connect(mapStateToProps, { getOrgDataByCode, userSignIn, getUserData })(RegisterOrganization);






// http://localhost:3001/register?title=s2ptech&email=shashidhar2katkam@gmail.com&mobile=7893916941&username=shashidhar&password=Meluha@321


// http://localhost:3001?org=bea


// https://portal.macademy.in/register?title=s2ptech&email=shashidhar2katkam@gmail.com&mobile=7893916941&username=shashidhar&password=Meluha@321


// https://portal.macademy.in?org=bea