import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { StaticConstants } from '../../constants';
import FileUpload from './../discussionBoard/common/fileUpload';
import _ from 'lodash';
import { getFileType } from '../../utile';
import AttachmentsUploadWithProgress from '../discussionBoard/common/attachmentUploadWithProgress';
import { AttachmentIcon } from '../svgIcons';

export default class ResourceUpload extends Component {
    constructor(props) {
        super(props)
        this.state = {
            uploadedFilesInfo: this.props.resources || []
        };
    }


    _onProgress = (filesInfo) => {

        let files = this.state.uploadedFilesInfo;
        files = files.map((file) => {
            if (file.name == filesInfo.name) {
                file = filesInfo;
            }
            return file;
        })

        this.setState((prevState) => {
            return { uploadedFilesInfo: files }
        });
    }

    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Invalid', detail: errMsg, life: 3000 });
    }

    _afterFilesUploaded = (filess) => {
        let files = _.cloneDeep(Array.from(filess));
        let uploadedFilesInfo = this.state.uploadedFilesInfo;
        for (let i = 0; i < files.length; i++) {
            if (!uploadedFilesInfo.find(a => a.name == files[i].name)) {
                uploadedFilesInfo = [...uploadedFilesInfo, files[i]]
            }
        }
        this.setState((prevState, prevProps) => ({
            uploadedFilesInfo
        }));
    }

    _onSuccess = (fileName, data) => {
        let files = this.state.uploadedFilesInfo;
        files = files.map((file) => {
            if (file.name == fileName) {
                file.s3Info = data;
                file.uri = data.Location;
            }
            return file;
        })
        this.setState((prevState) => {
            return { uploadedFilesInfo: files }
        });

    }

    removeAttachment = (attachment) => {

        let uploadedFilesInfo = this.state.uploadedFilesInfo.filter(a => a.newFileName != attachment.newFileName);
        this.setState({ uploadedFilesInfo });
    }

    saveUploadedResource = () => {

        const { uploadedFilesInfo } = this.state;
        if (uploadedFilesInfo && uploadedFilesInfo.length) {
            let uploadInProgress = false;
            for (let i = 0; i < uploadedFilesInfo.length; i++) {
                if (!uploadedFilesInfo[i].uri) {
                    uploadInProgress = true;
                    break;
                }
            }
            if (uploadInProgress) {

                this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please wait attachments are uploading...', life: 3000 });
                return;
            }
        }
        //  else {
        //     this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select files to upload', life: 3000 });
        //     return;

        // }

        let attachmentT = uploadedFilesInfo.map(attach => {
            return {
                type: getFileType(attach.type),
                uri: attach.uri,
                name: attach.name,
                newFileName: attach.newFileName,
                mimeType: attach.type
            }
        })

        //   console.log(attachmentT, 'attachmentT');
        this.props.uploadFiles(attachmentT);

    }

    render() {
        return (
            <Dialog
                header='Upload Resources'
                visible={true}
                style={{ width: '50%' }}
                blockScroll={true}
                // modal
                footer={() => {
                    return (<div className='ma-mt20'>
                        <Button label="Cancel" className='p-button-outlined' onClick={this.props.onCancel} />
                        <Button label="Upload" className='' onClick={this.saveUploadedResource} />
                    </div>)
                }}
                draggable={false}
                // footer={this.footer}
                closeOnEscape={true}
                dismissableMask={true}
                // visible={true}
                closable={false}
                onHide={this.props.onCancel} >
                <div className='resource-attach'>
                    <div className='attach-w'>
                        <AttachmentsUploadWithProgress noPreview={true} attachments={this.state.uploadedFilesInfo} removeAttachment={this.removeAttachment} />
                    </div>
                    <hr />

                    <FileUpload id={"file" + new Date().getTime().toString().substring(4) + 12}
                        multiple={false}
                        onProgress={this._onProgress}
                        onSucess={this._onSuccess}
                        Reset={this.state.Reset}
                        maxFileSize={StaticConstants.MAX_FILE_SIZE}
                        onError={this._onFilsUploadError}
                        accept={'ivp'}
                        title={'add resource'}
                        afterFilesUploaded={this._afterFilesUploaded} >
                        <span style={{ position: 'relative', top: '5px' }}><AttachmentIcon height={20} width={20} /></span>

                    </FileUpload>
                </div>
                {this.state.errorMsg != '' && <p className="create-err">{this.state.errorMsg} </p>}

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </ Dialog>
        )
    }
}
