import * as ActionTypes from '../actionTypes';
import { userPool } from './awsProfile';

const dispatchSignOut = () => ({
  type: ActionTypes.USER_SIGN_OUT
});

const dispatchClearAllData = () => ({
  type: ActionTypes.CLEAR_ALL_DATA
});

const dispatchUnAuthUser = () => ({
  type: ActionTypes.UN_AUTH_USER
});

export const userSignOut = () => (dispatch) => {
  dispatch(dispatchSignOut());
  signOutUser(dispatch)
    .then((res) => {
      dispatch(dispatchClearAllData());
    })
    .catch((err) => {});
};

function signOutUser(dispatch) {
  const p = new Promise((res, rej) => {
    const cognitoUser = userPool.getCurrentUser();
    // localStorage.removeItem('user_token');
    // localStorage.removeItem('perms');
    // localStorage.removeItem('name');
    // localStorage.removeItem('picture');
    // localStorage.removeItem('isAdmin');
    // localStorage.removeItem('boardId');
    // localStorage.removeItem('discussion_uId');
    // localStorage.removeItem('discussion_token');

    localStorage.clear();
    dispatch(dispatchUnAuthUser());
    cognitoUser.signOut();
    window.location.reload();
  });
  return p;
}
