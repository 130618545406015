import { DataTable } from 'primereact/datatable'
import React, { Component } from 'react'
import SvgViewer from './../../../customComponents/svgViewer';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { warningDailogInit } from '../../../../utile';
import { InputText } from 'primereact/inputtext';
import Authorizer, { PERMISSIONS } from '../../../session/authorizer';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';


export default class SubjectWiseReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: [],
            examsResult: []
        };
    }

    getData = (examsResult) => {
        const data = examsResult && examsResult.length > 0 && examsResult.map((result) => {
            const answers = {};
            result.userAnswered && result.userAnswered.forEach((answer, index) => {
                let subject = "";
                let total = 0;
                Object.keys(answer).forEach((key, index) => {
                    if (key === "subjectName") {
                        subject = answer[key];
                    } else if (key === "rightAnswer") {
                        answers[subject + ' Right'] = answer[key];
                        total += parseInt(answer[key]);
                    } else if (key === "wrongAnswer") {
                        answers[subject + ' Wrong'] = answer[key];
                        total += parseInt(answer[key]);
                    } else if (key === "unAnswered") {
                        answers[subject + ' unAnswered'] = answer[key];
                        total += parseInt(answer[key]);
                    }
                });
                answers[subject + ' Total'] = total;
            });
            return {
                ...result,
                ...answers
            };
        });
        return data;
    };


    getColumns = (examsResult) => {
        let columns = [];
        examsResult && examsResult.length > 0 && examsResult[0].userAnswered && examsResult[0].userAnswered.forEach((answer, index) => {
            let subject = "";
            Object.keys(answer).forEach((key, index) => {
                let name = null;
                if (key === "subjectName") {
                    subject = answer[key];
                } else if (key === "rightAnswer") {
                    name = subject + ' Right';
                } else if (key === "wrongAnswer") {
                    name = subject + ' Wrong';
                } else if (key === "unAnswered") {
                    name = subject + ' unAnswered';
                }
                name && (key !== "subjectName") && columns.push({
                    label: name,
                    field: name,
                })
            });


        });
        return columns;
    }



    componentDidMount() {
        const { examsResult } = this.props;
        if (examsResult) {
            let c = this.getColumns(examsResult);

            this.setState({
                data: this.getData(examsResult),
                columns: c
            })
        }

    }



    componentDidUpdate(prevProps) {

        if (this.props.examsResult != prevProps.examsResult) {
            if (this.props.examsResult && this.props.examsResult.length) {
                let c = this.getColumns(this.props.examsResult);

                this.setState({
                    data: this.getData(this.props.examsResult),
                    columns: c
                })
            }

        }

    }
    exportCSV = () => {
        this.dt.exportCSV();
    }

    render() {

        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >Subject Wise Report</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={(e) => { this.setState({ globalFilter: e.target.value }) }} />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.BOARDS_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch, globalFilter: '' }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.BOARDS_VIEWCOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" onClick={(event) => this.menu.toggle(event)} data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer> */}
                        <Authorizer permId={PERMISSIONS.BOARDS_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                {/* <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} /> */}
            </div>
        );
        return (
            <div className="card datatable-crud-demo q-s-wise-report ma-mt20">
                <DataTable
                    ref={(el) => this.dt = el}
                    value={this.state.data}
                    className="ma-table-d mt-3"
                    tableClassName='ma-table-tbl'
                    scrollable


                    rows={10}
                    paginator
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={this.state.globalFilter}
                    header={header}>
                    <Column headerClassName='col-header' sortable field="userName" header="User Name" />
                    <Column headerClassName='col-header' sortable field="branchName" header="Branch" />
                    <Column headerClassName='col-header' sortable field="sectionName" header="Section" />
                    <Column headerClassName='col-header' sortable field="given_name" header="Given Name"/>
                    <Column headerClassName='col-header' sortable field="phoneNumber" header="Phone Number" />
                    <Column headerClassName='col-header' sortable field="boardName" header="Board" />
                    <Column headerClassName='col-header' sortable field="className" header="Class" />
                    {
                        this.state.columns.map(c => <Column sortable field={c.field} headerClassName='col-answer' header={c.label} />)
                    }
                    <Column headerClassName='col-header' sortable field="totalmarks" header="Total Marks" />
                </DataTable>

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div>
        )
    }
}
