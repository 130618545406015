export const selectUsers = (state) => {
  const { userData, roleData } = state.adminUserList.data;
  
  if (userData) {
    return userData.data.map((rec) => {
      const user = {
        _id: rec._id,
        userId: rec.user_id,
        ...rec.user_info
      };
      if (user.roles && roleData && roleData.data.length > 0) {
        user.roles = user.roles
          .map((roleId) => {
            const role = roleData.data.find((item) => item._id === roleId);
            if (role) {
              return role.roleName;
            }
            return '';
          })
          .join(',');
      } else {
        user.roles = '-';
      }
      return user;
    });
  }
  return [];
};
