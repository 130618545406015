import * as ActionTypes from "../actionTypes";

export default function(state={}, action){
    switch(action.type){
        case ActionTypes.REQUEST_GET_GRADE_DETAILS:
            return{
                ...state,
                loading:true,
                errorInfo:null,
                error:false
            }
        case ActionTypes.RECEIVED_GET_GRADE_DETAILS :
            return{
                ...state,
                loading:false,
                response:action.res,
                error:false
            }    
        case ActionTypes.ERROR_GET_GRADE_DETAILS :
            return{
                ...state,
                errorInfo:action.err,
                error:true,
                loading:true
            } 
        case ActionTypes.CLEAR_GET_GRADE_DETAILS :
            return{
                state:{}
            }
        default:
            return state
    }
}