import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';

const reportConstants = [
    { key: 'abuse', value: 'Abusive' },
    { key: 'inappropriate', value: 'Inappropriate' },
    { key: 'noclearinputs', value: 'No clear inputs' },
    { key: 'outofcurriculum', value: 'Out of curriculum' },
    { key: 'other', value: 'Other' },
]

class ReportDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            otherReason: '',
            otherReasonErr: '',
            errorMsg: ''

        }
    }


    handleChange = (e) => {
        this.setState({
            reason: e.target.value,
            otherReasonErr: ''
        });
    }

    submit = () => {


        let reason = this.state.reason;


        if (reason == 'other') {
            if (this.state.otherReason.length < 10) {
                this.setState({
                    otherReasonErr: 'Please enter atleast 10 characters'
                });
                return;
            } else {
                reason = this.state.otherReason;
            }
        }


        if (reason == '') {

            this.setState({
                errorMsg: 'Select reason'
            });


        } else {
            this.props.onSubmit(reason);
        }



    }


    render() {
        const { item } = this.state;
        //  console.log(this.state)
        return (
            <>

                <Dialog

                    draggable={false}
                    closeOnEscape={true}
                    className='ma-alert-no-header'
                    dismissableMask={true}
                    visible={this.props.open}
                    blockScroll={true}
                    closable={false}
                    style={{ maxWidth: '28vw', minWidth: '340px' }}
                    footer={() => {
                        return (<div>
                            <Button label="Cancel" className='p-button-outlined' onClick={this.props.onClose} />
                            <Button label="Submit" className='ma-m-lr10'
                                onClick={this.submit} />

                        </div>)
                    }}

                >

                    <div className="report-dialog">
                        <p className="report-title">Please select the reason for reporting</p>
                        {reportConstants.map((c) => {
                            return <div className='p-field-radiobutton ma-mt10' key={c.key}>  <RadioButton className="radio-inline" value={c.key} inputId={c.key} name="userType" onChange={this.handleChange} checked={this.state.reason === c.key} />
                                <label className='ma-label-s1' htmlFor={c.key}>{c.value}</label>
                            </div>
                        })}
                        {this.state.reason == 'other' && <><textarea placeholder="If other reason, please type here" className="report-textarea" rows="3" onChange={(e) => { this.setState({ otherReason: e.target.value }) }} cols="50" />
                            <span className="err-msg">{this.state.otherReasonErr}</span>
                        </>}
                        <span className="err-msg">{this.state.errorMsg}</span>
                    </div>
                </Dialog>

            </>
        )
    }
}

export default ReportDialog;