import * as ActionTypes  from '../actionTypes';
import axios from 'axios';
import { baseUrlAdmin } from '../apiConstants';



export const dispatchRequestUpdateSubject = data => ({
    type: ActionTypes.REQUEST_UPDATE_SUBJECT,
    data
})

export const dispatchReceivedUpdateSubject = res => ({
    type: ActionTypes.RECEIVED_UPDATE_SUBJECT,
    res
})

export const dispatchErrorUpdateSubject = err => ({
    type: ActionTypes.ERROR_UPDATE_SUBJECT,
    err
})

const dispatchClearUpdateSubject = () => ({
    type: ActionTypes.CLEAR_UPDATE_SUBJECT_DATA
})

export const clearUpdateSubjectData = () => dispatchClearUpdateSubject();


export const updateSubject = (subjectId, subjectName, subjectCode, method, isSubjectActive) => (dispatch, getState) => {  
  
  const selectedOrganizationDataIds = getState().selectedOrganizationDataIds;
  const curriculumData = getState().curriculumData;
  const { boardId, classId, groupId } = selectedOrganizationDataIds;
  let _subjects = (curriculumData && curriculumData.response && curriculumData.response.Item && curriculumData.response.Item.subjects) || [];
 
  let subjects = [];
  switch(method) {
    case 'updateSubject':
    if(_subjects && _subjects.length) {        
      _subjects.map(sub => {
        if(sub.subjectId === subjectId) {
          subjects.push({...sub, subjectName, subjectCode, isSubjectActive})
        }else{
          subjects.push(sub);
        }
      } )    
    }else{
      subjects.push({
        subjectId,
        subjectName,
        isSubjectActive,
        chapters: []
      })
    }
    break;
    case 'addSubject':  

      subjects=[..._subjects, {
        subjectId,
        subjectName,
        subjectCode,
        isSubjectActive,
        chapters: []
      }]
    break;
    case 'deleteSubject':
      subjects = _subjects.filter(sub => sub.subjectId !== subjectId);
    break;
    default: return
  }

  const subjectData = {
    boardId,
    classId,
    groupId,
    subjects
  }


   const _data =   {
        "boardId": "90b85367-1740-4324-a231-1f2a0ead10ae",
        "classId": "41f1ce5e-ccb8-1fce-e7ad-71c607ae5d9e",
        "groupId":  "7b896c4b-12fd-5a55-cdc4-f6b60cbbbfb0",
        "subjects": {
            "chapters": [
                {
                    "chapterDescription": "The Living World",
                    "chapterId": "2ee179a4-87e2-c0e1-1a6d-35448a0b7d75",
                    "chapterName": "The Living World",
                    "chapterNumber": "1",
                    "topics": [
                      {
                        "topicName": "Introduction",
                        "topicId": "e5f4706d-fdf7-576f-dfa0-ef16d83ace51"
                      },
                      {
                        "topicName": "What Is living?",
                        "topicId": "0d74e0ab-4667-8f13-342d-5bec5ae81fb7"
                      },
                      {
                        "topicName": "Diversity in The Living World",
                        "topicId": "643882d4-15ff-1db1-c68f-86ccfdf704a5"
                      },
                      {
                        "topicName": "Taxonomic Categories",
                        "topicId": "feffafe6-f57d-032b-3680-f18796c7d8b3"
                      },
                      {
                        "topicName": "Taxonomical Aids",
                        "topicId": "00cd08b6-2541-e849-2cd8-5ecf49da03c0"
                      }
                    ]
                }
            ],
            "subjectId": "eaea3c11-a71d-baa1-a964-5377764009cb",
            "subjectName": "Test Subject"
        }
    }


    if(subjects && subjects.length) {
      dispatch(dispatchRequestUpdateSubject());
      axios({
          method: 'post',
          url:  `${baseUrlAdmin}/subject`,
          data: subjectData,
      }).then(({data}) => {
          dispatch(dispatchReceivedUpdateSubject(data));
      }).catch(e => {
          dispatch(dispatchErrorUpdateSubject(e));
      });
    }else if(subjects && method === 'deleteSubject') {
      dispatch(dispatchRequestUpdateSubject());
      axios({
          method: 'post',
          url:  `${baseUrlAdmin}/subject`,
          data: subjectData,
      }).then(({data}) => {
          dispatch(dispatchReceivedUpdateSubject(data));
      }).catch(e => {
          dispatch(dispatchErrorUpdateSubject(e));
      });
    }  
}


