
import * as ActionTypes from './../actionTypes';

export default (state={},action) => {

    switch(action.type){
        case ActionTypes.REQUEST_CONTENT_PRE_SIGNED_URL:                
            return {
                ...state,
                loading:true,
                error:false,
                errorInfo:null
            }        
        case ActionTypes.RECEIVED_CONTENT_PRE_SIGNED_URL:
            return {
                ...state,
                loading:false,
                response:action.res,
                error:false,
                errorInfo:null
            }
        case ActionTypes.ERROR_CONTENT_PRE_SIGNED_URL:
            return{
                ...state,
                loading:false,
                error:true,
                errorInfo:action.err
            }
        case ActionTypes.CLEAR_CONTENT_PRE_SIGNED_URL_DATA:
            return {}
        default: return state;
    }
} 