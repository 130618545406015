import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import Service from '../../services';
import moment from 'moment';
import { baseUrlAdmin } from '../../store/apiConstants';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../loadingComponent';
import { Tooltip } from 'primereact/tooltip';
import { Menu } from 'primereact/menu';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default class AttandeesDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            students: [],
            globalFilter: '',
            columns: [
                { columnName: 'Created At', isVisible: false, id: 'createDateTime' }]
        }

        this.service = new Service();
    }


    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }
    exportCSV = () => {
        this.dt.exportCSV();
    }


    getAttendees = () => {
        this.setState({
            isLoading: true
        });
        const url = `${baseUrlAdmin}/vmeetingstudents/getattendance/${this.props.virtualClassId}`;
        this.service.get(url, true).then(res => {

            if (res && res.res && res.res.status) {

                this.setState({
                    isLoading: false,
                    students: res.res.data
                });


            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    }

    questionsAvailableR = () => {
        return <>
            <p className='avail-q'> Available Students:  <span>{this.state.students.length}</span> </p>
        </>
    }
    componentDidMount() {
        this.getAttendees();


    }

    render() {
        const header = (
            <div className="table-header  ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'>Students List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                        value={this.state.globalFilter}
                        onChange={(e) => {
                            this.setState({
                                globalFilter: e.target.value
                            })

                        }} />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        {/* <Authorizer permId={PERMISSIONS.USER_SEARCH} > */}
                        <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* </Authorizer> */}
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.USER_VIEWCOLUMNS} > */}
                        {/* <li><i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" /> */}
                        {/* </Authorizer>
                        <Authorizer permId={PERMISSIONS.USER_DOWNLOADCSV} > */}
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        {/* </Authorizer> */}
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );


        return (<><Dialog
            visible={true}
            style={{ width: '80%', minHeight: '90vh' }}
            header={'Attendees List'}
            blockScroll={true}
            modal
            draggable={false}
            closeOnEscape={true}
            dismissableMask={false}
            closable={true}
            onHide={this.props.onHide}>
            <div className="card datatable-crud-demo ma-m30">
                <DataTable ref={(el) => this.dt = el}
                    value={this.state.students}
                    dataKey="_id"
                    paginator
                    rows={10}
                    paginatorLeft={this.questionsAvailableR}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={this.state.globalFilter}
                    header={header}>
                    <Column filterField="userRef.user_info.username" field="userRef.user_info.username" header="Username" body={(rowData) => {
                        return <>{rowData.userRef.user_info.username.substring(3)}</>

                    }} />
                    <Column filterField="userRef.user_info.given_name" field="userRef.user_info.given_name" header="Given Name" />
                    <Column filterField="userRef.user_info.phone_number" field="userRef.user_info.phone_number" header="Phone Number" />
                    <Column field="createdAt" body={(rowData) => {
                        return (
                            <div className="ma-status">
                                {moment(rowData.createdAt).format('lll')}
                            </div>
                        )
                    }}
                        header="Start Date & Time" />
                </DataTable>
            </div>
        </Dialog>
            {
                (this.state.isLoading || this.props.isLoading) && <LoadingComponent />
            }
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </>
        )
    }
}
