import * as ActionType from "../actionTypes";

export default function(state={}, action){
    switch(action.type){
        case ActionType.SELECTED_QUESTION_TYPE:
            return{
                ...state,
                questionType:action.questionType
            }
        case ActionType.SELECTED_SKILL_TYPE:
            return{
                ...state,
                skillType:action.skillType
            }    
        case ActionType.SELECTED_DIFFICULTY_TYPE:
            return{
                ...state,
                difficultyType:action.difficultyType
            }  
        case ActionType.SELECTED_STATUS_TYPE:
            return{
                ...state,
                statusType:action.statusType
            }
        case ActionType.SELECTED_TAGS_TYPE:
            return{
                ...state,
                tags: action.tagsType
            }    
        case ActionType.SELECTED_QUESTION_FOR_TEST :
            return{
                ...state,
                testQuestionId:action.questionId,
                
            }    

        case ActionType.CREATE_DATA_FOR_TEST :
            return{
                ...state,
                createTestData: action.data,
                creteQuestionForTestError: null
            }   
        case ActionType.ERROR_DATA_FOR_TEST :
            return{
                ...state,
                creteQuestionForTestError: action.creteQuestionForTestError
            }      
        case ActionType.CLEAR_QUESTION_FILTERS:
            return{
                
            }    
            
        default: return state
    }
}