
import * as ActionTypes from './../actionTypes';

export default (state={},action) =>{

    switch(action.type){
        case ActionTypes.REQUEST_CREATE_PACKAGE:                
            return {
                ...state,
                loading:true,
                error:false,
                errorInfo:null,
                response: null,
            }
        
        case ActionTypes.RECEIVED_CREATE_PACKAGE:
            return {
                ...state,
                loading:false,
                response:action.res,
                error:false,
                errorInfo:null
            }

        case ActionTypes.ERROR_CREATE_PACKAGE:
            return{
                ...state,
                loading:false,
                error:true,
                errorInfo:action.err
            }

            case ActionTypes.CLEAR_CREATE_PACKAGE_DATA:
            return {}
            default: return state;

    }
} 