import * as ActionType from "../actionTypes";
import axios from 'axios';
import { baseUrlAdmin } from "../apiConstants";

const dispatchRequestEditQuestion = (data) => ({
   type:ActionType.REQUEST_EDIT_QUESTION,
   data,
});

const dispatchRecivedEditQuestion = (res) => ({
    type:ActionType.RECEIVED_EDIT_QUESTION,
    res
    
});

const dispatchErrorEditQuestion = (err) => ({
    type:ActionType.ERROR_EDIT_QUESTION,
    err

})

const dispatchCleareEditQuestion= () => ({
    type:ActionType.CLEAR_EDIT_QUESTION
    
});

const dispatchGetEditQuestionDetails = id => ({
    type:ActionType.GET_EDIT_QUESTION_DETAILS,
    editQuestionInfo:id
})

const dispatchClearGetQuestion = () => ({
    type:ActionType.CLEAR_GET_QUESTION
});

export const getEditQuestionDetail = (id) => (dispatch, getState) => {

     const store = getState() && getState().fetchQuestionInfo && getState().fetchQuestionInfo.questions;
     const filterQuestionInfo = store.find(questions => questions.questionId === id);
     
    dispatch(dispatchGetEditQuestionDetails(filterQuestionInfo))
}

export const updateQuestion = (data, questionId) => dispatch => {
   dispatch(dispatchRequestEditQuestion(data));   
   axios({
    method: 'PUT',      
    url: `${baseUrlAdmin}/question/${questionId}`,
    data: data
}).then((res) => {        
    dispatch(dispatchRecivedEditQuestion(res.data));
}).catch(e => {
    dispatch(dispatchErrorEditQuestion(e));
});
}

export const clearEditQuestion= () => dispatch => {
   dispatch(dispatchCleareEditQuestion());
}

export const clearGetQuestion = () => dispatch => {
    dispatch(dispatchClearGetQuestion())
}

