import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Service from '../../../services';
import SvgViewer from './../../customComponents/svgViewer';

import { Tooltip } from 'primereact/tooltip';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Menu } from 'primereact/menu';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import CreateBoard from './createBoard';
import cloneDeep from 'lodash/cloneDeep';
import './styles.scss'
import { warningDailogInit } from '../../../utile';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { WarningIcon } from '../../svgIcons';
import { I18Next } from './../../../internationalization';
class BoardsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearch: false,
            editBoard: null,
            globalFilter: null,
            isShowBoardCreate: false,
            warningDialog: warningDailogInit,
            columns: [{ columnName: 'Board Id', isVisible: false, id: 'boardId' }]
        }
    }

    chaptersCountTemplate = (rowData) => {
        return (
            <p >{rowData.chapters.length}</p>
        );
    }

    statusTemplate = (rowData) => {
        return (
            <div className="ma-status">
                <span className="ma-status-text">{rowData.boardActivity ? 'Active' : 'InActive'} </span>
                <Authorizer permId={PERMISSIONS.EDIT_BOARD} >
                    <InputSwitch onChange={(e) => { this.changeStatus(e, rowData) }} checked={rowData.boardActivity} />
                </Authorizer>
            </div>
        );
    }

    changeStatus = (e, rowData) => {
        let data = { ...rowData }
        if (e.value == true) {
            data.boardActivity = true
        } else {
            data.boardActivity = false
        }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You are changing this Board status ${e.value ? 'InActive' : 'Active'} to ${e.value ? 'Active' : 'InActive'}`
            },
            editBoard: data
        });

    }

    onConfirmChange = () => {
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        });

        let boardInfo = this.state.editBoard;

        let oldBoards = cloneDeep(this.props.boards);
        let boards = oldBoards.map(board => {
            if (board.boardId === boardInfo.boardId) {
                board.boardActivity = boardInfo.boardActivity;
            }
            return board
        });
        this.props.addOrUpdateBoardsData(boards);

    }


    editTemplate = (rowData) => {
        return (
            <>
                <Authorizer permId={PERMISSIONS.EDIT_BOARD} >
                    <span data-pr-tooltip="Edit Board" data-pr-position="bottom" className={`edit${rowData.boardId.substr(0, 5)}`} onClick={() => { this.props.showEditBoardDialog(rowData) }}>
                        <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" />
                    </span>
                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData.boardId.substr(0, 5)}`} />
                </Authorizer>
            </>
        );
    }

    showClassesTemplate = (rowData) => {
        const { defaultLanguage } = this.props
        const langType = defaultLanguage
        return (
            <Authorizer permId={PERMISSIONS.SHOW_CLASSES} >
                <Button label={I18Next('showClasses', langType).COMMON_KEY} className='p-button-outlined' onClick={() => { this.props.showClasses(rowData) }} />
            </Authorizer>
        );
    }

    checkIsDuplicate(boards, boardInfo, isEdit) {
        let err, errMsg;
        boards &&
            boards.length &&
            boards.forEach((board) => {
                if (isEdit) {
                    if (board.boardId != boardInfo.boardId) {
                        if (
                            board.boardCode.toLowerCase() === boardInfo.boardCode.toLowerCase() ||
                            board.boardName.toLowerCase() === boardInfo.boardName.toLowerCase()
                        ) {
                            err = true;
                            errMsg =
                                board.boardCode.toLowerCase() === boardInfo.boardCode.toLowerCase()
                                    ? 'Duplicate Board Code'
                                    : board.boardName.toLowerCase() === boardInfo.boardName.toLowerCase()
                                        ? 'Duplicate Board Name'
                                        : 'Enter Valid Details';
                            return;
                        }
                    }
                } else {
                    if (
                        board.boardCode.toLowerCase() === boardInfo.boardCode.toLowerCase() ||
                        board.boardName.toLowerCase() === boardInfo.boardName.toLowerCase()
                    ) {
                        err = true;
                        errMsg =
                            board.boardCode.toLowerCase() === boardInfo.boardCode.toLowerCase()
                                ? 'Duplicate Board Code'
                                : board.boardName.toLowerCase() === boardInfo.boardName.toLowerCase()
                                    ? 'Duplicate board Name'
                                    : 'Enter Valid Details';
                        return;
                    }
                }

            });

        return { err, errMsg };
    }

    addBoard = (boardInfo) => {

        if (this.props.isBoardAdd) {
            let { err, errMsg } = this.checkIsDuplicate(this.props.boards, boardInfo);
            if (err) {
                this.toast.show({ severity: 'error', summary: 'Error occured', detail: errMsg, life: 3000 });
            } else {
                let boards = cloneDeep(this.props.boards);

                boards.push({
                    boardId: boardInfo.boardId,
                    boardName: boardInfo.boardName,
                    boardActivity: boardInfo.boardActivity,
                    boardCode: boardInfo.boardCode,
                    classes: []
                });

                this.props.addOrUpdateBoardsData(boards);
            }

        } else {
            let boards = [];
            if (this.props.boards && this.props.boards.length) {
                let oldBoards = cloneDeep(this.props.boards);
                boards = oldBoards.map(board => {
                    if (board.boardId === boardInfo.boardId) {
                        board.boardName = boardInfo.boardName;
                        board.boardCode = boardInfo.boardCode;
                        board.boardActivity = boardInfo.boardActivity;
                    }
                    return board
                });
            }
            let { err, errMsg } = this.checkIsDuplicate(this.props.boards, boardInfo, true);
            if (err) {
                this.toast.show({ severity: 'error', summary: 'Error occured', detail: errMsg, life: 3000 });
            } else {
                this.props.addOrUpdateBoardsData(boards);
            }

        }
    }


    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }


    render() {
        const { defaultLanguage } = this.props
        const { board, formValidations } = this.state;
        const langType = defaultLanguage

        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'> {I18Next('boardsList', langType).COMMON_KEY}</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={(e) => this.setState({ globalFilter: e.target.value })} />}

                </div>

                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.BOARDS_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <Authorizer permId={PERMISSIONS.BOARDS_VIEWCOLUMNS} >
                            <li><i data-pr-tooltip="View Columns"
                                onClick={(event) => this.menu.toggle(event)}
                                data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.BOARDS_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        return (<>
            <Authorizer permId={PERMISSIONS.ADD_BOARD} >
                <div className='ma-mtb25'>
                    <Button label={I18Next('addBoard', langType).COMMON_KEY} className='ma-m-lr10'
                        onClick={this.props.showCreateBoard} />
                </div>
            </Authorizer>

            <div className="card datatable-crud-demo ma-m30">
                <DataTable ref={(el) => this.dt = el}
                    //  lazy
                    value={this.props.boards}
                    // paginatorLeft={() => {
                    //     return <>
                    //         <p className='avail-q'> Available Boards:  <span>{this.props.boards && this.props.boards.length}</span> </p>
                    //     </>
                    // }}
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                    dataKey="id"
                    paginator
                    rows={10}
                    responsiveLayout="scroll"
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Boards"
                    globalFilter={this.state.globalFilter}
                    header={header}>
                    <Column filter filterMatchMode='contains' sortable field="boardName" header={I18Next('boardName', langType).COMMON_KEY} />
                    <Column filter filterMatchMode='contains' sortable field="boardCode" header={I18Next('boardCode', langType).COMMON_KEY} />
                    {
                        this.isColumnVisible('boardId') && <Column field="boardId" header={I18Next('boardId', langType).COMMON_KEY}  />
                    }

                    <Column filter filterMatchMode='contains' sortable field="classes.length" header={I18Next('classes', langType).COMMON_KEY} />
                    <Column sortable field="boardActivity" header={I18Next('status', langType).COMMON_KEY} body={this.statusTemplate} />
                    <Column header={I18Next('edit', langType).COMMON_KEY} body={this.editTemplate} ></Column>
                    <Column header={I18Next('showClasses', langType).COMMON_KEY} body={this.showClassesTemplate}></Column>

                </DataTable>
                {this.props.isShowBoardCreate && <CreateBoard
                    isAdd={this.props.isBoardAdd}
                    hideCreateBoardDialog={this.props.hideCreateBoardDialog}
                    editBoard={this.props.editBoardData}
                    addBoard={this.addBoard} />}

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div>
            <Dialog
                //header={<></>}
                draggable={false}
                blockScroll={true}
                closeOnEscape={true}
                className='ma-alert-box'
                dismissableMask={true}
                closable={false}
                visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                footer={() => {
                    return (<div>
                        <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editBoard: null }) }} />
                        <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmChange} />

                    </div>)
                }}
                onHide={() => this.setState({ warningDialog: warningDailogInit, editBoard: null })}>
                <div>

                    <span className="warning-icon" >
                        <WarningIcon width={"100%"} height={"100%"} />
                    </span>
                </div>
                <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                <p className='ma-alert-msg'>{this.state.warningDialog.message}</p>
            </Dialog>
        </>
        )
    }
}
const mapStatesToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,

});

export default connect(mapStatesToProps, {})(BoardsList);
