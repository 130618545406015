import React, { Component } from 'react'
import Service from '../../../services';
import { connect } from 'react-redux';
import CkEditorComponent from '../../questionBank/common/ckEditor';
import { withRouter } from 'react-router-dom';
import { Toolbar } from 'primereact/toolbar';
import { Menu } from 'primereact/menu';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import SvgViewer from '../../customComponents/svgViewer';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {
    getBoardsData,
} from '../../../store/actions';
import { baseUrlAdmin } from '../../../store/apiConstants';
import SingleQuestion from '../../questionBank/common/singleQuestion';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { Dropdown } from 'primereact/dropdown';
import { getFormFields, isFormValid, onChipsChange, onTextChange, isFieldValid, isValidMobile, onNumberChange, onDropDownChange, onChoiceChange, onEmailChange, isValidEmail, isValidUrl } from '../../../utile/formHelper';
import { MultiSelect } from 'primereact/multiselect';
import { Difficulty, QuestionTypeKeysWithAQ, SkillTypes, Status } from '../../questionBank/constants';
import { BasicLazyParams, getServerTime } from '../../../utile';
import PreviewQuestion from '../../questionBank/common/previewQuestion';
import _, { cloneDeep, set } from 'lodash';
import uuidv4 from 'uuid/v4';
import LoadingComponent from '../../loadingComponent';
import QuestionInTable from '../../questionBank/common/questionInTable';
import moment from 'moment';
import { TabView, TabPanel } from 'primereact/tabview';
import { Chips } from 'primereact/chips';
import { DeleteIcon, PreviewIcon } from '../../svgIcons';
import { FIELDS_INFO } from '../../../constants';
import InputTextB from '../../customComponents/inputTextB';


class CreateSubjective extends Component {
    constructor(props) {
        super(props);
        this.state = {
            boards: [],
            classes: [],
            groups: [],
            subjects: [],
            boardId: null,
            classId: null,
            groupId: null,
            subjectId: [],
            boardName: null,
            className: null,
            groupName: null,
            subjectName: null,
            isLoading: false,

            showSearch: false,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            offlineTestName: "",
            subjectFields: "",
            boardErr: false,
            nameErr: false,
            classErr: false,
            subjectNameErr: false,
            boardErrMsg: "",
            nameErrMsg: "",
            classErrMsg: "",
            subjectNameErrMsg: "",
            selectedSubjects: [],
            instructions: ""
        }

        this.service = new Service();
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }



    formGradesDataForEdit = (editExam) => {

        if (this.props.boards && this.props.boards.length) {
            const _classes = [];
            const _groups = [];
            const selectedBoard =
                this.props.boards &&
                this.props.boards &&
                this.props.boards.length > 0 &&
                this.props.boards.find((board) => board.boardId === editExam.boardId);
            if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                selectedBoard.classes.map((item) => _classes.push({ className: item.className, classId: item.classId }));
            }

            const selectedClass = selectedBoard && selectedBoard.classes.find((_class) => _class.classId === editExam.classId);
            if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
                selectedClass.groups.map((item) => _groups.push({ groupName: item.groupName, groupId: item.groupId }));
                _groups.find((g) => g.groupId == editExam.groupId)

                this.setState(
                    {
                        boardId: editExam.boardId,
                        classId: editExam.classId,
                        groupId: editExam.groupId,
                        classes: _classes,
                        groups: _groups,
                        boardName: selectedBoard.boardName,
                        className: selectedClass.className,

                    }, () => {
                        this.getCurriculumData(true);
                    });
            }
        }
    }


    formQuestionsFromExam = (exam) => {

        let questions = [];
        exam.sections.forEach((s) => {
            questions = [...questions, ...s.questions]
        })



        return questions;
    }

    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }

        if (this.props.editExamId) {
            this.setState({
                isLoading: true
            });


            this.service.get(`${baseUrlAdmin}/examination/${this.props.editExamId}/exam-type/offlineTest`, true).then((data) => {
                if (data && data.res && data.res.Item && data.res.Item.length) {

                    this.setState({
                        // exam: data.res.Item[0],
                        // selectedExamQuestions: this.formQuestionsFromExam(data.res.Item[0]),
                        isLoading: false,
                        offlineTestName: data.res.Item[0].examName,
                        subjectId: data.res.Item[0].subjects.map(e => e.subjectId),
                        selectedSubjects: data.res.Item[0].subjects
                    }, () => this.formGradesDataForEdit(data.res.Item[0]));
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
            });



        }
    }

    // grades selection --- start
    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [],
            classId: null, groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,

        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ className: item.className, classId: item.classId }));
        }

        this.setState((prevState) => ({
            exam: {
                ...prevState.exam,
                boardId,
                boardName: selectedBoard.boardName,
            },
            classes: _classes,
            boardId,
            boardName: selectedBoard.boardName,
            resourseOrigin: selectedBoard.isBase ? 'upload' : null,
            selectedSubjects: []
        }));

    };

    onChangeClass = (classId) => {

        this.setState({

            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,

        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));

            this.setState((prevState) => {
                return {
                    exam: {
                        ...prevState.exam,
                        classId,
                        className: selectedClass.className,
                        groupId: _groups[0].value,
                        groupName: _groups[0].label,
                    },
                    groups: _groups,
                    classId,
                    className: selectedClass.className,
                    groupId: _groups[0].value,
                    groupName: _groups[0].label,
                    selectedSubjects: []
                }
            }, () => {
                this.getCurriculumData();
            }
            );
        }
    }


    onChangeSubject = (subjectId) => {
        let selectedSubject = this.state.subjects.find(s => s.subjectId == subjectId);

        this.setState((prevState) => {
            return {
                exam: {
                    ...prevState.exam,
                    subjectId,
                    subjectName: selectedSubject.subjectName,

                },
                subjectId
            }
        });
    }

    // grades selection --- end


    getSelectedCurriculum = (subjects) => {
        let { subjectId } = this.state.exam;
        return {
            subjects,
            subjectId,
        };
    }

    getCurriculumData = (isEdit) => {
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, true).then((data) => {



                if (data && data.res && data.res.Item) {
                    // let selected = isEdit ? data.res.Item.subjects : []
                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false,


                        // ...selected
                    }, () => {





                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    };

    onChangeChapter = (chapterId) => {
        let selectedChapter = this.state.chapters.filter((chapter) => chapter.chapterId == chapterId);
        this.setState(
            {
                topics: selectedChapter[0].topics,
                chapterId,
                chapterName: selectedChapter[0].chapterName,
                topicId: null,
                content: null,

            });
    };

    onChangeTopic = (topicId) => {
        let selectedTopic = this.state.topics.find(topic => topic.topicId == topicId);
        this.setState(
            {
                topicId,
                topicName: selectedTopic.topicName
            });
    };

    onMultiSelectChange = (e) => {
        if (e.length) {
            this.setState((prevState) => {
                return {
                    subjectId: e
                }
            })
            const subjectArr = this.state.subjects.filter(s => {
                return e.some(f => {
                    return f === s.subjectId
                })
            })
            if (subjectArr.length) {
                let arr = subjectArr.map(s => {
                    let obj = {}
                    let marks = this.state.selectedSubjects.find((f) => f.subjectId == s.subjectId)
                    obj.subjectName = s.subjectName
                    obj.subjectId = s.subjectId
                    obj.maxMarks = marks?.maxMarks || ""
                    return obj
                })
                this.setState({
                    selectedSubjects: arr
                })
            }
        } else {
            this.setState({
                subjectId: [],
                selectedSubjects: []
            })
        }
    }

    onProceed = () => {
        let { offlineTestName, boardId, classId, groupId, subjectId, } = this.state
        let formValid = true
        if (offlineTestName === "" || offlineTestName === null || offlineTestName === undefined) {
            formValid = false
            this.setState({ nameErr: true, nameErrMsg: "Enter Name of the offline test" })
        }
        if (boardId === "" || boardId === null || boardId === undefined) {
            formValid = false
            this.toast.show({ severity: 'error', summary: 'Select board', detail: "", life: 3000 });
        } else if (classId === "" || classId === null || classId === undefined) {
            formValid = false
            this.toast.show({ severity: 'error', summary: 'Select class', detail: "", life: 3000 });
        } else if (subjectId === "" || subjectId === null || subjectId === undefined) {
            formValid = false
            this.toast.show({ severity: 'error', summary: 'Select subject', detail: "", life: 3000 });
        }
        if (this.state.selectedSubjects.length) {
            let marksCheck = this.state.selectedSubjects.every(e => e.maxMarks !== "")
            if (marksCheck) {
                formValid = marksCheck
            } else {
                formValid = false
                this.toast.show({ severity: 'error', summary: 'Enter max marks', detail: "", life: 3000 });
            }

        }

        if (formValid) {
            if (this.props.editExamId) {
                let payload = {
                    examName: this.state.offlineTestName,
                    boardId: this.state.boardId,
                    classId: this.state.classId,
                    subjects: this.state.selectedSubjects,
                    instructions: this.state.instructions,
                    examType: "offlineTest",
                    boardName: this.state.boardName,
                    className: this.state.className,
                    groupId: this.state.groupId,
                    groupName: this.state.groupName,
                    gradeId: this.state.classId,
                    gradeName: this.state.className,
                }
                let url = `${baseUrlAdmin}/examination/${this.props.editExamId}/exam-type/offlineTest/update-exam`
                this.setState({ isLoading: true })
                this.service.put(url, payload, true).then(res => {
                    if (res?.status && res?.res?.success) {
                        this.setState({
                            isLoading: false
                        }, () => this.props.onCreateSuccess(true))
                    } else {
                        this.setState({ isLoading: false })
                        this.toast.show({ severity: 'error', summary: 'some error occured', detail: "", life: 3000 });
                    }
                }).catch(err => {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: 'some error occured', detail: err?.message, life: 3000 });
                })
            } else {
                let payload = {
                    examName: this.state.offlineTestName,
                    boardId: this.state.boardId,
                    classId: this.state.classId,
                    subjects: this.state.selectedSubjects,
                    instructions: this.state.instructions,
                    examType: "offlineTest",
                    boardName: this.state.boardName,
                    className: this.state.className,
                    groupId: this.state.groupId,
                    groupName: this.state.groupName,
                    gradeId: this.state.classId,
                    gradeName: this.state.className,
                }
                let url = `${baseUrlAdmin}/examination/add-exam-test`
                this.setState({ isLoading: true })
                this.service.post(url, payload, true).then(res => {
                    if (res?.status && res?.res?.success) {
                        this.setState({
                            isLoading: false
                        }, () => this.props.onCreateSuccess())
                    } else {
                        this.setState({ isLoading: false })
                        this.toast.show({ severity: 'error', summary: 'some error occured', detail: "", life: 3000 });
                    }
                }).catch(err => {
                    this.setState({ isLoading: false })
                    this.toast.show({ severity: 'error', summary: 'some error occured', detail: err?.message, life: 3000 });
                })
            }
        }
    }

    render() {
        const { exam, selectedSubjects, instructions } = this.state;

        return (<div className='create-quiz'>
            <>
                <div className='ma-p20 paper-s1 '>
                    <div className="p-grid ma-w100p " >
                        <div className="p-col-12 p-md-3">
                            <p className='ma-label-s2'>Name of the Test<span className='ma-required'>*</span></p>
                            <InputTextB info={FIELDS_INFO.EXAM_NAME} id="kexpT">
                                <InputText value={this.state.offlineTestName}
                                    onChange={(e) => { this.setState({ offlineTestName: e.target.value, nameErr: false, nameErrMsg: "" }) }}
                                    className='p-inputtext-style1 ma-w100p' />
                            </InputTextB>
                            {this.state.nameErr && <p className="p-error">{this.state.nameErrMsg}</p>}
                        </div>
                        <div className="p-col-12 p-md-3">
                            <p className='ma-label-s2'>Board Name<span className='ma-required'>*</span></p>
                            <InputTextB info={FIELDS_INFO.BOARD_SELECT} id="leeaeT">
                                <Dropdown value={this.state.boardId}
                                    className='ma-w100p'
                                    options={this.props.boards}
                                    optionLabel="boardName"
                                    optionValue="boardId"
                                    disabled={this.props.editExamId}
                                    onChange={(e) => this.onChangeBoard(e.value)}
                                    placeholder="Select Board" />
                            </InputTextB>
                        </div>
                        <div className="p-col-12 p-md-3 ">
                            <p className='ma-label-s2'>Class Name<span className='ma-required'>*</span></p>
                            <InputTextB info={FIELDS_INFO.CLASS_SELECT} id="leaaeT">
                                <Dropdown value={this.state.classId}
                                    options={this.state.classes}
                                    optionLabel="className"
                                    optionValue="classId"
                                    className='ma-w100p'
                                    disabled={this.props.editExamId}
                                    onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                            </InputTextB>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <p className='ma-label-s2'>Subject Name<span className='ma-required'>*</span></p>
                            <InputTextB info={FIELDS_INFO.SUBJECT_SELECT} id="leeaeT">
                                <MultiSelect value={this.state.subjectId}
                                    className='ma-w100p'
                                    options={this.state.subjects}
                                    optionLabel='subjectName'
                                    optionValue='subjectId'
                                    disabled={this.props.editExamId}
                                    onChange={(e) => this.onMultiSelectChange(e.value)}
                                    placeholder="Select Subjects" />
                            </InputTextB>
                        </div>
                    </div>
                    <div className='ma-mt20 ma-ml20'>
                        {selectedSubjects.length ? selectedSubjects.length && selectedSubjects.map((e, i) => {
                            return (
                                <>
                                    <div className='p-grid'>
                                        <div className='p-col-12 p-md-2 ma-mt20'>
                                            <p className='ma-label-s2'>{e?.subjectName}</p>
                                        </div>
                                        <div className='p-col-12 p-md-3 ma-mt10'>
                                            <InputText keyfilter= "num" value={selectedSubjects[i].maxMarks} className='p-inputtext-style1 ma-w100p' //placeholder={`Max ${e?.subjectName} marks`}
                                                onChange={(e) => {
                                                    if (e.target.value <= 100) {
                                                        let arr = cloneDeep(selectedSubjects)
                                                        arr[i].maxMarks = e.target.value
                                                        this.setState({
                                                            selectedSubjects: arr
                                                        })
                                                    } else {
                                                        this.toast.show({ severity: 'error', summary: 'Max marks upto 100', detail: '', life: 3000 });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>)
                        }) : <></>
                        }
                    </div>
                    <div className='p-grid ma-mt20'>
                        <div className="p-col-12 p-md-6">
                            <p className='ma-label-s2'>Instructions<span className='ma-required'>*</span></p>
                            <CkEditorComponent
                                disabled={true}
                                value={instructions}
                                onChange={(value) => this.setState({ instructions: value })}
                            />
                        </div>
                    </div>
                </div>
            </>
            <Toolbar className="ma-toolbard ma-mt20" right={() => <>
                <Button label={this.props.editExamId ? "Update" : "Save"} className='ma-m-lr10' onClick={this.onProceed} />
            </>}
            ></Toolbar>
            {
                (this.state.isLoading || this.props.isLoading) && <LoadingComponent />
            }
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </div >)
    }
}


const mapsStatesToProps = (state) => {
    return {
        boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
        isLoading: state.boardsData.loading,
    };
};

export default connect(mapsStatesToProps, {
    getBoardsData,
})(withRouter(CreateSubjective));
