import * as ActionTypes from './../actionTypes';



export default (state = {}, action) => {

    switch (action.type) {
        case ActionTypes.REQUEST_LINKING_VIDEO:

            return {
                ...state,
                loading: true,
                error: false,
                errorInfo: null,
                success: false
            }

        case ActionTypes.RECEIVED_LINKING_VIDEO:
            
            return {
                ...state,
                loading: false,
                response: action.res,
                success: true,
                error: false,
                errorInfo: null,
            }

        case ActionTypes.ERROR_LINKING_VIDEO:
            return {
                ...state,
                loading: false,
                error: true,
                errorInfo: action.err
            }


        case ActionTypes.CLEAR_LINKING_VIDEO:
            return {}
        default: return state;

    }
}