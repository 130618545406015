import * as ActionTypes from './../actionTypes';

const files = [];
function addMissingFiles(file) {
    files.push(file);
    return files
}

let successCount = 0;
let failureCount = 0;

export default (state={}, action) => {
    switch(action.type){
        case ActionTypes.REQUEST_BULK_UPLOAD:                
            return {
                ...state,
                loading:true,
                error:false,
                errorInfo:null,
                response: null,
                successCount,
                failureCount,
                uploadingContent: action.data,
                uploadContentIndex: action.index
            }
        
        case ActionTypes.RECEIVED_BULK_UPLOAD:
            return {
                ...state,
                loading:false,
                response:action.res,
                error:false,
                errorInfo:null,
                successCount: ++successCount
            }

        case ActionTypes.ERROR_BULK_UPLOAD:
            return{
                ...state,
                loading:false,
                error:true,
                errorInfo:action.err,
                missingFiles: action.errInfo,
                failureCount: ++failureCount
            }

            case ActionTypes.CLEAR_BULK_UPLOAD:
            return {}
            default: return state;
    }
} 