import * as ActionTypes from './../actionTypes';

export default function(state={packageDetails:[]}, action) {
    switch(action.type) {
        case(ActionTypes.REQUEST_PACKAGES_FOR_ASSIGN):
            return {
                ...state,
                loading: true,
                error: false
            }
        case(ActionTypes.RECEIVED_PACKAGES_FOR_ASSIGN):
            return {
                ...state,
                loading: false,
                error: false,
                data: action.data
            }
        case(ActionTypes.ERROR_PACKAGES_FOR_ASSIGN):
            return {
                ...state,
                loading: false,
                error: true,
                errorMsg: action.err
            }
        case(ActionTypes.CLEAR_PACKAGES_FOR_ASSIGN):
            return {}

        case(ActionTypes.REQUEST_USER_ASSIGNED_PACKAGES):
            return {
                ...state,
                loading: true,
                error: false,
                packageDetails:[]
            }
        case(ActionTypes.RECEIVED_USER_ASSIGNED_PACKAGES):
            return {
                ...state,
                loading: false,
                error: false,
                packageDetails: action.data.packageDetails && action.data.packageDetails
            }
        case(ActionTypes.ERROR_USER_ASSIGNED_PACKAGES):
            return {
                ...state,
                loading: false,
                error: true,
                errorMsg: action.err,
                packageDetails:[]
            }
        case(ActionTypes.CLEAR_USER_ASSIGNED_PACKAGES):
            return {packageDetails: []}
        default: return state
    }
}
