import React, { useEffect, useState } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import Authorizer, { PERMISSIONS, isAuthorized } from '../session/authorizer';
import Authentication from './../session';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { baseUrlAdmin } from '../../store/apiConstants';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import Navbar from './../navbar';
import { Column } from 'primereact/column';
import Service from './../../services';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { I18Next } from './../../internationalization';
import { useSelector, useDispatch } from "react-redux";
import InputTextB from './../customComponents/inputTextB';
import { Dropdown } from 'primereact/dropdown';
import { getRolesList } from '../../store/actions/rolesAction';
import { selectActiveAndAcademicRoles } from '../../store/selectors/authorize';
import { sumBy, set } from 'lodash'
import { Toast } from 'primereact/toast';

const BasicLazyParams = {
    first: 0,
    rows: 50,
    page: 0,
    search: '',
}

const items = [
    { label: 'Management' },
    { label: 'Approval Management', className: 'ma-active-item' },
];

const home = { icon: 'pi pi-home' }


const ApprovalManagement = () => {
    const [state, setState] = useState({
        assignConcessionToStudPopup: false,
        showSearch: false,
        totalRecords: 0,
        approvalList: [],
        globalSearch: '',
        isLoading: false,
        lazyParams: BasicLazyParams,
        feeInstallments: {}
    });
    const dispatch = useDispatch()
    const { feeInstallments, assignConcessionToStudPopup, showSearch, isLoading, lazyParams, globalSearch, approvalList, totalRecords } = state
    useEffect(() => {
        getApprovalList()
        dispatch(getRolesList())
    }, [dispatch])
    const approverId = localStorage.getItem('currentUserRole');
    const service = new Service();
    const reducerState = useSelector((redState) => redState);
    const langType = reducerState?.languageReducer?.language
    const rolesList = selectActiveAndAcademicRoles(reducerState)
    const [toast, setToast] = useState(null);

    const getApprovalList = () => {
        setState((prev) => ({ ...prev, isLoading: true }))
        if (approverId) {

            let url = `${baseUrlAdmin}/student-fee/get-concession/${approverId}`;
            service.get(url, true).then(res => {
                if (res && res.res.status && res.res.data) {
                    setState((prev) => ({
                        ...prev,
                        isLoading: false,
                        approvalList: res.res.data.map((r, index) => {
                            r.slNo = index + 1;
                            return r;
                        }),
                    }))
                } else {
                    setState((prev) => ({ ...prev, isLoading: false }))
                }
            }).catch((e) => {
                setState((prev) => ({ ...prev, isLoading: false }))
            });
        }
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Approval Management</h2>
            </React.Fragment>
        )
    }

    const onPage = (event) => {
        setState((prev) => ({ ...prev, lazyParams: event }));
    }

    const onGlobalSearch = (e) => {
        setState((prev) => ({
            ...prev, globalSearch: e.target.value,
            lazyParams: BasicLazyParams
        }));

    }

    const header = (
        <div className="table-header ma-tbl-header">
            <div className="ma-tbl-left">
                <h2 >Approval Mechanism Management</h2>
                {showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                    value={globalSearch}
                    onChange={onGlobalSearch} />}
            </div>
            <div className="p-input-icon-left ma-tbl-filter">
                <ul className="ma-tbl-li">
                    <Authorizer permId={PERMISSIONS.ADMIN_MANAGEMENT_GENERAL_SEARCH} >
                        <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { setState((prev) => ({ ...prev, showSearch: !showSearch })) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                    </Authorizer>
                </ul>
            </div>
        </div>
    );

    const getConcessionDialog = (installments, userId) => {
        setState((prev) => ({
            ...prev, assignConcessionToStudPopup: true,
            feeInstallments: installments
        }));
    }

    const onChangeSection = (key, field) => {
        let data = feeInstallments
        set(data, `${field}`, key)
        setState((prevState) => ({
            ...prevState,
            feeInstallments: data,
        }))
    }

    const onTextChange = (value, field, index) => {
        let data = feeInstallments
        set(data, `installments[${index}][${field}]`, value)
        setState((prevState) => ({
            ...prevState,
            feeInstallments: data,
        }))
    }

    const submitConcession = (status) => {
        // call api to submit concession
        setState((prevState) => ({
            ...prevState,
            isLoading: true
        }))
        let concessionData = feeInstallments
        concessionData.type = "Concession"
        concessionData.status = status ? "Approved" : "Rejected"
        concessionData.approver = feeInstallments?.approver._id || feeInstallments?.approver
        let url = `${baseUrlAdmin}/student-fee/update-concession-status/${feeInstallments?._id}`;
        if (concessionData) {
            service.put(url, concessionData, true).then(data => {
                if (data.status) {
                    getApprovalList()
                    toast && toast.show({ severity: 'success', summary: 'Success', detail: "Updated successfully", life: 3000 });
                }
                else {
                    toast && toast.show({ severity: 'error', summary: 'Error', detail: 'Some Error Occured', life: 3000 });
                }
                setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    assignConcessionToStudPopup: false,
                    feeInstallments: null
                }))
            });
        }
    }

    return (
        <div>
            <Navbar >
                <div className="p-grid ma-toolbar">
                    <div className="p-col-12 p-md-1 ma-no-p">
                    </div>
                    <div className="p-col-12 p-md-11 ma-no-pm">
                        <Toolbar className="ma-toolbard" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    </div>
                </div>

                <div className="admin-management ma-main">
                    <>
                        <Authorizer permId={PERMISSIONS.SHOW_ROLES_LIST} >
                            <div className="card datatable-crud-demo ma-m30">
                                <DataTable
                                    value={approvalList}
                                    dataKey="id"
                                    paginator
                                    lazy
                                    onPage={onPage}
                                    first={lazyParams.first}
                                    last={totalRecords}
                                    rows={lazyParams.rows}
                                    totalRecords={totalRecords}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    responsiveLayout="scroll"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                    globalFilter={""}
                                    header={header}>
                                    <Column field="slNo" header="Sr. No" body={(rowData) => {
                                        return <span>
                                            {(lazyParams.page * lazyParams.rows) + rowData.slNo}
                                        </span>
                                    }} />
                                    <Column field="createdAt" header="Date" />
                                    <Column field="type" header="Approval Type" />
                                    <Column field="status" header="Status" />
                                    <Column field="approver" body={(rowData) => rowData?.approver?.roleName} header="Apporved By" />
                                    <Column field="role" header="User Role" body={(rowData) => rowData?.approver?.roleType} />
                                    <Column
                                        body={(rowData) => {
                                            return <div className="p-d-flex ma-mlr10 user-edit-t">
                                                {["Approved"].includes(rowData?.status) ? null : <Button label={`View`} onClick={() => getConcessionDialog(rowData)} />}
                                            </div>
                                        }}
                                        header="Actions" />
                                </DataTable>
                            </div>
                        </Authorizer>
                    </>
                </div>

            </Navbar>
            <Dialog
                visible={assignConcessionToStudPopup}
                blockScroll={true}
                style={{ width: '40%' }}
                header={'Concession Details'}
                modal
                footer={() => {
                    return (<div className='ma-mt20'>
                        <Button label="Reject" className='p-button-outlined'
                            onClick={() => submitConcession(false)} />
                        <Button label='Approve' className='ma-m-lr10' onClick={() => submitConcession(true)} />
                    </div>)
                }}
                draggable={false}
                closeOnEscape={false}
                dismissableMask={false}
                closable={false}
                onHide={assignConcessionToStudPopup}
            >
                <div className="p-grid subject-add-dialog">
                    <div className="p-grid subject-add-dialog">
                        {Array.isArray(feeInstallments?.installments) && feeInstallments?.installments?.length > 0
                            &&
                            <>
                                <p>{feeInstallments?.feeType}</p><br />
                                <p>Student Details</p>
                                <DataTable className=''
                                    value={feeInstallments?.installments || []}>
                                    <Column header={I18Next('sNo', langType).COMMON_KEY} body={(rowData, index) => {
                                        return (
                                            <p>{index.rowIndex + 1}</p>
                                        )
                                    }} />
                                    <Column header={I18Next('installmentName', langType).COMMON_KEY} field='name' />
                                    <Column header={I18Next('amount', langType).COMMON_KEY} field='amount' />
                                    <Column header={I18Next('concession', langType).COMMON_KEY}
                                        body={(rowData, inde) => {
                                            return (
                                                <InputTextB info={"Enter concession amount"} id="cr1">
                                                    <InputText
                                                        value={rowData?.concession}
                                                        onChange={(e) => {
                                                            if (rowData?.amount >= parseInt(e.target.value)) {
                                                                onTextChange(parseInt(e?.target?.value), 'concession', inde?.rowIndex)
                                                            } else {
                                                                e.preventDefault()
                                                            }
                                                        }}
                                                        className='p-inputtext-style1' />
                                                </InputTextB>
                                            )
                                        }}
                                        field='conession' />
                                </DataTable>
                            </>}
                        <div >
                            <div className="p-col-12 p-md-2">
                                <p className='ma-label-s1'>Comment<span className='ma-required'></span></p>
                            </div>
                            <div className="p-col-12 p-md-10">
                                <InputTextB info={"Enter Comment"} id="cr1">
                                    <InputText
                                        value={feeInstallments?.comment}
                                        onChange={(e) => {
                                            onChangeSection(e.target.value, "comment")
                                        }}
                                        className='p-inputtext-style1' />
                                </InputTextB>
                            </div>
                        </div>

                        {/* <Dropdown
                            value={feeInstallments?.approver?._id || feeInstallments?.approver || ''}
                            className='ma-w100p '
                            optionLabel={"roleName"}
                            optionValue={"_id"}
                            options={rolesList || []}
                            onChange={(e) => onChangeSection(e.value, "approver")}
                            placeholder="Select Roles" /> */}
                    </div>
                </div>
            </Dialog>
            <Toast ref={(el) => setToast(el)} />

        </div >

    );
};

export default (Authentication(withRouter(ApprovalManagement)));