import * as ActionTypes from '../actionTypes';

const getInitialLanguage = () => {
    const storedLanguage = localStorage.getItem('defaultLanguage');
    return storedLanguage || 'en'; // Use 'en' as the default language if none is stored
};


let defaultLanguage = getInitialLanguage()


const languageReducer = (state = {
    language: defaultLanguage,
    isLoading: true,
    error: false,
    errorMessage: '',
}, action) => {
    switch (action.type) {
        case ActionTypes.GET_LANGUAGE:
            return {
                ...state,
                isLoading: true,
                error: false,
            }

        case ActionTypes.SET_LANGUAGE:
            return {
                ...state,
                isLoading: false,
                language: action.payload,
                error: false,
                errorMessage: null,
            }

        default: return state;

    }
}

export default languageReducer;