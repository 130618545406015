import React, { Component } from 'react';
import PDFViewer from 'mgr-pdf-viewer-react';
import { connect } from 'react-redux';

import {
    getVideoUrl
} from '../../../store/actions';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';



class PDFPlayer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scale: 1,
            videoInfo: {}
        };
        this.service = new Service();
    }


    getVideoUrl = (contentStorageKey) => {
        let url = `${baseUrlAdmin}/se3VideoFetch`;
        this.service.post(url, {
            preUrl: contentStorageKey
        }, true).then(res => {
            if (res && res.res) {
         
                this.setState((prevState) => {
                    return {
                        videoInfo: {
                            ...prevState.videoInfo,
                            [contentStorageKey]: res.res
                        },
                    };
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }


        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });

            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }

    componentDidMount() {
      //  if (this.props.contentInfo && this.props.contentInfo.contentStorageKey) {
            this.getVideoUrl(this.props.contentInfo.contentStorageKey)
      //  }
    }





    render() {
        //  console.log(this.props);
        return <>


            <div style={{ height: '100vh', backgroundColor: "#fff" }}>
                {/* <div className="p-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', width: '100%', top: "9%" }}>
                    <div style={{ width: 300, height: 40, backgroundColor: '#323643', display: 'flex', justifyContent: 'center' }}>
                        <Remove className="ml-2 mt-1" onClick={this.scaleDecrease} style={{ color: '#fff', width: 32, height: 32 }} />

                        <ZoomIn className="p-1 ml-2 mt-1" style={{ color: '#fff', width: 32, height: 32 }} />
                        <Add className="mt-1" onClick={this.scaleIncrease} style={{ color: '#fff', width: 32, height: 32 }} />

                    </div>

                </div> */}
                <div className="mt-5">

                    {this.state.videoInfo && this.state.videoInfo[this.props.contentInfo.contentStorageKey] && <>
                        <PDFViewer
                            document={{
                                fileType: 'pdf',
                                url: this.state.videoInfo && this.state.videoInfo[this.props.contentInfo.contentStorageKey],
                                // className="pdf_view"

                            }}
                            scale={this.state.scale}
                            css="customViewer"

                        // navigation={{
                        //     elements: {
                        //         previousPageBtn: CustomPrevButton,
                        //         nextPageBtn: CustomNextButton,
                        //         pages: CustomPages
                        //     }
                        // }}


                        />
                    </>}
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div>
        </>;
    }
}




const mapsStatesToProps = (state) => {
    return {
        videoInfo: state.getVideoUrlInfo && state.getVideoUrlInfo.allVideoUrls
    };
};


export default connect(mapsStatesToProps, {
    getVideoUrl
})(PDFPlayer);