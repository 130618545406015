import * as ActionType from "../actionTypes";

const initialState = {
    questions: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionType.REQUEST_FETCH_QUESTION:

            return {
                ...state,
                loading: true,
                errorInfo: null,
                error: false

            }
        case ActionType.RECIVED_FETCH_QUESTION:
            return {
                ...state,
                loading: false,
                errorInfo: null,
                error: false,
                response: action.res,
                questions: [...state.questions, ...action.questions],
                questionsCurriculum: action.questionsCurriculum

            }
        case ActionType.ERROR_FETCH_QUESTION:
            return {
                ...state,
                loading: false,
                errorInfo: action.err,
                error: true
            }
        case ActionType.CLEAR_FETCH_QUESTION:
            return { ...initialState }
        case ActionType.CLEAR_SEARCH_QUESTION:
            return {
                questions: []
            }
        default:
            return state

    }
}