import * as ActionTypes from '../actionTypes';

export default (state = {}, action) => {
    switch(action.type){
        case(ActionTypes.REQUEST_USER_DATA):
            return {
                ...state,
                loading:true,
                error:false,
                userDataSuccess: false
            }
        case(ActionTypes.RECEIVED_USER_DATA):
            return{
                ...state,
                loading:false,
                error:false,
                data: action.data,
                userIdsInfo: action.userIdsInfo,
                userDataSuccess: true,
                packageDetails: action.packageDetails,
                subscribedChapters: action.subscribedChapters
            }
        case(ActionTypes.ERROR_USER_DATA):
            return{
                ...state,
                loading:false,
                error:true,
                errorMsg :action.err,
                userDataSuccess: false
            }
        case(ActionTypes.IS_VALID_USER_DATA):
            return {
                ...state,
                isValidUserData: action.res
            }
         case(ActionTypes.CLEAR_USER_DATA):
            return {}
        default: return state;
    }
} 


