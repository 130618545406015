import * as ActionTypes  from '../actionTypes';
import axios from 'axios';
import { baseUrlAdmin } from '../apiConstants';


const dispatchRequestUpdateOrganizationData = data => ({
    type: ActionTypes.REQUEST_UPDATE_ORGANIZATION,
    data
})

const dispatchReceivedUpdateOrganizationData = res => ({
    type: ActionTypes.RECEIVED_UPDATE_ORGANIZATION,
    res
})

const dispatchErrorUpdateOrganizationData = err => ({
    type: ActionTypes.ERROR_UPDATE_ORGANIZATION,
    err
})

const dispatchClearUpdateOrganizationData = () => ({
    type: ActionTypes.CLEAR_UPDATE_ORGANIZATION_DATA
})

export const clearUpdateOrganizationData = () => dispatchClearUpdateOrganizationData();



export const updateBoard = ( boardId, boardName, boardCode, method, newBoardInfo, boardActivity) =>  (dispatch, getState) => { 
    const organizationData = getState().organizationData;
    const etag = organizationData && organizationData.etag;
    const boards = organizationData.response && organizationData.response.Item && organizationData.response.Item.boards

    let headers = {};
    if(etag) {
        headers = {
            "If-Match": etag
        }
    }

    switch(method) {
        case 'update':
        updateOrganizationBoard();
            break;
        case 'add':
            addOrganizationBoard();
            break;
        case 'delete':
            deleteOrganizationBoard();
            break;
        default: return;
    }

      
    function updateOrganizationBoard() {
       
        let newBoards = [];
        if(boards && boards.length) {
            boards.map( board => {
                if(board.boardId === boardId) {
                    newBoards.push({
                        ...board,
                        boardName,
                        boardCode,
                        boardActivity
                    });
                }else{
                    newBoards.push(board);
                }
            })
            updateOrganization(newBoards, headers, dispatch);
            
        }
    }


    function deleteOrganizationBoard() {
        let newBoards = [];
        if(boards && boards.length) {
          boards.map( board => {
              if(board.boardId === boardId) {
              }else{
                  newBoards.push(board);
              }
          })
          updateOrganization(newBoards, headers, dispatch);
          
      }
    }

    function addOrganizationBoard() {
        let newBoards = [];
        if(boards && boards.length) {
            newBoards = [...boards, newBoardInfo];
        }else{
            newBoards = [...newBoards, newBoardInfo];
        }
        updateOrganization(newBoards, headers, dispatch);                
    }

}

export const updateClass = ( classId, className, hasGroups, isActivity, method, newClassInfo ) => (dispatch, getState) => {

    const organizationData = getState().organizationData;
    const etag = organizationData && organizationData.etag;
    const boards = organizationData.response && organizationData.response.Item && organizationData.response.Item.boards;
    const selectedBoard = organizationData && organizationData.selectedBoard && organizationData.selectedBoard.length && organizationData.selectedBoard[0] && organizationData.selectedBoard[0];

    const boardId = selectedBoard && selectedBoard.boardId;

    let headers = {};
    if(etag) {
        headers = {
            "If-Match": etag
        }
    }

    switch(method) {
        case 'update':
        updateOrganizationClass();
            break;
        case 'add':
            addOrganizationClass();
            break;
        case 'delete':
            deleteOrganizationClass();
            break;
        default: return;
    }

    function updateOrganizationClass() {
        let newBoards = [];
        if(boards && boards.length) {
            boards.map( board => {
                if(board.boardId === boardId) {
                    if(board.classes && board.classes.length) {
                        let newClasses = [];
                        board.classes.map(_class => {
                            if(_class.classId === classId) {
                                newClasses.push({
                                    ..._class,
                                    classId, 
                                    hasGroups, 
                                    className, 
                                    isActivity
                                })
                            }else{
                                newClasses.push(_class);
                            }
                        })
                        newBoards.push({
                            ...board,
                            classes:newClasses
                        })

                    }
                }else{
                    newBoards.push(board);
                }
            })
        }        
        updateOrganization(newBoards, headers, dispatch);
    }

    function deleteOrganizationClass() {
        let newBoards = [];
        if(boards && boards.length) {
            boards.map( board => {
                if(board.boardId === boardId) {
                    if(board.classes && board.classes.length) {
                        let newClasses = [];
                        board.classes.map(_class => {
                            if(_class.classId === classId) {                                
                            }else{
                                newClasses.push(_class);
                            }
                        })
                        newBoards.push({
                            ...board,
                            classes:newClasses
                        })

                    }
                }else{
                    newBoards.push(board);
                }
            })
        }        
        updateOrganization(newBoards, headers, dispatch);
    }

    function addOrganizationClass() {
        let newBoards = [];
        if(boards && boards.length) {
            boards.map( board => {
                if(board.boardId === boardId) {
                    if(board.classes && board.classes.length) {                        
                        const classes = [...board.classes, newClassInfo];
                        const _board = {...board, classes};
                        newBoards.push(_board);
                    }else{
                        const classes = [newClassInfo];
                        const _board = {...board, classes};
                        newBoards.push(_board);
                    }
                }else{
                    newBoards.push(board);
                }
            })
        }        
        updateOrganization(newBoards, headers, dispatch);
    }
   
    
}

export const updateGroup = (groupId, groupName, method, newGroupInfo, isGroupActivity) => (dispatch, getState) => {
    const organizationData = getState().organizationData;
    const etag = organizationData && organizationData.etag;
    const boards = organizationData.response && organizationData.response.Item && organizationData.response.Item.boards;
    const selectedBoard = organizationData && organizationData.selectedBoard && organizationData.selectedBoard.length && organizationData.selectedBoard[0] && organizationData.selectedBoard[0];
    const selectedClass = organizationData && organizationData.selectedClass && organizationData.selectedClass.length && organizationData.selectedClass[0] && organizationData.selectedClass[0];

    const boardId = selectedBoard && selectedBoard.boardId;
    const classId = selectedClass && selectedClass.classId;

    let headers = {};
    if(etag) {
        headers = {
            "If-Match": etag
        }
    }

    switch(method) {
        case 'update':
        updateOrganizationGroup();
            break;
        case 'add':
            addOrganizationGroup();
            break;
        case 'delete':
            deleteOrganizationGroup();
            break;
        default: return;
    }

    function updateOrganizationGroup() {   
       
        let newBoards = [];
        if(boards && boards.length) {
            boards.map( board => {
                if(board.boardId === boardId) {
                    if(board.classes && board.classes.length) {
                        let newClasses = [];
                        board.classes.map( _class => {
                            if(_class.classId === classId) {
                                let newGroup = [];
                                if(_class.groups && _class.groups.length) {
                                    _class.groups.map(group => {
                                        if(group.groupId === groupId) {
                                            
                                            newGroup.push({
                                                ...group,
                                                groupName,
                                                isGroupActivity
                                            })
                                        }else{
                                            newGroup.push(group);
                                        }
                                    })
                                }
                                newClasses.push({
                                    ..._class,
                                    groups: newGroup

                                })
                            }else{
                                newClasses.push(_class);
                            }
                        })
                        newBoards.push({
                            ...board,
                            classes: newClasses
                        })
                    }
                   
                }else{
                    newBoards.push(board);
                }
            })
        }        
        updateOrganization(newBoards, headers, dispatch);

    }

    function deleteOrganizationGroup() {
        
        let newBoards = [];
        if(boards && boards.length) {
            boards.map( board => {
                if(board.boardId === boardId) {
                    if(board.classes && board.classes.length) {
                        let newClasses = [];
                        board.classes.map( _class => {
                            if(_class.classId === classId) {
                                let newGroup = [];
                                if(_class.groups && _class.groups.length) {
                                    _class.groups.map(group => {
                                        if(group.groupId === groupId) {
                                        }else{
                                            newGroup.push(group);
                                        }
                                    })
                                }
                                newClasses.push({
                                    ..._class,
                                    groups: newGroup

                                })
                            }else{
                                newClasses.push(_class);
                            }
                        })
                        newBoards.push({
                            ...board,
                            classes: newClasses
                        })
                    }
                   
                }else{
                    newBoards.push(board);
                }
            })
        }        
        updateOrganization(newBoards, headers, dispatch);

    }

    function addOrganizationGroup() {
        
        let newBoards = [];
        if(boards && boards.length) {
            boards.map( board => {
                if(board.boardId === boardId) {
                    if(board.classes && board.classes.length) {
                        let newClasses = [];
                        board.classes.map( _class => {
                            if(_class.classId === classId) {
                                let newGroup = [];
                                if(_class.groups && _class.groups.length) {
                                   newGroup = [..._class.groups, newGroupInfo]
                                }else{
                                    newGroup=[newGroupInfo];
                                }
                                newClasses.push({
                                    ..._class,
                                    groups: newGroup

                                })
                            }else{
                                newClasses.push(_class);
                            }
                        })
                        newBoards.push({
                            ...board,
                            classes: newClasses
                        })
                    }
                   
                }else{
                    newBoards.push(board);
                }
            })
        }        

        updateOrganization(newBoards, headers, dispatch);

    }
}

function updateOrganization(boards, headers, dispatch) {
    if(boards && boards.length) {       
         
     axios({
         method: 'put',
         url: `${baseUrlAdmin}/organization`,
         data: {boards: [...boards]},
         headers, 
     }).then((res) => {        
         dispatch(dispatchReceivedUpdateOrganizationData(res.data)); 
     }).catch(e => {
         dispatch(dispatchErrorUpdateOrganizationData(e));
     });
    }
}



export const updateOrganizationData = ( boardId, boardName, boardCode, method ) => (dispatch, getState) => {


}


