import * as ActionType from "../actionTypes";
import axios from 'axios';
import {
    baseUrlAdmin
} from "../apiConstants";
const dispatchRequestGetImageUrl = (data) => ({
    type: ActionType.REQUEST_GET_IMAGE_URL,
    data,
});

const dispatchReceivedGetImageUrl = (res, storageKey) => ({
    type: ActionType.RECEIVED_GET_IMAGE_URL,
    res,
    storageKey
});

const dispatchErrorGetImageUrl = (err) => ({
    type: ActionType.ERROR_GET_IMAGE_URL,
    err

})

const dispatchClearGetImageUrl = () => ({
    type: ActionType.CLEAR_GET_IMAGE_URL,

});



export const getImageUrl = (storageKey, s3Type = "main") => dispatch => {
    
    dispatch(dispatchRequestGetImageUrl(storageKey));
    axios({
        method: 'POST',
        url: `${baseUrlAdmin}/se3fetch`,
        data: {
            preUrl: storageKey,
            s3Type
        }
    }).then((res) => {
        dispatch(dispatchReceivedGetImageUrl(res.data, storageKey));
    }).catch(e => {
        dispatch(dispatchErrorGetImageUrl(e));
    });
}

export const clearImageUrl = () => dispatch => {
    dispatch(dispatchClearGetImageUrl());
}