import React from 'react';
import XLSX from 'xlsx';
import { connect } from 'react-redux';
import Navbar from './../../navbar/index';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { bulkUpload, clearBulkUploadData } from './../../../store/actions';
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import ExcelDataTable from './excelDataTable';
import LoadingComponent from '../../loadingComponent';
import Authorizer, { PERMISSIONS, isAuthorized } from './../../session/authorizer';
import Authentication from './../../session';
const items = [
    { label: 'Academics' },
    { label: 'Content Master' },
    { label: 'Bulk Upload', className: 'ma-active-item' }

];

const home = { icon: 'pi pi-home' }

const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'scroll',
    selectableRows: false
};

/* list of supported file types */
const SheetJSFT = ['xlsx', 'xlsb', 'xlsm', 'xls', 'csv']
    .map(function (x) {
        return '.' + x;
    })
    .join(',');

/* generate an array of column objects */
const make_cols = (refstr) => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
};

class BulkUpload extends React.Component {
    state = {
        open: false,
        openSnackBar: false,
        filteredData: [],
        newJsonSheet: [],
        showMissedListTable: false,
        showUploadBtn: false,
        showLoader: false
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };
    handleClickOpenDelete = () => {
        this.setState({ openDelete: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleDeleteClose = () => {
        this.setState({ openDelete: false });
    };

    componentWillUnmount() {
        this.props.clearBulkUploadData();
    }

    updateTable = () => {

        if (this.props.error && this.props.missingFiles && this.props.missingFiles.length) {
            this.setState({
                showLoader: false
            });
            if (
                this.state.newJsonSheet &&
                this.state.newJsonSheet.length &&
                this.props.missingFiles &&
                this.props.missingFiles.length
            ) {
                const jsonSheetWithMissingFiles = [];
                const jsonSheetOnlyMissingFiles = [];
                this.state.newJsonSheet.map((content) => {
                    let missingFile = false;
                    this.props.missingFiles.map((file) => {
                        if (content['Content Uri'] === file) {
                            missingFile = true;
                        }
                    });

                    if (missingFile) {
                        jsonSheetWithMissingFiles.push({
                            ...content,
                            missing: true
                        });
                        jsonSheetOnlyMissingFiles.push({
                            ...content
                        });
                    } else {
                        jsonSheetWithMissingFiles.push({
                            ...content,
                            missing: false
                        });
                    }
                });

                this.setState({
                    newJsonSheet: jsonSheetWithMissingFiles,
                    jsonSheetWithMissingFiles,
                    jsonSheetOnlyMissingFiles,
                    showMissedListTable: true
                });
            }
        }
    };
    componentDidUpdate(prevProps) {
        if (prevProps.missingFiles !== this.props.missingFiles) {
            if (this.props.missingFiles && this.props.missingFiles.length && this.props.error) {
                this.updateTable();
            }
        }

        if (prevProps.error !== this.props.error) {
            if (this.props.error) {
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'failed to add content or missing files', life: 3000 });
            }
        }

        if (prevProps.success !== this.props.success) {
            if (this.props.success) {
                this.toast.show({ severity: 'success', summary: 'Process Initiated', detail: 'Process is taking more time... please wait 5-10 mins. Do not Re-upload', life: 3000 });
            }
        }
    }

    handleFile = (file /*:File*/) => {
        this.setState({ fileSelected: file['name'] });
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        let data = [];
        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            data = XLSX.utils.sheet_to_json(ws, { raw: false });
            this.setState({ data: data, cols: make_cols(ws['!ref']) });

            const newJsonSheet = [];
            for (let i = 0; i < data.length; i++) {
                let excelRowData = {
                    ...data[i],
                    __rowNum__: undefined
                };

                excelRowData = JSON.parse(JSON.stringify(excelRowData));

                newJsonSheet.push(excelRowData);
            }
            this.setState({ newJsonSheet });

            for (const key in newJsonSheet) {
                if (newJsonSheet.hasOwnProperty(key)) {
                    const element = newJsonSheet[key];

                    var result = element.hasOwnProperty('Content Number');
                    if (result == false) {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Content Number not found', life: 3000 });
                        break;
                    }
                }
            }

            for (const key in newJsonSheet) {
                if (newJsonSheet.hasOwnProperty(key)) {
                    const element = newJsonSheet[key];
                    var result1 = element.hasOwnProperty('Content Title');

                    if (result1 == false) {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Content Title not found', life: 3000 });
                        break;
                    }
                }
            }

            for (const key in newJsonSheet) {
                if (newJsonSheet.hasOwnProperty(key)) {
                    const element = newJsonSheet[key];
                    var result2 = element.hasOwnProperty('Content Uri');
                    if (result2 == false) {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Content Uri not found', life: 3000 });
                        break;
                    }
                }
            }

            for (const key in newJsonSheet) {
                if (newJsonSheet.hasOwnProperty(key)) {
                    const element = newJsonSheet[key];
                    var result3 = element.hasOwnProperty('Content Type');
                    if (result3 == false) {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Content Type not found', life: 3000 });
                        break;
                    }
                }
            }

            for (const key in newJsonSheet) {
                if (newJsonSheet.hasOwnProperty(key)) {
                    const element = newJsonSheet[key];
                    var result4 = element.hasOwnProperty('Content Priority');
                    if (result4 == false) {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Content Priority not found', life: 3000 });
                        break;
                    }
                }
            }

            if (result && result1 && result2 && result3 && result4) {
                const filteredData = [];
                newJsonSheet.map((x) => {
                    filteredData.push({
                        boardId: x['Board Id'],

                        classId: x['Class Id'],
                        groupId: x['Group Id'],
                        subjectId: x['Subject Id'],
                        //   subjectName: x['Subject Name'],
                        chapterId: x['Chapter Id'],
                        // chapterName: x['Chapter Name'],
                        topicId: x['Topic Id'],
                        filelist: [
                            {
                                'topic.number': x['Content Number'],
                                'topic.name': x['Content Title'],
                                'topic.type.uri': x['Content Uri'],
                                'topic.type.contentType': x['Content Type'],
                                'topic.priority': x['Content Priority']
                            }
                        ]
                    });
                });
                this.setState({ filteredData, showUploadBtn: true });
            }
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    };

    handleChange = (e) => {
        const files = e.target.files;

        if (files && files[0]) this.handleFile(files[0]);
    };



    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Content Bulk Upload</h2>
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    render() {
        return (
            <div className='bulk-upload'>
                <Navbar >
                    <div className="p-grid ma-toolbar">
                        <div className="p-col-12 p-md-1 ma-no-p">

                        </div>
                        <div className="p-col-12 p-md-11 ma-no-pm">
                            <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        </div>
                    </div>
                    <div className="ask-a-doubt ma-main">
                        <Authorizer permId={PERMISSIONS.BULK_UPLOAD_GENERAL_CHOOSEFILE} >
                            <p className='spread-title'>Content Spreadsheet</p>
                            <InputText
                                className='p-inputtext-style1'
                                value={this.state.fileSelected} disabled />
                            <label htmlFor="file" className='select-file'>Select File</label>
                            <input
                                type="file"
                                className="form-control"
                                id="file"
                                hidden={true}
                                accept={SheetJSFT}
                                onChange={this.handleChange}
                                disabled={this.props.loading}
                            />
                            <div className='ma-clearFix'></div>
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.BULK_UPLOAD_GENERAL_UPLOAD_DATA} >
                            <Button label="Upload data" className='ma-mt10'
                                disabled={this.state.showMissedListTable || this.props.loading || !this.state.showUploadBtn}
                                onClick={() => {
                                    if (this.state.filteredData && this.state.filteredData.length) {
                                        this.props.bulkUpload(this.state.filteredData);
                                    }
                                }}
                                icon="pi pi-cloud-upload"
                            />
                            <p className='upload-note ma-mt10'><span>Note:</span> Bulk upload is given only to upload content stored in S3. It doesn't support third party uploads like Colearners,Erudex as of now.</p>
                        </Authorizer>

                        <div className='ma-clearFix'></div>

                        <div className='ma-mt20'>
                            <Authorizer permId={PERMISSIONS.BULK_UPLOAD_GENERAL_SHOW_MISSED_FILEDATA} >
                                <Button label="Show Missed Files Data" className='ma-mt10'
                                    disabled={!this.state.showMissedListTable}
                                    onClick={() => {
                                        if (this.state.showMissedListTable) {
                                            this.setState({
                                                newJsonSheet: this.state.jsonSheetOnlyMissingFiles
                                            });
                                        }
                                    }}
                                />
                            </Authorizer>
                            <Authorizer permId={PERMISSIONS.BULK_UPLOAD_GENERAL_SHOW_ALLDATA_WITH_MISSED_FILES} >
                                <Button label="Show All Data With Missed Files" className='ma-mt10 ma-ml10'
                                    disabled={!this.state.showMissedListTable}
                                    onClick={() => {
                                        if (this.state.showMissedListTable) {
                                            this.setState({
                                                newJsonSheet: this.state.jsonSheetWithMissingFiles
                                            });
                                        }
                                    }}
                                />
                            </Authorizer>
                        </div>

                    </div>


                    {this.state.newJsonSheet && this.state.newJsonSheet.length ? (
                        <ExcelDataTable data={this.state.newJsonSheet} />
                    ) : null}
                    {this.props.loading || this.state.showLoader ? <LoadingComponent /> : null}
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                </Navbar>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    loading: state.bulkUpload && state.bulkUpload.loading,
    error: state.bulkUpload && state.bulkUpload.error,
    success: state.bulkUpload && state.bulkUpload.response && state.bulkUpload.response.success,
    missingFiles: state.bulkUpload && state.bulkUpload.missingFiles,
    successFiles: state.bulkUpload && state.bulkUpload.successFiles,
    successCount: state.bulkUpload && state.bulkUpload.successCount,
    failureCount: state.bulkUpload && state.bulkUpload.failureCount,
    uploadingContent: state.bulkUpload && state.bulkUpload.uploadingContent && state.bulkUpload.uploadingContent,
    uploadContentIndex: state.bulkUpload && state.bulkUpload.uploadContentIndex && state.bulkUpload.uploadContentIndex
});

export default connect(mapStateToProps, { bulkUpload, clearBulkUploadData })(Authentication(BulkUpload));
