import React, { Component } from 'react'
import { Tooltip } from 'primereact/tooltip';
import SvgViewer from './svgViewer';
import { InputText } from 'primereact/inputtext';
import './styles.scss';

class PasswordText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowPassword: false,
            typee: 'password'
        }
    }


    render() {
        const { isShowPassword, typee } = this.state;
        return (
            <>

                <span className="p-input-icon-right">
                    <i className="pi" >
                        <span onClick={() => {
                            this.setState({
                                // isShowPassword: !isShowPassword,
                                typee: typee != 'text' ? 'text' : 'password'
                            })
                        }}
                            className='ma-eye-icon'>
                            {typee != 'text' ? <SvgViewer className='ma-icon-svg' src='new-assets/invisible-symbol.svg' width="20" height="20" /> :
                                <SvgViewer className='ma-icon-svg' src='new-assets/edit.svg' width="20" height="20" />}

                        </span>
                    </i>
                    <InputText
                        value={this.props.valuee}

                        onChange={(e) => { this.props.onChangee(e) }}
                        //className={this.props.classNames}
                        className='p-inputtext-style1 ma-w100p' />
                </span>

                {/* <div className='custom-password-w'>
                    <div className='custom-right-icon'>
                        <span onClick={() => {
                            this.setState({
                                isShowPassword: !isShowPassword,
                                typee: isShowPassword ? 'text' : 'password'
                            })
                        }}
                            className='ma-eye-icon'>
                            <SvgViewer className='ma-icon-svg' src='new-assets/invisible-symbol.svg' width="20" height="20" />
                        </span>
                    </div>
                    <div className='custom-left-input' >
                        <input
                            type={typee}
                            //type='text'
                            value={this.props.valuee}

                            onChange={(e) => { this.props.onChangee(e) }}
                            className={this.props.classNames}
                        //{...this.props}
                        />
                    </div>
                </div> */}
            </>
        );
    }

}



export default PasswordText;