
import * as ActionTypes from '../actionTypes';

export default (state={packageCodes:[]},action) => {

    switch(action.type){
        case ActionTypes.REQUEST_PACKAGE:                
            return {
                ...state,
                loading:true,
                error:false,
                errorInfo:null
            }        
        case ActionTypes.RECEIVED_PACKAGE:
            return {
                ...state,
                loading:false,
                response:action.res,
                error:false,
                errorInfo:null
            }
        case ActionTypes.ERROR_PACKAGE:
            return{
                ...state,
                loading:false,
                error:true,
                errorInfo:action.err
            }

        case ActionTypes.REQUEST_PACKAGE_CODES:                
            return {
                ...state,
                loading:true,
                error:false,
                errorInfo:null
            }        
        case ActionTypes.RECEIVED_PACKAGE_CODES:
            return {
                ...state,
                loading:false,
                packageCodes:action.res,
                error:false,
                errorInfo:null
            }
        case ActionTypes.ERROR_PACKAGE_CODES:
            return{
                ...state,
                loading:false,
                error:true,
                errorInfo:action.err
            }
        case ActionTypes.CLEAR_PACKAGE_DATA:
            return {}
        default: return state;
    }
} 