import React from 'react';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import { getServerTime } from '../../utile';

var intervalTimer;
class TeacherAnalytics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentInfo: this.props.contentInfo,
            startTime: getServerTime().getTime(),
            initialSaveDone: false,
            currentAnalyticsId: null
        }


        this.service = new Service();
    }


    saveAnalytics = () => {
        const { chapterId, chapterName, date, startTime, endTime, sectionId, subjectId, subjectName, teacherId, teacherName, name } = this.props.period;
        const { contentId, contentName, } = this.props.contentInfo;

        const currentDate = getServerTime();
        if (this.state.currentAnalyticsId == null) {
            let payload = {
                startTime: this.state.startTime,
                endTime: currentDate.getTime(),
                timeSpent: 0,
                name,
                date: currentDate,
                analyticsType: 'content',
                contentId, contentName,
                chapterId, chapterName, date, startTime, endTime, sectionId, subjectId, subjectName, teacherId, teacherName,
                fromTime: Number(currentDate.getHours() + '.' + currentDate.getMinutes()),
                toTime: Number(currentDate.getHours() + '.' + currentDate.getMinutes())
            }

            const url = `${baseUrlAdmin}/timetable/saveanalytics`;
            this.service.post(url, payload, true).then((data) => {
                if (data && data.res.data)
                    this.setState({
                        currentAnalyticsId: data.res.data
                    });
            });



        } else {
            let payload = {
                id: this.state.currentAnalyticsId,
                endTime: currentDate.getTime(),
                timeSpent: Math.round((currentDate.getTime() - this.state.startTime) / 1000),
                toTime: Number(currentDate.getHours() + '.' + currentDate.getMinutes())
            }

            const url = `${baseUrlAdmin}/timetable/updateAnalytics`;
            this.service.post(url, payload, true).then((res) => {

            });
        }
    }

    componentDidMount() {
        
        if (this.state.contentInfo && !this.state.initialSaveDone) {
            this.saveAnalytics();
            this.setState({
                contentInfo: this.props.contentInfo,
                initialSaveDone: true
            });
            var thisObj = this;
            intervalTimer = setInterval(() => {
                thisObj.saveAnalytics();
            }, 30000)
        }

    }

    componentDidUpdate(prevProps) {
        if (this.state.contentInfo && !this.state.initialSaveDone) {
            this.saveAnalytics();
            this.setState({
                contentInfo: prevProps.contentInfo,
                initialSaveDone: true
            });
            var thisObj = this;
            intervalTimer = setInterval(() => {
                thisObj.saveAnalytics();
            }, 30000)
        }

    }

    componentWillUnmount() {
        try {
            clearInterval(intervalTimer);
        } catch (e) {
        }
    }



    render() {
        console.log('aaaaa', this.props)
        return (
            <>
            </>
        )
    }
}


export default TeacherAnalytics;