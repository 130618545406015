import axios from 'axios';

export default class Service {

    get(url, isAuthRequired) {
        let config;
        if (isAuthRequired) {
            config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('discussion_token') ? localStorage.getItem('discussion_token') : ''}`,
                    // 'Access-Control-Allow-Origin': '*',
                }
            };
        } else {
            config = {};
        }

        return axios.get(url, config)
            .then(res => {
                if (res) {
                    return { status: true, res: res.data };
                }
            }).catch((err) => {
                if (err) {
                    return { status: false, errMessage: err.message };
                }
            });
    }
    post(url, data, isAuthRequired) {
        let config;
        if (isAuthRequired) {
            config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('discussion_token') ? localStorage.getItem('discussion_token') : ''}`,
                    // 'Access-Control-Allow-Origin': '*',
                }
            };
        } else {
            config = {};
        }
        return axios.post(url, data, config)
            .then(res => {
                if (res) {
                    return { status: true, res: res.data };
                }
            }).catch((err) => {

                if (err) {
                    return { status: false, errMessage: err.message };
                }
            });
    }
    patch(url, data, isAuthRequired) {
        let config;
        if (isAuthRequired) {
            config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('discussion_token') ? localStorage.getItem('discussion_token') : ''}`,
                    // 'Access-Control-Allow-Origin': '*',
                }
            };
        } else {
            config = {};
        }
        return axios.patch(url, data, config)
            .then(res => {
                if (res) {
                    return { status: true, res: res.data };
                }
            }).catch((err) => {
                if (err) {
                    return { status: false, errMessage: err.message };
                }
            });
    }
    put(url, data, isAuthRequired) {
        let config;
        if (isAuthRequired) {
            config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('discussion_token') ? localStorage.getItem('discussion_token') : ''}`,
                    // 'Access-Control-Allow-Origin': '*'
                }
            };
        } else {
            config = {};
        }
        return axios.put(url, data, config)
            .then(res => {
                if (res) {
                    return { status: true, res: res.data };
                }
            }).catch((err) => {
                if (err) {
                    return { status: false, errMessage: err.message };
                }
            });
    }
    delete(url, data, isAuthRequired) {
        let config;
        if (isAuthRequired) {
            config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('discussion_token') ? localStorage.getItem('discussion_token') : ''}`,
                    // 'Access-Control-Allow-Origin': '*',
                }
            };
        } else {
            config = {};
        }
        return axios.delete(url, config)
            .then(res => {
                if (res) {
                    return { status: true, res: res.data };
                }
            }).catch((err) => {
                if (err) {
                    return { status: false, errMessage: err.message };
                }
            });
    }
    postWithCustonAuthToken(url, data, isAuthRequired, authToken) {
        let config;
        if (isAuthRequired) {
            config = {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    // 'Access-Control-Allow-Origin': '*',
                }
            };
        } else {
            config = {};
        }
        return axios.post(url, data, config)
            .then(res => {
                if (res) {
                    return { status: true, res: res.data };
                }
            }).catch((err) => {
                if (err) {
                    return { status: false, errMessage: err.message };
                }
            });
    }
    getWithCustonAuthToken(url, isAuthRequired, authToken) {
        let config;
        if (isAuthRequired) {
            config = {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    // 'Access-Control-Allow-Origin': '*'
                }
            };
        } else {
            config = {};
        }
        return axios.get(url, config)
            .then(res => {
                if (res) {
                    return { status: true, res: res.data };
                }
            }).catch((err) => {
                if (err) {
                    return { status: false, errMessage: err.message };
                }
            });
    }
}