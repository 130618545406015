import React from 'react';
import './styles.scss';
import { baseUrlAdmin, baseUrlForLongRunningTask } from './../../../store/apiConstants';
import LoadingIndicator from './../../loadingComponent';
import { TabView, TabPanel } from 'primereact/tabview';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import moment from 'moment';
import { connect } from 'react-redux';
import { ResponsiveBar } from '@nivo/bar';
import {
    getOrganizationData,
    clearOrganizationData,
    getBranchCourseSection,
    clearCurriculumData,
    getActiveOrganizationData,
    getAllExamsByFilter,
    getImageUrl,
    getBranchesLatest
} from './../../../store/actions';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import Service from '../../../services';
import LoadingComponent from './../../loadingComponent';
import { Toast } from 'primereact/toast';
import GraphicalView from './graphicalView';
import IndividualCompleteAnalysis from './individualCompleteAnalysis'
import PreviewQuestion from '../../questionBank/common/previewQuestion';
import { AnalyticsIcon } from '../../svgIcons';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { getRegex } from '../../../utile';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import dummyState from './dummy.json'
import DownloadAanalytics from './downloadAanalytics';
import { cloneDeep } from 'lodash';



const testType = [
    // {
    //     value: 'Quiz',
    //     label: 'Quiz'
    // },
    {
        value: 'Assessment',
        label: 'Assessment'
    },
    // {
    //     value: 'MockTest',
    //     label: 'Mock Test'
    // },
    // {
    //     value: 'Template List',
    //     label: 'Template'
    // },
    {
        value: 'AdhocMockTest',
        label: 'Adhoc Mock Test'
    },
    {
        value: 'AdhocMockTestT2',
        label: 'Adhoc Mock Test T2'
    }
];




class IndividualAnalytics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            board: [],
            classes: [],
            branch: [],
            courses: [],
            sections: [],
            boardId: null,
            boardName: null,
            classId: null,
            className: null,
            groupId: null,
            groupName: null,
            branchId: null,
            branchName: null,
            courseId: [],
            courseName: null,
            sectionId: [],
            sectionName: null,
            examType: null,
            examId: null,
            // examType: 'Assessment',
            // examId: 'd211daea-afa5-49ce-b95f-39a08df2544b',
            startDateError: null,
            endDateError: null,
            exams: [],
            startDate: null,
            endDate: null,
            questionwiseReport: null,
            userWiseReport: null,
            currentViewType: 'user',
            examsListByFilter: [],
            isLoading: false,
            showGradeSearch: false,
            //graphData: [],
            currentViewRange: 0,
            subjectName: 'All',
            subjects: [],
            isShowQuestionPreview: false,
            questionId: null,
            question: null,

            isShowCompleteAnalysis: false,
            completeExamReport: null,
            dateRange: [],
            selectedSubject: "All",
            allGraphs: [],
            questionWiseAllGraphs: [],
            backupUserWiseReport: []
            // ...dummyState
        }
        this.service = new Service()

    };


    setExamType = (examType) => {

        if (examType != "AdhocMockTest" && examType) {
            this.setState({
                showGradeSearch: true,
                examType,
                examId: null,
                examsListByFilter: [],
                singleUserRecord: null,
                userWiseReport: null,
                questionwiseReport: null,
                //graphData,
                subjectName: 'All',
                currentViewRange: 0,
                graphTabs: null,
                subjects: [],

            });
        } else {
            this.setState({
                examType,
                examId: null,
                examsListByFilter: [],
                showGradeSearch: false,
                singleUserRecord: null,
                userWiseReport: null,
                questionwiseReport: null,
                //graphData,
                subjectName: 'All',
                currentViewRange: 0,
                graphTabs: null,
                subjects: []
            });
        }



    }

    fetchExams = () => {
        let { dateRange, examType, } = this.state;

        if (!dateRange || !dateRange.length > 1) {
            this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly Select Dates', life: 3000 });
            return;
        } else if (!dateRange[0] || !dateRange[1]) {
            this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly Select Dates', life: 3000 });
            return;
        } else if (!examType) {
            this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly Select Exam Type', life: 3000 });
            return;
        }




        // else {
        //     if (!boardId || !classId || !groupId || !examType || !startDate || !endDate) {
        //         this.setState({
        //             openSnackBar: true,
        //             snakBarMsg: 'Please select all the fields'
        //         });
        //         return;
        //     }
        // }


        let tStartDate = new Date(dateRange[0]);
        tStartDate.setHours(0);
        tStartDate.setMinutes(0);
        tStartDate.setSeconds(0);


        let tEndDate = new Date(dateRange[1]);
        tEndDate.setHours(23);
        tEndDate.setMinutes(59);
        tEndDate.setSeconds(59);


        let filters = { startDate: tStartDate.getTime(), endDate: tEndDate.getTime(), examType };


        this.setState({
            isLoading: true,
            examsListByFilter: []
        })


        //this.props.getAllExamsByFilter(filters);
        let url = `${baseUrlAdmin}/examination/getexamNamesbyfilter`;
        this.service.post(url, filters, true).then(res => {

            if (res && res.res && res.res.Items) {
                if (res.res.Items.length) {
                    this.setState({
                        examsListByFilter: res.res.Items,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        examsListByFilter: [],
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'No Exams', detail: 'No exams found with the given search criteria.', life: 3000 });
                }


            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
            //    console.log(res);


        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });

            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    }

    handleClearSearch = () => {
        this.setState(
            {
                examType: null,
                dateRange: []
            }
        );
    }

    getGraphData = (data) => {
        let questionwiseReport = data.sort((a, b) => a.qNo - b.qNo);
        let temp = questionwiseReport.length;
        let t1 = 0;
        let t2 = 0;
        let t3 = 0;
        let graphTabs = [];
        if (questionwiseReport && questionwiseReport.length) {
            for (let ie = 0; ie <= temp; ie++) {
                if (temp >= 20) {
                    t2 += 20;
                    let values = [];
                    for (let le = t1; le < t2; le++) {
                        values.push({
                            qNo: questionwiseReport[le].qNo,
                            questionId: questionwiseReport[le].questionId,
                            "Correct Perfect Time": questionwiseReport[le].correctPerfectTimePercentage,
                            "Correct Perfect TimeColor": "#AF9CFA",
                            "Correct Over Time": questionwiseReport[le].correctOverTimePercentage,
                            "Correct Over TimeColor ": "#472BB8",
                            background: "#472BB8",
                            backgroundColor: "#472BB8",
                            color: 'orange',
                            fill: 'red'
                            // "Wrong Perfect Time": questionwiseReport[le].wrongPerfectTimePercentage,
                            // "Wrong Perfect TimeColor": "hsl(70, 70%, 50%)",
                            // "Wrong Over Time": questionwiseReport[le].wrongOverTimePercentage,
                            // "Wrong Over TimeColor": "hsl(306, 70%, 50%)",
                        });
                    }
                    graphTabs.push({
                        label: (questionwiseReport[t1] ? questionwiseReport[t1].qNo : '') + '-' + (questionwiseReport[t2 - 1] ? questionwiseReport[t2 - 1].qNo : ''),
                        values,
                        key: t3
                    });
                    t1 += 20;
                    t3++;
                } else {
                    t2 += temp;
                    let values = [];
                    for (let le = t1; le < t2; le++) {
                        values.push({
                            qNo: questionwiseReport[le].qNo,
                            questionId: questionwiseReport[le].questionId,
                            "Correct Perfect Time": questionwiseReport[le].correctPerfectTimePercentage,
                            "Correct Perfect TimeColor": "#AF9CFA",
                            "Correct Over Time": questionwiseReport[le].correctOverTimePercentage,
                            "Correct Over TimeColor ": "#472BB8",
                            // "Wrong Perfect Time": questionwiseReport[le].wrongPerfectTimePercentage,
                            // "Wrong Perfect TimeColor": "hsl(70, 70%, 50%)",
                            // "Wrong Over Time": questionwiseReport[le].wrongOverTimePercentage,
                            // "Wrong Over TimeColor": "hsl(306, 70%, 50%)",
                        });
                    }
                    graphTabs.push({
                        //   label: t1.toString() + '-' + t2.toString(),
                        //label: questionwiseReport[t1].qNo + '-' + questionwiseReport[t2].qNo,
                        label: (questionwiseReport[t1] ? questionwiseReport[t1].qNo : '') + '-' + (questionwiseReport[t2 - 1] ? questionwiseReport[t2 - 1].qNo : ''),
                        values,
                        key: t3
                    });
                    t3++;
                }
                temp -= 20;
            }
        }

        return graphTabs;
    }


    getDownloadGraphData = (userWiseReport, singleUserRecord, questionwiseReport) => {

        let allGraphs = [];

        if (userWiseReport && userWiseReport.length) {
            let total = 0;
            let allRightAnswers = 0;
            let allWrongAnswers = 0;
            let allUnattemptedAnswers = 0;

            userWiseReport.forEach((record) => {
                let totalQuestions = parseInt(record.allRightAnswers) + parseInt(record.allWrongAnswers) + parseInt(record.allUnattemptedAnswers);
                total += totalQuestions;

                allRightAnswers += parseInt(record.allRightAnswers);
                allWrongAnswers += parseInt(record.allWrongAnswers);
                allUnattemptedAnswers += parseInt(record.allUnattemptedAnswers);
            })



            let totalRWUReport = {
                "Correct": Math.round((allRightAnswers / total) * 100),
                "Wrong": Math.round((allWrongAnswers / total) * 100),
                "Un Attempted": Math.round((allUnattemptedAnswers / total) * 100)
            }



            let totalStats = {
                "Highest Marks": userWiseReport[0].highestMarks,
                "Average Marks": userWiseReport[0].averageMarks,
            }
            let maxValue = userWiseReport[0].totalExamMarks;

            allGraphs.push({
                key: "All Subjects",
                pieData: this.getPieData(totalRWUReport),
                barData: this.getBarData(totalStats),
                totalStats,
                maxValue
            })


            singleUserRecord?.userAnswered.map(s => {

                let subject = s.subjectName;
                let total = 0;
                let allRightAnswers = 0;
                let allWrongAnswers = 0;
                let allUnattemptedAnswers = 0;

                userWiseReport.forEach((record) => {
                    total += record[`${subject.replace(getRegex(' '), '_')}_rightAnswer`] + record[`${subject.replace(getRegex(' '), '_')}_wrongAnswer`] + record[`${subject.replace(getRegex(' '), '_')}_unAnswered`];

                    allRightAnswers += record[`${subject.replace(getRegex(' '), '_')}_rightAnswer`];
                    allWrongAnswers += record[`${subject.replace(getRegex(' '), '_')}_wrongAnswer`];
                    allUnattemptedAnswers += record[`${subject.replace(getRegex(' '), '_')}_unAnswered`];
                })



                let totalRWUReport = {
                    "Correct": Math.round((allRightAnswers / total) * 100),
                    "Wrong": Math.round((allWrongAnswers / total) * 100),
                    "Un Attempted": Math.round((allUnattemptedAnswers / total) * 100)
                }


                let totalStats = {
                    "Highest Marks": userWiseReport[0][`${subject.replace(getRegex(' '), '_')}_highestMarks`],
                    "Average Marks": userWiseReport[0][`${subject.replace(getRegex(' '), '_')}_averageMarks`],

                }
                let maxValue = userWiseReport[0][`${subject.replace(getRegex(' '), '_')}_totalMarks`];
                allGraphs.push({
                    key: subject,
                    pieData: this.getPieData(totalRWUReport),
                    barData: this.getBarData(totalStats),
                    totalStats,
                    maxValue
                })
            })
        }


        // if (subjectName == 'All') {
        //     this.setState({
        //         subjectName,
        //         graphTabs: this.getGraphData(questionwiseReport),
        //         currentViewRange: 0
        //     });
        // } else {


        let questionWiseAllGraphs = [];
        singleUserRecord?.userAnswered?.map(s => {
            let subjectWiseQuestions = questionwiseReport?.filter((q => q.subjectName == s.subjectName));

            questionWiseAllGraphs.push({
                subjectName: s.subjectName,
                graphTabs: this.getGraphData(subjectWiseQuestions),
                currentViewRange: 0
            });

        });

        // }

        return { allGraphs, questionWiseAllGraphs }

    }

    componentDidMount() {
        // let { allGraphs, questionWiseAllGraphs } = this.getDownloadGraphData(this.state.userWiseReport, this.state.singleUserRecord[0], this.state.questionwiseReport);

        // this.setState({
        //     allGraphs, questionWiseAllGraphs,
        // })
        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }
    }

    componentDidUpdate() {

        if (!this.state.sectionsData) {
            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }


    }

    handleSearch = () => {
        let { boardId, classId, groupId, startDate, endDate, examType, examId } = this.state;

        if (!examId) {

            this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Please select exam', life: 3000 });
            return;
        }

        this.setState({
            isLoading: true,
            singleUserRecord: null,
            userWiseReport: null,
            questionwiseReport: null,
            //graphData,
            subjectName: 'All',
            currentViewRange: 0,
            graphTabs: [],
            subjects: [],
            isShowHeirarchyDropdowns: true
        });
        const service = new Service();
        let url = `${baseUrlForLongRunningTask}/analytics/getperformanceanalyticsbyexamid/${examId}/examType/${examType}`;
        service.get(url, false).then((res) => {
            if (res.status) {
                //let graphData = [];
                let graphTabs = [];
                if (res.res && res.res.questionwiseReport && res.res.questionwiseReport) {
                    graphTabs = this.getGraphData(res.res.questionwiseReport);
                }
                let subjects = ['All'];
                let b;
                if (res.res && res.res.singleUserRecord && res.res.singleUserRecord.length) {
                    res.res.singleUserRecord[0].userAnswered.forEach((d) => {
                        subjects.push(
                            d.subjectName
                        );
                    });
                    b = this.getDownloadGraphData(res.res.userWiseReport, res.res.singleUserRecord[0], res.res.questionwiseReport);


                }

                let { allGraphs, questionWiseAllGraphs } = b || { allGraphs: [], questionWiseAllGraphs: [] };


                this.setState({
                    singleUserRecord: res.res && res.res.singleUserRecord && res.res.singleUserRecord[0],
                    userWiseReport: res.res && res.res.userWiseReport && res.res.userWiseReport,
                    backupUserWiseReport: res.res && res.res.userWiseReport && res.res.userWiseReport,
                    questionwiseReport: res.res && res.res.questionwiseReport && res.res.questionwiseReport,
                    //graphData,
                    isLoading: false,
                    subjectName: 'All',
                    currentViewRange: 0,
                    graphTabs,
                    subjects,
                    allGraphs, questionWiseAllGraphs
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });

            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });
        //   }

    }

    onSubjectChange = (subjectName) => {
        if (subjectName == 'All') {
            this.setState({
                subjectName,
                graphTabs: this.getGraphData(this.state.questionwiseReport),
                currentViewRange: 0
            });
        } else {
            let subjectWiseQuestions = this.state.questionwiseReport.filter((q => q.subjectName == subjectName));

            this.setState({
                subjectName,
                graphTabs: this.getGraphData(subjectWiseQuestions),
                currentViewRange: 0
            });
        }
    }

    showQuestion = (questionId) => {

        this.setState({
            isLoading: true,
            questionId: questionId
        });

        let thisObj = this;
        const service = new Service();
        let url = `${baseUrlAdmin}/question/question-id/${questionId}`;
        service.get(url, true).then((res) => {

            if (res.status) {
                this.setState({
                    isLoading: false,
                    questionToPreview: res.res.questions,
                    isShowQuestionPreview: true

                });


                // if (res.res.questions.attachmentUrl) {
                //     thisObj.props.getImageUrl(res.res.questions.attachmentUrl)
                // }


            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: '', life: 3000 });

            }
        });




    }

    handleTabChange = (event, newValue) => {

        this.setState({ currentViewRange: event.index });
    };

    getPieData = (data) => {
        let temp = [];

        Object.keys(data).forEach(key => {
            temp.push({
                id: key,
                label: key,
                value: parseFloat(data[key])
            })
        });

        return temp;
    }

    getBarData = (data) => {
        let temp = [];

        Object.keys(data).forEach(key => {
            temp.push({
                key: key,
                [key]: parseFloat(data[key])
            })
        });

        return temp;
    }

    showGraphView = () => {
        let userWiseReport = this.state.userWiseReport || []

        if (userWiseReport && userWiseReport.length) {
            let total = 0;
            let allRightAnswers = 0;
            let allWrongAnswers = 0;
            let allUnattemptedAnswers = 0;

            userWiseReport.forEach((record) => {

                let totalQuestions = parseInt(record.allRightAnswers) + parseInt(record.allWrongAnswers) + parseInt(record.allUnattemptedAnswers);
                total += totalQuestions;


                record.allRightAnswersPercentage = Math.round((parseInt(record.allRightAnswers) / totalQuestions) * 100)
                record.allWrongAnswersPercentage = Math.round((parseInt(record.allWrongAnswers) / totalQuestions) * 100)
                record.allUnattemptedAnswersPercentage = Math.round((parseInt(record.allUnattemptedAnswers) / totalQuestions) * 100)

                allRightAnswers += parseInt(record.allRightAnswers);
                allWrongAnswers += parseInt(record.allWrongAnswers);
                allUnattemptedAnswers += parseInt(record.allUnattemptedAnswers);
            })


            //let total = allRightAnswers + allWrongAnswers + allUnattemptedAnswers;

            let totalRWUReport = {
                "Correct": Math.round((allRightAnswers / total) * 100),
                "Wrong": Math.round((allWrongAnswers / total) * 100),
                "Un Attempted": Math.round((allUnattemptedAnswers / total) * 100)
            }


            let totalStats = {
                "Highest Marks": userWiseReport[0].highestMarks,
                "Average Marks": userWiseReport[0].averageMarks,
            }

            this.setState({
                pieData: this.getPieData(totalRWUReport),
                barData: this.getBarData(totalStats),
                totalStats,
                userWiseReport,
                isShowGraphicalView: true,
                subjectsForGraph: this.state.singleUserRecord.userAnswered.map(a => a.subjectName),
                selectedSubject: "All"
            })
        }

    }

    onSelectSubject = (subject) => {
        if (subject == 'All') {
            this.showGraphView();

        } else {

            let userWiseReport = this.state.userWiseReport || []

            if (userWiseReport && userWiseReport.length) {
                let total = 0;
                let allRightAnswers = 0;
                let allWrongAnswers = 0;
                let allUnattemptedAnswers = 0;

                userWiseReport.forEach((record) => {
                    total += record[`${subject.replace(getRegex(' '), '_')}_rightAnswer`] + record[`${subject.replace(getRegex(' '), '_')}_wrongAnswer`] + record[`${subject.replace(getRegex(' '), '_')}_unAnswered`];

                    allRightAnswers += record[`${subject.replace(getRegex(' '), '_')}_rightAnswer`];
                    allWrongAnswers += record[`${subject.replace(getRegex(' '), '_')}_wrongAnswer`];
                    allUnattemptedAnswers += record[`${subject.replace(getRegex(' '), '_')}_unAnswered`];
                })



                let totalRWUReport = {
                    "Correct": Math.round((allRightAnswers / total) * 100),
                    "Wrong": Math.round((allWrongAnswers / total) * 100),
                    "Un Attempted": Math.round((allUnattemptedAnswers / total) * 100)
                }


                let totalStats = {
                    "Highest Marks": userWiseReport[0][`${subject.replace(getRegex(' '), '_')}_highestMarks`],
                    "Average Marks": userWiseReport[0][`${subject.replace(getRegex(' '), '_')}_averageMarks`],
                }

                this.setState({
                    pieData: this.getPieData(totalRWUReport),
                    barData: this.getBarData(totalStats),
                    totalStats,
                    userWiseReport,
                    selectedSubject: subject
                    // isShowGraphicalView: true,
                    //  subjectsForGraph: this.state.singleUserRecord.userAnswered.map(a => a.subjectName),
                })
            }
        }
    }



    showUserStats = (record) => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/analytics/getuserexamanalyticsreport/examId/${record.examId}/userId/${record.userId}`;
        this.service.get(url, true).then(res => {

            if (res && res.res && res.res) {
                this.setState({
                    isShowCompleteAnalysis: true,
                    completeExamReport: res.res,
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch((e) => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });
    }
    getSelectedLevelData(data, key) {
        let items = [];
        let selectedNode = data.find(a => a.key == key);
        if (selectedNode && selectedNode.children) {
            selectedNode.children.forEach((c) => {
                items.push(data.find(a => a.key == c))
            })

        }
        return { items, selectedNode };
    }
    onChangeSection = (key, level) => {
        let { sectionsData } = this.state;
        let data = this.getSelectedLevelData(this.props.branchData, key)
        let emptyBeforeLevels = {};
        for (let i = (level + 1); i <= this.props.branchLevels.length; i++) {
            emptyBeforeLevels = {
                ...emptyBeforeLevels,
                ['level' + i]: [],
                ['levelSelected' + i]: null
            }
        }
        let newSectionData = {
            ...sectionsData,
            ...emptyBeforeLevels,
            ['level' + (level + 1)]: data.items,
            ['levelSelected' + (level)]: key
        }
        this.setState({
            sectionsData: newSectionData,
            sectionIds: []
        });
    };

    // showUserStats = (record) => {
    //     let totalQuestions = parseInt(record.allRightAnswers) + parseInt(record.allWrongAnswers) + parseInt(record.allUnattemptedAnswers);

    //     record.allRightAnswersPercentage = Math.round((parseInt(record.allRightAnswers) / totalQuestions) * 100)
    //     record.allWrongAnswersPercentage = Math.round((parseInt(record.allWrongAnswers) / totalQuestions) * 100)
    //     record.allUnattemptedAnswersPercentage = Math.round((parseInt(record.allUnattemptedAnswers) / totalQuestions) * 100)
    //     

    //     let totalRWUReport = {
    //         "Correct": record.allRightAnswersPercentage,
    //         "Wrong": record.allWrongAnswersPercentage,
    //         "Un Attempted": record.allUnattemptedAnswersPercentage
    //     }
    //     

    //     let totalStats = {

    //         "Highest Marks": record.highestmarks,
    //         "Average Marks": record.averagemarks,
    //         "User Marks": record.totalmarks,

    //     }


    //     this.setState({
    //         pieData: this.getPieData(totalRWUReport),
    //         barData: this.getBarData(totalStats),
    //         totalStats,
    //         isShowGraphicalView: true
    //     })

    // }

    red = () => {

        let d = 'f'

        return <></>
    }
    // searchUserWiseReport = () => {
    //     this.setState({
    //         isLoading: true
    //     })
    //     let maxLevelSelected;
    //     for (let index = this.props.branchLevels?.length; index > 0; index--) {
    //         if (this.state.sectionsData['levelSelected' + (index)] && this.state.sectionsData['levelSelected' + (index)].length) {
    //             maxLevelSelected = this.state.sectionsData['levelSelected' + (index)];
    //             break
    //         }
    //     }
    //     if (maxLevelSelected) {
    //         let finaldata = this.state.backupUserWiseReport?.filter((eachUserData) => eachUserData?.heirarchy && eachUserData?.heirarchy?.includes(maxLevelSelected))
    //         this.setState({
    //             userWiseReport: finaldata,
    //             isLoading: false
    //         })
    //     } else {
    //         this.setState({
    //             isLoading: false
    //         })
    //     }
    // }

    // clearHeirarchyFilters = () => {
    //     let secData = cloneDeep(this.state.sectionsData)
    //     for (let index = this.props.branchLevels?.length; index > 0; index--) {
    //         secData['levelSelected' + (index)] = null
    //     }
    //     this.setState({
    //         userWiseReport: this.state.backupUserWiseReport,
    //         sectionsData : secData
    //     })
    // }

    searchUserWiseReport = () => {
        const { sectionsData, backupUserWiseReport } = this.state;
        const { branchLevels } = this.props;

        this.setState({ isLoading: true });

        let maxLevelSelected = null, level = null;
        for (let i = branchLevels.length; i > 0; i--) {
            const key = 'levelSelected' + i;
            if (sectionsData[key] && sectionsData[key].length) {
                maxLevelSelected = sectionsData[key];
                level = i
                break;
            }
        }
        let finaldata = backupUserWiseReport;
        if (maxLevelSelected) {
            // finaldata = finaldata.filter(userData => userData?.heirarchy?.includes(maxLevelSelected));
            finaldata = finaldata.filter(userData => userData && userData?.data && userData?.data[level - 1] && userData?.data[level - 1]?.key == maxLevelSelected);
        }

        this.setState({
            userWiseReport: finaldata,
            isLoading: false
        });
    };

    clearHeirarchyFilters = () => {
        const { sectionsData, backupUserWiseReport } = this.state;
        const { branchLevels } = this.props;

        const secData = { ...sectionsData };
        branchLevels.forEach((_, index) => {
            secData['levelSelected' + (index + 1)] = null;
        });

        this.setState({
            userWiseReport: backupUserWiseReport,
            sectionsData: secData
        });
    };



    render() {
        console.log('allgraphs', this.state);

        return (
            <>

                {this.red()}
                <div>
                    <div className='p-grid' >

                        <Authorizer permId={PERMISSIONS.EXAMANALYTICS_FETCHEXAMS} >


                            <div className="p-col-12 p-md-6">
                                <div className='p-gerid' >

                                    <Calendar showIcon={true}
                                        placeholder="Select Dates"
                                        selectionMode="range"
                                        // className='ma-w200'
                                        className='ma-mt10 '
                                        readOnlyInput
                                        value={this.state.dateRange}
                                        onChange={(e) => {
                                            this.setState({
                                                dateRange: e.value,
                                            });
                                        }}
                                    ></Calendar>
                                    <span className='ma-required-mark '>*</span>

                                    <Dropdown value={this.state.examType}
                                        className='ma-mt10 ma-w200'
                                        options={testType}
                                        onChange={(e) => { this.setExamType(e.target.value) }}
                                        placeholder="Select Exam Type" />
                                    <span className='ma-required-mark '>*</span>
                                </div>
                                <p className="ma-error-msg">{this.state.startDateError ? this.state.startDateError : ''}</p>

                                <div className='ma-mt20'>
                                    <Button
                                        label="Fetch Exams"
                                        onClick={() => {
                                            this.fetchExams()
                                        }}
                                    >
                                    </Button>
                                    <Button
                                        label="Clear"
                                        className='ma-ml10 p-button-outlined white-bg'
                                        onClick={() => {
                                            this.handleClearSearch()
                                        }}
                                    >
                                    </Button>
                                </div>
                            </div>
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.EXAMANALYTICS_SHOW_RESULTS} >
                            <div className="p-col-12 p-md-6">
                                <div className='ma-mlr30'>
                                    <div>
                                        <Dropdown value={this.state.examId}
                                            className='ma-w200 '
                                            optionLabel='examName'
                                            optionValue='examId'
                                            options={this.state.examsListByFilter}
                                            onChange={(e) => this.setState({ examId: e.target.value })}
                                            placeholder="Select Exam" />
                                        <span className='ma-required-mark '>*</span>
                                    </div>
                                    <Button
                                        className='ma-mt20'
                                        label='Show Results'
                                        onClick={() => {
                                            this.handleSearch()
                                        }}
                                    >
                                    </Button>
                                </div>
                            </div>
                        </Authorizer>
                    </div>


                    <Toolbar className="ma-toolbar exam-analytics-tabs"


                        right={() => {
                            return <>
                                <div className='qb-tabs'>
                                    <Authorizer permId={PERMISSIONS.EXAMANALYTICS_GRAPHICALVIEW} >
                                        <Button data-pr-position="bottom" data-pr-tooltip="Graphical View" onClick={() => {
                                            this.showGraphView()
                                        }} icon="pi pi-slack"
                                            className='ma-mr20'

                                        ></Button>
                                        <Tooltip className="table-li-tooltip" target=".egraph" />
                                    </Authorizer>
                                    <ReactToPrint
                                        // trigger={() => <>  <Button data-pr-position="bottom"
                                        //     data-pr-tooltip="Download "
                                        //     // onClick={() => {
                                        //     //     this.showGraphView()
                                        //     // }} 
                                        //     icon="pi pi-slack"
                                        //     className='ma-mr20'

                                        // ></Button>
                                        //     <Tooltip className="table-li-tooltip" target=".egraph" />
                                        // </>
                                        // }
                                        pageStyle="padding:'10px'"
                                        //  documentTitle='Adhoc Exam t'
                                        content={() => this.componentRef}
                                    >
                                        <PrintContextConsumer>
                                            {({ handlePrint }) => (
                                                <>  <Button data-pr-position="bottom"
                                                    data-pr-tooltip="Download "
                                                    onClick={handlePrint}
                                                    //  icon="pi pi-slack"
                                                    className='ma-mr20'
                                                    label='Download Report'
                                                ></Button>
                                                    <Tooltip className="table-li-tooltip" target=".egraph" />
                                                </>

                                            )}
                                        </PrintContextConsumer>
                                    </ReactToPrint>
                                    <Authorizer permId={PERMISSIONS.EXAMANALYTICS_TESTWISE} >
                                        <span onClick={() => { this.setState({ currentViewType: 'user' }) }} className={this.state.currentViewType == 'user' ? 'qb-active-tab' : 'qb-inactive-tab'}>Test Wise Analytics</span>
                                    </Authorizer>
                                    <Authorizer permId={PERMISSIONS.EXAMANALYTICS_QUESTIONWISE} >
                                        <span onClick={() => { this.setState({ currentViewType: 'question' }) }} className={this.state.currentViewType == 'question' ? 'qb-active-tab' : 'qb-inactive-tab'}>Question Wise Analytics</span>
                                    </Authorizer>
                                </div>
                            </>
                        }}></Toolbar>

                    {this.state.isShowHeirarchyDropdowns && this.state.currentViewType == 'user' && this.props.branchLevels ? <div>

                        {
                            this.props.branchLevels.map((level, index) => {
                                return (<Dropdown
                                    className='ma-mr20 ma-mt10 ma-w200'
                                    value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                    // className='ma-w100p'
                                    //    disabled={this.state.hideUpdateButton}
                                    optionLabel={"name"}
                                    optionValue={"key"}
                                    options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}

                                    onChange={(e) => this.onChangeSection(e.value, index + 1)}
                                    placeholder={`--Select ${level.levelName}--`} />
                                )
                            })
                        }
                        <Button
                            label="Filter"
                            onClick={() => {
                                this.searchUserWiseReport()
                            }}
                        >
                        </Button>

                        <Button
                            label="Reset"
                            className='ma-ml10 p-button-outlined white-bg'
                            onClick={() => {
                                this.clearHeirarchyFilters()
                            }}
                        >
                        </Button>


                    </div> : <></>}

                    <div className="adhoc-report-tbl">

                        <div>
                            <Authorizer permId={PERMISSIONS.EXAMANALYTICS_TESTWISE} >
                                {this.state.currentViewType == 'user' && this.state.userWiseReport && this.state.userWiseReport.length ? <>
                                    <table className="exam-tbl" >
                                        <thead>
                                            <tr>
                                                <th rowSpan="2" className='sno'>S.No</th>
                                                <th rowSpan="2" className='mobile-number'>Mobile Number</th>
                                                <th rowspan="2">User Name</th>
                                                <th rowspan="2" >Name</th>
                                                <th rowspan="2">Branch Name</th>
                                                <th rowspan="2">Board Name</th>
                                                <th rowspan="2">Class Name</th>
                                                <th rowspan="2">Section Name</th>
                                                <th colSpan="6" >Overall</th>
                                                {this.state.singleUserRecord.userAnswered.map((subject, inf) => {
                                                    return <th colSpan="6" key={'op' + inf} >{subject.subjectName}</th>
                                                })
                                                }
                                            </tr>

                                            <tr>
                                                <th>C</th>
                                                <th>W</th>
                                                <th>U</th>
                                                <th>Total <span style={{ fontSize: '12px' }}> ({this.state.userWiseReport && this.state.userWiseReport[0].totalExamMarks})</span></th>
                                                <th>Percentile</th>
                                                <th>Rank</th>
                                                {
                                                    (this.state.singleUserRecord) ? <>
                                                        {
                                                            this.state.singleUserRecord.userAnswered.map((subject, inf) => {
                                                                let total = 0;
                                                                if (this.state.examType == 'AdhocMockTest' || this.state.examType == 'AdhocMockTestT2') {
                                                                    subject.questionTyeWise.forEach(q => {
                                                                        total += q.totalMarks;
                                                                    })
                                                                } else {
                                                                    total = subject.totalMarks
                                                                }


                                                                return <React.Fragment key={inf}>
                                                                    <th>C</th>
                                                                    <th>W</th>
                                                                    <th>U</th>
                                                                    <th>Total <span style={{ fontSize: '12px' }}>({total})</span> </th>
                                                                    <th>Percentile</th>
                                                                    <th>Rank</th>
                                                                </React.Fragment >
                                                            })


                                                        }
                                                    </> : <></>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.userWiseReport.map((userRecord, index) => {
                                                    return <tr key={'ddd' + index}>
                                                        <td>{index + 1}</td>
                                                        <td className="phone-number" onClick={() => { this.showUserStats(userRecord) }} ><span data-pr-tooltip="For User Test Analytics Click Here" data-pr-position="bottom" className={`ae${index}`} >{userRecord.phoneNumber}</span>
                                                            <Tooltip className="table-li-tooltip2" autoHide={false} target={`.ae${index}`} />
                                                        </td>
                                                        <td>{userRecord.givenName}</td>
                                                        <td>{userRecord.userName}</td>
                                                        <td>{userRecord.branchName}</td>
                                                        <td>{userRecord.boardName}</td>
                                                        <td>{userRecord.className}</td>
                                                        <td>{userRecord.sectionName}</td>
                                                        <td>{userRecord.allRightAnswers}</td>
                                                        <td>{userRecord.allWrongAnswers}</td>
                                                        <td>{userRecord.allUnattemptedAnswers}</td>
                                                        <td>{userRecord.totalMarks}</td>
                                                        <td>{userRecord.percentile}</td>
                                                        <td>{userRecord.rank}</td>
                                                        {
                                                            (this.state.singleUserRecord) ?
                                                                <>{this.state.singleUserRecord.userAnswered.map((subject, d) => {
                                                                    return <React.Fragment key={'d' + d}>
                                                                        <td>{userRecord[`${subject.subjectName.replace(getRegex(' '), '_')}_rightAnswer`]}</td>
                                                                        <td>{userRecord[`${subject.subjectName.replace(getRegex(' '), '_')}_wrongAnswer`]}</td>
                                                                        <td>{userRecord[`${subject.subjectName.replace(getRegex(' '), '_')}_unAnswered`]}</td>
                                                                        <td>{userRecord[`${subject.subjectName.replace(getRegex(' '), '_')}_marks`]}</td>
                                                                        <td>{userRecord[`${subject.subjectName.replace(getRegex(' '), '_')}_percentile`]}</td>
                                                                        <td>{userRecord[`${subject.subjectName.replace(getRegex(' '), '_')}_rank`]}</td>

                                                                    </React.Fragment>
                                                                })
                                                                }</> : <></>}
                                                    </tr>

                                                })
                                            }
                                        </tbody>
                                    </table>

                                </> : null
                                }
                            </Authorizer>

                            <Authorizer permId={PERMISSIONS.EXAMANALYTICS_QUESTIONWISE} >
                                {this.state.currentViewType == 'question' && this.state.questionwiseReport && this.state.questionwiseReport.length && <>
                                    <table className="exam-tbl" style={{ width: '100%' }}>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Questions No</th>
                                            <th>Subject Name</th>
                                            <th>Questions Type</th>
                                            <th>Correct %</th>
                                            <th>Wrong %</th>
                                            <th>Unattempted %</th>
                                        </tr>
                                        {this.state.questionwiseReport.map((report, index) => {
                                            return <tr key={index} className='question-item' onClick={() => { this.showQuestion(report.questionId) }}>
                                                <td>{index + 1}</td>
                                                <td>{report.qNo}</td>
                                                <td>{report.subjectName}</td>
                                                <td>{report.questionType}</td>
                                                <td>{report.correctCountPercentage}</td>
                                                <td>{report.wrongCountPercentage}</td>
                                                <td>{report.unAnsweredCountPercentage}</td>
                                            </tr>
                                        })}
                                    </table>

                                </>
                                }
                            </Authorizer>

                        </div>
                    </div>

                    <Authorizer permId={PERMISSIONS.EXAMANALYTICS_QUESTIONWISE} >
                        {this.state.currentViewType == 'question' && this.state.graphTabs && <>
                            <div className='graph-c'>
                                <div className="">

                                    <Toolbar className="ma-toolbard"
                                        right={() => {
                                            return <>
                                                <TabView className='question-graph-tbs ma-mlr30' activeIndex={this.state.currentViewRange} onTabChange={(e) => this.handleTabChange(e,)}>
                                                    {this.state.graphTabs && this.state.graphTabs.map((item, nd) => {
                                                        return <TabPanel header={item.label} key={"dd" + nd}  >

                                                        </TabPanel>
                                                    })}

                                                </TabView>
                                                <Dropdown value={this.state.subjectName}
                                                    className='ma-w140 '
                                                    options={this.state.subjects.map(s => { return { value: s } })}
                                                    optionLabel="value"
                                                    optionValue={'value'}
                                                    onChange={(e) => { this.onSubjectChange(e.target.value) }}
                                                    placeholder="Select Exam Type" />
                                            </>
                                        }}
                                    ></Toolbar>
                                </div>
                                <div className='questions-graph'>
                                    {(this.state.graphTabs && this.state.graphTabs[this.state.currentViewRange] && this.state.graphTabs[this.state.currentViewRange].values) && <>
                                        <ResponsiveBar

                                            data={this.state.graphTabs[this.state.currentViewRange].values}
                                            keys={['Correct Perfect Time', 'Correct Over Time']}
                                            indexBy="qNo"
                                            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                            valueScale={{ type: 'linear' }}
                                            indexScale={{ type: 'band', round: true }}
                                            padding={0.3}
                                            borderRadius={4}
                                            colors={['var(--theme-70)', 'var(--theme-85)', '#FFDA83', '#A3A0FB']}

                                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: 'Question No',
                                                legendPosition: 'middle',
                                                legendOffset: 32
                                            }}
                                            axisLeft={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: 'Percentage (%)',
                                                legendPosition: 'middle',
                                                legendOffset: -40
                                            }}
                                            labelSkipWidth={12}
                                            labelSkipHeight={12}
                                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                            legends={[
                                                {
                                                    dataFrom: 'keys',
                                                    anchor: 'bottom-right',
                                                    direction: 'row',
                                                    justify: false,
                                                    translateX: 89,
                                                    translateY: 50,
                                                    itemsSpacing: 9,
                                                    itemWidth: 130,
                                                    itemHeight: 29,
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 0.85,
                                                    symbolSize: 20,
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                            animate={true}
                                            motionStiffness={70}
                                            motionDamping={15}
                                            onClick={(a) => { this.showQuestion(a.data.questionId) }}
                                        />
                                    </>
                                    }
                                </div>

                            </div>    </>
                        }
                    </Authorizer>
                </div >
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {
                    (this.state.isLoading || this.props.isLoading) && <LoadingComponent />
                }

                {
                    this.state.isShowGraphicalView && <GraphicalView
                        pieData={this.state.pieData || []}
                        //graphAnalytics={this.state.graphAnalytics || {}}
                        totalStats={this.state.totalStats}
                        barData={this.state.barData || []}
                        hideGraphicalView={() => {
                            this.setState({
                                isShowGraphicalView: false
                            })
                        }}
                        selectedSubject={this.state.selectedSubject}
                        subjectsForGraph={this.state.subjectsForGraph || []}
                        onSelectSubject={this.onSelectSubject}

                    />
                }

                {
                    this.state.isShowCompleteAnalysis && <IndividualCompleteAnalysis onHide={() => {
                        this.setState({
                            isShowCompleteAnalysis: false,

                        })
                    }}
                        completeExamReport={this.state.completeExamReport}

                    />
                }

                {
                    this.state.isShowQuestionPreview && <PreviewQuestion
                        question={this.state.questionToPreview}
                        onHide={() => {
                            this.setState({
                                isShowQuestionPreview: false
                            })
                        }}
                    />
                }



                <div className='hidden-print' >
                    <DownloadAanalytics allGraphs={this.state.allGraphs}
                        questionWiseAllGraphs={this.state.questionWiseAllGraphs}
                        userWiseReport={this.state.userWiseReport && this.state.userWiseReport.length && this.state.userWiseReport[0]}
                        ref={el => this.componentRef = el} />
                </div>


            </>
        );
    }
}



const mapStatesToProps = (state) => ({


    isLoading: (state.curriculumData && state.curriculumData.loading) || (state.examinationDetailsInfo && state.examinationDetailsInfo.loading),
    error: state.curriculumData && state.curriculumData.error && state.curriculumData.error,
    errorInfo: state.curriculumData && state.curriculumData.errorInfo && state.curriculumData.errorInfo,
    branchInfo:
        state.curriculumData &&
        state.curriculumData.branch &&
        state.curriculumData.branch.Item &&
        state.curriculumData.branch.Item,
    organizationLoading: state.organizationData && state.organizationData.loading,
    organizationLoading: state.organizationData && state.organizationData.loading,
    organizationError: state.organizationData && state.organizationData.error,
    organizationErrorInfo: state.organizationData && state.organizationData.errorInfo,
    organizationData: state.organizationData && state.organizationData.response && state.organizationData.response,
    boardsInfo:
        state.organizationData &&
        state.organizationData.response &&
        state.organizationData.response.Item &&
        state.organizationData.response.Item,
    selectedOrganizationDataIds: state.selectedOrganizationDataIds && state.selectedOrganizationDataIds,
    examsListByFilter: state.examinationDetailsInfo && state.examinationDetailsInfo.examsListByFilter,
    imageInfo: state.getImageUrlInfo && state.getImageUrlInfo.allImageUrls,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
    roleBasedBoardIds: localStorage.getItem('boardId'),

});

export default connect(mapStatesToProps, {
    getOrganizationData,
    getActiveOrganizationData,
    clearOrganizationData,
    clearCurriculumData,
    getBranchCourseSection,
    getAllExamsByFilter,
    getImageUrl,
    getBranchesLatest

})((IndividualAnalytics));