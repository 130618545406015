import React, { Component } from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;





export default class PDF extends Component {
    constructor(props) {
        super(props)
        this.state = {
            totalPage: 0,
            currentPage: 2,
            scale: 1

        }

    }
    onDocumentLoadSuccess = (data) => {
        // numPages
        //setNumPages(numPages);
        this.setState({
            totalPage: data.numPages
        });
    }


    onItemClick = (data) => {
        
    }


    componentDidMount() {
        window.onscroll = function () { myFunction() };

        var navbar = document.getElementById("navbar");
        var sticky = navbar.offsetTop;

        function myFunction() {
            if (window.pageYOffset >= sticky) {
                navbar.classList.add("sticky")
            } else {
                navbar.classList.remove("sticky");
            }
        }
    }

    render() {
        return (
            <div>
                <p>
                    Testing appp
                </p>
                <p>
                    Testing appp
                </p>
                <p>
                    Testing appp
                </p><p>
                    Testing appp
                </p>
                <p>
                    Testing appp
                </p>
                <p>
                    Testing appp
                </p>

                <div className="p-grid">
                    <div className="p-col-2 p-md-2">

                    </div>
                    <div className="p-col-8 p-md-8">


                        <div id="navbar">
                            {
                                this.state.currentPage > 1 &&
                                <span onClick={() => {
                                    this.setState({
                                        currentPage: this.state.currentPage - 1
                                    })
                                }}>prev</span>}

                            {
                                this.state.currentPage < this.state.totalPage &&
                                < span onClick={() => {
                                    this.setState({
                                        currentPage: this.state.currentPage + 1
                                    })
                                }}>next</span>}

                            {
                                this.state.scale < 5 && <span onClick={() => {
                                    this.setState({
                                        scale: this.state.scale + 0.2
                                    })
                                }}>in</span>}
                            <span>{(this.state.scale * 100).toFixed(0)}%</span>
                            {
                                this.state.scale > 0.3 && <span onClick={() => {
                                    this.setState({
                                        scale: this.state.scale - 0.2
                                    })
                                }}>de</span>
                            }
                        </div>
                        <div>
                            <Document
                                file="./ticket.pdf"
                                onLoadSuccess={this.onDocumentLoadSuccess}
                                onItemClick={this.onItemClick}
                                renderMode="svg"
                            >
                                <Page className="test"
                                    //width={1000}
                                    scale={this.state.scale}
                                    height={2000}

                                    pageNumber={this.state.currentPage} />
                            </Document>
                            <p>Page {this.state.currentPage} of {this.state.totalPage}</p>
                        </div>
                    </div>
                    <div className="p-col-2 p-md-2">
                        <p>dsfdd</p>
                    </div>
                </div>
            </div >
        )
    }
}
