import * as ActionType from "../actionTypes";
import axios from 'axios';
import { baseUrlAdmin } from "../apiConstants";

const dispatchErrorGetVideoUrl = (err) => ({
    type: ActionType.ERROR_GET_VIDEO_URL,
    err

})

const dispatchRequestGetVideoUrl = (data) => ({
    type: ActionType.REQUEST_GET_VIDEO_URL,
    data,
});

const dispatchReceivedGetVideoUrl = (res, storageKey) => ({
    type: ActionType.RECEIVED_GET__VIDEO_URL,
    res,
    storageKey
});

const dispatchClearGetVideoUrl = () => ({
    type: ActionType.CLEAR_GET__VIDEO_URL,

});

export const getVideoUrl = (storageKey) => dispatch => {
    dispatch(dispatchRequestGetVideoUrl(storageKey));
    let baseUrl = baseUrlAdmin;
    axios({
        method: 'POST',
        url: `${baseUrl}/se3VideoFetch`,
        data: {
            preUrl: storageKey
        }
    }).then((res) => {
        dispatch(dispatchReceivedGetVideoUrl(res.data, storageKey));
    }).catch(e => {
        dispatch(dispatchErrorGetVideoUrl(e));
    });
}

export const clearVideoUrl = () => dispatch => {
    dispatch(dispatchClearGetVideoUrl());
}