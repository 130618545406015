import * as ActionType from "../actionTypes";

export default (state = {}, action) => {
  switch(action.type){
    case ActionType.REQUEST_ADD_QUESTION :

        return{
            ...state,
            loading:true,
            error:false,
            errorInfo:null,
            response:null
            }
    case ActionType.RECIVED_ADD_QUESTION :
        return{
            ...state,
            loading:false,
            response:action.res,
            error:false,
            errorInfo:null
        }
    case ActionType.ERROR_ADD_QUESTION :
        return{
            ...state,
            loading:false,
            error:true,
            errorInfo:action.err,
            response:null
        }
    case ActionType.CLEAR_ADD_QUESTION :
        return{
            
        }
    default : 
      return state    

  }
}