import * as ActionType from "../actionTypes";

export default (state = {}, action) => {
  switch(action.type){
    case ActionType.REQUEST_CREATE_NEW_USER :
        return{
            ...state,
            loading:true,
            errorInfo:null,
            error:false,
            response:null,
            errorMessage:null
        }
    case ActionType.RECEIVED_CREATE_NEW_USER :
        return{
            ...state,
            loading:false,
            errorInfo:null,
            error:false,
            response:action.res,
            errorMessage:null
        }
    case ActionType.ERROR_CREATE_NEW_USER :
        return{
            ...state,
            loading:false,
            errorInfo:action.err,
            error:true,
            response:null,
            errorMessage:null
        }
    case ActionType.THROW_ERROR_MESSAGE :
        return{
            ...state,
            loading:false,
            errorMessage:action.errorMessage,
            error:true,
            response:null
        }   
    case ActionType.CLEAR_CREATE_NEW_USER :
        return {
        }
    case ActionType.REQUEST_SAVE_BULK_USERS :
        return {
            ...state,
            bulkUserUploadRequested: true,
            loading:true,
            errorInfo:null,
            error:false,
        }
    case ActionType.RECEIVED_SAVE_BULK_USERS :
        return {
            ...state,
            loading:false,
            bulkUserUploadRequested: false,
            errorInfo:null,
            error:false,
            bulkUserUploadSaveResponse: action.res
        }
    case ActionType.ERROR_SAVE_BULK_USERS :
        return{
            ...state,
            loading:false,
            errorInfo:action.err,
            bulkUserUploadRequested: false,
            error:true,
            response:null,
            errorMessage:null
        }
    case ActionType.REQUEST_BULK_UPLOAD_TRANSACTIONS :
        return {
            ...state,
            loading:true,
            errorInfo:null,
            error:false,
        }
    case ActionType.RECEIVED_BULK_UPLOAD_TRANSACTIONS :
        return {
            ...state,
            loading:false,
            errorInfo:null,
            error:false,
            bulkUserUploadTransactionDetails:[],
            bulkUserUploadTransactions: action.res
        }
    case ActionType.ERROR_BULK_UPLOAD_TRANSACTIONS :
        return{
            ...state,
            loading:false,
            errorInfo:action.err,
            error:true,
            response:null,
            errorMessage:null
        }
    case ActionType.REQUEST_BULK_UPLOAD_TRANSACTION_DETAILS :
        return {
            ...state,
            loading:true,
            errorInfo:null,
            error:false,
        }
    case ActionType.RECEIVED_BULK_UPLOAD_TRANSACTION_DETAILS :
        return {
            ...state,
            loading:false,
            errorInfo:null,
            error:false,
            bulkUserUploadTransactionDetails: action.res
        }
    case ActionType.ERROR_BULK_UPLOAD_TRANSACTION_DETAILS :
        return{
            ...state,
            loading:false,
            errorInfo:action.err,
            error:true,
        }
    case ActionType.CLEAR_BULK_USERS_TRANSACTION_DATA:
        return {
            ...state,
            bulkUserUploadTransactions: [],
            bulkUserUploadTransactionDetails: [],
            bulkUserUploadSaveResponse: null
        }
    case ActionType.REQUEST_DELETE_USER:
        return {
            ...state,
            loading:true,
            errorInfo:null,
            error:false,
            deleteResponse: null
        }
    case ActionType.RECEIVED_DELETE_USER:
        return {
            ...state,
            loading:false,
            errorInfo:null,
            error:false,
            deleteResponse: action.res
        }
    case ActionType.ERROR_DELETE_USER:
        return{
            ...state,
            loading:false,
            errorInfo:action.err,
            error:true,
            deleteResponse: null
        }
    default : 
      return state    

  }
}