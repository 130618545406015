import * as ActionTypes from '../actionTypes';
import axios from 'axios';
import {
  baseUrlAdmin
} from '../apiConstants';

export const getAdminUsersListRequested = () => ({
  type: ActionTypes.GET_ADMIN_USER_LISTS_REQUESTED
});
const getAdminUsersListReqSucceeded = (data) => ({
  type: ActionTypes.GET_ADMIN_USER_LISTS_SUCCEEDED,
  data
});
const getAdminUsersListRequestFailed = () => ({
  type: ActionTypes.GET_ADMIN_USER_LISTS_FAILED
});

const url = `${baseUrlAdmin}/admin/getAllUserAdmin`;

const getAdminUserDetails = () => {
  return axios({
    method: 'get',
    url,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
    }
  });
};

export const getAdminUserList = () => (dispatch) => {
  dispatch(getAdminUsersListRequested());
  getAdminUserDetails()
    .then((res) => {
      if (res.data) {
        dispatch(getAdminUsersListReqSucceeded(res.data));
      } else {
        dispatch(getAdminUsersListRequestFailed());
      }
    })
    .catch((e) => {
      if (e.response && e.response.status == 401) {
        localStorage.clear();
        window.location.reload();
      } else {
      dispatch(getAdminUsersListRequestFailed());
      }
    });
};