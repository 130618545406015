import * as ActionTypes from './../actionTypes';


export default (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.SELECT_BOARD:
            return {
                ...state,
                boardId: action.boardId,
                boardName: action.boardName,
                classId: '',
                className: '',
                groupId: '',
                groupName: '',
                questionTarget: "Normal"
            }

        case ActionTypes.SELECT_CLASS:
            return {
                ...state,
                classId: action.classId,
                className: action.className,
                groupId: '',
                groupName: '',
            }

        case ActionTypes.SELECT_GROUP:
            return {
                ...state,
                groupId: action.groupId,
                groupName: action.groupName

            }

        case ActionTypes.SELECT_SUBJECT:
            return {
                ...state,
                subjectId: action.subjectId,
                subjectName: action.subjectName

            }

        case ActionTypes.SELECT_MULTIPLE_SUBJECTS:
            return {
                ...state,
                subjectsMultipleSelected: [
                    action.subjects
                ]

            }
        case ActionTypes.SELECT_TOPIC:
            return {
                ...state,
                topicId: action.topicId,
                topicName: action.topicName

            }
        case ActionTypes.SELECT_MULTIPLE_TOPICS:
            return {
                ...state,
                topicsMultipleSelected: [
                    action.topics
                ]
            }
        case ActionTypes.SELECT_CHAPTER:
            return {
                ...state,
                chapterId: action.chapterId,
                chapterName: action.chapterName,
            }
        case ActionTypes.SELECT_QUESTION_TARGET:
            return {
                ...state,
                questionTarget: action.questionTarget,
            }
        case ActionTypes.SELECT_MULTIPLE_CHAPTERS:
            return {
                ...state,
                chaptersMultipleSelected: [
                    action.chapters
                ]
            }
        case ActionTypes.CLEAR_SELECTED_ORGANIZATION_DATA:
            return {}
        case ActionTypes.CLEAR_SELECTED_ORGANIZATION_DATA_SELECTIONS:
            return {
                ...state,
                boardId: null,
                boardName: null,
                classId: null,
                className: null,
                groupId: null,
                groupName: null,
            }
        default: return state
    }
}

