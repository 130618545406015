import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { isValidUrl } from '../../utile/formHelper';


export default class Resources extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: props.data && props.data.url ? props.data.url : '',
            isAssigned: props.data ? true : false,
            errorMsg: '',
        }

    }



    onChange = (field, data) => {
        this.setState({
            [field]: data
        },
            () => {
                this.props.onSave(
                    {
                        id: this.props.id,
                        data: {

                        },
                        assigned: false
                    });
            });

    };


    assignCurriculum = () => {
        const { url } = this.state;

        if (url != '') {
            if (isValidUrl(url)) {
                this.setState({
                    isAssigned: true
                }, () => {
                    this.props.onSave(
                        {
                            id: this.props.id,
                            data: {
                                url
                            },
                            assigned: true
                        });
                })
            } else {
                this.setState({
                    errorMsg: 'Invalid URL'
                });
                setTimeout(() => {
                    this.setState({
                        errorMsg: ''
                    });
                }, 3000);
            }
        } else {
            this.setState({
                errorMsg: 'Url is required'
            });

            setTimeout(() => {
                this.setState({
                    errorMsg: ''
                });
            }, 3000);
        }



    }


    onEditClick = () => {
        if (this.props.resources.every((s) => s.assigned === true)) {
            this.setState({
                isAssigned: false,
            }, () => {
                this.props.onSave(
                    {
                        id: this.props.id,
                        assigned: false
                    });
            });
        } else {
            this.toast.show({ severity: 'warn', summary: 'Warning', detail: 'Please add or remove which is in edit state.', life: 3000 });
        }

    }





    render() {
        return (
            <div className='p-grid' >

                <div className='p-col-12 p-md-12'>
                    <div className='p-grid ma-w100p ma-no-pm' >

                        <InputText className='p-inputtext-style1 ma-w50p'
                        disabled={this.state.isAssigned}
                            onChange={(e) => this.onChange('url', e.target.value)}
                            value={this.state.url}
                        />
                        {this.state.isAssigned ? <Button
                            label={`Edit`}
                            className='ma-ml10'
                            onClick={this.onEditClick}
                        />

                            : <Button
                            className='ma-ml10'
                                label={`Save`}
                                onClick={this.assignCurriculum}
                            />
                        }
                        <span className="ma-ml10 previous-remove ma-pointer" onClick={() => { this.props.removeResourcesRow(this.props.id) }}>
                            <i className="pi pi-times"></i>
                        </span>
                    </div>

                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                    <p className='ma-error-msg'>{this.state.errorMsg}</p>
                </div>
            </div>
        )
    }
}
