import * as ActionType from "../actionTypes";

const initialState = {
    activeContent: {},
    videoLinking: {
        question: [], content: []
    },
    selectedVideoQuestion: [],
    filterVideoLinkingQuestion: []
}

export default (state = initialState, action) => {
    switch (action.type) {

        case ActionType.RECIVED_VIDEO_FETCH_QUESTION:
            return {
                ...state,
                loading: false,
                errorInfo: null,
                error: false,
                response: action.res,
                activeContent: { ...state.activeContent, ...action.content[0] },
                selectedVideoQuestion: action.content[0]['questionIds'],
                videoLinking: {
                    question: action.question,
                    content: action.content,
                }
            }
        case ActionType.REQUEST_VIDEO_FETCH_QUESTION:
            return {
                ...state,
                loading: true,
                errorInfo: null,
                error: false

            }

        case ActionType.ACTIVE_VIDEO_LINKING:
            return {
                ...state,
                activeContent:  action.data ,
                selectedVideoQuestion: action.data.questionIds ? action.data.questionIds : [],
                loading: false,
                errorInfo: null,
                error: false

            }
        case ActionType.FILTER_VIDEO_LINKING_QUESTION:
            return {
                ...state,
                filterVideoLinkingQuestion: action.data || [],
                loading: false,
                errorInfo: null,
                error: false

            }

        case ActionType.SELECTED_VIDEO_LINKING_QUESTION:
            return {
                ...state,
                selectedVideoQuestion: [...action.data],
                loading: false,
                errorInfo: null,
                error: false

            }
        case ActionType.SAVE_VIDEO_LINKING_QUESTION:
            return {
                ...state,
                loading: false,
                errorInfo: null,
                error: false,
                success: true
            }
        case ActionType.ERROR_VIDEO_FETCH_QUESTION:
            return {
                ...state,
                loading: false,
                errorInfo: action.err,
                error: true
            }
        case ActionType.CLEAR_VIDEO_FETCH_QUESTION:
            return { ...initialState }
        case ActionType.CLEAR_VIDEO_SEARCH_QUESTION:
            return {
                filterVideoLinkingQuestion: [],
                videoLinking: {
                    question: [], content: []
                },
            }
        default:
            return state

    }
}