import React, { Component } from 'react';
import moment from 'moment';
import { discussionBoardEndpoint } from './../../../store/apiConstants';
import { Toolbar } from 'primereact/toolbar';
import Service from './../service';
import ReportDialog from './report';
import { getProfileURL } from './../../../utile';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Attachments from './attachments';
import CreateDiscussion from './../allDiscussions/createDiscussion';
import CreateComment from '../allComments/createComment';
import { Menu } from 'primereact/menu';
import { DisLikeIcon, DisLike_HighlightIcon, Edit2Icon, LikeIcon, ReportingHighlightIcon, TickMarkIcon } from '../../svgIcons';
const TEACHER_ID = localStorage.getItem('discussion_uId');

class Comment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: this.props.item,
            showReport: false,
            showComments: true,
            open: false,
            showEdit: false,
            moreOptionsRef: React.createRef(null),
            isDeleted: false,
            isLoading: false

        }
        this.service = new Service();
    }


    handleClose = () => {
        this.setState({
            open: false
        })
    }

    likeOrDislike = (id, liked, event) => {

        if (liked && event == 'like') {
            return;
        }

        if (liked == false && event == 'dislike') {
            return;
        }


        let likeCount = 0;
        let dislikeCount = 0;
        if (liked) {
            if (event == 'dislike') {
                likeCount = -1;
                dislikeCount = 1;
            }
        } else if (liked == null) {
            if (event == 'dislike') {
                dislikeCount = 1;
            } else if (event == 'like') {
                likeCount = 1;
            }
        } else if (liked == false) {
            if (event == 'like') {
                likeCount = 1;
                dislikeCount = -1;
            }
        }


        const url = `${discussionBoardEndpoint}/comments/${id}/${event}`;
        let reqBody = {
            postId: this.props.postId
        };



        this.service.post(url, reqBody, true).then(data => {
            if (data && data.status) {
                if (data.res.status) {
                    this.setState((prevState) => ({
                        item: {
                            ...prevState.item,
                            liked: event == 'like' ? true : false,
                            likeCount: prevState.item.likeCount + likeCount,
                            dislikeCount: prevState.item.dislikeCount + dislikeCount
                        },
                        isLoading: false
                    }));
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            } else {
                this.setState({
                    isLoading: false
                })
            }
        });
    }

    reportThis = (reason) => {
        const url = `${discussionBoardEndpoint}/reports`;
        this.service.post(url, {
            for: 'Comment',
            doc: this.state.item._id,
            content: reason,
            postId: this.props.postId

        }, true).then(data => {
            if (data && data.status) {
                if (data.res.status) {
                    this.setState((prevState) => ({
                        item: { ...prevState.item, reported: true },
                        showReport: false
                    }));
                } else {
                    this.setState((prevState) => ({
                        showReport: false
                    }));
                }
            } else {
                this.setState((prevState) => ({
                    showReport: false
                }));
            }
        });
    }


    onMoreClick = (event) => {
        this.setState({
            open: true,
            moreOptionsRef: event.currentTarget
        });
    }

    markAsCorrectAnswer = (answerId) => {

        const url = `${discussionBoardEndpoint}/comments/${answerId}`;
        this.service.patch(url, {
            isCorrect: true
        }, true).then(data => {
            if (data && data.status) {
                if (data.res.status) {
                    this.setState((prevState) => ({
                        item: { ...prevState.item, isCorrect: true },
                        open: false
                    }));
                } else {
                    this.setState((prevState) => ({
                        open: false

                    }));
                }
            } else {
                this.setState((prevState) => ({
                    open: false
                }));
            }
        });
    }

    onEditClick = () => {
        this.setState({
            open: false,
            showEdit: true
        });
    }



    onEditComment = (answer) => {
        this.setState((prevState) => ({
            item: answer,
            showEdit: false
        }));
    }

    onDeleteClick = () => {
        this.setState({
            open: false
        });
        const url = `${discussionBoardEndpoint}/comments/${this.state.item._id}`;
        this.service.delete(url, {}, true).then(data => {

            if (data && data.status) {
                if (data.res.status) {
                    this.setState((prevState) => ({
                        isDeleted: true
                    }));
                }
            }
        });

    }

    render() {
        const { item, isDeleted } = this.state;
        const items = [
            {
                template: (<>{this.props.discussionUser && this.props.discussionUser._id != item.postedBy._id && !item.reported && <div className='ma-pointer' onClick={(event) => { this.setState({ showReport: true, open: false }); this.menu.toggle(event) }}><ReportingHighlightIcon width={"16px"} /> Report this Comment</div>}</>)
            },
            {
                template: (<>{!item.isCorrect && <div onClick={(event) => { this.markAsCorrectAnswer(item._id); this.menu.toggle(event) }} className='ma-pointer' ><TickMarkIcon width={"16px"} /> Mark as Correct Comment</div>}</>)
            },
            {
                template: (<>{this.props.discussionUser && this.props.discussionUser._id == item.postedBy._id && <div className='ma-pointer' onClick={(event) => { this.onEditClick(item); this.menu.toggle(event) }}> <Edit2Icon width={"16px"} /> Edit</div>}</>)
            },
            {
                template: (<>{this.props.discussionUser && this.props.discussionUser._id == item.postedBy._id && <div className='ma-pointer' onClick={(event) => { this.onDeleteClick(item); this.menu.toggle(event) }}> <i className="pi pi-trash" /> Delete</div>}</>)
            }
        ]


        return (
            <>
                {(!item.reported && !isDeleted) && <div className="discussion-item disc-comment"  >
                    {this.state.showEdit ? <>
                        <CreateComment onEditComment={this.onEditComment} postId={this.props.postId} comment={item} />
                    </> : <>
                        <div style={{ width: '100%', overflow: 'hidden' }}>
                            <div style={{ width: '40px', float: 'left' }}>
                                <img src={getProfileURL(item?.postedBy?.photo)} alt="n" className="profile-img" />
                            </div>
                            <div style={{ marginLeft: '40px' }} className="answer-c">
                                <p className="profile-name2">{item.postedBy.name}
                                    {item.isCorrect && <span className="correct-ans">Correct</span>}
                                </p>
                                <p className="created-at">{moment(item.createdAt).fromNow()} </p>
                                <p className="answer-txt">{item.content}</p>
                                {/* <div className='p-grid'>
                                    <div className='p-col-6'>
                                        <ul className="discussion-actions" >
                                            <li onClick={() => { this.likeOrDislike(item._id, item.liked, 'like') }}>
                                                {item.liked ? <img src="./images/Like_highlighted.svg" className="li-img-icon ma-pointer" /> : <img src="./images/Like.svg" className="li-img-icon ma-pointer" />}
                                                {item.likeCount}
                                            </li>
                                            <li onClick={() => { this.likeOrDislike(item._id, item.liked, 'dislike') }}  >
                                                {item.liked == false ? <img src="./images/Dis_Like_highlighted.svg" className="li-img-icon ma-pointer" /> : <img src="./images/Dis_Like.svg" className="li-img-icon ma-pointer" />}
                                                {item.dislikeCount}</li>
                                        </ul>
                                    </div>
                                    <div className='p-col-6'>
                                        <span className="favourite-icon"
                                            onClick={(event) => this.menu.toggle(event)}
                                        >
                                            <img src="./images/menu.svg" className="li-img-icon ma-pointer" /> </span>
                                    </div>
                                </div> */}
                                <Toolbar className="ma-toolbard"
                                    left={() => {
                                        return <ul className="discussion-actions" >
                                            <li onClick={() => { this.likeOrDislike(item._id, item.liked, 'like') }}>
                                                <span className='ma-pointer'>
                                                    <LikeIcon width={16} height={16} color={item.liked ? 'var(--primary-color)' : '#9D9A9A'} />
                                                </span>
                                                {item.likeCount}
                                            </li>
                                            <li onClick={() => { this.likeOrDislike(item._id, item.liked, 'dislike') }}  >
                                                {/* {item.liked == false ? <img src="./images/Dis_Like_highlighted.svg" className="li-img-icon ma-pointer" /> : <img src="./images/Dis_Like.svg" className="li-img-icon ma-pointer" />} */}
                                                <span className='ma-pointer'>
                                                    <DisLikeIcon width={16} height={16} color={item.liked == false ? "#ec8888" : '#9d9a9a'} />
                                                </span>
                                                {item.dislikeCount}</li>
                                        </ul>
                                    }}
                                    right={() => {
                                        return <>
                                            {items && items.length ? <span className="favourite-icon"
                                                onClick={(event) => this.menu.toggle(event)}
                                            >
                                                <i className="pi pi-ellipsis-h ma-pointer" />
                                            </span> : <></>}
                                        </>

                                    }}

                                ></Toolbar>
                                <Menu model={items} className="answer-menu" popup ref={el => this.menu = el} />

                            </div>
                        </div>
                    </>
                    }
                    {
                        this.state.showReport && <ReportDialog open={true} onClose={() => { this.setState({ showReport: false }) }} reportThis={this.reportThis} />
                    }
                </div>
                }
            </>
        )
    }
}



const mapStateToProps = state => {
    return {
        discussionUser: state.currentUserPerms && state.currentUserPerms.discussionUser && state.currentUserPerms.discussionUser,
    }
}

export default connect(mapStateToProps, {})(withRouter(Comment));