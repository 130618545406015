import { Dialog } from 'primereact/dialog';
import React, { Component } from 'react'
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import {
    VideoIcon, PptIcon,
    PdfIcon,
    QuizIcon,
    QuizCountIcon
} from '../../svgIcons';
import { getRandomColor } from '../../../utile';
let totalAnalyticsTypes = [
    { field: 'mp4', title: 'VIDEOS', icon: <VideoIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Time Spent' },
    { field: 'pdf', title: 'PDF', icon: <PdfIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Time Spent' },
    { field: 'zip', title: 'PPT', icon: <PptIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Time Spent' },
    { field: 'quiz', title: 'QUIZS', icon: <QuizIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Time Spent' },
    { field: 'quizCount', title: 'QUIZ ATTEMPTS', icon: <QuizCountIcon width={34} height={34} color={'var(--primary-color)'} />, subTitle: 'Total Attempts' }

]

// const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {

//     return (
//         <text
//             x={centerX}
//             y={centerY}
//             textAnchor="middle"
//             dominantBaseline="central"
//             style={{
//                 fontSize: '52px',
//                 fontWeight: 600,
//             }}
//         >
//             {'fff'}
//         </text>
//     )
// }

export default class GraphicalView extends Component {
    render() {
        // console.log('props-graph', this.props)
        const { hideGraphicalView, totalUsageAnalytics, subjectWiseGraphData, contentWiseWiseGraphData } = this.props;
        return (<Dialog
            //visible={this.state.isShowCreate}
            className='graph-view'
            style={{ width: '90%', height: '90vh' }}
            header='Overall Usage Analytics'
            modal
            //footer={this.createRoleDialogFooter}
            draggable={false}
            // footer={this.footer}
            blockScroll={true}
            closeOnEscape={false}
            dismissableMask={false}
            visible={true}
            closable={true}
            onHide={hideGraphicalView}>
            <div>

                <Toolbar className="ma-toolbard"
                    //  left={() => {
                    //     return <>
                    //         <i className='pi pi-refresh refresh-analytics' /></>
                    // }}
                    right={() => {
                        return <> <Button label='Download PDF' /> </>
                    }}
                ></Toolbar>
                <div className='p-grid sp-no-pm highlights-tabs '>
                    {totalAnalyticsTypes.map((a) => {
                        return <div className="p-col-12 p-sm-12 p-md-2-5 ">
                            <div className='paper-s1 highlights-tab border-primary'>
                                <p className='h-title'><span className='content-type'>{a.icon} </span>{a.title}</p>
                                <p ><span className='h-time-l'>{a.subTitle}:</span> <span className='h-time'>{totalUsageAnalytics[a.field]}</span></p>
                            </div>
                        </div>
                    })}
                </div>


                <div className='p-grid analytics-graph '>
                    <div className="p-col-12 p-md-6 ">

                        <div className='hours-graph-bar'>
                            {/* <p>Resource usage <span>(overall)</span></p> */}
                            {
                                !totalUsageAnalytics.totalTime ? <>
                                    <p className='no-data'> No data found</p> </> : <>
                                    <ResponsivePie
                                        //layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
                                        data={subjectWiseGraphData}
                                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                        innerRadius={0.55}
                                        padAngle={0}
                                        cornerRadius={0}
                                        activeOuterRadiusOffset={8}
                                        borderWidth={0}
                                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                        // arcLinkLabelsSkipAngle={10}
                                        enableSliceLabels={false}
                                        enableRadialLabels={false}
                                        enableArcLinkLabels={false}
                                        // arcLinkLabelsTextColor="#333333"
                                        // colors={Array.from({ length: (subjectWiseGraphData && subjectWiseGraphData) ? subjectWiseGraphData.length : 10 }, (_, index) => index + 1).map((l, index) => {
                                        //     return getRandomColor()
                                        // })}
                                        colors={['#F82D13', '#20C826', '#312BDD', '#ff00bf', '#ff9900', '#00ffaa', '#00ffff', '#ffff00', '#884dff', '#80bfff', '#800000', '#000099']}
                                        // arcLinkLabelsThickness={2}
                                        // arcLinkLabelsColor={{ from: 'color' }}
                                        // arcLabelsSkipAngle={10}
                                        // arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}

                                        tooltip={(dt) => {
                                            return <h3>{dt.datum.value + " %"}</h3>
                                        }}
                                        legends={[
                                            {
                                                anchor: 'bottom',
                                                direction: 'row',
                                                justify: false,
                                                translateX: 0,
                                                translateY: 56,
                                                //itemsSpacing: 0,
                                                itemsSpacing: 31,
                                                itemWidth: 80,
                                                //itemWidth: 60,
                                                itemHeight: 16,
                                                itemTextColor: '#999',
                                                itemDirection: 'left-to-right',
                                                itemOpacity: 1,
                                                symbolSize: 16,
                                                symbolShape: 'circle',
                                                effects: [
                                                    {
                                                        on: 'hover',
                                                        style: {
                                                            itemTextColor: '#000'
                                                        }
                                                    }
                                                ]
                                            }
                                        ]}
                                    //  layers={[ CenteredMetric]}
                                    />
                                </>
                            }

                        </div>
                    </div>
                    <div className="p-col-12 p-md-6 ">
                        <div className='hours-graph-pie' >

                            <div style={{ height: '100%', width: '100%' }}>
                                {
                                    !totalUsageAnalytics.totalTime ? <>
                                        <p className='no-data'> No data found</p> </> : <>
                                        <ResponsivePie
                                            enableArcLinkLabels={false}
                                            enableSliceLabels={false}
                                            enableRadialLabels={false}
                                            data={contentWiseWiseGraphData}
                                            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                            innerRadius={0.55}
                                            padAngle={0}
                                            cornerRadius={0}
                                            activeOuterRadiusOffset={8}
                                            borderWidth={0}
                                            tooltip={(dt) => {
                                                return <h3>{dt.datum.value + " %"}</h3>
                                            }}
                                            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                            // arcLinkLabelsSkipAngle={10}
                                            //enableSliceLabels={false}
                                            // enableRadialLabels={false}
                                            // arcLinkLabelsTextColor="#333333"
                                            colors={['#20C2C8', '#FF8373', '#FFDA83', '#A3A0FB']}
                                            // arcLinkLabelsThickness={2}
                                            // arcLinkLabelsColor={{ from: 'color' }}
                                            // arcLabelsSkipAngle={10}
                                            // arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}


                                            legends={[
                                                {
                                                    anchor: 'bottom',
                                                    direction: 'row',
                                                    justify: false,
                                                    translateX: 0,
                                                    translateY: 56,
                                                    itemsSpacing: 0,
                                                    itemWidth: 60,
                                                    itemHeight: 16,
                                                    itemTextColor: '#999',
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 1,
                                                    symbolSize: 16,
                                                    symbolShape: 'circle',
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemTextColor: '#000'
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                        //  layers={[ CenteredMetric]}
                                        />
                                    </>}

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </Dialog >


        )
    }
}
