import * as ActionTypes  from '../actionTypes';
import axios from 'axios';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../apiConstants';


const dispatchRequestUpdateContent = data => ({
    type: ActionTypes.REQUEST_UPDATE_CONTENT,
    data
})

const dispatchReceivedUpdateContent = res => ({
    type: ActionTypes.RECEIVED_UPDATE_CONTENT,
    res
})

const dispatchErrorUpdateContent = err => ({
    type: ActionTypes.ERROR_UPDATE_CONTENT,
    err
})

const dispatchClearUpdateContent = () => ({
    type: ActionTypes.CLEAR_UPDATE_CONTENT
})

export const clearContentData = () => dispatchClearUpdateContent();


export const updateContent = (content) => (dispatch, getState) => {
    const organizationData = getState().organizationData;
    const boards = organizationData.response && organizationData.response.Item && organizationData.response.Item.boards

    let headers = {};
    const etag = localStorage.getItem('etag');
    if(etag) {
        headers = {
            "If-Match": etag
        }
    }

    dispatch(dispatchRequestUpdateContent());
    axios({
        method: 'put',      
        url: `${baseUrlForLongRunningTask}/long/content`,
        data: content,
        headers  
    }).then((res) => {        
        dispatch(dispatchReceivedUpdateContent(res.data));
    }).catch(e => {
        dispatch(dispatchErrorUpdateContent(e));
    });
}


