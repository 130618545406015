import * as ActionTypes from './../actionTypes';

export default (state={},action) => {
    switch(action.type){
        case ActionTypes.REQUEST_UPDATE_CONTENT:                
            return {
                ...state,
                loading:true,
                error:false,
                errorInfo:null
            }        
        case ActionTypes.RECEIVED_UPDATE_CONTENT:
            return {
                ...state,
                loading:false,
                response:action.res,
                error:false,
                errorInfo:null
            }
        case ActionTypes.ERROR_UPDATE_CONTENT:
            return{
                ...state,
                loading:false,
                error:true,
                errorInfo:action.err
            }
        case ActionTypes.CLEAR_UPDATE_CONTENT:
            return {}
        default: return state;
    }
} 