
import * as ActionTypes from '../actionTypes';

export default (state = {}, action) => {

    switch (action.type) {
        case ActionTypes.REQUEST_UPDATE_USER_PACKAGE:
            return {
                ...state,
                loading: true,
                error: false,
                errorInfo: null,
                response: null
            }
        case ActionTypes.RECEIVED_UPDATE_USER_PACKAGE:
            return {
                ...state,
                loading: false,
                response: action.res,
                error: false,
                errorInfo: null
            }
        case ActionTypes.ERROR_UPDATE_USER_PACKAGE:
            return {
                ...state,
                loading: false,
                error: true,
                errorInfo: action.err,
                response: null
            }
        case ActionTypes.CLEAR_UPDATE_USER_PACKAGE:
            return {}
        default: return state;
    }
} 