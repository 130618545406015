import Service from '../../services';


import {
    baseUrlAdmin
} from '../../store/apiConstants';


export default class Services {
    getUserGrades = (user_id) => {
        const url = `${baseUrlAdmin}/user/grades/getUserGrades/${user_id}`;
        const service = new Service();
        return service.get(url, true);
    }
    getAllUserGrades = (user_id) => {
        const url = `${baseUrlAdmin}/user/grades/getAllUserGrades/${user_id}`;
        const service = new Service();
        return service.get(url, true);
    }


    addNewGradeToUser = (data) => {
        const service = new Service();
       
        let url = `${baseUrlAdmin}/user/addgradetouser`;
        return service.put(url, data, true);
    }

    deleteGrade = (data) => {
        let service = new Service();
        let url = `${baseUrlAdmin}/user/deletegradeofuser`;
        return service.put(url, data, true);
    }



}