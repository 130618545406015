import * as ActionType from '../actionTypes'

const store = (state = {
    courses: []
}, action) => {
    switch (action.type) {
        case ActionType.REQUEST_GET_ALL_COURSE_DETAILS:
            return {
                ...state,
                loading: true,
                errorInfo: null,
                error: false
            }

        case ActionType.RECIVED_GET_COURSE_DETAILS:
            return {
                ...state,
                loading: false,
                errorInfo: null,
                error: false,
                response: action.res,
                courses :action.res.data
            }
        case ActionType.ERROR_GET_COURSE_DETAILS:
            return {
                ...state,
                loading: false,
                errorInfo: action.err,
                error: true
            }
        case ActionType.CLEAR_ALL_COURSE_DETAILS:
            return {

            }
        default:
            return state
    }
}
export default store;