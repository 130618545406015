import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';

import { Menu } from 'primereact/menu';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';

import { Button } from 'primereact/button';
import { DeleteIcon, AttachmentIcon, CloudIcon, EditIcon, PreviewIcon, CalendarIcon, NotificationIcon, PlayIcon, WarningIcon } from '../svgIcons';
import SvgViewer from './../customComponents/svgViewer';
import { Dialog } from 'primereact/dialog';
import Authentication from './../session';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { BreadCrumb } from 'primereact/breadcrumb';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../store/apiConstants';
import Service from '../../services';
import Navbar from './../navbar';
import {

    getCurriculum,
    getActiveOrganizationData,
    selectBoard,
    selectClass,
    selectGroup,
    clearSelectedOrganizationData,
    updateSubject,
    getSubject,
    dispatchClearCurriculumData,
    startExportToOffline,
    getImageUrl,
    getBoardsData
} from './../../store/actions';
import './styles.scss';
import CreateClass from './createClass';
import axios from 'axios';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import ResourceUpload from './resourceUpload';
import LoadingComponent from '../loadingComponent';
import { BasicLazyParams, getServerTime } from '../../utile';
import AttandeesDetails from './attandeesDetails';
import ParticipantsDetails from './participantsDetails';
import ClassDetails from './classDetails';
import AttachmentsUploadWithProgress from '../discussionBoard/common/attachmentUploadWithProgress';
import Attachments from '../discussionBoard/common/attachments';
import AttachmentWithIcons from '../discussionBoard/common/attachmentsWithIcons';
import Authorizer, { PERMISSIONS } from '../session/authorizer';

const items = [
    { label: 'Academics' },
    { label: 'Virtual Class', className: 'ma-active-item' }
];
const createItems = [
    { label: 'Academics' },
    { label: 'Virtual Class' },
    { label: 'Create/Edit Class', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' };


export class VirtualClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            boardId: null,
            classId: null,
            groupId: null,
            boards: [],
            classes: [],
            groups: [],
            isShowCreate: false,
            virtualClasses: [],
            showSearch: false,
            showSearch: false,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            filter: 'ongoing',
            isShowMeetingStartDialog: false,
            selectedClassId: null,
            isShowConfirmDelete: false,
            editVirtualClass: null,
            columns: [],
            totalRecords: 0,
            selectedIndex: null,
        }


        this.service = new Service();
    }



    handleDownload = (url, filename) => {

        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {

                const url = window.URL.createObjectURL(
                    new Blob([res.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `FileName.pdf`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                // fileDownload(res.data, filename)
            })
    }


    componentDidMount() {




        //   this.handleDownload('./certificate.pdf', 'sss')


        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        } else {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });
                this.setState({
                    boards: boards
                });
            }


        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.boards !== this.props.boards) {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });


                this.setState({
                    boards: boards
                });
            }
        }

        if (this.state.curriculumInfo && this.state.curriculumInfo.offlineExportStatus && this.state.curriculumInfo.offlineExportStatus.storageKey && this.props.getAllImagesUrlInfo && this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey] && (prevProps.getAllImagesUrlInfo && (this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey] != prevProps.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey]))) {
            window.open(this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey]);
        }

    }



    // grades selection --- start
    onChangeBoard = (boardId) => {
        this.setState({ classes: [], groups: [], classId: null, groupId: null });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
            virtualClasses: [],
            totalRecords: 0,
        });

    };

    onChangeClass = (classId) => {
        this.setState({ groups: [], groupId: null });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);

        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                groupId: _groups[0].value,
                virtualClasses: [],
                totalRecords: 0,
            }
                // , () => {
                //     this.getMeetings()
                // }
            );
        }
    };

    onCreateSuccess = () => {
        this.setState({
            isShowCreate: false,
            lazyParams: BasicLazyParams
        }, () => {
            this.getMeetings()
        });
    }

    onChangeGroup = (groupId) => {
        this.setState(
            {
                groupId,
            },
            () => {
                this.getMeetings()
            }
        );
    };
    // grades selection --- end


    //subjects --- start


    getCurriculumData = () => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}`;
            this.service.get(url, true).then((data) => {
                if (data && data.res && data.res.Item) {
                    this.setState({
                        curriculumInfo: data.res.Item,
                        showSubjects: true
                    });
                } else {

                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            // this.snackBarOpen('select board, class and group id');
        }
    };


    applyFilter = (filter) => {
        this.setState({
            filter
        }, () => {
            this.getMeetings()
        });
    }

    getMeetings = () => {

        const {
            lazyParams, globalSearch
        } = this.state;

        if (this.state.groupId && this.state.filter) {
            this.setState({
                isLoading: true,
                virtualClasses: [],
                totalRecords: 0,
            });

            const url = `${baseUrlAdmin}/virtualclass/getmeetings/${this.state.groupId}?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}&sort=-startTime&filter=${this.state.filter}`;

            this.service.get(url, true).then((data) => {

                if (data && data.res && data.res.data) {
                    this.setState({
                        isLoading: false,
                        virtualClasses: data.res.data.virtualClasses,
                        totalRecords: data.res.data.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            }).catch((e) => {
                this.setState({
                    isLoading: false
                });

            });
        } else {

            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select borad and class', life: 3000 });

        }


    }



    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getMeetings);
    }


    deleteMeeting = (rowData) => {

        this.setState({
            selectedClassId: rowData._id,
            isShowConfirmDelete: true
        });


    }

    showResourceUpload = (rowData) => {
        this.setState({
            editVirtualClass: rowData,
            isShowUploadResource: true
        });

    }

    showClassDetails = (rowData) => {
        this.setState({
            editVirtualClass: rowData,
            isShowClassDetails: true
        });

    }

    showAtttendees = (virtualClassId) => {
        this.setState({
            virtualClassId,
            isShowAttendees: true
        })
    }




    uploadFiles = (resources) => {
        this.setState({
            isLoading: true
        });

        const { editVirtualClass } = this.state;

        const url = `${baseUrlAdmin}/virtualclass/update`;
        this.service.put(url, { _id: editVirtualClass._id, attachments: resources }, true).then((data) => {
            if (data && data.res && data.res.status) {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Resources uploaded successfully.', life: 3000 });
                this.setState({
                    isLoading: false,
                    isShowUploadResource: false
                }, () => {
                    this.getMeetings();
                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured.', life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });

        });

        // this.setState({
        //     isShowUploadResource: false
        // });

    }

    onConfirmDelete = () => {
        this.setState({
            isLoading: true
        })

        const url = `${baseUrlAdmin}/virtualclass/delete/${this.state.selectedClassId}`;
        this.service.delete(url, {}, true).then((data) => {
            if (data && data.res && data.res.status) {
                this.setState({
                    isLoading: false,
                    isShowConfirmDelete: false
                }, () => {
                    this.getMeetings();
                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured.', life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });

        });
    }


    changeMeetingStatus = (meetigId, status) => {

        this.setState({
            isLoading: true
        })

        const url = `${baseUrlAdmin}/virtualclass/change/${meetigId}/status/${status}`;
        this.service.post(url, {}, true).then((data) => {
            if (data && data.res && data.res.status) {
                this.setState({
                    isLoading: false
                }, () => {
                    this.getMeetings();
                })

            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured.', life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });

        });

    }

    startMeeting = (rowData) => {
        if (rowData.meetingApp == 'zoom') {
            window.open(rowData.meetingInfo.start_url, "_blank");
        } else if (rowData.meetingApp == 'teams') {
            window.open(rowData.meetingInfo.joinUrl, "_blank");
        } else if (rowData.meetingApp == 'custom') {
            window.open(rowData.customJoinLink, "_blank");
        }
        this.changeMeetingStatus(rowData._id, 'running');
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Virtual Classes</h2>
                {/* <p>{this.state.isShowChapters && 'Chapters'}</p> */}
            </React.Fragment>
        )
    }
    leftToolbarTemplate = () => {

        return (
            <React.Fragment>
                <BreadCrumb model={this.state.isShowCreate ? createItems : items} home={home} />
            </React.Fragment>
        )
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getMeetings);
    }

    onGoBack = () => {
        this.setState({
            isShowCreate: false,
            editClassData: null
        })
    }


    exportCSV = () => {
        //   this.dt.exportCSV();
    }


    showParticipants = (virtualClass) => {
        this.setState({
            isShowParticipants: true,
            virtualClass
        })
    }

    getSubjectsAndSections = (user_id) => {
        this.setState({
            isLoading: true
        })

        const url = `${baseUrlAdmin}/admin/getusersec/${user_id}`;
        this.service.get(url, true).then((data) => {

            if (data && data.res && data.res.status) {
                this.setState({
                    isLoading: false,
                    teacherAssignedSubjects: data.res.data.assignedSubjects,
                    teacherAssignedSections: data.res.data.assignedSections,
                    isShowCreate: true,
                });
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });
    };

    onEditClass = (rowData) => {
        this.setState({
            editClassData: rowData
        }, () => {
            this.getSubjectsAndSections(rowData.teacherId);
        })
    }


    render() {
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title capitalize'>{this.state.filter} Classes</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={this.onGlobalSearch} />}

                </div>

                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.VIRTUAL_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.VIRTUAL_FILTERS} >
                            <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.VIRTUAL_VIEW_COLUMNS} >
                            <li><i data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.VIRTUAL_DOWNLOAD} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
            </div>
        );

        const { editVirtualClass, selectedIndex, totalRecords, lazyParams } = this.state;
        return (
            <div>
                <Navbar >
                    <div className="p-grid ma-toolbar">
                        <div className="p-col-12 p-md-1 ma-no-p">
                            {(this.state.isShowCreate) && <>
                                <p className='back-arrow' onClick={this.onGoBack}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                            </>
                            }
                        </div>
                        <div className="p-col-12 p-md-11 ma-no-pm">
                            <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        </div>
                    </div>
                    {
                        this.state.isShowCreate ? <><CreateClass teacherAssignedSubjects={this.state.teacherAssignedSubjects} teacherAssignedSections={this.state.teacherAssignedSections} onCreateSuccess={this.onCreateSuccess} editClassData={this.state.editClassData} />
                        </> : <>
                            <div className='ma-main'>
                                <div>
                                    <Dropdown value={this.state.boardId}
                                        className='ma-m-lr10 ma-w200'
                                        options={this.state.boards}
                                        onChange={(e) => this.onChangeBoard(e.value)}
                                        placeholder="Select Board" />
                                    <Dropdown value={this.state.classId}
                                        options={this.state.classes}
                                        className='ma-m-lr10 ma-w200'
                                        onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                                    <Authorizer permId={PERMISSIONS.VIRTUAL_FETCH_CLASSES} >
                                        <Button label="Fetch Classes"
                                            onClick={this.getMeetings}
                                            className='ma-m-lr10'
                                        />
                                    </Authorizer>
                                </div>
                                <div>
                                    <Toolbar className="ma-toolbard" left={() => {
                                        return (
                                            <div className='ma-mtb25'>
                                                <Authorizer permId={PERMISSIONS.VIRTUAL_ONGOING} >
                                                    <Button label="Ongoing" className={`${this.state.filter == 'ongoing' ? '' : 'ma-disabled'} ma-m-lr10`}
                                                        onClick={() => { this.applyFilter('ongoing') }} />
                                                </Authorizer>
                                                <Authorizer permId={PERMISSIONS.VIRTUAL_UPCOMING} >
                                                    <Button label="Upcoming" className={`${this.state.filter == 'upcoming' ? '' : 'ma-disabled'} ma-m-lr10`}
                                                        onClick={() => { this.applyFilter('upcoming') }} />
                                                </Authorizer>
                                                <Authorizer permId={PERMISSIONS.VIRTUAL_COMPLETED} >
                                                    <Button label="Completed" className={`${this.state.filter == 'completed' ? '' : 'ma-disabled'} ma-m-lr10`}
                                                        onClick={() => { this.applyFilter('completed') }} />
                                                </Authorizer>
                                            </div>
                                        )
                                    }} right={() => {
                                        return (
                                            <Authorizer permId={PERMISSIONS.VIRTUAL_CREATE} >
                                                <Button icon="pi pi-plus" label="Create New Class" className='ma-m-lr10'
                                                    onClick={() => { this.setState({ isShowCreate: true, editClassData: null }) }} />
                                            </Authorizer>
                                        )

                                    }}></Toolbar>
                                </div>
                                <div className='card datatable-crud-demo ma-mt20 fetch-q-tabled'>
                                    <div className='p-datatable p-component p-datatable-scrollable ma-table-d'>
                                        <div className='p-datatable-header'>
                                            <div className="table-header ma-tbl-header">
                                                <div className="ma-tbl-left">
                                                    <h2 className='ma-table-title capitalize'>{this.state.filter} Classes</h2>
                                                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={this.onGlobalSearch} />}
                                                </div>
                                                <div className="p-input-icon-left ma-tbl-filter">
                                                    <ul className="ma-tbl-li">
                                                    <Authorizer permId={PERMISSIONS.VIRTUAL_SEARCH} >
                                                        <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                                                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                                                        </Authorizer>
                                                        <Authorizer permId={PERMISSIONS.VIRTUAL_VIEW_COLUMNS} >
                                                        <li><i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                                                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                                                        </Authorizer>
                                                        <Authorizer permId={PERMISSIONS.VIRTUAL_DOWNLOAD} >
                                                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                                                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                                                        </Authorizer>
                                                    </ul>
                                                </div>
                                                <Menu
                                                    className='table-filter-w'
                                                    model={[
                                                        {
                                                            template: (<div className='table-filter'>
                                                                <h4 className="filter-title">View Columns</h4>
                                                                <ul>
                                                                    {this.state.columns.map((column) => {
                                                                        return <li onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                                                            checked={column.isVisible}
                                                                        ></Checkbox>
                                                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                                                        </li>
                                                                    })}
                                                                </ul>
                                                            </div>)
                                                        }]
                                                    }
                                                    popup
                                                    ref={el => this.menu = el} />
                                            </div>
                                        </div>
                                        <div className='p-datatable-scrollable-wrapper'>
                                            <div className='p-datatable-scrollable-view'>
                                                <div className='p-datatable-scrollable-header'>
                                                    <table class="p-datatable-scrollable-header-table ma-table-tbl">

                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='p-datatable-scrollable-body p-datatable-scrollable-header'>
                                            <table className='p-datatable-scrollable-body-table ma-table-tbl' >
                                                <thead class="p-datatable-thead">
                                                    <tr role="row">
                                                        <th role="columnheader" class="p-sortable-disabled difficulty">
                                                            <span class="p-column-title">Class Title</span>
                                                        </th>
                                                        <th role="columnheader" class="p-sortable-disabled difficulty">
                                                            <span class="p-column-title">Date &amp; Time</span>
                                                        </th>
                                                        <th role="columnheader" class="p-sortable-disabled difficulty">
                                                            <span class="p-column-title">Subject Name</span>
                                                        </th>
                                                        <th role="columnheader" class="p-sortable-disabled difficulty">
                                                            <span class="p-column-title">Chapter</span>
                                                        </th>
                                                        <th role="columnheader" class="p-sortable-disabled difficulty">
                                                            <span class="p-column-title">Strength</span>
                                                        </th>
                                                        <th role="columnheader" class="p-sortable-disabled difficulty">
                                                            <span class="p-column-title">Actions</span>
                                                        </th>
                                                        <th role="columnheader" class="p-sortable-disabled question-id">
                                                            <p></p>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className='p-datatable-tbody'>
                                                    {
                                                        this.state.virtualClasses.map((rowData, { rowIndex }) => {
                                                            return <>
                                                                <tr>
                                                                    <td>

                                                                        {rowData.title}
                                                                    </td>
                                                                    <td>{<div className="ma-status">
                                                                        {moment(rowData.startTime).format('lll')}
                                                                    </div>}</td>
                                                                    <td>{rowData.subjectName}</td>
                                                                    <td> <div className="ma-status">
                                                                        {rowData.chapters.map(a => a.chapterName).join(', ')}
                                                                    </div></td>
                                                                    <td>  <span data-pr-tooltip="View students" data-pr-position="bottom" className={`abl${rowIndex} ma-pointer`} style={{ color: 'var(--primary-color)' }} onClick={() => this.showParticipants(rowData)}>{rowData.strength}</span>
                                                                        <Tooltip className="table-li-tooltip2" autoHide={false} target={`.abl${rowIndex}`} /></td>

                                                                    <td >
                                                                        <div style={{ width: '200px' }}>
                                                                            {
                                                                                this.state.filter == 'ongoing' && <>
                                                                                    <Authorizer permId={PERMISSIONS.VIRTUAL_JOINANDEXIT} >
                                                                                        <Button label="Join" className='p-button-outlined' onClick={() => this.startMeeting(rowData)} />
                                                                                        {
                                                                                            rowData.meetingStatus == 'running' && <Button label="Exit" className='ma-m-lr10 p-button-outlined' onClick={() => { this.changeMeetingStatus(rowData._id, 'completed') }} />
                                                                                        }
                                                                                    </Authorizer>
                                                                                    <Authorizer permId={PERMISSIONS.VIRTUAL_EDIT} >
                                                                                        {
                                                                                            getServerTime().getTime() < new Date(rowData.startTime).getTime() && <>
                                                                                                <span data-pr-tooltip="Edit" data-pr-position="bottom" className={`cds${rowIndex} ma-ml10 ma-pointer`} onClick={() => this.onEditClass(rowData)}><EditIcon height={20} width={20} color={`var(--primary-color)`} /></span>
                                                                                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.cds${rowIndex}`} />
                                                                                            </>
                                                                                        }
                                                                                    </Authorizer>
                                                                                    <Authorizer permId={PERMISSIONS.VIRTUAL_AUTO_REMINDER} >
                                                                                        <span data-pr-tooltip="Reminder" data-pr-position="bottom" className={`ma-ml10 ma-pointer cdsd${rowIndex}`}> <CalendarIcon height={20} width={20} /></span>
                                                                                        <Tooltip className="table-li-tooltip2" autoHide={false} target={`.cdsd${rowIndex}`} />
                                                                                    </Authorizer>
                                                                                    <Authorizer permId={PERMISSIONS.VIRTUAL_DELETE} >
                                                                                        <span data-pr-tooltip="Delete" data-pr-position="bottom" className={`ma-ml10 ma-pointer tgr${rowIndex}`} onClick={() => { this.deleteMeeting(rowData) }}> <DeleteIcon height={20} width={20} color={`var(--primary-color)`} /></span>
                                                                                        <Tooltip className="table-li-tooltip2" autoHide={false} target={`.tgr${rowIndex}`} />
                                                                                    </Authorizer>
                                                                                    <Authorizer permId={PERMISSIONS.VIRTUAL_NOTIFY} >
                                                                                        < span data-pr-tooltip="Notify" data-pr-position="bottom" className={`ma-ml10 ma-pointer www${rowIndex}`}> <NotificationIcon height={20} width={20} color='grey' /></span>
                                                                                        <Tooltip className="table-li-tooltip2" autoHide={false} target={`.www${rowIndex}`} />
                                                                                    </Authorizer>
                                                                                </>
                                                                            }
                                                                            {
                                                                                this.state.filter == 'upcoming' && <>
                                                                                    <Authorizer permId={PERMISSIONS.VIRTUAL_EDIT} >
                                                                                        <span data-pr-tooltip="Edit" data-pr-position="bottom" className={`ma-ml10 ma-pointer ghj${rowIndex}`} onClick={() => {
                                                                                            this.onEditClass(rowData)
                                                                                        }}><EditIcon height={20} width={20} color={'var(--primary-color)'} /></span>
                                                                                        <Tooltip className="table-li-tooltip2" autoHide={false} target={`.ghj${rowIndex}`} />
                                                                                    </Authorizer>
                                                                                    <Authorizer permId={PERMISSIONS.VIRTUAL_NOTIFY} >
                                                                                        <span data-pr-tooltip="Notify" data-pr-position="bottom" className={`ma-ml10 ma-pointer yhn${rowIndex}`}> <CalendarIcon height={20} width={20} /></span>
                                                                                        <Tooltip className="table-li-tooltip2" autoHide={false} target={`.yhn${rowIndex}`} />
                                                                                    </Authorizer>
                                                                                    <Authorizer permId={PERMISSIONS.VIRTUAL_DELETE} >
                                                                                        <span data-pr-tooltip="Delete" data-pr-position="bottom" className={`ma-ml10 ma-pointer edc${rowIndex}`} onClick={() => { this.deleteMeeting(rowData) }}><DeleteIcon height={20} width={20} /></span>
                                                                                        <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edc${rowIndex}`} />
                                                                                    </Authorizer>
                                                                                </>
                                                                            }
                                                                            {
                                                                                this.state.filter == 'completed' && <>
                                                                                    <Authorizer permId={PERMISSIONS.VIRTUAL_SHOW_ATTENDEES} >
                                                                                        <span data-pr-tooltip="Show Attendees" data-pr-position="bottom" className={`ma-ml10 ma-pointer ewr${rowIndex}`} onClick={() => { this.showAtttendees(rowData._id) }}><AttachmentIcon height={20} width={20} /></span>
                                                                                        <Tooltip className="table-li-tooltip2" autoHide={false} target={`.ewr${rowIndex}`} />
                                                                                    </Authorizer>
                                                                                    <Authorizer permId={PERMISSIONS.VIRTUAL_WATCH_RECORDINGS} >
                                                                                        <span data-pr-tooltip="Play recordings" data-pr-position="bottom" className={`ma-ml10 ma-pointer eer${rowIndex}`}><PlayIcon height={20} width={20} /></span>
                                                                                        <Tooltip className="table-li-tooltip2" autoHide={false} target={`.eer${rowIndex}`} />
                                                                                    </Authorizer>
                                                                                    <Authorizer permId={PERMISSIONS.VIRTUAL_UPLOAD_RESOURCES} >
                                                                                        <span data-pr-tooltip="Upload Resources" data-pr-position="bottom" className={`ma-ml10 ma-pointer tyu${rowIndex}`} onClick={() => { this.showResourceUpload(rowData) }} style={{ position: 'relative', top: '11px' }}><CloudIcon height={36} width={30} /></span>
                                                                                        <Tooltip className="table-li-tooltip2" autoHide={false} target={`.tyu${rowIndex}`} />
                                                                                    </Authorizer>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <span className={`ma-ml20 tgb${rowIndex} ma-pointer`} style={{ float: 'right' }} data-pr-tooltip="Show Class Details" data-pr-position="bottom" onClick={() => {
                                                                            if (selectedIndex == rowIndex) {
                                                                                this.setState((prevState) => {
                                                                                    return {
                                                                                        selectedIndex: null
                                                                                    };
                                                                                });
                                                                            } else {
                                                                                this.setState((prevState) => {
                                                                                    return {
                                                                                        selectedIndex: rowIndex
                                                                                    };
                                                                                });
                                                                            }
                                                                        }}><i style={{ fontSize: '16px' }} className={`pi ${selectedIndex != rowIndex ? 'pi-chevron-down' : 'pi-chevron-up'} `}></i></span>
                                                                    </td>
                                                                </tr>
                                                                {rowIndex == selectedIndex && <tr >
                                                                    <td colSpan={7}>
                                                                        <div className='p-grid class-more-details'>
                                                                            <div className="p-col-12 p-md-6">
                                                                                <p>  <span className='cmd-label'>Teacher: </span>
                                                                                    <span className='cmd-value'>{rowData.teacherName}</span>
                                                                                </p>
                                                                                <p>  <span className='cmd-label'>Board: </span>
                                                                                    <span className='cmd-value'>{rowData.boardName}</span>
                                                                                </p>
                                                                                <p>  <span className='cmd-label'>Class: </span>
                                                                                    <span className='cmd-value'>{rowData.className}</span>
                                                                                </p>
                                                                            </div>
                                                                            <div className="p-col-12 p-md-6">
                                                                                <p><span className='cmd-label'>Class Resources: </span>
                                                                                    {(rowData.attachments && rowData.attachments.length) || (rowData.resources && rowData.resources.length) ? <>
                                                                                        <AttachmentWithIcons noPreview={true} attachments={rowData.attachments} noRemove={true} />
                                                                                        {
                                                                                            rowData.resources && rowData.resources.length ? <>
                                                                                                {
                                                                                                    rowData.resources.map(a => <p><a className='cls-resource-link' href={a.url} target="_blank" >{a.url}</a></p>)
                                                                                                }
                                                                                            </> : <></>
                                                                                        }
                                                                                    </> : <p>No Resources</p>}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                }
                                                            </>
                                                        })
                                                    }
                                                </tbody>

                                            </table>
                                        </div>
                                        <Paginator
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                            first={lazyParams.first} rows={lazyParams.rows} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 30]} onPageChange={this.onPage

                                                // (e) => {
                                                //     console.log(e);
                                                //     this.setState({
                                                //         paginator: e
                                                //     })
                                                // }
                                            }
                                            alwaysShow={true}
                                            leftContent={<p className='avail-q'> Available Classes:  <span>{this.state.totalRecords}</span> </p>}
                                        ></Paginator>
                                    </div>
                                </div >

                            </div>
                            <Dialog
                                dismissableMask={false}
                                visible={this.state.isShowConfirmDelete}
                                blockScroll={true}
                                footer={() => {
                                    return (<div className='ma-mt20'>
                                        <Button label="Cancel" className='p-button-outlined' onClick={() => {
                                            this.setState({
                                                isShowConfirmDelete: false,
                                                selectedClassId: null
                                            })
                                        }} />
                                        <Button label="Delete" className='' onClick={this.onConfirmDelete} />
                                    </div>)
                                }}
                                draggable={false}
                                closeOnEscape={true}
                                closable={false}
                                onHide={() => { this.setState({ isShowConfirmDelete: false }) }}>
                                <div className='attention-dialog'>
                                    <div className='ma-center'>
                                        <WarningIcon width={"50"} height={"50"} />
                                    </div>
                                    <p className='title-s1'>Attention!</p>
                                    <p className='desc-s1'>
                                        You are about Delete this Class.
                                    </p>
                                </div>
                            </Dialog>
                            {
                                this.state.isShowUploadResource && <ResourceUpload onCancel={() => {
                                    this.setState({
                                        isShowUploadResource: false,
                                    })
                                }}
                                    uploadFiles={this.uploadFiles}
                                    resources={(editVirtualClass && editVirtualClass.attachments) || []}
                                />
                            }
                        </>
                    }
                </Navbar>
                {
                    (this.state.isLoading || this.props.isLoading) && <LoadingComponent />
                }
                {
                    this.state.isShowAttendees && <>
                        <AttandeesDetails virtualClassId={this.state.virtualClassId} onHide={() => {
                            this.setState({
                                isShowAttendees: false
                            })
                        }} />
                    </>
                }
                {
                    this.state.isShowParticipants && <>
                        <ParticipantsDetails virtualClass={this.state.virtualClass} onHide={() => {
                            this.setState({
                                isShowParticipants: false,
                                virtualClass: null
                            })
                        }} />
                    </>
                }
                {
                    this.state.isShowClassDetails && <ClassDetails onHide={() => {
                        this.setState({
                            isShowClassDetails: false,
                            editClassData: null
                        })
                    }} classDetails={this.state.editVirtualClass} />
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div >
        )
    }
}




const mapStateToProps = (state) => ({
    loading: state.curriculumData && state.curriculumData.loading,
    error: state.curriculumData && state.curriculumData.error && state.curriculumData.error,
    errorInfo: state.curriculumData && state.curriculumData.errorInfo && state.curriculumData.errorInfo,
    subjectData:
        state.curriculumData &&
        state.curriculumData.response &&
        state.curriculumData.response.Item &&
        state.curriculumData.response.Item.subjects &&
        state.curriculumData.response.Item.subjects,
    offlineExportStatus: state.curriculumData &&
        state.curriculumData.response &&
        state.curriculumData.response.Item &&
        state.curriculumData.response.Item.offlineExportStatus &&
        state.curriculumData.response.Item.offlineExportStatus,
    organizationLoading: state.organizationData && state.organizationData.loading,
    organizationError: state.organizationData && state.organizationData.error,
    organizationErrorInfo: state.organizationData && state.organizationData.errorInfo,
    organizationData: state.organizationData && state.organizationData.response && state.organizationData.response,
    boardsInfo:
        state.organizationData &&
        state.organizationData.response &&
        state.organizationData.response.Item &&
        state.organizationData.response.Item,
    selectedOrganizationDataIds: state.selectedOrganizationDataIds && state.selectedOrganizationDataIds,
    updateSubjectResponse: state.updateSubject && state.updateSubject.response && state.updateSubject.response,
    subjectsUpdateError: state.updateSubject && state.updateSubject.error && state.updateSubject.error,
    updateSubjectLoading: state.updateSubject && state.updateSubject.loading && state.updateSubject.loading,
    getAllImagesUrlInfo: state.getImageUrlInfo && state.getImageUrlInfo.allImageUrls,
    boards: userAssignedBoards(state, 'activeBoards'),

    isLoading: state.boardsData.loading,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});


export default connect(mapStateToProps, {
    getCurriculum,
    getActiveOrganizationData,
    selectBoard,
    dispatchClearCurriculumData,
    selectClass,
    selectGroup,
    updateSubject,
    getSubject,
    clearSelectedOrganizationData,
    startExportToOffline,
    getImageUrl,
    getBoardsData
})(Authentication(withRouter(VirtualClass)));
