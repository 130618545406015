import * as ActionType from "../actionTypes";

export default (state = {}, action) => {
  switch(action.type){
    case ActionType.REQUEST_GET_TEMPLATE_DETAILS:

        return{
            ...state,
            loading:true,
            errorInfo:null,
            error:false
            
            }
    case ActionType.RECEIVED_GET_TEMPLATE_DETAILS :
        return{
            ...state,
            loading:false,
            errorInfo:null,
            error:false,
            response:action.res

        }
    case ActionType.ERROR_GET_TEMPLATE_DETAILS :
        return{
            ...state,
            loading:false,
            errorInfo:action.err,
            error:true
        }
    case ActionType.CLEAR_GET_TEMPLATE_DETAILS :
        return{
        }
    default : 
      return state    

  }
}