export const questionType = [
  {
    label: "SCCT",
    value: "SCCT",
  },
  {
    label: "MCCT",
    value: "MCCT",
  },
  {
    label: "FB",
    value: "FB",
  },

  {
    label: "MATCHING",
    value: "MATCHING",
  },
  {
    label: "NUM",
    value: "NUM",
  },
];

export const difficulty = [
  {
    label: "Easy",
    value: "Easy",
  },
  {
    label: "Medium",
    value: "Medium",
  },
  {
    label: "Hard",
    value: "Hard",
  },
];

export const skillType = [
  {
    label: "Critical Thinking",
    value: "Critical Thinking",
  },
  {
    label: "Problem Solving",
    value: "Problem Solving",
  },
  {
    label: "Analytical Thinking",
    value: "Analytical Thinking",
  },
  {
    label: "Conceptual Clarity",
    value: "Conceptual Clarity",
  },
  {
    label: "Knowledge",
    value: "Knowledge",
  },
  {
    label: "Observation",
    value: "Observation",
  },
  {
    label: "Understanding",
    value: "Understanding",
  },
];

export const status = [
  {
    label: "In Review",
    value: "In Review",
  },
  {
    label: "CREATED",
    value: "CREATED",
  },
];

export const tags = [
  {
    label: "Tag Sample1",
    value: "Tag Sample1",
  },
  {
    label: "Tag Sample2",
    value: "Tag Sample2",
  },
  {
    label: "Tag Sample3",
    value: "Tag Sample3",
  },
  {
    label: "Tag Sample4",
    value: "Tag Sample4",
  },
];

export const examType = [
  {
    value: "JEE Advanced",
    label: "JEE Advanced",
  },
  {
    value: "JEE Mains",
    label: "JEE Mains",
  },
  {
    value: "NEET",
    label: "NEET",
  },
  {
    value: "EAMCET(ENGG)",
    label: "EAMCET(ENGG)",
  },
  {
    value: "EAMCET(MEDIC)",
    label: "EAMCET(MEDIC)",
  },
  {
    value: "AFMC",
    label: "AFMC",
  },
  {
    value: "JIPMER",
    label: "JIPMER",
  },
];
export const source = [
  {
    value: "Meluha",
    label: "Meluha",
  },
  {
    value: "Gayathri",
    label: "Gayathri",
  },
  {
    value: "Engineering Physics, Malik and Singh, tata Mc Graw Hill",
    label: "Engineering Physics, Malik and Singh, tata Mc Graw Hill",
  },
  {
    value: "Engineering Physics, Naidu, Pearson",
    label: "Engineering Physics, Naidu, Pearson",
  },
  {
    value: "Mechanics, Mathur, S.Chand Publishing",
    label: "Mechanics, Mathur, S.Chand Publishing",
  },
  {
    value: "Classical Mechanics, Upadhyaya, Himalaya Publishing House",
    label: "Classical Mechanics, Upadhyaya, Himalaya Publishing House",
  },
  {
    value: "Classical Mechanics, G.Aruldhas, PHI",
    label: "Classical Mechanics, G.Aruldhas, PHI",
  },
  {
    value:
      "Engineering Chemistry, Satyaprakesh & Manisha Agrawal, khanna Book Publishing, Delhi",
    label:
      "Engineering Chemistry, Satyaprakesh & Manisha Agrawal, khanna Book Publishing, Delhi",
  },
  {
    value:
      "A Text Book Of Engg. Chemistry, Sashi Chawala, Dhanapat Rai & Co.(p)Ltd",
    label:
      "A Text Book Of Engg. Chemistry, Sashi Chawala, Dhanapat Rai & Co.(p)Ltd",
  },
  {
    value: "Essentials of Physical Chemistry, Bahi & Tuli, S.Chand publishing",
    label: "Essentials of Physical Chemistry, Bahi & Tuli, S.Chand publishing",
  },
  {
    value: "Applied Chemistry, Sunita Rattan, Kataria",
    label: "Applied Chemistry, Sunita Rattan, Kataria",
  },
  {
    value: "Engineering Chemistry, Baskar, Wiley",
    label: "Engineering Chemistry, Baskar, Wiley",
  },
  {
    value:
      "Advanced Engineering Mathematics, Chandrika Prasad & Reena Garg, Khanna Book Publishing Co.(p)Ltd., Delhi (ISBN:9789386173522)",
    label:
      "Advanced Engineering Mathematics, Chandrika Prasad & Reena Garg, Khanna Book Publishing Co.(p)Ltd., Delhi (ISBN:9789386173522)",
  },
  {
    value:
      "Engineering Mathematics for first year, veeraranjan T., Tata McGraw-Hill",
    label:
      "Engineering Mathematics for first year, veeraranjan T., Tata McGraw-Hill",
  },
  {
    value: "Higher Engineering Mathematics, Ramana B.V., Tata Mc Graw",
    label: "Higher Engineering Mathematics, Ramana B.V., Tata Mc Graw",
  },
  {
    value:
      "Advanced Engineering Mathematics,Chandrika Prasad & Reena Garg, Khanna Book Publishing",
    label:
      "Advanced Engineering Mathematics,Chandrika Prasad & Reena Garg, Khanna Book Publishing",
  },
];

export const questionTypeForAdd = [
  {
    value: "SCCT",
    label: "SCCT",
  },
  {
    value: "MCCT",
    label: "MCCT",
  },
  {
    value: "FB",
    label: "FB",
  },
  {
    value: "DESC",
    label: "DESC",
  },
  {
    value: "PSG",
    label: "PSG",
  },
  {
    value: "NUM",
    label: "NUM",
  },
];

export const primaryColor = "#6610f2";

//export const baseUrlAdmin = "https://m9weqzdg2f.execute-api.ap-south-1.amazonaws.com/dev/v1/data";
//export const baseUrlUser = 'https://4op3rd7fsi.execute-api.ap-south-1.amazonaws.com/dev/v1/data';
// export const baseUrlForBulkUpload = 'https://m9weqzdg2f.execute-api.ap-south-1.amazonaws.com/dev';
// https://871511fe188a.ngrok.io
// https://m9weqzdg2f.execute-api.ap-south-1.amazonaws.com/dev/v1/data
// https://4op3rd7fsi.execute-api.ap-south-1.amazonaws.com/dev/v1/data   user
// export const baseUrlAdmin = "https://ev87vcdpg4.execute-api.ap-south-1.amazonaws.com/prod/v1/data";
// export const baseUrlUser = 'https://lvt49qxmvh.execute-api.ap-south-1.amazonaws.com/prod/v1/data';

export const baseUrlAdmin =
  process.env &&
  process.env.REACT_APP_API_BASE_URL_ADMIN &&
  process.env.REACT_APP_API_BASE_URL_ADMIN;
export const baseUrlUser =
  process.env &&
  process.env.REACT_APP_API_BASE_URL_USER &&
  process.env.REACT_APP_API_BASE_URL_USER;
export const baseUrlForLongRunningTask =
  process.env &&
  process.env.REACT_APP_API_BASE_URL_FOR_LONG_RUNNING_TASK &&
  process.env.REACT_APP_API_BASE_URL_FOR_LONG_RUNNING_TASK;

export const discussionBoardEndpoint =
  process.env &&
  process.env.REACT_APP_DISCUSSION_BOARD_ENDPOINT &&
  process.env.REACT_APP_DISCUSSION_BOARD_ENDPOINT;

export const askADoubtEndpoint =
  process.env &&
  process.env.REACT_APP_ASK_A_DOUBT_ENDPOINT &&
  process.env.REACT_APP_ASK_A_DOUBT_ENDPOINT;
export const askADoubtSocketEndpoint =
  process.env &&
  process.env.REACT_APP_SOCKET_API_ENDPOINT &&
  process.env.REACT_APP_SOCKET_API_ENDPOINT;
export const ticketMangementEndpoint =
  process.env &&
  process.env.REACT_APP_TICKET_MANAGEMENT_ENDPOINT &&
  process.env.REACT_APP_TICKET_MANAGEMENT_ENDPOINT;

export const Reat_app_title =
  process.env && process.env.REACT_APP_TITLE && process.env.REACT_APP_TITLE;

export const getSoketEndPoint = () => {
  let STAGE = process.env && process.env.REACT_APP_STAGE;

  switch (STAGE) {
    case "sasdev":
      return "wss://m4fonw7jkk.execute-api.ap-south-1.amazonaws.com/production";
    case "sasprod":
      return "wss://9n5znix7dk.execute-api.ap-south-1.amazonaws.com/production";
    default:
      return "wss://m4fonw7jkk.execute-api.ap-south-1.amazonaws.com/production";
  }
};


export const getTicketSoketEndPoint = () => {
  let STAGE = process.env && process.env.REACT_APP_STAGE
  switch (STAGE) {
      case 'sasdev':
          return "wss://7vnp0a4lr7.execute-api.ap-south-1.amazonaws.com/dev";
      case 'sasprod':
          return "wss://7vnp0a4lr7.execute-api.ap-south-1.amazonaws.com/dev";
      default:
          return "wss://7vnp0a4lr7.execute-api.ap-south-1.amazonaws.com/dev";
  }

}