import * as ActionTypes from '../actionTypes';
import axios from 'axios';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../apiConstants';

const createAdminUserRequested = () => ({ type: ActionTypes.ADD_ADMIN_USER_REQUESTED });
const createAdminUserReqSucceeded = (data) => ({ type: ActionTypes.ADD_ADMIN_USER_SUCCEEDED, data });
const createAdminUserRequestFailed = () => ({ type: ActionTypes.ADD_ADMIN_USER_FAILED });

const updateAdminUserRequested = () => ({ type: ActionTypes.UPDATE_ADMIN_USER_REQUESTED });
const updateAdminUserReqSucceeded = (data) => ({ type: ActionTypes.UPDATE_ADMIN_USER_SUCCEEDED, data });
const updateAdminUserRequestFailed = () => ({ type: ActionTypes.UPDATE_ADMIN_USER_FAILED });

const dispatchErrorCreateNewAdminUser = (err) => ({
  type: ActionTypes.ERROR_CREATE_NEW_ADMIN_USER,
  err
});

const dispatchErrorUpdateAdminUser = (err) => ({
  type: ActionTypes.ERROR_UPDATE_ADMIN_USER,
  err
});

const dispatchRequestSaveBulkAdmins = () => ({
  type: ActionTypes.REQUEST_SAVE_BULK_ADMINS
}); 

const dispatchReceivedSaveBulkAdmins = (res) => ({
  type: ActionTypes.RECEIVED_SAVE_BULK_ADMINS,
  res
});

const dispatchErrorSaveBulkAdmins= err => ({
  type: ActionTypes.ERROR_SAVE_BULK_ADMINS,
  err
});

const url = `${baseUrlAdmin}/admin/addAdminUser`;
const createAdminUser = (data) => {
  return axios({
    method: 'POST',
    url: url,
    data: data
  });
};

const dispatchRequestBulkUploadTransactions = () => ({
  type: ActionTypes.REQUEST_BULK_UPLOAD_TRANSACTIONS_ADMINS
});
const dispatchReceivedBulkUploadTransactions = (res) => ({
  type: ActionTypes.RECEIVED_BULK_UPLOAD_TRANSACTIONS_ADMINS,
  res
});
const dispatchErrorBulkUploadTransactions = err => ({
  type: ActionTypes.ERROR_BULK_UPLOAD_TRANSACTIONS_ADMINS,
  err
});
const dispatchClearBulkUserUploadData = () => ({
  type: ActionTypes.CLEAR_BULK_ADMINS_TRANSACTION_DATA,

});

const dispatchRequestBulkUploadTransactionDetails = () => ({
  type: ActionTypes.REQUEST_BULK_UPLOAD_TRANSACTION_DETAILS_ADMINS
});

const dispatchReceivedBulkUploadTransactionDetails = (res) => ({
  type: ActionTypes.RECEIVED_BULK_UPLOAD_TRANSACTION_DETAILS_ADMINS,
  res
});

const dispatchErrorBulkUploadTransactionDetails = err => ({
  type: ActionTypes.ERROR_BULK_UPLOAD_TRANSACTION_DETAILS_ADMINS,
  err
});

export const createAdminUserAction = (data) => (dispatch) => {
  dispatch(createAdminUserRequested());
  createAdminUser(data)
    .then((res) => {
      if (res.data) {
        dispatch(createAdminUserReqSucceeded(res.data));
      } else {
        dispatch(createAdminUserRequestFailed());
      }
    })
    .catch((e) => {
      dispatch(dispatchErrorCreateNewAdminUser(e));
      dispatch(createAdminUserRequestFailed());
    });
};

export const updateAdminUser = (data) => (dispatch) => {
  const url = `${baseUrlAdmin}/admin/updateAdminUser`;
  dispatch(updateAdminUserRequested());
  axios({
    method: 'PUT',
    url: url,
    data: data,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
  }
  })
    .then((res) => {
      if (res.status === 200 || res.message === "success") {
        dispatch(updateAdminUserReqSucceeded(res.data));
      } else {
        dispatch(updateAdminUserRequestFailed());
      }
    })
    .catch((e) => {
      if (e.response && e.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      } else {
        dispatch(dispatchErrorUpdateAdminUser(e));
        dispatch(updateAdminUserRequestFailed());
      }
    });
};

export const saveBulkUploadAdmins = (data) => dispatch => {
  dispatch(dispatchRequestSaveBulkAdmins());
    axios({
        method: 'POST',
        url: `${baseUrlForLongRunningTask}/admin/bulk/adminupload`,
        data: data,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        }
    }).then(({ data }) => {
        dispatch(dispatchReceivedSaveBulkAdmins(data));
    }).catch(e => {
        if (e.response && e.response.status == 401) {
            localStorage.clear();
            window.location.reload();
        } else {
            dispatch(dispatchErrorSaveBulkAdmins(e));
        }
    });
}

export const getBulkUploadTransactionsAdmins = (data, filter) => dispatch => {
  dispatch(dispatchRequestBulkUploadTransactions());
  const userId = data.userId ? data.userId : null;
  const url = `${baseUrlAdmin}/admin/allbulk/admindata/${userId}?limit=${filter.rows}&page=${filter.page + 1}&search=${filter.globalSearch}`;
  axios({
      method: 'get',
      url: url,
      headers: {
          'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
      }
  }).then(({ data }) => {
      dispatch(dispatchReceivedBulkUploadTransactions(data));
  }).catch(e => {
      if (e.response && e.response.status == 401) {
          localStorage.clear();
          window.location.reload();
      } else {
          dispatch(dispatchErrorBulkUploadTransactions(e));
      }
  });
}

export const getBulkUploadTransactionDetailsAdmins = (data) => dispatch => {
  dispatch(dispatchRequestBulkUploadTransactionDetails());
  const { userId, transactionId } = data;
  axios({
      method: 'get',
      url: `${baseUrlAdmin}/admin/${userId}/view/transcations/${transactionId}`,
      headers: {
          'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
      }
  }).then(({ data }) => {
      dispatch(dispatchReceivedBulkUploadTransactionDetails(data));
  }).catch(e => {
      if (e.response && e.response.status == 401) {
          localStorage.clear();
          window.location.reload();
      } else {
          dispatch(dispatchErrorBulkUploadTransactionDetails(e));
      }
  });
}

export const clearBulkUserUploadDataAdmins = () => dispatch => {
  dispatch(dispatchClearBulkUserUploadData());
}