import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'primereact/button';
import { baseUrlAdmin } from './../../../store/apiConstants';
import { MELUHA_BOARD_ID } from './../../../store/actionTypes';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { getFormFields, isFormValid, onTextChange, isFieldValid, isValidMobile, onNumberChange, onDropDownChange, onChoiceChange, onEmailChange, isValidEmail, isValidUrl } from '../../../utile/formHelper';
import _ from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import { connect } from 'react-redux';
import {
    getActiveOrganizationData,
    getBranchesLatest
} from '../../../store/actions';

import InputTextB from './../../customComponents/inputTextB';

class AddSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAssigned: props.data ? true : false,
            errorMsg: '',
            sectionId: props.data && props.data.sectionId ? props.data.sectionId : '',
            section: props.data && props.data.section ? props.data.section : '',
            sectionsData: {}

        }
    }

    checkIsAssigned = (sectionId) => {
        let isAssigned = false;
        this.props.assignedSections.forEach(g => {
            if (g.data && g.data.sectionId == sectionId) {
                isAssigned = true;
            }
        });
        return isAssigned
    }


    checkIsAssignedInOthers = (sectionId) => {
        let isAssigned = false;
        this.props.assignedSections.forEach(g => {
            if ((g.data && g.data.sectionId == sectionId) && g.id != this.props.id) {
                isAssigned = true;
            }
        });
        return isAssigned
    }



    assignCurriculum = () => {
        const { sectionId, section
        } = this.state;

        if (sectionId != '' && section != '') {
            this.setState({
                isAssigned: true
            }, () => {
                this.props.onAssignClick(
                    {
                        id: this.props.id,
                        data: {
                            sectionId,
                            section
                        },
                        assigned: true
                    });
            })
        } else {
            this.setState({
                errorMsg: 'select section'
            });

            setTimeout(() => {
                this.setState({
                    errorMsg: ''
                });
            }, 3000);
        }
    }


    onEditClick = () => {
        if (this.props.assignedSections.every((s) => s.assigned === true)) {

            let items = this.state.lastNodeItems.filter(a => !(this.checkIsAssignedInOthers(a.key)))
            let newSectionData = {
                ...this.state.sectionsData,
                ['level' + (this.props.branchLevels.length)]: items,
            }

            this.setState({
                isAssigned: false,
                sectionsData: newSectionData
            }, () => {
                this.props.onAssignClick(
                    {
                        id: this.props.id,
                        assigned: false
                    });
            });
        } else {
            this.toast.show({ severity: 'warn', summary: 'Warning', detail: 'Please add or remove which is in edit state.', life: 3000 });
        }




    }

    componentDidMount() {
        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            if (this.props.data && this.props.data) {
                let finaldata = this.getSectionsData(this.props.branchData, this.state.sectionId, this.props.branchLevels.length);
                let lastNodeItems = finaldata['level' + this.props.branchLevels.length];

                this.setState({
                    sectionsData: finaldata,
                    lastNodeItems
                });
            } else {
                let sectionsData = {
                    level1: this.props.branchData.filter((a) => a.level == 1)
                }
                // let lastNodeItems = this.props.branchData.filter(a => a.level == this.props.branchLevels.length)

                this.setState({
                    sectionsData,
                    //     lastNodeItems
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.branchData !== this.props.branchData) {
            if (this.props.data && this.props.data) {
                let finaldata = this.getSectionsData(this.props.branchData, this.state.sectionId, this.props.branchLevels.length);

                let lastNodeItems = finaldata['level' + this.props.branchLevels.length];

                this.setState({
                    sectionsData: finaldata,
                    lastNodeItems
                });

            } else {
                let sectionsData = {
                    level1: this.props.branchData.filter((a) => a.level == 1)
                }

                //let lastNodeItems = this.props.branchData.filter(a => a.level == this.props.branchLevels.length)

                this.setState({
                    sectionsData,
                    //  lastNodeItems
                });
            }
        }
        // if (prevProps.branchData !== this.props.branchData) {
        //     let sectionsData = {
        //         level1: this.props.branchData.filter((a) => a.level == 1)
        //     }
        //     this.setState({
        //         sectionsData
        //     });
        // }
    }


    getSelectedLevelData(data, key) {
        let items = [];
        let selectedNode = data.find(a => a.key == key);
        if (selectedNode && selectedNode.children) {
            selectedNode.children.forEach((c) => {
                items.push(data.find(a => a.key == c))
            })

        }
        return { items, selectedNode };
    }

    getSectionsData = (nodes, key, totalLevels) => {
        let data = _.cloneDeep(nodes)
        let sectionsData = {};
        let selectedKey = key;
        for (let k = totalLevels; k > 1; k--) {
            for (let j = 0; j < data.length; j++) {
                const i = data[j];
                if (i.children) {
                    if (i.children.includes(selectedKey)) {
                        sectionsData['level' + k] = i.children;
                        sectionsData = {
                            ...sectionsData,
                            ['level' + k]: i.children.map(c => data.find(d => d.key == c)),
                            ['levelSelected' + k]: selectedKey
                        }
                        selectedKey = _.cloneDeep(i.key)
                        break;
                    }
                }
            }
        }

        sectionsData = {
            ...sectionsData,
            ['level1']: data.filter(nod => nod.level == 1),
            ['levelSelected1']: selectedKey
        }
        return sectionsData
    }




    onChangeSection = (key, level) => {
        let { sectionsData } = this.state;
        let data = this.getSelectedLevelData(this.props.branchData, key)
        let emptyBeforeLevels = {};
        for (let i = (level + 1); i <= this.props.branchLevels.length; i++) {
            emptyBeforeLevels = {
                ...emptyBeforeLevels,
                ['level' + i]: [],
                ['levelSelected' + i]: null
            }
        }

        data.items = data.items.filter((a => !(this.checkIsAssigned(a.key))))
        let newSectionData = {
            ...sectionsData,
            ...emptyBeforeLevels,
            ['level' + (level + 1)]: data.items,
            ['levelSelected' + (level)]: key
        }
        if (!data.selectedNode.children) {
            this.setState((prevState) => {
                return {
                    sectionId: data.selectedNode.key,
                    section: data.selectedNode.name,
                    sectionsData: newSectionData,

                };
            });
        } else {
            this.setState({
                sectionId: '',
                section: '',
                sectionsData: newSectionData,
                lastNodeItems: data.items
            });
        }
    };

    render() {
        // console.log('props-1', this.props)
        // console.log('state-1', this.state)
        return (
            <div className='p-grid' >
                <div className='p-col-12 p-md-12'>
                    {/* <InputTextB info='Enter the name of the organization registered as per the ROC' id="cu10"> */}
                    <div className='p-grid ma-w100p' >
                        {
                            this.props.branchLevels && this.props.branchLevels.map((level, index) => {
                                return <div className='p-col-12 p-md-6 p-md-2-5 p-mb-1'>
                                    <Dropdown
                                        value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                        className='ma-w100p '
                                        //    disabled={this.state.hideUpdateButton}
                                        disabled={this.state.isAssigned}
                                        optionLabel={"name"}
                                        optionValue={"key"}
                                        options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}

                                        onChange={(e) => this.onChangeSection(e.value, index + 1)}
                                        placeholder="--Select--" />
                                </div>

                            })
                        }

                        <div className='p-col-12 p-md-6 p-md-2-5 p-mb-1'>
                            {this.state.isAssigned ? <Button
                                label={`Edit`}
                                onClick={this.onEditClick}
                            />

                                : <Button
                                    label={`Assign`}
                                    onClick={this.assignCurriculum}
                                />
                            }

                            <span className="ma-ml10 ma-pointer" onClick={() => { this.props.removeTempSubject(this.props.id) }}>
                                <i className="pi pi-times"></i>
                            </span>
                        </div>
                    </div>

                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                    {/* </InputTextB> */}
                    <p style={{ color: 'var(--required)', fontSize: '14px' }}>{this.state.errorMsg}</p>
                </div>
            </div>
        )
    }
}




const mapStateToProps = state => {
    return {
        boardsInfo:
            state.organizationData &&
            state.organizationData.response &&
            state.organizationData.response.Item &&
            state.organizationData.response.Item,
        isBranchLoading: state.branchDataLatest.isLoading,
        branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
        branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
    }
}


export default connect(mapStateToProps, {
    getActiveOrganizationData,
    getBranchesLatest
})(withRouter(AddSection));