import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Service from '../../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { getFormFields, isFormValid } from '../../../utile/formHelper';
import uuidv4 from 'uuid/v4';
import { trimObj } from '../../../utile';
import InputTextB from './../../customComponents/inputTextB';
import { FIELDS_INFO } from '../../../constants';
import './styles.scss'

const formFields = [
    // {
    //     "Type": "Text",
    //     "Label": "Topic Number",
    //     "FieldName": "topicNumber",
    //     "Required": true
    // },
    {
        "Type": "Text",
        "Label": "Topic Name",
        "FieldName": "topicName",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Topic Code",
        "FieldName": "topicCode",
        "Required": true
    },
    {
        "Type": "Toggle",
        "Label": "Active Status",
        "FieldName": "isTopicActivity",
        "Required": false
    },
    {
        "Type": "Text",
        "Label": "Priority",
        "FieldName": "priority",
        "Required": true
    }


    

]

class CreateTopic extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, this.props.editTopicData || { topicId: uuidv4() });
        this.state = {
            topic: this.formFields.data,
            formValidations: this.formFields.formValidations
        }
    }

    footer = () => {
        return (<>
            <Button label="Cancel" className='p-button-outlined' onClick={this.onCloseDialog} />
            <Button label={`${this.props.isAdd ? 'Add' : 'Update'}`} className='ma-m-lr10' onClick={this.onSubmitClick} />

        </>)
    }

    onSubmitClick = () => {
        const formStatus = isFormValid(formFields, this.formFields.formValidations, trimObj(this.state.topic));
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {
            this.props.addTopic(trimObj(this.state.topic));
            // this.setState({
            //     formValidations: formStatus.formValidations,
            // });
        }
    }

    onCloseDialog = () => {
        this.props.hideCreateDialog();
        // this.setState({
        //     showCreateDialog: false,
        //     topic: {
        //         topicName: '',
        //         topicCode: '',
        //         isTopicActivity: false
        //     }
        // }, () => { })
    }

    onTextChange = (e, field) => {
        let data = JSON.parse(JSON.stringify(this.state.topic));
        let formValidations = this.state.formValidations;
        data[field] = e.target.value;
        let fieldInfo = formFields.filter((f) => f.FieldName == field)
        if (formValidations.fields[field].isRequired) {
            if (data[field] == '' || data[field] == null) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
        }

        this.setState({
            topic: data,
            formValidations
        });
    }
    onToggleChange = (e, field) => {
        let data = JSON.parse(JSON.stringify(this.state.topic));
        let formValidations = this.state.formValidations;
        data[field] = e.value;
        let fieldInfo = formFields.filter((f) => f.FieldName == field)
        if (formValidations.fields[field].isRequired) {
            if (!data[field]) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
        }
        this.setState({
            topic: data,
            formValidations
        });
    }

    render() {
        debugger
        const { topic, formValidations } = this.state;
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >Topics</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={(e) => { }} />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <li><i data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
            </div>
        );

        return (
            <Dialog header={`${this.props.isAdd ? 'Add' : 'Edit'} Topic`}
                draggable={false} footer={this.footer}
                closeOnEscape={true}
                dismissableMask={true}
                blockScroll={true}
                visible={true}
                closable={false}
                onHide={() => this.setState({ showCreateDialog: false })}>
                <div className="p-grid subject-add-dialog">
                    <div className="p-col-4 p-md-4 p-lg-4">
                        <p className='text-label'>Priority <span className='ma-required'>*</span></p>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                        <InputTextB info={FIELDS_INFO.TOPIC_PRIORITY} id="prioritycn">
                            <InputText value={topic.priority} onChange={(e) => { this.onTextChange(e, 'priority') }} className='p-inputtext-style1 ' />
                        </InputTextB>
                        {formValidations && !formValidations.fields['priority'].isValid && <p className="p-error">{formValidations.fields['priority'].errorMsg}</p>} 
                    </div>
                </div>


                <div className="p-grid subject-add-dialog">
                    <div className="p-col-4 p-md-4 p-lg-4">
                        <p className='text-label'>Topic Name <span className='ma-required'>*</span></p>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                        <InputTextB info={FIELDS_INFO.TOPIC_NAME} id="topicn">
                            <InputText value={topic.topicName} onChange={(e) => { this.onTextChange(e, 'topicName') }} className='p-inputtext-style1 ' />
                        </InputTextB>
                        {formValidations && !formValidations.fields['topicName'].isValid && <p className="p-error">{formValidations.fields['topicName'].errorMsg}</p>}
                    </div>
                </div>

                
                <div className="p-grid subject-add-dialog">
                    <div className="p-col-4 p-md-4 p-lg-4">
                        <p className='text-label' >Topic Code <span className='ma-required'>*</span></p>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                        <InputTextB info={FIELDS_INFO.TOPIC_CODE} id="topicc">
                            <InputText value={topic.topicCode} onChange={(e) => { this.onTextChange(e, 'topicCode') }} className='p-inputtext-style1' />
                        </InputTextB>
                        {formValidations && !formValidations.fields['topicCode'].isValid && <p className="p-error">{formValidations.fields['topicCode'].errorMsg}</p>}
                    </div>
                </div>
                <div className="p-grid subject-add-dialog">
                    <div className="p-col-4 p-md-4 p-lg-4">
                        <p className='text-label'>Active Status</p>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                        <InputTextB info={FIELDS_INFO.TOPIC_STATUS} id="topics">
                            <InputSwitch checked={topic.isTopicActivity} onChange={(e) => { this.onToggleChange(e, 'isTopicActivity') }} />
                        </InputTextB>
                    </div>
                </div>
            </Dialog >
        )
    }
}




export default CreateTopic;