import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SvgViewer from '../customComponents/svgViewer';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Tooltip } from 'primereact/tooltip';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Navbar from './../navbar/index';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Menu } from 'primereact/menu';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import Service from '../../services';
import { Toast } from 'primereact/toast';
import CreatePlanner from './createPlanner';
import _ from 'lodash';
import './styles.scss'
import {  warningDailogInit } from '../../utile';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import moment from 'moment';
import { baseUrlAdmin } from '../../store/apiConstants';
import {

    getBoardsData,


    //getYearlyPlannerData,
} from '../../store/actions';
import Authentication from './../session';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import LoadingComponent from '../loadingComponent';
import { ApprovalIcon, DeleteIcon, DownloadIcon, EditIcon, WarningIcon } from '../svgIcons';
import AssignPlanner from './assignPlanner';

const items = [
    { label: 'Academics' },
    { label: 'Yearly Planner', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: '',
    sortField: 'createdAt',
    sortOrder: -1,
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'country.name': { value: '', matchMode: 'contains' },
        'company': { value: '', matchMode: 'contains' },
        'representative.name': { value: '', matchMode: 'contains' },
    }
}

class PlannerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearch: false,
            editPlannerData: null,
            globalFilter: null,
            isShowBoardCreate: false,
            warningDialog: warningDailogInit,
            warningDialogForDelete: warningDailogInit,
            columns: [{ columnName: 'status', isVisible: true, id: 'status' }],
            editData: {},
            isShowCreatePlanner: false,
            yearlyPlanners: [],
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            downloadPlannerData: []
        }
        this.service = new Service();
    }

    chaptersCountTemplate = (rowData) => {
        return (
            <p >{rowData.chapters.length}</p>
        );
    }

    statusTemplate = (rowData) => {
        return (
            <div className="ma-status">
                <span className="ma-status-text">{rowData.isActive ? 'Active' : 'InActive'} </span>
                <Authorizer permId={PERMISSIONS.EDIT_BOARD} >
                    <InputSwitch onChange={(e) => { this.changeStatus(e, rowData) }} checked={rowData.isActive} />
                </Authorizer>
            </div>
        );
    }

    changeStatus = (e, rowData) => {
        let data = { ...rowData }
        if (e.value == true) {
            data.isActive = true
        } else {
            data.isActive = false
        }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You are changing the Plan status ${e.value ? 'InActive' : 'Active'} to ${e.value ? 'Active' : 'InActive'}`
            },
            editPlannerData: data
        });

    }

    onConfirmChange = () => {
        this.setState({
            warningDialog: warningDailogInit,
        }, () => {
            this.updatePlanner()
        });

    }


    updatePlanner = () => {
        // this.setState({

        // })
        const url = `${baseUrlAdmin}/yearlyplanner`;

        this.setState({
            isLoading: true
        })
        this.service.put(url, this.state.editPlannerData, true).then((res) => {
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false
                }, () => {
                    this.onPlannerCreateorEditSuccess(true);
                })

            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }

        }).catch(e => {


            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })



    }





    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Yearly Planner</h2>
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }



    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }
    onIndexTemplate = (data, props) => {
        return props.rowIndex + 1;
    }
    handleDownload = (rowData) => {

        // console.log(rowData, 'ss');

        const url = `${baseUrlAdmin}/yearlyplanner/download/${rowData._id}`;

        this.setState({
            isLoading: true
        });

        this.service.get(url, true).then((res) => {

            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    downloadPlannerData: res.res.data
                }, () => {
                    //this.onPlannerCreateorEditSuccess(true);
                    this.dt2.exportCSV();
                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }

        }).catch(e => {


            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })





    }

    handleDelete = async (rowData) => {
        this.setState({
            warningDialogForDelete: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `Do you want to delete the ${rowData.title} planner.`
            },
            editPlannerData: rowData
        });
        // let { data } = await axios.delete(`${baseUrlAdmin}/yearlyplanner/${_id}`,
        //     {
        //         headers: {
        //             'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`,
        //         }
        //     });
        // console.log("deleted data", data)
        // // this.props.getYearlyPlannerData()
        // this.toast.show({ severity: 'success', summary: 'Delete Item', detail: "item deleted", life: 3000 });

    }

    deletePlanner = () => {
        // this.setState({

        // })
        let { editPlannerData } = this.state;
        const url = `${baseUrlAdmin}/yearlyplanner/${editPlannerData._id}`;

        this.setState({
            warningDialogForDelete: warningDailogInit,
            isLoading: true
        })
        this.service.delete(url, true).then((res) => {
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false
                }, () => {
                    this.onPlannerCreateorEditSuccess(true);
                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }

        }).catch(e => {


            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })



    }

    assignPlanner = (plannerToAssign) => {
        this.setState({
            isShowAssignDialog: true,
            plannerToAssign
        })

    }


    onAssignSuccess = () => {

        this.setState({
            isShowAssignDialog: false
        })
        this.toast.show({ severity: 'success', summary: 'Assigned', detail: 'Successfully assigned', life: 3000 });
    }


    cancelCreate = () => {
        this.setState({
            isShowCreatePlanner: false
        })
    }

    getPlanners = () => {
        this.setState({
            isLoading: true,
            yearlyPlanners: [],
            totalRecords: 0
        });
        let { lazyParams, globalSearch } = this.state;

        let url = `${baseUrlAdmin}/yearlyplanner/all?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.service.post(url, lazyParams.filters, true).then(res => {

            if (res && res.status && res.res.success) {
                this.setState((prevState) => {
                    return {
                        yearlyPlanners: res.res.data.yearlyPlanners,
                        totalRecords: res.res.data.totalRecords,
                        isLoading: false
                    };
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            console.log(e);
        })
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getPlanners);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getPlanners);
    }



    onSort = (event) => {


        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getPlanners);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.getPlanners);
        //  this.setState({ lazyParams: event }, this.getPlanners);
    }



    componentDidMount() {

        this.getPlanners();
    }


    onPlannerCreateorEditSuccess = (isEdit) => {
        this.setState({
            isShowCreatePlanner: false,
            editPlannerData: null,
            lazyParams: BasicLazyParams,
            globalSearch: ''
        }, () => {
            this.getPlanners()
        })
        this.toast.show({ severity: 'success', summary: 'Success', detail: `Planner ${isEdit ? 'edited' : 'created'} Successfully`, life: 3000 });
    }

    render() {
        const { board, formValidations } = this.state;
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">

                    <h2 className='ma-table-title'>Yearly Planners List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={this.onGlobalSearch} />}

                </div>

                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.YEARLY_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.YEARLY_FILTERS} >
                            <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.YEARLY_VIEW_COLUMNS} >
                            <li><i data-pr-tooltip="View Columns"
                                onClick={(event) => this.menu.toggle(event)}
                                data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.YEARLY_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        )

        return (<>
            <Navbar >

                <div className="p-grid ma-toolbar">
                    <div className="p-col-12 p-md-1 ma-no-p">
                        {(this.state.isShowCreatePlanner) && <>
                            <p className='back-arrow' onClick={() => {
                                this.setState({
                                    isShowCreatePlanner: false,
                                    editPlannerData: null
                                })
                            }}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                        </>
                        }
                    </div>
                    <div className="p-col-12 p-md-11 ma-no-pm">
                        <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>
                </div>
                <div className="ask-a-doubt ma-main question-bank">
                    {!this.state.isShowCreatePlanner ?
                        <>
                            <Toolbar className="ma-toolbard"
                                right={() => {
                                    return <>
                                        <Authorizer permId={PERMISSIONS.YEARLY_BULK_PLANNER_UPLOAD} >
                                            <Button iconPos='left' icon="pi pi-cloud-upload" label="Bulk Planner Upload" className='ma-m-lr10' onClick={() => { this.props.history.push('/yearlyplanner-bulk') }} />
                                        </Authorizer>
                                    </>
                                }}

                                left={() => {
                                    return <>
                                        <Authorizer permId={PERMISSIONS.YEARLY_ADD_PLANNER} >
                                            <div className='ma-mtb25'>
                                                <Button label="+ Add Planner" className='ma-m-lr10'
                                                    onClick={() => {
                                                        this.setState({
                                                            isShowCreatePlanner: true
                                                        })
                                                    }} />
                                            </div>
                                        </Authorizer>
                                    </>
                                }}
                            ></Toolbar>

                            <div className="card datatable-crud-demo ma-m30 createPlanner-table">
                                <DataTable ref={(el) => this.dt = el}
                                    let-i="rowIndex"
                                    value={this.state.yearlyPlanners}
                                    dataKey="_id"
                                    paginator
                                    lazy
                                    onPage={this.onPage}
                                    onSort={this.onSort}
                                    onFilter={this.onFilter}
                                    first={this.state.lazyParams.first}
                                    last={this.state.totalRecords}
                                    paginatorLeft={() => {
                                        return <>
                                            <p className='avail-q'> Available Planners:  <span>{this.state.totalRecords}</span> </p>
                                        </>
                                    }
                                    }
                                    rows={this.state.lazyParams.rows}
                                    sortField={this.state.lazyParams.sortField}
                                    sortOrder={this.state.lazyParams.sortOrder}
                                    responsiveLayout="scroll"
                                    totalRecords={this.state.totalRecords}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                    header={header}
                                >
                                    {/* <Column filterMatchMode='contains' sortable body={this.onIndexTemplate} field="Index" header="S No" /> */}
                                    <Column filterMatchMode='contains' sortable field="title" header="Title" />
                                    <Column filterMatchMode='contains' sortable field="boardName" header="Board" />
                                    <Column filterMatchMode='contains' sortable field="className" header="Grade" />
                                    <Column header="Start & End Date" body={(rowData) => {
                                        return (
                                            <div className="ma-status">
                                                <p>{moment(rowData.startDate).format('LL')} - {moment(rowData.endDate).format('LL')}</p>
                                            </div>
                                        )
                                    }} />
                                    {
                                        this.isColumnVisible('status') && <Authorizer permId={PERMISSIONS.YEARLY_STATUS_CHANGE} ><Column sortable field="isActive" header="Status" body={this.statusTemplate} /></Authorizer>}

                                    <Column header="Actions" body={(rowData, { rowIndex }) => {
                                        return <>
                                            <div className="p-input-icon-left ma-tbl-filter w-100">
                                                <ul className="ma-tbl-li action-btn w-100">
                                                    <Authorizer permId={PERMISSIONS.YEARLY_EDIT} >
                                                        <span data-pr-tooltip="Edit" data-pr-position="bottom" className={`xyz${rowIndex} ma-mr20`} onClick={() => {
                                                            this.setState({
                                                                isShowCreatePlanner: true,
                                                                editPlannerData: rowData
                                                            })
                                                        }}>
                                                            <EditIcon height={13} width={13} />
                                                        </span>
                                                        <Tooltip className="table-li-tooltip" autoHide={false} target={`.xyz${rowIndex}`} />
                                                    </Authorizer>

                                                    <Authorizer permId={PERMISSIONS.YEARLY_ASSIGN} >
                                                        <span data-pr-tooltip="Assign" data-pr-position="bottom" className={`ass${rowIndex} ma-mr20`} onClick={() => { this.assignPlanner(rowData) }}>
                                                            <ApprovalIcon height={13} width={13} />
                                                        </span>
                                                        <Tooltip className="table-li-tooltip" target={`.ass${rowIndex}`} />
                                                    </Authorizer>
                                                    <Authorizer permId={PERMISSIONS.YEARLY_DOWNLOAD_PLANNER} >
                                                        <span data-pr-tooltip="Download" data-pr-position="bottom" className={`dnd${rowIndex} ma-mr20`} onClick={() => { this.handleDownload(rowData) }}>
                                                            <DownloadIcon height={13} width={13} />
                                                        </span>
                                                        <Tooltip className="table-li-tooltip" target={`.dnd${rowIndex}`} />
                                                    </Authorizer>
                                                    {/* <Authorizer permId={PERMISSIONS.YEARLY_DELETE} >
                                                        <span data-pr-tooltip="Delete" data-pr-position="bottom" className={`dlete${rowIndex} ma-mr20`} onClick={() => { this.handleDelete(rowData) }}>
                                                            <DeleteIcon height={13} width={13} />
                                                        </span>
                                                        <Tooltip className="table-li-tooltip" target={`.dlete${rowIndex}`} />
                                                    </Authorizer> */}

                                                </ul>
                                            </div>
                                        </>
                                    }} >
                                    </Column>
                                </DataTable>

                            </div >
                        </> :

                        <CreatePlanner
                            editPlannerData={_.cloneDeep(this.state.editPlannerData)}
                            cancelCreate={() => {
                                this.setState({
                                    isShowCreatePlanner: false,
                                    editPlannerData: null
                                })
                            }}

                            onPlannerCreateorEditSuccess={
                                this.onPlannerCreateorEditSuccess
                            }


                        />

                    }




                    <Dialog
                        //header={<></>}
                        draggable={false}
                        blockScroll={true}
                        closeOnEscape={true}
                        className='ma-alert-box'
                        dismissableMask={true}
                        closable={false}
                        visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                        footer={() => {
                            return (<div>
                                <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editPlannerData: null }) }} />
                                <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmChange} />

                            </div>)
                        }}
                        onHide={() => this.setState({ warningDialog: warningDailogInit, editPlannerData: null })}>
                        <div>
                            <span className="warning-icon" >
                                <WarningIcon width={"100%"} height={"100%"} />
                            </span>
                        </div>
                        <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                        <p className='ma-alert-msg'>{this.state.warningDialog.message}</p>
                    </Dialog>
                    <Dialog
                        draggable={false}
                        blockScroll={true}
                        closeOnEscape={true}
                        className='ma-alert-box'
                        dismissableMask={true}
                        closable={false}
                        visible={this.state.warningDialogForDelete.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                        footer={() => {
                            return (<div>
                                <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialogForDelete: warningDailogInit, editPlannerData: null }) }} />
                                <Button label="Yes" className='ma-m-lr10' onClick={this.deletePlanner} />

                            </div>)
                        }}
                        onHide={() => this.setState({ warningDialogForDelete: warningDailogInit, editPlannerData: null })}>
                        <div>

                            <span className="warning-icon" >
                                <WarningIcon width={"100%"} height={"100%"} />
                            </span>
                        </div>
                        <p className='ma-alert-head'>{this.state.warningDialogForDelete.headerMsg}</p>
                        <p className='ma-alert-msg'>{this.state.warningDialogForDelete.message}</p>
                    </Dialog>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {
                    this.state.isLoading && <LoadingComponent />
                }
                {
                    this.state.isShowAssignDialog && <AssignPlanner examType="Assessment" plannerToAssign={this.state.plannerToAssign} onCancel={() => {
                        this.setState({
                            isShowAssignDialog: false
                        })
                    }}
                        onAssignSuccess={this.onAssignSuccess}

                    />
                }


                {

                    <div style={{ display: 'none' }}>
                        <DataTable ref={(el) => this.dt2 = el}
                            value={this.state.downloadPlannerData}
                            dataKey="id"
                            scrollable
                            responsiveLayout="scroll"
                            paginator
                            rows={100}

                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        // header={header2}
                        >
                            <Column headerClassName='difficulty' field="title" header="title" />
                            {/* <Column headerClassName='difficulty' field="plannerStartDate" header="plannerStartDate" />
                            <Column headerClassName='difficulty' field="plannerEndDate" header="plannerEndDate" />
                            <Column headerClassName='difficulty' field="description" header="description" /> */}
                            <Column headerClassName='difficulty' field="boardId" header="boardId" />
                            <Column headerClassName='difficulty' field="classId" header="classId" />
                            <Column headerClassName='difficulty' field="boardName" header="boardName" />
                            <Column headerClassName='difficulty' field="className" header="className" />
                            <Column headerClassName='difficulty' field="subjectName" header="subjectName" />
                            <Column headerClassName='difficulty' field="subjectId" header="subjectId" />
                            <Column headerClassName='difficulty' field="chapterName" header="chapterName" />
                            <Column headerClassName='difficulty' field="chapterId" header="chapterId" />
                            <Column headerClassName='difficulty' field="startDate" header="startDate" />
                            <Column headerClassName='difficulty' field="endDate" header="endDate" />
                            <Column headerClassName='difficulty' field="preKnowledge" header="preKnowledge" />
                            <Column headerClassName='difficulty' field="learningObjective" header="learningObjective" />
                            <Column headerClassName='difficulty' field="learningOutcome" header="learningOutcome" />
                        </DataTable>
                    </div>
                }
            </Navbar>
        </>
        )
    }
}


const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
});

export default connect(mapStateToProps, {
    getBoardsData,
    //  getYearlyPlannerData
})(Authentication(withRouter(PlannerList)));
//export default withRouter(PlannerList);