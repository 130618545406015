import React, { Component } from 'react'
import { baseUrlAdmin } from "../../../store/apiConstants";
import Service from "../../../services";
import Chart from 'react-apexcharts';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';

export default class BehaviorAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            qDifficulty: 'all',
            series: [],
            options: {

            },
            timeGraphSeries: [],
            timeGraphOptions: {

            },
            reportBytimeSlots: {},
            classTotalAccuracy: 0
        }

        this.service = new Service();

    }



    getBehaviorReport = (examId, userId) => {

        this.setState({
            isLoading: true
        })
        const url = `${baseUrlAdmin}/analytics/getbehaviouranalyticsreport/examId/${examId}/userId/${userId}`;
        //const url = `${baseUrlAdmin}/admin/getAllUserAdmin`;
        this.service.get(url, true).then(res => {

            
            if (res && res.res && res.res) {
                let data = this.processReport(res.res.records);
                this.setState({
                    ...data,
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
           // console.log(res);


        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });

            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    }


    componentDidMount() {
        const { examId, userId } = this.props;

        if (!examId || !userId) {
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'No Exam Id and user Id found', life: 3000 });
        } else {
            this.getBehaviorReport(examId, userId);
        }


        //this.processReport();

    }

    processReport = (data) => {
        // let data = {
        //     "_id": "61a4e8a23b1cbaf93043ce10",
        //     "userId": "cce7a04d-9376-4dfa-901e-683408923f0c",
        //     "examId": "66700a50-0fb8-4e35-9de0-a27a8e4633a6",
        //     "examTotalTime": 180,
        //     "questions": [
        //         {
        //             "qNo": 1,
        //             "difficulty": "Easy",
        //             "attemptedAt": 0,
        //             "timeSlot": "0-30",
        //             "timeTaken": 14,
        //             "answerStatus": "Wrong"
        //         },
        //         {
        //             "qNo": 2,
        //             "difficulty": "Easy",
        //             "attemptedAt": 0,
        //             "timeSlot": "0-30",
        //             "timeTaken": 10,
        //             "answerStatus": "Wrong"
        //         },
        //         {
        //             "qNo": 3,
        //             "difficulty": "Easy",
        //             "attemptedAt": 0,
        //             "timeSlot": "0-30",
        //             "timeTaken": 5,
        //             "answerStatus": "Wrong"
        //         },
        //         {
        //             "qNo": 4,
        //             "difficulty": "Easy",
        //             "attemptedAt": 0,
        //             "timeSlot": "0-30",
        //             "timeTaken": 8,
        //             "answerStatus": "Wrong"
        //         },
        //         {
        //             "qNo": 5,
        //             "difficulty": "Easy",
        //             "attemptedAt": 0,
        //             "timeSlot": "0-30",
        //             "timeTaken": 4,
        //             "answerStatus": "Correct"
        //         },
        //         {
        //             "qNo": 6,
        //             "difficulty": "Easy",
        //             "attemptedAt": 0,
        //             "timeSlot": "0-30",
        //             "timeTaken": 6,
        //             "answerStatus": "Wrong"
        //         },
        //         {
        //             "qNo": 7,
        //             "difficulty": "Easy",
        //             "attemptedAt": 0,
        //             "timeSlot": "0-30",
        //             "timeTaken": 7,
        //             "answerStatus": "Wrong"
        //         },
        //         {
        //             "qNo": 8,
        //             "difficulty": "Easy",
        //             "attemptedAt": 0,
        //             "timeSlot": "0-30",
        //             "timeTaken": 6,
        //             "answerStatus": "Wrong"
        //         },
        //         {
        //             "qNo": 9,
        //             "difficulty": "Easy",
        //             "attemptedAt": 0,
        //             "timeSlot": "0-30",
        //             "timeTaken": 4,
        //             "answerStatus": "Wrong"
        //         },
        //         {
        //             "qNo": 10,
        //             "difficulty": "Easy",
        //             "attemptedAt": 0,
        //             "timeSlot": "0-30",
        //             "timeTaken": 7,
        //             "answerStatus": "Wrong"
        //         },
        //         {
        //             "qNo": 11,
        //             "difficulty": "Easy",
        //             "attemptedAt": 0,
        //             "timeSlot": "0-30",
        //             "timeTaken": 5,
        //             "answerStatus": "Wrong"
        //         },
        //         {
        //             "qNo": 12,
        //             "difficulty": "Easy",
        //             "attemptedAt": 0,
        //             "timeSlot": "0-30",
        //             "timeTaken": 13,
        //             "answerStatus": "Correct"
        //         },
        //         {
        //             "qNo": 13,
        //             "difficulty": "Easy",
        //             "attemptedAt": 0,
        //             "timeSlot": "0-30",
        //             "timeTaken": 5,
        //             "answerStatus": "Correct"
        //         },
        //         {
        //             "qNo": 14,
        //             "difficulty": "Easy",
        //             "attemptedAt": 0,
        //             "timeSlot": "0-30",
        //             "timeTaken": 5,
        //             "answerStatus": "Correct"
        //         },
        //         {
        //             "qNo": 15,
        //             "difficulty": "Easy",
        //             "attemptedAt": 0,
        //             "timeSlot": "0-30",
        //             "timeTaken": 5,
        //             "answerStatus": "Wrong"
        //         },
        //         {
        //             "qNo": 16,
        //             "difficulty": "Easy",
        //             "attemptedAt": 1,
        //             "timeSlot": "0-30",
        //             "timeTaken": 69,
        //             "answerStatus": "Wrong"
        //         },
        //         {
        //             "qNo": 98,
        //             "difficulty": "Easy",
        //             "attemptedAt": 0,
        //             "timeSlot": "0-30",
        //             "timeTaken": 19,
        //             "answerStatus": "Wrong"
        //         }
        //     ],
        //     "reportBytimeSlots": {
        //         "0-30": {
        //             "Wrong": 13,
        //             "Correct": 4,
        //             "Accuracy": 23.52941176470588
        //         },
        //         "30-60": {
        //             "Wrong": 0,
        //             "Correct": 0,
        //             "Accuracy": 0
        //         },
        //         "60-90": {
        //             "Wrong": 0,
        //             "Correct": 0,
        //             "Accuracy": 0
        //         },
        //         "90-120": {
        //             "Wrong": 0,
        //             "Correct": 0,
        //             "Accuracy": 0
        //         },
        //         "120-150": {
        //             "Wrong": 0,
        //             "Correct": 0,
        //             "Accuracy": 0
        //         },
        //         "150-180": {
        //             "Wrong": 0,
        //             "Correct": 0,
        //             "Accuracy": 0
        //         },
        //         "180-210": {
        //             "Wrong": 0,
        //             "Correct": 0,
        //             "Accuracy": 0
        //         }
        //     },
        //     "timeAccuracyReport": [
        //         {
        //             "time": 15,
        //             "accuracy": 24,
        //             "classTotalAccuracy": 4
        //         },
        //         {
        //             "time": 45,
        //             "accuracy": 0,
        //             "classTotalAccuracy": 4
        //         },
        //         {
        //             "time": 75,
        //             "accuracy": 0,
        //             "classTotalAccuracy": 4
        //         },
        //         {
        //             "time": 105,
        //             "accuracy": 0,
        //             "classTotalAccuracy": 4
        //         },
        //         {
        //             "time": 135,
        //             "accuracy": 0,
        //             "classTotalAccuracy": 4
        //         },
        //         {
        //             "time": 165,
        //             "accuracy": 0,
        //             "classTotalAccuracy": 4
        //         }
        //     ],
        //     "timeSlots": [
        //         "0-30",
        //         "30-60",
        //         "60-90",
        //         "90-120",
        //         "120-150",
        //         "150-180"
        //     ],
        //     "userAccuracy": 4,
        //     "createdAt": "2021-11-29T14:50:10.177Z",
        //     "updatedAt": "2021-11-29T14:50:10.177Z",
        //     "__v": 0
        // }
        let examTotalTime = data.examTotalTime;
        let totalNoOfQuestions = data.totalNoOfQuestions || 180;
        let timeAccuracyReport = [
            {
                name: "Below Average",
                data: []
            },
            {
                name: "Above Average",
                data: []
            }
        ]


        let classTotalAccuracy = 0;
        if (data.timeAccuracyReport) {
            
            classTotalAccuracy = data.timeAccuracyReport[0].classTotalAccuracy
            for (let i = 0; i < data.timeAccuracyReport.length; i++) {
                let r = data.timeAccuracyReport[i];

                if (r.classTotalAccuracy <= r.accuracy) {
                    timeAccuracyReport.forEach(a => {
                        if (a.name == 'Above Average') {
                            a.data.push([r.time, r.accuracy])
                        }
                    })

                } else {
                    timeAccuracyReport.forEach(a => {
                        if (a.name == 'Below Average') {
                            a.data.push([r.time, r.accuracy])
                        }
                    })
                }
            }
        }

        let options = {
            colors: ['#FEDB30', '#17B705'],
            chart: {
                height: 350,
                type: 'scatter',
                zoom: {
                    enabled: false,
                    type: 'xy'
                },
                toolbar: {
                    show: false
                }
            },
            xaxis: {
                tickAmount: data.timeSlots.length,
                labels: {
                    formatter: function (val) {
                        return parseFloat(val).toFixed(0)
                    }
                },
                min: 0,
                max: Number(examTotalTime),
                // range :0,
                floating: 0,
                title: {
                    text: 'Times (Minutes)',
                    style: {
                        fontWeight: 600
                    }
                }
            },
            yaxis: {
                tickAmount: 2,
                labels: {
                    formatter: function (val) {
                        return parseFloat(val).toFixed(0)
                    }
                },
                min: 0,
                max: 100,
                // range :0,
                floating: 0,
                title: {
                    text: 'Accuracy %',
                    style: {
                        fontWeight: 600
                    }
                }
            },
            legend: {
                position: 'right',
                horizontalAlign: 'center'
            },

        }

        let timeGraphSeries = [
            {
                name: "Wrong",
                data: []
            },
            {
                name: "Correct",
                data: []
            }
        ]

        if (data.questions) {
            for (let i = 0; i < data.questions.length; i++) {
                let r = data.questions[i];
                if (r.answerStatus == 'Wrong') {
                    timeGraphSeries.forEach(a => {
                        if (a.name == 'Wrong') {
                            a.data.push([r.qNo, r.correctCountPercentage])
                        }
                    })

                } else {
                    timeGraphSeries.forEach(a => {
                        if (a.name == 'Correct') {
                            a.data.push([r.qNo, r.correctCountPercentage])
                        }
                    })
                }
            }
        }


        let timeGraphOptions = {
            colors: ['#DC4229', '#17B705'],
            chart: {
                height: 250,
                type: 'scatter',
                zoom: {
                    enabled: false,
                    type: 'xy'
                },
                toolbar: {
                    show: false
                }
            },
            xaxis: {
                tickAmount: data.timeSlots.length,
                labels: {
                    formatter: function (val) {
                        return parseFloat(val).toFixed(0)
                    }
                },
                min: 0,
                max: Number(totalNoOfQuestions),
                // range :0,
                floating: 0,
                title: {
                    text: 'Questions',
                    style: {
                        fontWeight: 600
                    }
                }
            },
            yaxis: {
                //  tickAmount: 5,
                labels: {
                    formatter: function (val) {
                        return parseFloat(val).toFixed(0)
                    }
                },
                min: 0,
                max: 100,
                tickAmount: 4,
                floating: 0,
                title: {
                    text: 'Correct Percentage',
                    style: {
                        fontWeight: 600
                    }
                },

            },
            legend: {
                position: 'right',
                horizontalAlign: 'center'
            },
            grid: {
                yaxis: {
                    lines: {
                        show: false
                    }
                },
                xaxis: {
                    lines: {
                        show: true
                    }
                },

            }
        }


        this.setState({
            series: timeAccuracyReport,
            options,
            timeGraphSeries,
            timeGraphOptions,
            analyticsData: data,
            reportBytimeSlots: data.reportBytimeSlots,
            classTotalAccuracy
        })








    }



    filterGrapQDifficulty = (qDifficulty) => {

        let filteredQ = [];
        if (qDifficulty == 'all') {
            filteredQ = this.state.analyticsData.questions;
        } else {
            filteredQ = this.state.analyticsData.questions.filter(q => q.difficulty == qDifficulty);
        }


        let timeGraphSeries = [
            {
                name: "Wrong",
                data: []
            },
            {
                name: "Correct",
                data: []
            }
        ]


        if (filteredQ) {
            for (let i = 0; i < filteredQ.length; i++) {
                let r = filteredQ[i];
                if (r.answerStatus == 'Wrong') {
                    timeGraphSeries.forEach(a => {
                        if (a.name == 'Wrong') {
                            a.data.push([r.qNo, r.correctCountPercentage])
                        }
                    })

                } else {
                    timeGraphSeries.forEach(a => {
                        if (a.name == 'Correct') {
                            a.data.push([r.qNo, r.correctCountPercentage])
                        }
                    })
                }
            }
        }


        
        this.setState({
            qDifficulty: qDifficulty,
            timeGraphSeries,


        })




    }



    render() {
       // console.log(this.state)
        const { qDifficulty, reportBytimeSlots, classTotalAccuracy } = this.state;
        return (
            <div className='behavior-analytics '>

                {Object.keys(reportBytimeSlots).length ? <>
                    <p className='behavior-title'>Behaviour Analytics</p>
                    <div className='time-accuracy'>
                        <p className='t-accuracy'>Accuracy</p>

                        <div className='p-grid '>
                            <div className='p-col-12 p-md-10'>
                                <Chart
                                    options={this.state.options}
                                    series={this.state.series}
                                    type="scatter"
                                    height={200} />
                            </div>
                        </div>
                        <p className='ma-label-s2 ma-ml20'>Class Accuracy : {classTotalAccuracy}</p>
                    </div>
                    <h2 className='ma-mt30'>Frequency Wise Analysis</h2>
                    <div className='p-grid'>
                        <div className='p-col-12 p-md-10'>
                            <Chart
                                options={this.state.timeGraphOptions}
                                series={this.state.timeGraphSeries}
                                type="scatter"
                                height={200} />
                        </div>

                        <div className='p-col-12 p-md-2'>
                            <p>Filter by</p>
                            <div className='ma-mt10'>
                                <Button label='All' onClick={() => { this.filterGrapQDifficulty('all') }} className={`${qDifficulty == 'all' ? '' : 'p-button-secondary'} p-button-outlined filter-btn`} />
                            </div>
                            <div className='ma-mt10'>
                                <Button label='Easy' onClick={() => { this.filterGrapQDifficulty('Easy') }} className={`${qDifficulty == 'Easy' ? '' : 'p-button-secondary'} p-button-outlined filter-btn`} />
                            </div>
                            <div className='ma-mt10'>
                                <Button label='Medium' onClick={() => { this.filterGrapQDifficulty('Medium') }} className={`${qDifficulty == 'Medium' ? '' : 'p-button-secondary'} p-button-outlined filter-btn`} />
                            </div>
                            <div className='ma-mt10'>
                                <Button label='Hard' onClick={() => { this.filterGrapQDifficulty('Hard') }} className={`${qDifficulty == 'Hard' ? '' : 'p-button-secondary'} p-button-outlined filter-btn`} />
                            </div>


                        </div>
                    </div>

                    {/* <h2 className='ma-mt30'>Frequency Wise Analysis-Stats</h2> */}
                    <div className='p-grid'>
                        {Object.keys(reportBytimeSlots).map(key => {
                            return <div className='p-col-2 p-md-2 ma-t-center frequency-wise'>
                                <p className='ma-bold ma-t-center'>{key} mins</p>
                                <p className='ma-t-center fr-value'>{Math.round(reportBytimeSlots[key].Accuracy)}</p>
                                <p className='fr-label'>Accuracy</p>
                                <div className='p-grid ma-t-center'>
                                    <div className='p-col-12 p-md-6'>
                                        <p className='fr-value correct '>{reportBytimeSlots[key].Correct}</p>
                                        <p className='fr-label '>CORRECT</p>
                                    </div>
                                    <div className='p-col-12 p-md-6'>
                                        <p className='fr-value wrong'>{reportBytimeSlots[key].Wrong}</p>
                                        <p className='fr-label'>WRONG</p>
                                    </div>
                                </div>

                            </div>

                        })}

                    </div>
                </> : <></>}

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {
                    (this.state.isLoading) && <>
                        <LoadingComponent />
                    </>
                }
            </div>
        )
    }
}



