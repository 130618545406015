import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ContetOrigin } from '../../../utile/index';
import ColearnersVideo from './ColearnersVideo';

import ErudexVideo from './ErudexVideo';
import { connect } from 'react-redux';
import {
    getVideoUrl
} from '../../../store/actions';
import PDFPlayer from './pdfPlayer';
import PPTPlayer from './pptPlayer';
import QuizPreview from './quizPreview';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';





class ContentPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentInfo: this.props.contentInfo,
            videoInfo: {}
        };
        this.service = new Service()
    }


    getVideoUrl = (contentStorageKey) => {
        let url = `${baseUrlAdmin}/se3VideoFetch`;
        this.service.post(url, {
            preUrl: contentStorageKey
        }, true).then(res => {
            if (res && res.res) {
         
                this.setState((prevState) => {
                    return {
                        videoInfo: {
                            ...prevState.videoInfo,
                            [contentStorageKey]: res.res
                        },
                    };
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }


        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });

            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }

    componentDidMount() {
      //  if (this.state.contentInfo && this.state.contentInfo.contentStorageKey) {
      //      if (!(this.state.videoInfo && this.state.videoInfo[this.state.contentInfo.contentStorageKey])) {
                this.getVideoUrl(this.state.contentInfo.contentStorageKey)
      //      }

    //    }
    }

    render() {
        //   console.log('content-play', this.state.contentInfo)
        return (
            <Dialog
                style={{ width: '90%', height: '90vh' }}
                modal
                blockScroll={true}
                footer={() => {
                    return (<div className='ma-mt20'>
                        <Button label="Cancel" className='p-button-outlined' onClick={this.props.onCloseDialog} />
                    </div>)
                }}
                draggable={false}
                header={this.state.contentInfo.contentName}
                closeOnEscape={false}

                dismissableMask={true}
                visible={true}
                closable={true}
                onHide={this.props.onCloseDialog}>
                <div className='cotent-player' >
                    {parseInt(this.state.contentInfo.contentOrigin) == ContetOrigin.COLEARNERS && this.state.contentInfo.contentFileType == 'mp4' &&
                        <ColearnersVideo contentInfo={this.state.contentInfo} />
                    }
                    {parseInt(this.state.contentInfo.contentOrigin) == ContetOrigin.ERUDEX && this.state.contentInfo.contentFileType == 'mp4' &&
                        <ErudexVideo contentInfo={this.state.contentInfo} />
                    }
                    {/* parseInt(this.state.contentInfo.contentOrigin) == ContetOrigin.MELUHA && */}
                    {this.state.contentInfo.contentFileType == 'mp4' && this.state.contentInfo.contentStorageKey &&
                        <video
                            width={"100%"}
                            height={"100%"}
                            src={this.state.videoInfo && this.state.videoInfo[this.state.contentInfo.contentStorageKey]}
                            type={'video/' + this.state.contentInfo.contentFileType}
                            ref="vidRef"
                            controls
                        ></video>
                    }
                    {
                        this.state.contentInfo.contentFileType == 'pdf' && <PDFPlayer contentInfo={this.state.contentInfo} />
                    }
                    {
                        this.state.contentInfo.contentFileType == 'zip' && <PPTPlayer contentInfo={this.state.contentInfo} />
                    }


                    {
                        this.state.contentInfo.contentFileType == 'quiz' && <QuizPreview contentInfo={this.state.contentInfo} />
                    }
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </Dialog>
        );
    }
}





const mapsStatesToProps = (state) => {
    return {
        videoInfo: state.getVideoUrlInfo && state.getVideoUrlInfo.allVideoUrls
    };
};


export default connect(mapsStatesToProps, {

    getVideoUrl
})(ContentPlayer);