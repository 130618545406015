import React, { Component } from 'react'
import Service from '../../services';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import uuidv4 from 'uuid/v4';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import Period from './period';
import { Calendar } from 'primereact/calendar';
import { baseUrlAdmin } from '../../store/apiConstants';
import LoadingComponent from '../loadingComponent';
import { getServerTime, warningDailogInit } from '../../utile';
import { WarningIcon } from '../svgIcons';
import _ from 'lodash';

export default class AssignTeachers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      date: getServerTime(),
      copyDate: null,
      warningDialog: warningDailogInit,

    }
    this.service = new Service();
  }



  formPeriods = (savedPeriods, date) => {

    const { timeTable } = this.props;
    let periods = [];
    timeTable.periods.forEach((period, index) => {
      let _temp = {
        ...period,
        teacherName: '',
        teacherId: '',
        subjectId: '',
        subjectName: '',
        chapterName: '',
        chapterId: '',
        description: '',
        learningObjective: '',
        learningOutcome: '',
        teachingAids: [],
        no: index + 1,
        //  periodId: uuidv4(),
        date: date,
        // ...savedPeriod
      }

      periods.push(_temp);
    });

    // console.log(periods, 'periods');

    let timeTableWithPeriods = [];
    timeTable.sections.forEach(sec => {
      let _periods = JSON.parse(JSON.stringify(periods));
      _periods = _periods.map(period => {
        let savedPeriod = savedPeriods.find(p => p.periodId == period.periodId && p.sectionId == sec.sectionId);
        if (savedPeriod) {
          period = { ...period, ...savedPeriod }
        }
        return period;
      })

      let sectionInfo = timeTable.sectionInfo.find(s => s.sectionId == sec.sectionId);
      sectionInfo = JSON.parse(JSON.stringify(sectionInfo));
      timeTableWithPeriods.push({
        timeTableId: timeTable._id,
        ...sec,
        periods: _periods,
        sectionInfo: sectionInfo.data
      });
    });

    return timeTableWithPeriods
  }

  getOrGeneratePeriodsData = (date) => {
    const { timeTable } = this.props;
    this.setState({
      data: [],
      isLoading: true,
      isWarningForHoliday: false,
      isWarningForRemoveHoliday: false
    })
    const url = `${baseUrlAdmin}/timetable/getperiods`;
    this.service.post(url, { timeTableId: timeTable._id, sections: timeTable.sections.map(a => a.sectionId), date: moment(date).format('YYYY-MM-DD') }, true).then(res => {
      if (res && res.res && res.res.status) {

        if (res.res.data && res.res.data.holiday) {
          this.setState({
            isHoliday: true,
            isLoading: false
          })

        } else {

          let finalData = this.formPeriods(res.res.data, date)
          this.setState({
            data: finalData,
            isLoading: false,
            isHoliday: false
          })
        }



      } else {
        this.setState({
          isLoading: false
        })
        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
      }
    }).catch(e => {
      console.log(e);
      this.setState({
        isLoading: false
      })
    })

  }

  goTo = (where) => {

    if (where === 'prev') {
      let date = moment(this.state.date).add(-1, 'day')
      this.setState({
        date: new Date(new Date(date).setHours(0, 0, 0, 0))
      }, () => {
        this.onGo()
      })

    } else {
      let date = moment(this.state.date).add(1, 'day')
      this.setState({
        date: new Date(new Date(date).setHours(0, 0, 0, 0))
      }, () => {
        this.onGo()
      })
    }
  }
  copyData = () => {
    if (this.state.date) {
      let copyDate = moment(this.state.date).add(-1, 'day')
      this.setState({
        copyDate: new Date(new Date(copyDate).setHours(0, 0, 0, 0)),
        warningDialog: {
          visible: true,
          headerMsg: 'Are you Sure?',
          message: `Do you want to copy previous date periods? It will replaces the saved periods data in the current date`
        },
      })
    } else {
      this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'No Current date selected', life: 3000 });
    }
  }


  onCopyClick = () => {
    if (this.state.copyDate) {
      this.setState({
        warningDialog: {
          visible: true,
          headerMsg: 'Are you Sure?',
          message: `Do you want to copy the selected date periods? It will replaces the saved periods data in the current date`
        },
      })
    } else {
      this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'No date selected to copy', life: 3000 });
    }
  }

  setHoliday = () => {
    this.setState({
      isWarningForHoliday: true,
      warningDialog: {
        visible: true,
        headerMsg: 'Are you Sure?',
        message: `Do you want to set holiday for the selected date? It will replaces the saved periods data in the current date`
      },
    })

  }

  removeHoliday = () => {
    this.setState({
      isWarningForRemoveHoliday: true,
      warningDialog: {
        visible: true,
        headerMsg: 'Are you Sure?',
        message: `Do you want to remove holiday for the selected date?`
      },
    })

  }


  onConfirmSetHoliday = () => {
    const { timeTable } = this.props;

    this.setState({
      data: [],
      isLoading: true,
      warningDialog: warningDailogInit,
      isWarningForHoliday: false
    })
    const url = `${baseUrlAdmin}/timetable/setholiday`;
    this.service.post(url, { timeTableId: timeTable._id, sectionIds: timeTable.sections.map(a => a.sectionId), date: moment(this.state.date).format('YYYY-MM-DD') }, true).then(res => {

      // if (res && res.res && res.res.status) {
      //   let finalData = this.formPeriods(res.res.data, this.state.date)
      //   this.setState({
      //     data: finalData,
      //     isLoading: false
      //   })

      // } else {
      //   this.setState({
      //     isLoading: false
      //   })
      //   this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
      // }

      this.setState({
        isLoading: false,
      }, () => {
        this.getOrGeneratePeriodsData(this.state.date);
      })

      this.toast.show({ severity: 'success', summary: 'Success', detail: 'Holiday successfully set.', life: 3000 });
    }).catch(e => {
      console.log(e);
      this.setState({
        isLoading: false
      })
    })
  }

  onConfirmRemoveHoliday = () => {
    const { timeTable } = this.props;

    this.setState({
      data: [],
      isLoading: true,
      warningDialog: warningDailogInit,
      isWarningForRemoveHoliday: false,

    })
    const url = `${baseUrlAdmin}/timetable/removeholiday`;
    this.service.post(url, { timeTableId: timeTable._id, sectionIds: timeTable.sections.map(a => a.sectionId), date: moment(this.state.date).format('YYYY-MM-DD') }, true).then(res => {

      // if (res && res.res && res.res.status) {
      //   let finalData = this.formPeriods(res.res.data, this.state.date)
      //   this.setState({
      //     data: finalData,
      //     isLoading: false
      //   })

      // } else {
      //   this.setState({
      //     isLoading: false
      //   })
      //   this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
      // }

      this.setState({
        isLoading: false,
        isHoliday: false,

      }, () => {
        this.getOrGeneratePeriodsData(this.state.date);
      })

      this.toast.show({ severity: 'success', summary: 'Success', detail: 'Holiday successfully removed.', life: 3000 });
    }).catch(e => {
      console.log(e);
      this.setState({
        isLoading: false
      })
    })
  }

  onConfirmCopy = () => {

    if (this.state.isWarningForHoliday) {
      return this.onConfirmSetHoliday()
    } if (this.state.isWarningForRemoveHoliday) {
      return this.onConfirmRemoveHoliday()
    } else {


      const { timeTable } = this.props;

      this.setState({
        // data: [],
        isLoading: true,
        warningDialog: warningDailogInit

      })
      const url = `${baseUrlAdmin}/timetable/copy`;
      this.service.post(url, { copyDate: moment(this.state.copyDate).format('YYYY-MM-DD'), timeTableId: timeTable._id, date: moment(this.state.date).format('YYYY-MM-DD') }, true).then(res => {

        if (res && res.res && res.res.status) {
          let finalData = this.formPeriods(res.res.data, this.state.date)
          this.setState({
            data: finalData,
            isLoading: false
          })

        } else {
          this.setState({
            isLoading: false
          })
          this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
        }
      }).catch(e => {
        console.log(e);
        this.setState({
          isLoading: false
        })
      })


    }
  }

  onDateChange = (date) => {
    this.setState({
      date,
      data: []
    });
  }


  componentDidMount() {
    this.getOrGeneratePeriodsData(this.state.date);
  }
  onGo = () => {
    if (this.state.date) {
      this.getOrGeneratePeriodsData(this.state.date);

    } else {
      this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select date', life: 3000 });
    }
  }

  renderSectionInfo = (rowData) => {

    // let a = rowData.sectionInfo && rowData.sectionInfo.map(a => a.name).join('---');


    const sectionInfo = _.cloneDeep(rowData.sectionInfo)
    if (sectionInfo && sectionInfo.length) {
      sectionInfo.length = sectionInfo.length - 1;
    }


    return <>
      {/* {a ? a : null} */}
      {sectionInfo && sectionInfo.map(a => <p>{a.name}</p>)}

    </>
  }


  render() {
    const { timeTable } = this.props;
    // console.log('teachers-data', this.props)
    //  console.log('data', this.state.data)
    
    return (
      <>
        <p className='assing-teacher-title'>Assign Teachers</p>
        <Calendar
          showIcon={true}
          placeholder='Select Date'
          value={this.state.date}
          name='startDate'
          onChange={(e) => this.onDateChange(e.value)}
          id='fromDates'
          className='ma-w200'
        ></Calendar>
        <Button label="Go" className='ma-ml10' onClick={() => this.onGo()} />

        <div className='ma-clearFix'>

        </div>
        <div className='ma-mt20'>
          <p className='periods-date'><span onClick={() => { this.goTo('prev') }} className="ma-mr10" > <i className='pi pi-angle-double-left' />PREV</span> {this.state.date ? moment(this.state.date).format('MMMM Do YYYY, dddd') : ''}<span className="ma-ml10" onClick={() => { this.goTo('next') }}>NEXT<i className='pi pi-angle-double-right' /></span></p>

          {this.state.isHoliday ? <Button label="Remove Holiday" className='ma-ml10 p-button-danger' onClick={() => this.removeHoliday()} /> : <>  <Button label="Copy Last Date" className='ma-ml20 p-button-warning' onClick={() => this.copyData()} />
            <Calendar
              showIcon={true}
              placeholder='Select Date'
              value={this.state.copyDate}
              name='copydate'
              onChange={(e) => this.setState({ copyDate: e.value })}
              id='copydate'
              className='ma-w200 ma-ml20'
            ></Calendar>
            <Button label="Copy From Selected Date" className='ma-ml10' onClick={() => this.onCopyClick()} /><Button label="Set Holiday" className='ma-ml10 p-button-danger' onClick={() => this.setHoliday()} /></>}


        </div>
        {/* <div className="card datatable-crud-demo ma-mt20  fetch-q-tabled">
          <DataTable ref={(el) => this.dt = el}
            scrollable
            showGridlines
            let-i="rowIndex"
            value={this.state.data}
            dataKey="id"
            responsiveLayout="scroll"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          >
            <Column filterMatchMode='contains' className='difficulty' field="sectionName" header={"Section Name"} />
            {
              timeTable.periods.map((p, index) => <Column className='difficulty' header={<>{p.name}</>} body={(rowData) => {
                return <Period section={rowData} periodIndex={index} />
              }} />)
            }
          </DataTable>




        </div > */}

        {
          this.state.data && this.state.data.length ? <>
            <div className='ma-periods-table-wrapper'>
              <table className='ma-periods-table'>
                <tr>
                  <th className="section-info">Branch Info</th>
                  <th className="section-info">Section</th>
                  {timeTable.periods && timeTable.periods.length ? timeTable.periods.map((p, index) => {
                    return <th key={p.periodId} className={`${p.type == 'break' ? 'break-s' : ''}`} rowspan={p.type == 'break' ? this.state.data.length + 1 : undefined}> {p.name}</th>
                  }) : null}
                </tr>

                {this.state.data.map((rowData, ind) => <tr key={'ad' + ind}>
                  <td >
                    <p className='section-name'> {this.renderSectionInfo(rowData)}</p>
                    {/* {rowData.sectionName} */}
                  </td>
                  <td >
                    <p className='section-name'> {rowData.sectionName}</p>
                  </td>
                  {timeTable.periods.filter(b => b.type != 'break').map((p, index) => {
                    return <td key={p.periodId}> <Period section={{ ...rowData, periods: rowData.periods.filter(b => b.type != 'break') }} periodIndex={index} /></td>
                  })}
                </tr>

                )}
              </table>
            </div>
          </> : null
        }


        {this.state.isHoliday && <div className="ma-card ma-mt20"> <p className='ma-label-s1'>
          Holiday
        </p></div>}



        <Toast ref={(el) => this.toast = el} position="bottom-right" />

        {this.state.isLoading && <LoadingComponent />}
        <Dialog
          header={<></>}
          draggable={false}
          closeOnEscape={true}
          className='ma-alert-box'
          blockScroll={true}
          dismissableMask={true}
          closable={false}
          visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
          footer={() => {
            return (<div>
              <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, copyDate: null }) }} />
              <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmCopy} />

            </div>)
          }}
          onHide={() => this.setState({ warningDialog: warningDailogInit, copyDate: null })}>
          <div>
            <span className="warning-icon" >
              <WarningIcon width={"100%"} height={"100%"} />
            </span>
          </div>
          <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
          <p className='ma-alert-msg'>{this.state.warningDialog.message}</p>
        </Dialog>

      </>
    )
  }
}
