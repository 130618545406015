import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import PrimeReact from 'primereact/api';

// import io from 'socket.io-client';

//export const socket = io(`http://sasmeluhalong.macademy.in:4000`, { autoConnect: true });


// socket.on('online', function (data) {
//   console.log(data);

// });




// socket.on("connect", () => {
//   console.log(socket.id, 'id'); // "G5p5..."
//   socket.emit('ping', { user: new Date().getTime() });
// });


// socket.on("message", (data) => {
//   console.log(data, 'message')
// });

// socket.on("disconnect", () => {
//   console.log(socket.id)
// });

PrimeReact.ripple = true;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
