export const userAssignedBoards = (state, boardsType) => {

    let boards = [];
    if (localStorage.getItem('isAdmin') == "true") {
        if (state.boardsData && state.boardsData[boardsType] && state.boardsData[boardsType].length) {
            boards = state.boardsData[boardsType];
        }
    } else {
        if (state.boardsData && state.boardsData[boardsType] && state.boardsData[boardsType].length) {
            let boardsAssigned = [];
            if (localStorage.getItem('boardId') != '' && localStorage.getItem('boardId') != undefined) {
                boardsAssigned = localStorage.getItem('boardId').split(',');
            }
            boards = state.boardsData[boardsType].filter((board) => boardsAssigned.includes(board.boardId))
        }
    }
    return boards;
};

export const checkAndReturnOnlyBranches = (state, isLevels) => {
    let levels = [];
    let markedIndex = false;
    let isLevelMarked = false;
    let markedFound = false
    if (state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels) {

        markedIndex = state.branchDataLatest.data.levels.findIndex(l => l.isMarked);
        isLevelMarked = markedIndex >= 0 ? true : false
        if (markedIndex >= 0) {

            levels = state.branchDataLatest.data.levels && state.branchDataLatest.data.levels.map(l => {
                if (l.isMarked) {
                    markedFound = true;
                }
                if (!markedFound || l.isMarked) {
                    return l
                } else {
                    return null
                }
            })
                .filter((l) => l)


        }







    }

    
    if (isLevels) {
        return levels;
    } else {
        return isLevelMarked;
    }


}