import * as ActionTypes from '../actionTypes';
import axios from 'axios';
import {
    baseUrlAdmin
} from '../apiConstants';

const dispatchRequestBoardsData = data => ({
    type: ActionTypes.REQUEST_BOARDS,
    data
});

const dispatchReceivedBoardsData = (res, etag) => ({
    type: ActionTypes.RECEIVED_BOARDS,
    res,
    etag
});

const dispatchErrorBoardsData = err => ({
    type: ActionTypes.ERROR_BOARDS,
    err
});

const dispatchClearBoardsData = () => ({
    type: ActionTypes.CLEAR_BOARDS_DATA
});





const dispatchGetClassFromBoard = board => ({
    type: ActionTypes.SELECTED_CLASS_FROM_BOARD,
    selectedBoard: board
});

const dispatchGetGroupFromClass = classInfo => ({
    type: ActionTypes.SELECT_GROUP_FROM_CLASS,
    selectedClass: classInfo
});


const dispatchRequestUpdateOrganizationData = data => ({
    type: ActionTypes.REQUEST_UPDATE_ORGANIZATION,
    data
})

const dispatchReceivedUpdateOrganizationData = (res, etag) => ({
    type: ActionTypes.RECEIVED_UPDATE_ORGANIZATION,
    res,
    etag
})

const dispatchErrorUpdateOrganizationData = err => ({
    type: ActionTypes.ERROR_UPDATE_ORGANIZATION,
    err
})

const dispatchClearUpdateOrganizationData = () => ({
    type: ActionTypes.CLEAR_UPDATE_ORGANIZATION_DATA
})


const dispatchRequestContentBoardsData = data => ({
    type: ActionTypes.REQUEST_CONTENT_BOARDS,
    data
});


const dispatchReceivedContentBoardsData = (contentRepoBoards) => ({
    type: ActionTypes.RECEIVED_CONTENT_BOARDS,
    contentRepoBoards
});

const dispatchErrorContentBoardsData = err => ({
    type: ActionTypes.ERROR_CONTENT_BOARDS,
    err
});

const dispatchClearContentBoardsData = () => ({
    type: ActionTypes.CLEAR_CONTENT_BOARDS_DATA
});


export const getClassesFromBoard = boardId => (dispatch, getState) => {
    const organizationData = getState().organizationData;
    const boards = organizationData && organizationData.response && organizationData.response.Item && organizationData.response.Item.boards;

    if (boards && boards.length) {
        const board = boards.filter(board => board.boardId === boardId);
        dispatch(dispatchGetClassFromBoard(board));
    }
}

export const getGroupsFromClasses = classId => (dispatch, getState) => {
    const organizationData = getState().organizationData;
    const classes = organizationData && organizationData.selectedBoard && organizationData.selectedBoard.length && organizationData.selectedBoard[0] && organizationData.selectedBoard[0].classes || [];
    if (classes && classes.length) {
        const selectedClass = classes.filter(_class => _class.classId === classId);
        dispatch(dispatchGetGroupFromClass(selectedClass));
    }
}


const generateBoardsData = (response) => {
    let boards = [];
    let boardsWithoutMeluha = [];
    let activeBoards = [];
    let activeBoardsWithoutMeluha = [];
    boards = JSON.parse(JSON.stringify(response));

    boardsWithoutMeluha = boards.filter(b => b.boardId !== ActionTypes.MELUHA_BOARD_ID)
    activeBoards = JSON.parse(JSON.stringify(boards));;
    for (let i = activeBoards.length - 1; i >= 0; i--) {
        if (!activeBoards[i].boardActivity) {
            activeBoards.splice(i, 1);
        } else {
            if (activeBoards[i].classes && activeBoards[i].classes.length) {
                for (let j = activeBoards[i].classes.length - 1; j >= 0; j--) {
                    if (!activeBoards[i].classes[j].isActivity) {
                        activeBoards[i].classes.splice(j, 1);
                    } else {
                        if (activeBoards[i].classes[j].groups && activeBoards[i].classes[j].groups.length) {
                            for (let k = activeBoards[i].classes[j].groups.length - 1; k >= 0; k--) {
                                if (!activeBoards[i].classes[j].groups[k].isGroupActivity) {
                                    activeBoards[i].classes[j].groups.splice(k, 1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    activeBoardsWithoutMeluha = activeBoards.filter(b => b.boardId !== ActionTypes.MELUHA_BOARD_ID)

    return {
        boards,
        boardsWithoutMeluha,
        activeBoards,
        activeBoardsWithoutMeluha
    };
}

export const getBoardsData = (forEdit) => dispatch => {
    let _data;

    dispatch(dispatchRequestBoardsData(forEdit));

    let url = `${baseUrlAdmin}/organization${forEdit ? '?etag=true' : ''}`;


    axios({
        method: 'get',
        url: url,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        }
    }).then((res) => {

        _data = res.data

        const etag = res.headers && res.headers.etag;
        // if (etag) {
        //     localStorage.setItem('orgEtag', etag);
        // }


        if (res && res.data && res.data.Item && res.data.Item.boards) {
            const {
                boards,
                boardsWithoutMeluha,
                activeBoards,
                activeBoardsWithoutMeluha
            } = generateBoardsData(res.data.Item.boards);
            dispatch(dispatchReceivedBoardsData({
                boards,
                boardsWithoutMeluha,
                activeBoards,
                activeBoardsWithoutMeluha
            }, etag));
        } else {
            dispatch(dispatchErrorBoardsData({
                message: 'Some error occured'
            }));

        }


        // if (withoutMeluhaBoard) {
        //     _data = res.data.Item.boards.filter(b => b.boardId !== ActionTypes.MELUHA_BOARD_ID);
        //     if (res.data && res.data.Item && res.data.Item.boards && res.data.Item.boards.length) res.data.Item.boards = _data;
        // }










    }).catch(e => {
        if (e.response && e.response.status == 401) {
            localStorage.clear();
            window.location.reload();
        } else {
            dispatch(dispatchErrorBoardsData(e));
        }
    });
}

export const getActiveBoardsData = (withoutMeluhaBoard) => dispatch => {
    let _data;
    dispatch(dispatchRequestBoardsData(withoutMeluhaBoard));
    axios({
        method: 'get',
        url: `${baseUrlAdmin}/organization/active`
    }).then((res) => {
        const etag = res.headers && res.headers.etag;
        _data = res.data
        localStorage.setItem('orgEtag', etag);
        if (withoutMeluhaBoard) {
            _data = res.data.Item.boards.filter(b => b.boardId !== ActionTypes.MELUHA_BOARD_ID);
            if (res.data && res.data.Item && res.data.Item.boards && res.data.Item.boards.length) res.data.Item.boards = _data;
        }
        dispatch(dispatchReceivedBoardsData(res.data, etag));
    }).catch(e => {
        if (e.response && e.response.status == 401) {
            localStorage.clear();
            window.location.reload();
        } else {
            dispatch(dispatchErrorBoardsData(e));
        }
    });
}

export const clearBoardsData = () => dispatchClearBoardsData();

export const updateBoardOfORG = (boards) => (dispatch, getState) => {
    const store = getState();

    dispatch(dispatchRequestUpdateOrganizationData());
    // return
    let etag = store?.boardsData?.etag;

    let headers = {
        "If-Match": etag,
        'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
    }

    if (boards && boards.length) {
        axios({
            method: 'put',
            url: `${baseUrlAdmin}/organization`,
            data: {
                boards: [...boards]
            },
            headers,
        }).then((res) => {

            const etag = res.headers && res.headers.etag;
            if (etag) {
                localStorage.setItem('orgEtag', etag);
            }
            const {
                boards,
                boardsWithoutMeluha,
                activeBoards,
                activeBoardsWithoutMeluha
            } = generateBoardsData(res.data.boards);
            dispatch(dispatchReceivedUpdateOrganizationData({
                boards,
                boardsWithoutMeluha,
                activeBoards,
                activeBoardsWithoutMeluha
            }, etag));
        }).catch(e => {
            if (e.response && e.response.status == 401) {
                localStorage.clear();
                window.location.reload();
            } else if (e && e.response && e.response.data && e.response.data.errorResponse && e.response.data.errorResponse.length) {
                dispatch(dispatchErrorUpdateOrganizationData(e.response.data.errorResponse[0]));
            } else {
                dispatch(dispatchErrorUpdateOrganizationData(e));
            }
        });
    }
}


export const getContentRepoBoards = () => dispatch => {
    dispatch(dispatchRequestContentBoardsData());
    axios({
        method: 'get',
        url: `${baseUrlAdmin}/organization/content-repo-data`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        }
    }).then((res) => {
        if (res && res.data && res.data && res.data.data) {
            dispatch(dispatchReceivedContentBoardsData(res.data.data));
        } else {
            dispatch(dispatchErrorContentBoardsData({
                message: 'Some error occured'
            }));
        }
    }).catch(e => {
        if (e.response && e.response.status == 401) {
            localStorage.clear();
            window.location.reload();
        } else {
            dispatch(dispatchErrorBoardsData(e));
        }
    });
}