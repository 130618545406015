import React, { Component } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { createAdminUserAction } from '../../../store/actions/createAdminUser';
import { connect } from 'react-redux';
import { getContentPreSignedUrl, getBoardsData, uploadContent, getImageUrl, clearImageUrl } from '../../../store/actions';
import { getRolesList } from '../../../store/actions/rolesAction';
import { convertDataURIToBinary, trimObj } from '../../../utile';
import { getOrganizationData, getActiveOrganizationData, clearOrganizationData } from '../../../store/actions';
import { selectActiveRoles } from '../../../store/selectors/authorize';
import { selectOrganizationData } from '../../../store/selectors/organization';
import { MultiSelect } from 'primereact/multiselect';
import AddSubject from './addSubject';
import AddSection from './addSection';
import { RadioButton } from 'primereact/radiobutton';
import _ from 'lodash';
import { getFormFields, isFormValid } from '../../../utile/formHelper';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';
import InputTextB from './../../customComponents/inputTextB';
import PasswordText from './../../customComponents/passwordText';
import { FIELDS_INFO } from '../../../constants';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { InputSwitch } from 'primereact/inputswitch';
//import './../style.scss';
import uuidv4 from 'uuid/v4';

const formFields = [
    {
        "Type": "Choice",
        "Label": "User Type",
        "FieldName": "userType",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": " Role",
        "FieldName": "roles",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "User Name",
        "FieldName": "username",
        "Required": true,
        "MinLength": 5,
        "MaxLength": 30,
        "RegEx": "/^[\\w\\-\\s]+$/"
    },
    {
        "Type": "Text",
        "Label": "Name",
        "MinLength": 5,
        "FieldName": "name",
        "Required": true
    },
    {
        "Type": "Number",
        "Label": "Mobile Number",
        "FieldName": "phone_number",
        "MinLength": 10,
        "MaxLength": 10,
        "Required": true,
        "errMsgCustom": true,
        "errMsg": 'Invalid Mobile Number'

    },
    {
        "Type": "Email",
        "Label": "Email",
        "FieldName": "email",
        "Required": true
    },
    {
        "Type": "Password",
        "Label": "Password",
        "FieldName": "password",
        "Required": true,
        "MinLength": 8,
    },
    {
        "Type": "Password",
        "Label": "Confirm Password",
        "FieldName": "confirmPassword",
        "Required": true,
        "MinLength": 8,
    },
    // {
    //     "Type": "CheckBox",
    //     "Label": "Is Admin",
    //     "Label1": "Is Ok",
    //     "FieldName": "isAdmin",
    //     "Required": false
    // },
    {
        "Type": "CheckBox",
        "Label": "Can Change Role",
        "Label1": "Yes",
        "FieldName": "canChangeRole",
        "Required": false
    },
    {
        "Type": "Array",
        "Label": "Curriculums",
        "FieldName": "boardId",
        "Required": true,
        "errMsgCustom": true,
        "errMsg": 'Please assign atleast one curriculum'
    },
    {
        "Type": "Array",
        "Label": "Assign Subjects",
        "FieldName": "assignedSubjects",
        //"Required": true,
        "errMsgCustom": true,
        "errMsg": 'Please assign atleast one subject',
        "data": [{ id: 1, assigned: false }]
    },
    {
        "Type": "Array",
        "Label": "Assign Sections",
        "FieldName": "assignedSections",
        //"Required": true,
        "errMsgCustom": true,
        "errMsg": 'Please assign atleast one section',
        "data": [{ id: 1, assigned: false }]
    }

]
const getFormEditFields = () => {
    let fieldsNotRequired = [
        'password',
        'confirmPassword'
    ]
    return formFields.map((field) => {
        if (fieldsNotRequired.includes(field.FieldName)) {
            field.Required = false
        }
        return field
    });
}

class CreateUser extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(props.isAdd ? formFields : getFormEditFields(), this.props.editUserData || {});
        this.state = {
            user: this.formFields.data,
            formValidations: this.formFields.formValidations,
            getAllImagesUrlInfo: {},
            selectedRole: '',
            name: '',
            username: '',
            email: '',
            password: '',
            repassword: '',
            curriculum: [],
            avatarImage: '',
            errors: {},
            snackBarMsg: '',
            snackBarOpen: false,
            //  isAdmin: false,
            assignedSubjects: [],
            assignedSections: [],
            userType: "academic",
            rolesList: [],
            temp: null,
            isLoading: false
        };

        this.service = new Service();
    }




    componentDidMount() {
        this.props.getRolesListReq();
        // this.props.getActiveOrganizationData(true);

        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }

        if (this.props.editUserData && this.props.rolesList) {

            let rolesList = _.cloneDeep(this.props.rolesList);
            rolesList = rolesList.filter((i) => i.roleType == this.props.editUserData.userType);

            this.setState({
                rolesList
            });


        }

        // if (!this.props.branchData) {
        //     this.props.getBranchesLatest();
        // } else {
        //     if (this.props.editUser && this.props.branchData) {
        //         let finaldata = this.getSectionsData(this.props.branchData, this.state.user.sectionId, this.props.branchLevels.length);
        //         this.setState({
        //             sectionsData: finaldata
        //         });

        //     } else {
        //         let sectionsData = {
        //             level1: this.props.branchData.filter((a) => a.level == 1)
        //         }
        //         this.setState({
        //             sectionsData
        //         });
        //     }
        // }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.contentPreSignedUrl !== this.props.contentPreSignedUrl) {
            if (this.props.contentPreSignedUrl) {
                if (this.props.contentPreSignedUrl.presignedURL) {
                    this.props.uploadContent(this.props.contentPreSignedUrl.presignedURL, this.state.attachFileTestBinary);
                    const contentStorageKey = this.props.contentPreSignedUrl && this.props.contentPreSignedUrl.imageStorageKey;
                    this.setState({
                        createdContentStorageKey: contentStorageKey
                    });

                    if (this.state.selectedOptionId) {
                        const _optionArray = this.state.optionsArray.map((o) => {
                            if (o.id === this.state.selectedOptionId) {
                                return {
                                    ...o,
                                    imageName: contentStorageKey
                                };
                            }
                            return o;
                        });

                        this.setState({
                            optionsArray: _optionArray
                        });
                    }

                    if (this.state.selectedAttachedImage) {
                        this.setState({
                            attachFile: contentStorageKey
                        });
                    }

                    if (this.state.selectedHintAttachedImage) {
                        this.setState({
                            hintImage: contentStorageKey
                        });
                    }

                    if (this.state.slectedExplainImage) {
                        this.setState({
                            explainationImage: contentStorageKey
                        });
                    }
                }
            }
        }

        if (prevProps.uploadImageSuccess !== this.props.uploadImageSuccess) {
            if (this.props.uploadImageSuccess !== null) {
                this.props.getImageUrl(this.state.createdContentStorageKey);
            }
        }

        if (prevProps.getAllImagesUrlInfo !== this.props.getAllImagesUrlInfo) {
            if (this.props.getAllImagesUrlInfo) {
                this.setState(
                    {
                        getAllImagesUrlInfo: {
                            ...this.state.getAllImagesUrlInfo,
                            ...this.props.getAllImagesUrlInfo
                        }
                    }
                );
            }
        }

        if (prevProps.createNewUserError !== this.props.createNewUserError) {
            if (this.props.createNewUserError) {
                this.setState({
                    snackBarOpen: true,
                    snackBarMsg: this.props.createNewUserError
                });
            }
        }
        if (prevProps.isCreateUserSucceeded === false && this.props.isCreateUserSucceeded) {
            this.setState(
                {
                    snackBarOpen: true,
                    snackBarMsg: 'User created successfully.',
                    getAllImagesUrlInfo: {},
                    selectedRole: '',
                    name: '',
                    username: '',
                    email: '',
                    password: '',
                    repassword: '',
                    curriculum: [],
                    avatarImage: '',
                    errors: {},
                    rolesList: [], //this.props.rolesList, //.filter(i => i.roleType == 'academic'),
                    userType: 'academic'
                },
                () => {
                    window.location.reload();
                    this.props.clearImageUrl();
                }
            );
        }


        if (this.props.rolesList && prevProps.rolesList && this.props.rolesList.length != prevProps.rolesList.length) {

            if (this.props.editUserData) {
                let rolesList = _.cloneDeep(this.props.rolesList);
                rolesList = rolesList.filter((i) => i.roleType == this.props.editUserData.userType);

                this.setState({
                    rolesList
                });
            }

        }
    }

    componentWillReceiveProps(newProps) {
        // if (this.props.rolesList !== newProps.rolesList) {
        //     this.setState({
        //         rolesList: newProps.rolesList //.filter(i => i.roleType == 'academic')
        //     });
        // }
    }

    // componentWillUnmount() {
    //     this.props.clearOrganizationData();
    // }

    snackBarClose = () => {
        this.setState({
            snackBarMsg: null,
            snackBarOpen: false
        });
    };

    isValidNumber = (username) => {
        if (/^[0-9\b]+$/.test(username) && /^[6-9]\d{9}$/.test(username)) {
            return true;
        } else {
            return false;
        }
    };

    isValidateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    handleChange = (name) => (event) => {
        const val = event.target.value;
        this.setState((prevState, event) => ({
            [name]: val,
            errors: {
                ...prevState.errors,
                [name]: false
            }
        }));
    };

    fileUploadChangeToBinary = (e) => {
        const f = e.target.files[0];

        if (f) {
            if (/(jpe?g|png|gif)$/i.test(f.type)) {
                const r = new FileReader();
                r.onload = (e) => {
                    const base64Img = e.target.result;
                    const binaryImg = convertDataURIToBinary(base64Img);
                    const blob = new Blob([binaryImg], { type: f.type });
                    const blobURL = window.URL.createObjectURL(blob);
                    const fileName = f.name;
                    let binaryImage = JSON.stringify(binaryImg, null, 2);
                    this.setState({
                        attachFileTestBinary: binaryImg
                    });
                };
                r.readAsDataURL(f);
            } else {
                alert('Failed file type');
            }
        } else {
            alert('Failed to load file');
        }
    };

    handleAttachFileUpload = (name) => (e) => {
        const fileSize = e.target.files[0].size;
        this.fileUploadChangeToBinary(e);

        let files = e.target.files;
        let fileName = files && files.length > 0 && files[0].name;

        this.setState({ [name]: fileName }, () => {
            this.props.getContentPreSignedUrl(fileName, true);
        });
    };

    renderRow = (label, key, type = 'text') => {
        const { classes } = this.props;
        return <></>;
    };


    isAdditionalValid = () => {

        let user = JSON.parse(JSON.stringify(this.state.user));
        let { formValidations } = this.state;
        let { assignedSubjects, assignedSections } = user;

        // password and confirm password 
        let isValid = true;
        if (user.password != user.confirmPassword) {
            isValid = false;
            formValidations.fields['confirmPassword'] = { ...formValidations.fields['confirmPassword'], isValid: false, errorMsg: `Password and Confirm password is not matched.` }
        }

        if (true) {
            if (!(assignedSubjects && assignedSubjects.length)) {
                formValidations.fields['assignedSubjects'] = { ...formValidations.fields['assignedSubjects'], isValid: false, errorMsg: `Please assign subject` }
                isValid = false;
            } else if (!(assignedSubjects.every(i => i.assigned))) {
                isValid = false;
                formValidations.fields['assignedSubjects'] = { ...formValidations.fields['assignedSubjects'], isValid: false, errorMsg: `Please assign subject` }
            } else {
                formValidations.fields['assignedSubjects'] = { ...formValidations.fields['assignedSubjects'], isValid: true, errorMsg: `` }
            }

        }


        if (true) {
            if (!(assignedSections && assignedSections.length)) {
                formValidations.fields['assignedSections'] = { ...formValidations.fields['assignedSections'], isValid: false, errorMsg: `Please assign section` }
                isValid = false;
            } else if (!(assignedSections.every(i => i.assigned))) {
                isValid = false;
                formValidations.fields['assignedSections'] = { ...formValidations.fields['assignedSections'], isValid: false, errorMsg: `Please assign section` }
            } else {
                formValidations.fields['assignedSections'] = { ...formValidations.fields['assignedSections'], isValid: true, errorMsg: `` }
            }

        }


        // if (!isValid) {
        this.setState((prevState) => {
            return {
                formValidations
            };
        });
        //}

        return isValid

    }

    onSubmitCreateUser = () => {


        const formStatus = isFormValid(formFields, this.formFields.formValidations, trimObj(this.state.user));


        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }
        if (!this.isAdditionalValid()) {
            isCompleteFormValid = false;
        }

        if (this.state.user.username !== '' && !(/^[\w\-]+$/).test(this.state.user.username)) {
            formStatus.formValidations.fields['username'] = { ...formStatus.formValidations.fields['username'], isValid: false, errorMsg: `User name allows only letters, numbers, -,_` }

            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
            return

        }


        if (isCompleteFormValid) {
            let user = this.state.user;
            let assignedSubjects = [];
            this.state.user.assignedSubjects.map((ia) => {
                if (ia.assigned) {
                    assignedSubjects.push(ia.data);
                }
            });

            let assignedSections = [];
            this.state.user.assignedSections.map((ia) => {
                if (ia.assigned) {
                    assignedSections.push(ia.data);
                }
            });

            if (this.props.isAdd) {

                let payload = {
                    roles: user.roles,
                    username: user.username,
                    phone_number: `+91${user.phone_number}`,
                    name: user.name,
                    email: user.email,
                    password: user.password,
                    boardId: user.boardId.toString(),
                    //  isAdmin: user.isAdmin,
                    canChangeRole: user.canChangeRole,
                    assignedSubjects,
                    assignedSections,
                    userType: user.userType
                };

                payload = trimObj(payload)
                this.createAdminUser(payload)
            } else {

                let payload = {
                    username: `${user.username}`,
                    phone_number: `+91${user.phone_number}`,
                    userId: user.userId,
                    roles: user.roles,
                    name: user.name.trim(),
                    boardId: user.boardId.toString(),
                    //   isAdmin: user.isAdmin,
                    canChangeRole: user.canChangeRole,
                    userType: user.userType,
                    assignedSubjects: assignedSubjects,
                    assignedSections,
                    _id: user._id,
                    status: user.status,
                    email:user.email
                   
                }   

                payload = trimObj(payload)

                this.updateAdminUser(payload)

            }


        }




        // if (
        //     this.state.getAllImagesUrlInfo &&
        //     this.state.createdContentStorageKey &&
        //     this.state.getAllImagesUrlInfo[this.state.createdContentStorageKey]
        // ) {
        //     payload.picture = this.state.getAllImagesUrlInfo[this.state.createdContentStorageKey];
        // }

        //return;


    }







    // if (Object.values(errorState).some((item) => item === true)) {
    //     this.setState({ errors: errorState });
    // }
    // if (Object.values(errorState).every((item) => item === false)) {

    // }



    createAdminUser = (data) => {
        this.setState({
            isLoading: true
        });
        let thisObj = this;
        const url = `${baseUrlAdmin}/admin/addAdminUser`;

        this.service.post(url, data, true).then((res) => {

            if (res && res.status && res.res.success) {

                this.setState({
                    isLoading: false
                }, () => {
                    this.props.onUserCreate(true);
                })

            } else {

                this.setState({
                    isLoading: false
                });
                thisObj.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            thisObj.setState({
                isLoading: false
            });
            thisObj.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }

    updateAdminUser = (data) => {
        this.setState({
            isLoading: true
        });
        const url = `${baseUrlAdmin}/admin/updateAdminUser`;

        this.service.put(url, data, true).then((res) => {

            if (res && res.status && res.res.message) {

                this.setState({
                    isLoading: false
                }, () => {
                    this.props.onUserCreate(false);
                })

            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }


    getBoardName = (id) => {
        const { boardsInfo } = this.props;
        const _board = boardsInfo && boardsInfo.length && boardsInfo.find((b) => b.boardId === id);
        if (_board) {
            return _board.boardName;
        }
        return '';
    };


    onAssignClick = (curriculum) => {
        let assignedSubjects = this.state.user.assignedSubjects.map((item) => {
            if (item.id == curriculum.id) {
                return curriculum;
            } else {
                return item;
            }
        });

        this.setState((prevState) => {
            return {
                user: {
                    ...prevState.user,
                    assignedSubjects: assignedSubjects
                },
            }
        });
    }

    addMore = () => {

        if (this.state.user.assignedSubjects.every((s) => s.assigned === true)) {

            this.setState((prevState) => {
                return {
                    user: {
                        ...prevState.user,
                        assignedSubjects: [...prevState.user.assignedSubjects, { id: uuidv4(), assigned: false }],
                    }
                }
            }
            );
        } else {
            this.toast.show({ severity: 'warn', summary: 'Warning', detail: 'Please assign subject to add more', life: 3000 });

        }
    }



    removeTempSubject = (id) => {

        if (this.state.user.assignedSubjects.length) {
            let assignedSubjects = this.state.user.assignedSubjects.filter(item => item.id != id);
            this.setState((prevState) => {
                return {
                    user: {
                        ...prevState.user,
                        assignedSubjects
                    },
                }
            });
        }
    }




    onSectionAssignClick = (curriculum) => {
        let assignedSections = this.state.user.assignedSections.map((item) => {
            if (item.id == curriculum.id) {
                return curriculum;
            } else {
                return item;
            }
        });

        this.setState((prevState) => {
            return {
                user: {
                    ...prevState.user,
                    assignedSections
                },
            }
        });
    }

    addMoreSection = () => {

        if (this.state.user.assignedSections.every((s) => s.assigned === true)) {

            this.setState((prevState) => {
                return {
                    user: {
                        ...prevState.user,
                        assignedSections: [...prevState.user.assignedSections, { id: uuidv4(), assigned: false }],
                    }
                }
            }
            );
        } else {
            this.toast.show({ severity: 'warn', summary: 'Warning', detail: 'Please assign section to add more', life: 3000 });

        }
    }



    removeTempSection = (id) => {

        if (this.state.user.assignedSections.length) {
            let assignedSections = this.state.user.assignedSections.filter(item => item.id != id);
            this.setState((prevState) => {
                return {
                    user: {
                        ...prevState.user,
                        assignedSections
                    },
                }
            });
        }
    }


    onTextChange = (e, field) => {
        let data = JSON.parse(JSON.stringify(this.state.user));
        let formValidations = this.state.formValidations;
        data[field] = e.target.value;
        let fieldInfo = formFields.filter((f) => f.FieldName == field)
        if (formValidations.fields[field].isRequired) {
            if (data[field] == '' || data[field] == null) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
        }

        this.setState({
            user: data,
            formValidations
        });
    }
    onNumberChange = (e, field) => {
        if (!isNaN(e.target.value)) {
            let data = JSON.parse(JSON.stringify(this.state.user));
            let formValidations = this.state.formValidations;
            data[field] = e.target.value;
            let fieldInfo = formFields.filter((f) => f.FieldName == field)
            if (formValidations.fields[field].isRequired) {
                if (data[field] == '' || data[field] == null) {
                    formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` }
                    formValidations.isFormValid = false;
                } else {
                    formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
                }
            }

            this.setState({
                user: data,
                formValidations
            });

        } else {
            let formValidations = this.state.formValidations;
            formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `Only numbers are allowed` };
            this.setState((prevState) => {
                return {
                    formValidations
                };
            });
        }
    }


    onChoiceChange = (value, field) => {
        if (value) {
            let rolesList = _.cloneDeep(this.props.rolesList);
            rolesList = rolesList.filter((i) => i.roleType == value);
            let data = JSON.parse(JSON.stringify(this.state.user));
            let formValidations = this.state.formValidations;
            data[field] = value;
            formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `` }

            if (field == 'userType') {
                data['roles'] = '';
            }


            this.setState({
                user: data,
                formValidations,
                rolesList
            });
        }
    }

    onSelectRole = (value, field) => {

        let data = JSON.parse(JSON.stringify(this.state.user));
        let formValidations = this.state.formValidations;
        data[field] = value;
        formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `` }
        this.setState((prevState) => {
            return {
                user: data,
                formValidations
            };
        });
    };

    handleCheckBoxChange = (value, field) => {
        this.setState((prevState) => {
            return {
                user: {
                    ...prevState.user,
                    [field]: value
                }
            };
        });
    };

    onMultiChange = (value, field) => {

        let formValidations = this.state.formValidations;
        if (value && value.length) {
            formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `` }
        }
        this.setState((prevState) => {
            return {
                user: {
                    ...prevState.user,
                    [field]: value
                },
                formValidations
            };
        });

    }

    createDialogFooter = () => {
        return (<div className='ma-mt20'>
            <Button label={`${this.props.isAdd ? 'Add' : 'Update'}`} className='ma-m-lr10' onClick={this.onSubmitCreateUser} />
            <Button label="Cancel" className='p-button-outlined' onClick={this.props.hideUserCreateDialog} />
        </div>)
    };


    getUsername =(username)=> {
        if(!this.props.isAdd) {
                return username.substring(3);
        }else {
            return username
        }

    }

    render() {

        const { classes, boardsInfo = [] } = this.props;
        const { rolesList = [], user, formValidations, isLoading } = this.state;
        //    console.log(this.state.user)
        return (
            <>
                {
                    isLoading && <>
                        <LoadingComponent />
                    </>
                }
                <Dialog
                    blockScroll={true}
                    //visible={this.state.isShowCreate}
                    style={{ width: '80%' }}
                    header={this.props.isAdd ? "Create User" : "Edit User"}
                    modal
                    footer={() => {
                        return (<div className='ma-mt20'>
                            <Button label="Cancel" className='p-button-outlined' onClick={this.props.hideUserCreateDialog} />
                            <Button label={`${this.props.isAdd ? 'Add' : 'Update'}`} className='ma-m-lr10' onClick={this.onSubmitCreateUser} />
                        </div>)
                    }}
                    draggable={false}
                    // footer={this.footer}
                    closeOnEscape={false}
                    dismissableMask={false}
                    visible={true}
                    closable={false}
                    onHide={this.props.hideUserCreateDialog}>
                    <div className="p-col-12 p-md-12">
                        <div className="p-grid  ma-box-style1">
                            <div className="p-col-12 p-md-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <p className='ma-label-s1'>User Type<span className='ma-required'>*</span></p>
                                    </div>
                                    <div className="p-col-12 p-md-10 ma-pt20">
                                        <InputTextB info={FIELDS_INFO.ADMIN_USER_TYPE} id="cu1">
                                            <RadioButton className="radio-inline" value='academic' inputId="academic" name="userType" onChange={(e) => this.onChoiceChange(e.value, 'userType')} checked={user.userType === 'academic'} />
                                            <label className='ma-label-s1' htmlFor="academic">Academic</label>
                                            <RadioButton className="radio-inline ma-ml10" value='non-academic' inputId="non-academic" name="userType" onChange={(e) => this.onChoiceChange(e.value, 'userType')} checked={user.userType === 'non-academic'} />

                                            <label className='ma-label-s1' htmlFor="non-academic">Non Academic</label>
                                        </InputTextB>
                                        {formValidations && !formValidations.fields['userType'].isValid && <p className="p-error">{formValidations.fields['userType'].errorMsg}</p>}
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <p className='ma-label-s1'>Role<span className='ma-required'>*</span></p>
                                    </div>
                                    <div className="p-col-12 p-md-10">
                                        <InputTextB info={FIELDS_INFO.ADMIN_USER_ROLE} id="cu2">
                                            <Dropdown value={user.roles}
                                                className='ma-w200'
                                                options={rolesList.map(a => {
                                                    return { label: a.roleName, value: a.roleId }
                                                })}
                                                onChange={(e) => this.onSelectRole(e.value, 'roles')}
                                                placeholder="Select role" />
                                        </InputTextB>
                                        {formValidations && !formValidations.fields['roles'].isValid && <p className="p-error">{formValidations.fields['roles'].errorMsg}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid ma-box-style2">
                            <div className="p-col-12 p-md-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <p className='ma-label-s1'>Name of the user<span className='ma-required'>*</span></p>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <InputTextB info={FIELDS_INFO.ADMIN_USER_NAME} id="cu3">
                                            <InputText value={user.name} onChange={(e) => { this.onTextChange(e, 'name') }} className='p-inputtext-style1' />
                                        </InputTextB>
                                        {formValidations && !formValidations.fields['name'].isValid && <p className="p-error">{formValidations.fields['name'].errorMsg}</p>}
                                    </div>
                                </div>

                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <p className='ma-label-s1'>Email Id<span className='ma-required'>*</span></p>
                                    </div>
                                    <div className="p-col-12 p-md-10">
                                        <InputTextB info={FIELDS_INFO.ADMIN_USER_EMAIL_ID} id="cu5">
                                            <InputText
                                                type='email' value={user.email} onChange={(e) => { this.onTextChange(e, 'email') }} className='p-inputtext-style1' />
                                        </InputTextB>
                                        {formValidations && !formValidations.fields['email'].isValid && <p className="p-error">{formValidations.fields['email'].errorMsg}</p>}
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <p className='ma-label-s1'>Mobile Number<span className='ma-required'>*</span></p>
                                    </div>
                                    <div className="p-col-12 p-md-10">
                                        <InputTextB info={FIELDS_INFO.ADMIN_USER_MOBILE} id="cu4">

                                            <InputText
                                                disabled={!this.props.isAdd}
                                                value={user.phone_number} onChange={(e) => { this.onNumberChange(e, 'phone_number') }} className='p-inputtext-style1' />
                                        </InputTextB>
                                        {formValidations && !formValidations.fields['phone_number'].isValid && <p className="p-error">{formValidations.fields['phone_number'].errorMsg}</p>}
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <p className='ma-label-s1'>User Name (for login)<span className='ma-required'>*</span></p>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <InputTextB info={FIELDS_INFO.ADMIN_USER_LOGIN_NAME} id="cu3">
                                            <InputText  disabled={!this.props.isAdd} value={this.getUsername(user.username)} onChange={(e) => { this.onTextChange(e, 'username') }} className='p-inputtext-style1' />
                                        </InputTextB>
                                        {formValidations && !formValidations.fields['username'].isValid && <p className="p-error">{formValidations.fields['username'].errorMsg}</p>}
                                    </div>
                                </div>
                                {
                                    this.props.isAdd ? <>
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-2">
                                                <p className='ma-label-s1'>Password<span className='ma-required'>*</span></p>
                                            </div>
                                            <div className="p-col-12 p-md-10">
                                                <InputTextB info={FIELDS_INFO.ADMIN_USER_PASSWORD} id="cu6">
                                                    <InputText autocomplete="new-password" type='password' value={user.password} onChange={(e) => { this.onTextChange(e, 'password') }} className='p-inputtext-style1' />
                                                    {/* <PasswordText onChange={(e) => { this.onTextChange(e, 'password') }} /> */}
                                                </InputTextB>
                                                {formValidations && !formValidations.fields['password'].isValid && <p className="p-error">{formValidations.fields['password'].errorMsg}</p>}
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-2">
                                                <p className='ma-label-s1'>Confirm Password<span className='ma-required'>*</span></p>
                                            </div>
                                            <div className="p-col-12 p-md-10">
                                                <InputTextB info={FIELDS_INFO.ADMIN_USER_CONFIRM} id="cu7">
                                                    <InputText autocomplete="new-password" type='password' value={user.confirmPassword} onChange={(e) => { this.onTextChange(e, 'confirmPassword') }} className='p-inputtext-style1' />
                                                </InputTextB>
                                                {formValidations && !formValidations.fields['confirmPassword'].isValid && <p className="p-error">{formValidations.fields['confirmPassword'].errorMsg}</p>}
                                            </div>
                                        </div></> : <></>
                                }


                                {/* <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <p className='ma-label-s1'>Is Admin</p>
                                    </div>
                                    <div className="p-col-12 p-md-10 ma-pt20">
                                        <div>
                                            <InputTextB info={FIELDS_INFO.ADMIN_USER_TYPE} id="cu8">
                                                <Checkbox inputId="admin-management" checked={user.isAdmin}
                                                    onChange={(e) => { this.handleCheckBoxChange(e.checked, 'isAdmin') }}
                                                ></Checkbox>
                                            </InputTextB>

                                        </div>
                                        {formValidations && !formValidations.fields['isAdmin'].isValid && <p className="p-error">{formValidations.fields['isAdmin'].errorMsg}</p>}
                                    </div>
                                </div> */}
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <p className='ma-label-s1'>Can Change Role</p>
                                    </div>
                                    <div className="p-col-12 p-md-10 ma-pt20">
                                        <div>
                                            <InputTextB info={FIELDS_INFO.ADMIN_CHANGE_ROLE} id="cdu8">
                                                <InputSwitch
                                                    onChange={(e) => { this.handleCheckBoxChange(e.value, 'canChangeRole') }}
                                                    checked={user.canChangeRole} />
                                                {/* <Checkbox inputId="admin-management" checked={user.canChangeRole}
                                                    onChange={(e) => { this.handleCheckBoxChange(e.value, 'canChangeRole') }}
                                                ></Checkbox> */}
                                            </InputTextB>

                                        </div>
                                        {formValidations && !formValidations.fields['canChangeRole'].isValid && <p className="p-error">{formValidations.fields['canChangeRole'].errorMsg}</p>}
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <p className='ma-label-s1'>Curriculums<span className='ma-required'>*</span></p>
                                    </div>
                                    <div className="p-col-12 p-md-10">
                                        <InputTextB info={FIELDS_INFO.ADMIN_USER_CURRICULUM} id="cu9">
                                            <MultiSelect
                                                className='p-inputtext-style1-np'
                                                value={user.boardId}
                                                onChange={(e) => this.onMultiChange(e.value, 'boardId')}
                                                options={this.props.boards}
                                                optionLabel="boardName"
                                                optionValue="boardId"
                                                placeholder='Select Curriculum'
                                            // onChange={(e) => setCities(e.value)} 
                                            />
                                        </InputTextB>
                                        {formValidations && !formValidations.fields['boardId'].isValid && <p className="p-error">{formValidations.fields['boardId'].errorMsg}</p>}
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <p className='ma-label-s1'>Assign Subjects<span className='ma-required'>*</span></p>
                                    </div>
                                    <div className="p-col-10 p-md-10">

                                        {
                                            this.state.user && this.state.user.assignedSubjects && this.state.user.assignedSubjects.length ? this.state.user.assignedSubjects.map((temp) => {
                                                return <div key={temp.id} >
                                                    <AddSubject data={temp.data}
                                                        assignedSubjects={this.state.user.assignedSubjects}
                                                        key={temp.id}
                                                        id={temp.id}
                                                        onAssignClick={this.onAssignClick}
                                                        removeTempSubject={this.removeTempSubject} />
                                                </div>
                                            }) : null
                                        }

                                        <span onClick={this.addMore} className='ma-addmore ma-pointer'>
                                            <i className="pi pi-plus-circle"></i>
                                        </span>

                                        {formValidations && !formValidations.fields['assignedSubjects'].isValid && <p className="p-error">{formValidations.fields['assignedSubjects'].errorMsg}</p>}
                                    </div>
                                </div>


                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <p className='ma-label-s1'>Assign Sections<span className='ma-required'>*</span></p>
                                    </div>
                                    <div className="p-col-10 p-md-10">

                                        {
                                            this.state.user && this.state.user.assignedSections && this.state.user.assignedSections.length ? this.state.user.assignedSections.map((temp) => {
                                                return <div key={temp.id} >
                                                    <AddSection data={temp.data} assignedSections={this.state.user.assignedSections} key={temp.id} id={temp.id} onAssignClick={this.onSectionAssignClick} removeTempSubject={this.removeTempSection} />
                                                </div>
                                            }) : ''
                                        }

                                        <span onClick={this.addMoreSection} className='ma-addmore ma-pointer'>
                                            <i className="pi pi-plus-circle"></i>
                                        </span>

                                        {formValidations && !formValidations.fields['assignedSections'].isValid && <p className="p-error">{formValidations.fields['assignedSections'].errorMsg}</p>}
                                    </div>
                                </div>
                            </div>




                        </div>

                        {/* <Toolbar className="ma-toolbar" right={() => { return (<Button label={`Save`} className='' onClick={this.onSubmitCreateUser} />) }}></Toolbar> */}
                    </div>
                    
                </Dialog>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>

        );
    }

}


const mapStateToProps = (state) => ({
    rolesList: selectActiveRoles(state),
    boardsInfo: selectOrganizationData(state),
    contentPreSignedUrl:
        state.contentPreSignedUrl && state.contentPreSignedUrl.response && state.contentPreSignedUrl.response,
    uploadImageSuccess: state.uploadContent && state.uploadContent.response,
    getImageUrlInfo: state.getImageUrlInfo && state.getImageUrlInfo.response,
    getAllImagesUrlInfo: state.getImageUrlInfo && state.getImageUrlInfo.allImageUrls,
    getImageUrlLoading: state.getImageUrlInfo && state.getImageUrlInfo.loading,
    imageUrlErrorInfo: state.getImageUrlInfo && state.getImageUrlInfo.errorInfo,
    tagsInfo: state.tagsInfo && state.tagsInfo.response,
    curriculamInfo: state.curriculumData && state.curriculumData.subjectInfo,
    imageInfo: state.getImageUrlInfo && state.getImageUrlInfo.allImageUrls,
    isCreateUserSucceeded: state.createNewAdminUserInfo.isCreateUserSucceeded,
    createNewUserError:
        state.createNewAdminUserInfo &&
        state.createNewAdminUserInfo.errorInfo &&
        state.createNewAdminUserInfo.errorInfo.response &&
        state.createNewAdminUserInfo.errorInfo.response.data &&
        state.createNewAdminUserInfo.errorInfo.response.data.errorResponse[0] &&
        state.createNewAdminUserInfo.errorInfo.response.data.errorResponse[0].message,


    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData && state.boardsData.loading,
});

const mapDispatchToProps = (dispatch) => ({
    getContentPreSignedUrl: (fileName, option) => dispatch(getContentPreSignedUrl(fileName, option)),
    getRolesListReq: () => dispatch(getRolesList()),
    // getOrganizationData: () => dispatch(getOrganizationData()),
    //  getActiveOrganizationData: () => dispatch(getActiveOrganizationData()),
    // clearOrganizationData: () => dispatch(clearOrganizationData()),
    // createAdminUser: (data) => dispatch(createAdminUserAction(data)),
    uploadContent: (url, binary) => dispatch(uploadContent(url, binary)),
    getImageUrl: (url) => dispatch(getImageUrl(url)),
    clearImageUrl: () => dispatch(clearImageUrl()),
    getBoardsData: () => dispatch(getBoardsData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
