import React from 'react';
import uuidv4 from 'uuid/v4';
import { connect } from 'react-redux';
import './styles.scss';
import Service from '../discussionBoard/service';
import { ticketMangementEndpoint } from '../../store/apiConstants';
import DoubtItem from './common/doubtItem';
import SearchBar from './searchBar';
import {cloneDeep,sortBy} from 'lodash';
import { isUserAuthorized } from '../../utile/isAuthorized';
import PERM_CONSTANTS from './permissionConstants';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import moment from 'moment';

const isLocal = false;
var timer;

class DoubtsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            doubts: [],
            currentTab: this.props.currentTab,
            sort: '-createdAt',
            limit: 10,
            page: 1,
            search: '',
            stopRequests: false,
            isLoading: false,
            currentScrollPosition: 0,
            currentSocketMessage: { eventUID: 0 },
            gradeFilter: []
        };
        this.myRef = React.createRef();
        this.service = new Service();
    }



    getDoubts(sort, limit, page, search, currentTab, isNew, gradeFilter) {
        if (!this.state.isLoading && !this.state.stopRequests) {
            this.setState({
                isLoading: true,
                gradeFilter
            });
            ;
            let url1 = isLocal ? 'http://localhost:3002/api/v1' : ticketMangementEndpoint;
            let url = `${url1}/tickets?sort=${sort}&limit=${limit}&page=${page}&search=${search}`;

            let filter = {};

            if (gradeFilter && gradeFilter.length) {
                // filter['$and'] = [];
                // filter['$and'].push({ '$or': [] })

                // gradeFilter.forEach((item) => {
                //     filter['$and'][0]['$or'].push({
                //         boardId: item.boardId,
                //         classId: item.classId,
                //         groupId: item.groupId,
                //         subjectId: item.subjectId
                //     });
                // });

                filter.grades = gradeFilter;
            }

            if (currentTab == 0) {
                filter = { ...filter, 
                    // "isAssigned": false, 
                    // isDoubtCleared: false, 
                    // isReported: false,
                    ticketStatus:"NEW"
                 };
            } else if (currentTab == 1) {
                filter = { ...filter, 
                    // "isAssigned": true, 
                    // isDoubtCleared: false, 
                    // isReported: false,
                    ticketStatus:"INPROGRESS"
                 };
                url = url + '&assignedtome=true';
            } else if (currentTab == 2) {
                filter = { ...filter, 
                    // "isAssigned": true, 
                    // isDoubtCleared: true,
                    ticketStatus:"CLOSED" 
                };
                url = url + '&assignedtome=true';
            }
            if (this.props.userInfo.isSuperAdmin) {
                filter = { ...filter };
                url = url + '&isSuperAdmin=true';
            }

            //  console.log(filter);
            this.service.post(url, filter, true).then(data => {
                if (data.status) {
                    let doubts = isNew ? [] : this.state.doubts;
                    doubts = doubts.concat(data.res.data)
                    doubts = doubts.sort((a, b) => {
                        const dateA = new Date(a.lastMessage?.createdAt);
                        const dateB = new Date(b.lastMessage?.createdAt);
                        return dateB - dateA; // Sort in descending order
                      });
                    this.setState({
                        doubts: doubts,
                        isLoading: false,
                        stopRequests: data.res.data.length ? false : true,
                        page: page
                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });

        }
    }

    onRefresh = (isCallRefresh) => {
        let { sort, limit, page, search, currentTab, gradeFilter } = this.state;
        this.setState({
            stopRequests: false,
        }, () => {
            this.getDoubts(sort, limit, page, search, currentTab, true, gradeFilter)
        });
        if (isCallRefresh) {
            this.props.disabledOnrefresh()
        }
    }

    componentDidMount() {

        let { sort, limit, page, search, currentTab, gradeFilter } = this.state;
        if (this.state.gradeFilter.length) {
            this.getDoubts(sort, limit, page, search, currentTab, false, gradeFilter);
        }

        let thisObj = this;
        timer = setInterval(() => {
            let { sort, limit, page, search, currentTab, gradeFilter } = thisObj.state;
            if (currentTab == 0 && this.state.gradeFilter && this.state.gradeFilter.length) {
                this.getDoubts(sort, limit, 1, search, currentTab, true, gradeFilter);
            }
            //
        }, 60000);

    }


    componentWillUnmount() {

        clearTimeout(timer);
    }

    socketSend = (data) => {
        this.props.socketSend(data);
    }
    acknowledgeMessage = (doubtId, conversations) => {
        let conversationIds = conversations.filter(c => {
            if (c.from._id !== this.props.discussionUser._id) {
                return c._id
            }
        });

        if (conversationIds && conversationIds.length) {
            let payload = {
                action: "receivedMessage",
                data: {
                    doubtId: doubtId,
                    conversationIds: conversationIds.map((a) => { return a._id })
                },
                user: this.props.discussionUser._id,
                beacon: uuidv4()
            };
            this.socketSend(payload);
        }
    }

    onMessageRecieve = (response) => {
        if (response.type == 'doubt') {
            this.acknowledgeMessage(response.doubtId, response.message);

            let doubts = this.state.doubts.map((doubt) => {
                if (response.doubtId == doubt._id && this.props.selectedDoubt && this.props.selectedDoubt._id != doubt._id) {
                    if (response.message && response.message.length && response.message[response.message.length - 1].direction == 1) {
                        doubt.lastMessage = response.message[response.message.length - 1];
                        doubt.unseen += 1;
                    }
                }
                return doubt;
            });

            this.setState({
                doubts
            });
        }
    }


    componentWillReceiveProps(newProps) {
        let { sort, limit, page, search, currentTab, gradeFilter } = this.state;

        if (currentTab != newProps.currentTab) {
            this.setState({
                currentTab: newProps.currentTab,
                stopRequests: false,
                isLoading: false,
                sort: '-createdAt',
                limit: 10,
                page: 1,
                search: ''
            }, () => { this.getDoubts('-createdAt', 10, 1, '', newProps.currentTab, true, gradeFilter) });
        }

        if (newProps.currentSocketMessage) {
            if (this.state.currentSocketMessage.eventUID != newProps.currentSocketMessage.eventUID) {
                this.setState({
                    currentSocketMessage: newProps.currentSocketMessage
                }, () => { this.onMessageRecieve(newProps.currentSocketMessage) });
            }
        }
        if (this.props.callRefresh !== newProps.callRefresh && newProps.callRefresh) {
            this.onRefresh(newProps.callRefresh)
        }
    }


    onScroll = () => {
        const current = this.myRef.current;
        let total = current.scrollHeight - current.clientHeight;
        if (((current.scrollTop / total) * 100) > 80) {
            let { sort, limit, page, search, currentTab, gradeFilter } = this.state;
            this.getDoubts(sort, limit, page + 1, search, currentTab, false, gradeFilter);
        }
        this.setState({
            currentScrollPosition: current.scrollTop
        });
    }


    onsearchApply = (search, gradeFilter) => {
        let { limit, page, currentTab } = this.state;
        this.setState({
            search,
            page: 1,
            stopRequests: false
        }, () => { this.getDoubts('-createdAt', limit, 1, search, currentTab, true, gradeFilter) });

    }


    onDoubtSelect = (doubt) => {
        // let doubts = this.state.doubts.map(d => {
        //     if (doubt._id == d._id) {
        //         d.unseen = 0;
        //     }
        //     return d;
        // });

        this.props.onDoubtSelect(doubt);
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isCallRefresh !== this.state.data) {
            // Now fetch the new data here.
        }
    }

    onTabChange = (event, newValue) => {

        this.setState({
            currentTab: newValue
        });
    }

    render() {
        return (<>
            <div className="doubts-list ma-scroll-vr-smoke" ref={this.myRef}
                onScroll={this.onScroll}>
                <div>
                    {/* {isUserAuthorized(PERM_CONSTANTS.REFRESH) &&  */}
                    <Authorizer permId={PERMISSIONS.ASK_A_DOUBT_GENERAL_REFRESH}  >
                        <img src="./Refresh_button_1.svg" onClick={() => { this.onRefresh() }} className="refresh-i ma-pointer" />
                    </Authorizer>
                    {/* } */}
                    {/* <LoopIcon className="refresh-i" onClick={() => { this.onRefresh() }} /> */}
                </div>
                <div className='ma-mlr10'>
                    <SearchBar onsearchApply={this.onsearchApply} search={this.state.search} />
                </div>
                <div className='ma-mt10'>
                    {/* <img src="./searchbar.png" style={{ width: '100%' }} /> */}
                    {this.state.doubts && this.state.doubts.length ? this.state.doubts.map((d) => {
                        return <div key={d._id}>
                            <DoubtItem key={d._id}
                                selectedDoubt={this.props.selectedDoubt}
                                doubt={d} currentTab={this.props.currentTab}
                                onDoubtSelect={this.onDoubtSelect}
                            /></div>
                    }) : <p className="no-doubts">No Tickts found</p>
                    }
                </div>
            </div>
        </>
        );
    }
}

const mapStatesToProps = (state) => ({
    isLoading: state.contactUsInfo && state.contactUsInfo.loading,
    discussionUser: state.currentUserPerms && state.currentUserPerms.discussionUser && state.currentUserPerms.discussionUser,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},

});

export default connect(mapStatesToProps, {})(DoubtsList);
