import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';
export default class AllDataTableForExcel extends Component {
    constructor() {
        super();
        this.state = {
            allDataInfo: []
        }
    }


    componentDidMount() {
        if (this.props.curriculumInfo && this.props.curriculumInfo && this.props.curriculumInfo.subjects && this.props.curriculumInfo.subjects.length) {
            const data = [];
            const { boardId, classId, groupId, orgId, subjects } = this.props.curriculumInfo;
            subjects.length && subjects.map(sub => {
                sub.chapters && sub.chapters.length && sub.chapters.map(chap => {
                    chap.topics && chap.topics.length && chap.topics.map(topic => {
                        data.push({
                            boardId,
                            classId,
                            groupId,
                            orgId,
                            subjectId: sub.subjectId,
                            subjectName: sub.subjectName,
                            chapterId: chap.chapterId,
                            chapterName: chap.chapterName,
                            topicId: topic.topicId,
                            topicName: topic.topicName,
                            'contentNumber': '',
                            'resoureTitle': '',
                            'contentUri': '',
                            'contentType': '',
                            "contentPrioirty": ''
                        })
                    })
                })
            })
            this.setState({ allDataInfo: data })
        }

    }

    exportCSV = () => {
        this.dt.exportCSV();
    }


    render() {
        // console.log(this.props)
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >Curriculum Information</h2>
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
            </div>
        );
        return (
            <Dialog
                //visible={this.state.isShowCreate}
                style={{ width: '90%', height: '90vh' }}
                header={null}
                modal
                blockScroll={true}

                draggable={false}
                // footer={this.footer}
                closeOnEscape={true}
                dismissableMask={false}
                visible={true}
                closable={true}
                onHide={this.props.hideDataTable}>
                <div className="p-col-12 p-md-12">
                    <div className="card datatable-crud-demo ma-m30">
                        <DataTable ref={(el) => this.dt = el}
                            //  lazy
                            value={this.state.allDataInfo}
                            // selection={this.state.selectedProducts}
                            // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            // globalFilter={this.state.globalFilter}
                            header={header}>

                            <Column field="boardId" header="Board Id" />
                            <Column field="classId" header="Class Id" />
                            <Column field="groupId" header="Group Id" />
                            <Column field="subjectId" header="Subject Id" />
                            <Column field="subjectName" header="Subject Name" />
                            <Column field="chapterId" header="Chapter Id" />
                            <Column field="chapterName" header="Chapter Name" />
                            <Column field="topicId" header="Topic Id" />
                            <Column field="topicName" header="Topic Name" />
                            <Column field="contentNumber" header="Content Number" />
                            <Column field="resoureTitle" header="Content Title" />
                            <Column field="contentUri" header="Content Uri" />
                            <Column field="contentType" header="Content Type" />
                            <Column field="contentPrioirty" header="Content Priority" />
                        </DataTable>
                    </div>
                </div>
            </Dialog>
        )
    }
}
