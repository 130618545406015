import * as ActionTypes from '../actionTypes';
import axios from 'axios';
import { baseUrlAdmin } from '../apiConstants';


const dispatchRequestBranchData = data => ({
    type: ActionTypes.REQUEST_BRANCH_DATA,
    data
})

const dispatchReceivedBranchData = (data, etag) => ({
    type: ActionTypes.RECEIVED_BRANCH_DATA,
    data,
    etag

})

const dispatchErrorBranchData = err => ({
    type: ActionTypes.ERROR_BRANCH_DATA,
    err
})

const dispatchClearBranchData = () => ({
    type: ActionTypes.CLEAR_BRANCH_DATA
})



const dispatchUpdateBranchData = data => ({
    type: ActionTypes.REQUEST_UPDATE_BRANCH_DATA,
    data
})

const dispatchRecievedUpdateBranchDat = (data, etag) => ({
    type: ActionTypes.RECEIVED_UPDATE_BRANCH_DATA,
    data,
    etag

})

const dispatchErrorUpdateBranchData = err => ({
    type: ActionTypes.ERROR_UPDATE_BRANCH_DATA,
    err
})

export const clearBranchData = () => dispatchClearBranchData();



export const getBranchesLatest = (forEdit) => dispatch => {
    let _data;
    dispatch(dispatchRequestBranchData(forEdit));
    axios({
        method: 'get',
        url: `${baseUrlAdmin}/branch/latest${forEdit ? '?etag=true' : ''}`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        }
    }).then((res) => {

        const etag = res.headers && res.headers.etag;

        if (res && res.data && res.data.data) {

            dispatch(dispatchReceivedBranchData(res.data.data, etag));
        }
    }).catch(e => {
        if (e.response && e.response.status == 401) {
            localStorage.clear();
            window.location.reload();
        } else {
            dispatch(dispatchErrorBranchData(e));
        }
    });
}



export const saveBranchesLatest = (data) => (dispatch, getState) => {
    const store = getState();
    let etag = store?.branchDataLatest?.etag;

    dispatch(dispatchUpdateBranchData());
    axios({
        method: 'put',
        url: `${baseUrlAdmin}/branch`,
        headers: {
            "If-Match": etag,
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        data: data
    }).then((res) => {
        const etag = res.headers && res.headers.etag;

        if (res && res.data && res.data.data) {
            dispatch(dispatchRecievedUpdateBranchDat(res.data.data, etag));
        }
    }).catch(e => {
        if (e.response && e.response.status == 401) {
            localStorage.clear();
            window.location.reload();
        } else if (e && e.response && e.response.data && e.response.data.errorResponse && e.response.data.errorResponse.length) {
            dispatch(dispatchErrorUpdateBranchData(e.response.data.errorResponse[0]));
        } else {
            dispatch(dispatchErrorUpdateBranchData(e));
        }
        //  dispatch(dispatchErrorUpdateBranchData(e));
    });
}

