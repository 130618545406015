import * as ActionTypes from '../actionTypes';
import axios from 'axios';
import {
    baseUrlForLongRunningTask
} from '../apiConstants';

export const exportContentOfSelectedGrade = () => ({
    type: ActionTypes.EXPORT_CONTENT_OF_SECLECTED_GRADE
});




export const startExportToOffline = (boardId, classId, groupId) => (dispatch) => {
    const url = `${baseUrlForLongRunningTask}/content/exportcontentofselectedgrade/board/${boardId}/class/${classId}/group/${groupId}`;
    axios({
            method: 'get',
            url: url
        })
        .then((res) => {})
        .catch((e) => {});
};