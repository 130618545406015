import React, { Component } from 'react';
import { discussionBoardEndpoint } from '../../../store/apiConstants';
import Service from '../service';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import FileUpload from '../common/fileUpload';
import { S3_UPLOAD_CONFIG, StaticConstants } from './../../../constants';
import AttachmentsU from '../common/attachmentU';
import { getProfileURL, getFileType } from './../../../utile';
import _ from 'lodash';
class CreateAnswer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort: '-createdAt',
            limit: 5,
            page: 1,
            search: '',
            answers: [],
            stopRequests: false,
            isLoading: false,
            currentScrollPosition: 0,
            currentFilter: 1,
            uploadedFilesInfo: (this.props.answer && this.props.answer.attachments) ? this.props.answer.attachments : [],
            open: false,
            api: 1,
            repliesFilterRef: React.createRef(null),
            answer: {
                content: (this.props.answer && this.props.answer.content) ? this.props.answer.content : '',
                tags: [],
                attachments: [],
                postId: this.props.postId
            },
            errors: { content: false },
            errorMsg: ''

        }
        this.service = new Service();
        this.myRef = React.createRef();
    }

    componentDidMount() {

    }

    onAnswerChange = (event) => {
        let e = event.currentTarget.value;
        this.setState((prevState) => ({
            answer: {
                ...prevState.answer,
                content: e
            }
        }));
    }

    postAnswer = () => {
        if (!this.state.isLoading) {


            let { content } = this.state.answer;
            let { uploadedFilesInfo } = this.state;
            let errors = {
                content: false
            };
            let errorMsg = '';
            if (content == '') {
                errors.content = true;
            }
            if (Object.values(errors).some(e => e === true)) {
                this.setState({
                    errors,
                    errorMsg
                });
                setTimeout(() => {
                    this.setState({
                        errorMsg: null
                    });
                }, 3000);
            }

            if (uploadedFilesInfo && uploadedFilesInfo.length) {
                let uploadInProgress = false;
                for (let i = 0; i < uploadedFilesInfo.length; i++) {
                    if (!uploadedFilesInfo[i].uri) {
                        uploadInProgress = true;
                        break;
                    }
                }
                if (uploadInProgress) {
                    this.setState({
                        errorMsg: 'Please wait attachments are uploading...'
                    });
                    setTimeout(() => {
                        this.setState({
                            errorMsg: ''
                        });
                    }, 3000);
                    return;
                }
            }


            if (Object.values(errors).every(e => e === false)) {


                let attachmentT = this.state.uploadedFilesInfo.map(attach => {
                    return {
                        type: getFileType(attach.type),
                        uri: attach.uri,
                        name: attach.name,
                        newFileName: attach.newFileName,
                        mimeType: attach.type
                    }
                });

                let answerInfo = {
                    ...this.state.answer,
                    attachments: attachmentT
                }


                this.setState({ isLoading: true })

                if (this.props.answer) {
                    const url = `${discussionBoardEndpoint}/answers/${this.props.answer._id}`;

                    this.service.patch(url, answerInfo, true).then(data => {
                        if (data && data.status) {
                            this.setState((prevState) => ({
                                answer: {
                                    ...prevState.answer,
                                    content: '',
                                    attachments: []
                                },
                                uploadedFilesInfo: [],
                                isLoading: false
                            }), () => {
                                this.props.onEditAnswer(data.res.data.doc)
                            });
                        } else {
                            this.setState({ isLoading: false, stopRequests: true, });
                        }
                    });

                } else {
                    const url = `${discussionBoardEndpoint}/posts/${this.props.postId}/answers`;

                    this.service.post(url, answerInfo, true).then(data => {
                        if (data && data.status) {
                            this.setState((prevState) => ({
                                answer: {
                                    ...prevState.answer,
                                    content: '',
                                    attachments: []
                                },
                                uploadedFilesInfo: [],
                                isLoading: false
                            }), () => {
                                this.props.onAnswerPost(data.res.data.doc)
                            });
                        } else {
                            this.setState({ isLoading: false, stopRequests: true, });
                        }
                    });
                }

            }

        }
    }

    _onProgress = (filesInfo) => {
        let files = this.state.uploadedFilesInfo;
        files = files.map((file) => {
            if (file.name == filesInfo.name) {
                file = filesInfo;
            }
            return file;
        })

        this.setState((prevState) => {
            return { uploadedFilesInfo: files }
        });
    }

    _onFilsUploadError = (errMsg) => {
        this.setState({
            snackBarMsg: errMsg,
            snackBarOpen: true
        });
    }

    _afterFilesUploaded = (filess) => {
        let files = _.cloneDeep(Array.from(filess));
        for (let i = 0; i < files.length; i++) {
            this.setState((prevState, prevProps) => ({
                uploadedFilesInfo: [...prevState.uploadedFilesInfo, files[i]]
            }));
        }
    }

    _onSuccess = (fileName, data) => {
        let files = this.state.uploadedFilesInfo;
        files = files.map((file) => {
            if (file.name == fileName) {
                file.s3Info = data;
                file.uri = data.Location;
            }
            return file;
        })
        this.setState((prevState) => {
            return { uploadedFilesInfo: files }
        });

    }

    removeAttachment = (attachment) => {
        let uploadedFilesInfo = this.state.uploadedFilesInfo.filter(a => a.name != attachment.name);
        this.setState({ uploadedFilesInfo });
    }

    render() {
        const { item } = this.state;
        return (
            <>
                <div className="discussion-item disc-answer">
                    <div style={{ width: '100%', overflow: 'hidden', marginTop: '10px' }}>
                        <div style={{ width: '40px', float: 'left' }}>
                            <img src={getProfileURL(this.props?.discussionUser ? this.props?.discussionUser?.photo : '')} alt="n" className="profile-img" />
                        </div>
                        <div style={{ marginLeft: '40px' }} className="answer-box">

                            <div style={{ width: '100%', overflow: 'hidden' }}>
                                <div style={{ width: '60px', float: 'right' }}>
                                    <span style={{ fontWeight: 'bold' }}>@</span><span className="post-txt" onClick={this.postAnswer}>Post</span>
                                </div>
                                <div style={{ marginRight: '60px' }} >
                                    <textarea className="answer-txtarea" value={this.state.answer.content} placeholder="Leave your thoughts here...." onChange={this.onAnswerChange} rows={2} />
                                </div>
                            </div>
                            <AttachmentsU attachments={this.state.uploadedFilesInfo} removeAttachment={this.removeAttachment} />
                            {this.state.errorMsg != '' && <p className="create-err">{this.state.errorMsg} </p>}
                            <hr className="comment-hr ma-h2" />
                            <ul className="comment-attachments" >
                                <li>
                                    <FileUpload id={"file" + (new Date().getTime().toString().substring(9) + 10)}
                                        multiple={false}
                                        onProgress={this._onProgress}
                                        onSucess={this._onSuccess}
                                        Reset={this.state.Reset}
                                        maxFileSize={StaticConstants.MAX_FILE_SIZE}
                                        onError={this._onFilsUploadError}
                                        accept={'audios'}
                                        title={'add audio'}
                                        afterFilesUploaded={this._afterFilesUploaded} >
                                        <img src="./images/Voice_recorder.svg" className="li-img-icon" />
                                    </FileUpload>
                                    {/* <label htmlFor={'file-upload1'} className="" ><img src="./images/Voice_recorder.svg" className="li-img-icon" /></label> */}
                                </li>
                                <li>
                                    <FileUpload id={"file" + (new Date().getTime().toString().substring(9) + 320)}
                                        multiple={false}
                                        onProgress={this._onProgress}
                                        Reset={this.state.Reset}
                                        onSucess={this._onSuccess}
                                        maxFileSize={StaticConstants.MAX_FILE_SIZE}
                                        onError={this._onFilsUploadError}
                                        accept={'images'}
                                        title={'add image'}
                                        afterFilesUploaded={this._afterFilesUploaded} >
                                        <img src="./images/Upload_Image.svg" className="li-img-icon" />
                                    </FileUpload>
                                </li>
                                <li>
                                    <FileUpload id={"file" + (new Date().getTime().toString().substring(9) + 50)}
                                        multiple={false}
                                        onProgress={this._onProgress}
                                        Reset={this.state.Reset}
                                        onSucess={this._onSuccess}
                                        maxFileSize={StaticConstants.MAX_FILE_SIZE}
                                        onError={this._onFilsUploadError}
                                        accept={'videos'}
                                        title={'add video'}
                                        afterFilesUploaded={this._afterFilesUploaded} >
                                        <img src="./images/Upload_video.svg" className="li-img-icon" />
                                    </FileUpload>
                                </li>
                                <li>
                                    <FileUpload id={"file" + (new Date().getTime().toString().substring(9) + 60)}
                                        multiple={false}
                                        onProgress={this._onProgress}
                                        Reset={this.state.Reset}
                                        onSucess={this._onSuccess}
                                        maxFileSize={StaticConstants.MAX_FILE_SIZE}
                                        onError={this._onFilsUploadError}
                                        accept={'pdfs'}
                                        title={'add pdf'}
                                        afterFilesUploaded={this._afterFilesUploaded} >
                                        <img src="./images/Upload_Documents.svg" className="li-img-icon" />
                                    </FileUpload>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {

        discussionUser: state.currentUserPerms && state.currentUserPerms.discussionUser && state.currentUserPerms.discussionUser,
    }
}

export default connect(mapStateToProps, {})(withRouter(CreateAnswer));