import React, { Component } from 'react'
import Service from '../../../services';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { Toolbar } from 'primereact/toolbar';
import { Menu } from 'primereact/menu';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import SvgViewer from './../../customComponents/svgViewer';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { InputText } from 'primereact/inputtext';
import {
    getBoardsData,
} from '../../../store/actions';
import { baseUrlAdmin } from '../../../store/apiConstants';
import SingleQuestion from '../../questionBank/common/singleQuestion';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { Dropdown } from 'primereact/dropdown';
import { getFormFields, isFormValid, onChipsChange, onTextChange, isFieldValid, isValidMobile, onNumberChange, onDropDownChange, onChoiceChange, onEmailChange, isValidEmail, isValidUrl } from '../../../utile/formHelper';
import { MultiSelect } from 'primereact/multiselect';
import { Difficulty, QuestionTypeKeys, QuestionTypes, SkillTypes, Status } from '../../questionBank/constants';
import { BasicLazyParams } from '../../../utile';
import PreviewQuestion from '../../questionBank/common/previewQuestion';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import LoadingComponent from '../../loadingComponent';
import QuizPreview from './quizPreview';
import QuestionInTable from '../../questionBank/common/questionInTable';
import { PreviewIcon } from '../../svgIcons';
import InputTextB from '../../customComponents/inputTextB';
import { FIELDS_INFO } from '../../../constants';
const quizFields = require('./../examForms/quiz.json');

const processEditQuiz = (editQuiz) => {

    // editUser = _.cloneDeep(editUser);
    // editUser.gradeId = editUser.classId;
    // editUser.phone_number = editUser.phone_number.substring(3);

    // editUser.grade = editUser['custom:grade'];
    // editUser.group = editUser['custom:group'];
    // editUser.board = editUser['custom:board'];
    // editUser.board = editUser['custom:board'];
    return editQuiz;
}


class CreateQuiz extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(quizFields, this.props.editQuiz ? processEditQuiz(this.props.editQuiz) : {});
        this.state = {
            exam: this.formFields.data,
            formValidations: this.formFields.formValidations,
            boards: [],
            classes: [],
            groups: [],
            subjects: [],
            chapters: [],
            topics: [],
            boardId: null,
            classId: null,
            groupId: null,
            subjectId: null,
            chapterId: null,
            topicId: null,
            boardName: null,
            className: null,
            groupName: null,
            subjectName: null,
            chapterName: null,
            topicName: null,
            isLoading: false,
            columns: [
                { columnName: 'Question Type', isVisible: false, id: 'questionType' },
                { columnName: 'Video Linked', isVisible: true, id: 'videoLinking' },
                { columnName: 'Difficulty', isVisible: false, id: 'difficulty' },
                { columnName: 'Skill Type', isVisible: false, id: 'skillType' },
                { columnName: 'Exam Type', isVisible: false, id: 'examType' },
                { columnName: 'Source', isVisible: false, id: 'source' },
                { columnName: 'Status', isVisible: false, id: 'status' },

            ],
            showSearch: false,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            questions: [],
            currentStep: 1,
            selectedQIds: []
        }

        this.service = new Service();
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }



    formGradesDataForEdit = (editQuestion) => {

        if (this.props.boards && this.props.boards.length) {
            const _classes = [];
            const _groups = [];
            const selectedBoard =
                this.props.boards &&
                this.props.boards &&
                this.props.boards.length > 0 &&
                this.props.boards.find((board) => board.boardId === editQuestion.boardId);
            if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                selectedBoard.classes.map((item) => _classes.push({ className: item.className, classId: item.classId }));
            }

            const selectedClass = selectedBoard && selectedBoard.classes.find((_class) => _class.classId === editQuestion.classId);
            if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
                selectedClass.groups.map((item) => _groups.push({ groupName: item.groupName, groupId: item.groupId }));
                _groups.find((g) => g.groupId == editQuestion.groupId)

                this.setState(
                    {
                        boardId: editQuestion.boardId,
                        classId: editQuestion.classId,
                        groupId: editQuestion.groupId,
                        classes: _classes,
                        groups: _groups,
                        boardName: selectedBoard.boardName,
                        className: selectedClass.className,

                    }, () => {
                        this.getCurriculumData(true);
                    });
            }
        }
    }

    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }




        if (this.props.editExamId) {
            this.setState({
                isLoading: true
            });


            this.service.get(`${baseUrlAdmin}/examination/${this.props.editExamId}/exam-type/Quiz`, true).then((data) => {
                if (data && data.res && data.res.Item && data.res.Item.length) {
                    this.setState({
                        exam: data.res.Item[0],
                        selectedQIds: data.res.Item[0].questions.map((q) => q.questionId),
                        isLoading: false,
                    }, () => this.formGradesDataForEdit(data.res.Item[0]));
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
            });



        }
    }




    // grades selection --- start
    onChangeBoard = (boardId) => {
        this.setState((prevState) => {
            return {
                classes: [], groups: [],
                classId: null, groupId: null,
                subjects: [], subjectId: null,
                chapters: [], chapterId: null,
                topics: [], topicId: null,
                content: null,
                exam: {
                    ...prevState.exam,
                    questions: []

                },
                selectedQIds: [],
                questions: [],
                totalRecords: 0,
            };
        });

        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ className: item.className, classId: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
            boardName: selectedBoard.boardName,
            resourseOrigin: selectedBoard.isBase ? 'upload' : null

        });

    };

    onChangeClass = (classId) => {

        this.setState((prevState) => {
            return {
                groups: [], groupId: null,
                subjects: [], subjectId: null,
                chapters: [], chapterId: null,
                topics: [], topicId: null,
                content: null,
                exam: {
                    ...prevState.exam,
                    questions: []

                },
                selectedQIds: [],
                questions: [],
                totalRecords: 0,

            };
        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                className: selectedClass.className,
                groupId: _groups[0].value,
                groupName: _groups[0].label,
            }, () => {
                this.getCurriculumData();
            });
        }
    };


    // grades selection --- end


    //subjects --- start
    getSelectedCurriculum = (subjects) => {
        let { subjectId, chapterId, topicId } = this.state.exam;

        let selectedSubject = subjects.find((subject) => subject.subjectId == subjectId);
        let selectedChapter = selectedSubject && selectedSubject.chapters && selectedSubject.chapters.find(c => c.chapterId == chapterId);
        let selectedTopic = selectedChapter && selectedChapter.topics && selectedChapter.topics.find(c => c.topicId == topicId);

        return {
            subjects,
            chapters: selectedSubject.chapters,
            topics: (selectedChapter && selectedChapter.topics) ? selectedChapter.topics : [],
            subjectId,
            chapterId,
            topicId,
            subjectName: selectedSubject.subjectName,
            chapterName: selectedChapter ? selectedChapter.chapterName : null,
            topicName: selectedTopic ? selectedTopic.topicName : null,
            content: null,

        };

    }

    getCurriculumData = (isEdit) => {
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, true).then((data) => {



                if (data && data.res && data.res.Item) {
                    let selected = isEdit ? this.getSelectedCurriculum(data.res.Item.subjects) : {}

                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false,

                        ...selected
                    }, () => {

                        if (isEdit) {
                            this.onSearch()
                        }



                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    };


    onChangeSubject = (subjectId) => {
        let selectedSubject = this.state.subjects.filter((subject) => subject.subjectId == subjectId);

        this.setState((prevState) => {
            return {
                subjectId,
                subjectName: selectedSubject[0].subjectName,
                chapterId: null,
                topicId: null,
                chapters: selectedSubject[0].chapters.sort((a,b) => a.chapterNumber - b.chapterNumber),
                topics: [],
                content: null,
                exam: {
                    ...prevState.exam,
                    questions: []

                },
                selectedQIds: [],
                questions: [],
                totalRecords: 0,

            };
        });

    };

    onChangeChapter = (chapterId) => {
        let selectedChapter = this.state.chapters.filter((chapter) => chapter.chapterId == chapterId);


        this.setState((prevState) => {
            return {
                topics: selectedChapter[0].topics,
                chapterId,
                chapterName: selectedChapter[0].chapterName,
                topicId: null,
                content: null,
                exam: {
                    ...prevState.exam,
                    questions: []

                },
                selectedQIds: [],
                questions: [],
                totalRecords: 0,

            };
        });
    };

    onChangeTopic = (topicId) => {
        let selectedTopic = this.state.topics.find(topic => topic.topicId == topicId);


        this.setState((prevState) => {
            return {
                topicId,
                topicName: selectedTopic.topicName,
                exam: {
                    ...prevState.exam,
                    questions: []

                },
                selectedQIds: [],
                questions: [],
                totalRecords: 0,

            };
        });
    };
    onChipsChange = (value, fieldName, formFields, formData, formValidations) => {

        let data = JSON.parse(JSON.stringify(formData));
        data[fieldName] = value;
        let fieldInfo = formFields.filter((f) => f.FieldName == fieldName)
        fieldInfo = fieldInfo[0];
        if (formValidations.fields[fieldName].isRequired) {
            if (!data[fieldName].length) {
                formValidations.fields[fieldName] = {
                    ...formValidations.fields[fieldName],
                    isValid: false,
                    errorMsg: fieldInfo.RequiredMsg ? fieldInfo.RequiredMsg : `${fieldInfo.Label} is required.`
                }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[fieldName] = {
                    ...formValidations.fields[fieldName],
                    isValid: true,
                    errorMsg: ``
                }
            }
        }


        this.setState((prevState) => {
            return {
                exam: { ...data, questions: [] },
                formValidations: formValidations,
                selectedQIds: [],
                questions: [],
                totalRecords: 0,
            };
        });
    };

    isAdditionalValid = (exam) => {
        if (exam.questions.length == parseInt(exam.noOfQuestions)) {
            return true;

        } else {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please add the required number of questions.', life: 3000 });

            return false;
        }




    }

    questionsAvailableR = () => {
        return <>
            <p className='avail-q'> Available Questions:  <span>{this.state.questions.length}</span> </p>
        </>
    }



    onProceed = () => {
        const {
            exam,

        } = this.state;


        const formStatus = isFormValid(quizFields, this.formFields.formValidations, exam);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                isSubmitClick: true,
                formValidations: formStatus.formValidations,
            });
        } else if (!this.isAdditionalValid(exam)) {

        } else {








            this.setState({
                currentStep: 2
            })


            // this.toast.show({ severity: 'success', summary: 'valid', detail: '', life: 3000 });

            //  console.log('exam', this.state.exam)


        }
    }

    processExamData = (ex) => {
        let exam = _.cloneDeep(ex)
        exam.questions.sort((a, b) => a.psgQId.localeCompare(b.psgQId));

        exam.questions = exam.questions.map((a, index) => {
            a.qNo = index + 1;

            return a;
        });


        return exam
    }

    onSubmit = () => {

        const {
            exam,
            boardId,
            classId,
            groupId,
            subjectId,
            chapterId,
            topicId,
            boardName,
            className,
            groupName,
            subjectName,
            chapterName,
            topicName,

        } = this.state;

        let examInfo = {
            examType: 'Quiz',
            ...exam,
            boardId,
            classId,
            groupId,
            subjectId,
            chapterId,
            topicId,
            boardName,
            className,
            groupName,
            subjectName,
            chapterName,
            topicName,
        };


        //  console.log('examInfo', examInfo)


        let examToSave = this.processExamData(examInfo);
        if (this.props.editExamId) {
            this.setState({
                isLoading: true
            });



            this.service.put(`${baseUrlAdmin}/examination/${exam.examId}/exam-type/Quiz/update-exam`, examToSave, true).then((data) => {



                if (data && data.res && data.res.success) {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.props.onCreateSuccess(true);
                    });






                } else {
                    this.setState({
                        isLoading: false
                    });


                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Unable to create exam record', life: 3000 });
                }

                // if (data && data.status) {

                //     if (data?.res?.data) {

                //         this.setState({
                //             totalRecords: data.res.data.totalRecords,
                //             questions: data.res.data.questions
                //         })

                //     }
                // }


            }).catch(e => {


                this.setState({
                    isLoading: false
                });

                console.log(e);

            });
        } else {







            //return;
            this.setState({
                isLoading: true
            });
            this.service.post(`${baseUrlAdmin}/examination/add-exam-test`, examToSave, true).then((data) => {



                if (data && data.res && data.res.success) {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.props.onCreateSuccess();
                    });


                } else {
                    this.setState({
                        isLoading: false
                    });

                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Unable to create exam record', life: 3000 });
                }

                // if (data && data.status) {

                //     if (data?.res?.data) {

                //         this.setState({
                //             totalRecords: data.res.data.totalRecords,
                //             questions: data.res.data.questions
                //         })

                //     }
                // }


            }).catch(e => {

                this.setState({
                    isLoading: false
                });

                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                console.log(e);

            });
        }
    }


    onFetchQuestions = () => {

        this.setState((prevState) => {
            return {
                exam: {
                    ...prevState.exam,
                    questions: []

                },
                selectedQIds: []
            };
        }, this.onSearch);
    }

    onSearch = () => {

        const { topicId,
            lazyParams, globalSearch,
            exam,
            formValidations
        } = this.state;




        if (topicId && topicId != '') {
            let { questionType, difficulty,
                skillType, status, } = exam;

            let filters = {};


            if (topicId) {
                filters = { ...filters, topicId: [topicId] }
            }





            if (questionType && questionType.length) {
                filters = { ...filters, questionType: questionType }
            }
            if (difficulty && difficulty.length) {
                filters = { ...filters, difficulty: difficulty }
            }
            if (skillType && skillType.length) {
                filters = { ...filters, skillType: skillType }
            }
            if (status && status.length) {
                filters = { ...filters, status: status }
            }

            this.setState((prevState) => {
                return {
                    isLoading: true,
                    questions: [],
                    totalRecords: 0,

                };
            });
            this.service.post(`${baseUrlAdmin}/question/getTopicLinkedQuestions`, filters, true).then((data) => {

                if (data && data.status) {
                    if (data?.res?.questions && data?.res?.questions.length) {
                        this.setState({
                            questions: data?.res?.questions,
                            isLoading: false
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'No Questinon available', life: 3000 });
                    }
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                })
                console.log(e);

            });


        } else {

            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select board, class, subject, chapter, topic', life: 3000 });
        }




    }


    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.onSearch);
    }



    onSort = (event) => {


        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.onSearch);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.onSearch);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }

    onSelectQuestions = (e) => {

        let { exam } = this.state;
        if (exam.noOfQuestions && exam.noOfQuestions != '') {


            if (exam.questions.length < parseInt(exam.noOfQuestions) || e.value.length < exam.questions.length) {
                this.setState((prevState) => {
                    return {
                        exam: {
                            ...prevState.exam,
                            questions: e.value
                        },
                    };
                });
                // if (selectedQuestions && selectedQuestions.length) {
                //     if ((selectedQuestions.length + exam.questions.length) <= parseInt(exam.noOfQuestions)) {
                //         exam.questions = [...exam.questions, ...selectedQuestions];
                //         exam.questions = [... new Set(exam.questions)]
                //         this.setState({
                //             exam: exam,
                //             selectedQuestions: []
                //         });
                //     } else {
                //         let removeCount = (selectedQuestions.length + exam.questions.length) - parseInt(exam.noOfQuestions);
                //         this.toast.show({ severity: 'error', summary: 'Invalid', detail: `Number questions are exceeded, Remove ${removeCount} questions`, life: 3000 });
                //     }
                // }
            } else {
                this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'you have already selected maximum number of  questions required for the QUIZ.', life: 3000 });
            }
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter the number qustions before adding questions', life: 3000 });
        }


        // let { exam } = this.state;
        // if (exam.questions && exam.questions.length) {



        //     let isAlreadyAdded = false;
        //     for (let i = 0; i < exam.questions.length; i++) {
        //         const q = exam.questions[i];
        //         let examF = e.value.find((sq) => sq._id == q._id)
        //         if (examF) {
        //             isAlreadyAdded = true;
        //             break;
        //         }
        //     }
        //     if (!isAlreadyAdded) {
        //         this.setState({ selectedQuestions: e.value })
        //     } else {
        //         this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'This question already added', life: 3000 });

        //     }



        // } else {
        //     this.setState({ selectedQuestions: e.value })
        // }




    }


    onSelectQuestion = (question, e, psgQ) => {


        console.log(e)
        let { exam } = this.state;
        if (exam.noOfQuestions && exam.noOfQuestions != '') {
            if (e.checked) {
                //  if (exam.questions.length < parseInt(exam.noOfQuestions)) {

                if (question.questionType == 'SCCT' || question.questionType == 'MCCT' || question.questionType == 'NUM' || question.questionType == 'INT' || question.questionType == 'FB') {
                    this.setState((prevState) => {
                        return {
                            exam: {
                                ...prevState.exam,
                                questions: [...prevState.exam.questions, { ...question, psgQId: '' }]
                            },

                            selectedQIds: [...prevState.selectedQIds, question.questionId]
                        };
                    });
                } else if (question.questionType == 'PQS' || question.questionType == 'PQM') {
                    let subQObj = {
                        ...question,
                        paragraph: psgQ.paragraph,
                        paragraphUrl: psgQ.paragraphUrl,
                        videoLinking: psgQ.videoLinking,
                        psgQId: psgQ.questionId,
                        subjectId: psgQ.subjectId,
                        subjectName: psgQ.subjectName,
                        difficulty: psgQ.difficulty,
                        curriculumsOfQuestion: psgQ.curriculumsOfQuestion
                    }

                    let psgQuestions = this.state.exam.psgQuestions || [];
                    let questions = this.state.exam.questions || [];
                    questions = [...questions, subQObj]

                    if (!psgQuestions.find(q => q.questionId == psgQ.questionId)) {
                        psgQuestions.push(psgQ)
                    }

                    this.setState((prevState) => {
                        return {
                            exam: {
                                ...prevState.exam,
                                questions,
                                psgQuestions
                            },
                            selectedQIds: [...prevState.selectedQIds, question.questionId]
                        };
                    });
                } else if (question.questionType == 'PSGS' || question.questionType == 'PSGM') {

                    let examQuestions = _.cloneDeep(this.state.exam.questions);
                    let selectedQIds = _.cloneDeep(this.state.selectedQIds);


                    question.questions.forEach((subQ) => {
                        let subQObj = {
                            ...subQ,
                            paragraph: question.paragraph,
                            paragraphUrl: question.paragraphUrl,
                            videoLinking: question.videoLinking,
                            psgQId: question.questionId,
                            subjectId: question.subjectId,
                            subjectName: question.subjectName,
                            difficulty: question.difficulty,
                            curriculumsOfQuestion: question.curriculumsOfQuestion
                        }

                        examQuestions.push(subQObj);
                        selectedQIds.push(subQ.questionId);
                    });

                    let psgQuestions = this.state.exam.psgQuestions || [];

                    if (!psgQuestions.find(q => q.questionId == question.questionId)) {

                        psgQuestions.push(question)
                    }








                    this.setState((prevState) => {
                        return {
                            exam: {
                                ...prevState.exam,
                                questions: examQuestions,
                                psgQuestions
                            },
                            selectedQIds: selectedQIds
                        };
                    });





                }



                // } else {
                //      this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'you have already selected maximum number of  questions required for the QUIZ.', life: 3000 });
                //  }
            } else {

                if (question.questionType == 'PSGS' || question.questionType == 'PSGM') {
                    let selectedQuestions = this.state.exam.questions;
                    let selectedQIds = this.state.selectedQIds;

                    question.questions.forEach((qu) => {
                        selectedQuestions = selectedQuestions.filter((q) => q.questionId != qu.questionId);
                        selectedQIds = selectedQIds.filter((i) => i != qu.questionId)
                    });

                    let psgQuestions = this.state.exam.psgQuestions || [];
                    // for (let index = 0; index < psgQuestions.length; index++) {
                    //     if (psgQuestions[index].questions.some((q) => selectedQIds.includes(q.questionId))) {

                    //     } else {
                    //         psgQuestions[index] = psgQuestions[index].filter((pq) => pq.questionId != psgQ.questionId)
                    //     }

                    // }
                    psgQuestions = psgQuestions.filter((pq) => pq.questions.some((q) => selectedQIds.includes(q.questionId)))


                    this.setState((prevState) => {
                        return {
                            exam: {
                                ...prevState.exam,
                                questions: selectedQuestions,
                                psgQuestions
                            },

                            selectedQIds: selectedQIds
                        };
                    });

                } else {
                    let selectedQuestions = this.state.exam.questions.filter((q) => q.questionId != question.questionId);
                    let selectedQIds = this.state.selectedQIds.filter((i) => i != question.questionId)




                    let psgQuestions = this.state.exam.psgQuestions || [];




                    // for (let index = 0; index < psgQuestions.length; index++) {
                    //     if (psgQuestions[index].questions.some((q) => selectedQIds.includes(q.questionId))) {

                    //     } else {
                    //         psgQuestions[index] = psgQuestions[index].filter((pq) => pq.questionId != psgQ.questionId)
                    //     }

                    // }
                    psgQuestions = psgQuestions.filter((pq) => pq.questions.some((q) => selectedQIds.includes(q.questionId)))




                    this.setState((prevState) => {
                        return {
                            exam: {
                                ...prevState.exam,
                                questions: selectedQuestions,
                                psgQuestions
                            },

                            selectedQIds: selectedQIds
                        };
                    });
                }





            }
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter the number qustions before adding questions', life: 3000 });
        }


        // let { exam } = this.state;
        // if (exam.questions && exam.questions.length) {



        //     let isAlreadyAdded = false;
        //     for (let i = 0; i < exam.questions.length; i++) {
        //         const q = exam.questions[i];
        //         let examF = e.value.find((sq) => sq._id == q._id)
        //         if (examF) {
        //             isAlreadyAdded = true;
        //             break;
        //         }
        //     }
        //     if (!isAlreadyAdded) {
        //         this.setState({ selectedQuestions: e.value })
        //     } else {
        //         this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'This question already added', life: 3000 });

        //     }



        // } else {
        //     this.setState({ selectedQuestions: e.value })
        // }




    }

    onSelectQuestiones = (e) => {
        let { exam } = this.state;
        if (exam.questions && exam.questions.length) {



            let isAlreadyAdded = false;
            for (let i = 0; i < exam.questions.length; i++) {
                const q = exam.questions[i];
                let examF = e.value.find((sq) => sq._id == q._id)
                if (examF) {
                    isAlreadyAdded = true;
                    break;
                }
            }
            if (!isAlreadyAdded) {
                this.setState({ selectedQuestions: e.value })
            } else {
                this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'This question already added', life: 3000 });

            }



        } else {
            this.setState({ selectedQuestions: e.value })
        }




    }

    onAddQuestions = () => {
        let { selectedQuestions, exam } = this.state;
        if (selectedQuestions && selectedQuestions.length) {
            if (exam.noOfQuestions && exam.noOfQuestions != '') {
                if (exam.questions.length < parseInt(exam.noOfQuestions)) {
                    if (selectedQuestions && selectedQuestions.length) {
                        if ((selectedQuestions.length + exam.questions.length) <= parseInt(exam.noOfQuestions)) {
                            exam.questions = [...exam.questions, ...selectedQuestions];
                            exam.questions = [... new Set(exam.questions)]
                            this.setState({
                                exam: exam,
                                selectedQuestions: []
                            });
                        } else {
                            let removeCount = (selectedQuestions.length + exam.questions.length) - parseInt(exam.noOfQuestions);
                            this.toast.show({ severity: 'error', summary: 'Invalid', detail: `Number questions are exceeded, Remove ${removeCount} questions`, life: 3000 });
                        }
                    }
                } else {
                    this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'You have already selected maximum number of question required for the Exam', life: 3000 });
                }
            } else {
                this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter the number qustions before adding questions', life: 3000 });
            }
        } else {

            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select questions to add', life: 3000 });
        }
    }


    checkIsChecked = (question) => {
        let qIds = this.state.selectedQIds
        if (question.questionType == 'PSGS' || question.questionType == 'PSGM') {
            return question.questions.some((q) => qIds.includes(q.questionId))
        } else {
            return this.state.selectedQIds.includes(question.questionId);
        }


    }
    removeQuestion = (remQ) => {
        let { exam } = this.state;
        exam.questions = exam.questions.filter((q) => q._id != remQ._id);
        this.setState({
            exam: exam
        });
    }


    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }


    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }


    render() {
        const { exam, formValidations, questions } = this.state;
        //   console.log('state', this.state.exam)
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'>Questions List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" value={this.state.globalSearch} className="ma-tbl-search p-inputtext-md" onChange={this.onGlobalSearch} />}

                </div>

                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        {/* <Authorizer permId={PERMISSIONS.ORG_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer> */}
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <Authorizer permId={PERMISSIONS.ORG_VIEW_COLUMNS} > */}
                        <li><i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        {/* </Authorizer> */}
                        {/* <Authorizer permId={PERMISSIONS.ORG_DOWNLOAD} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer> */}
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <li onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </li>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        //  console.log('this.props', this.props)
        return (<div className='create-quiz'>
            {this.state.currentStep == 1 && <>

                <div className='ma-p20 paper-s1 '>

                    <div>
                        <div className="p-grid ma-w100p ma-p20" >
                            <div className="p-col-12 p-md-3">
                                <p className='ma-label-s2'>Name of the Test<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.EXAM_NAME} id="xTjT">
                                    <InputText value={exam.examName}
                                        onChange={(e) => { onTextChange(e.target.value, 'examName', this, quizFields, exam, formValidations, 'exam', 'formValidations') }}
                                        className='p-inputtext-style1 ma-w100p' />
                                </InputTextB>
                                {formValidations && !formValidations.fields['examName'].isValid && <p className="p-error">{formValidations.fields['examName'].errorMsg}</p>}
                            </div>
                            <div className="p-col-12 p-md-3">
                                <p className='ma-label-s2'>Number of Questions<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.NO_OF_QUESTIONS} id="xTdT">
                                    <InputText value={exam.noOfQuestions}
                                        onChange={(e) => { onNumberChange(e.target.value, 'noOfQuestions', this, quizFields, exam, formValidations, 'exam', 'formValidations') }}
                                        className='p-inputtext-style1 ma-w100p' />
                                </InputTextB>
                                {formValidations && !formValidations.fields['noOfQuestions'].isValid && <p className="p-error">{formValidations.fields['noOfQuestions'].errorMsg}</p>}
                            </div>
                            <div className="p-col-12 p-md-3">
                                <p className='ma-label-s2'>Total Time(In minutes)<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.EXAM_TIME} id="exTT">
                                    <InputText value={exam.totalTime}
                                        onChange={(e) => { onNumberChange(e.target.value, 'totalTime', this, quizFields, exam, formValidations, 'exam', 'formValidations') }}
                                        className='p-inputtext-style1 ma-w100p' />
                                </InputTextB>
                                {formValidations && !formValidations.fields['totalTime'].isValid && <p className="p-error">{formValidations.fields['totalTime'].errorMsg}</p>}
                            </div>
                            <div className="p-col-12 p-md-3">
                                <p className='ma-label-s2'>Total Marks<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.TOTAL_MARKS} id="xTeT">
                                    <InputText value={exam.totalMarks}
                                        onChange={(e) => { onNumberChange(e.target.value, 'totalMarks', this, quizFields, exam, formValidations, 'exam', 'formValidations') }}
                                        className='p-inputtext-style1 ma-w100p' />
                                </InputTextB>
                                {formValidations && !formValidations.fields['totalMarks'].isValid && <p className="p-error">{formValidations.fields['totalMarks'].errorMsg}</p>}
                            </div>

                        </div>
                    </div>
                    <div>
                        <div>

                            <Dropdown value={this.state.boardId}
                                className='ma-w200 ma-mt10'
                                options={this.props.boards}
                                optionLabel="boardName"
                                optionValue="boardId"
                                onChange={(e) => this.onChangeBoard(e.value)}
                                placeholder="Select Board" />
                            <span className='ma-required-mark '>*</span>

                            <Dropdown value={this.state.classId}
                                options={this.state.classes}
                                optionLabel='className'
                                optionValue='classId'
                                className='ma-w200 ma-mt10'
                                onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                            <span className='ma-required-mark '>*</span>
                            <Dropdown value={this.state.subjectId}
                                options={this.state.subjects}
                                optionLabel='subjectName'
                                optionValue='subjectId'
                                className='ma-w200 ma-mt10'
                                onChange={(e) => this.onChangeSubject(e.value)} placeholder="Select Subject" />
                            <span className='ma-required-mark '>*</span>
                            <Dropdown value={this.state.chapterId}
                                options={this.state.chapters}
                                optionLabel='chapterName'
                                optionValue='chapterId'
                                className='ma-w200 ma-mt10'
                                onChange={(e) => this.onChangeChapter(e.value)} placeholder="Select Chapter" />
                            <span className='ma-required-mark '>*</span>
                            <Dropdown value={this.state.topicId}
                                options={this.state.topics}
                                optionLabel='topicName'
                                optionValue='topicId'
                                className='ma-w200 ma-mt10'
                                onChange={(e) => this.onChangeTopic(e.value)} placeholder="Select Topic" />
                            <span className='ma-required-mark '>*</span>
                        </div>
                        <div className='ma-mt20'>
                            <MultiSelect value={exam.questionType}
                                options={QuestionTypes}
                                className='ma-mr20 ma-w200 ma-mt10'
                                onChange={(e) => this.onChipsChange(e.value, 'questionType', quizFields, exam, formValidations,

                                )}
                                placeholder="Select Question Type" />
                            <MultiSelect value={exam.difficulty}
                                options={Difficulty}
                                className='ma-mr20 ma-w200 ma-mt10'
                                onChange={(e) => this.onChipsChange(e.value, 'difficulty', quizFields, exam, formValidations)}
                                placeholder="Select Difficulty" />
                            <MultiSelect value={exam.skillType}
                                options={SkillTypes}
                                className='ma-mr20 ma-w200 ma-mt10'
                                onChange={(e) => this.onChipsChange(e.value, 'skillType', quizFields, exam, formValidations)}
                                placeholder="Select Skill Type" />
                            <MultiSelect value={exam.status}
                                options={Status}
                                className='ma-mr20 ma-w200 ma-mt10'
                                onChange={(e) => this.onChipsChange(e.value, 'status', quizFields, exam, formValidations)}
                                placeholder="Select Status" />
                        </div>
                    </div>
                    <Toolbar className="ma-toolbard  ma-mt20" right={() => <>
                        {/* <Button label="Reset" className='p-button-outlined' onClick={this.resetFilter} /> */}
                        <Button label='Generate' className='ma-m-lr10'
                            onClick={this.onFetchQuestions}
                        /></>}></Toolbar>
                </div>


                {/* <div className="card datatable-crud-demo ma-mt20">
                    <DataTable ref={(el) => this.dt = el}
                        scrollable scrollHeight="500px"
                        responsiveLayout="scroll"
                        dataKey="_id"
                        value={exam.questions}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        header="Questions">
                        <Column headerStyle={{ width: '130px' }} field="questionId" header="Question ID" />
                        <Column headerStyle={{ width: '140px' }} field="questionType" header="Question Type" />
                        <Column header="Question" body={(rowData) => {
                            return (<>
                                {this.renderQuestion(rowData)}
                            </>)
                        }} />

                        <Column headerStyle={{ width: '140px' }} header="Edit" body={(rowData) => {
                            return (<>
                                <span data-pr-tooltip="Preview Question" data-pr-position="bottom" className={`preview${rowData._id}`}
                                    onClick={() => {
                                        this.setState({
                                            isShowQuestionPreview: true,
                                            questionToPreview: rowData
                                        })
                                    }}>
                                    <i className='pi pi-eye ma-mr10' />
                                </span>
                                <span data-pr-tooltip="Remove Question" data-pr-position="bottom" className={`rem${rowData._id}`}
                                    onClick={() => { this.removeQuestion(rowData) }}>
                                    <i className='pi pi-times ma-mr10' />
                                </span>
                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.rem${rowData._id}`} />
                            </>
                            );
                        }} ></Column>
                    </DataTable>
                </div> */}
                <div className="card datatable-crud-demo fetch-q-tabled fetch-q-table  ma-mt20">
                    <DataTable ref={(el) => this.dt = el}
                        scrollable scrollHeight="500px"
                        responsiveLayout="scroll"
                        dataKey="_id"
                        value={this.state.questions}
                        paginatorLeft={this.questionsAvailableR}
                        // loading={this.props.isLoading}
                        paginator
                        // lazy
                        // filters={this.state.lazyParams.filters}
                        // onPage={this.onPage}
                        // onSort={this.onSort}
                        // onFilter={this.onFilter}
                        // first={this.state.lazyParams.first}
                        // last={this.state.totalRecords}
                        // rows={this.state.lazyParams.rows}
                        // sortField={this.state.lazyParams.sortField}
                        // sortOrder={this.state.lazyParams.sortOrder}
                        // totalRecords={this.state.totalRecords}
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        selectionMode="checkbox"
                        metaKeySelection={false}
                        // selection={exam.questions}
                        //onSelectionChange={this.onSelectQuestions}

                        //paginatorTemplate={paginatorTemplate}
                        //paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        // globalFilter={this.state.globalFilter}
                        header={header}>
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column> */}
                        <Column headerStyle={{ width: '130px' }} body={(rowData) => {
                            return (<><Checkbox inputId="cb1" onChange={e => this.onSelectQuestion(rowData, e)}
                                checked={this.checkIsChecked(rowData)}
                            ></Checkbox>
                            </>)
                        }} header="Select"
                        />
                        <Column headerStyle={{ width: '130px' }} field="questionId" header="Question ID" />

                        <Column headerClassName='question' header="Question and solution" body={(rowData) => {
                            return (<>
                                <QuestionInTable isCreateTest={true} selectedQIds={this.state.selectedQIds} onSelectQuestion={this.onSelectQuestion} question={rowData} />
                            </>)
                        }} />
                        {
                            this.isColumnVisible('questionType') && <Column headerClassName='difficulty' field="questionType" header="Question Type" />}
                        {
                            this.isColumnVisible('videoLinking') && <Column headerClassName='difficulty' field="videoLinking" header="Video Linked"
                                body={(rowData) => {
                                    return (<>
                                        <InputSwitch
                                            checked={(rowData.videoLinking && rowData.videoLinking.length) ? true : false} />

                                    </>)
                                }}

                            />
                        }
                        {
                            this.isColumnVisible('difficulty') && <Column headerClassName='difficulty' field="difficulty" header="Difficulty"

                            />}
                        {
                            this.isColumnVisible('skillType') && <Column headerClassName='difficulty' field="skillType" header="Skill Type"

                            />}
                        {
                            this.isColumnVisible('examType') && <Column headerClassName='difficulty' field="examType" header="Exam Type"
                                body={(rowData) => {
                                    return (<>
                                        {rowData.examType && rowData.examType.length ? rowData.examType.join(', ') : ''}
                                    </>)
                                }}
                            />}
                        {
                            this.isColumnVisible('source') && <Column headerClassName='difficulty' field="source" header="Source"
                            />}
                        {
                            this.isColumnVisible('status') && <Column headerClassName='difficulty' field="status" header="Status"
                            />}
                        <Column headerClassName='difficulty' headerStyle={{ width: '140px' }} header="Actions" body={(rowData) => {
                            return (<>
                                <span data-pr-tooltip="Preview Question" data-pr-position="bottom" className={`preview${rowData._id}`}
                                    onClick={() => {
                                        this.setState({
                                            isShowQuestionPreview: true,
                                            questionToPreview: rowData
                                        })
                                    }}>
                                    <PreviewIcon width={20} height={20} />

                                </span>
                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.preview${rowData._id}`} />
                            </>
                            );
                        }} ></Column>
                        {/* <Column header="Topics" body={this.showChaptersTemplate}></Column> */}
                    </DataTable>


                </div>
                {formValidations && !formValidations.fields['questions'].isValid && <p className="p-error">{formValidations.fields['questions'].errorMsg}</p>}
                <Toolbar className="ma-toolbard ma-mt20"
                    left={() => <>
                        {exam.noOfQuestions && exam.noOfQuestions.length && <>
                            <p className='selected-q-count'>Selected Questions: <span> {exam.questions.length}/{exam.noOfQuestions}</span></p>
                        </>}
                    </>

                    }

                    right={() => <>

                        <Button label='Proceed' className='ma-m-lr10'
                            onClick={this.onProceed}
                        /></>}
                // left={() => <Button label='Add Questions' onClick={this.onAddQuestions} />}

                ></Toolbar>
            </>}






            {this.state.currentStep == 2 && <>
                <QuizPreview exam={exam} />


                <Toolbar className="ma-toolbard ma-mt20" right={() => <>
                    <Button label='Previous' className='ma-m-lr10 p-button-outlined'
                        onClick={() => {
                            this.setState({
                                currentStep: 1
                            })
                        }} />
                    <Button label='Proceed' className='ma-m-lr10'
                        onClick={this.onSubmit}

                    />

                </>}

                ></Toolbar>

            </>}

            {
                this.state.isShowQuestionPreview && <PreviewQuestion
                    question={this.state.questionToPreview}
                    onHide={() => {
                        this.setState({
                            isShowQuestionPreview: false
                        })
                    }}
                />
            }
            {
                (this.state.isLoading || this.props.isLoading) && <LoadingComponent />
            }
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </div>)
    }
}


const mapsStatesToProps = (state) => {
    return {
        boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
        isLoading: state.boardsData.loading,
    };
};

export default connect(mapsStatesToProps, {
    getBoardsData,
})(withRouter(CreateQuiz));
