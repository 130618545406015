import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { Component } from 'react'
import uuidv4 from 'uuid/v4';
import './styles.scss';


export default class InstallmentsAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            installments: this.props?.selectedFeeType?.installments || [{
                id: uuidv4(),
                name: '',
                percentage: '',
                amount: '',
                dueDate: null
            }]
        }
    }

    onSaveInstallmentTypes = () => {

        let isValid = true;
        this.state.installments.forEach(instlmt => {
            if (instlmt.name.trim() == '') {
                isValid = false
            } else if (instlmt.percentage.trim() == '') {
                isValid = false
            } else if (instlmt.amount == '') {
                isValid = false
            } else if (!instlmt.dueDate) {
                isValid = false
            }
        });

        if (!isValid) {
            return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter all the fields', life: 3000 });
        }


        let totalPercentage = this.state.installments.reduce((total, installment) => {
            return total + Math.round(installment.percentage);
        }, 0)
        if (totalPercentage != 100) {
            return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Sum of percentage should be 100', life: 3000 });
        }


        this.props.addInstallments(this.props.selectedFeeType.id, this.state.installments)



    }

    onChangeText = (id, value) => {
        let feeType = this.state.installments.find(s => s.name == value)
        if (feeType) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'This fee type already added.', life: 3000 });
        } else {
            let a = this.state.installments.map(a => {
                if (a.id == id) {
                    a.name = value
                }
                return a;
            })
            this.setState({
                installments: a
            })
        }
    }

 

    onPercentageChange = (id, value) => {

        var thisObj = this;
        function returnAmount(percentage) {
            let amount = parseInt(thisObj.props.selectedFeeType.amount);
            if (amount && amount > 0) {
                return (amount * percentage) / 100
            } else {
                return 0;
            }
        }

        if (isNaN(value) || parseInt(value) < 0) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Invalid number', life: 3000 });
        } else if (parseInt(value) > 100) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Percentage should be less than 100', life: 3000 });

        } else {
            let a = this.state.installments.map(a => {
                if (a.id == id) {
                    a.percentage = value;
                    a.amount = returnAmount(value)
                }
                return a;
            })
            this.setState({
                installments: a
            })
        }
    }

    onDueDateChange = (id, value) => {
        
        let a = this.state.installments.map(a => {
            if (a.id == id) {
                a.dueDate = value
            }
            return a;
        })
        this.setState({
            installments: a
        })

    }

    installmentNameRender = (rowData) => {
        return <InputText
            value={rowData.name}
            onChange={(e) => { this.onChangeText(rowData.id, e.target.value) }}
            className='p-inputtext-style1' />
    }

    onCounterClick = (isDecrease) => {
        if (isDecrease) {
            if (this.state.installments.length > 1) {
                const installments = this.state.installments;
                installments.pop();

                this.setState((prevState) => {
                    return {
                        installments: installments
                    }
                })
            }
        } else {
            this.setState((prevState) => {
                return {
                    installments: [...prevState.installments, {
                        id: uuidv4(),
                        name: '',
                        percentage: '',
                        amount: '',
                        dueDate: null
                    }]
                }
            })
        }
    }

    installmentCounters = () => {
        return (
            <React.Fragment>
                <Button icon="pi pi-minus" onClick={() => this.onCounterClick(true)} aria-label="Decrease" />
                <span className='installment-count'>{this.state.installments.length}</span>
                <Button icon="pi pi-plus" onClick={() => this.onCounterClick(false)} aria-label="Increase" />
            </React.Fragment>
        )
    }

    amountRender = (rowData) => {
        return <InputText
            value={rowData.amount}
            disabled
            className='p-inputtext-style1' />
    }

    percentageRender = (rowData) => {
        return <InputText
            value={rowData.percentage}
            onChange={(e) => { this.onPercentageChange(rowData.id, e.target.value) }}
            className='p-inputtext-style1' />
    }

    dueDateRender = (rowData) => {
        return <Calendar showIcon={true}
            placeholder="Select Date"
            // selectionMode="range"
            // readOnlyInput
            className='ma-mt10'
            minDate={new Date()}
            value={rowData.dueDate ? new Date(rowData.dueDate) : null}
            onChange={(e) => this.onDueDateChange(rowData.id, e.value)}
        ></Calendar>
    }

    render() {

        console.log(this.state, 'abcde')
        return (<Dialog
            visible={true}
            style={{ width: '80%' }}
            header={"Installment Types"}
            modal
            footer={<div className='ma-mt20'>
                <Button label="Cancel" className='p-button-outlined'
                    onClick={this.props.onCancel}
                />
                <Button
                    label='Save'
                    className='ma-m-lr10'
                    onClick={this.onSaveInstallmentTypes} />
            </div>}
            draggable={false}
            // footer={this.footer}
            blockScroll={true}
            closeOnEscape={false}
            dismissableMask={false}
            closable={true}
            onHide={this.props.onCancel}>
            <>
            </>

            <Toolbar className="ma-toolbard" right={this.installmentCounters}></Toolbar>
            <div className="p-grid ma-ml20">
                <div className="p-c ol-12 p-md-3">
                    <b>Fee Type</b> {
                        this.props.selectedFeeType.feeType
                    }
                </div>
                <div className="p-col-12 p-md-3">
                    <b>Amount</b> {
                        this.props.selectedFeeType.amount
                    }
                </div>
            </div>
            <div className="p-col-12 p-md-12">

                <DataTable ref={(el) => this.dt = el}
                    value={this.state.installments}
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} types"
                    globalFilter={this.state.globalFilter}
                // header={header}
                >
                    <Column field="" header="Installment Name" body={this.installmentNameRender} />
                    <Column field="" header="Percentage" body={this.percentageRender} />
                    <Column field="" header="Amount" body={this.amountRender} />
                    <Column field="" header="Due Date" body={this.dueDateRender} />
                </DataTable>
            </div>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </Dialog>
        )
    }
}
