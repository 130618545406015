import * as ActionType from "../actionTypes";

export default (state = {}, action) => {
  switch(action.type){
    case ActionType.REQUEST_EDIT_MOCK_TEST_DETAILS :

        return{
            ...state,
            loading:true,
            error:false,
            errorInfo:null,
            response: null
            }
    case ActionType.RECEIVED_EDIT_MOCK_TEST_DETAILS :
        return{
            ...state,
            loading:false,
            response:action.res,
            error:false,
            errorInfo:null
        }
    case ActionType.ERROR_EDIT_MOCK_TEST_DETAILS:
        return{
            ...state,
            loading:false,
            error:true,
            errorInfo:action.err,
            response: null
        }
    case ActionType.GET_EDIT_MOCK_TEST_DETAILS:
        return{
            ...state,
             editMockTest:true,
             mockTestInfo: action.mockTestInfo
        }   
    case ActionType.CLEAR_EDIT_MOCK_TEST_DETAILS:
        return {
        }
    case ActionType.CLEAR_GET_MOCK_TEST_DETAILS:
         return{
             ...state,
             mockTestInfo:{},
             editMockTest:false,
         }    
    default : 
      return state    

  }
}