import React, { Component } from 'react'
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { ResponsiveBar } from '@nivo/bar';
import InLoadingComponent from '../../loadingComponent/inlineLoading';
import { I18Next } from './../../../internationalization';
import { connect } from 'react-redux';
import Authentication from './../../session';


class ResourceUsageReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inlineLoading: false,


        }



        this.service = new Service();


        // const userAnalyticsBySubjectT = userAnalyticsBySubject.map(item => {
        //     if (item.mp4 > 0 || item.pdf > 0 || item.zip > 0 || item.quiz > 0) {
        //         isGraphDataAvailable = true;
        //     }
        //     return { Videos: item.mp4, Pdf: item.pdf, Ppt: item.zip, Quiz: item.quiz, quizCount: item.quizCount, subjectId: item.subjectId, subjectName: item.subjectName };
        // });
    }

    getReport = () => {

        this.setState({
            inlineLoading: true
        })

        const url = `${baseUrlAdmin}/analytics/resourceUsageReport`;
        this.service.get(url, true).then(res => {


            if (res && res.status) {
                this.setState({
                    ...res.res.data,
                    inlineLoading: false
                })

            } else {
                this.setState({
                    inlineLoading: false
                });
            }
        }).catch(e => {
            this.setState({
                inlineLoading: false

            });
        })

    }



    componentDidMount() {

        this.getReport();

    }
    render() {
        const { defaultLanguage } = this.props;
        const { graphData, stats, inlineLoading } = this.state;
        const langType = defaultLanguage


        return (
            <div className='ma-relative'>
                <div className='resource-usage-report' style={{ background: "#d6edff" }}>
                    <h3 className="resource-title ma-mt10" style={{ height: '14px' }}>{I18Next('resourceUsageReport', langType).COMMON_KEY}</h3>
                    <div className='p-grid'>
                        <div className='p-col-12 p-md-11'>
                            <div className='trending-table-rup'>
                                {graphData && graphData.length &&
                                    <table className='trending-table ma-ml10'>
                                        <tr className=''>
                                            <th></th>
                                            <th className='t-head-v'>{I18Next('videos', langType).COMMON_KEY}</th>
                                            <th className='t-head-pdf'>{I18Next('pdf', langType).COMMON_KEY}</th>
                                            <th className='t-head-ppt'>{I18Next('ppt', langType).COMMON_KEY}</th>
                                            <th className='t-head-q'>{I18Next('quiz', langType).COMMON_KEY}</th>
                                        </tr>
                                        <tr>
                                            <td className='color' style={{ fontSize: 13 }}>Current week (hours)</td>
                                            <td className='ma-ml10'>{graphData[0]?.["Current Week"]}</td>
                                            <td>{graphData[1]?.["Current Week"]}</td>
                                            <td>{graphData[2]?.["Current Week"]}</td>
                                            <td>{graphData[3]?.["Current Week"]}</td>
                                        </tr>
                                        <tr>
                                            <td className='color2' style={{ fontSize: 13 }}>Previous week (hours)</td>
                                            <td>{graphData[0]?.["Last Week"]}</td>
                                            <td>{graphData[1]?.["Last Week"]}</td>
                                            <td>{graphData[2]?.["Last Week"]}</td>
                                            <td>{graphData[3]?.["Last Week"]}</td>
                                        </tr>
                                    </table>}
                            </div>

                        </div>


                        <div className='p-col-12 p-md-1'>
                            {
                                graphData && graphData.length ? <div className='usage-trend'>
                                    <p className='trend-title'>{I18Next('usageTrend', langType).COMMON_KEY}</p>
                                    <img src='./new-assets/Path_3270.png' style={{ height: '20px', marginLeft: '20px' }} />
                                    <p className='trend-mark'>+ {stats}%</p>
                                </div> : <></>
                            }

                        </div>

                    </div>
                    <div className='p-grid'>
                        <div className='p-col-12 p-md-3'>

                        </div>
                        <div className='p-col-12 p-md-3'>

                        </div>
                        <div className='p-col-12 p-md-3'>

                        </div>
                        <div className='p-col-12 p-md-3'>

                        </div>
                    </div>
                </div>
                {
                    inlineLoading && <InLoadingComponent />
                }
            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,
});

export default connect(mapStatesToProps, {})(Authentication(ResourceUsageReport));
