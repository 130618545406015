import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from './../session';
import Service from '../../services';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../store/apiConstants';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import Navbar from './../navbar/index';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { BreadCrumb } from 'primereact/breadcrumb';
import cloneDeep from 'lodash/cloneDeep';
import {
    getCurriculum,
    getActiveOrganizationData,
    selectBoard,
    selectClass,
    selectGroup,
    clearSelectedOrganizationData,
    updateSubject,
    getSubject,
    dispatchClearCurriculumData,
    startExportToOffline,
    getImageUrl,
    getBoardsData,
    updateBoardOfORG
} from './../../store/actions';
import BoardsList from './boards';
import ClassesList from './classes';
import './styles.scss'
import LoadingComponent from '../loadingComponent';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import { I18Next } from './../../internationalization';

const items = [
    { label: 'Organization' },
    { label: 'Boards Master', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

class BoardMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            groups: [],
            curriculumInfo: {},
            isShowBoardCreate: false,
            editBoardData: null,
            isBoardAdd: true,
            isShowBoards: true,
            isShowClasses: false,
            editChapterData: null,
            isShowExportToOffline: false

        }
        this.service = new Service();
    }



    componentDidMount() {

        // localStorage.setItem('super_admin', 'eyJraWQiOiJrbmpETGpcL04yT3k5M0w3Z3hVUVlJaEVjNk9UYXhrMlpFMU5LTmF6SGxoMD0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI3YmVhMDZiNi1hMDVmLTRmMDUtOGFhOS0wZjI4NDFjZDE0ZmIiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGgtMS5hbWF6b25hd3MuY29tXC9hcC1zb3V0aC0xX0Q1YVRyaVl1UCIsImNvZ25pdG86dXNlcm5hbWUiOiI3YmVhMDZiNi1hMDVmLTRmMDUtOGFhOS0wZjI4NDFjZDE0ZmIiLCJvcmlnaW5fanRpIjoiZTAyNmFhZTUtOGNjNi00Y2NmLWJhM2UtMmI5OGZlZGY4Nzc2IiwiYXVkIjoiM3NtMWVsdXRiOW9vaWhiYmZyNXZ1ZGIzMm4iLCJldmVudF9pZCI6IjljNGFmNTYyLWFmZDItNGJlYi04Mjc3LTg4MmE4NzhkMDEwYiIsImN1c3RvbTpib2FyZElkIjoiICIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjQyMTcyMjY5LCJuYW1lIjoiU3VwZXIgQWRtaW4iLCJwaG9uZV9udW1iZXIiOiIrOTE5OTk5OTk5OTk5IiwiY3VzdG9tOm9yZ0lkIjoiNjFhMGQ4ODcxZWMxOGFhNWYxOGFkODA2IiwiZXhwIjoxNjQyMTc1ODY5LCJjdXN0b206cm9sZSI6IjYxY2FlNWY2MWNmOWRiNzcyY2FhODZhZiIsImlhdCI6MTY0MjE3MjI2OSwianRpIjoiMDc0MjE3NTktMGIwOC00MmY1LWJmMmEtYTc3ZTQwMzdiODIyIiwiZW1haWwiOiJzdXBlcmFkbWluQGdtYWlsLmNvbSIsImN1c3RvbTppc0FkbWluIjoidHJ1ZSJ9.CfqhAyPE0V1wcaPwo4RT1aWikDrABBetbsGGWYAizWAUeYVZnlGL7NtQt4mfd5QpisZUPDFSFAQSljqzyhc_3VmygHOmf1jYkfQ79yRkCBBsVOxCMz6Ff-vZgsUxoulBUUFEWweiAIjTxghLA81WGFMCf8sBuM-HtH2LHqkgnSFHK_7UIy0c26IXf0IvxegrBdWJOhhKeuQyXZ2Ah2d2WuKNrne2Pb3wwnt1eJX1v3XYE3kC20hqRNgxVoZ63gsKNPCeW73l1Odnibj4NeQrBtLAxxpikAnVpJaLZrNslvOgtOEtMJiXe1wNXXa6kxpE7DXb-Z9gWYT-v-sHVTBClA')
        // localStorage.setItem('Bearer', 'eyJraWQiOiJrbmpETGpcL04yT3k5M0w3Z3hVUVlJaEVjNk9UYXhrMlpFMU5LTmF6SGxoMD0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI3YmVhMDZiNi1hMDVmLTRmMDUtOGFhOS0wZjI4NDFjZDE0ZmIiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGgtMS5hbWF6b25hd3MuY29tXC9hcC1zb3V0aC0xX0Q1YVRyaVl1UCIsImNvZ25pdG86dXNlcm5hbWUiOiI3YmVhMDZiNi1hMDVmLTRmMDUtOGFhOS0wZjI4NDFjZDE0ZmIiLCJvcmlnaW5fanRpIjoiZTAyNmFhZTUtOGNjNi00Y2NmLWJhM2UtMmI5OGZlZGY4Nzc2IiwiYXVkIjoiM3NtMWVsdXRiOW9vaWhiYmZyNXZ1ZGIzMm4iLCJldmVudF9pZCI6IjljNGFmNTYyLWFmZDItNGJlYi04Mjc3LTg4MmE4NzhkMDEwYiIsImN1c3RvbTpib2FyZElkIjoiICIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjQyMTcyMjY5LCJuYW1lIjoiU3VwZXIgQWRtaW4iLCJwaG9uZV9udW1iZXIiOiIrOTE5OTk5OTk5OTk5IiwiY3VzdG9tOm9yZ0lkIjoiNjFhMGQ4ODcxZWMxOGFhNWYxOGFkODA2IiwiZXhwIjoxNjQyMTc1ODY5LCJjdXN0b206cm9sZSI6IjYxY2FlNWY2MWNmOWRiNzcyY2FhODZhZiIsImlhdCI6MTY0MjE3MjI2OSwianRpIjoiMDc0MjE3NTktMGIwOC00MmY1LWJmMmEtYTc3ZTQwMzdiODIyIiwiZW1haWwiOiJzdXBlcmFkbWluQGdtYWlsLmNvbSIsImN1c3RvbTppc0FkbWluIjoidHJ1ZSJ9.CfqhAyPE0V1wcaPwo4RT1aWikDrABBetbsGGWYAizWAUeYVZnlGL7NtQt4mfd5QpisZUPDFSFAQSljqzyhc_3VmygHOmf1jYkfQ79yRkCBBsVOxCMz6Ff-vZgsUxoulBUUFEWweiAIjTxghLA81WGFMCf8sBuM-HtH2LHqkgnSFHK_7UIy0c26IXf0IvxegrBdWJOhhKeuQyXZ2Ah2d2WuKNrne2Pb3wwnt1eJX1v3XYE3kC20hqRNgxVoZ63gsKNPCeW73l1Odnibj4NeQrBtLAxxpikAnVpJaLZrNslvOgtOEtMJiXe1wNXXa6kxpE7DXb-Z9gWYT-v-sHVTBClA')
        //if (!this.props.boards || !this.props.boards.length) {
        this.props.getBoardsData(true);
        //} else {
        // if (this.props.boards) {
        //     let boards = [];
        //     this.props.boards &&
        //         this.props.boards.map((item, index) => {
        //             boards.push({ value: item.boardId, label: item.boardName });
        //         });
        //     this.setState({
        //         boards: boards
        //     });
        // }
        //}
    }

    componentDidUpdate(prevProps) {
        if (prevProps.boards !== this.props.boards) {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });

                this.setState({
                    boards: boards
                });
            }
        }

        if (prevProps.isUpdated !== this.props.isUpdated && this.props.isUpdated) {

            let editBoardData = this.state.editBoardData ? cloneDeep(this.state.editBoardData) : null;
            if (editBoardData) {
                this.props.boards.forEach((b) => {
                    if (b.boardId == editBoardData.boardId) {
                        editBoardData.classes = b.classes;

                    }
                })
            }

            this.setState({
                isShowBoardCreate: false,
                editBoardData
            });

            this.toast.show({ severity: 'success', summary: 'Updated', detail: 'Successfully Added Or Updated', life: 3000 });
        }

        if (prevProps.updateError !== this.props.updateError && this.props.updateError) {

            this.toast.show({ severity: 'error', summary: 'Unable to update', detail: this.props.updateErrorMessage, life: 3000 });

        }
    }


    goBackToBoards = () => {
        this.setState({
            isShowClasses: false,
            isShowBoards: true
        });
    }

    showEditBoardDialog = (editBoardData) => {
        this.setState({
            editBoardData: editBoardData,
            isBoardAdd: false,
            isShowBoardCreate: true
        })
    }

    showCreateBoard = () => {
        this.setState({ isShowBoardCreate: true, editBoardData: null, isBoardAdd: true });
    }

    hideCreateBoardDialog = () => {
        this.setState({
            isShowBoardCreate: false,
            editBoardData: null,
            isBoardAdd: true
        })
    }

    showClasses = (board) => {
        this.setState({
            editBoardData: board,
            isShowBoards: false,
            isShowClasses: true
        });
    }


    addOrUpdateBoardsData = (boards) => {
        this.props.updateBoardOfORG(boards)
    }


    addOrUpdateClassInfo = (classes) => {
        let boards = cloneDeep(this.props.boards);

        boards = boards.map((board) => {
            if (board.boardId == this.state.editBoardData.boardId) {
                board.classes = classes;
            }
            return board;
        });

        this.addOrUpdateBoardsData(boards)
    }



    onGoBack = () => {

        if (this.state.isShowClasses) {
            this.goBackToBoards();
        }

    }

    rightToolbarTemplate = () => {
        const { defaultLanguage } = this.props;
        const langType = defaultLanguage
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>{I18Next('boardMaster', langType).COMMON_KEY}</h2>
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    exportDialogFooter = () => {
        return (<div>
            <Button label="Proceed" className='ma-m-lr10' onClick={this.startExportToOffline} />
            <Button label="Cancel" className='p-button-outlined' onClick={() => { this.setState({ isShowExportToOffline: false }) }} />
        </div>)
    }


    render() {
        const { defaultLanguage } = this.props;
        const langType = defaultLanguage
   
        return (
            < >
                <Navbar >
                    <div className="p-grid ma-toolbar">
                        <div className="p-col-12 p-md-1 ma-no-p">
                            {(this.state.isShowClasses) && <>
                                <p className='back-arrow' onClick={this.onGoBack}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                            </>
                            }
                        </div>
                        <div className="p-col-12 p-md-11 ma-no-pm">
                            <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        </div>
                    </div>
                    <div className='ma-main'>
                        {this.state.isShowBoards && <div >

                            <BoardsList
                                showClasses={this.showClasses}
                                addOrUpdateBoardsData={this.addOrUpdateBoardsData}
                                isBoardAdd={this.state.isBoardAdd}
                                showCreateBoard={this.showCreateBoard}
                                editBoardData={this.state.editBoardData}
                                showEditBoardDialog={this.showEditBoardDialog}
                                boards={this.props.boards}
                                isShowBoardCreate={this.state.isShowBoardCreate}
                                hideCreateBoardDialog={this.hideCreateBoardDialog} />
                        </div>
                        }
                        {
                            this.state.isShowClasses && <>
                                <ClassesList
                                    addOrUpdateClassInfo={this.addOrUpdateClassInfo}
                                    classes={(this.state.editBoardData && this.state.editBoardData.classes) ? this.state.editBoardData.classes : []}
                                    boardId={this.state.editBoardData?.boardId}
                                    parentBoardId={this.state.editBoardData?.parentBoardId}
                                    syncEnabled={this.props.syncEnabled}
                                />
                            </>
                        }

                    </div>
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                    {
                        this.props.isLoading && <>
                            <LoadingComponent />
                        </>
                    }
                </Navbar>
            </>
        )
    }
}



const mapStateToProps = (state) => ({
    loading: state.curriculumData && state.curriculumData.loading,
    error: state.curriculumData && state.curriculumData.error && state.curriculumData.error,
    errorInfo: state.curriculumData && state.curriculumData.errorInfo && state.curriculumData.errorInfo,
    subjectData:
        state.curriculumData &&
        state.curriculumData.response &&
        state.curriculumData.response.Item &&
        state.curriculumData.response.Item.subjects &&
        state.curriculumData.response.Item.subjects,
    offlineExportStatus: state.curriculumData &&
        state.curriculumData.response &&
        state.curriculumData.response.Item &&
        state.curriculumData.response.Item.offlineExportStatus &&
        state.curriculumData.response.Item.offlineExportStatus,
    organizationLoading: state.organizationData && state.organizationData.loading,
    organizationError: state.organizationData && state.organizationData.error,
    organizationErrorInfo: state.organizationData && state.organizationData.errorInfo,
    organizationData: state.organizationData && state.organizationData.response && state.organizationData.response,
    boardsInfo:
        state.organizationData &&
        state.organizationData.response &&
        state.organizationData.response.Item &&
        state.organizationData.response.Item,
    selectedOrganizationDataIds: state.selectedOrganizationDataIds && state.selectedOrganizationDataIds,
    updateSubjectResponse: state.updateSubject && state.updateSubject.response && state.updateSubject.response,
    subjectsUpdateError: state.updateSubject && state.updateSubject.error && state.updateSubject.error,
    updateSubjectLoading: state.updateSubject && state.updateSubject.loading && state.updateSubject.loading,
    getAllImagesUrlInfo: state.getImageUrlInfo && state.getImageUrlInfo.allImageUrls,

    syncEnabled: state.orgData.syncEnabled,

    boards: state.boardsData.boards,
    isLoading: state.boardsData.isLoading,
    isUpdated: state.boardsData.isUpdated,
    boardsData: state.boardsData,
    updateError: state.boardsData.error,
    updateErrorMessage: state.boardsData.errorMessage,
    defaultLanguage: state.languageReducer.language,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});


export default connect(mapStateToProps, {
    // getCurriculum,
    // getActiveOrganizationData,
    // selectBoard,
    // dispatchClearCurriculumData,
    // selectClass,
    // selectGroup,
    // updateSubject,
    // getSubject,
    //clearSelectedOrganizationData,
    startExportToOffline,
    getImageUrl,
    getBoardsData,
    updateBoardOfORG,
   
})(Authentication(withRouter(BoardMaster)));

//Authentication