import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import React, { Component } from 'react'
import { getFormFields, onTextChange } from '../../utile/formHelper';
import LoadingComponent from '../loadingComponent';
import Admission from './admission';
import CreateEnquiry from './createEnquiry';
import registrationFields from './registrationFields.json'

export default class Registation extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(registrationFields);
        this.state = {
            registrationFieldsS: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isLoading: false,
            paymentMode: '',
            admissionForm: false,
            registrationForm: true
        }
    }

    componentDidMount() {
        console.log(this.props, 'reg')
    }

    proceedToAdmisssion = () => {
        this.setState({
            registrationForm: false,
            admissionForm: true,
            enquiryFormData : '',
            registrationFormDetails : ''
        })
    }

    render() {
        const { formValidations, registrationFieldsS } = this.state

        return (
            <>
                {this.state.registrationForm &&
                    <>
                        <div>
                            <CreateEnquiry enquiryFormData={this.props.enquiryFormData} />
                        </div>
                        <div className="p-card ma-w100p ma-p20 p-mt-5">
                            <div className="p-col-12">
                                <h2 className='enquiry-header'>Registration</h2>
                            </div>

                            <div className="p-col-12 p-md-4">
                                <p className='ma-label-s2'>Application Number<span className='ma-required'>*</span></p>
                                <InputText
                                    className='p-inputtext-style1 ma-w70p'
                                    value={registrationFieldsS.applicationNumber}
                                    onChange={(e) => onTextChange(e.target.value, 'applicationNumber', this, registrationFields, registrationFieldsS, formValidations, 'registrationFieldsS', 'formValidations')}
                                />
                                {formValidations && !formValidations.fields['applicationNumber'].isValid && <p className="p-error">{formValidations.fields['applicationNumber'].errorMsg}</p>}
                            </div>

                            <div className="p-col-12 p-md-4">
                                <p className='ma-label-s2'>Application Fee<span className='ma-required'>*</span></p>
                                <InputText
                                    className='p-inputtext-style1 ma-w70p'
                                    value={registrationFieldsS.applicationFee}
                                    onChange={(e) => onTextChange(e.target.value, 'applicationFee', this, registrationFields, registrationFieldsS, formValidations, 'registrationFieldsS', 'formValidations')}
                                />
                                {formValidations && !formValidations.fields['applicationFee'].isValid && <p className="p-error">{formValidations.fields['applicationFee'].errorMsg}</p>}
                            </div>

                            <div className="p-col-12 p-md-4">
                                <p className='ma-label-s2'>Registation Fee<span className='ma-required'>*</span></p>
                                <InputText
                                    className='p-inputtext-style1 ma-w70p'
                                    value={registrationFieldsS.registationFee}
                                    onChange={(e) => onTextChange(e.target.value, 'registationFee', this, registrationFields, registrationFieldsS, formValidations, 'registrationFieldsS', 'formValidations')}
                                />
                                {formValidations && !formValidations.fields['registationFee'].isValid && <p className="p-error">{formValidations.fields['registationFee'].errorMsg}</p>}
                            </div>
                            <div className="p-ml-2">
                                <p className='ma-label-s2'>Payment Mode<span className='ma-required'>*</span></p>
                                <div className="p-grid p-mt-1">
                                    <div className="p-col-1 p-md-1">
                                        <RadioButton
                                            inputId="paymentMode1" name="paymentMode" value="cash"
                                            onChange={(e) => this.setState({ paymentMode: e.value })}
                                            checked={this.state.paymentMode === 'cash'} />
                                        <label htmlFor="Cash" className="p-ml-2">Cash</label>
                                    </div>
                                    <div className="p-col-1 p-md-1">
                                        <RadioButton
                                            inputId="paymentMode2" name="paymentMode" value="cheque"
                                            onChange={(e) => this.setState({ paymentMode: e.value })}
                                            checked={this.state.paymentMode === 'cheque'} />
                                        <label htmlFor="Cheque" className="p-ml-2">Cheque</label>
                                    </div>
                                    <div className="p-col-1 p-md-1">
                                        <RadioButton
                                            inputId="paymentMode3" name="paymentMode" value="credit/debit"
                                            onChange={(e) => this.setState({ paymentMode: e.value })}
                                            checked={this.state.paymentMode === 'credit/debit'} />
                                        <label htmlFor="Credit/Debit" className="p-ml-2">Credit/Debit</label>
                                    </div>
                                    {/* {formValidations && !formValidations.fields['paymentMode'].isValid && <p className="p-error">{formValidations.fields['paymentMode'].errorMsg}</p>} */}
                                </div>
                            </div>
                            <div className='p-mt-3 p-ml-2'>
                                <Button label='Exit' />
                                <Button label='Continue for Admission' className='p-ml-3' onClick={this.proceedToAdmisssion} disabled = {this.props.registrationFormDetails == "" ? true : false}/>
                            </div>
                        </div>
                    </>}

                {this.state.admissionForm && <Admission enquiryFormData = {this.state.enquiryFormData} registrationFormDetails = {this.state.registrationFormDetails}/>}

                {this.state.isLoading && <LoadingComponent />}
            </>
        )
    }
}
