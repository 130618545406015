import React from 'react';
import './login.scss';
import logoImg from '../../images/logo.png';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { userSignIn, clearSignInData, getUserData } from './../../store/actions';
import Authentication from './../session';
import { Link, withRouter } from 'react-router-dom';
import LoadingComponent from '../loadingComponent';
const INITIAL_STATE = {
    number: '',
    password: '',
    error: null,
    passwordErr: null
};

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE
        };
    }

    isVaildNumber = (mobile) => {
        if (/^[0-9\b]+$/.test(mobile)) {
            return true;
        } else {
            return false;
        }
    };

    componentDidMount() {
        if (this.props.authenticated) {
            this.props.history.push('/');
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.authenticated !== this.props.authenticated) {
            if (this.props.authenticated) {
                this.props.getUserData();
                setTimeout(() => {
                    this.props.history.push('/');
                }, 10);
            }
        }
    }

    userSignIn = () => {
        // const number = "+919052565771"  //'shashi2puppy'// `+91${this.state.number}`;
        // const pw ="Lodu@9052565771"       /// 'Dinesh@123'  // this.state.password;
        // const number = `+91${this.state.number}`;
        const pw = this.state.password;
        this.props.userSignIn((this.props.orgCode ? this.props.orgCode : '') + this.state.number, pw);
    };
    
    render() {
        const { userSignIn, number, password, numberErr, passwordErr } = this.state;
        // console.log(this.props.orgCode)
        const showBtn = number === '' || password === '';
        //|| !this.isVaildNumber(number)
        const { logoRectangle } = this.props;
        return (
            <div id="login">
                <div className="">
                    <div className="p-grid">
                        <div className="p-col- mx-auto border_total paper-s1">
                            <div className="text-center mb-5">
                                {logoRectangle?.storageLocation && <img src={`${logoRectangle?.storageLocation ? logoRectangle?.storageLocation : ""}`}
                                    //'./images/macademy_logo.PNG' 
                                    alt="" className="img-fluid" />}
                            </div>
                            <div>
                                <div className="col-12">
                                    <label className='ma-label-s1'>User Name <span className='ma-required'>*</span></label>
                                    <input
                                        onChange={(e) => {
                                            // const value = e.target.value;
                                            // if (/([a-zA-Z!-*' ', ; . @ ^ /'])$/g.test(value)) {
                                            //     return null;
                                            // }
                                            this.setState({ number: e.target.value });
                                        }}
                                        // onBlur={() => {
                                        //     number.length != 10 || number === '' || !this.isVaildNumber(number)
                                        //         ? this.setState({
                                        //             numberErr: 'Mobile number should be 10 digits'
                                        //         })
                                        //         : this.setState({ numberErr: null });
                                        // }}
                                        value={number}
                                        ref="number"
                                        type="text"
                                        className="input-1"
                                        placeholder="username"
                                    />
                                    <div className="ma-danger">{numberErr ? 'Please enter mobile number' : ' '}</div>
                                </div>
                                <div className="col-12 mt-3">
                                    <label className='ma-label-s1'>Password <span className='ma-required'>*</span></label>
                                    <input
                                        onChange={(e) => this.setState({ password: e.target.value })}
                                        onBlur={() =>
                                            password === ''
                                                ? this.setState({ passwordErr: 'please enter the password' })
                                                : this.setState({ passwordErr: '' })
                                        }
                                        value={password}
                                        ref="password"
                                        type="password"
                                        className="input-1"
                                        placeholder="Password"
                                    />
                                    <div className="ma-danger">{passwordErr ? 'Please enter password' : ' '}</div>
                                </div>
                                <div className="text-center text-warning">
                                    {this.props.errMsg && this.props.errMsg.message ? this.props.errMsg.message : null}
                                </div>
                                <div style={{ textAlign: 'center', color: 'red' }}>{this.props.errMsg && this.props.errMsg}</div>
                                <div className="mt-3 pl-3 pr-3">
                                    {/* <button
                                        // disabled={showBtn}
                                        className="btn btn-md btn-primary text-white login_btn"
                                        onClick={() => this.userSignIn()}
                                    >
                                        LOGIN
                                    </button> */}
                                    <Button
                                        disabled={showBtn}
                                        className='login_btn ma-w100p'
                                        label="Login"
                                        onClick={() => this.userSignIn()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (this.props.isLoading || this.props.loading) && <LoadingComponent />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.login && state.login,
    authenticated: state.auth && state.auth.authenticated,
    loading: state.signInData && state.signInData.loading && state.signInData.loading,
    error: state.signInData && state.signInData.error && state.signInData.error,
    errMsg: state.signInData && state.signInData.errMsg && state.signInData.errMsg.message,
    logoRectangle: state.orgData.logoRectangle,
    orgName: state.orgData.name,
    orgCode: state.orgData.orgCode,
    isLoading: state.orgData.isLoading,
    isSuperOrg: state.orgData.isSuper,
});

export default connect(mapStateToProps, { userSignIn, clearSignInData, getUserData })(
    Authentication(withRouter(LoginPage))
);
