import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { connect } from 'react-redux';
import React, { Component } from 'react'
import Authentication from './../session';
import { baseUrlAdmin } from '../../store/apiConstants';
import {  warningDailogInit } from '../../utile';
import LoadingComponent from '../loadingComponent';
import Service from './../../services';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import Authorizer, { PERMISSIONS, isAuthorized } from '../session/authorizer';
import Navbar from '../navbar';
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import CreateNotification from './createNotification';
import './styles.scss';
import moment from 'moment';
import { Menu } from 'primereact/menu';
import { Checkbox } from 'primereact/checkbox';
import { withRouter } from 'react-router-dom';
const items = [
    { label: 'Support' },
    { label: 'Notification Management', className: 'ma-active-item' },
];

const home = { icon: 'pi pi-home' }

const notificationItems = [
    { key: 'o1', label: 'Support' },
    { key: 'o2', label: 'Notification Management', className: 'ma-active-item' }
];

const createNotificationItems = [
    { key: 'co1', label: 'Support' },
    { key: 'co2', label: 'Notification Management' },
    { key: 'co3', label: 'Create Notification', className: 'ma-active-item' }

];
const editNotificationItems = [
    { key: 'co1', label: 'Support' },
    { key: 'co2', label: 'Notification Management' },
    { key: 'co3', label: 'Edit Notification', className: 'ma-active-item' }

];

const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: '',
    sortField: 'createdAt',
    sortOrder: -1,
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'country.name': { value: '', matchMode: 'contains' },
        'company': { value: '', matchMode: 'contains' },
        'representative.name': { value: '', matchMode: 'contains' },
    }
}


class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: notificationItems,
            notifications: [],
            warningDialog: warningDailogInit,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            isShowCreate: false,
            isLoading: false,
            columns: [
                { columnName: 'No of Users', isVisible: true, id: 'usersCount' },


            ],
        }
        this.service = new Service();
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }


    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }


    exportCSV = () => {
        this.dt.exportCSV();
    }


    componentDidMount() {
        this.getNotifications();
    }

    getNotifications = () => {
        this.setState({
            isLoading: true
        });
        let { lazyParams, globalSearch } = this.state;

        const url = `${baseUrlAdmin}/notification-manager?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.service.get(url, true).then(res => {

            if (res && res.res && res.res.status) {
                this.setState({
                    notifications: res.res.data,
                    totalRecords: res.res.totalRecords,
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
          //  console.log(res);


        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });

            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getNotifications);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getNotifications);
    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getNotifications);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.getNotifications);
    }

    showCreateNotification = () => {
        this.setState({
            isShowCreate: true,
            editOrganization: null,
            items: createNotificationItems
        });


    }

    onEditOrgClick = (editOrganization) => {
        this.setState({
            editOrganization,
            isShowCreate: true,
            items: editNotificationItems
        });
    }


    onAssignSuccess = () => {
        this.setState({
            isShowCreate: false,
            items: notificationItems
        }, () => { this.getNotifications() })
        this.toast.show({ severity: 'success', summary: 'Success', detail: 'Notification is created', life: 3000 });
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Notification Management</h2>
            </React.Fragment>
        )
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }
    render() {
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >Notifications</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                        onChange={this.onGlobalSearch}
                        value={this.state.globalSearch}
                    />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.NOTIFICATION_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>

                        {/* <Authorizer permId={PERMISSIONS.NOTIFICATION_FILTER} >
                            <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" />
                        </Authorizer> */}
                        <Authorizer permId={PERMISSIONS.NOTIFICATION_VIEW_COLUMNS} >
                            <li><i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.NOTIFICATION_DOWNLOAD} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />

                            <Menu
                                className='table-filter-w'
                                model={[
                                    {
                                        template: (<div className='table-filter'>
                                            <h4 className="filter-title">View Columns</h4>
                                            <ul>
                                                {this.state.columns.map((column) => {
                                                    return <li onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                                        checked={column.isVisible}
                                                    ></Checkbox>
                                                        <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                                    </li>
                                                })}
                                            </ul>
                                        </div>)
                                    }]
                                }
                                popup
                                ref={el => this.menu = el} />


                        </Authorizer>
                    </ul>
                </div>
            </div>
        );

        const { isShowCreate } = this.state;
        return (
            <div>
                <Navbar >
                    <div className="p-grid ma-toolbar">
                        <div className="p-col-12 p-md-1 ma-no-p">
                            {(this.state.isShowCreate) && <>
                                <p className='back-arrow' onClick={() => {
                                    this.setState({
                                        isShowCreate: false,
                                        items: notificationItems
                                    })
                                }}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                            </>
                            }
                        </div>
                        <div className="p-col-12 p-md-11 ma-no-pm">
                            <Toolbar className="ma-toolbard"
                                left={() => {
                                    return (<>  <BreadCrumb model={this.state.items} home={home} />
                                    </>)
                                }}
                                right={() => {
                                    return (<h2 className='ma-tab-title'>Notification Management</h2>)
                                }}
                            ></Toolbar>
                        </div>
                    </div>
                    <div className='ma-main'>


                        {
                            isShowCreate ? <>
                                <CreateNotification onAssignSuccess={this.onAssignSuccess} />
                            </>
                                :
                                <>
                                    <Authorizer permId={PERMISSIONS.NOTIFICATION_ADD} >
                                        <Button label={`+ Add`} className='rol-add-btn' onClick={this.showCreateNotification} />
                                    </Authorizer>
                                    <div className="card datatable-crud-demo ma-m30 fetch-q-tabled">
                                        <DataTable ref={(el) => this.dt = el}
                                            scrollable
                                            responsiveLayout="scroll"
                                            value={this.state.notifications}
                                            dataKey="_id"
                                            lazy
                                            onSort={this.onSort}
                                            onPage={this.onPage}
                                            onFilter={this.onFilter}
                                            first={this.state.lazyParams.first}
                                            last={this.state.totalRecords}
                                            rows={this.state.lazyParams.rows}
                                            totalRecords={this.state.totalRecords}
                                            paginator
                                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                            globalFilter={this.state.globalFilter}
                                            header={header}

                                        >

                                            <Column headerClassName='difficulty' field="name" header="Name" />
                                            <Column headerClassName='difficulty' sortField='user_info.name' field="name" header="Date and Time" body={(rowData) => {
                                                return <>
                                                    {moment(rowData.scheduleTime).format('MMM Do YYYY, h:mm:ss a')}</>
                                            }} />
                                            <Column headerClassName='difficulty' field="type" header="Notification Type" />
                                            {
                                                this.isColumnVisible('usersCount') && <Column headerClassName='difficulty' field="usersCount" header="No of Users" />}

                                            <Column headerClassName='difficulty' field="mode" header="Notification Mode" />

                                        </DataTable>

                                    </div>
                                </>
                        }
                    </div>

                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                    {
                        (this.state.isLoading || this.props.isLoading) && <>
                            <LoadingComponent />
                        </>
                    }
                </Navbar >
            </div >


        )
    }




}




const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, {})(Authentication(withRouter(Notification)));
