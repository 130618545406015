import React, { Component } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { TestsForReports } from '../constants';
import { Toast } from 'primereact/toast';

import { Button } from 'primereact/button';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import Service from '../../../services';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../../store/apiConstants';
import QuestionWiseReport from './adhockReports/questionWiseReport';
import SubjectWiseReport from './adhockReports/subjectWiseReport';
import SubjectQuestionTypeWiseReport from './adhockReports/subjectQuestionTypeWiseReport';
import LoadingComponent from '../../loadingComponent';
import { FIELDS_INFO } from '../../../constants';
import InputTextB from '../../customComponents/inputTextB';
import StundentReport from './studentReport';
import { MultiSelect } from 'primereact/multiselect';
import Searchfields from './searchfields';


const InitialFilters = {
    timeRange: { from: 0, to: 24 },
    subjectId: null,
    contentType: null
};


export default class AdhocMockTest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            examType: props.editExamType ? props.editExamType : '',
            exams: [],
            examsResult: null,
            filters: InitialFilters,
            filterData: null,
           
           
        };

        this.service = new Service();
            }



    componentDidMount() {


        this.getExams();

    }


    getExams = () => {
        this.setState({
            isLoading: true
        })


        this.service.get(`${baseUrlAdmin}/examination/getallexams/examtype/AdhocMockTest`, true).then(data => {
            if (data && data.res && data.res.Items) { 
                this.setState({
                    exams: data.res.Items,
                    isLoading: false
                });
            } else {
                this.setState({
                    exams: [],
                    isLoading: false
                });

                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: '', life: 3000 });
            }

        }).catch(e => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    }


    getUrl = (reportType, examId) => {

        switch (reportType) {
            case 1:
                return `${baseUrlForLongRunningTask}/results/get-all/test/${examId}/results`;
            case 2:
                return `${baseUrlForLongRunningTask}/results/get/test/${examId}/marking`;
            case 3:
                return `${baseUrlForLongRunningTask}/results/get/test/${examId}/report3`;
            case 4:
                return `${baseUrlForLongRunningTask}/results/get/test/${examId}/results`;
            default:
                return '';
        }
    }

     

    onSearchClick = (data) => {

        if(data?.boardId === null &&data?.classId === null && data?.groupId===null && data?.branchId===null){
            this.setState({filterData:{}})
        }
        else{
              this.setState((prevState) => ({ filterData: { ...prevState.filterData, ...data } }))
        }

      

        this.setState({
            reportType: '',
        })
    }


    getReport = (reportType) => {
        
       const { examId } = this.state;


        if (examId) {
            this.setState({
                isLoading: true,
                reportType,
                examsResult: null
            })
            let searchValidation = true
            const payload = {
                boardId: this.state.filterData?.boardId,
                classId: this.state.filterData?.classId,
                sectionId: this.state.filterData?.sectionIds
            }
           

            if(this.state.filterData?.boardId){
                searchValidation =  this.state.filterData?.classId === null ? false : true
            }
            if(this.state.filterData?.sectionDataValue){
                searchValidation =  this.state.filterData?.sectionIds?.length === 0 ? false : true
            }

            
          

       if(searchValidation){
            const url = this.getUrl(reportType, examId)

            if(reportType !== 4){
            this.service.post(url, payload, true).then(data => {
              if (data && data.res && data.res.examResult) {
                    this.setState({
                        examsResult: data?.res?.examResult,
                        isLoading: false
                    });
                } else {

                    this.setState({
                        isLoading: false
                    })
                }



            }).catch(e => {

                this.setState({
                    isLoading: false
                })


            })
           }
          else{
              this.service.get(url, true).then(data => {
                  if (data && data.res && data.res.examResult) {
                        this.setState({
                            examsResult: data?.res?.examResult,
                            isLoading: false
                        });
                    } else {
    
                        this.setState({
                            isLoading: false
                        })
                    }
    
    
    
                }).catch(e => {
    
                    this.setState({
                        isLoading: false
                    })
    
    
                })
             }
       
        }
        else{
            this.setState({
                isLoading: false
            })
            this.state.filterData?.sectionIds?.length === 0 && this.toast.show({ severity: 'error', summary: 'Error', detail: "Select section", life: 2000 });
            this.state.filterData?.classId === null && this.toast.show({ severity: 'error', summary: 'Error', detail: "Select class", life: 2000 });
        }
    }

        else {
            this.toast.show({ severity: 'error', summary: 'Select Exam', detail: "", life: 2000 });
        }

    }

    

    render() {
        const { examsResult } = this.state;
        
        return (<>
            <InputTextB info={FIELDS_INFO.EXAM_SELECT} id="acd">
                <Dropdown value={this.state.examId}
                    className='ma-w200'
                    options={this.state.exams}
                    optionLabel='examName'
                    optionValue='examId'
                    onChange={(e) => this.setState({ examId: e.value, reportType: ""})}
                    placeholder="Select Exam" />
            </InputTextB>
            <div style={{ marginLeft: "-0px", marginTop: "-5px" }}>
                <Searchfields filterData={this.state.filterData} classError1={this.state.classError1} filterUsersData={this.onSearchClick} />
            </div>
            <div className='ma-mt20'>
                <Authorizer permId={PERMISSIONS.EXAM_RESULT_ADHOC_QUESTION_WISE} >
                    <Button label="Question Wise" onClick={() => {
                        this.getReport(1)
                    }} className={`ma-mr10 ${this.state.reportType === 1 ? '' : 'p-button-outlined'}`} />
                </Authorizer>
                <Authorizer permId={PERMISSIONS.EXAM_RESULT_ADHOC_SUBJECT_WISE} >
                    <Button label="Subject Wise" onClick={() => {
                        this.getReport(2)
                    }} className={`ma-mr10  ${this.state.reportType === 2 ? '' : 'p-button-outlined'}`} />
                </Authorizer>
                <Authorizer permId={PERMISSIONS.EXAM_RESULT_ADHOC_SUBJECT_AND_Q_WISE} >
                    <Button label="Subject and Question Type Wise" onClick={() => {
                        this.getReport(3)
                    }} className={`${this.state.reportType === 3 ? '' : 'p-button-outlined'}`} />
                </Authorizer>
                <Authorizer permId={PERMISSIONS.EXAM_RESULT_ADHOC_SUBJECT_WISE} >
                    <Button label="Student Report" onClick={() => {
                        this.getReport(4)
                    }} className={`ma-ml10 ${this.state.reportType === 4 ? '' : 'p-button-outlined'}`} />
                </Authorizer>
            </div>
            {this.state.reportType === 1 && (
                <QuestionWiseReport examsResult={examsResult} />
            )}
            {this.state.reportType === 2 && (
                <SubjectWiseReport examsResult={examsResult} />
            )}
            {this.state.reportType === 3 && (
                <SubjectQuestionTypeWiseReport examsResult={examsResult} />
            )}
            {this.state.reportType === 4 && (
                <StundentReport examsResult={examsResult} examId = {this.state.examId}/>
            )}

            {
                this.state.isLoading && <>
                    <LoadingComponent />
                </>
            }
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </>)
    }
}
