import React from 'react';
import DeleteMessage from './deleteMessage';
import { Dialog } from 'primereact/dialog';
import moment from "moment";
import { MESSAGE_STATUS } from './../../../utile';



const TEACHER_ID = localStorage.getItem('discussion_uId');

class ImageView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showImagePop: false,
            attachmentURI: null
        }
    }

    render() {
        const { conversation } = this.props;
        return (
            <div className={`ask-wr ${conversation.direction == 2 ? 'ma-right' : 'ma-left1'}`}>
                <img src={conversation.content} className="conversation-img"
                    onClick={() => { this.setState({ showImagePop: true, attachmentURI: conversation.content }) }}

                />
                {(conversation.direction == 2 && conversation.from._id == this.props.discussionUser._id) && <DeleteMessage conversation={conversation} deleteMessage={this.props.deleteMessage} />}

                <div className="ma-clearFix"> </div>
                <span className={`message-time  ${conversation.direction == 2 ? '' : 'ma-right'}`}>{moment(conversation.createdAt).format('lll')} {conversation.direction == 2 && (conversation.status == MESSAGE_STATUS.sent ? <i className="pi pi-check sent-icon" /> : conversation.status == MESSAGE_STATUS.sending ? <i className="pi pi-check sent-icon" /> : ' ')}</span>


                <Dialog
                    blockScroll={true}
                    visible={this.state.showImagePop}
                    onHide={() => { this.setState({ showImagePop: false }) }}
                    // closable={false}
                >
                    <img src={this.state.attachmentURI} className="popup-img" />
                    
                </Dialog>
            </div>
        );
    }
}

export default ImageView;
