import * as ActionTypes from '../actionTypes';
import axios from 'axios';
import { baseUrlAdmin } from '../apiConstants';

export const getRolesRequested = () => ({ type: ActionTypes.GET_ROLES_REQUESTED });
const getRolesReqSucceeded = (data) => ({ type: ActionTypes.GET_ROLES_SUCCEEDED, data });
const getRolesRequestFailed = () => ({ type: ActionTypes.GET_ROLES_FAILED });

const url = `${baseUrlAdmin}/authorize/getRoles`;

const getRolesDetails = () => {
  return axios({
    method: 'get',
    url,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
    }
  });
};

export const getRolesList = () => (dispatch) => {
  dispatch(getRolesRequested());
  getRolesDetails()
    .then((res) => {
      if (res.data) {
        dispatch(getRolesReqSucceeded(res.data.data));
      } else {
        dispatch(getRolesRequestFailed());
      }
    })
    .catch((e) => {
      if (e.response && e.response.status == 401) {
        localStorage.clear();
        window.location.reload();
      } else {
        dispatch(getRolesRequestFailed());
      }
    });
};

const addRolesRequested = () => ({ type: ActionTypes.ADD_ROLES_REQUESTED });
const addRolesReqSucceeded = () => ({ type: ActionTypes.ADD_ROLES_SUCCEEDED });
const addRolesRequestFailed = () => ({ type: ActionTypes.ADD_ROLES_FAILED });
const updateAddRolesRequestFailed = () => ({ type: ActionTypes.UPDATE_ADD_ROLES });

const updateRolesRequested = () => ({ type: ActionTypes.UPDATE_ROLES_REQUESTED });
const updateRolesReqSucceeded = () => ({ type: ActionTypes.UPDATE_ROLES_SUCCEEDED });
const updateRolesRequestFailed = () => ({ type: ActionTypes.UPDATE_ROLES_FAILED });

const createRole = (data) => {
  return axios({
    method: 'POST',
    url: `${baseUrlAdmin}/authorize/addRole`,
    data: data
  });
};

export const updateAddRolesRequest = () => (dispatch) => {
  dispatch(updateAddRolesRequestFailed());
}
export const addRole = (data) => (dispatch) => {
  dispatch(addRolesRequested());
  createRole(data)
    .then((res) => {

      if (res.data) {
        dispatch(addRolesReqSucceeded());
      } else {
        dispatch(addRolesRequestFailed());
      }
    })
    .catch((e) => {
      if (e.response && e.response.status == 401) {
        localStorage.clear();
        window.location.reload();
      } else {
        dispatch(addRolesRequestFailed());
      }
    });
};

export const updateRole = (data) => (dispatch) => {
  dispatch(updateRolesRequested());
  axios({
    method: 'PUT',
    url: `${baseUrlAdmin}/authorize/updateRole`,
    data: data
  })
    .then((res) => {
      if (res.data) {
        dispatch(updateRolesReqSucceeded());
      } else {
        dispatch(updateRolesRequestFailed());
      }
    })
    .catch((err) => {
      if (err.response && err.response.status == 401) {
        localStorage.clear();
        window.location.reload();
      } else {
        dispatch(updateRolesRequestFailed());
      }

    });
};
