import React, { Component } from 'react'
import { Button } from 'primereact/button';

import RolesList from './roles';
import UsersList from './users';

export default class ListTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTab: 2,


        }
    }


    switchTab = (tab) => {

        this.setState({
            currentTab: tab
        })
    }


    render() {
        return (
            <div>
                <Button label="Role" className='' onClick={() => this.switchTab(1)} />
                <Button label="User" className='ma-m-lr10' onClick={() => this.switchTab(2)} />
                {
                    this.state.currentTab == 1 && <>
                        <RolesList />
                    </>
                }
                {
                    this.state.currentTab == 2 && <>
                        <UsersList />
                    </>
                }
            </div>
        )
    }
}
