import {
  combineReducers
} from 'redux';
import organizationData from './organizationReducer';
import organizationData2 from './organizationReducer2';
import curriculumData from './curriculumReducer';
import curriculumData2 from './curriculumReducer2';
import signInData from './signInReducer';
import userData from './userDataReducer';
import auth from './authenticateReducer';
import updateOrganization from './updateOrganizationDataReducer';
import updateSubject from './updateSubjectReducer';
import updateSubject2 from './updateSubjectReducer2';
import updateChapter from './updateChapterReducer';
import updateTopic from './updateTopicReducer';
import contentInfo from './getContentReducer';
import contentPreSignedUrl from './getContentPreSignedUrlReducer';
import selectedOrganizationDataIds from './selectOrganizationReducer';
import selectedOrganizationDataIds2 from './selectOrganizationReducer2';
import uploadContent from './uploadContentReducer';
import updateContent from './updateContentReducer';
import packageInfo from './getPackageReducer';
import updatePackageInfo from './updatePackageReducer';
import deletePackageInfo from './deletePackageReducer';
import createPackageInfo from './createPackageReducer';
import bulkUpload from './bulkUploadReducer';
import deleteContent from './deleteContentReducer';
import assignContent from './assignContentReducer';
import allUsersData from './getAllUserDataReducers';
import transactionsInfo from './transactionsReducer';
import userPackageForAssign from './getUserPackageForAssignReducer';
import assignPackageToUser from './assignPackageToUserReducer';
import updateUserPackage from './updateUserPackageReducer';
import addQuestionInfo from './addQuestionReducer';
import fetchQuestionInfo from './fetchQuestionReducer';
import videoFetchQuestionInfo from './videoQuestionReducer';

import editQuestionInfo from './editQuestionReducer';
import tagsInfo from './getTagsReducer';
import languageDetailsInfo from './alternateLanguageReducers';
import gradeDetailsInfo from './getGradeReducer';
import deleteQuestionInfo from './deleteQuestionReducer';
import questionFiltersInfo from './questionFiltersReducer';
import createTestInfo from './createTestReducer';
import getImageUrlInfo from './getImageUrlReducer';
import getVideoUrlInfo from './getVideoUrlReducer';

import getSingleExamDetailsInfo from './getSingleExaminationReducer';
import editExaminationInfo from './editExaminationReducer';
import deleteExaminationInfo from './deleteExaminationReducer';
import examinationDetailsInfo from './getAllExaminationReducer';
import MockTestTemplateDetailsInfo from './getMockTestTemplateDataReducer';
import createTemplateInfo from './createTemplateReducer';
import TemplateInfo from './getAllTemplateReducer';
import singleTemplateInfo from './getSingleTemplateReducer';
import deleteTemplateInfo from './deleteTemplateReducer';
import editTemplateInfo from './editTemplateReducer';
import editExamDetailsInfo from './editQUizReducer';
import editMockTestInfo from './editMockTestReducer';
import contactUsInfo from './conatctUsInfoReducer';
import createNewUserInfo from './createNewUserReducer';
import permissions from './permissionsReducer';
import roles from './rolesReducer';
import createNewAdminUserInfo from './createNewAdminUserReducer';
import currentUserPerms from './loggedInAdminUserPermsReducer';
import adminUserList from './getAdminUserListReducer';

import branchData from './branchReducer';
import branchDataLatest from './sp_branchReducer';
import userInfo from './getUserDataByIdReducer';
import common from './commonReducer';
import boardsData from './sp_boardsReducer';
import orgData from './sp_orgReducer';
import LinkVideosToQuestions from './linkVideosToQuestionsReducer';
import plannerData from './yearlyPlannerReducer';
import coursesData from './getAllAdmissionCoursesReducer';
import languageReducer from './languageReducer';

const rootReducer = combineReducers({
  organizationData,
  organizationData2,
  curriculumData,
  curriculumData2,
  signInData,
  userData,
  auth,
  updateOrganization,
  updateSubject,
  updateSubject2,
  updateChapter,
  updateTopic,
  contentInfo,
  contentPreSignedUrl,
  selectedOrganizationDataIds,
  selectedOrganizationDataIds2,
  uploadContent,
  updateContent,
  packageInfo,
  updatePackageInfo,
  deletePackageInfo,
  createPackageInfo,
  bulkUpload,
  deleteContent,
  assignContent,
  allUsersData,
  transactionsInfo,
  userPackageForAssign,
  assignPackageToUser,
  updateUserPackage,
  addQuestionInfo,
  fetchQuestionInfo,
  videoFetchQuestionInfo,
  editQuestionInfo,
  tagsInfo,
  languageDetailsInfo,
  gradeDetailsInfo,
  deleteQuestionInfo,
  questionFiltersInfo,
  createTestInfo,
  getImageUrlInfo,
  getVideoUrlInfo,
  getSingleExamDetailsInfo,
  editExaminationInfo,
  deleteExaminationInfo,
  examinationDetailsInfo,
  MockTestTemplateDetailsInfo,
  createTemplateInfo,
  TemplateInfo,
  singleTemplateInfo,
  deleteTemplateInfo,
  editTemplateInfo,
  editExamDetailsInfo,
  editMockTestInfo,
  contactUsInfo,
  createNewUserInfo,
  createNewAdminUserInfo,
  permissions,
  roles,
  currentUserPerms,
  adminUserList,

  branchData,
  userInfo,
  common,
  LinkVideosToQuestions,
  boardsData,
  orgData,
  branchDataLatest,
  plannerData,
  coursesData,
  languageReducer
});

export default rootReducer;