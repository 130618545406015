import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Service from '../../../services';
import { DataTable } from 'primereact/datatable';
import SvgViewer from './../../customComponents/svgViewer';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import CreateFeePlan from './createFeePlan';

import cloneDeep from 'lodash/cloneDeep';
//import './styles.scss'
import _ from 'lodash';
import { WarningIcon } from '../../svgIcons';
import LoadingComponent from '../../loadingComponent';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../../store/apiConstants';
import Navbar from '../../navbar';
import { BreadCrumb } from 'primereact/breadcrumb';

const items = [
    { label: 'Organization' },
    { label: 'FeeTypes', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }


const feePlanItems = [
    { key: 'o1', label: 'Organization' },
    { key: 'o2', label: 'Organization Manage' },
    { key: 'o2', label: 'Fee Plans', className: 'ma-active-item' }
];

const createFeePlanItems = [
    { key: 'co1', label: 'Organization' },
    { key: 'co2', label: 'Organization Manage' },
    { key: 'co3', label: 'Fee Plans' },
    { key: 'co4', label: 'Create Fee Plan', className: 'ma-active-item' }

];
const editFeePlanItems = [
    { key: 'co1', label: 'Organization' },
    { key: 'co2', label: 'Organization Manage' },
    { key: 'co3', label: 'Fee Plans' },
    { key: 'co4', label: 'Edit Fee Plan', className: 'ma-active-item' }
];


class FeePlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearch: false,
            editSubject: null,
            globalFilter: null,
            columns: [],
            items: feePlanItems,
            warningDialog: warningDailogInit,
            warningDialog2: warningDailogInit,
            isShowCreateOrEdit: false,
            feePlans: [],
            editFeePlan: null
        }

        this.service = new Service()
    }


    showCreateOrEdit = () => {

        this.setState({
            isShowCreateOrEdit: true,
            editFeePlan: null,
            items: createFeePlanItems,

        });


    }

    onCreateCancel = () => {

        this.setState({
            isShowCreateOrEdit: false,
            items: feePlanItems,
        });
    }

    onEditFeePlanClick = (editFeePlan) => {
        this.setState({
            editFeePlan,
            isShowCreateOrEdit: true,
            items: editFeePlanItems,
        });
    }



    onCreateOrEditSuccess = () => {

        this.setState({
            editFeePlan: null,
            isShowCreateOrEdit: false,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            items: feePlanItems
        }, this.getOrganizations)
    }


    getFeePlans = () => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/fee-plans/all`;
        this.service.post(url,{} ,true).then(data => {

            if (data.status) {
                if (data.res && data.res.data && data.res.data.length) {
                    this.setState({
                        feePlans: data.res.data,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            } else {
                this.setState({
                    isLoading: false
                })
            }
        });
    }

    componentDidMount() {

        this.getFeePlans();
    }
    chaptersCountTemplate = (rowData) => {
        return (
            <p >{rowData.chapters.length}</p>
        );
    }

    consApplicableTemplate = (rowData) => {
        return (
            <div className="ma-status">
                <span className="ma-status-text">{rowData.isConsApplicable ? 'Yes' : 'No'} </span>
                <InputSwitch disabled
                    checked={rowData.isConsApplicable} />
            </div>

        );
    }


    applicableTypesRender = (rowData) => {
        return (
            <>
                {(rowData.applicableType || []).join(',')}</>

        );
    }

    editTemplate = (rowData) => {
        return (<>
            <span data-pr-tooltip="Edit Fee Plan" data-pr-position="bottom" className={`edit${rowData._id.substr(0, 5)}`}
                onClick={() => { this.onEditFeePlanClick(rowData) }}
            >
                <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" />
            </span>
            <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData._id.substr(0, 5)}`} />

        </>);
    }


    checkIsDuplicate(subjects, subjectInfo, isEdit) {
        let err, errMsg;
        subjects &&
            subjects.length &&
            subjects.forEach((subject) => {
                if (isEdit) {
                    if (subject.subjectId != subjectInfo.subjectId) {
                        if (
                            subject.subjectCode.toLowerCase() === subjectInfo.subjectCode.toLowerCase() ||
                            subject.subjectName.toLowerCase() === subjectInfo.subjectName.toLowerCase()
                        ) {
                            err = true;
                            errMsg =
                                subject.subjectCode.toLowerCase() === subjectInfo.subjectCode.toLowerCase()
                                    ? 'Duplicate Subject Code'
                                    : subject.subjectName.toLowerCase() === subjectInfo.subjectName.toLowerCase()
                                        ? 'Duplicate Subject Name'
                                        : 'Enter Valid Details';
                            return;
                        }
                    }
                } else {
                    if (
                        subject.subjectCode.toLowerCase() === subjectInfo.subjectCode.toLowerCase() ||
                        subject.subjectName.toLowerCase() === subjectInfo.subjectName.toLowerCase()
                    ) {
                        err = true;
                        errMsg =
                            subject.subjectCode.toLowerCase() === subjectInfo.subjectCode.toLowerCase()
                                ? 'Duplicate Subject Code'
                                : subject.subjectName.toLowerCase() === subjectInfo.subjectName.toLowerCase()
                                    ? 'Duplicate Subject Name'
                                    : 'Enter Valid Details';
                        return;
                    }
                }

            });

        return { err, errMsg };
    }

    onFeePlanCreate = (isUpdate) => {

        this.setState({
            isShowCreateOrEdit: false
        }, this.getFeePlans)
        //this.toast.show({ severity: 'success', summary: 'Sucess', detail: `successfully ${isUpdate ? 'updated' : 'Created'}`, life: 3000 });
    }

    changeStatus = (e, rowData) => {
        let data = { ...rowData }
        if (e.value == true) {
            data.isSubjectActive = true
        } else {
            data.isSubjectActive = false
        }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You are changing this Subject status ${e.value ? 'InActive' : 'Active'} to ${e.value ? 'Active' : 'InActive'}`
            },
            editSubject: data
        });

    }

    onConfirmChange = () => {
        this.setState({
            warningDialog: warningDailogInit
        });

        if (this.props.subjects && this.props.subjects.length) {
            let oldSubjects = _.cloneDeep(this.props.subjects);


            const { editSubject } = this.state;
            let subjects = oldSubjects.map(sub => {
                if (sub.subjectId === editSubject.subjectId) {
                    sub.isSubjectActive = editSubject.isSubjectActive;
                }

                return sub
            });
            this.props.addOrUpdateCurriculumInfo(subjects);
        }



    }





    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Fee Types</h2>
                {/* <p>{this.state.isShowChapters && 'Chapters'}</p> */}
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    hideCreateFeePlanDialog = () => {
        this.setState({
            isShowCreateOrEdit: false,
            editSubjectData: null
        })
    }


    render() {
        const { subject, formValidations } = this.state;

        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'>Fee Plans</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={(e) => this.setState({ globalFilter: e.target.value })} />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.CURRICULUM_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <Authorizer permId={PERMISSIONS.CURRICULUM_VIEWCOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" data-pr-position="bottom" onClick={(event) => this.menu.toggle(event)}
                                className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.CURRICULUM_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        return (
            <Navbar >
                <div className="p-grid ma-toolbar">
                    <div className="p-col-12 p-md-1 ma-no-p">
                        {(this.state.isShowCreateOrEdit) && <>
                            <p className='back-arrow' onClick={() => {
                                this.setState({
                                    isShowCreateOrEdit: false,
                                    items: feePlanItems,
                                })
                            }}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                        </>
                        }
                    </div>
                    <div className="p-col-12 p-md-11 ma-no-pm">
                        <Toolbar className="ma-toolbard"
                            left={() => {
                                return (<>  <BreadCrumb model={this.state.items} home={home} />
                                </>)
                            }}
                            right={() => {
                                return (<h2 className='ma-tab-title'>Fee Plans</h2>)
                            }}
                        ></Toolbar>
                    </div>
                </div>
                <div className='ma-main'>
                    {this.state.isShowCreateOrEdit ?
                        <CreateFeePlan
                            editFeePlan={this.state.editFeePlan}
                            hideCreateFeePlanDialog={this.hideCreateFeePlanDialog}
                            editFeeType={this.state.editFeeType}
                            onFeePlanCreate={this.onFeePlanCreate} />
                        : <>
                            <div className='ma-mtb25'>
                                <Button label="+ Add Fee Plan" className='ma-m-lr10'
                                    onClick={this.showCreateOrEdit} />
                            </div>
                            <div className="card datatable-crud-demo ma-m30">
                                <DataTable ref={(el) => this.dt = el}
                                    value={this.state.feePlans}
                                    dataKey="id"
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                    globalFilter={this.state.globalFilter}
                                    header={header}>

                                    <Column field="planName" header="Fee Plan Name" />
                                    <Column field="academicYear" header="Academic Year" />
                                    <Column field="applicableType" header="Applicable Types" body={this.applicableTypesRender} />
                                    <Column header="Edit" body={this.editTemplate} ></Column>
                                    {/* 
                          
                               <Column header="Show Chapters" body={this.showChaptersTemplate}></Column> 
                    */}

                                </DataTable>


                                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                                {/* <Dialog
                    header={<></>}
                    blockScroll={true}
                    draggable={false}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editRoleData: null }) }} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmChange} />
                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, editRoleData: null })}>
                    <div>
                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>
                    <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <p className='ma-alert-msg'>{this.state.warningDialog.message}</p>
                </Dialog>
                <Dialog
                    header={<></>}
                    blockScroll={true}
                    draggable={false}
                    closeOnEscape={false}
                    className='ma-alert-box'
                    dismissableMask={false}
                    closable={false}
                    visible={this.state.warningDialog2.visible}
                    style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog2: warningDailogInit, syncData: null }) }} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.onSyncConfirm} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog2: warningDailogInit, syncData: null })}>
                    <div>

                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>
                    <p className='ma-alert-head'>{this.state.warningDialog2.headerMsg}</p>
                    <p className='ma-alert-msg'>{this.state.warningDialog2.message}</p>
                </Dialog> */}
                                {this.state.isLoading && <LoadingComponent />}
                            </div >
                        </>}
                </div>
            </Navbar>)
    }
}

export default FeePlan;