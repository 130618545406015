


exports.Constants = {
    Text: 'text',
    Alert: 'alert',
    List: 'list',
    AlertBlock: 'alertBlock',
    AlertDanger: "alertDanger",
    Button: 'button',
    Image: 'image',
    Video: 'video',
    PDF: 'pdf',
    ZOOM: 'zoom'
}

