import * as ActionTypes from "../actionTypes";

export default function(state={}, action){
    switch(action.type){
        case ActionTypes.SELECT_QUESTION_TYPE_FOR_TEMPLATE :
         return{
             ...state,
             questionType:action.questionType
        } 

        case ActionTypes.SELECT_CHAPTER_DATA_FOR_TEMPLATE :
        return{
            ...state,
            chaptersDetails:action.chapterDetails
        }
        case ActionTypes.SELECT_MOCK_TEMPLATE_DETAILS :
            return {
                ...state,
                mockTestInfo:action.mockTestInfo    
            } 
            case ActionTypes.GET_TEMPLATE_TYPE_DETAILS :
                return {
                    ...state,
                    templateTypeInfo:action.details
                }     
        case ActionTypes.ERROR_CREATE_TEMPLATE :
                return{
                    ...state,
                    templateError: action.templateError
                }     
        default: return state
    }
}