import React, { Component } from 'react'

export default class Test extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }


    }

    componentDidUpdate(prevProps, prevState) {
        //  console.log('testing')

        if (this.props) {

        }
        if (this.state) {

        }
    }


    render() {
        return (
            <div>Test</div>
        )
    }
}
