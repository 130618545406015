import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import { trimObj } from '../../utile';
import { getFormFields, isFormValid, onTextChange } from '../../utile/formHelper';
import contentMaster from '../contentMaster';
import LoadingComponent from '../loadingComponent';
import Authentication from '../session'
import './styles.scss'
import {
    getBoardsData
} from './../../store/actions';
import { InputNumber } from 'primereact/inputnumber';

class CreateFee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,

            boardId: this.props.editFee ? this.props.editFee.boardId : '',
            classId: this.props.editFee ? this.props.editFee.classId : '',
            admissionType: this.props.editFee ? this.props.editFee.admissionType : '',
            applicationFee: this.props.editFee ? this.props.editFee.applicationFee : '',
            registrationFee: this.props.editFee ? this.props.editFee.registrationFee : '',
            errMsgb: false,
            errMsgc: false,
            errMsgAppFee: false,
            errMsgRegFee: false,
            admTypeOptions: []

        }

        this.service = new Service()
    }

    componentDidMount() {
        this.getAdmissionTypeOptions();
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        } else if (this.props.editFee && this.props.boards.length) {
            this.formGradesDataForEdit(this.props.editFee);
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.boards.length !== this.props.boards.length && this.props.editFee) {
            this.formGradesDataForEdit(this.props.editFee);
        }
    }

    getAdmissionTypeOptions = () => {
        this.setState({
            isLoading: true
        })

        let url = `${baseUrlAdmin}/admission-types`
        this.service.get(url, true).then(res => {
            if (res?.status && res?.res?.status) {
                // console.log(res, 'admTypeOptions');
                this.setState({
                    admTypeOptions: res?.res?.data,
                    isLoading: false,
                })
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(err => {
            console.log(err, 'err')
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
        })
    }



    formGradesDataForEdit = (editQuestion) => {

        if (this.props.boards && this.props.boards.length) {
            const _classes = [];
            const selectedBoard =
                this.props.boards &&
                this.props.boards.length > 0 &&
                this.props.boards.find((board) => board.boardId === editQuestion.boardId);
            if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                selectedBoard.classes.map((item) => _classes.push({ label: item.className, value: item.classId }));
            }

            const selectedClass = selectedBoard && selectedBoard.classes.find((_class) => _class.classId === editQuestion.classId);

            this.setState(
                {
                    boardId: selectedBoard.boardId,
                    classId: selectedClass.classId,
                    classes: _classes,
                    boardName: selectedBoard.boardName,
                    className: selectedClass.className,

                });
        }
    }


    checkIsFeeCreatedForBC = (classId, admissionType) => {
        let feeCreated = this.props.applicationFees.find(appFee => appFee.classId === classId && appFee.admissionType == admissionType);
        if (feeCreated && feeCreated._id != this.props.editFee?._id) {
            return true
        } else {
            return false;
        }
    }

    onSubmitClick = () => {

        const { boardId, classId, applicationFee, admissionType, registrationFee } = this.state
        let isCompleteFormValid = true;

        if (boardId == '' || boardId == (undefined || null)) {
            this.setState({ errMsgb: true })
            isCompleteFormValid = false;
        }
        if (classId == '' || classId == (undefined || null)) {
            this.setState({ errMsgc: true })
            isCompleteFormValid = false;
        }
        if (admissionType == '' || admissionType == (undefined || null)) {
            this.setState({ errMsgAdmType: true })
            isCompleteFormValid = false;
        }
        if (applicationFee == '' || applicationFee == (undefined || null)) {
            this.setState({ errMsgAppFee: true })
            isCompleteFormValid = false;
        }
        if (registrationFee == '' || registrationFee == (undefined || null)) {
            this.setState({ errMsgRegFee: true })
            isCompleteFormValid = false;
        }
        if (this.checkIsFeeCreatedForBC(classId, admissionType)) {
            //this.setState({ errMsgc: true })
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Fee Type is created for the selected board and class and admission type', life: 3000 });
            isCompleteFormValid = false;
        }
        // console.log('b,', this.state.boardId, 'c', this.state.classId, 'c', this.state.applicationFee, 'at', admissionType, 'd', registrationFee)
        // return
        if (isCompleteFormValid) {
            let body = {
                ...this.props.editFee,
                boardId: this.state.boardId,
                boardName: this.state.boardName,
                classId: this.state.classId,
                className: this.state.className,
                groupId: this.state.groupId,
                admissionType: this.state.admissionType,
                applicationFee: this.state.applicationFee,
                registrationFee: this.state.registrationFee
            }


            // console.log('fee body', body)

            if (this.props.editFee) {
                this.setState({
                    isLoading: true
                })


                let url = `${baseUrlAdmin}/application-fee`
                this.service.put(url, body, true).then(res => {
                    if (res?.status && res?.res?.status) {
                        // console.log(res, 'resy');
                        this.setState({
                            isLoading: false,
                        }, () => { this.props.onSaveOrUpdate(true) })
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(err => {
                    console.log(err, 'err')
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
                })

            } else {
                this.setState({
                    isLoading: true
                })
                let url = `${baseUrlAdmin}/application-fee`

                this.service.post(url, body, true).then(res => {
                    if (res?.status && res?.res?.status) {
                        // console.log(res, 'resy');
                        this.setState({
                            isLoading: false,
                        }, () => { this.props.onSaveOrUpdate(false) })
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(err => {
                    console.log(err, 'err')
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
                })
            }
        }
    }

    footer = () => {
        return (<div>
            <Button label="Cancel" className='p-button-outlined' onClick={this.props.onClose} />
            <Button label={`${this.props.editFee ? 'Update' : 'Add'}`} className='ma-m-lr10' onClick={this.onSubmitClick} />
        </div>)
    }

    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [],
            classId: null, groupId: null,
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
            boardName: selectedBoard.boardName,
            errMsgb: false
        });
    };

    onChangeClass = (classId) => {
        this.setState({ groups: [], groupId: null });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        let selectedClassLabel = this.state.classes.find(a => a.value === classId)
        // this.setState({ classId: e.value, className: selectedClass.label, errMsgc: false })

        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            let selectedGroup = _groups[0];
            this.setState({
                groups: _groups,
                classId: classId,
                groupId: selectedGroup.value,
                className: selectedClassLabel.label,
                errMsgc: false
            });
        }
    };



    render() {
        return (
            <Dialog
                visible={true}
                draggable={false}
                blockScroll={true}
                closeOnEscape={true}
                className='ma-alert-box'
                dismissableMask={true}
                closable={false}
                style={{ maxWidth: '30%', minWidth: '30%' }}
                footer={this.footer}
                header={`${this.props.editFee ? 'Edit' : 'Add'} Fee`}
            >
                <div className="p-grid subject-add-dialog">
                    <div className="p-col-4 p-md-4 p-lg-4">
                        <p className='text-label'>Board Name<span className='ma-required'>*</span></p>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                        <Dropdown className='ma-m-lr10 ma-w200'
                            placeholder='Select Board'
                            value={this.state.boardId}
                            options={this.props.boards}
                            optionLabel="boardName"
                            optionValue="boardId"
                            onChange={(e) => this.onChangeBoard(e.value)}
                        />
                        {this.state.errMsgb && <p className='p-error p-ml-3'>Board Name is Required</p>}
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-4">
                        <p className='text-label'>Class Name<span className='ma-required'>*</span></p>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                        <Dropdown className='ma-m-lr10 ma-w200'
                            value={this.state.classId}
                            placeholder='Select Class'
                            options={this.state.classes}
                            onChange={(e) => this.onChangeClass(e.value)
                                // let selectedClass = this.state.classes.find(a => a.value === e.value)
                                // this.setState({ classId: e.value, className: selectedClass.label, errMsgc: false })
                            }
                        />
                        {this.state.errMsgc && <p className='p-error p-ml-3'>Class Name is Required</p>}
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-4">
                        <p className='text-label'>Admission Type<span className='ma-required'>*</span></p>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                        <Dropdown className='ma-m-lr10 ma-w200'
                            placeholder='--Select--'
                            value={this.state.admissionType}
                            options={this.state.admTypeOptions} optionLabel='admissionType' optionValue='admissionCode'
                            onChange={(e) => {
                                this.setState({ admissionType: e.value, errMsgAdmType: false })
                            }}
                        />
                        {this.state.errMsgAdmType && <p className='p-error p-ml-3'>Admission Type is Required</p>}
                    </div>

                    <div className="p-col-4 p-md-4 p-lg-4">
                        <p className='text-label'>Application Fee<span className='ma-required'>*</span></p>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                        <InputNumber className='ma-m-lr10 ma-w200'
                            value={this.state.applicationFee}
                            onChange={(e) => this.setState({ applicationFee: e.value, errMsgAppFee: false })}
                        />
                        {this.state.errMsgAppFee && <p className='p-error p-ml-3'>Application Fee is Required</p>}
                    </div>

                    <div className="p-col-4 p-md-4 p-lg-4">
                        <p className='text-label'>Registration Fee<span className='ma-required'>*</span></p>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                        <InputNumber className='ma-m-lr10 ma-w200'
                            value={this.state.registrationFee}
                            onChange={(e) => this.setState({ registrationFee: e.value, errMsgRegFee: false })}
                        />
                        {this.state.errMsgRegFee && <p className='p-error p-ml-3'>Registration Fee is Required</p>}
                    </div>
                </div>

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </Dialog>
        )
    }
}


const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
});


export default connect(mapStateToProps, {
    getBoardsData
})(Authentication(withRouter(CreateFee)));
