import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import { trimObj } from '../../utile';
import { getFormFields, isFormValid, onTextChange } from '../../utile/formHelper';
import contentMaster from '../contentMaster';
import LoadingComponent from '../loadingComponent';
import Authentication from '../session'
import './styles.scss'
import {
    getBoardsData
} from './../../store/actions';

class CreateCourse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,

            boardId: this.props.editCourse ? this.props.editCourse.boardId : '',
            classId: this.props.editCourse ? this.props.editCourse.classId : '',
            courseName: this.props.editCourse ? this.props.editCourse.courseName : '',
            errMsgb: false,
            errMsgc: false,
            errMsgCourse: false,


        }

        this.service = new Service()
    }

    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        } else if (this.props.editCourse && this.props.boards.length) {
            this.formGradesDataForEdit(this.props.editCourse);
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.boards.length !== this.props.boards.length && this.props.editCourse) {
            this.formGradesDataForEdit(this.props.editCourse);
        }


    }



    formGradesDataForEdit = (editQuestion) => {

        if (this.props.boards && this.props.boards.length) {
            const _classes = [];
            const selectedBoard =
                this.props.boards &&
                this.props.boards.length > 0 &&
                this.props.boards.find((board) => board.boardId === editQuestion.boardId);
            if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                selectedBoard.classes.map((item) => _classes.push({ label: item.className, value: item.classId }));
            }

            const selectedClass = selectedBoard && selectedBoard.classes.find((_class) => _class.classId === editQuestion.classId);

            this.setState(
                {
                    boardId: selectedBoard.boardId,
                    classId: selectedClass.classId,
                    classes: _classes,
                    boardName: selectedBoard.boardName,
                    className: selectedClass.className,

                });
        }
    }




    checkIsCourseCreatedForBC = (classId) => {
        let feeCreated = this.props.existedCourses.find(course => course.classId === classId);
        if (feeCreated && feeCreated._id != this.props.editCourse?._id) {
            return true
        } else {
            return false;
        }
    }
    onSubmitClick = () => {

        const { boardId, classId, courseName } = this.state
        let isCompleteFormValid = true;

        if (boardId == '' || boardId == (undefined || null)) {
            this.setState({ errMsgb: true })
            isCompleteFormValid = false;
        }
        if (classId == '' || classId == (undefined || null)) {
            this.setState({ errMsgc: true })
            isCompleteFormValid = false;
        }
        if (courseName == '' || courseName == (undefined || null)) {
            this.setState({ errMsgCourse: true })
            isCompleteFormValid = false;
        }

        if (this.checkIsCourseCreatedForBC(classId)) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Course is created for the selected board and class', life: 3000 });

            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            let body = {
                ...this.props.editCourse,
                boardId: this.state.boardId,
                boardName: this.state.boardName,
                classId: this.state.classId,
                className: this.state.className,
                courseName: this.state.courseName
            }

            if (this.props.editCourse) {
                this.setState({
                    isLoading: true
                })

                let url = `${baseUrlAdmin}/admission-courses`
                this.service.put(url, body, true).then(res => {
                    if (res?.status && res?.res?.status) {
                        // console.log(res, 'resy');
                        this.setState({
                            isLoading: false,
                        }, this.props.onSaveOrUpdate(true))
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(err => {
                    console.log(err, 'err')
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
                })

            } else {
                this.setState({
                    isLoading: true
                })
                let url = `${baseUrlAdmin}/admission-courses`

                this.service.post(url, body, true).then(res => {
                    if (res?.status && res?.res?.status) {
                        // console.log(res, 'resy');
                        this.setState({
                            isLoading: false,
                        }, () => this.props.onSaveOrUpdate())
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(err => {
                    console.log(err, 'err')
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
                })
            }
        }
    }

    footer = () => {
        return (<div>
            <Button label="Cancel" className='p-button-outlined' onClick={this.props.onClose} />
            <Button label={`${this.props.editCourse ? 'Update' : 'Add'}`} className='ma-m-lr10' onClick={this.onSubmitClick} />

        </div>)
    }

    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [],
            classId: null, groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
            boardName: selectedBoard.boardName,
            isBaseBoard: selectedBoard.isBase,
            resourseOrigin: selectedBoard.isBase ? 'upload' : null,
            errMsgb: false
        });

    };



    render() {
        return (
            <Dialog
                visible={true}
                draggable={false}
                blockScroll={true}
                closeOnEscape={true}
                className='ma-alert-box'
                dismissableMask={true}
                closable={false}
                style={{ maxWidth: '30%', minWidth: '30%' }}
                footer={this.footer}
                header={`${this.props.editCourse ? 'Edit' : 'Add'} Course`}
            >
                <div className="p-grid subject-add-dialog">
                    <div className="p-col-4 p-md-4 p-lg-4">
                        <p className='text-label'>Board Name<span className='ma-required'>*</span></p>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                        <Dropdown className='ma-m-lr10 ma-w200'
                            placeholder='Select Board'
                            value={this.state.boardId}
                            options={this.props.boards}
                            optionLabel="boardName"
                            optionValue="boardId"
                            onChange={(e) => this.onChangeBoard(e.value)}
                        />
                        {this.state.errMsgb && <p className='p-error p-ml-3'>Board Name is Required</p>}
                    </div>
                    <div className="p-col-4 p-md-4 p-lg-4">
                        <p className='text-label'>Class Name<span className='ma-required'>*</span></p>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                        <Dropdown className='ma-m-lr10 ma-w200'
                            value={this.state.classId}
                            placeholder='Select Class'
                            options={this.state.classes}
                            onChange={(e) => {
                                let selectedClass = this.state.classes.find(a => a.value === e.value)
                                this.setState({ classId: e.value, className: selectedClass.label, errMsgc: false })
                            }}
                        />
                        {this.state.errMsgc && <p className='p-error p-ml-3'>Class Name is Required</p>}
                    </div>

                    <div className="p-col-4 p-md-4 p-lg-4">
                        <p className='text-label'>Course Name<span className='ma-required'>*</span></p>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                        <InputText className='p-inputtext-style1 ma-ml10'
                            options={this.state.classes}
                            placeholder='Enter Course'
                            value={this.state.courseName}
                            onChange={(e) => this.setState({ courseName: e.target.value, errMsgCourse: false })}
                        />
                        {this.state.errMsgCourse && <p className='p-error p-ml-3'>Course Name is Required</p>}
                    </div>
                </div>

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </Dialog>
        )
    }
}


const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
});


export default connect(mapStateToProps, {
    getBoardsData
})(Authentication(withRouter(CreateCourse)));
