import React, { Component } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class CkEditorComponent extends Component {
    render() {
        return (<CKEditor
            editor={ClassicEditor}
            data={this.props.value}
            onChange={(event, editor) => {
                const data = editor.getData();
                this.props.onChange(data);
            }}
            disabled={this.props.disabled}
        />);
    }
}

export default CkEditorComponent;