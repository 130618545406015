import React from 'react';
import moment from "moment";
import { Menu } from 'primereact/menu';
import { ConfirmPopup } from 'primereact/confirmpopup';

class DeleteMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            moreOptionsRef: React.createRef(null),
        }
    }



    handleClose = () => {
        this.setState({
            open: false
        })
    }


    onMoreClick = (event) => {
        this.setState({
            open: true,
            moreOptionsRef: event.currentTarget
        });
    }
    render() {
        return (
            <div className={'delete-wrapper'}>
                <img src="./images/down_arrow.svg"
                    onClick={(event) => this.menu.toggle(event)}
                    className="down-arrow" />
                <Menu
                    className='delete-m-menu'
                    model={
                        [
                            {
                                template: (<>
                                    <span onClick={(event) => { this.props.deleteMessage(this.props.conversation); this.menu.toggle(event) }}> Delete Message</span>
                                </>)
                            }
                        ]}
                    popup ref={el => this.menu = el} />
            </div>

        );
    }
}


export default DeleteMessage;
