import moment from 'moment'
import { Dialog } from 'primereact/dialog'
import React, { Component } from 'react'

export default class PreViewDetails extends Component {
    render() {
        // console.log(this.props)
        const  {viewVehicleDetails} =  this.props

        return (
            <>
                <Dialog
                    visible={this.props.previewDetails}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={true}
                    style={{ maxWidth: '25%', minWidth: '25%' }}
                    footer={this.footer}
                    header='Vehicle Details'
                    onHide={this.props.onClosePreview}
                >
                    <div className="p-grid subject-add-dialog">
                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Registration Number</b></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {viewVehicleDetails.vehicleRegistrationNo}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Make of The Vehicle</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {viewVehicleDetails.makeOfTheVehicle}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Model of The Vehicle</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {viewVehicleDetails.modelOfTheVehicle}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Fuel Type</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {viewVehicleDetails.fuelType}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Chassis Number</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {viewVehicleDetails.chassisNo}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Engine Number</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {viewVehicleDetails.engineNo}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Seating Capacity</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {viewVehicleDetails.seatingCapacity}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Certification of Registration</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {moment(viewVehicleDetails.certificationOfRegistrationStartDate).format('DD/MM/YYYY') } - {moment(viewVehicleDetails.certificationOfRegistrationEndDate).format('DD/MM/YYYY')}</p>

                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Insurance</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {moment(viewVehicleDetails.insuranceStartDate).format('DD/MM/YYYY') } - {moment(viewVehicleDetails.insuranceEndDate).format('DD/MM/YYYY')}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Pollution Control</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {moment(viewVehicleDetails.pollutionControlStartDate).format('DD/MM/YYYY') }- {moment(viewVehicleDetails.pollutionControlEndDate).format('DD/MM/YYYY') }</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Permit</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {moment(viewVehicleDetails.permitStartDate).format('DD/MM/YYYY')} - {moment(viewVehicleDetails.permitEndDate).format('DD/MM/YYYY')}</p>
                        </div>
                    </div>
                </Dialog>
            </>
        )
    }
}
