import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from './../session';
import { Tooltip } from 'primereact/tooltip';
import axios from 'axios';
import Service from '../../services';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../store/apiConstants';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Navbar from './../navbar/index';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { BreadCrumb } from 'primereact/breadcrumb';
import cloneDeep from 'lodash/cloneDeep';
import {
    getCurriculum,
    getActiveOrganizationData,
    selectBoard,
    selectClass,
    selectGroup,
    clearSelectedOrganizationData,
    updateSubject,
    getSubject,
    dispatchClearCurriculumData,
    startExportToOffline,
    getImageUrl,
    getBoardsData
} from './../../store/actions';
import SubjectsList from './subjects';
import ChaptersList from './chapters';
import TopicsList from './topics';
import './styles.scss'
import LoadingComponent from '../loadingComponent';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import ResourcesList from './resources';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import AllDataTableForExcel from './allDataTableForExcel';
const items = [
    { label: 'Academics' },
    { label: 'Curriculum Master', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

class CurriculumMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            boardId: null,
            classId: null,
            groupId: null,
            boards: [],
            classes: [],
            groups: [],
            curriculumInfo: {},
            isShowSubjectCreate: false,
            editSubjectData: null,
            isSubjectAdd: true,
            isShowSubjects: false,
            isShowChapters: false,
            isShowTopics: false,
            isShowResouces: false,
            editChapterData: null,
            isShowExportToOffline: false,
            isShowCurriculumDownload: false,
            curriculumDownloadData: null

        }
        this.service = new Service();
    }



    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }
        // else {
        //     if (this.props.boards) {
        //         let boards = [];
        //         this.props.boards &&
        //             this.props.boards.map((item, index) => {
        //                 boards.push({ value: item.boardId, label: item.boardName });
        //             });
        //         this.setState({
        //             boards: boards
        //         });
        //     }


        // }
    }

    componentDidUpdate(prevProps) {
        // if (prevProps.boards !== this.props.boards) {
        //     if (this.props.boards) {
        //         let boards = [];
        //         this.props.boards &&
        //             this.props.boards.map((item, index) => {
        //                 boards.push({ value: item.boardId, label: item.boardName });
        //             });

        //         this.setState({
        //             boards: boards
        //         });
        //     }
        // }

        if (this.state.curriculumInfo && this.state.curriculumInfo.offlineExportStatus && this.state.curriculumInfo.offlineExportStatus.storageKey && this.props.getAllImagesUrlInfo && this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey] && (prevProps.getAllImagesUrlInfo && (this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey] != prevProps.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey]))) {
            window.open(this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey]);
        }

    }



    // grades selection --- start
    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [], classId: null, groupId: null, curriculumInfo: null,
            isShowSubjects: false,
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
            parentBoardId: selectedBoard.parentBoardId
        });

    };

    onChangeClass = (classId) => {
        this.setState({
            groups: [], groupId: null,
            curriculumInfo: null,
            isShowSubjects: false,
        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                groupId: _groups[0].value,
                parentClassId: selectedClass.parentClassId,
                parentGroupId: selectedClass.groups[0].parentGroupId
            }, () => {
                this.getCurriculumData();
            });
        }
    };

    onChangeGroup = (groupId) => {
        this.setState(
            {
                groupId,
            },
            () => {
                this.getCurriculumData();
            }
        );
    };
    // grades selection --- end


    //subjects --- start


    getCurriculumData = () => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true,
                curriculumInfo: {}
            });
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}?etag=true`;
            this.service.get(url, true).then((data) => {

                const etag = data && data.etag;

                if (data && data.res && data.res.Item) {
                    this.setState({
                        curriculumInfo: data.res.Item,
                        isShowSubjects: true,
                        isShowChapters: false,
                        isShowTopics: false,
                        isShowResouces: false,
                        isLoading: false,
                        etag
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });

                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false,

            })
            // this.snackBarOpen('select board, class and group id');
        }
    };

    showCreateSubject = () => {
        this.setState({ isShowSubjectCreate: true, isSubjectAdd: true, editSubjectData: null });
    }

    goBackToSubjects = () => {
        this.setState({
            isShowChapters: false,
            isShowTopics: false,
            isShowSubjects: true
        });
    }

    showEditSubjectDialog = (editSubjectData) => {
        this.setState({
            editSubjectData: editSubjectData,
            isSubjectAdd: false,
            isShowSubjectCreate: true
        })

    }

    hideCreateSubjectDialog = () => {
        this.setState({
            isShowSubjectCreate: false,
            editSubjectData: null,
            isSubjectAdd: true
        })
    }

    updateCurriculumInDB = (curriculumInfo) => {

    }
    addOrUpdateCurriculumInfo = (subjects) => {

        const url = `${baseUrlAdmin}/subject`;

        let curriculumInfo = cloneDeep(this.state.curriculumInfo);
        curriculumInfo.subjects = subjects;



        this.setState({
            isLoading: true
        })
        this.service.post(url, curriculumInfo, true, { "If-Match": this.state.etag }).then((res) => {
            if (res && res.res && res.res.success) {
                this.setState((prevState) => {
                    return {
                        curriculumInfo: {
                            ...prevState.curriculumInfo,
                            subjects
                        },
                        editSubjectData: null,
                        isShowSubjectCreate: false,
                        isLoading: false
                    }
                });

                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Curriculum Changed, it will reflect in 5 minutes', life: 3000 });

            } else {


                this.setState({
                    isLoading: false
                })

                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }



        }).catch(e => {
            console.log(e)
            this.setState({
                isLoading: false,

            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });

        });



    }
    //subjects --- end


    //chapters --- start
    showChapters = (subject) => {
        this.setState({
            editSubjectData: subject,
            isShowSubjects: false,
            isShowChapters: true
        });
    }


    addOrUpdateChapterInfo = (chapters) => {
        let curriculumInfo = cloneDeep(this.state.curriculumInfo);
        curriculumInfo.subjects = curriculumInfo.subjects.map((subject) => {
            if (subject.subjectId == this.state.editSubjectData.subjectId) {
                subject.chapters = chapters;
            }
            return subject;
        });


        this.setState({
            isLoading: true
        });
        const url = `${baseUrlAdmin}/subject`;
        this.service.post(url, curriculumInfo, true, { "If-Match": this.state.etag }).then((res) => {
            if (res && res.res && res.res.success) {
                this.setState((prevState) => {
                    return {
                        curriculumInfo,
                        editSubjectData: {
                            ...prevState.editSubjectData,
                            chapters
                        },
                        isLoading: false
                    }
                });


                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Curriculum Changed, it will reflect in 5 minutes', life: 3000 });

            } else {

                this.setState({
                    isLoading: false
                })

                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            console.log(e)
            this.setState({
                isLoading: false,
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });
    }

    // chapters end

    //topics start
    showTopics = (chapter) => {
        this.setState({
            editChapterData: chapter,
            isShowChapters: false,
            isShowTopics: true
        });

    }


    showResources = (editTopicData) => {
        this.setState({
            editTopicData: editTopicData,
            isShowChapters: false,
            isShowTopics: false,
            isShowResouces: true
        });

    }

    goBackToChapters = () => {
        this.setState({
            isShowTopics: false,
            isShowChapters: true,
            editChapterData: null

        });
    }

    goBackToTopics = () => {
        this.setState({
            isShowResouces: false,
            isShowTopics: true,
            editTopicData: null
        });
    }


    addOrUpdateTopicInfo = (topics) => {
        let curriculumInfo = cloneDeep(this.state.curriculumInfo);
        let editChapterData = cloneDeep(this.state.editChapterData);
        let editSubjectData = cloneDeep(this.state.editSubjectData);
        editChapterData.topics = topics;
        editSubjectData.chapters = this.state.editSubjectData.chapters.map((chapter) => {
            if (chapter.chapterId == editChapterData.chapterId) {
                chapter = editChapterData;
            }
            return chapter;
        });

        curriculumInfo.subjects = this.state.curriculumInfo.subjects.map((subject) => {
            if (subject.subjectId == this.state.editSubjectData.subjectId) {
                subject = editSubjectData;
            }
            return subject;
        });


        const url = `${baseUrlAdmin}/subject`;


        this.setState({
            isLoading: true
        })
        this.service.post(url, curriculumInfo, true, { "If-Match": this.state.etag }).then((res) => {
            if (res && res.res && res.res.success) {
                this.setState((prevState) => {
                    return {
                        curriculumInfo,
                        editSubjectData,
                        editChapterData: {
                            ...prevState.editChapterData,
                            topics
                        },
                        isLoading: false
                    }
                });
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Curriculum Changed, it will reflect in 5 minutes', life: 3000 });

            } else {

                this.setState({
                    isLoading: false
                })

                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            console.log(e)
            this.setState({
                isLoading: false,

            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });

    }
    //topics --- end


    addOrUpdateResourcesInfo = (resources) => {
        let curriculumInfo = cloneDeep(this.state.curriculumInfo);
        let editChapterData = cloneDeep(this.state.editChapterData);
        let editSubjectData = cloneDeep(this.state.editSubjectData);
        let editTopicData = cloneDeep(this.state.editTopicData);

        editTopicData.resources = resources;
        editChapterData.topics = editChapterData.topics.map(topic => {
            if (topic.topicId == editTopicData.topicId) {
                return editTopicData
            } else {
                return topic;
            }
        });


        editSubjectData.chapters = this.state.editSubjectData.chapters.map((chapter) => {
            if (chapter.chapterId == editChapterData.chapterId) {
                chapter = editChapterData;
            }
            return chapter;
        });

        curriculumInfo.subjects = this.state.curriculumInfo.subjects.map((subject) => {
            if (subject.subjectId == this.state.editSubjectData.subjectId) {
                subject = editSubjectData;
            }
            return subject;
        });

        const url = `${baseUrlAdmin}/subject`;

        this.setState({
            isLoading: true
        })
        this.service.post(url, curriculumInfo, true, { "If-Match": this.state.etag }).then((res) => {
            if (res && res.res && res.res.success) {
                this.setState((prevState) => {
                    return {
                        curriculumInfo,
                        editSubjectData,
                        editChapterData,
                        editTopicData,
                        isLoading: false
                    }
                });
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Curriculum Changed, it will reflect in 5 minutes', life: 3000 });

            } else {

                this.setState({
                    isLoading: false
                })

                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            console.log(e)
            this.setState({
                isLoading: false,

            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });

    }


    onGoBack = () => {

        if (this.state.isShowChapters) {
            this.goBackToSubjects();
        } else if (this.state.isShowTopics) {
            this.goBackToChapters();
        } else {
            this.goBackToTopics();
        }

    }

    showExportToOffline = () => {
        this.setState({ isShowExportToOffline: true });
    };

    downloadOfflineData = () => {
        this.props.getImageUrl(this.state.curriculumInfo.offlineExportStatus.storageKey, 'offline');

        // this.props.getImageUrl('45162249-a1f2-4689-9df6-ca633875bab7.zip', 'offline');
        // let thisObj = this;
        // setTimeout(() => {
        //     window.open(thisObj.props.getAllImagesUrlInfo['45162249-a1f2-4689-9df6-ca633875bab7.zip']);
        // }, 5000);

    };

    parseData = (ndd) => {
        return ndd.map((ind) => {
            // if (ind && parseInt(ind.contentOrigin) == 3) {
            //   ind.contentFileName = ''
            //   ind.contentFile = ''
            // }
            // if (ind.contentFile && ind.contentFile != '') {
            //   ind.contentFile = ind.contentFile.replace('http://mymicroschool.in/API2/v2/getTopic.php/', '')
            //   ind.contentFile = ind.contentFile.replace('https://mymicroschool.in/API2/v2/getTopic.php/', '')
            // }

            if (ind.contentFileName && ind.contentFileName != '') {
                ind.contentFileName = ind.contentFileName.replace('http://mymicroschool.in/API2/v2/getTopic.php/', '')
                ind.contentFileName = ind.contentFileName.replace('https://mymicroschool.in/API2/v2/getTopic.php/', '')


            }


            //  ind.contentOrigin = this.getContentOrigin(parseInt(ind.contentOrigin))

            return ind;
        })
    }


    downloadCompleteCurriculum = async () => {

        try {


            let contents = [];
            if (this.props.boards && this.props.boards.length) {

                let boards = [];
                const boardId = this.state.boardId;
                const classId = this.state.classId;
                const groupId = this.state.groupId;

                if (boardId && classId && groupId) {

                    const selectedBoard =
                        this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

                    const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);

                    const seelctedGroup = selectedClass.groups.find((item) => item.groupId == groupId);


                    if (boardId && classId && groupId) {
                        boards.push({
                            boardId,
                            boardName: selectedBoard.boardName,
                            classId,
                            className: selectedClass.className,
                            groupId,
                            groupName: seelctedGroup.groupName
                        })
                    }
                    else {

                        this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select board, class', life: 3000 });
                        return;

                    }
                } else {
                    this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select board, class', life: 3000 });
                    return;

                }


                this.setState({
                    isLoading: true
                })

                let grades = [];
                //  console.log('boards-1', [...boards])
                for (let index = 0; index < boards.length; index++) {
                    const board = boards[index];
                    let config = {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                        }
                    }

                    let { data } = await axios.get(`${baseUrlAdmin}/board/${board.boardId}/class/${board.classId}/group/${board.groupId}`,
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`,
                                'Access-Control-Allow-Origin': '*',
                            }
                        });
                    if (data && data.Item && data.Item.subjects && data.Item.subjects.length) {
                        data.Item.subjects.forEach((s) => {
                            if (s.chapters && s.chapters.length) {
                                s.chapters.forEach((c) => {
                                    if (c.topics && c.topics.length) {
                                        c.topics.forEach((t) => {
                                            grades.push({
                                                ...boards[index],
                                                subjectName: s.subjectName,
                                                subjectId: s.subjectId,
                                                chapterName: c.chapterName,
                                                chapterId: c.chapterId,
                                                topicName: t.topicName,
                                                topicId: t.topicId
                                            })


                                        })
                                    }
                                })
                            }
                        });
                    }
                }

                //   console.log('grades-2', [...grades])



                for (let index = 0; index < grades.length; index++) {
                    const board = grades[index];
                    let { data } = await axios.get(`${baseUrlAdmin}/board/${board.boardId}/class/${board.classId}/group/${board.groupId}/subject/${board.subjectId}/chapter/${board.chapterId}/topic/${board.topicId}`,
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`,
                                'Access-Control-Allow-Origin': '*',
                            }
                        });
                    if (data && data.Item && data.Item.contentDetails && data.Item.contentDetails.length) {
                        data.Item.contentDetails.forEach((cnt) => {

                            cnt.topicName = undefined;
                            contents.push({
                                ...grades[index],
                                ...cnt,
                                boardName: grades[index].boardName,
                                className: grades[index].className,
                                subjectName: grades[index].subjectName,
                                subjectId: grades[index].subjectId,
                                chapterName: grades[index].chapterName,
                                chapterId: grades[index].chapterId,
                                topicName: grades[index].topicName,
                                topicId: grades[index].topicId,

                            })
                        });
                    }

                }
                contents = this.parseData(contents);
                this.setState({
                    isLoading: false,
                    curriculumDownloadData: contents,
                    isShowCurriculumDownload: true
                });

                //   console.log('contents-3', contents)
            }
        } catch (e) {


            console.log(e)

            this.setState({
                isLoading: false,
            })

            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Some error occured', life: 3000 });
        }



    }


    startExportToOffline = () => {

        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {


            const url = `${baseUrlForLongRunningTask}/content/exportcontentofselectedgrade/board/${boardId}/class/${classId}/group/${groupId}`;
            this.service.get(url, false).then((res) => {
            }).catch((err) => {
                console.log(err);
            });
        } else {
            this.toast.show({ severity: 'error', summary: 'Please select', detail: 'Please select grades', life: 3000 });
        }
    }


    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Curriculum master</h2>
                {/* <p>{this.state.isShowChapters && 'Chapters'}</p> */}
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    exportDialogFooter = () => {
        return (<div>
            <Button label="Proceed" className='ma-m-lr10' onClick={this.startExportToOffline} />
            <Button label="Cancel" className='p-button-outlined' onClick={() => { this.setState({ isShowExportToOffline: false }) }} />
        </div>)
    }
    exportCSV2 = () => {
        this.dt2.exportCSV();
    }

    render() {

        console.log(this.state)
        const header2 = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >Complete Curriculum Data</h2>
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV2}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
            </div>
        );
        return (
            < >
                <Navbar >
                    <div className="p-grid ma-toolbar">
                        <div className="p-col-12 p-md-1 ma-no-p">
                            {(this.state.isShowChapters || this.state.isShowTopics || this.state.isShowResouces) && <>
                                <p className='back-arrow' onClick={this.onGoBack}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                            </>
                            }
                        </div>
                        <div className="p-col-12 p-md-11 ma-no-pm">
                            <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        </div>
                    </div>
                    <div className='ma-main'>
                        {(!this.state.isShowChapters && !this.state.isShowTopics && !this.state.isShowResouces) && <>
                            <Authorizer permId={PERMISSIONS.CURRICULUM_SUBJECTS_LIST} >
                                <Dropdown value={this.state.boardId}
                                    className='ma-m-lr10 ma-w200'
                                    options={this.props.boards}
                                    optionLabel="boardName"
                                    optionValue="boardId"
                                    onChange={(e) => this.onChangeBoard(e.value)}
                                    placeholder="Select Board" />
                                <Dropdown value={this.state.classId}
                                    options={this.state.classes}
                                    className='ma-m-lr10 ma-w200'
                                    onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                                {/* <Dropdown value={this.state.groupId}
                            options={this.state.groups}
                            className='ma-m-lr10 ma-w200'
                            onChange={(e) => this.onChangeGroup(e.value)} placeholder="Select Group" /> */}
                            </Authorizer>
                        </>}
                        {this.state.isShowSubjects && <div >

                            <div className='ma-mtb25'>
                                <Authorizer permId={PERMISSIONS.CURRICULUM_ADD_SUBJECT} >
                                    <Button label="+ Add Subject" className='ma-m-lr10'
                                        onClick={this.showCreateSubject} />
                                </Authorizer>
                                <Authorizer permId={PERMISSIONS.CURRICULUM_DOWNLOAD_DATA_FOR_BULKUPLOAD} >
                                    <Button label="Download Data for Bulk Upload" onClick={() => {
                                        this.setState({
                                            isShowBulkUploadData: true
                                        })
                                    }} className='ma-m-lr10' />
                                </Authorizer>
                                <Authorizer permId={PERMISSIONS.CURRICULUM_DOWNLOAD_DATA} >
                                    <Button label="Download Curriculum Data"
                                        onClick={this.downloadCompleteCurriculum} className='ma-m-lr10' />
                                </Authorizer>
                                {/* 
                                <Button label="Export Data"
                                    disabled={(this.state.curriculumInfo && this.state.curriculumInfo.offlineExportStatus && this.state.curriculumInfo.offlineExportStatus.started)}
                                    tooltip={(this.state.curriculumInfo && this.state.curriculumInfo.offlineExportStatus && this.state.curriculumInfo.offlineExportStatus.message) ? this.state.curriculumInfo.offlineExportStatus.message : ''}
                                    onClick={this.showExportToOffline} className='ma-m-lr10' />
                                 */}

                            </div>
                            <SubjectsList
                                showChapters={this.showChapters}
                                addOrUpdateCurriculumInfo={this.addOrUpdateCurriculumInfo}
                                isSubjectAdd={this.state.isSubjectAdd} editSubject={this.state.editSubjectData}
                                showEditSubjectDialog={this.showEditSubjectDialog}
                                subjects={(this.state.curriculumInfo && this.state.curriculumInfo.subjects) ? this.state.curriculumInfo.subjects : []}
                                boardsHeirarchy={{
                                    boardId: this.state.curriculumInfo?.boardId,
                                    classId: this.state.curriculumInfo?.classId,
                                    groupId: this.state.curriculumInfo?.groupId,
                                    parentBoardId: this.state.parentBoardId,
                                    parentClassId: this.state.parentClassId,
                                    parentGroupId: this.state.parentGroupId,
                                }}
                                etag={this.state.etag}
                                getCurriculumData={this.getCurriculumData}
                                isShowSubjectCreate={this.state.isShowSubjectCreate}
                                syncEnabled={this.props.syncEnabled}
                                hideCreateSubjectDialog={this.hideCreateSubjectDialog} />
                        </div>
                        }
                        {
                            this.state.isShowChapters && <>
                                <ChaptersList showTopics={this.showTopics}
                                    addOrUpdateChapterInfo={this.addOrUpdateChapterInfo}
                                    chapters={this.state.editSubjectData && this.state.editSubjectData.chapters}

                                    boardsHeirarchy={{
                                        boardId: this.state.curriculumInfo?.boardId,
                                        classId: this.state.curriculumInfo?.classId,
                                        groupId: this.state.curriculumInfo?.groupId,
                                        subjectId: this.state.editSubjectData?.subjectId,
                                        parentBoardId: this.state.parentBoardId,
                                        parentClassId: this.state.parentClassId,
                                        parentGroupId: this.state.parentGroupId,
                                    }}
                                    getCurriculumData={this.getCurriculumData}
                                    etag={this.state.etag}
                                    syncEnabled={this.props.syncEnabled}
                                />
                            </>
                        }
                        {
                            this.state.isShowTopics && <>
                                <TopicsList
                                    addOrUpdateTopicInfo={this.addOrUpdateTopicInfo}
                                    topics={this.state.editChapterData && this.state.editChapterData.topics}
                                    showResources={this.showResources}
                                    boardsHeirarchy={{
                                        boardId: this.state.curriculumInfo?.boardId,
                                        classId: this.state.curriculumInfo?.classId,
                                        groupId: this.state.curriculumInfo?.groupId,
                                        subjectId: this.state.editSubjectData?.subjectId,
                                        chapterId: this.state.editChapterData?.chapterId,
                                        parentBoardId: this.state.parentBoardId,
                                        parentClassId: this.state.parentClassId,
                                        parentGroupId: this.state.parentGroupId,
                                    }}
                                    getCurriculumData={this.getCurriculumData}
                                    etag={this.state.etag}
                                    syncEnabled={this.props.syncEnabled}
                                />
                            </>
                        }

                        {
                            this.state.isShowResouces && <>
                                <ResourcesList
                                    //  addOrUpdateTopicInfo={this.addOrUpdateTopicInfo}
                                    addOrUpdateResourcesInfo={this.addOrUpdateResourcesInfo}
                                    resources={this.state.editTopicData && this.state.editTopicData.resources}
                                />
                            </>
                        }

                        {
                            this.state.isShowBulkUploadData && <>
                                <AllDataTableForExcel
                                    curriculumInfo={this.state.curriculumInfo}
                                    hideDataTable={() => {
                                        this.setState({
                                            isShowBulkUploadData: false
                                        })
                                    }} />
                            </>
                        }
                    </div>
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                    <Dialog header="Export to Offline"
                        closeOnEscape={true}
                        closable={false}
                        blockScroll={true}
                        dismissableMask={true}
                        draggable={false}
                        visible={this.state.isShowExportToOffline} style={{ width: '20vw' }} footer={this.exportDialogFooter} onHide={() => this.setState({ isShowExportToOffline: false })}>
                        <p>Would you like to export to offline this grade. It will take some time to export to offline. once exported, you will get a download button.</p>
                    </Dialog>
                    <Dialog
                        draggable={false}
                        closeOnEscape={true}
                        className='ma-alert-box'
                        blockScroll={true}
                        dismissableMask={false}
                        closable={true}

                        style={{ minHeight: '90vh' }}
                        visible={this.state.isShowCurriculumDownload}
                        onHide={() => this.setState({ isShowCurriculumDownload: false, curriculumDownloadData: null })}>
                        <div>
                            <div className="card datatable-crud-demo ma-m30">
                                <DataTable ref={(el) => this.dt2 = el}
                                    value={this.state.curriculumDownloadData}
                                    dataKey="contentId"
                                    header={header2}
                                    emptyMessage=""
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"

                                >
                                    <Column field="boardId" header="Board ID" />
                                    <Column field="boardName" header="Board Name" />
                                    <Column field="classId" header="Class ID" />
                                    <Column field="className" header="Class Name" />
                                    <Column field="subjectId" header="Subject Id" />
                                    <Column field="subjectName" header="Subject Name" />
                                    <Column field="chapterId" header="Chapter Id" />
                                    <Column field="chapterName" header="Chater Name" />
                                    <Column field="topicId" header="Topic Id" />
                                    <Column field="topicName" header="Topic Name" />
                                    <Column field="contentName" header="Resource Name" />
                                    <Column field="contentResourceType" header="Resource Type" />
                                    <Column field="contentFileName" header="Content File Name" />
                                    <Column field="contentId" header="Content Id" />

                                    {/* <Column field="contentFile" header="Content File" />
                                    <Column field="contentOrigin" header="Content Origin" /> */}

                                    <Column field="contentFileType" header="Content File Type" />
                                    <Column field="priority" header="Resource Priority" />
                                </DataTable>
                            </div>
                        </div>

                    </Dialog>



                    {
                        this.props.isLoading || this.state.isLoading && <>
                            <LoadingComponent />
                        </>
                    }
                </Navbar>
            </>
        )
    }
}



const mapStateToProps = (state) => ({
    loading: state.curriculumData && state.curriculumData.loading,
    error: state.curriculumData && state.curriculumData.error && state.curriculumData.error,
    errorInfo: state.curriculumData && state.curriculumData.errorInfo && state.curriculumData.errorInfo,
    subjectData:
        state.curriculumData &&
        state.curriculumData.response &&
        state.curriculumData.response.Item &&
        state.curriculumData.response.Item.subjects &&
        state.curriculumData.response.Item.subjects,
    offlineExportStatus: state.curriculumData &&
        state.curriculumData.response &&
        state.curriculumData.response.Item &&
        state.curriculumData.response.Item.offlineExportStatus &&
        state.curriculumData.response.Item.offlineExportStatus,
    organizationLoading: state.organizationData && state.organizationData.loading,
    organizationError: state.organizationData && state.organizationData.error,
    organizationErrorInfo: state.organizationData && state.organizationData.errorInfo,
    organizationData: state.organizationData && state.organizationData.response && state.organizationData.response,
    boardsInfo:
        state.organizationData &&
        state.organizationData.response &&
        state.organizationData.response.Item &&
        state.organizationData.response.Item,
    selectedOrganizationDataIds: state.selectedOrganizationDataIds && state.selectedOrganizationDataIds,
    updateSubjectResponse: state.updateSubject && state.updateSubject.response && state.updateSubject.response,
    subjectsUpdateError: state.updateSubject && state.updateSubject.error && state.updateSubject.error,
    updateSubjectLoading: state.updateSubject && state.updateSubject.loading && state.updateSubject.loading,
    getAllImagesUrlInfo: state.getImageUrlInfo && state.getImageUrlInfo.allImageUrls,
    syncEnabled: state.orgData.syncEnabled,


    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData && state.boardsData.loading,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});


export default connect(mapStateToProps, {
    getCurriculum,
    //getActiveOrganizationData,
    // selectBoard,
    ///  dispatchClearCurriculumData,
    // selectClass,
    // selectGroup,
    // updateSubject,
    // getSubject,
    // clearSelectedOrganizationData,
    startExportToOffline,
    getImageUrl,
    getBoardsData
})(Authentication(withRouter(CurriculumMaster)));

//Authentication