import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { FIELDS_INFO } from '../../constants';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import { Dropdown } from 'primereact/dropdown';
import InputTextB from './../customComponents/inputTextB';

export default class RoleChange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: [],
            selectedRole: this.props.currentRole ? this.props.currentRole : ''
        }

        this.service = new Service();
    }


    getRoles = () => {
        const url = `${baseUrlAdmin}/authorize/getRolesNamesids?roleType=academic`;

        this.setState({
            isLoading: true
        })
        this.service.get(url, true).then((data) => {
            if (data && data.res && data.res.data) {
                this.setState({
                    roles: data.res.data,
                    isLoading: false
                });
            } else {

                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Unable to get the data', life: 3000 });
            }




        }).catch(e => {

            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }
    componentDidMount() {

        this.getRoles();
    }

    componentDidUpdate(prevProps) {


        if (prevProps.currentRole != this.props.currentRole) {
            this.setState({
                selectedRole: this.props.currentRole
            })

        }
    }


    onSelectRole = (selectedRole) => {

        this.setState({
            selectedRole: selectedRole
        });
    }


    onSubmitClick = () => {

        if (this.state.selectedRole && this.state.selectedRole != '') {
            const url = `${baseUrlAdmin}/admin/changeRole?_id=${this.props._id}&roleId=${this.state.selectedRole}`;
            this.setState({
                isLoading: true
            })
            this.service.put(url, {}, true).then((data) => {


                if (data && data.res && data.res.success) {
                    this.setState({
                        //roles: data.res.data,
                        isLoading: false
                    }, () => {

                        window.location.reload();

                    });
                } else {

                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Unable to change', life: 3000 });
                }

            }).catch(e => {
                this.setState({
                    isLoading: false

                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })



        } else {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select the role to switch', life: 3000 });
        }
    }

    footer = () => {
        return (<div>
            <Button label="Cancel" className='p-button-outlined' onClick={this.props.onCloseDialog} />
            <Button label={`Change`} className='ma-m-lr10' onClick={this.onSubmitClick} />

        </div>)
    }



    render() {
        //  console.log('props', this.props);
        //  console.log('state', this.state);
        const { roles } = this.state;
        return (<><Dialog
            header={`Change Role`}
            draggable={false}
            blockScroll={true}
            footer={this.footer}
            closeOnEscape={true}
            dismissableMask={true}
            visible={true}
            closable={false}
        // onHide={() => this.setState({ showCreateDialog: false })}
        >
            <div className='change-role'>
                <div className="p-grid ">

                    <InputTextB info={FIELDS_INFO.ROLE_SELECT} id="cot12">
                        <Dropdown value={this.state.selectedRole}
                            className='ma-w200'
                            options={roles}
                            optionLabel='roleName'
                            optionValue='_id'
                            onChange={(e) => this.onSelectRole(e.value)}
                            placeholder="Select role" />
                    </InputTextB>
                </div>
            </div>
        </Dialog >
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </>
        )
    }
}
