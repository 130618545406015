import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import React, { Component } from 'react'
import Navbar from '../navbar';
import Payfee from './payFee';
import './styles.scss'
import Profile from './profile';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../loadingComponent';
import { baseUrlAdmin } from '../../store/apiConstants';
import Service from '../../services';
import FeeDetails from '../enqiryForm/feeDetails';
import { RadioButton } from 'primereact/radiobutton';
import { Menu } from 'primereact/menu';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import FeeReciept from '../enqiryForm/feeReciept';
import { Dropdown } from 'primereact/dropdown';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from './../session';
import {
    getBoardsData,
    getBranchesLatest
} from './../../store/actions';

import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import { MultiSelect } from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import moment from 'moment';
import SvgViewer from '../customComponents/svgViewer';
import { PreviewIcon } from '../svgIcons';
import NewFeeReciept from './newFeeReciept';


const items = [
    { label: 'Organization' },
    { label: 'Finance', className: '' },
    { label: 'Fee Collect', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }




class Finance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            admissionNo: '',
            isAdmissionFound: false,
            isLoading: false,
            feeDetails: [],
            admission: null,
            paymentMethod: null,
            amount: 0,

            boardId: "",
            classId: "",
            boards: [],
            classes: [],

            branchLevels: {},
            feeDueStudList: [],

            showSearch: false,
            globalFilter: null,
            columns: [{ columnName: '', isVisible: false, id: '' }],

            assignFeeToStudPopup: false,
            feePlans: [],
            selectedFeeToStud: '',
            selecteUserId: '',
            userInfo: {},
            transactionInfo: '',
            selectedTab: null,

            upiTransactionId: '',
            upiTransactionDate: '',
            upiUtrNumber: '',

            chequeNo: '',
            chequeDate: '',
            bankName: '',
            bankBranch: '',

            cardType: '',
            cardLast4Digits: '',
            cardTransactionId: '',
            cardTransactionDate: '',

            isShowEditRecieptDate: false,
            editRecieptDate: '',
            transactionId: '',
            recieptDate: '',
            selectedFeePlans: [],


            dataToProfile : {}
        }

        this.service = new Service();
    }

    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }

        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }
        this.formState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.boards !== this.props.boards) {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });

                this.setState({
                    boards: boards
                });
            }
        }

        if (prevProps.branchData !== this.props.branchData) {

            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }


    }

    formState = () => {
        if (this.props.filterData) {


            const { boardId, classId, groupId } = this.props.filterData;
            const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === this.props.filterData.boardId);
            if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
                let _classes = [];
                selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));

                this.setState((prevState) => {
                    return {
                        classes: _classes,
                        boardId, classId, groupId
                    };
                });
            }

        }
        // console.log('0', this.state.sectionsData)



    }

    getProfileDetails = (id) => {
        this.setState({
            isLoading: true
        })
        const url = `${baseUrlAdmin}/student-fee/${id}`;
        this.service.get(url, true).then(res => {
            // console.log('dfbfdbfdb',res);
            if (res && res.status) {
                this.setState({
                    isLoading: false,
                    dataToProfile : res?.res?.data?.admission?.user_info,
                    feeCollection : res?.res?.data?.feeCollection,
                    feeDetails : res?.res?.data?.feeDetails,
                    admission : res?.res?.data?.admission
                })
            } else {
                this.setState({
                    isLoading: false
                }, () => {
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            console.log(e);
        })
    }

    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [], classId: null, groupId: null, curriculumInfo: null,
            isShowSubjects: false,
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
            parentBoardId: selectedBoard.parentBoardId,
            isAdmissionFound: false,
            feeDueStudList: []
        });

    };

    onChangeClass = (classId) => {
        this.setState({
            groups: [], groupId: null,
            curriculumInfo: null,
            isShowSubjects: false,
        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                groupId: _groups[0].value,
                parentClassId: selectedClass.parentClassId,
                parentGroupId: selectedClass.groups[0].parentGroupId,

                isAdmissionFound: false,
                feeDueStudList: []
            });
        }

    };

    getSelectedLevelData(data, key) {
        let items = [];
        let selectedNode = data.find(a => a.key == key);
        if (selectedNode && selectedNode.children) {
            selectedNode.children.forEach((c) => {
                items.push(data.find(a => a.key == c))
            })

        }
        // console.log(items)
        return { items, selectedNode };

    }

    onChangeSection = (key, level) => {

        let { sectionsData } = this.state;

        let data = this.getSelectedLevelData(this.props.branchData, key)


        let emptyBeforeLevels = {};

        for (let i = (level + 1); i <= this.props.branchLevels.length; i++) {
            emptyBeforeLevels = {
                ...emptyBeforeLevels,
                ['level' + i]: [],
                ['levelSelected' + i]: null
            }
        }


        let newSectionData = {
            ...sectionsData,
            ...emptyBeforeLevels,
            ['level' + (level + 1)]: data.items,
            ['levelSelected' + (level)]: key
        }

        this.setState({
            sectionsData: newSectionData,
            sectionIds: [],

            isAdmissionFound: false,
            feeDueStudList: []
        });




        //   let selectedSection = this.props.branches.find((s) => s.value == sectionId);





    };


    getFeePlans = (accountId) => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/fee-plans/all/${accountId}`;
        this.service.post(url, {
            boardId: this.state.boardId,
            classId: [this.state.classId],
            sectionId: this.state.sectionIds
        }, true).then(data => {
            if (data.status) {
                if (data.res && data.res.data && data.res.data.length) {
                    let filterValues=data.res.data.filter((fp)=> fp.assigned==true ).map((e)=>e._id)
                    //
                    this.setState({
                        feePlans: data.res.data,
                        selectedFeePlans:filterValues,
                        isLoading: false

                    })
                } else {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some Error Occured', life: 3000 });
                }
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some Error Occured', life: 3000 });
            }
        });
    }

    updateRecievedDate = () => {
        this.setState({
            isLoading: true
        });

        let url = `${baseUrlAdmin}/student-fee/updatedate`;
        this.service.put(url, {
            cancelledDate: this.state.cancelledDate,
            recieptDate: this.state.editRecieptDate,
            transactionId: this.state.transactionId
        }, true).then(data => {
            if (data.status) {
                if (data.status && data.res.status) {
                    this.setState({
                        isLoading: false,
                        isShowEditRecieptDate: false,
                        isAdmissionFound: false
                    }, this.onClickSearch)
                } else {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some Error Occured', life: 3000 });
                }
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some Error Occured', life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some Error Occured', life: 3000 });
        })
    }

    onMultiChange = (value) => {
        this.setState((prevState) => {
            return {
                sectionIds: value,
                isAdmissionFound: false,
                feeDueStudList: []
            };
        });

    }


    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Fee Collect</h2>
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    changeTab = (num) => {
        this.setState({
            selectedTab: num
        })
        this.getProfileDetails(this.state.id)
    }

    handleClearSearch = () => {


        let sectionsData = {};
        if (this.props.branchData && this.props.branchData.length) {
            sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
        }

        this.setState(
            {
                boardId: null,
                classId: null,
                groupId: null,
                branchId: null,
                courseId: [],
                sectionIds: [],
                username: '',
                classes: [],
                sectionsData,
                feeDueStudList: [],
                isAdmissionFound: false,
                amount: '',
                paymentMethod: '',
                transactionInfo: '',

                upiTransactionId: '',
                upiTransactionDate: '',
                upiUtrNumber: '',

                chequeNo: '',
                chequeDate: '',
                bankName: '',
                bankBranch: '',

                cardType: '',
                cardLast4Digits: '',
                cardTransactionId: '',
                cardTransactionDate: '',

                recieptDate: ''
            }
        );
    };

    onClickSearch = () => {

        let isValid = true

        if (this.state.boardId == "" || this.state.boardId == null) {
            isValid = false
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please Select Board', life: 3000 });
        }
        else if (this.state.classId == "" || this.state.classId == null) {
            isValid = false
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please Select Class', life: 3000 });
        }

        let body = {}
        body.boardId = this.state.boardId
        body.classId = this.state.classId
        body.groupId = this.state.groupId
        body.sectionId = this.state.sectionIds
        // console.log('first',body)
        if (isValid) {

            this.setState({
                isLoading: true
            })


            const url = `${baseUrlAdmin}/student-fee`;
            this.service.post(url, body, true).then(res => {
                if (res && res.status) {
                    this.setState({
                        isLoading: false,
                        feeDueStudList: res?.res?.data?.Items,
                        // feeDetails: res.res.data?.StudentsFeeDetails?.feeDetails,
                        // admission: res.res.data?.StudentsFeeDetails?.admission,
                        selectedTab: 1,
                        // isAdmissionFound: true
                    })
                    if (this.state.feeDueStudList.length === 0) {
                        this.toast.show({ severity: 'info', summary: 'No Data Found', detail: '', life: 3000 });
                    }
                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                console.log(e);
            })
        }
        // if (this.state.admissionNo == "") {
        //     this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please Enter Student Name Or Admission Number', life: 3000 });
        // } else if (this.state.admissionNo.length != 9) {
        //     this.toast.show({ severity: 'error', summary: 'Error', detail: 'Admission number should be 9 characters', life: 3000 });
        // } else {


        //     
    }
    onPaymentModeChange = (e) => this.setState((prevState) => {

        if (e.value == 'cheque') {
            prevState.upiTransactionId = ''
            prevState.upiTransactionDate = ''
            prevState.upiUtrNumber = ''

            prevState.cardType = ''
            prevState.cardLast4Digits = ''
            prevState.cardTransactionId = ''
            prevState.cardTransactionDate = ''
        }
        if (e.value == 'credit/debit') {
            prevState.upiTransactionId = ''
            prevState.upiTransactionDate = ''
            prevState.upiUtrNumber = ''

            prevState.chequeNo = ''
            prevState.chequeDate = ''
            prevState.bankName = ''
            prevState.bankBranch = ''

        }
        if (e.value == 'upiPayment') {
            prevState.chequeNo = ''
            prevState.chequeDate = ''
            prevState.bankName = ''
            prevState.bankBranch = ''

            prevState.cardType = ''
            prevState.cardLast4Digits = ''
            prevState.cardTransactionId = ''
            prevState.cardTransactionDate = ''
        }
        return {
            paymentMethod: e.value

        }

    })


    onSave = () => {
        const { amount, paymentMethod, transactionInfo, chequeDate, chequeNo, cardType, cardLast4Digits, cardTransactionId, upiTransactionId, upiUtrNumber, cardTransactionDate, upiTransactionDate, bankName, bankBranch, recieptDate } = this.state;
        let totalFeeToBePaid = this.state.feeDetails.reduce((total, fee) => {
            return total + parseInt(fee.balance);
        }, 0);
        console.log("totalFeeToBePaid", totalFeeToBePaid)
        if (!amount || amount == '') {
            return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter amount', life: 3000 });
        }
        if (parseInt(amount) > totalFeeToBePaid) {
            return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter amount less than the total amount', life: 3000 });
        }
        if (!paymentMethod || paymentMethod == "") {
            return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select payment mode', life: 3000 });
        }
        if ((paymentMethod === 'cheque') && ((!chequeDate || chequeDate === '') || (!chequeNo || chequeNo === ''))) {
            return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter proper cheque details', life: 3000 });
        }
        if ((paymentMethod === 'credit/debit') && ((!cardType || cardType === '') || (!cardLast4Digits || cardLast4Digits === '' || cardLast4Digits.toString().length < 4) || (!cardTransactionId || cardTransactionId === ''))) {
            return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter proper card details', life: 3000 });
        }
        if ((paymentMethod === 'upiPayment') && ((!upiTransactionId || upiTransactionId === '') || (!upiUtrNumber || upiUtrNumber === ''))) {
            return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter proper upi details', life: 3000 });
        } if (!recieptDate || recieptDate === '') {
            return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select reciept date', life: 3000 });
        } else {
            let body = {};
            body.amount = parseInt(amount);
            body.paymentMethod = paymentMethod;
            body.feeDetails = this.state.feeDetails;
            body.admission = this.state.admission;
            // body.transactionInfo = this.state.transactionInfo;

            body.chequeNo = chequeNo
            body.chequeDate = chequeDate
            body.bankName = bankName
            body.bankBranch = bankBranch

            body.cardType = cardType
            body.cardLast4Digits = cardLast4Digits
            body.cardTransactionId = cardTransactionId
            body.cardTransactionDate = cardTransactionDate

            body.upiTransactionId = upiTransactionId
            body.upiTransactionDate = upiTransactionDate
            body.upiUtrNumber = upiUtrNumber

            // userInfo
            body.studentName = this.state.userInfo.given_name ? this.state.userInfo.given_name : ""
            body.fatherName = this.state.admission.user_info.fatherName ? this.state.admission.user_info.fatherName : ""
            body.registrationNo = this.state.id
            body.academicYear = this.state.admission.user_info.academicYear ? this.state.admission.user_info.academicYear : ""
            body.boardName = this.state.userInfo["custom:board"]
            body.className = this.state.userInfo["custom:grade"]
            body.recieptDate = moment(this.state.recieptDate).utc('Asia/Kolkata').toISOString();

            this.setState({
                isLoading: true
            })

            const url = `${baseUrlAdmin}/student-fee/collect`;

            this.service.post(url, body, true).then(res => {

                if (res && res.status) {
                    this.setState({
                        isLoading: false,

                        reciept: res.res.data.reciept,
                        transactionId: res?.res?.data?._id,
                        isShowFeePrint: true,

                        upiTransactionId: '',
                        upiTransactionDate: '',
                        upiUtrNumber: '',

                        chequeNo: '',
                        chequeDate: '',
                        bankName: '',
                        bankBranch: '',

                        cardType: '',
                        cardLast4Digits: '',
                        cardTransactionId: '',
                        cardTransactionDate: '',

                        recieptDate: ''
                    })

                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                console.log(e);
            })



        }
    }

    onAssingnFeeToStud = () => {
        
        console.log(this.state.selecteUserId, this.state.selectedFeeToStud, 'wstat')
        let feeDetails = []
        feeDetails=this.state.feePlans.filter(e => !e.assigned && this.state.selectedFeePlans.includes(e._id))
        if(feeDetails?.length === 0)
        {
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please select atleast one plan' })
            return;
        }
        //feeDetails = this.state.feePlans.filter(e => this.state.selectedFeePlans.includes(e._id))
        let body = {}
        body.levelId = feeDetails[0].levelId
        body.user_id = this.state.selecteUserId
       
        body.feeDetails = feeDetails.map((vl) => vl?.feeTypes?.map(v => ({ ...v, planName: vl?.planName , planId:vl?._id}))).filter((e) => e).flat()
       
        body.levelIds = feeDetails.map((vl) => vl.levelId).filter((e) => e)
        if (this.state.selectedFeePlans.length === 0 || feeDetails.length === 0) {
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please select atleast one plan' })
        } else {
            this.setState({
                isLoading: true
            });

            let url = `${baseUrlAdmin}/student-fee/update`;
            this.service.post(url, body, true).then(data => {
                if (data.status) {
                    if (data.status && data.res.status) {
                        this.setState({
                            selecteUserId: '',
                            selectedFeeToStud: '',
                            assignFeeToStudPopup: false,
                            isLoading: false,
                            selectedFeePlans: []
                        }, this.onClickSearch)
                        this.toast.show({ severity: 'success', summary: 'Success', detail: 'successfully assigned', life: 3000 })
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured' })

                    }
                } else {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured' })
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured' })
            });
        }
    }

    onClickViewFeeDetails = (rowData) => {

        this.setState({
            isAdmissionFound: true,
            selectedTab: 1,
            // feeDetails : rowData.feeDetails,
            id: rowData.user_id,
            userInfo: rowData.user_info
        })
        this.getProfileDetails(rowData.user_id)
        
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    onClickViewReciept = (rowData) => {
        this.setState({
            reciept: rowData.reciept,
            isShowFeePrint: true,
            transactionId: rowData._id
        })
    }

    showEditRecieptDate = (rowData) => {
        this.setState({
            isShowEditRecieptDate: true,
            editRecieptDate: rowData.reciept.recieptDate,
            cancelledDate: rowData.reciept.recieptDate,
            transactionId: rowData._id
        })
    }

    selectFeePlan = (colId) => {
        
        let cloneSelectedFeeplanIds = this.state.selectedFeePlans
        let existIndex = cloneSelectedFeeplanIds.indexOf(colId)
        if (existIndex >= 0) {
            cloneSelectedFeeplanIds.splice(existIndex, 1)
        } else {
            cloneSelectedFeeplanIds.push(colId)
           
        }
        this.setState({
            selectedFeePlans: cloneSelectedFeeplanIds
        })
    }

    
    render() {
        const { selectedTab, amount, paymentMethod, transactionInfo, selectedFeePlans } = this.state
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'>Fee Collect</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={(e) => this.setState({ globalFilter: e.target.value })} />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.CURRICULUM_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <Authorizer permId={PERMISSIONS.CURRICULUM_VIEWCOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" data-pr-position="bottom" onClick={(event) => this.menu.toggle(event)}
                                className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.CURRICULUM_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        return (
            <>
                <Navbar>
                    <div className='p-grid ma-toolbar '>
                        <div className="p-col-12 p-md-11 ma-no-p toolbar">
                            <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        </div>
                    </div>
                    <div className='ma-main'>
                        {/* <label className='ma-table-title'>Admission Number (Username)</label>
                        <InputText className='p-inputtext-style1 p-ml-3'
                            value={this.state.admissionNo}
                            onChange={(e) => this.setState({ admissionNo: e.target.value })}
                        /> */}
                        <div>
                            <Authorizer permId={PERMISSIONS.BOARDS_SEARCH} >
                                <Dropdown
                                    value={this.state.boardId}
                                    className='ma-m-lr10 ma-w200'
                                    options={this.props.boards}
                                    optionLabel="boardName"
                                    optionValue="boardId"
                                    onChange={(e) => this.onChangeBoard(e.value)}
                                    placeholder="Select Board" />
                                <span className='ma-required-mark '>*</span>
                                <Dropdown
                                    value={this.state.classId}
                                    options={this.state.classes}
                                    className='ma-m-lr10 ma-w200'
                                    onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                                <span className='ma-required-mark '>*</span>
                            </Authorizer>
                            {
                                this.props.branchLevels ? this.props.branchLevels.map((level, index) => {
                                    return (!(this.props.branchLevels.length == index + 1) ?
                                        <Dropdown
                                            className='ma-mr20 ma-mt10 ma-w200'
                                            value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                            // className='ma-w100p'
                                            //    disabled={this.state.hideUpdateButton}
                                            optionLabel={"name"}
                                            optionValue={"key"}
                                            options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}

                                            onChange={(e) => this.onChangeSection(e.value, index + 1)}
                                            placeholder={`--Select ${level.levelName}--`} /> :
                                        <MultiSelect
                                            className='ma-mr20 ma-mt10 ma-w200'
                                            value={this.state.sectionIds ? this.state.sectionIds : []}
                                            // value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                            onChange={(e) => this.onMultiChange(e.value)}
                                            options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}
                                            optionLabel={"name"}
                                            optionValue={"key"}
                                            placeholder={`--Select ${level.levelName}--`}

                                        // onChange={(e) => setCities(e.value)} 
                                        />
                                    )
                                }) : null
                            }
                        </div>
                        <div className='ma-mt20'>
                            <Authorizer permId={PERMISSIONS.BOARDS_SEARCH} >
                                <Button label='Search' className='p-ml-3' onClick={this.onClickSearch} />
                                <Button label="Clear" className='ma-m-lr10 p-button-outlined' onClick={this.handleClearSearch} />
                            </Authorizer>
                        </div>
                        {(this.state.feeDueStudList && this.state.feeDueStudList.length >= 0) && !this.state.isAdmissionFound &&
                            <div className='card datatable-crud-demo ma-m30 '>
                                <DataTable
                                    ref={(el) => this.dt = el}
                                    //  lazy
                                    scrollable
                                    value={this.state.feeDueStudList}
                                    // paginatorLeft={() => {
                                    //     return <>
                                    //         <p className='avail-q'> Available Boards:  <span>{this.props.boards && this.props.boards.length}</span> </p>
                                    //     </>
                                    // }}
                                    // selection={this.state.selectedProducts}
                                    // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}

                                    className="ma-table-d"
                                    tableClassName='ma-table-tbl'
                                    dataKey="id"
                                    paginator
                                    rows={10}
                                    responsiveLayout="scroll"
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Studnets"
                                    globalFilter={this.state.globalFilter}
                                    header={header}
                                >
                                    {/* <Column header='Transport' field='transport'/> */}
                                    <Column header='Student Name' body={(rowData) => {
                                        return (
                                            <p>{rowData.user_info.given_name}</p>
                                        )
                                    }} />
                                    {/* <Column header='Academic Year' field='academicYear'/> 
                                {/* <Column header='Admission Type' field='admissionType'/> */}
                                    {/* <Column header='Gender' field='gender'/> */}
                                    {/* <Column header='Father Name' field='fatherName'/> */}
                                    <Column header='Father MobileNo' field='phone_number' body={(rowData) => {
                                        return (
                                            <p>{rowData.user_info.phone_number}</p>
                                        )
                                    }} />
                                    <Column header='Admission No' field='' body={(rowData) => {
                                        return (
                                            <p>{rowData.user_info.username}</p>
                                        )
                                    }} />
                                    {/* <Column header='Application Number' field='applicationNumber'/> */}
                                    {/* <Column header='Admission Payment Mode' field='admissionPaymentMode'/> */}
                                    {/* <Column header='Stage' field='stage'/> */}
                                    <Column header='Assign Fee' body={(rowData) => {
                                        return (

                                            <Button label='Assign Fee' className='p-button-outlined' onClick={() => this.setState({ assignFeeToStudPopup: true, selecteUserId: rowData.user_id }, this.getFeePlans(rowData.account_id))} />
                                        )
                                    }} />
                                    <Column header='View Fee Details' body={(rowData) => {
                                        return (
                                            <Button label='Fee Details' onClick={() => this.onClickViewFeeDetails(rowData)} />
                                        )
                                    }} />
                                </DataTable>
                            </div>
                        }
                        {this.state.isAdmissionFound &&
                            <div className='ma-mt20  ma-p20'>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6 p-lg-4 p-xl-4">
                                        <div className="p-grid doubts-tabs">
                                            <div className={`p-col-4 p-md-4 doubts-tab-w ${selectedTab == 1 ? 'tab-active' : ''}`}>
                                                <p className={`ma-pointer tab-p  ${selectedTab == 1 ? 'tab-active-p' : ''}`} onClick={() => { this.changeTab(1) }}>Profile</p>
                                            </div>
                                            <div className={`p-col-4 p-md-4 doubts-tab-w ${selectedTab == 2 ? 'tab-active' : ''}`}>
                                                <p className={`ma-pointer tab-p ${selectedTab == 2 ? 'tab-active-p' : ''}`} onClick={() => { this.changeTab(2) }}>Pay Fee</p>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='p-col-8 p-md-8 backInFeeDetails'>
                                        <p><i className="pi pi-arrow-circle-left pointer ma-mr10" style={{ fontSize: '1.5rem' }} onClick={() => {
                                            this.setState({
                                                isAdmissionFound: false,
                                                selectedTab: 1,
                                                amount: '',
                                                paymentMethod: '',
                                                transactionInfo: ''
                                            })
                                        }}></i></p>
                                    </div>
                                </div>
                                {/* <div className='p-mt-3 p-mb-3'>
                                    <div className='qb-tabs'>
                                        <span onClick={() => { this.changeTab(1) }} className={selectedTab == 1 ? 'qb-active-tab' : 'qb-inactive-tab'}>Profile</span>
                                        <span onClick={() => { this.changeTab(2) }} className={selectedTab == 2 ? 'qb-active-tab' : 'qb-inactive-tab'}>Pay Fee</span>
                                    </div>
                                </div> */}
                                {
                                    selectedTab == 1 &&
                                    <>
                                        <Profile className='p-mb-4'
                                            dataToProfile = {this.state.dataToProfile} 
                                            // id={this.state.id}
                                        // admission={this.state.admission} 
                                        />
                                        <div className='ma-mt30'>
                                            <DataTable dataKey='_id'
                                                header={<h2>Previous Paid Reciepts</h2>}
                                                value={this.state.feeCollection}
                                            >
                                                <Column field='collectedBy' header='Collected By' />
                                                <Column field='paymentMethod' header='Payment Method' />
                                                <Column field='paymentFor' header='Payment For' />
                                                <Column field='amount' header='Amount' />
                                                <Column header='Created At' body={(rowData) => {
                                                    return (
                                                        <>
                                                            <p>{moment(rowData.createdAt).format('DD-MM-YYYY')}</p>
                                                        </>
                                                    )
                                                }} />
                                                <Column header="Actions" body={(rowData, { rowIndex }) => {
                                                    return (
                                                        <div className='preview-text'>
                                                            <span data-pr-tooltip="View Reciept" data-pr-position="top" className={`edd${rowIndex}`}
                                                                onClick={() => this.onClickViewReciept(rowData)}>
                                                                <PreviewIcon height={15} width={15} />
                                                            </span>
                                                            <Tooltip className="table-li-tooltip" autoHide={false} target={`.edd${rowIndex}`} />

                                                            <span data-pr-tooltip="Edit Recipet Date" data-pr-position="top" className={`ma-ml10 p-ml-3 ar${rowIndex}`} onClick={() => this.showEditRecieptDate(rowData)} >
                                                                <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="15" height="15" />
                                                            </span>
                                                            <Tooltip className="table-li-tooltip" autoHide={false} target={`.ar${rowIndex}`} />
                                                        </div>
                                                    )
                                                }}
                                                />
                                            </DataTable>
                                        </div>
                                    </>
                                }
                                {
                                    selectedTab == 2 && <div className='p-grid  white-bg tab-active-body'>
                                        <div className='p-col-12'>

                                            <FeeDetails feeDetails={this.state.feeDetails} getProfileDetails={this.getProfileDetails} id={this.state.id}/>

                                            <div>
                                                <div className='flex'>
                                                    <div>
                                                        <p className='ma-label-s2 ma-mt10'>Amount <span className='ma-required'>*</span></p>
                                                        <InputNumber
                                                            placeholder='Enter amount paying'
                                                            className='inputNumberStyle ma-w30p '
                                                            value={amount}
                                                            onChange={(e) => {
                                                                this.setState((prevState) => {
                                                                    return {
                                                                        amount: e.value

                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='ma-ml20 ma-mt10'>
                                                        <p className='ma-label-s2'>Reciept Date <span className='ma-required'>*</span></p>
                                                        <Calendar showIcon className='' maxDate={new Date()}
                                                            value={this.state.recieptDate}
                                                            onChange={(e) => this.setState({ recieptDate: e.target.value })}
                                                        />
                                                    </div>
                                                </div>

                                                <div className=" ma-mt30">
                                                    <p className='ma-label-s2'>Payment Mode<span className='ma-required'>*</span></p>
                                                    <div className="p-grid p-mt-1">
                                                        <div className="p-col-1 p-md-1">
                                                            <RadioButton
                                                                inputId="paymentMode1" name="paymentMode" value="cash"
                                                                onChange={this.onPaymentModeChange}
                                                                checked={paymentMethod === 'cash'} />
                                                            <label htmlFor="Cash" className="p-ml-2">Cash</label>
                                                        </div>
                                                        <div className="p-col-1 p-md-1">
                                                            <RadioButton
                                                                inputId="paymentMode2" name="paymentMode" value="cheque"
                                                                onChange={this.onPaymentModeChange}
                                                                checked={paymentMethod === 'cheque'} />
                                                            <label htmlFor="Cheque" className="p-ml-2">Cheque</label>
                                                        </div>
                                                        <div className="p-col-2 p-md-2" style={{ width: "150px" }}>
                                                            <RadioButton
                                                                inputId="paymentMode3" name="paymentMode" value="credit/debit"
                                                                onChange={this.onPaymentModeChange}
                                                                checked={paymentMethod === 'credit/debit'} />
                                                            <label htmlFor="Credit/Debit" className="p-ml-2">Credit/Debit</label>
                                                        </div>
                                                        <div className="p-col-1 p-md-2">
                                                            <RadioButton
                                                                inputId="paymentMode4" name="paymentMode" value="upiPayment"
                                                                onChange={this.onPaymentModeChange}
                                                                checked={paymentMethod === 'upiPayment'} />
                                                            <label htmlFor="upiPayment" className="p-ml-2">UPI Payment</label>
                                                        </div>
                                                    </div>

                                                    {/* {paymentMethod === 'cash' && 
                                                            <div style={{display : "flex",gap:'30px',marginTop : '20px',marginBottom : '20px'}}>
                                                                <div>    
                                                                    <label className="p-ml-2">Remarks </label>
                                                                    <div>    
                                                                        <InputText className='p-inputtext-style1 p-mt-1' 
                                                                            value={transactionInfo} 
                                                                            onChange={(e)=>this.setState({transactionInfo : e.target.value})}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        } */}
                                                    {paymentMethod === 'cheque' &&
                                                        <div className='' style={{ display: "flex", gap: '30px', marginTop: '20px', marginBottom: '20px' }}>
                                                            <div>
                                                                <label className="">Cheque No<span className='ma-required'>*</span> </label>
                                                                <div>
                                                                    <InputText className='p-inputtext-style1 p-mt-1'
                                                                        value={this.state.chequeNo}
                                                                        onChange={(e) => this.setState({ chequeNo: e.target.value })} />
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <label className="">Cheque Date<span className='ma-required'>*</span> </label>
                                                                <div>
                                                                    <Calendar showIcon className='p-mt-1'
                                                                        value={this.state.chequeDate}
                                                                        onChange={(e) => this.setState({ chequeDate: e.target.value })}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <label className="">Bank Name</label>
                                                                <div>
                                                                    <InputText className='p-inputtext-style1 p-mt-1'
                                                                        value={this.state.bankName}
                                                                        onChange={(e) => this.setState({ bankName: e.target.value })} />
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <label className="">Branch</label>
                                                                <div>
                                                                    <InputText className='p-inputtext-style1 p-mt-1'
                                                                        value={this.state.bankBranch}
                                                                        onChange={(e) => this.setState({ bankBranch: e.target.value })} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {paymentMethod === 'credit/debit' &&
                                                        <div className='' style={{ display: "flex", gap: '30px', marginTop: '20px', marginBottom: '20px' }}>
                                                            <div>
                                                                <label className="">Type of Card<span className='ma-required'>*</span> </label>
                                                                <div>
                                                                    <InputText className='p-inputtext-style1 p-mt-1'
                                                                        value={this.state.cardType}
                                                                        onChange={(e) => this.setState({ cardType: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <label className="">Last 4 Digits Of Card<span className='ma-required'>*</span> </label>
                                                                <div>
                                                                    <InputNumber className='inputNumberStyle p-mt-1'
                                                                        value={this.state.cardLast4Digits}
                                                                        onChange={(e) => {
                                                                            this.setState((prevState) => {
                                                                                return {
                                                                                    cardLast4Digits: e.value

                                                                                }
                                                                            })
                                                                        }
                                                                            // this.setState({cardLast4Digits : e.target.value})
                                                                        } />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <label className="">Transaction ID<span className='ma-required'>*</span> </label>
                                                                <div>
                                                                    <InputText className='p-inputtext-style1 p-mt-1'
                                                                        value={this.state.cardTransactionId}
                                                                        onChange={(e) => this.setState({ cardTransactionId: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <label className="">Date<span className='ma-required'>*</span> </label>
                                                                <div>
                                                                    <Calendar showIcon className='p-mt-1'
                                                                        value={this.state.cardTransactionDate}
                                                                        onChange={(e) => this.setState({ cardTransactionDate: e.target.value })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {paymentMethod === 'upiPayment' &&
                                                        <div style={{ display: "flex", gap: '30px', marginTop: '20px', marginBottom: '20px' }}>
                                                            <div>
                                                                <label className="">Transaction ID<span className='ma-required'>*</span> </label>
                                                                <div>
                                                                    <InputText className='p-inputtext-style1 p-mt-1'
                                                                        value={this.state.upiTransactionId}
                                                                        onChange={(e) => this.setState({ upiTransactionId: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <label className="">Date<span className='ma-required'>*</span> </label>
                                                                <div>
                                                                    <Calendar showIcon className='p-mt-1'
                                                                        value={this.state.upiTransactionDate}
                                                                        onChange={(e) => this.setState({ upiTransactionDate: e.target.value })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <label className="">UTR Number<span className='ma-required'>*</span> </label>
                                                                <div>
                                                                    <InputText className='p-inputtext-style1 p-mt-1'
                                                                        value={this.state.upiUtrNumber}
                                                                        onChange={(e) => this.setState({ upiUtrNumber: e.target.value })} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                                <Button label='Save' className='ma-mt10' onClick={this.onSave} />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }

                    </div>
                    {
                        this.state.isShowFeePrint && <NewFeeReciept
                            reciept={this.state.reciept}
                            transactionId={this.state.transactionId}
                            hideFeeReciept={() => {
                                if (selectedTab === 1) {
                                    this.setState({
                                        isShowFeePrint: false,
                                        feeDetails: null,
                                        admission: null,
                                        selectedTab: 1,
                                        reciept: null,
                                        admissionNo: '',
                                        amount: 0,
                                        paymentMethod: null,
                                        isAdmissionFound: true,
                                        amount: '',
                                        paymentMethod: '',
                                        transactionInfo: ''
                                    })
                                }
                                if (selectedTab === 2) {
                                    this.setState({
                                        isShowFeePrint: false,
                                        feeDetails: null,
                                        admission: null,
                                        selectedTab: 0,
                                        reciept: null,
                                        admissionNo: '',
                                        amount: 0,
                                        paymentMethod: null,
                                        isAdmissionFound: false,
                                        amount: '',
                                        paymentMethod: '',
                                        transactionInfo: ''
                                    })
                                }
                            }}
                        />
                    }
                    {/* {
                        (this.state.isShowFeePrint) && <FeeReciept
                            reciept={this.state.reciept}
                            transactionId={this.state.transactionId}
                            hideFeeReciept={() => {
                                if (selectedTab === 1) {
                                    this.setState({
                                        isShowFeePrint: false,
                                        feeDetails: null,
                                        admission: null,
                                        selectedTab: 1,
                                        reciept: null,
                                        admissionNo: '',
                                        amount: 0,
                                        paymentMethod: null,
                                        isAdmissionFound: true,
                                        amount: '',
                                        paymentMethod: '',
                                        transactionInfo: ''
                                    })
                                }
                                if (selectedTab === 2) {
                                    this.setState({
                                        isShowFeePrint: false,
                                        feeDetails: null,
                                        admission: null,
                                        selectedTab: 0,
                                        reciept: null,
                                        admissionNo: '',
                                        amount: 0,
                                        paymentMethod: null,
                                        isAdmissionFound: false,
                                        amount: '',
                                        paymentMethod: '',
                                        transactionInfo: ''
                                    })
                                }
                            }}
                        />
                    } */}
                    <Dialog
                        visible={this.state.assignFeeToStudPopup}
                        blockScroll={true}
                        style={{ width: '40%' }}
                        header={'Assign Fee'}
                        modal
                        footer={() => {
                            return (<div className='ma-mt20'>
                                <Button label="Cancel" className='p-button-outlined' onClick={() => this.setState({ assignFeeToStudPopup: false ,feePlans : []})} />
                                <Button label='Assign' className='ma-m-lr10' onClick={this.onAssingnFeeToStud} />
                            </div>)
                        }}
                        draggable={false}
                        closeOnEscape={false}
                        dismissableMask={false}
                        closable={false}
                        onHide={this.state.assignFeeToStudPopup}
                    >
                        <div className="p-grid subject-add-dialog">
                            <DataTable
                                ref={(el) => this.dt = el}
                                value={this.state.feePlans}
                                dataKey="_id"
                                // paginator
                                // lazy
                                // onPage={this.onPage}
                                // first={this.state.lazyParams.first}
                                // last={this.state.totalRecords}
                                // rows={this.state.lazyParams.rows}
                                // totalRecords={this.state.totalRecords}
                                responsiveLayout="scroll"
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Fee Plans"
                                globalFilter={this.state.globalFilter}
                            // selection={this.state.selectedCustomers}
                            // onSelectionChange={e => this.setState({ selectedCustomers: e.value })}
                            >
                                <Column header='Select' className='w-10' body={(rowData) => {
                                    return (
                                        // <RadioButton value={rowData._id}
                                        //     onChange={(e) => { this.setState({ selectedFeeToStud: e.target.value }) }}
                                        //     checked={this.state.selectedFeeToStud === rowData._id}
                                        //     className="field-radiobutton"
                                        // />
                                       
                                        <div >
                                            <Checkbox onChange={() => this.selectFeePlan(rowData._id)} inputId={rowData.id}
                                                checked={this.state.selectedFeePlans.includes(rowData._id)}
                                                disabled = {rowData?.assigned ?  true : false}
                                            />
                                            <label htmlFor={rowData.id} className="p-checkbox-label p-name" />
                                        </div>
                                    )
                                }} />
                                <Column header='Fee Plan Name' field='planName' />
                            </DataTable>
                        </div>
                    </Dialog>
                    <Dialog
                        blockScroll={true}
                        style={{ width: '25%' }}
                        header={'Reciept Date'}
                        modal
                        footer={() => {
                            return (
                                <div className='ma-mt20'>
                                    <Button label="Cancel" className='p-button-outlined' onClick={() => this.setState({ isShowEditRecieptDate: false })} />
                                    <Button label='Update' className='ma-m-lr10' onClick={this.updateRecievedDate} />
                                </div>
                            )
                        }}
                        draggable={false}
                        closeOnEscape={false}
                        dismissableMask={false}
                        closable={false}
                        onHide={this.state.isShowEditRecieptDate}
                        visible={this.state.isShowEditRecieptDate}
                    >
                        <div className='flex'>
                            <label className="p-mt-3 mr-5">Select Date<span className='ma-required'>*</span> </label>
                            <div>
                                <Calendar showIcon className='p-mt-1'
                                    value={this.state.editRecieptDate ? new Date(this.state.editRecieptDate) : null}
                                    onChange={(e) => this.setState({ editRecieptDate: e.target.value })}
                                />
                            </div>
                        </div>
                    </Dialog>
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />

                    {this.state.isLoading && <LoadingComponent />}
                </Navbar>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    isUpdated: state.boardsData.isUpdated,
    boardsData: state.boardsData,
    branches: state.branchDataLatest.branches,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
})


export default connect(mapStateToProps, {
    getBoardsData,
    getBranchesLatest
})(Authentication(withRouter(Finance)))