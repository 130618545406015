import React, { Component } from 'react'
import './style.scss';

export default class AttachPreviewDelete extends Component {

    render() {
        return (<div className='attach-preview-d'>
            <img
                src={this.props.imagePath}
                alt="no-img"
                className='question-img'
            />
            {
                !this.props.disableRemove && <span class="attach-remove" onClick={this.props.removeAttach}><i className='pi pi-times'> </i></span>
            }
            
        </div>
        )
    }
}
