// import messages from './Intl.json'
import en from './internationilizationLanguages/en.json'
import hi from './internationilizationLanguages/hi.json'
import gu from './internationilizationLanguages/gu.json'
import bn from './internationilizationLanguages/bn.json'
import ml from './internationilizationLanguages/ml.json'
import mr from './internationilizationLanguages/mr.json'
import pa from './internationilizationLanguages/pa.json'
import ta from './internationilizationLanguages/ta.json'
import te from './internationilizationLanguages/te.json'
import kn from './internationilizationLanguages/kn.json'

const languageObj = {
  en, hi, gu, bn, ml, mr, pa, ta, te, kn
}

export const I18Next = (fieldName, lang) => {
  let messages = languageObj[lang] || languageObj['en']
  return {
    COMMON_KEY: `${messages[fieldName]}`,
  };
};