import * as ActionType from "../actionTypes";

export default (state = {}, action) => {
  switch(action.type){
    case ActionType.REQUEST_TAGS:

        return{
            ...state,
            loading:true,
            error:false,
            errorInfo:null
            }
    case ActionType.RECEIVED_TAGS :
        return{
            ...state,
            loading:false,
            response:action.res,
            error:false,
            errorInfo:null
        }
    case ActionType.ERROR_TAGS:
        return{
            ...state,
            loading:false,
            error:true,
            errorInfo:action.err
        }
    case ActionType.CLEAR_TAGS:
        return{
            state: {}
        }
    default : 
      return state    

  }
}