import * as ActionTypes from '../actionTypes';
import axios from 'axios';
import { baseUrlAdmin } from '../apiConstants';

const dispatchRequestCurriculumData = data => ({
    type: ActionTypes.REQUEST_CURRICULUM,
    data
})

const dispatchReceivedCurriculumData = res => ({
    type: ActionTypes.RECEIVED_CURRICULUM,
    res
})

const dispatchErrorCurriculumData = err => ({
    type: ActionTypes.ERROR_CURRICULUM,
    err
})

const dispatchRequestCurriculumBranchData = data => ({
    type: ActionTypes.REQUEST_CURRICULUM_BRANCH,
    data
});

const dispatchReceivedCurriculumBranchData = res => ({
    type: ActionTypes.RECEIVED_CURRICULUM_BRANCH,
    res
});

const dispatchErrorCurriculumBranchData = err => ({
    type: ActionTypes.ERROR_CURRICULUM_BRANCH,
    err
});

export const dispatchClearCurriculumData = () => ({
    type: ActionTypes.CLEAR_CURRICULUM_DATA
});

const dispatchFindSelectedSubjectFromSubjects = data => ({
    type: ActionTypes.SELECTED_SUBJECT_DATA,
    data
});

const dispatchFindSelectedSubjectQuestionsFromSubjects = data => ({
    type: ActionTypes.SELECTED_SUBJECT_QUESTIONS_DATA,
    data
});

const dispatchRequestFindSelectedSubjectQuestionsFromSubjects = (data) => ({
    type: ActionTypes.REQUEST_SELECTED_SUBJECT_QUESTIONS_DATA,
    data
});

const dispatchErrorFindSelectedSubjectQuestionsFromSubjects = (err) => ({
    type: ActionTypes.THROW_ERROR_SELECTED_SUBJECT_QUESTIONS_DATA,
    err
});

const dispatchRequestCreateAdhocMockTest = (data) => ({
    type: ActionTypes.REQUEST_CREATE_ADHOC_MOCK_TEST,
    data
});

const dispatchReceivedCreateAdhocMockTest = (res) => ({
    type: ActionTypes.RECIVED_CREATE_ADHOC_MOCK_TEST,
    res
});

const dispatchErrorCreateAdhocMockTest = (err) => ({
    type: ActionTypes.THROW_ERROR_CREATE_ADHOC_MOCK_TEST,
    err
});

const dispatchRequestFetchQuestionsByIds = (data) => ({
    type: ActionTypes.REQUEST_QUESTIONS_BY_IDS,
    data
});

const dispatchReceivedFetchQuestionsByIds = (res) => ({
    type: ActionTypes.RECIVED_QUESTIONS_BY_IDS,
    res
});

const dispatchRemoveQuestionById = (res) => ({
    type: ActionTypes.REMOVE_QUESTIONS_BY_ID,
    res
});

const dispatchErrorFetchQuestionsByIds = (err) => ({
    type: ActionTypes.THROW_ERROR_QUESTIONS_BY_IDS,
    err
});

const dispatchFindSelectedTopicsFromSubjects = data => ({
    type: ActionTypes.SELECTED_TOPIC_DATA,
    data
})

const dispatchErrorForCurriculum = error => ({
    type: ActionTypes.THROW_ERROR_FOR_CURRICULUM,
    error
})


export const clearCurriculumData = () => dispatch => {
    dispatch(dispatchClearCurriculumData())
}
export const throwErrorForCurriculum = (error) => dispatch => {
    dispatch(dispatchErrorForCurriculum(error))
}


export const getSubject = subjectId => (dispatch, getState) => {
    throwErrorForCurriculum(null)

    const curriculumData = getState().curriculumData;
    const subjects = curriculumData && curriculumData.response && curriculumData.response.Item && curriculumData.response.Item.subjects || [];

    if (subjects && subjects.length) {
        if (subjectId && Array.isArray(subjectId) && subjectId.length) {
            const _subjects = [];
            subjectId.map(id => {
                const subject = subjects.find(c => c.subjectId === id);
                if (subject) {
                    _subjects.push(subject);
                }
            })
            dispatch(dispatchFindSelectedSubjectFromSubjects(_subjects))
            return;
        }
        const subject = subjects.filter(sub => sub.subjectId === subjectId);
        dispatch(dispatchFindSelectedSubjectFromSubjects(subject))
    }
}


export const getTopics = chapterId => (dispatch, getState) => {


    const curriculumData = getState().curriculumData;
    let _chapterDetails = []
    curriculumData && curriculumData.subjectInfo && curriculumData.subjectInfo.length > 0 && curriculumData.subjectInfo.map(sub => {
        _chapterDetails.push(sub.chapters)
    });

    let _chapterInfo = [];
    _chapterDetails.map(chap => _chapterInfo = [..._chapterInfo, ...chap]);


    const chapters = curriculumData && curriculumData.subjectInfo && curriculumData.subjectInfo.length && curriculumData.subjectInfo[0] && curriculumData.subjectInfo[0].chapters || [];




    if (_chapterInfo && _chapterInfo.length) {
        if (chapterId && Array.isArray(chapterId) && chapterId.length) {

            const _chapters = [];
            chapterId.map(id => {
                const chapter = _chapterInfo.find(c => c.chapterId === id);
                if (chapter) {
                    _chapters.push(chapter);
                }
            })

            dispatch(dispatchFindSelectedTopicsFromSubjects(_chapters))
            return;
        }
        const chapter = _chapterInfo.filter(chapter => chapter.chapterId === chapterId);
        dispatch(dispatchFindSelectedTopicsFromSubjects(chapter))
    }
}


export const getCurriculum = (boardId, classId, groupId, isActive) => dispatch => {
    let config = {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`,
            'Access-Control-Allow-Origin': '*'
        }
    };

    dispatch(dispatchRequestCurriculumData());
    axios.get(`${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}`, config)
        .then(({ data }) => {
            if (isActive) {
                if (data && data.Item && data.Item.subjects) {
                    data.Item.subjects = data.Item.subjects.filter(i => i.isSubjectActive == true);
                }
            }
            dispatch(dispatchReceivedCurriculumData(data));
        })
        .catch(e => dispatchErrorCurriculumData(e));

}

export const getSelectedSubjectQuestions = (subjectId) => dispatch => {
    dispatch(dispatchRequestFindSelectedSubjectQuestionsFromSubjects(subjectId));
    axios.get(`${baseUrlAdmin}/question/get-all-questions/type/adhoc`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
        }
    })
        .then(({ data }) => {
            dispatch(dispatchFindSelectedSubjectQuestionsFromSubjects(data));
        })
        .catch(e => dispatchErrorFindSelectedSubjectQuestionsFromSubjects(e));

}

export const createAdhocMockTest = (data) => (dispatch) => {
    dispatch(dispatchRequestCreateAdhocMockTest(data));

    axios({
        method: 'POST',
        url: `${baseUrlAdmin}/examination/add-adhoc-exam-test`,
        data: data
    })
        .then((res) => {
            dispatch(dispatchReceivedCreateAdhocMockTest(res.data));
        })
        .catch((e) => {
            dispatch(dispatchErrorCreateAdhocMockTest(e));
        });
};

export const editAdhocMockTest = (payload) => (dispatch) => {
    dispatch(dispatchRequestCreateAdhocMockTest(data));
    const { examId, examType, ...data } = payload
    axios({
        method: 'PUT',
        url: `${baseUrlAdmin}/examination/${examId}/exam-type/${examType}/update-exam`,
        data: data
    })
        .then((res) => {
            dispatch(dispatchReceivedCreateAdhocMockTest(res.data));
        })
        .catch((e) => {
            dispatch(dispatchErrorCreateAdhocMockTest(e));
        });
};

export const fetchQuestionsByIds = (data) => (dispatch) => {
    dispatch(dispatchRequestFetchQuestionsByIds(data));
    axios({
        method: 'POST',
        url: `${baseUrlAdmin}/question/fetch-question-by-ids`,
        data: { questionIds: data.questionIds }
    })
        .then((res) => {
            dispatch(dispatchReceivedFetchQuestionsByIds({ data: res.data, ...data }));
        })
        .catch((e) => {
            dispatch(dispatchErrorFetchQuestionsByIds(e));
        });
};

export const removeQuestionById = (data) => (dispatch) => {
    dispatch(dispatchRemoveQuestionById(data));
};

export const getBranchCourseSection = () => dispatch => {

    dispatch(dispatchRequestCurriculumBranchData());

    axios.get(`${baseUrlAdmin}/branch`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
        }
    })
        .then(({ data }) => {
            dispatch(dispatchReceivedCurriculumBranchData(data));
        })
        .catch(e => dispatchErrorCurriculumBranchData(e));
};

