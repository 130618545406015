import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import React, { Component } from 'react'
import {
    getBoardsData,
    getBranchesLatest
} from './../../../store/actions';
import { connect } from 'react-redux';
import { baseUrlForLongRunningTask } from '../../../store/apiConstants';
import Service from '../../../services';
import { InputText } from 'primereact/inputtext';
import XLSX from 'xlsx';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import LoadingComponent from '../../loadingComponent';
import { Toast } from 'primereact/toast';


const SheetJSFT = ['xlsx', 'xlsb', 'xlsm', 'xls', 'csv']
    .map(function (x) {
        return '.' + x;
    })
    .join(',');

/* generate an array of column objects */
const make_cols = (refstr) => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
};


class OfflineResultUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 1,
            examId: null,
            sectionIds: [],
            data: [],
            isLoading: false
        }
        this.fileUploadRef = React.createRef();
        this.service = new Service()
    }


    componentDidMount() {


        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }

    }

    componentDidUpdate(prevProps) {



        if (prevProps.branchData !== this.props.branchData) {

            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }

    }

    onChangeSection = (key, level) => {

        let { sectionsData } = this.state;

        let data = this.getSelectedLevelData(this.props.branchData, key)


        let emptyBeforeLevels = {};

        for (let i = (level + 1); i <= this.props.branchLevels.length; i++) {
            emptyBeforeLevels = {
                ...emptyBeforeLevels,
                ['level' + i]: [],
                ['levelSelected' + i]: null
            }
        }


        let newSectionData = {
            ...sectionsData,
            ...emptyBeforeLevels,
            ['level' + (level + 1)]: data.items,
            ['levelSelected' + (level)]: key
        }

        this.setState({
            sectionsData: newSectionData,
            sectionIds: [],
            selectedSection: []
        });




        //   let selectedSection = this.props.branches.find((s) => s.value == sectionId);





    };

    getSelectedLevelData(data, key) {
        let items = [];
        let selectedNode = data.find(a => a.key == key);
        if (selectedNode && selectedNode.children) {
            selectedNode.children.forEach((c) => {
                items.push(data.find(a => a.key == c))
            })

        }
        //   console.log(items)
        return { items, selectedNode };

    }

    onMultiChange = (value) => {
        this.setState((prevState) => {
            return {
                sectionIds: value
            };
        });

    }

    exportCSVV = () => {
        this.dt.exportCSV();
    }

    downloadSheet = () => {

        if (this.state.sectionIds && this.state.sectionIds.length) {
            this.setState({isLoading: true})

            let thisObj = this;
            const url = `${baseUrlForLongRunningTask}/examination/offline-upload-users`;
            this.service.post(url, { examId: this.props.rowDetailsOfUploading.examId, sectionIds: this.state.sectionIds }, true).then(res => {
                if (res && res.status) {
                    if (res?.res?.data?.length) {
                        
                        thisObj.setState({
                            isLoading: false,
                            data: res.res.data
                        }, () => {
                            thisObj.exportCSVV(res.res.data)
                        });
                        thisObj.toast.show({ severity: 'success', summary: 'Success', detail: 'Exam assigned usrers found, please fill the sheet and upload.', life: 3000 });
                        //  thisObj.exportCSV(res.res.data)
                    } else {
                        thisObj.setState({
                            isLoading: false
                        })
                        thisObj.toast.show({ severity: 'error', summary: 'No users', detail: 'Exam is not assigned to any users in the selected sections', life: 3000 });
                    }
                } else {
                    thisObj.setState({
                        isLoading: false
                    }, () => {
                        thisObj.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    });
                }
            }).catch(e => {
                this.setState({isLoading: false});
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                console.log(e);
            })
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Select Section Ids', life: 3000 });
        }





        // this.exportCSV(this.state.items);
    }

    handleFile = (file) => {
        
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        let data = [];
        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            data = XLSX.utils.sheet_to_json(ws, { raw: false });
            this.setState({ data: data, cols: make_cols(ws['!ref']) });

            const newJsonSheet = [];
            for (let i = 0; i < data.length; i++) {
                let excelRowData = {
                    ...data[i],
                    __rowNum__: undefined
                };

                excelRowData = JSON.parse(JSON.stringify(excelRowData));

                newJsonSheet.push(excelRowData);
            }
            this.setState({ users: newJsonSheet });
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    };

    handleChange = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            this.setState({ fileSelected: files[0]['name'] });
            this.handleFile(files[0]);
            e.target.value = null;
        }
    };

    changeTab = (tab) => {
        this.setState({
            selectedTab: tab,

        });
    }

    uploadExamResults = () => {
        if (this.state.users && this.state.users.length) {
            this.setState({ isLoading: true })

            let thisObj = this;
            const url = `${baseUrlForLongRunningTask}/examination/upload-offline-results`;
            this.service.post(url, { users: this.state.users }, true).then(res => {
                if (res && res.status) {
                    this.setState({ isLoading: false, users: [], data: [] })
                    thisObj.fileUploadRef.current.value = null;
                    this.props.onCancel();
                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    });
                }
            }).catch(e => {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                console.log(e);
            })
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please upload users', life: 3000 });
        }
    }

    render() {
        const { selectedTab } = this.state
        return (
            <>
                <div className='question-bank'>
                    <div className='qb-tabs'>
                        <span onClick={() => { this.changeTab(1) }} className={selectedTab == 1 ? 'qb-active-tab' : 'qb-inactive-tab'}>Download Students</span>
                        <span onClick={() => { this.changeTab(2) }} className={selectedTab == 2 ? 'qb-active-tab' : 'qb-inactive-tab'}>Upload Results</span>
                    </div>
                </div>
                {
                    selectedTab == 1 && <>
                        <div>

                            <h3>{this.props.rowDetailsOfUploading.examName}</h3>
                            {
                                this.props.branchLevels ? this.props.branchLevels.map((level, index) => {
                                    return (!(this.props.branchLevels.length == index + 1) ? <Dropdown
                                        className={`ma-mr20 ma-mt10 ma-w200`}
                                        value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                        // className='ma-w100p'
                                        //    disabled={this.state.hideUpdateButton}
                                        optionLabel={"name"}
                                        optionValue={"key"}
                                        options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}

                                        onChange={(e) => this.onChangeSection(e.value, index + 1)}
                                        placeholder={`--Select ${level.levelName}--`} /> : <MultiSelect
                                        className='ma-m-lr10 ma-w200'
                                        value={this.state.sectionIds ? this.state.sectionIds : []}
                                        // value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                        onChange={(e) => this.onMultiChange(e.value)}
                                        options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}
                                        optionLabel={"name"}
                                        optionValue={"key"}
                                        placeholder={`--Select ${level.levelName}--`}

                                    // onChange={(e) => setCities(e.value)} 
                                    />
                                    )
                                }) : null
                            }
                        </div>

                        <Button label="Download Sheet" className='ma-mt20' onClick={this.downloadSheet} />


                    </>
                }
                {
                    selectedTab == 2 && <>
                        <div className=" bulk-upload ma-mt30 ">
                            <p className='spread-title'>Users Spreadsheet</p>
                            <InputText className='p-inputtext-style1' value={this.state.fileSelected} disabled />
                            <label htmlFor="file" className='select-file'>Select File</label>
                            <input type="file" className="form-control" id="file" hidden={true} accept={SheetJSFT}
                                ref={this.fileUploadRef} onChange={this.handleChange} />
                            <div className='ma-clearFix ma-mt5'>
                                <label className='p-error'>Note : <span style={{color : "black"}}>Please upload xlsx file format</span></label>
                            </div>
                            <Button label="Upload data" className='ma-mt10' icon="pi pi-cloud-upload"
                                onClick={() => {
                                    if (this.state.users && this.state.users.length) {
                                        this.uploadExamResults();
                                    }
                                }}
                            />
                        </div>
                    </>
                }

                <DataTable ref={(el) => this.dt = el} value={this.state.data} style={{ display: "none" }}>
                    {
                        this.state.data && this.state.data.length ? Object.keys(this.state.data[0]).map(item => {
                            return <Column field={item} header={item} />
                        }) : null
                    }
                </DataTable>

                {(this.state.isLoading || this.props.isLoading) && <LoadingComponent />}
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}


const mapStateToProps = (state) => ({

    isLoading: state.boardsData.loading,
    branches: state.branchDataLatest.branches,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});

export default connect(mapStateToProps, { getBranchesLatest })(OfflineResultUpload)
