// import React from "react";
// import PropTypes from "prop-types";
// import _ from "lodash";
// import { Responsive, WidthProvider } from "react-grid-layout";
// import uuidv4 from 'uuid/v4';
// import { Dialog } from "primereact/dialog";
// import { warningDailogInit } from "../../utile";
// import { Dropdown } from "primereact/dropdown";
// import { Button } from "primereact/button";
// import WidgetWrapper from "./widgetWrapper";
// import ResourceUsageReport from "../dashboard/widgets/resourseUsageReport";
// import TrendingChapters from "../dashboard/widgets/trendingChapters";
// import AskADoubtStats from "../dashboard/widgets/askADoubtStats";
// import DiscussionboardStats from "../dashboard/widgets/discussionboardStats";
// import Notifications from "../dashboard/widgets/notifications";
// import UsersStats from "../dashboard/widgets/usersStats";


// let dropdownItems = [
//     { label: 'usage report', value: 'usagereport' },
//     { label: 'trending chapter', value: 'trendingchapter' },
//     { label: 'discusson board', value: 'discussonboard' },
//     { label: 'ask a doubt', value: 'askadoubt' },
//     { label: 'Notifications', value: 'notifications' },
//     { label: 'User Stats', value: 'usersstats' }
// ]

// const ResponsiveReactGridLayout = WidthProvider(Responsive);



// let layouts = [


// ]
// console.log(layouts)


// export default class ShowcaseLayout extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
//             currentBreakpoint: "lg",
//             compactType: "vertical",
//             mounted: false,
//             layouts: {
//                 lg: layouts,
//                 md: layouts,
//                 sm: layouts,
//                 s: layouts
//             },
//             layoutsData: {},
//             selectedGridId: null,
//             warningDialog: warningDailogInit,
//             layout: layouts

//         };

//         this.onBreakpointChange = this.onBreakpointChange.bind(this);
//         this.onCompactTypeChange = this.onCompactTypeChange.bind(this);

//     }

//     renderComp = (comp, gridItem) => {
//         switch (comp) {
//             case 'usagereport':
//                 return <WidgetWrapper removeGrid={() => { this.removeGrid(gridItem) }}
//                     editWidget={() => { this.editWidget(gridItem) }}
//                     removeWidget={() => { this.removeWidget(gridItem) }}

//                 >
//                     <ResourceUsageReport />
//                 </WidgetWrapper>
//             case 'trendingchapter':
//                 return <WidgetWrapper


//                     removeGrid={() => { this.removeGrid(gridItem) }}
//                     editWidget={() => { this.editWidget(gridItem) }}
//                     removeWidget={() => { this.removeWidget(gridItem) }}


//                 >
//                     <TrendingChapters />
//                 </WidgetWrapper>
//             case 'discussonboard':
//                 return <WidgetWrapper removeGrid={() => { this.removeGrid(gridItem) }}
//                     editWidget={() => { this.editWidget(gridItem) }}
//                     removeWidget={() => { this.removeWidget(gridItem) }}>
//                     <AskADoubtStats />
//                 </WidgetWrapper>
//             case 'askadoubt':
//                 return <WidgetWrapper removeGrid={() => { this.removeGrid(gridItem) }}
//                     editWidget={() => { this.editWidget(gridItem) }}
//                     removeWidget={() => { this.removeWidget(gridItem) }}
//                 >
//                     <DiscussionboardStats />
//                 </WidgetWrapper>
//             case 'notifications':
//                 return <WidgetWrapper removeGrid={() => { this.removeGrid(gridItem) }}
//                     editWidget={() => { this.editWidget(gridItem) }}
//                     removeWidget={() => { this.removeWidget(gridItem) }}
//                 >
//                     <Notifications />
//                 </WidgetWrapper>
//             case 'usersstats':
//                 return <WidgetWrapper removeGrid={() => { this.removeGrid(gridItem) }}
//                     editWidget={() => { this.editWidget(gridItem) }}
//                     removeWidget={() => { this.removeWidget(gridItem) }}
//                 >
//                     <UsersStats />
//                 </WidgetWrapper>
//             default:
//                 return <>
//                     <WidgetWrapper removeGrid={() => { this.removeGrid(gridItem) }}> <p>Select </p>
//                         <p>{gridItem.i}</p>
//                         <Button label="+ Add" className='' onClick={() => {
//                             
//                             this.setState({
//                                 warningDialog: {
//                                     visible: true,
//                                     headerMsg: '',
//                                     message: ''
//                                 },
//                                 selectedGridId: gridItem.i,
//                             })
//                         }} /></WidgetWrapper></>
//         }

//     }

//     generateDOM = () => {
//         let thisObj = this;
//         return _.map(this.state.layouts.lg, function (l, i) {
//             return (<div key={i} className='dom-tree' >
//                 {thisObj.renderComp(thisObj.state.layoutsData[l.i], l)}
//             </div>
//             );
//         });
//     }

//     onBreakpointChange(breakpoint) {
//         this.setState({
//             currentBreakpoint: breakpoint
//         });
//     }

//     onLayoutChange = (layout, allLayouts) => {
//         console.log(layout, 'la')
//         this.setState({});

//         this.setState((prevState) => {

//             return {
//                 layout: layout,
//                 layouts: allLayouts
//                 //  {
//                 //     [prevState.currentBreakpoint]: layout
//                 // }
//             };
//         }, () => {
//             
//             localStorage.setItem('layout', JSON.stringify(this.state.layouts));


//         });




//     }

//     onCompactTypeChange() {
//         const { compactType: oldCompactType } = this.state;
//         const compactType =
//             oldCompactType === "horizontal"
//                 ? "vertical"
//                 : oldCompactType === "vertical"
//                     ? null
//                     : "horizontal";
//         this.setState({ compactType });
//     }




//     componentDidMount() {
//         let layout = localStorage.getItem('layout');
//         let layoutsData = localStorage.getItem('layoutsDafta');
//         
//         if (layout) {
//             this.setState({
//                 layouts: JSON.parse(layout),
//                 layoutsData: layoutsData ? JSON.parse(layoutsData) : {}
//             });
//         }

//         // this.setState({ mounted: true });
//     }

//     onConfirmChange = () => {
//         
//         let layoutsData = this.state.layoutsData;
//         layoutsData[this.state.selectedGridId] = this.state.compId;
//         
//         this.setState({
//             layoutsData: layoutsData,
//             compId: null,
//             selectedGridId: null,
//             warningDialog: warningDailogInit
//         })

//         localStorage.setItem('layoutsData', JSON.stringify(layoutsData));
//     }

//     addNewGrid = () => {

//         let layouts = this.state.layouts;
//         
//         Object.keys(layouts).forEach(key => {
//             layouts[key] = [...layouts[key], {
//                 "w": 6,
//                 "h": 2,
//                 "x": 100,
//                 "y": 100,
//                 minW: 2, maxW: 4,
//                 //"i": uuidv4(),
//                 // "id": uuidv4(),
//                 "moved": false,
//                 "static": false
//             }]
//         })

//         this.setState({
//             layouts

//         });



//     }

//     removeGrid = (item) => {
//         
//         let layouts = this.state.layouts;

//         Object.keys(layouts).forEach(key => {
//             layouts[key] = layouts[key].filter(a => a.i !== item.i);
//         });

//         let layoutsData = this.state.layoutsData;


//         for (let j = 0; j < Object.keys(layoutsData).length; j++) {
//             if (j >= item.i) {
//                 layoutsData[j] = layoutsData[j + 1];
//             }
//         }

//         
//         this.setState({
//             layouts,
//             layoutsData
//         })
//     }

//     editWidget = (gridItem) => {
//         this.setState({
//             warningDialog: {
//                 visible: true,
//                 headerMsg: '',
//                 message: ''
//             },
//             selectedGridId: gridItem.i,
//             compId: this.state.layoutsData[gridItem.i]
//         })
//     }

//     removeWidget = (gridItem) => {

//         let layoutsData = this.state.layoutsData;
//         layoutsData[gridItem.i] = undefined;
//         this.setState({
//             layoutsData
//         })

//     }

//     render() {
//         console.log(this.state, 'props-state')
//         return (
//             <div>
//                 {/* <div className="layoutJSON">
//                     Displayed as <code>[x, y, w, h]</code>:
//                     <div className="columns">{this.stringifyLayout()}</div>
//                 </div>
//                 <div>
//                     Current Breakpoint: {this.state.currentBreakpoint} ({
//                         this.state.cols[this.state.currentBreakpoint]
//                     }{" "}
//                     columns)
//                 </div> */}

//                 <Button label="add new grid" onClick={this.addNewGrid} />

//                 <ResponsiveReactGridLayout
//                     cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
//                     className="layout"
//                     // rowHeight={30}
//                     layouts={this.state.layouts}
//                     onBreakpointChange={this.onBreakpointChange}
//                     onLayoutChange={this.onLayoutChange}
//                     measureBeforeMount={false}
//                     useCSSTransforms={this.state.mounted}
//                     compactType={this.state.compactType}
//                     preventCollision={!this.state.compactType}
//                 >
//                     {this.generateDOM()}
//                 </ResponsiveReactGridLayout>
//                 <Dialog
//                     header={<></>}
//                     draggable={false}
//                     closeOnEscape={true}
//                     className='ma-alert-box'
//                     blockScroll={true}
//                     dismissableMask={true}
//                     closable={false}
//                     visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
//                     footer={() => {
//                         return (<div>
//                             <Button label="Cancel" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, selectedGridId: null }) }} />
//                             <Button label="Save" className='ma-m-lr10' onClick={this.onConfirmChange} />

//                         </div>)
//                     }}
//                     onHide={() => this.setState({ warningDialog: warningDailogInit, selectedGridId: null })}>
//                     <div>
//                         <Dropdown value={this.state.compId}
//                             className='ma-w200'
//                             options={dropdownItems}
//                             onChange={(e) => this.setState({
//                                 compId: e.value
//                             })}
//                             placeholder="Select Component" />


//                     </div>
//                 </Dialog>

//             </div >
//         );
//     }
// }



import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default class ShowcaseLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentBreakpoint: "lg",
      compactType: "vertical",
      mounted: false,
      layouts: { lg: props.initialLayout }
    };

    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onCompactTypeChange = this.onCompactTypeChange.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.onNewLayout = this.onNewLayout.bind(this);
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  generateDOM() {
    return _.map(this.state.layouts.lg, function(l, i) {
      return (
        <div key={i} className={l.static ? "static" : ""}>
          {l.static ? (
            <span
              className="text"
              title="This item is static and cannot be removed or resized."
            >
              Static - {i}
            </span>
          ) : (
            <span className="text">{i}</span>
          )}
        </div>
      );
    });
  }

  onBreakpointChange(breakpoint) {
    this.setState({
      currentBreakpoint: breakpoint
    });
  }

  onCompactTypeChange() {
    const { compactType: oldCompactType } = this.state;
    const compactType =
      oldCompactType === "horizontal"
        ? "vertical"
        : oldCompactType === "vertical"
          ? null
          : "horizontal";
    this.setState({ compactType });
  }

  onLayoutChange(layout, layouts) {
    this.props.onLayoutChange(layout, layouts);
  }

  onNewLayout() {
    this.setState({
      layouts: { lg: generateLayout() }
    });
  }

  render() {
    return (
      <div>
        <div>
          Current Breakpoint: {this.state.currentBreakpoint} ({
            this.props.cols[this.state.currentBreakpoint]
          }{" "}
          columns)
        </div>
        <div>
          Compaction type:{" "}
          {_.capitalize(this.state.compactType) || "No Compaction"}
        </div>
        <button onClick={this.onNewLayout}>Generate New Layout</button>
        <button onClick={this.onCompactTypeChange}>
          Change Compaction Type
        </button>
        <ResponsiveReactGridLayout
          {...this.props}
          layouts={this.state.layouts}
          onBreakpointChange={this.onBreakpointChange}
          onLayoutChange={this.onLayoutChange}
          // WidthProvider option
          measureBeforeMount={false}
          // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
          // and set `measureBeforeMount={true}`.
          useCSSTransforms={this.state.mounted}
          compactType={this.state.compactType}
          preventCollision={!this.state.compactType}
        >
          {this.generateDOM()}
        </ResponsiveReactGridLayout>
      </div>
    );
  }
}

ShowcaseLayout.propTypes = {
  onLayoutChange: PropTypes.func.isRequired
};

ShowcaseLayout.defaultProps = {
  className: "layout",
  rowHeight: 30,
  onLayoutChange: function() {},
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  initialLayout: generateLayout()
};

function generateLayout() {
  return _.map(_.range(0, 25), function(item, i) {
    var y = Math.ceil(Math.random() * 4) + 1;
    return {
      x: (_.random(0, 5) * 2) % 12,
      y: Math.floor(i / 6) * y,
      w: 2,
      h: y,
      i: i.toString(),
      static: Math.random() < 0.05
    };
  });
}
