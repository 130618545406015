import React, { Component } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { TestTypes } from '../constants';
import CreateQuiz from './createQuiz';
import { Toast } from 'primereact/toast';
import CreateAssessment from './createAssessment';
import Authorizer, { PERMISSIONS, isAuthorized, getPermIdOfAction } from '../../session/authorizer';
import Authentication from './../../session';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CreateAdhoc from './createAdhoc';
import { FIELDS_INFO } from '../../../constants';
import InputTextB from '../../customComponents/inputTextB';
import CreateSubjective from './createSubjective';
import CreateAdhocT2 from './createAdhocT2';
import CreateDiagnosticTest from './createDiagnosticTest';
import OfflineTest from './offlineTest';

class CreateTest extends Component {
    constructor(props) {
        super(props);


        this.state = {
            examType: props.editExamType ? props.editExamType : '',
            testTypes: [],
        };
    }



    onCreateSuccess = (isEdit) => {

        this.setState({
            examType: null
        }, () => {
            if (isEdit) {
                this.props.onExamEditSuccess()
            }

        })
        this.toast.show({ severity: 'success', summary: 'Created', detail: `${isEdit ? 'Edited' : 'Created'} exam record successfully`, life: 3000 });


    }



    componentDidMount() {
        if (this.props.permissionIds && this.props.userInfo) {
            if (this.props.permissionIds && this.props.permissionIds.length) {

                let testTypes = [
                    {
                        value: 'DiagnosticTest',
                        label: 'Digonstic Test'
                    }
                ];

                TestTypes.forEach((t) => {
                    if ((isAuthorized(this.props.permissionIds, getPermIdOfAction(t.value + '-create'), this.props.userInfo))) {
                        testTypes.push(t);
                    }
                });

                this.setState({
                    testTypes
                })

            }
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.permissionIds !== this.props.permissionIds && this.props.userInfo) {
            if (this.props.permissionIds && this.props.permissionIds.length) {

                let testTypes = [
                    {
                        value: 'DiagnosticTest',
                        label: 'Digonstic Test'
                    }
                ];

                TestTypes.forEach((t) => {
                    if ((isAuthorized(this.props.permissionIds, getPermIdOfAction(t.value + '-create'), this.props.userInfo))) {
                        testTypes.push(t);
                    }
                });

                this.setState({
                    testTypes
                })

            }
        }

        if (prevProps.editExamId != this.props.editExamId) {
            this.setState({
                editExamId: this.props.editExamId
            })


        }
    }



    render() {

        //  console.log('exam-creation', this.state)
        return (<>
            <InputTextB info={FIELDS_INFO.TEST_TYPE} id="leaAbT">
                <Dropdown value={this.state.examType}
                    className='ma-w200'
                    disabled={this.props.editExamType}
                    options={this.state.testTypes}
                    onChange={(e) => this.setState({ examType: e.value })}
                    placeholder="Select Test Type" />
            </InputTextB>
            <div className='ma-mt20'>
                {
                    this.state.examType == 'Quiz' && <CreateQuiz editExamId={this.props.editExamId} onCreateSuccess={this.onCreateSuccess} />
                }
                {
                    this.state.examType == 'Assessment' && <CreateAssessment editExamId={this.props.editExamId} onCreateSuccess={this.onCreateSuccess} />
                }
                {
                    this.state.examType == 'AdhocMockTest' && <CreateAdhoc editExamId={this.props.editExamId} onCreateSuccess={this.onCreateSuccess} />
                }
                {
                    this.state.examType == 'SubjectiveTest' && <CreateSubjective editExamId={this.props.editExamId} onCreateSuccess={this.onCreateSuccess} />
                }
                {
                    this.state.examType == 'AdhocMockTestT2' && <CreateAdhocT2 editExamId={this.props.editExamId} onCreateSuccess={this.onCreateSuccess} />
                }
                {
                    this.state.examType == 'DiagnosticTest' && <CreateDiagnosticTest editExamId={this.props.editExamId} onCreateSuccess={this.onCreateSuccess} />
                }
                {
                    this.state.examType == 'offlineTest' && <OfflineTest editExamId={this.props.editExamId} onCreateSuccess={this.onCreateSuccess} />
                }

                {/* {
                    this.state.examType == 'Quiz' && <CreateQuiz />
                } */}

            </div>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </>)
    }
}




const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});




export default connect(mapStatesToProps, {})(Authentication(withRouter(CreateTest)));