import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react'
import { Column } from 'react-formio/lib/types';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import SvgViewer from '../../customComponents/svgViewer';
import LoadingComponent from '../../loadingComponent';
import Navbar from '../../navbar';
import { AddIcon, DeleteIcon, PreviewIcon, WarningIcon } from '../../svgIcons';
import AddRoute from './addRoute';
import AddStop from './stops/addStop';
import PreViewDetials from './preViewDetials'
import Stops from './stops';
import Students from './stops/students/students';

const items = [
    { label: 'Organization' },
    { label: 'Route Management', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

export default class RouteManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            routeDetails: [],
            isLoading: false,
            showAddRoute: false,
            globalFilter: null,
            showSearch: false,
            columns: [
                { columnName: 'Cleaner Name', isVisible: false, id: 'cleanerName' },
                { columnName: 'Cleaner Mobile Number', isVisible: false, id: 'cleanerMobileNumber' }
            ],
            warningDialog: warningDailogInit,
            warningDialogForDelete: warningDailogInit,
            previewDetails: false,
            deleteRouteData: null,
            showAddStop: false,
            routeManagement: true,
            stopManagement: false,
            lazyParams: BasicLazyParams,
            totalRecords: 0,
            globalSearch: '',

        }

        this.service = new Service()

    }

    componentDidMount() {
        this.getRoutesData()
    }

    getRoutesData = () => {
        this.setState({
            isLoading: true
        })

        let lazyParams = this.state.lazyParams;


        let url = `${baseUrlAdmin}/transport-route?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${this.state.globalSearch}`

        this.service.get(url, true).then(res => {
            if (res?.status && res?.res?.status) {
                this.setState({
                    isLoading: false,
                    routeDetails: res?.res?.data?.routes,
                    totalRecords: res?.res?.data?.totalRecords
                })
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(err => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

        })
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Route Management</h2>
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }



    exportCSV = () => {
        this.dt.exportCSV();
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    handleDelete = async (rowData) => {
        this.setState({
            warningDialogForDelete: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `Do you want to delete the ${rowData} from the route.`
            },
            deleteRouteData: rowData
        });
    }

    deleteRoute = () => {
        // let { deleteRouteData } = this.state;
        // const url = `${baseUrlAdmin}/`;

        // this.setState({
        //     warningDialogForDelete: warningDailogInit,
        //     isLoading: true
        // })
        // this.service.delete(url, true).then((res) => {
        //     if (res && res.status && res.res.status) {
        //         this.setState({
        //             isLoading: false
        //         }
        //  () => {
        //     this.();
        // }
        //         )
        //     } else {
        //         this.setState({
        //             isLoading: false
        //         });
        //         this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
        //     }
        // }).catch(e => {
        //     console.log(e);
        //     this.setState({
        //         isLoading: false
        //     });
        //     this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        // })
    }

    showPreview = (rowData) => {
        this.setState({
            previewDetails: true,
            viewRouteDetails: rowData
        })
    }

    onClickAddVehicle = () => {
        this.setState({
            showAddRoute: true
        })
    }

    showEditRoleDialog = (editData) => {
        this.setState({
            editRouteDetails: editData,
            showAddRoute: true,
        });
    }

    showStops = (rowData) => {
        this.setState({
            routeManagement: false,
            stopManagement: true,
            routeId: rowData,
            currentTab: 'stops'
        })
    }

    updateCurrentTab = (currentTab) => {
        this.setState({
            currentTab
        })
    }

    onClickContinue = () => {
        this.setState({
            routeManagement: true,
            stopManagement: false,
            routeId: ''
        })
    }

    onClickContinueFormStudent = () => {
        this.setState({
            routeManagement: true,
            routeId: ''
        })
    }

    onClose = () => {
        this.setState({
            editRouteDetails: '',
            showAddRoute: false
        })
    }

    onSaveOrUpdate = (isUpdate) => {
        this.toast.show({ severity: 'success', summary: 'Saved', detail: `Staff ${isUpdate ? 'updated' : 'created'} successfuly`, life: 3000 });
        this.setState({
            editStaffDetails: '',
            showAddRoute: false
        }, this.getRoutesData)
    }

    onClosePreview = () => {
        this.setState({
            previewDetails: false
        })
    }

    onCloseAddStop = () => {
        this.setState({
            routeData: '',
            showAddStop: false
        })
    }


    editTemplate = (rowData, { rowIndex }) => {
        return (<>
            <div className="p-d-flex user-edit-t">
                <span data-pr-tooltip="Preview" data-pr-position="bottom" className={`edd${rowIndex}`}
                    onClick={() => {
                        this.showPreview(rowData)
                    }}>
                    <PreviewIcon height={15} width={15} />
                </span>
                <Tooltip className="table-li-tooltip" autoHide={false} target={`.edd${rowIndex}`} />

                <span data-pr-tooltip="Edit" data-pr-position="bottom" className={`ma-ml10 p-ml-3 ar${rowIndex}`} onClick={() => this.showEditRoleDialog(rowData)} >
                    <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="15" height="15" />
                </span>
                <Tooltip className="table-li-tooltip" autoHide={false} target={`.ar${rowIndex}`} />

                {/* <span data-pr-tooltip="Add Stop" data-pr-position="bottom" className={`add${rowIndex} p-ml-3 ma-ml10`}
                    onClick={() => { this.addStop(rowData) }}
                >
                    <AddIcon height={15} width={15} />
                </span>
                <Tooltip className="table-li-tooltip" autoHide={false} target={`.add${rowIndex}`} /> */}

                {/* <span data-pr-tooltip="Delete" data-pr-position="bottom" className={`dlete${rowIndex} p-ml-3 ma-ml10`}
                    onClick={() => { this.handleDelete(rowData) }}
                >
                    <DeleteIcon height={15} width={15} />
                </span>
                <Tooltip className="table-li-tooltip" autoHide={false} target={`.dlete${rowIndex}`} /> */}
            </div>
        </>
        );
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }

    onClickAddStop = () => {
        console.log('onClockPlus')
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getRoutesData);
    }

    onPage = (event) => {
        this.setState({ lazyParams: event }, this.getRoutesData);
    }

    onGoBack = () => {
        this.setState({
            stopManagement: false,
            routeManagement: true,
            routeId: ''

        }, this.getRoutesData)
    }





    render() {


        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'>Routes</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" value={this.state.globalSearch}
                        onChange={this.onGlobalSearch} />}

                </div>

                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <li><i data-pr-tooltip="View Columns"
                            onClick={(event) => this.menu.toggle(event)}
                            data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        return (
            <>
                <Navbar>
                    <div className='p-grid ma-toolbar'>
                        <div className="p-col-12 p-md-1 ma-no-p">

                        </div>
                        <div className="p-col-12 p-md-11 ma-no-pm">
                            <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        </div>
                    </div>

                    <div className='ma-main'>
                        {(this.state.stopManagement && this.state.currentTab != 'students') && <>
                            <p className='back-arrow' onClick={this.onGoBack}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                        </>
                        }
                        {this.state.routeManagement &&
                            <>
                                <div className='ma-mtb25'>

                                    <Button label="+ Add Route" className='ma-m-lr10' onClick={this.onClickAddVehicle} />
                                </div>
                                <div className='card datatable-crud-demo ma-m30'>
                                    <DataTable ref={(el) => this.dt = el}
                                        value={this.state.routeDetails || []}
                                        dataKey="id"
                                        paginator
                                        lazy
                                        onPage={this.onPage}
                                        rows={this.state.lazyParams.rows}
                                        first={this.state.lazyParams.first}
                                        last={this.state.totalRecords}
                                        totalRecords={this.state.totalRecords}
                                        responsiveLayout="scroll"
                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                        globalFilter={this.state.globalFilter}
                                        header={header}
                                    >
                                        <Column header="S.No." className='w-5' body={(rowData, index) => {
                                            return (
                                                <>
                                                    <p>{(this.state.lazyParams.page * this.state.lazyParams.rows)  + index.rowIndex + 1}</p>
                                                </>
                                            )
                                        }} />
                                        <Column sortable field="routeName" header="Route Name" />
                                        <Column field="vehicle" header="Vehicle Number" />
                                        <Column sortable field="driver" header="Driver Name" />
                                        <Column field="driverMobileNumber" header="Driver Mobile Number" />

                                        {
                                            this.isColumnVisible('cleanerName') && <Column field="cleanerName" header="Cleaner Name" />
                                        }
                                        {
                                            this.isColumnVisible('cleanerMobileNumber') && <Column field="cleanerMobileNumber" header="Cleaner Mobile Number" />
                                        }

                                        <Column sortable field="" header="No of Stops"
                                            body={(rowData, rowIndex) => {
                                                return (<span className='p-ml-10 text-primary ma-pointer' onClick={() => { this.showStops(rowData) }}>{rowData.stops}</span>)
                                            }} />
                                        <Column sortable field="" header="No of Students"
                                            body={(rowData, rowIndex) => {
                                                return (<span className='p-ml-10' >{rowData.admissions}</span>)
                                            }} />
                                        {/* <Column sortable field="" header="No of Students"
                                        body={(rowData) => {
                                            return (
                                                <>
                                                    <div className='p-grid'>
                                                        <p className='p-ml-10'>{rowData.numberOfStudents}</p>
                                                        <button className='p-ml-10' onClick={() => this.addStudent(rowData)}>+</button>
                                                    </div>
                                                </>
                                            )
                                        }}
                                    /> */}
                                        <Column field="" header="Actions" className='' body={this.editTemplate} />
                                    </DataTable>
                                    {this.state.showAddRoute &&
                                        <AddRoute
                                            editRouteDetails={this.state.editRouteDetails}
                                            visible={this.state.showAddRoute}
                                            onClose={this.onClose}
                                            onSaveOrUpdate={this.onSaveOrUpdate}

                                        />
                                    }
                                </div>

                                <Dialog
                                    draggable={false}
                                    blockScroll={true}
                                    closeOnEscape={true}
                                    className='ma-alert-box'
                                    dismissableMask={true}
                                    closable={false}
                                    visible={this.state.warningDialogForDelete.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                                    footer={() => {
                                        return (<div>
                                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialogForDelete: warningDailogInit, deleteRouteData: null }) }} />
                                            <Button label="Yes" className='ma-m-lr10' onClick={this.deleteRoute} />

                                        </div>)
                                    }}
                                    onHide={() => this.setState({ warningDialogForDelete: warningDailogInit, deleteRouteData: null })}>
                                    <div>
                                        <span className="warning-icon" >
                                            <WarningIcon width={"100%"} height={"100%"} />
                                        </span>
                                    </div>
                                    <p className='ma-alert-head'>{this.state.warningDialogForDelete.headerMsg}</p>
                                    <p className='ma-alert-msg'>{this.state.warningDialogForDelete.message}</p>
                                </Dialog>

                                {this.state.previewDetails &&
                                    <PreViewDetials
                                        previewDetails={this.state.previewDetails}
                                        viewRouteDetails={this.state.viewRouteDetails}
                                        onClosePreview={this.onClosePreview}
                                    />}

                                {/* {this.state.showAddStop &&
                                <AddStop
                                    showAddStop={this.state.showAddStop}
                                    routeData={this.state.routeData}
                                    onCloseAddStop={this.onCloseAddStop}
                                />
                            } */}

                            </>
                        }

                    </div>
                    {this.state.stopManagement &&
                        <Stops
                            routeId={this.state.routeId}
                            onClickContinue={this.onClickContinue}
                            updateCurrentTab={this.updateCurrentTab}
                        />

                    }




                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                    {this.state.isLoading && <LoadingComponent />}
                </Navbar>
            </>
        )
    }
}
