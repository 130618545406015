import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import React, { Component } from 'react'
import Registation from './registation';
import admissionFields from './admissionFields.json'
import { getFormFields, onTextChange } from '../../utile/formHelper';
import { Button } from 'primereact/button';

export default class Admission extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(admissionFields)
        this.state = {
            admissionFieldsS: this.formFields.data,
            formValidations: this.formFields.formValidations,
            paymentMode: ''
        }
    }

    render() {
        const { admissionFieldsS, formValidations } = this.state
        return (
            <>
                <div>
                    <Registation
                        registrationFormDetails={this.props.registrationFormDetails}
                        enquiryFormData={this.props.enquiryFormData}
                    />
                </div>
                <div className="p-card ma-w100p ma-p20 p-mt-5">
                    <div className="p-col-12">
                        <h2 className='enquiry-header'>Admission</h2>
                    </div>
                    <div className="p-col-12 p-md-4">
                        <p className='ma-label-s2'>Application Fee<span className='ma-required'>*</span></p>
                        <InputText
                            className='p-inputtext-style1 ma-w70p'
                            value={admissionFieldsS.applicationFee}
                            onChange={(e) => onTextChange(e.target.value, 'applicationFee', this, admissionFields, admissionFieldsS, formValidations, 'registrationFieldsS', 'formValidations')}
                        />
                        {formValidations && !formValidations.fields['applicationFee'].isValid && <p className="p-error">{formValidations.fields['applicationFee'].errorMsg}</p>}
                    </div>

                    <div className="p-col-12 p-md-4">
                        <p className='ma-label-s2'>Registation Fee<span className='ma-required'>*</span></p>
                        <InputText
                            className='p-inputtext-style1 ma-w70p'
                            value={admissionFieldsS.registationFee}
                            onChange={(e) => onTextChange(e.target.value, 'registationFee', this, admissionFields, admissionFieldsS, formValidations, 'registrationFieldsS', 'formValidations')}
                        />
                        {formValidations && !formValidations.fields['registationFee'].isValid && <p className="p-error">{formValidations.fields['registationFee'].errorMsg}</p>}
                    </div>

                    <div className="p-ml-2">
                        <p className='ma-label-s2'>Payment Mode<span className='ma-required'>*</span></p>
                        <div className="p-grid p-mt-1">
                            <div className="p-col-1 p-md-1">
                                <RadioButton
                                    inputId="paymentMode1" name="paymentMode" value="cash"
                                    onChange={(e) => this.setState({ paymentMode: e.value })}
                                    checked={this.state.paymentMode === 'cash'} />
                                <label htmlFor="Cash" className="p-ml-2">Cash</label>
                            </div>
                            <div className="p-col-1 p-md-1">
                                <RadioButton
                                    inputId="paymentMode2" name="paymentMode" value="cheque"
                                    onChange={(e) => this.setState({ paymentMode: e.value })}
                                    checked={this.state.paymentMode === 'cheque'} />
                                <label htmlFor="Cheque" className="p-ml-2">Cheque</label>
                            </div>
                            <div className="p-col-1 p-md-1">
                                <RadioButton
                                    inputId="paymentMode3" name="paymentMode" value="credit/debit"
                                    onChange={(e) => this.setState({ paymentMode: e.value })}
                                    checked={this.state.paymentMode === 'credit/debit'} />
                                <label htmlFor="Credit/Debit" className="p-ml-2">Credit/Debit</label>
                            </div>
                        </div>
                        <div className='p-mt-3 p-ml-2'>
                            <Button label='Save' />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
