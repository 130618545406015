import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { Component } from 'react'
import { Column } from 'react-formio/lib/types';
import { FIELDS_INFO } from '../../../constants';
import InputTextB from '../../customComponents/inputTextB';
import LoadingComponent from '../../loadingComponent';
import InstallmentsAdd from '../../organizationManage/feePlan/installmentsAdd';
import uuidv4 from 'uuid/v4';
import { RadioButton } from 'primereact/radiobutton';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { getFormFields, isFormValid, onTextChange } from '../../../utile/formHelper';
import Service from '../../../services';


const typeOfFee = [{
    "Type": "Text",
    "Label": "Name",
    "FieldName": "name",
    "Required": true
}]


export default class AddBusFee extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(typeOfFee, this.props.editBusFeeDetails)
        this.state = {
            feePlan: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isLoading: false,
            feeTypes: this.props.editBusFeeDetails ? this.props.editBusFeeDetails.feeTypes : [{
                id: uuidv4(),
                feeType: 'Bus Fee',
                amount: '',
                isItMandatory: null,
                installments: [],
            }],
            
            feeTypeOptions: [],
            selectedFeeType: null,

        }

        this.service = new Service();
    }


    addFeeTypeRow = () => {
        this.setState((prevState) => {
            return {
                feeTypes: [...prevState.feeTypes, {
                    id: uuidv4(),
                    feeType: undefined,
                    amount: '',
                    isItMandatory: null,
                    installments: [],
                }]
            }
        })
    }

    removeFeeTypeRow = (id) => {
        this.setState((prevState) => {
            return {
                feeTypes: [...prevState.feeTypes.filter(a => a.id != id)]
            }
        })
    }


    feeTypeRender = (rowData) => {
        return <Dropdown value={rowData.feeType}
            className='ma-w100p'
            options={['Bus Fee']}
            placeholder="Select Fee Type" />
    }

    amountRender = (rowData) => {
        return <InputText
            value={rowData.amount}
            onChange={(e) => { this.onNumberChange(rowData.id, e.target.value) }}
            className='p-inputtext-style1' />

    }

    onNumberChange = (id, value) => {
        
        if (isNaN(value) || parseInt(value) < 0) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Invalid amount', life: 3000 });
        } else {
            let a = this.state.feeTypes.map(a => {
                if (a.id == id) {
                    a.amount = value;
                    a.installments = [];
                }

                return a;
            })

            ;
            this.setState({
                feeTypes: a
            })
        }
    }

    isMandateryRender = (rowData) => {
        return <>
            <label className='ma-label-s1' htmlFor='Yes'>Yes</label>
            <RadioButton className="radio-inline ma-ml10" value={true} inputid="Yes" name="isItMandatory"
                onChange={(e) => {
                    this.onChoiceChange(rowData.id, e.value)
                }}
                checked={rowData.isItMandatory}
            />
            <label className='ma-label-s1' htmlFor='No'>No</label>
            <RadioButton className="radio-inline ma-ml10" value={false} inputid="No" name="isItMandatory"
                onChange={(e) => {
                    this.onChoiceChange(rowData.id, e.value)
                }}
                checked={rowData.isItMandatory == false}
            />
        </>

    }

    onChoiceChange = (id, value) => {
        let a = this.state.feeTypes.map(a => {
            if (a.id == id) {
                a.isItMandatory = value
            }
            return a;
        })
        this.setState({
            feeTypes: a
        })
    }


    installmentsRender = (rowData) => {
        return <><span> {rowData.installments.length} installments </span>
            <Button label="+ Add" className='' onClick={() => this.showAddInstallPopup(rowData)} />
        </>
    }

    addInstallments = (id, installments) => {
        let feeTps = this.state.feeTypes.map(a => {
            if (a.id == id) {
                a.installments = installments
            }
            return a;
        })

        this.setState({
            feeTypes: feeTps,
            isShowInstallmentsPopup: false,
            selectedFeeType: null
        });
    }

    showAddInstallPopup = (rowData) => {
        if (
            // rowData.feeType == '' ||
            rowData.amount == '' || rowData.isItMandatory == null) {
            return this.toast.show({ severity: 'error', summary: 'invalid', detail: 'Please select Fee type, amount, is madatory field to add installments.', life: 3000 });
        }

        this.setState({
            selectedFeeType: rowData,
            isShowInstallmentsPopup: true
        })

    }

    isAdditionalValid = () => {
        let isValid = true;

        if (!this.state.feeTypes.length) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Fee Types are required', life: 3000 });
            isValid = false;
        }

        if (this.state.feeTypes.find(r => r.feeType == '' || r.amount == '' || r.isItMandatory == null || !r.installments.length)) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter all fields in fee types.', life: 3000 });
            isValid = false;
        }



        return isValid;


    }

    onSaveFeePlan = () => {
        this.setState({
            isSaveClicked: true
        });


        let isCompleteFormValid = true;
        const formStatus = isFormValid(typeOfFee, this.formFields.formValidations, this.state.feePlan);
        if (!formStatus.formValidations.isFormValid) {
            isCompleteFormValid = false;
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter Plan Name', life: 3000 });
        } else if (!this.isAdditionalValid()) {

        }


        if (isCompleteFormValid) {
            const feePlan = this.state.feePlan;
            feePlan.feeTypes = this.state.feeTypes;
            this.setState({
                isLoading: true
            })
            let url = `${baseUrlAdmin}/bus-fee-plans`;
            this.service[this.props.editBusFeeDetails ? 'put' : 'post'](url, feePlan, true).then(res => {
                if (res && res.status && res.res.status) {
                    this.setState((prevState) => {
                        return {
                            isLoading: false
                        };
                    }, () => {
                        this.props.onSaveOrUpdate(this.props.editBusFeeDetails ? true : false)
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            })


        }
    }



    render() {
        const { feePlan, formValidations } = this.state;

        return (
            <>
                <Dialog
                    visible={this.props.visible}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={false}
                    style={{ maxWidth: '90%', minWidth: '90%' }}
                    footer={this.footer}
                    header={`${this.props.editBusFeeDetails ? 'Update' : 'Add'} Bus Plan Details`}
                >
                    <div className="ma-box-style2 create-org-w">
                        <span className='create-org-title'>{this.props.editBusFeeDetails ? 'Edit' : 'Create'} Bus Fee Plan</span>
                        <div className='ma-clearFix'>
                        </div>
                        <p className='craete-org-subtitle'>Bus Plan Details</p>
                        <hr className="ma-hr-light" />
                        <div className="p-grid ma-w100p" >
                            <div className="p-col-12 p-md-3">
                                <p className='ma-label-s2'>Fee Plan Name<span className='ma-required'>*</span></p>
                                {/* <InputTextB info={FIELDS_INFO.ORG_NAME} id="co4"> */}
                                <InputText
                                    className='p-inputtext-style1 ma-w100p'
                                    value={feePlan.name}
                                    onChange={(e) => { onTextChange(e.target.value, 'name', this, typeOfFee, feePlan, formValidations, 'feePlan', 'formValidations') }}
                                />
                                {/* </InputTextB> */}
                                {formValidations && !formValidations.fields['name'].isValid && <p className="p-error">{formValidations.fields['name'].errorMsg}</p>}
                            </div>
                        </div>

                        <div className="p-grid ma-w100p ma-mt10" >
                            <div className="p-col-12">
                                <p className='ma-label-s2'>Fee Types<span className='ma-required'>*</span></p>
                                <DataTable ref={(el) => this.dt = el}
                                    value={this.state.feeTypes}
                                    dataKey="id"
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                    globalFilter={this.state.globalFilter}
                                // header={header}
                                >
                                    <Column field="" header="Fee Type" body={this.feeTypeRender} />
                                    <Column field="" header="Amount" body={this.amountRender} />
                                    <Column field="" header="Is it mandatory" body={this.isMandateryRender} />
                                    <Column field="" header="Installments" body={this.installmentsRender} />

                                    {/* 

                            <Column header="Edit" body={this.editTemplate} ></Column>
                               <Column header="Show Chapters" body={this.showChaptersTemplate}></Column> 
                    */}

                                </DataTable>
                            </div>
                        </div>
                        <Toolbar className="ma-toolbard" right={() => {
                            return (
                                <div className='ma-mt20'>
                                    <Button label="Cancel" className='p-button-outlined'
                                        onClick={this.props.onClose}
                                    />
                                    <Button
                                        label='Save'
                                        className='ma-m-lr10'
                                        onClick={this.onSaveFeePlan} />
                                </div>
                            )
                        }}></Toolbar>
                        {
                            this.state.isShowInstallmentsPopup && <InstallmentsAdd onCancel={() => {
                                this.setState({
                                    isShowInstallmentsPopup: false
                                })
                            }}
                                selectedFeeType={this.state.selectedFeeType}
                                addInstallments={this.addInstallments}
                            />
                        }

                        <Toast ref={(el) => this.toast = el} position="bottom-right" />
                        {
                            this.state.isLoading && <LoadingComponent />
                        }
                    </div >
                </Dialog>

            </>
        )
    }
}
