import React, { Component } from 'react';
import Service from './../service';
import { discussionBoardEndpoint } from './../../../store/apiConstants';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getProfileURL } from './../../../utile';

class LeaderBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 'daily',
            ranks: [],
            posts: 0,
            replies: 0,
            likes: 0,
            dislikes: 0,
            rank: 0,
        }

        this.service = new Service();
    }



    changeTab = (tab) => {
        this.setState({
            currentTab: tab
        }, this.getData(tab));
    }
    //https://fxz4hbm9h9.execute-api.ap-south-1.amazonaws.com/b2cforumdev/api/v1/points/daily


    getData = (type) => {
        // let url = `${discussionBoardEndpoint}/points/${type}`;
        // this.service.postWithCustonAuthToken(url, {
        //     "boardId": "3bcb8873-7949-454e-911a-90008826c117",
        //     "classId": "804d9416-e248-4ae7-a8dd-4c01859fe449",
        //     "groupId": "45162249-a1f2-4689-9df6-ca633875bab2"
        // }, true, token).then(data => {

        //     if (data && data.status) {
        //         if (data.res.data) {
        //             this.setState((prevState) => {
        //                 return {
        //                     ...prevState, ...data.res.data,
        //                     isLoading: false
        //                 }
        //             });
        //         } else {
        //             this.setState({ isLoading: false });
        //         }
        //     } else {
        //         this.setState({ isLoading: false });
        //     }

        // });


        let url = `${discussionBoardEndpoint}/points/all/${type}`;
        this.service.get(url, true).then(data => {

            if (data && data.status) {
                if (data.res.data) {
                    this.setState((prevState) => {
                        return {
                            ...prevState, ...data.res.data,
                            isLoading: false
                        }
                    });
                } else {
                    this.setState({ isLoading: false });
                }
            } else {
                this.setState({ isLoading: false });
            }

        });
    }

    componentDidMount() {
        this.getData(this.state.currentTab);

    }


    render() {
        return (<>
            <div className="leader-board-c ma-box" >
                <div className="leader-board">
                    <ul className="leader-tabs" >
                        <li onClick={() => { this.changeTab('daily') }} className={this.state.currentTab == 'daily' ? 'tab-active-a' : ''}>Daily</li>
                        <li onClick={() => { this.changeTab('weekly') }} className={this.state.currentTab == 'weekly' ? 'tab-active-a' : ''}>Weekly</li>
                        <li onClick={() => { this.changeTab('monthly') }} className={this.state.currentTab == 'monthly' ? 'tab-active-a' : ''}>Monthly</li>
                        <li onClick={() => { this.changeTab('yearly') }} className={this.state.currentTab == 'yearly' ? 'tab-active-a' : ''}>Yearly</li>
                    </ul>
                </div>
                <div className="ma-clearFix"></div>
                <div className="p-grid ma-p10" >
                    <div className='p-col-6'>
                        <img src="./images/leader_board_Background.png" className="leaderboard-img" />
                    </div>
                    <div className="p-col-6 rank-section ma-center" >
                        <p className="rank-lable">Your Rank</p>
                        <p className="rank-value">{this.state.rank}</p>
                    </div>
                </div>
                {/* <hr className="comment-hr" /> */}
                <div style={{ borderBottom: '2px solid #E6E6E6', margin: '0px 20px 14px' }}>

                </div>

                <div className="p-grid ma-center" >
                    <div className='p-col-3'>
                        <p className="desc-value">{this.state.posts} </p>
                        <p className="desc-lable">Posts </p>
                    </div>
                    <div className='p-col-3'>
                        <p className="desc-value">{this.state.replies} </p>
                        <p className="desc-lable">Replies </p>
                    </div>
                    <div className='p-col-3'>
                        <p className="desc-value">{this.state.likes} </p>
                        <p className="desc-lable">Your Likes</p>
                    </div>
                    <div className='p-col-3'>
                        <p className="desc-value">{this.state.dislikes} </p>
                        <p className="desc-lable">Dislikes</p>
                    </div>
                </div>
            </div>
            <div className='p-grid'>
                {
                    this.state.ranks.map((r, index) => {
                        return <div className="p-col-6 rank-item-w" >
                            <div className="rank-item">
                                <div className='p-grid'>
                                    <div className='p-col-2'>
                                        <img src="./images/leader_board_Background.png" className="rank-img" />
                                    </div>
                                    <div className='p-col-8'>
                                        <div className="ma-p10">
                                            <img src={getProfileURL(r.user?.photo)} alt="n" className="profile-img" /> <span className="rank-name"> {r.user.name} </span>
                                        </div>
                                    </div>
                                    <div className='p-col-2'>
                                        <div
                                        // className="ma-p-tb10"
                                        >
                                            <p className="rank-value"><span>Rank </span><span>{index + 1} </span> </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    })
                }

            </div>

        </>
        )
    }
}


const mapStateToProps = state => {
    return {
        discussionUser: state.currentUserPerms && state.currentUserPerms.discussionUser && state.currentUserPerms.discussionUser,
    }
}


export default connect(mapStateToProps, {})(withRouter(LeaderBoard));