import * as ActionTypes from '../actionTypes';
import axios from 'axios';
import { baseUrlAdmin } from '../apiConstants';

export const getPermissionRequested = () => ({ type: ActionTypes.GET_PERMISSIONS_REQUESTED });
const getPermissionReqSucceeded = (data) => ({ type: ActionTypes.GET_PERMISSIONS_SUCCEEDED, data });
const getPermissionRequestFailed = () => ({ type: ActionTypes.GET_PERMISSIONS_FAILED });

const url = `${baseUrlAdmin}/authorize/getPermissions`;

export const getPermissionDetails = () => {
  return axios({
    method: 'get',
    url,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
    }
  });
};

export const getPermissionList = () => (dispatch) => {
  dispatch(getPermissionRequested());
  getPermissionDetails()
    .then((res) => {
      if (res.data) {
        dispatch(getPermissionReqSucceeded(res.data.data));
      } else {
        dispatch(getPermissionRequestFailed());
      }
    })
    .catch((e) => {
      if (e.response && e.response.status == 401) {
        localStorage.clear();
        window.location.reload();
      } else {
        dispatch(getPermissionRequestFailed());
      }
    });
};
