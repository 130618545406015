import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react'
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import ReactApexChart from 'react-apexcharts';
import { Button } from 'primereact/button';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { baseUrlForLongRunningTask } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import Service from '../../../services';

class StundentReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearch: false,
            globalFilter: "",
            data: [],
            columns: [],
            showingReports: false,
            objReport: {},
            series: [
                { name: 'Max marks', data: [] },
                { name: 'Sub.high score', data: [] },
                { name: 'Obtained marks', data: [] }
            ],
            options: {
                xaxis: {
                    categories: [],
                },
                yaxis: { title: { text: 'Marks' } },
                chart: { toolbar: { show: false } }
            },
            isLoading: false
        }
        this.service = new Service();
    }

    componentDidMount() {
        const { examsResult } = this.props;
        if (examsResult) {
            this.setState({
                data: examsResult,
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.examsResult != prevProps.examsResult) {
            const { examsResult } = this.props;
            if (examsResult) {
                this.setState({
                    data: examsResult,
                })
            }
        }

    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    reports = (rowData) => {
      
        let series = [
            { name: 'Max marks', data: rowData.maxMarks },
            { name: 'Sub.high score', data: rowData.subHighScore },
            { name: 'Obtained marks', data: rowData.obtainedMarks }
        ]
        let options = {
            xaxis: { categories: rowData.subjectsCategories, },
            yaxis: { title: { text: 'Marks' } },
            chart: { toolbar: { show: false } }
        }
        // if (rowData.reportData.length < 6) {
        //     for (let i = 0; i < 7; i++) {
        //         if (i > rowData.reportData.length) {
        //             rowData.reportData.push({})
        //         }
        //     }
        // }
        this.setState({
            showingReports: true,
            objReport: rowData,
            series: series,
            options: options
        })
    }


    onClickDownloadAllReports = () => {
        
        let examId = this.props?.examId
        let url = `${baseUrlForLongRunningTask}/longrunning/${examId}/download-all-reports`
        this.setState({ isLoading: true })
        axios.get(url, {headers: {'Access-Control-Allow-Origin': '*',}
        }).then(res => {
            if (res?.status && res?.data?.status) {
                this.handleDownload('https://classetofflinecontent-old.s3.ap-south-1.amazonaws.com/' + res.data.data.paperLink, res.data.data.paperLink)
                this.setState({ isLoading: false })
            } else {
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.message, life: 3000 });
            }
        }).catch(err => {
            this.setState({ isLoading: false })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err?.message, life: 3000 });
        })
    }


    handleDownload = (url, filename) => {

        axios.get(url, {
            responseType: 'blob',

        })
            .then((res) => {

                const url = window.URL.createObjectURL(
                    new Blob([res.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    filename,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                // fileDownload(res.data, filename)
            })
    }

    render() {
        const { objReport } = this.state
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >Student Report</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={(e) => { this.setState({ globalFilter: e.target.value }) }} />}
                </div>
                <div className=" flex">
                    <div className='ma-mr20 ma-mt5'>
                        <Button label='Download all reports' onClick={this.onClickDownloadAllReports} />
                    </div>
                    <div className='p-input-icon-left ma-tbl-filter'>
                        <ul className="ma-tbl-li">
                            <Authorizer permId={PERMISSIONS.BOARDS_SEARCH}>
                                <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch, globalFilter: '' }) }}></i></li>
                                <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                            </Authorizer>
                            <Authorizer permId={PERMISSIONS.BOARDS_DOWNLOADCSV}>
                                <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                                <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                            </Authorizer>
                        </ul>
                    </div>
                </div>
            </div>
        );
        return (
            <>
                <div className='ma-mt20 datatable-crud-demo'>
                    <DataTable value={this.state.data}
                        ref={(el) => this.dt = el} className="ma-table-d" tableClassName='ma-table-tbl'
                        scrollable rows={10} paginator
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} reports"
                        globalFilter={this.state.globalFilter}
                        header={header}
                    >
                        <Column header="User Name" field='nameOfTheStudent' />
                        <Column header="Phone Number" field='phoneNumber' />
                        <Column header="Reports" body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    <Authorizer permId={PERMISSIONS.EXAM_PAPER_DOWNLOAD} >
                                        <span data-pr-tooltip="Reports" data-pr-position="bottom" className={`e${rowIndex} ma-mr20`}
                                            onClick={() => { this.reports(rowData) }}
                                        >
                                            <i className='pi pi-eye'></i>
                                        </span>
                                        <Tooltip className="table-li-tooltip2" autoHide={false} target={`.e${rowIndex}`} />
                                    </Authorizer>
                                </>
                            )
                        }} />
                    </DataTable>

                    <Dialog
                        header={<>Report</>}
                        draggable={false}
                        blockScroll={true}
                        closeOnEscape={false}
                        className='ma-alert-box'
                        dismissableMask={false}
                        closable={true}
                        visible={this.state.showingReports}
                        style={{ width: '50vw' }}
                        // footer={() => {
                        //     return (<div>
                        //         <Button label="Download Report" className='p-button-outlined ma-mt10' />
                        //         <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmReset} />
                        //     </div>)
                        // }}
                        onHide={() => this.setState({ showingReports: false, objReport: {}, series: [], options: {} })}
                    >
                        <div ref={el => this.componentRef = el} style={{ border: "1px black solid" }} className='progress-report ma-w100p'>
                            <div className='ma-p10' style={{ paddingBottom: "0px" }}>
                                <img src={`${objReport?.orgImageWithDetails?.storageLocation ? objReport?.orgImageWithDetails?.storageLocation : ""}`} width={'100%'} height={'80'} className='logo-with-details' />
                            </div>
                            <hr />
                            <h3 className='ma-text-center'>STUDENT PROFILE</h3>
                            <hr />
                            <div className=' p-grid'>
                                <div className='stud-details span-font-size p-col-9 p-grid ma-ml20'>
                                    <div className='p-col-4 ma-mt10' style={{ padding: "0.2rem" }}>
                                        <span style={{ fontWeight: "bold" }}>Name of the Student </span>
                                    </div>
                                    <div className='p-col-8 ma-mt10' style={{ padding: "0.2rem" }}>
                                        <span style={{ fontWeight: "bold" }}>: {objReport?.nameOfTheStudent}</span>
                                    </div>
                                    <div className='p-col-4' style={{ padding: "0.2rem" }}>
                                        <span style={{ fontWeight: "bold" }}>Father's Name </span>
                                    </div>
                                    <div className='p-col-8' style={{ padding: "0.2rem" }}>
                                        <span style={{ fontWeight: "bold" }}>: {objReport?.fathersName}</span>
                                    </div>
                                    <div className='p-col-4' style={{ padding: "0.2rem" }}>
                                        <span style={{ fontWeight: "bold" }}>Mother's Name </span>
                                    </div>
                                    <div className='p-col-8' style={{ padding: "0.2rem" }}>
                                        <span style={{ fontWeight: "bold" }}>: {objReport?.mothersName}</span>
                                    </div>
                                    <div className='p-col-4' style={{ padding: "0.2rem" }}>
                                        <span style={{ fontWeight: "bold" }}>Class </span>
                                    </div>
                                    <div className='p-col-8' style={{ padding: "0.2rem" }}>
                                        <span style={{ fontWeight: "bold" }}>: {objReport?.class}</span>
                                    </div>
                                    <div className='p-col-4' style={{ padding: "0.2rem" }}>
                                        <span style={{ fontWeight: "bold" }}>Admin No. </span>
                                    </div>
                                    <div className='p-col-8' style={{ padding: "0.2rem" }}>
                                        <span style={{ fontWeight: "bold" }}>: 1234</span>
                                    </div>
                                </div>
                                <div className='p-col-2'>
                                    <img src={`${objReport?.studentProfile?.storageLocation ? objReport?.studentProfile?.storageLocation : ""}`} height={'130'} width={'120'} className='ma-mt5' />
                                </div>
                            </div>
                            <div className='list-of-tables ma-ml10 ma-mr10'>
                                <table border="1" width={"100%"} className='test-table' style={{ borderCollapse: "collapse" }}>
                                    <thead>
                                        <tr style={{ fontWeight: "bold" }}>
                                            <td colspan="12" width={"100%"} className='test-head ma-text-center' >PERIODIC TEST - I EXAMINATION REPORT</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='test-head'>
                                            <th width={"20%"}>Subjects</th>
                                            <th width={"15%"}>Total marks</th>
                                            <th width={"15%"}>Obtained marks</th>
                                            <th width={"10%"}>Grade</th>
                                            <th width={"10%"}>Grade point</th>
                                            <th width={"25%"}>Remarks</th>
                                        </tr>
                                        {objReport?.reportData?.map((e, i) => {
                                            return (
                                                <tr className='test-data ma-text-center' width={"20px"} height = {e?.subjectName ? "": "15px"}>
                                                    <td>{e?.subjectName}</td>
                                                    <td>{objReport?.maxMarks[i]}</td>
                                                    <td>{e?.obtainedMarks}</td>
                                                    <td>{e?.grade}</td>
                                                    <td>{e?.gradePoint}</td>
                                                    <td>{e?.remarks}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                    <tfoot>
                                        <tr className='test-head' style={{ fontWeight: "bold" }}>
                                            <td className='ma-text-center'>GRAND TOTAL</td>
                                            <td className='ma-text-center'>{objReport?.totalSujectsMarks}</td>
                                            <td className='ma-text-center'>{objReport?.totalObtainedMarks}</td>
                                            <td className='ma-text-center'>{objReport?.studentGrade}</td>
                                            <td className='ma-text-center'>{objReport?.studentGradePoint}</td>
                                            <td className='ma-text-center'>{objReport?.overallRemarks}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <table border='1' className='test-table ma-mt10' width={"100%"} style={{ borderCollapse: "collapse" }}>
                                    <tbody>
                                        <tr className='test-head' style={{ fontWeight: "bold" }}>
                                            <th width={"20%"}>Grade</th>
                                            <th >A+</th><th >A</th><th >B+</th><th >B</th><th >C</th><th >D</th><th >E</th>
                                        </tr>
                                        <tr className='test-data ma-text-center'>
                                            <td width={"20%"}>Range (%)</td>
                                            <td >90-100</td><td >80-89</td><td >70-79</td><td >60-69</td><td >45-59</td>
                                            <td >44-33</td><td >BELOW 33</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table border='1' className='test-table ma-mt10' width={"100%"} style={{ borderCollapse: "collapse" }}>
                                    <tbody>
                                        <tr className='test-head ma-text-center'>
                                            <td width={"15%"}>Month</td>
                                            <td >APR</td><td >MAY</td><td >JUN</td><td >JUL</td><td >AUG</td><td >SEP</td>
                                            <td >OCT</td><td >NOV</td><td >DEC</td><td >JAN</td><td >FEB</td><td >MAR</td>
                                            <td>TOTAL</td><td>PER(%)</td>
                                        </tr>
                                        <tr className='test-data ma-text-center'>
                                            <td width={"15%"}>Working Days</td>
                                            <td ></td><td ></td><td ></td><td ></td><td ></td><td ></td><td ></td>
                                            <td ></td><td ></td><td ></td><td ></td><td ></td><td ></td><td ></td>
                                        </tr>
                                        <tr className='test-data ma-text-center'>
                                            <td width={"15%"}>Days Present</td>
                                            <td ></td><td ></td><td ></td><td ></td><td ></td><td ></td><td ></td>
                                            <td ></td><td ></td><td ></td><td ></td><td ></td><td ></td><td ></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table border='1' className='test-table ma-mt10' width={"100%"} style={{ borderCollapse: "collapse" }}>
                                    <tr className='test-head ma-text-center'>
                                        <td width={'50%'}>CLASS TEACHER REMARK</td>
                                        <td>PARENTS REMARK</td>
                                    </tr>
                                    <tbody>
                                        <tr>
                                            <td className='remarks-height' height={'100px'}></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h3 className='graph-head ma-text-center ma-mt10'>Graphycal Analysis of {objReport?.nameOfTheStudent} Marks</h3>
                            <div>
                                <ReactApexChart className='graph-height' options={this.state?.options} series={this.state?.series} type="bar" height={250} />
                            </div>
                            <div className='ma-ml20 ma-mr20 ma-mb10' style={{ display: "flex", justifyContent: "space-between", marginTop: "50px" }}>
                                <h3>Class Teacher's Sign</h3>
                                <h3>Bigmaster's Sign</h3>
                                <h3>Parent/Guardian's Sign</h3>
                            </div>
                        </div>
                        <div className='flex '>
                            <ReactToPrint
                                pageStyle="padding:'10px',size : A4 portrait @span: 10px"
                                content={() => this.componentRef}
                                onAfterPrint={() => this.setState({ showingReports: false, objReport: {}, series: [], options: {} })}
                            >
                                <PrintContextConsumer>
                                    {({ handlePrint }) => (
                                        <Button label="Download Report" className='ma-mt10 ml-auto' onClick={handlePrint} />
                                    )}
                                </PrintContextConsumer>
                            </ReactToPrint>
                        </div>
                    </Dialog>
                </div>
                {this.state.isLoading && <LoadingComponent />}
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}

export default StundentReport