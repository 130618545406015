import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _ from 'lodash';

import ContentPlayers from '../contentMaster/contentPlayers';
import VideoLink from '../questionBank/common/videoLink';
import AddResourcesToPeriod from './addResourcesToPeriod';
import { Toast } from 'primereact/toast';

export default class PeriodResources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowLinkVideo: false,
        }
    }

    onAssignVideos = (videos) => {
        this.setState({
            isShowLinkVideo: false
        }, () => this.props.onAssignVideos(videos))
    }



    render() {
        const { videoLinking } = this.props;
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <Button icon="pi pi-plus" iconPos='left' label={'Add Resource'} className='' onClick={() => {
                        if (!this.props.chapterId) {
                            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select Chapter Name to add Resources', life: 3000 });
                        } else {
                            this.setState({ isShowLinkVideo: true })
                        }
                    }} />
                </div>
            </div>
        );
        return (
            <div key={this.props.id} id={this.props.id} className="card datatable-crud-demo vidoes-linked">
                <DataTable
                    header={header}
                    value={videoLinking || []}
                    dataKey="contentId"
                    paginator
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    rows={10}
                    responsiveLayout="scroll"
                    selectionMode="checkbox"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                >
                    <Column field="contentName" header="Resource Name" />
                    <Column body={(rowData) => {
                        return (
                            <div className="preview-c" >
                                <span onClick={() => {
                                    
                                    this.setState({
                                        previewContent: rowData,
                                        isShowPreview: true
                                    });
                                }} > <i className='pi pi-eye ma-mr10' />Preview </span>
                            </div>
                        )
                    }} field="status" header="Preview" />
                    <Column body={(rowData) => {
                        return (
                            <div className="preview-c" >
                                <span onClick={() => {
                                    this.props.removeLinkedVideo(rowData)
                                }} > <i className='pi pi-times ' /></span>
                            </div>
                        )
                    }} field="status" header="Remove" />
                </DataTable>

                {this.state.isShowLinkVideo && <AddResourcesToPeriod
                    onHide={() => {
                        this.setState({
                            isShowLinkVideo: false,

                        })


                    }}

                    chapterId={this.props.chapterId}
                    selectedTopicResourceTypes={[{ label: 'VIDEO', value: 'mp4', isActive: true, isSelected: true }]}
                    contentInfo={videoLinking || []}
                    resourseOrigin="upload"
                    //  selectedType={this.state.selectedType}
                    onSelectContent={this.props.onSelectContent}
                    onAssignVideos={this.onAssignVideos}
                />}

                {this.state.isShowPreview && <ContentPlayers
                    id={this.props.id}
                    onCloseDialog={() => {
                        this.setState({
                            isShowPreview: false,
                            previewContent: false
                        })
                    }} contentInfo={_.cloneDeep(this.state.previewContent)} />}

                <Toast ref={(el) => this.toast = el} position="bottom-right" />

            </div>
        )
    }
}
