import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Service from '../../../services';
import { DataTable } from 'primereact/datatable';
import SvgViewer from './../../customComponents/svgViewer';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import cloneDeep from 'lodash/cloneDeep';
import './styles.scss'
import Authorizer, { PERMISSIONS } from '../../session/authorizer';

export const FileTypes = {
    VIDEO: 'mp4',
    PPT: 'ppt',
    PDF: 'pdf',
    QUIZ: 'quiz'
}


const getResources = () => {
    let resources = [];
    Object.keys(FileTypes).forEach((key) => {
        resources.push({ label: key, value: FileTypes[key], isActive: false })
    });
    return resources;
}


class ResourcesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resources: this.props.resources ? this.props.resources : getResources()
        }
    }


    handleSelect = (subject) => {
        let resources = this.state.resources.map((s) => {
            if (s.value == subject.value) {
                s.isActive = s.isActive ? false : true;
            }

            return s;
        });
        this.setState({
            resources
        });
    }


    render() {
        return (<div>
            <div className='ma-main resources-list'>
                <p className='r-title'>Resource List</p>
                {this.state.resources.map((res) => {
                    return <>
                        <Checkbox inputId={res.value} value="New York"
                            onChange={() => this.handleSelect(res)}
                            checked={res.isActive}
                        ></Checkbox>
                        <label htmlFor={res.value} className="p-checkbox-label res-lable">{res.label}</label>
                    </>
                })}
                <div className='sp-clearFix'></div>
                {
                    <Authorizer permId={PERMISSIONS.CURRICULUM_ASSIGN_RESOURCE} >
                        <Button label="Assign Resource Types" className='ma-mt20' onClick={() => this.props.addOrUpdateResourcesInfo(this.state.resources)} />
                    </Authorizer>
                }
            </div>
        </div>
        )
    }
}




export default ResourcesList;