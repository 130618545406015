import * as ActionType from "../actionTypes";

export default (state = {}, action) => {
  switch(action.type){
    case ActionType.REQUEST_EDIT_QUIZ_DETAILS :

        return{
            ...state,
            loading:true,
            error:false,
            errorInfo:null,
            response: null
            }
    case ActionType.RECEIVED_EDIT_QUIZ_DETAILS :
        return{
            ...state,
            loading:false,
            response:action.res,
            error:false,
            errorInfo:null
        }
    case ActionType.ERROR_EDIT_QUIZ_DETAILS:
        return{
            ...state,
            loading:false,
            error:true,
            errorInfo:action.err,
            response: null
        }
        case ActionType.ERROR_DUPLICATE_QUESTION:
            return{
                ...state,
                duplicateQuestionError: action.duplicateQuestionError,
                
            } 
        case ActionType.EDIT_QUIZ_CREATE_DATA: 
        return{
            ...state,
            updateQuizCreate:action.data
        }    
    case ActionType.GET_EDIT_QUIZ_DETAILS:
        return{
            ...state,
             isQuiz:true,
             quizInfo: action.quizInfo,
             selectedQuestionId:action.selectedQuestionId,
             isSuccessfullyAdded:action.isSuccessfullyAdded
        }  
    case ActionType.CLEAR_GET_EDIT_QUIZ_DETAILS:
        return{
            ...state,
            quizInfo:{},
            isQuiz:false,   
        }
    case ActionType.CLEAR_DUPLICATE_ERROR_QUESTION:
        return{
            ...state,
            selectedQuestionId:null,
            isSuccessfullyAdded:false,
            duplicateQuestionError:null
            
        }        
    case ActionType.CLEAR_EDIT_QUIZ_DETAILS:
        return {
        }
    
    default : 
      return state    

  }
}