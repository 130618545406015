import * as ActionTypes from './../actionTypes';

let initialState = {
    branches: null,
    loading: false,
    error: false,
    errorMessage: ''
}


export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.REQUEST_BRANCH_DATA:
            return {
                ...state,
                isLoading: true,
                error: false,
                errorInfo: null
            }
        case ActionTypes.RECEIVED_BRANCH_DATA:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: false,
                errorInfo: null,
                etag: action.etag
            }
        case ActionTypes.ERROR_BRANCH_DATA:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorInfo: action.err
            }
        case ActionTypes.CLEAR_BRANCH:
            return {}
        case ActionTypes.REQUEST_UPDATE_BRANCH_DATA:
            return {
                ...state,
                isLoading: true,
                isUpdating: true,
                error: false,
                errorInfo: null
            }
        case ActionTypes.RECEIVED_UPDATE_BRANCH_DATA:
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: false,
                isUpdating: false,
                errorInfo: null,
                etag: action.etag
            }
        case ActionTypes.ERROR_UPDATE_BRANCH_DATA:
            return {
                ...state,
                isLoading: false,
                error: true,
                isUpdating: false,
                errorMessage: action.err.message
            }
        default: return state;

    }
} 