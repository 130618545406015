import moment from 'moment'
import { Dialog } from 'primereact/dialog'
import React, { Component } from 'react'

export default class PreViewDetails extends Component {
    render() {

        // console.log('props',this.props)
        const {viewStaffDetails} = this.props
        return (
            <>
                <Dialog
                    visible={this.props.previewDetails}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={true}
                    style={{ maxWidth: '30%', minWidth: '30%' }}
                    footer={this.footer}
                    header='Staff Details'
                    onHide={this.props.onClosePreview}
                >
                    <div className="p-grid subject-add-dialog">
                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Designation</b></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {viewStaffDetails.designation}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Name</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6 ">
                            <p className=''>: {viewStaffDetails.name}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Date of Joining</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {moment(viewStaffDetails.dateOfJoining).format('DD/MM/YYYY')}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Aadhar No</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {viewStaffDetails.aadharCard}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Phone Number</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {viewStaffDetails.phoneNumber}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Driving Licence</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {viewStaffDetails.drivingLicence }</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Years Of Experience</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {viewStaffDetails.yearsOfExperience }</p>
                        </div>


                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>No. of Cases Involved</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {viewStaffDetails.noOfCasesInvolved}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Class of Vehicle</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {viewStaffDetails.classOfVehicle}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Issuing Authority (RTO)</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {viewStaffDetails.rtoAuthority}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>RTO Issued & Expiry Dates</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6">
                            <p className=''>: {moment(viewStaffDetails.rtoIssuedDate).format('DD/MM/YYYY')} - {moment(viewStaffDetails.rtoExpiryDate).format('DD/MM/YYYY')}</p>
                        </div>
                    </div>
                </Dialog>
            </>
        )
    }
}
