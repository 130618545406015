import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';
export default class AllDataTableForExcel extends Component {

    exportCSV = () => {
        this.dt.exportCSV();
    }


    render() {
        // console.log(this.props)

        return (

            <div className="p-col-12 p-md-12">
                <div className="card datatable-crud-demo ma-m30">
                    <DataTable ref={(el) => this.dt = el}
                        value={this.props.data}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    //  header={header}
                    >

                        <Column field="Board Id" header="Board Id" />
                        <Column field="Class Id" header="Class Id" />
                        <Column field="Group Id" header="Group Id" />
                        <Column field="Subject Id" header="Subject Id" />
                        <Column field="Subject Name" header="Subject Name" />
                        <Column field="Chapter Id" header="Chapter Id" />
                        <Column field="Chapter Name" header="Chapter Name" />
                        <Column field="Topic Id" header="Topic Id" />
                        <Column field="Topic Name" header="Topic Name" />
                        <Column field="Content Number" header="Content Number" />
                        <Column field="Content Title" header="Content Title" />
                        <Column field="Content Uri" header="Content Uri" />
                        <Column field="Content Type" header="Content Type" />
                        <Column field="Content Priority" header="Content Priority" />
                    </DataTable>
                </div>
            </div>
        )
    }
}
