import React, { Component } from 'react';
import { discussionBoardEndpoint } from '../../../store/apiConstants';
import Answer from '../common/answer';
import Service from '../service';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getProfileURL } from './../../../utile';
import CreateAnswer from './createAnswer';
import { Menu } from 'primereact/menu';

class Answers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort: '-createdAt',
            limit: 5,
            page: 1,
            search: '',
            answers: [],
            stopRequests: false,
            isLoading: false,
            currentScrollPosition: 0,
            currentFilter: 1,
            open: false,
            api: 1,
            repliesFilterRef: React.createRef(null),
            answer: {
                content: "",
                tags: [],
                attachments: [],
                postId: this.props.postId
            },
            totalRecords: 0

        }
        this.service = new Service();
        this.myRef = React.createRef();
    }

    getFilterText = (filter) => {

        switch (filter) {
            case 1:
                return 'Most Recent';
            case 2:
                return 'Most Liked';
            case 3:
                return 'My Replies / Comments';
            default:
                return 'Most Recent';
        }

    }

    getAnswers = (postId, sort, limit, page, search, isSearch) => {
        if (!this.state.isLoading && !this.state.stopRequests) {
            this.setState({
                isLoading: true
            });
            const url = `${discussionBoardEndpoint}/posts/${postId}/answers?sort=${sort}&limit=${limit}&page=${page}&search=${search}`
            this.service.get(url, true).then(data => {
                let re = isSearch ? [] : this.state.answers;
                if (data && data.status) {
                    if (data.res.data.length > 0) {
                        re = re.concat(data.res.data)
                        this.setState((prevState) => {
                            return {
                                answers: re,
                                totalRecords: data.res.totalNumOfData,
                                currentScrollPosition: window.scrollY,
                                page: data.res.page,
                                isLoading: false,
                            }
                        });
                    } else {
                        this.setState({ isLoading: false, stopRequests: true, answers: re });
                    }
                } else {
                    this.setState({ isLoading: false, stopRequests: true, answers: re });
                }
            });
        }
    }

    getAnswersAPI2 = (postId, sort, limit, page, search, isSearch) => {
        if (!this.state.isLoading && !this.state.stopRequests) {
            this.setState({
                isLoading: true
            });


            const url = `${discussionBoardEndpoint}/ans-comnt/get-ans-cmnts?postId=${postId}&sort=-createdAt&limit=${limit}&page=${page}&search=${search}`
            this.service.get(url, true).then(data => {
                let re = isSearch ? [] : this.state.answers;
                if (data && data.status) {
                    if (data.res.data.length > 0) {

                        re = re.concat(data.res.data)
                        this.setState((prevState) => {
                            return {
                                answers: re,
                                totalRecords: data.res.totalNumOfData,
                                currentScrollPosition: window.scrollY,
                                page: data.res.page,
                                isLoading: false,
                                open: false
                            }
                        });
                    } else {
                        this.setState({ isLoading: false, stopRequests: true, answers: re });
                    }
                } else {
                    this.setState({ isLoading: false, stopRequests: true, answers: re });
                }

            });
        }
    }

    componentDidMount() {
        if (this.state.currentFilter == 3) {
            this.getAnswersAPI2(this.props.postId, this.state.sort, this.state.limit, this.state.page, this.state.search);
        } else {
            this.getAnswers(this.props.postId, this.state.sort, this.state.limit, this.state.page, this.state.search);
        }

    }

    handleToggle = (event) => {
        this.setState({
            open: !this.state.open,
            repliesFilterRef: event.currentTarget
        });
    };

    handleClose = () => {
        this.setState({
            open: !this.state.open
        });
    }

    onApplyFilter = (type) => {
        this.setState({
            open: false,

        }, () => {
            if (type != this.state.currentFilter) {
                if (type == 1) {
                    this.setState({
                        sort: '-createdAt',
                        currentFilter: 1,
                        stopRequests: false
                    }, () => { this.getAnswers(this.props.postId, '-createdAt', this.state.limit, 1, this.state.search, true) });


                } else if (type == 2) {
                    this.setState({
                        sort: '-likeCount,-createdAt',
                        currentFilter: 2,
                        stopRequests: false
                        // open: false
                    },
                        () => { this.getAnswers(this.props.postId, '-likeCount,-createdAt', this.state.limit, 1, this.state.search, true) });
                } else if (type == 3) {
                    this.setState({
                        currentFilter: 3,
                        //  open: false,
                        // sort: '-likeCount,-createdAt',
                        stopRequests: false
                    },
                        () => { this.getAnswersAPI2(this.props.postId, null, this.state.limit, 1, this.state.search, true) });

                }
            }
        });
    }

    // onScroll = () => {
    //     const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
    //     const current = this.myRef.current;
    //     //console.log(current.scrollHeight);
    //     //console.log(current.clientHeight);
    //     //console.log(current.scrollTop);

    //     let total = current.scrollHeight - current.clientHeight;
    //     let dd = (current.scrollTop / total) * 100;

    //     if (((current.scrollTop / total) * 100) > 80) {
    //         // let { sort, limit, page, search, currentTab } = this.state;
    //         // this.getDoubts(sort, limit, page + 1, search, currentTab);
    //         if (this.state.currentFilter == 3) {
    //             this.getAnswersAPI2(this.props.postId, this.state.sort, this.state.limit, this.state.page + 1, this.state.search);
    //         } else {
    //             this.getAnswers(this.props.postId, this.state.sort, this.state.limit, this.state.page + 1, this.state.search);
    //         }

    //     }

    //     //console.log(`onScroll, window.scrollY: ${scrollY} myRef.scrollTop: ${current.scrollTop}`)
    //     this.setState({
    //         currentScrollPosition: current.scrollTop
    //     })
    // }

    loadMoreClick = () => {
        if (this.state.currentFilter == 3) {
            this.getAnswersAPI2(this.props.postId, this.state.sort, this.state.limit, this.state.page + 1, this.state.search);
        } else {
            this.getAnswers(this.props.postId, this.state.sort, this.state.limit, this.state.page + 1, this.state.search);
        }
    }



    onAnswerPost = (answer) => {
        this.setState((prevState) => ({
            answers: [answer, ...prevState.answers].flat(),
        }));
    }

    render() {
        const { item } = this.state;
        //   console.log('state', this.state)


        const items = [
            {
                template: (<><div onClick={(event) => { this.onApplyFilter(1); this.menu.toggle(event) }} className='ma-pointer' ><img src="./images/most_recent1.svg" className="text-icon" /> Most Recent</div></>)
            },
            {
                template: (<><div onClick={(event) => { this.onApplyFilter(2); this.menu.toggle(event) }} className='ma-pointer'><img src="./images/most_liked1.svg" className="text-icon" /> Most Liked</div></>)
            },
            {
                template: (<><div onClick={(event) => { this.onApplyFilter(3); this.menu.toggle(event) }} className='ma-pointer'><img src="./images/my_replies1.svg" className="text-icon" /> My Replies/Comments</div></>)
            }
        ]
        return (

            <>
                < CreateAnswer onAnswerPost={this.onAnswerPost} postId={this.props.postId} />
                <p className="answers-filter-txt">Reply's
                    <span className="ma-right answers-filter ma-pointer"
                        //onClick={this.handleToggle}
                        onClick={(event) => this.menu.toggle(event)}
                    > {this.getFilterText(this.state.currentFilter)}
                        <img src="./images/Toop_down.svg" className="top-down most recent" />
                    </span>
                    <Menu model={items} className='answer-menu' popup ref={el => this.menu = el} />
                </p>
                <hr className="comment-hr ma-hr2 " />
                <div className="answers-list ma-scroll-vr" onScroll={this.onScroll} ref={this.myRef} >
                    {this.state.answers.length == 0 && <p className="no-replies">No Replies</p>}
                    {this.state.answers.map((answer, index) => {
                        return <div key={answer._id}> <Answer item={answer} postId={this.props.postId} />
                            {/* {index < this.state.answers.length - 1 && <hr className="comment-hr" />} */}
                        </div>
                    })}
                </div>
                {
                    (!this.state.stopRequests && this.state.totalRecords > this.state.answers.length) && <div className="load-w">
                        <span className="load-more" onClick={this.loadMoreClick}>Load More Replies <i className='pi pi-chevron-down' /></span>
                    </div>
                }

            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        discussionUser: state.currentUserPerms && state.currentUserPerms.discussionUser && state.currentUserPerms.discussionUser,
    }
}

export default connect(mapStateToProps, {})(withRouter(Answers));