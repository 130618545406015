import * as ActionTypes from './../actionTypes';



export default (state = {
    boards: [],
    boardsWithoutMeluha: [],
    activeBoards: [],
    activeBoardsWithoutMeluha: [],
    contentRepoBoards:[],
    isLoading: true,
    error: false,
    errorMessage: '',
}, action) => {

    switch (action.type) {
        case ActionTypes.REQUEST_BOARDS:
            return {
                ...state,
                isLoading: true,
                error: false,
            }
        case ActionTypes.RECEIVED_BOARDS:
            return {
                ...state,
                isLoading: false,
                boards: action.res.boards || [],
                boardsWithoutMeluha: action.res.boardsWithoutMeluha || [],
                activeBoards: action.res.activeBoards || [],
                activeBoardsWithoutMeluha: action.res.activeBoardsWithoutMeluha || [],
                error: false,
                etag: action.etag
            }
        case ActionTypes.ERROR_BOARDS:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: action.err.message
            }
        case ActionTypes.SELECTED_CLASS_FROM_BOARD:
            return {
                ...state,
                selectedBoard: action.selectedBoard
            }
        case ActionTypes.SELECT_GROUP_FROM_CLASS:
            return {
                ...state,
                selectedClass: action.selectedClass
            }

        case ActionTypes.CLEAR_BOARDS_DATA:
            return {
                boards: [],
                boardsWithoutMeluha: [],
                activeBoards: [],
                isLoading: false,
                error: false,
                errorMessage: '',
            }
        case ActionTypes.REQUEST_UPDATE_ORGANIZATION:
            return {
                ...state,
                isUpdated: false,
                isLoading: true,
                error: false,
                errorMessage: '',
            }

        case ActionTypes.RECEIVED_UPDATE_ORGANIZATION:
            return {
                ...state,
                isLoading: false,
                isUpdated: true,
                boards: action.res.boards || [],
                boardsWithoutMeluha: action.res.boardsWithoutMeluha || [],
                activeBoards: action.res.activeBoards || [],
                activeBoardsWithoutMeluha: action.res.activeBoardsWithoutMeluha || [],
                error: false,
                errorMessage: '',
                etag: action.etag
            }
        case ActionTypes.ERROR_UPDATE_ORGANIZATION:
            return {
                ...state,
                isLoading: false,
                isLoading: false,
                error: true,
                errorMessage: action.err.message,
            }
        case ActionTypes.CLEAR_UPDATE_ORGANIZATION_DATA:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: action.message,

            }


        case ActionTypes.REQUEST_CONTENT_BOARDS:
            return {
                ...state,
                isLoading: true,
                error: false,
            }
        case ActionTypes.RECEIVED_CONTENT_BOARDS:
            
            return {
                ...state,
                isLoading: false,
                contentRepoBoards: action.contentRepoBoards,
                error: false,
            }
        case ActionTypes.ERROR_CONTENT_BOARDS:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: action.err.message
            }

        default: return state;

    }
}