import React from 'react';
import { Toolbar } from 'primereact/toolbar';
import XLSX from 'xlsx';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { BreadCrumb } from 'primereact/breadcrumb';
import { DataTable } from 'primereact/datatable';
import moment from "moment";
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {
    getBulkUploadTransactionDetailsAdmins,
    getBulkUploadTransactionsAdmins,
    clearBulkUserUploadDataAdmins
} from './../../../store/actions';
import { saveBulkUploadAdmins } from './../../../store/actions';
import Navbar from '../../navbar';

import './styles.scss'
import { BasicLazyParams } from '../../../utile';
import BulkUploadDetails from './bulkUploadDetails';
import Authentication from './../../session';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { Dialog } from 'primereact/dialog';
import { cloneDeep } from 'lodash';
const home = { icon: 'pi pi-home' }
const items = [
    { label: 'Management' },
    { label: 'Admin Management' },
    { label: 'Bulk Admin Upload', className: 'ma-active-item' }
];

/* list of supported file types */
const SheetJSFT = ['xlsx', 'xlsb', 'xlsm', 'xls', 'csv']
    .map(function (x) {
        return '.' + x;
    })
    .join(',');

/* generate an array of column objects */
const make_cols = (refstr) => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
};

const SampleBulkUplodData = [];
class BulkUpload extends React.Component {
    state = {
        open: false,
        openSnackBar: false,
        users: [],
        showMissedListTable: false,
        showUploadBtn: false,
        showLoader: false,
        transactionId: null,
        totalRecords: 0,
        globalSearch: '',
        lazyParams: BasicLazyParams
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };
    handleClickOpenDelete = () => {
        this.setState({ openDelete: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleDeleteClose = () => {
        this.setState({ openDelete: false });
    };

    getBulkUploadTransactionsData = () => {
        this.props.getBulkUploadTransactionsAdmins({ userId: this.props.userId }, { ...this.state.lazyParams, globalSearch: this.state.globalSearch });
    };

    componentDidMount = () => {
        this.getBulkUploadTransactionsData();
    };

    componentWillUnmount() {
        this.props.clearBulkUserUploadDataAdmins();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error) {
            if (this.props.error) {
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: 'Failed to upload bulk admin data', life: 5000 });
            }
        }

        if (prevProps.bulkUserUploadSaveResponse !== this.props.bulkUserUploadSaveResponse) {
            if (this.props.bulkUserUploadSaveResponse) {
                const transactionId = this.props.bulkUserUploadSaveResponse.transaction_id ? this.props.bulkUserUploadSaveResponse.transaction_id : '';
                this.toast.show({ severity: 'success', summary: 'Process is initiated', detail: `Bulk admins upload process initiated, kindly refer the transaction ${transactionId} details in below table`, life: 10000 });
                this.getBulkUploadTransactionsData();
            }
        }
    }

    handleFile = (file) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        let data = [];
        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            data = XLSX.utils.sheet_to_json(ws, { raw: false });
            this.setState({ data: data, cols: make_cols(ws['!ref']) });

            const newJsonSheet = [];
            for (let i = 0; i < data.length; i++) {
                let excelRowData = {
                    ...data[i],
                    __rowNum__: undefined
                };

                excelRowData = JSON.parse(JSON.stringify(excelRowData));

                newJsonSheet.push(excelRowData);
            }

            const users = newJsonSheet.map((record) => ({
                ...record,
                mobile: `+91${record.mobile}`
            }));

            this.setState({ users });
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    };

    handleChange = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            this.setState({ fileSelected: files[0]['name'] });
            this.handleFile(files[0]);
            e.target.value = null;
        }
    };
    exportCSV = () => {
        this.dt.exportCSV();
    }
    exportCSV2 = () => {
        this.dt2.exportCSV();
    }

    onPage = (event) => {
        this.setState({ lazyParams: event }, this.getBulkUploadTransactionsData);
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getBulkUploadTransactionsData);
    }


    uploadBulkUsers = () => {


        let data = cloneDeep(this.state.users)
        let paylodForApi = data.map((element, index) => {
            let k = {
                "username": element.username,
                "user": this.props.userId,
                "password": element.password,
                "user_info": {
                    "username": element.username,
                    "name": element.name,
                    "email": element.email.replace(/^mailto:/i, ''),
                    "phone_number": `+91${element.phone_number}`,
                    "roles": element.roles,
                    "boardId": element.Curriculum,
                    "status": element.status,
                    "canChangeRole": element.canChangeRole,
                    "userType": element.userType,
                },
            }

            let assignedSubjects = []

            if (element.boardId1 != undefined && element.classId1 != undefined && element.groupId1 != undefined && element.subjectId1 != undefined) {
                assignedSubjects.push({ subjectId: element.subjectId1, boardId: element.boardId1, classId: element.classId1, groupId: element.groupId1 })
            }
            if (element.boardId2 != undefined && element.classId2 != undefined && element.groupId2 != undefined && element.subjectId2 != undefined) {
                assignedSubjects.push({ subjectId: element.subjectId2, boardId: element.boardId2, classId: element.classId2, groupId: element.groupId2 })
            }
            if (element.boardId3 != undefined && element.classId3 != undefined && element.groupId3 != undefined && element.subjectId3 != undefined) {
                assignedSubjects.push({ subjectId: element.subjectId3, boardId: element.boardId3, classId: element.classId3, groupId: element.groupId3 })
            }
            if (element.boardId4 != undefined && element.classId4 != undefined && element.groupId4 != undefined && element.subjectId4 != undefined) {
                assignedSubjects.push({ subjectId: element.subjectId4, boardId: element.boardId4, classId: element.classId4, groupId: element.groupId4 })
            }
            if (element.boardId5 != undefined && element.classId5 != undefined && element.groupId5 != undefined && element.subjectId5 != undefined) {
                assignedSubjects.push({ subjectId: element.subjectId5, boardId: element.boardId5, classId: element.classId5, groupId: element.groupId5 })
            }

            if (element.boardId6 != undefined && element.classId6 != undefined && element.groupId6 != undefined && element.subjectId6 != undefined) {
                assignedSubjects.push({ subjectId: element.subjectId6, boardId: element.boardId6, classId: element.classId6, groupId: element.groupId6 })
            }

            if (element.boardId7 != undefined && element.classId7 != undefined && element.groupId7 != undefined && element.subjectId7 != undefined) {
                assignedSubjects.push({ subjectId: element.subjectId7, boardId: element.boardId7, classId: element.classId7, groupId: element.groupId7 })
            }

            k.user_info.assignedSubjects = assignedSubjects

            let assignedSections = []


            if (element.sectionId1 != undefined) {
                assignedSections.push({ sectionId: element.sectionId1 })
            }
            if (element.sectionId2 != undefined) {
                assignedSections.push({ sectionId: element.sectionId2 })
            }
            if (element.sectionId3 != undefined) {
                assignedSections.push({ sectionId: element.sectionId3 })
            }
            if (element.sectionId4 != undefined) {
                assignedSections.push({ sectionId: element.sectionId4 })
            }
            if (element.sectionId5 != undefined) {
                assignedSections.push({ sectionId: element.sectionId5 })
            }
            if (element.sectionId6 != undefined) {
                assignedSections.push({ sectionId: element.sectionId6 })
            }
            if (element.sectionId7 != undefined) {
                assignedSections.push({ sectionId: element.sectionId7 })
            }

            k.user_info.assignedSections = assignedSections
            return k
        })


        const payload = {
            user_id: this.props.userId,
            users: paylodForApi
        };

        this.setState({
            users: [],
            fileSelected: null,
            fileSelected: ''
        }, () => {
            this.props.saveBulkUploadAdmins(payload);
        });
    };

    getTransactionDetails = (transactionId) => {

        this.setState({ transactionId });
        const payload = {
            userId: this.props.userId,
            transactionId
        };
        this.props.getBulkUploadTransactionDetailsAdmins(payload)
    };




    render() {
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >Transactions List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
                        value={this.state.globalSearch}

                        onChange={this.onGlobalSearch} />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* <li><i data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" /> */}
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
            </div>
        );

        const header2 = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >Sample Bulk Upload Sheet</h2>
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV2}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
            </div>
        );

        return (
            <div className='bulk-upload'>
                <Navbar >
                    <Authorizer permId={PERMISSIONS.USER_BULK_USERS_UPLOAD} >
                        <div className=''>

                            <div className="p-grid ma-toolbar">
                                <div className="p-col-12 p-md-1 ma-no-p">
                                </div>
                                <div className="p-col-12 p-md-11 ma-no-pm">
                                    <Toolbar className="ma-toolbard" left={() => {
                                        return (
                                            <React.Fragment>
                                                <BreadCrumb model={items} home={home} />
                                            </React.Fragment>
                                        )
                                    }}
                                        right={() => {
                                            return (
                                                <React.Fragment>
                                                    <h2 className='ma-tab-title'>Bulk User Upload</h2>
                                                </React.Fragment>
                                            )
                                        }}></Toolbar>
                                </div>
                            </div>
                            <div className="ask-a-doubt ma-main">
                                <p className='spread-title'>Admins Spreadsheet</p>
                                <InputText
                                    className='p-inputtext-style1'
                                    value={this.state.fileSelected} disabled />
                                {/* <span className='file-attach-name'>{this.state.fileSelected} </span> */}
                                <label htmlFor="file" className='select-file'>Select File</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="file"
                                    hidden={true}
                                    accept={SheetJSFT}
                                    onChange={this.handleChange}
                                    disabled={this.props.bulkUserUploadRequested}
                                />
                                <div className='ma-clearFix'></div>

                                <Button label="Upload data" className='ma-mt10'
                                    disabled={this.props.bulkUserUploadRequested}
                                    onClick={() => {
                                        if (this.state.users && this.state.users.length) {
                                            this.uploadBulkUsers();
                                        }
                                    }}
                                    icon="pi pi-cloud-upload"
                                />

                                <Button label="Sample Sheet"
                                    className='p-button-outlined ma-mt10 ma-ml10 ma-bg-white'
                                    disabled={this.props.bulkUserUploadRequested}
                                    onClick={() => {
                                        this.setState({
                                            isShowSampleFormat: true
                                        })
                                    }}
                                //  icon="pi pi-cloud-upload"
                                />
                            </div>

                            {(this.props.bulkUserUploadTransactions && this.props.bulkUserUploadTransactions.length) ? (
                                <Toolbar className="ma-toolbar"
                                    right={() => {
                                        return (
                                            <Button label="Refresh" className='ma-mlr30'
                                                icon="pi pi-refresh"
                                                onClick={() => {
                                                    this.getBulkUploadTransactionsData()
                                                }} />
                                        )
                                    }}></Toolbar>
                            ) : null}
                            <div className="p-grid ma-toolbar">
                                <div className="card datatable-crud-demo ma-m30">
                                    <DataTable ref={(el) => this.dt = el}
                                        //  lazy
                                        value={this.props.bulkUserUploadTransactions}
                                        // selection={this.state.selectedProducts}
                                        // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                                        dataKey="id"
                                        paginator
                                        lazy
                                        onPage={this.onPage}
                                        first={this.state.lazyParams.first}
                                        last={this.props.totalRecords}
                                        rows={this.state.lazyParams.rows}
                                        totalRecords={this.props.totalRecords}
                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                        // globalFilter={this.state.globalFilter}
                                        header={header}>
                                        <Column filterField='transaction_id' field="transaction_id" header="Transaction Id" />
                                        <Column filterField='bulkStatus' field="bulkStatus" header="Status" />
                                        <Column field="uploadedAt" header="Date" body={(rowData) => {
                                            return (<>
                                                <span>{moment(rowData.uploadedAt).format('LL')}</span>
                                            </>)
                                        }} />
                                        <Column header='Actions' body={(rowData) => {
                                            return (<>
                                                <Button label="Show Details" onClick={() => this.getTransactionDetails(rowData.transaction_id)} className='p-button-outlined' />
                                            </>
                                            );
                                        }} ></Column>
                                    </DataTable>
                                </div>
                            </div>
                            {this.props.bulkUserUploadTransactionDetails && this.props.bulkUserUploadTransactionDetails.length && (
                                <BulkUploadDetails transactionId={this.state.transactionId} data={this.props.bulkUserUploadTransactionDetails} />
                            )}
                        </div>

                        {this.props.loading || this.state.showLoader ? <LoadingComponent /> : null}
                        <Toast ref={(el) => this.toast = el} position="bottom-right" />

                        <Dialog
                            draggable={false}
                            closeOnEscape={true}
                            className='ma-alert-box'
                            blockScroll={true}
                            dismissableMask={false}
                            closable={true}

                            style={{ minHeight: '90vh' }}
                            visible={this.state.isShowSampleFormat}
                            onHide={() => this.setState({ isShowSampleFormat: false })}>
                            <div>
                                <div className="card datatable-crud-demo ma-m30">
                                    <DataTable ref={(el) => this.dt2 = el}
                                        value={SampleBulkUplodData}
                                        dataKey="id"
                                        header={header2}
                                        emptyMessage=""
                                        scrollable
                                        responsiveLayout="scroll"
                                        resizableColumns
                                        columnResizeMode='expand'
                                        onColumnResizerDoubleClick
                                    >
                                        <Column field="username" header="Username" />
                                        <Column field="password" header="Password" />
                                        <Column field="name" header="Name" />
                                        <Column field="email" header="Email" />
                                        <Column field="phone_number" header="Phone Number" />
                                        <Column field="roles" header="Roles" />
                                        <Column field="curriculum" header="Curriculum" />
                                        <Column field="status" header="Status" />
                                        <Column field="boardId1" header="BoardId1" />
                                        <Column field="classId1" header="ClassId1" />
                                        <Column field="groupId1" header="GroupId1" />
                                        <Column field="subjectId1" header="SubjectId1" />
                                        <Column field="boardId2" header="BoardId2" />
                                        <Column field="classId2" header="ClassId2" />
                                        <Column field="groupId2" header="GroupId2" />
                                        <Column field="subjectId2" header="SubjectId2" />
                                        <Column field="boardId3" header="BoardId3" />
                                        <Column field="classId3" header="ClassId3" />
                                        <Column field="groupId3" header="GroupId3" />
                                        <Column field="subjectId3" header="SubjectId3" />
                                        <Column field="boardId4" header="BoardId4" />
                                        <Column field="classId4" header="ClassId4" />
                                        <Column field="groupId4" header="GroupId4" />
                                        <Column field="subjectId4" header="SubjectId4" />
                                        <Column field="boardId5" header="BoardId5" />
                                        <Column field="classId5" header="ClassId5" />
                                        <Column field="groupId5" header="GroupId5" />
                                        <Column field="subjectId5" header="SubjectId5" />
                                        <Column field="boardId6" header="BoardId6" />
                                        <Column field="classId6" header="ClassId6" />
                                        <Column field="groupId6" header="GroupId6" />
                                        <Column field="subjectId6" header="SubjectId6" />
                                        <Column field="boardId7" header="BoardId7" />
                                        <Column field="classId7" header="ClassId7" />
                                        <Column field="groupId7" header="GroupId7" />
                                        <Column field="subjectId7" header="SubjectId7" />
                                        <Column field="sectionId1" header="SectionId1" />
                                        <Column field="sectionId2" header="SectionId2" />
                                        <Column field="sectionId3" header="SectionId3" />
                                        <Column field="sectionId4" header="SectionId4" />
                                        <Column field="sectionId5" header="SectionId5" />
                                        <Column field="sectionId6" header="SectionId6" />
                                        <Column field="sectionId7" header="SectionId7" />
                                        <Column field="userType" header="UserType" />
                                        <Column field="canChangeRole" header="CanChangeRole" />
                                    </DataTable>
                                </div>
                            </div>

                        </Dialog>
                    </Authorizer>
                </Navbar>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    loading: state.createNewAdminUserInfo && state.createNewAdminUserInfo.loading,

    error: state.createNewAdminUserInfo && state.createNewAdminUserInfo.error,

    bulkUserUploadTransactions: state.createNewAdminUserInfo && state.createNewAdminUserInfo.bulkUserUploadTransactions && state.createNewAdminUserInfo.bulkUserUploadTransactions && state.createNewAdminUserInfo.bulkUserUploadTransactions.bulkTransaction,

    totalRecords: state.createNewAdminUserInfo && state.createNewAdminUserInfo.bulkUserUploadTransactions && state.createNewAdminUserInfo.bulkUserUploadTransactions && state.createNewAdminUserInfo.bulkUserUploadTransactions.totalRecords,

    bulkUserUploadTransactionDetails: state.createNewAdminUserInfo && state.createNewAdminUserInfo.bulkUserUploadTransactionDetails && state.createNewAdminUserInfo.bulkUserUploadTransactionDetails.bulkTransaction && state.createNewAdminUserInfo.bulkUserUploadTransactionDetails.bulkTransaction[0] && state.createNewAdminUserInfo.bulkUserUploadTransactionDetails.bulkTransaction[0].output_json,

    bulkUserUploadSaveResponse: state.createNewAdminUserInfo && state.createNewAdminUserInfo.bulkUserUploadSaveResponse && state.createNewAdminUserInfo.bulkUserUploadSaveResponse,

    bulkUserUploadRequested: state.createNewAdminUserInfo && state.createNewAdminUserInfo.bulkUserUploadRequested && state.createNewAdminUserInfo.bulkUserUploadRequested,

    userId: localStorage.getItem('username'),
});

export default connect(mapStateToProps, {
    saveBulkUploadAdmins,
    getBulkUploadTransactionsAdmins,
    clearBulkUserUploadDataAdmins,
    getBulkUploadTransactionDetailsAdmins
})(Authentication(BulkUpload));
