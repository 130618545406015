import React, { Component } from 'react'
import { trimObj } from '../../../utile';
import { connect } from 'react-redux';
import Authentication from '../../session';
import { getFormFields, isFormValid, onTextChange } from '../../../utile/formHelper';
import Service from './../../../services';
import InputTextB from '../../customComponents/inputTextB';
import { InputText } from 'primereact/inputtext';
import { FIELDS_INFO } from '../../../constants';
import { Button } from 'primereact/button';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';
import SmsTemplates from './smsTemplates';

var formFields = require('./addSMSConfig.json');

let abc = {
    host: 'in-v3.mailjet.com',
    email: '2b4929f0e4f8551fa97d6b637e4f9a4c',
    password: '3640b52e1f08b58544f1c1946776df08',
    from: '"Shashidhar k" <shashidhar.k@meluhaedu.com>'
}

class AddSMSConfig extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, {});


        this.state = {
            smsConfig: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isEdit: false,
            isConfigured: true,
            isVerified: false,
            isNotAdded: false,
            isShowTemplates: false

        }
        this.service = new Service();
    }


    componentDidMount() {
        this.getConfig(this.props.orgId);

    }


    componentDidUpdate(prevProps) {
        if (prevProps.orgId != this.props.orgId)
            this.getConfig(this.props.orgId);

    }



    getConfig = (orgId) => {
        this.setState({
            isLoading: true
        })
        const url = `${baseUrlAdmin}/smsconfig/${orgId}`;
        this.service.get(url, true).then((res) => {
            if (res && res.res && res.res.status) {
                this.setState({
                    isLoading: false,
                    smsConfig: res.res.data
                })

            } else {

                this.setState({
                    isLoading: false,
                    isNotAdded: res.errMessage.includes('No Configurations') ? true : false

                })
               // this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });


            }


        }).catch(e => {
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            this.setState({
                isLoading: false
            });
        });


    }

    testConnection = () => {

        const { currentStep, smsConfig } = this.state;
        let submitData = trimObj(smsConfig)
        const formStatus = isFormValid(formFields, this.formFields.formValidations, submitData);
        
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                isSubmitClick: true,
                formValidations: formStatus.formValidations,
            });
        } else {
            this.setState({
                isLoading: true
            });

            const url = `${baseUrlAdmin}/smsconfig/test-conn`;
            this.service.post(url, submitData, true).then((res) => {
                
                if (res && res.res && res.res.data) {
                    this.setState({
                        isLoading: false,
                        isVerified: res.res.data.verified
                    })
                    if (res.res.data.verified) {
                        this.toast.show({ severity: 'success', summary: 'Verified', detail: 'Succussfully connect params.', life: 3000 });
                    } else {
                        this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Unable to connect to server', life: 3000 });
                    }

                } else {

                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }


            }).catch(e => {
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                this.setState({
                    isLoading: false
                });
            });
        }




    }

    onSubmitClick = () => {

        const { currentStep, smsConfig } = this.state;
        let submitData = trimObj(smsConfig)

        const formStatus = isFormValid(formFields, this.formFields.formValidations, submitData);
        
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                isSubmitClick: true,
                formValidations: formStatus.formValidations,
            });
        } else {
            this.setState({
                isLoading: true
            });

            submitData.orgId = this.props.orgId;
            const url = `${baseUrlAdmin}/smsconfig`;
            this.service[submitData._id ? 'put' : 'post'](url, submitData, true).then((res) => {
                
                if (res && res.res && res.res.status) {
                    this.setState({
                        isLoading: false,
                        smsConfig: res.res.data,
                        isEdit: false,
                        isNotAdded: false

                    })
                } else {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            }).catch(e => {
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                this.setState({
                    isLoading: false
                });
            });
        }
    }

    render() {
        const { smsConfig, formValidations, isEdit, isNotAdded } = this.state;
     //   console.log(this.props, 'dddd')
        return (
            <div className='ma-ml20'>
                <p className='config-txt'>Our SMS system is integrated with <a href='http://bulksmsapps.com/' target='_blank'>bulksmsapps.com</a></p>
                {isEdit ? <>
                    <div className='p-grid'>


                        <div className='p-col-12 p-md-4'>
                            <div>
                                <p className='ma-label-s2'>API Key<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.API_KEY} id="kexpT">
                                    <InputText value={smsConfig.apiKey}
                                        onChange={(e) => { onTextChange(e.target.value, 'apiKey', this, formFields, smsConfig, formValidations, 'smsConfig', 'formValidations') }}
                                        className='p-inputtext-style1 ma-w100p' />
                                </InputTextB>
                                {formValidations && !formValidations.fields['apiKey'].isValid && <p className="p-error">{formValidations.fields['apiKey'].errorMsg}</p>}
                            </div>

                            <div className='ma-mt10' >
                                <p className='ma-label-s2'>Header<span className='ma-required'>*</span></p>
                                <InputTextB info={FIELDS_INFO.DLT_HEADER} id="kexpT">
                                    <InputText value={smsConfig.header}
                                        onChange={(e) => { onTextChange(e.target.value, 'header', this, formFields, smsConfig, formValidations, 'smsConfig', 'formValidations') }}
                                        className='p-inputtext-style1 ma-w100p' />
                                </InputTextB>
                                {formValidations && !formValidations.fields['header'].isValid && <p className="p-error">{formValidations.fields['header'].errorMsg}</p>}
                            </div>
                            <Button label={'Save'} className='ma-mt10 ma-mlr10' onClick={this.onSubmitClick} />
                            <Button label={'Cancel'} className='ma-mt10 ma-mlr10 p-button-outlined' onClick={() => {
                                this.setState({
                                    isEdit: false
                                })
                            }} />
                        </div>
                    </div>
                </> : <>

                    {
                        isNotAdded ? <> <p className='config-txt'>No Configuration Added. Please add</p>
                            <Button label={'Add'} className='ma-mt10' onClick={() => {
                                this.setState({
                                    isEdit: true
                                })
                            }} />
                        </> : <div>
                            <div className='p-grid'>
                                <div className='p-col-6 p-md-3'>


                                    <p className='config-txt'><b>API Key : </b>{smsConfig.apiKey}
                                        <Button onClick={() => {
                                            this.setState({
                                                isEdit: true
                                            })
                                        }} icon="pi pi-pencil" className="ma-right p-button-rounded p-button-secondary p-button-text" aria-label="Edit" /></p>
                                    <p className='config-txt'><b>Header: </b>{smsConfig.header}</p>
                                </div>


                            </div>


                        </div>
                    }
                </>}
                <div>

                    <SmsTemplates orgId={this.props.orgId} />

                </div>
                {
                    this.state.isLoading && <LoadingComponent />
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div>

        )
    }
}



const mapStateToProps = (state) => ({
    smsConfig: state.orgData.smsConfig,
});


export default connect(mapStateToProps, {
})(Authentication(AddSMSConfig));
