import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { RadioButton } from 'primereact/radiobutton';
import React, { Component } from 'react'
import vehicleManagementFields from './vehicleManagement.json'
import { getFormFields, isFormValid, onChoiceChange, onNumberChange, onTextChange } from '../../../utile/formHelper';
import { cloneDeep } from 'lodash';
import { trimObj } from '../../../utile';
import { Toast } from 'primereact/toast';
import { baseUrlAdmin } from '../../../store/apiConstants';
import Service from '../../../services';
import LoadingComponent from '../../loadingComponent';


export default class AddVehicle extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(vehicleManagementFields, {})
        this.state = {
            vehicleField: this.props.editVehicleDetials ? this.props.editVehicleDetials : this.formFields.data,
            formValidations: this.formFields.formValidations,
            isLoading: false,
        }

        this.service = new Service()
    }

    // componentDidMount() {
    //     console.log('first', this.props.editVehicleDetials)
    // }

    onSubmitClick = () => {
        const { vehicleField } = this.state

        let body = cloneDeep(this.state?.vehicleField)
        const formStatus = isFormValid(vehicleManagementFields, this.formFields.formValidations, trimObj(body));
        let isCompleteFormValid = true;

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations
            })
            isCompleteFormValid = false
        }

        if (new Date(vehicleField.certificationOfRegistrationStartDate).getTime() > new Date(vehicleField.certificationOfRegistrationEndDate).getTime()) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Registration End date must be greater than registration start date.', life: 3000 });
            isCompleteFormValid = false
        }

        if (new Date(vehicleField.insuranceStartDate).getTime() > new Date(vehicleField.insuranceEndDate).getTime()) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Insurance End date must be greater than insurance start date.', life: 3000 });
            isCompleteFormValid = false
        }

        if (new Date(vehicleField.pollutionControlStartDate).getTime() > new Date(vehicleField.pollutionControlEndDate).getTime()) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Pollution control End date must be greater than Pollution control start date.', life: 3000 });
            isCompleteFormValid = false
        }

        if (new Date(vehicleField.permitStartDate).getTime() > new Date(vehicleField.permitEndDate).getTime()) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Permit End date must be greater than permit start date.', life: 3000 });
            isCompleteFormValid = false
        }

        if (isCompleteFormValid) {
            let body = vehicleField

            if (this.props.editVehicleDetials) {

                this.setState({
                    isLoading: true
                })

                let url = `${baseUrlAdmin}/transport-vehicle`

                this.service.put(url, body, true).then(res => {
                    if (res?.status && res?.res?.status) {
                        this.setState({
                            isLoading: false,
                        }, this.props.onSaveOrUpdate(true))
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(err => {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

                })

            } else {

                this.setState({
                    isLoading: true
                })

                let url = `${baseUrlAdmin}/transport-vehicle`

                this.service.post(url, body, true).then(res => {
                    if (res?.status && res?.res?.status) {
                        this.setState({
                            isLoading: false,
                            users: res?.res?.data
                        }, () => this.props.onSaveOrUpdate())
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(err => {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

                })

            }

        }
    }

    footer = () => {
        return (<div>
            <Button label={`${this.props.editVehicleDetials ? 'Update' : 'Save'}`} className='ma-m-lr10' onClick={this.onSubmitClick} />
            <Button label="Cancel" className='p-button-outlined' onClick={this.props.onClose} />
        </div>)
    }

    render() {
        const { formValidations, vehicleField } = this.state

        return (
            <>
                <Dialog
                    visible={this.props.visible}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={false}
                    style={{ maxWidth: '40%', minWidth: '40%' }}
                    footer={this.footer}
                    header={`${this.props.editVehicleDetials ? 'Update' : 'Add'} Vehicle`}
                >
                    <div className="p-grid subject-add-dialog">
                        <div className="p-col-4 p-md-4 p-lg-4">
                            <p className='text-label p-mt-3'>Vehicle Registration No<span className='ma-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputText className='p-inputtext-style1 ma-w100p'
                                value={vehicleField.vehicleRegistrationNo}
                                onChange={(e) => onTextChange(e.target.value.toUpperCase(), 'vehicleRegistrationNo', this, vehicleManagementFields, vehicleField, formValidations, 'vehicleField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['vehicleRegistrationNo'].isValid && <p className="p-error">{formValidations.fields['vehicleRegistrationNo'].errorMsg}</p>}
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-4">
                            <p className='text-label p-mt-3'>Make of the Vehicle<span className='ma-required'>*</span></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputText className='p-inputtext-style1 ma-w100p'
                                value={vehicleField.makeOfTheVehicle}
                                onChange={(e) => onTextChange(e.target.value, 'makeOfTheVehicle', this, vehicleManagementFields, vehicleField, formValidations, 'vehicleField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['makeOfTheVehicle'].isValid && <p className="p-error">{formValidations.fields['makeOfTheVehicle'].errorMsg}</p>}
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-4">
                            <p className='text-label p-mt-3'>Model of the Vehicle<span className='ma-required'>*</span></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputText className='p-inputtext-style1 ma-w100p'
                                value={vehicleField.modelOfTheVehicle}
                                onChange={(e) => onTextChange(e.target.value, 'modelOfTheVehicle', this, vehicleManagementFields, vehicleField, formValidations, 'vehicleField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['modelOfTheVehicle'].isValid && <p className="p-error">{formValidations.fields['modelOfTheVehicle'].errorMsg}</p>}

                        </div>

                        <div className="p-col-4 p-md-4 p-lg-4">
                            <p className='text-label p-mt-3'>Fuel Type<span className='ma-required'>*</span></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2 p-mt-2">
                            <RadioButton
                                inputId="Petrol" name="fuelType" value="petrol" className=''
                                onChange={(e) => onChoiceChange(e.value, 'fuelType', this, vehicleManagementFields, vehicleField, formValidations, 'vehicleField', 'formValidations')}
                                checked={vehicleField.fuelType === 'petrol'} />
                            <label htmlFor="Petrol" className="p-ml-2">Petrol</label>

                            <RadioButton
                                inputId="Diesel" name="fuelType" value="diesel" className='p-ml-3'
                                onChange={(e) => onChoiceChange(e.value, 'fuelType', this, vehicleManagementFields, vehicleField, formValidations, 'vehicleField', 'formValidations')}
                                checked={vehicleField.fuelType === 'diesel'} />
                            <label htmlFor="Diesel" className="p-ml-1">Diesel</label>

                            <RadioButton
                                inputId="CNG" name="fuelType" value="cng" className='p-ml-3'
                                onChange={(e) => onChoiceChange(e.value, 'fuelType', this, vehicleManagementFields, vehicleField, formValidations, 'vehicleField', 'formValidations')}
                                checked={vehicleField.fuelType === 'cng'} />
                            <label htmlFor="CNG" className="p-ml-2">CNG</label>

                            <RadioButton
                                inputId="EV" name="fuelType" value="ev" className='p-ml-3'
                                onChange={(e) => onChoiceChange(e.value, 'fuelType', this, vehicleManagementFields, vehicleField, formValidations, 'vehicleField', 'formValidations')}
                                checked={vehicleField.fuelType === 'ev'} />
                            <label htmlFor="EV" className="p-ml-2">EV</label>

                            {formValidations && !formValidations.fields['fuelType'].isValid && <p className="p-error">{formValidations.fields['fuelType'].errorMsg}</p>}

                        </div>

                        <div className="p-col-4 p-md-4 p-lg-4">
                            <p className='text-label p-mt-3'>Chassis No<span className='ma-required'>*</span></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputText className='p-inputtext-style1 ma-w100p'
                                value={vehicleField.chassisNo}
                                onChange={(e) => onTextChange(e.target.value, 'chassisNo', this, vehicleManagementFields, vehicleField, formValidations, 'vehicleField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['chassisNo'].isValid && <p className="p-error">{formValidations.fields['chassisNo'].errorMsg}</p>}

                        </div>

                        <div className="p-col-4 p-md-4 p-lg-4">
                            <p className='text-label p-mt-3'>Engine No<span className='ma-required'>*</span></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputText className='p-inputtext-style1 ma-w100p'
                                value={vehicleField.engineNo}
                                onChange={(e) => onTextChange(e.target.value, 'engineNo', this, vehicleManagementFields, vehicleField, formValidations, 'vehicleField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['engineNo'].isValid && <p className="p-error">{formValidations.fields['engineNo'].errorMsg}</p>}

                        </div>

                        <div className="p-col-4 p-md-4 p-lg-4">
                            <p className='text-label p-mt-3'>Seating Capacity<span className='ma-required'>*</span></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                            <InputText className='p-inputtext-style1 ma-w100p'
                                value={vehicleField.seatingCapacity}
                                onChange={(e) => onNumberChange(e.target.value, 'seatingCapacity', this, vehicleManagementFields, vehicleField, formValidations, 'vehicleField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['seatingCapacity'].isValid && <p className="p-error">{formValidations.fields['seatingCapacity'].errorMsg}</p>}

                        </div>

                        <div className="p-col-4 p-md-4 p-lg-4 ">
                            <p className='text-label p-mt-3'>Certification of Registration<span className='ma-required'>*</span></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2 p-mt-2">
                            <div className='p-grid'>
                                <div className='p-col-6 p-lg-6 p-md-8'>
                                    <Calendar showIcon className='' placeholder='Start Date'
                                        value={vehicleField.certificationOfRegistrationStartDate ? new Date(vehicleField.certificationOfRegistrationStartDate) : null}
                                        onChange={(e) => onTextChange(new Date(e.value).toString(), 'certificationOfRegistrationStartDate', this, vehicleManagementFields, vehicleField, formValidations, 'vehicleField', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['certificationOfRegistrationStartDate'].isValid && <p className="p-error">{formValidations.fields['certificationOfRegistrationStartDate'].errorMsg}</p>}

                                </div>
                                <div className='p-col-6 p-lg-6 p-md-8'>
                                    <Calendar showIcon className='' placeholder='End Date' minDate={new Date(vehicleField.certificationOfRegistrationStartDate)}
                                        value={vehicleField.certificationOfRegistrationEndDate ? new Date(vehicleField.certificationOfRegistrationEndDate) : null}
                                        onChange={(e) => onTextChange(new Date(e.value).toString(), 'certificationOfRegistrationEndDate', this, vehicleManagementFields, vehicleField, formValidations, 'vehicleField', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['certificationOfRegistrationEndDate'].isValid && <p className="p-error">{formValidations.fields['certificationOfRegistrationEndDate'].errorMsg}</p>}

                                </div>
                            </div>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-4 ">
                            <p className='text-label p-mt-3'>Insurance<span className='ma-required'>*</span></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2 p-mt-2">
                            <div className='p-grid'>
                                <div className='p-col-6 p-lg-6 p-md-8'>
                                    <Calendar showIcon className='' placeholder='Start Date'
                                        value={vehicleField.insuranceStartDate ? new Date(vehicleField.insuranceStartDate) : null}
                                        onChange={(e) => onTextChange(new Date(e.value).toString(), 'insuranceStartDate', this, vehicleManagementFields, vehicleField, formValidations, 'vehicleField', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['insuranceStartDate'].isValid && <p className="p-error">{formValidations.fields['insuranceStartDate'].errorMsg}</p>}

                                </div>
                                <div className='p-col-6 p-lg-6 p-md-8'>
                                    <Calendar showIcon className='' placeholder='End Date'
                                        minDate={new Date(vehicleField.insuranceStartDate)}
                                        value={vehicleField.insuranceEndDate ? new Date(vehicleField.insuranceEndDate) : null}
                                        onChange={(e) => onTextChange(new Date(e.value).toString(), 'insuranceEndDate', this, vehicleManagementFields, vehicleField, formValidations, 'vehicleField', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['insuranceEndDate'].isValid && <p className="p-error">{formValidations.fields['insuranceEndDate'].errorMsg}</p>}

                                </div>
                            </div>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-4 ">
                            <p className='text-label p-mt-3'>Pollution Control<span className='ma-required'>*</span></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2 p-mt-2">
                            <div className='p-grid'>
                                <div className='p-col-6 p-lg-6 p-md-8'>
                                    <Calendar showIcon className='' placeholder='Start Date'
                                        value={vehicleField.pollutionControlStartDate ? new Date(vehicleField.pollutionControlStartDate) : null}
                                        onChange={(e) => onTextChange(new Date(e.value).toString(), 'pollutionControlStartDate', this, vehicleManagementFields, vehicleField, formValidations, 'vehicleField', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['pollutionControlStartDate'].isValid && <p className="p-error">{formValidations.fields['pollutionControlStartDate'].errorMsg}</p>}

                                </div>
                                <div className='p-col-6 p-lg-6 p-md-8'>
                                    <Calendar showIcon className='' placeholder='End Date'
                                        minDate={new Date(vehicleField.pollutionControlStartDate)}
                                        value={vehicleField.pollutionControlEndDate ? new Date(vehicleField.pollutionControlEndDate) : null}
                                        onChange={(e) => onTextChange(new Date(e.value).toString(), 'pollutionControlEndDate', this, vehicleManagementFields, vehicleField, formValidations, 'vehicleField', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['pollutionControlEndDate'].isValid && <p className="p-error">{formValidations.fields['pollutionControlEndDate'].errorMsg}</p>}

                                </div>
                            </div>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-4 ">
                            <p className='text-label p-mt-3'>Permit<span className='ma-required'>*</span></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2 p-mt-2">
                            <div className='p-grid'>
                                <div className='p-col-6 p-lg-6 p-md-8'>
                                    <Calendar showIcon className='' placeholder='Start Date'
                                        value={vehicleField.permitStartDate ? new Date(vehicleField.permitStartDate) : null}
                                        onChange={(e) => onTextChange(new Date(e.value).toString(), 'permitStartDate', this, vehicleManagementFields, vehicleField, formValidations, 'vehicleField', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['permitStartDate'].isValid && <p className="p-error">{formValidations.fields['permitStartDate'].errorMsg}</p>}

                                </div>
                                <div className='p-col-6 p-lg-6 p-md-8'>
                                    <Calendar showIcon className='' placeholder='End Date'
                                        minDate={new Date(vehicleField.permitStartDate)}
                                        value={vehicleField.permitEndDate ? new Date(vehicleField.permitEndDate) : null}
                                        onChange={(e) => onTextChange(new Date(e.value).toString(), 'permitEndDate', this, vehicleManagementFields, vehicleField, formValidations, 'vehicleField', 'formValidations')}
                                    />
                                    {formValidations && !formValidations.fields['permitEndDate'].isValid && <p className="p-error">{formValidations.fields['permitEndDate'].errorMsg}</p>}

                                </div>
                            </div>
                        </div>

                    </div>
                </Dialog>
                {
                    this.state.isLoading && <LoadingComponent />
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />

            </>
        )
    }
}
