import React, { Component } from 'react'
import moment from 'moment';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default class PreviewPlanner extends Component {
  constructor(props) {
    {
      super(props);
      this.state = {
        selectedSubjectIndex: 0
      }
    }

  }


  htmlRender = (html) => {
    return <div dangerouslySetInnerHTML={{ __html: html }}></div>
  }
  callMathJax = () => {
    if (window && window.MathJax && window.MathJax.Hub) {
      window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
    }
  }
  componentDidUpdate(prevProps) {
    this.callMathJax();
  }

  render() {
    const { calendar } = this.props;
    return (<>
      <h3 className='create-class-t'>Preview Planner</h3>
      <div className="p-grid planner-preview">
        <div className="p-col-12 p-md-12 p-lg-6">
          <div className="p-grid">
            <div className="p-col-4 p-md-4 p-lg-4 ">
              <label className='preview-title p-d-block'>Title<span className='ma-required'>*</span></label>
              <p className='preview-value'>{calendar.title}</p>
            </div>
            <div className="p-col-4 p-md-4 p-lg-4 ">
              <label className='preview-title p-d-block'>Start Date<span className='ma-required'>*</span></label>
              <p className='preview-value'>{moment(calendar.startDate).format('DD-MM-YYYY')}</p>
            </div>
            <div className="p-col-4 p-md-4 p-lg-4 ">
              <label className='preview-title p-d-block'>End Date<span className='ma-required'>*</span></label>
              <p className='preview-value'>  {moment(calendar.endDate).format('DD-MM-YYYY')}</p>
            </div>
          </div>
          <div className="p-grid">
            <div className="p-col-12 ma-mt10">
              <label className='preview-title p-d-block'>Descripiton<span className='ma-required'>*</span></label>
              <p className='preview-value'> {calendar.description}</p>
            </div>
          </div>
          <div className="p-grid dropdown-grid ma-mt10">
            <div className='p-d-flex p-align-items-center p-col-4 p-md-4 p-lg-4'>
              <p className='preview-value'>  {calendar.boardName}</p>
            </div>
            <div className='p-d-flex p-align-items-center p-col-4 p-md-4 p-lg-4'>
              <p className='preview-value'>{calendar.className}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="ask-a-doubt-section">

        {(calendar.subjects && calendar.subjects.length > 0 && this.state.selectedSubjectIndex != null) && (<>
          <div className='doubt-tab-block'>
            {calendar.subjects.map((item, index) => {
              return (<>
                <Button label={item.subjectName} className={`${this.state.selectedSubjectIndex === index ? "" : "ma-disabled "} ma-mr10`} onClick={() => this.setState({ selectedSubjectIndex: index })} />
              </>)
            })}
          </div>
          <div>
            <div className="card datatable-crud-demo ma-mt20 planner-table-wrap">
              <DataTable ref={(el) => this.dt = el}
                let-i="rowIndex"
                value={calendar.subjects[this.state.selectedSubjectIndex].chapters}
                dataKey="id"
                // onRowClick={this.handleSelectedRow}
                rows={7}
                responsiveLayout="scroll"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
              >
                {/* <Column  sortable body={(data, props) => {
                  return props.rowIndex + 1;
              }} field="Index" header="No" /> */}
                <Column filterMatchMode='contains' field="chapterName" header={<>Chapter <span className='ma-star-mark2 '> *</span></>} />
                <Column filterMatchMode='contains' field="startEndDate" header={<>Start Date and End Date <span className='ma-star-mark2 '> *</span></>} body={(rowData) => {
                  return (<>{rowData && rowData.startEndDate && rowData.startEndDate[0] ? moment(rowData.startEndDate[0]).format('DD-MM-YYYY') : '-'} to {rowData && rowData.startEndDate && rowData.startEndDate[1] ? moment(rowData.startEndDate[1]).format('DD-MM-YYYY') : '-'}</>)
                }} />



                <Column filterMatchMode='contains' field="preKnowledge" header={<>Pre-knowledge <span className='ma-star-mark2 '> *</span></>} body={(rowData) => this.htmlRender(rowData.preKnowledge)} />
                <Column filterMatchMode='contains' field="learningObjective" header={<>Learning Objective <span className='ma-star-mark2 '> *</span></>} body={(rowData) => this.htmlRender(rowData.learningObjective)} />
                <Column filterMatchMode='contains' field="learningOutcome" header={<>Learning Outcome <span className='ma-star-mark2 '> *</span></>} body={(rowData) => this.htmlRender(rowData.learningOutcome)} />
                <Column header="" body={() => {
                  return (
                    <></>
                  )
                }} />
              </DataTable>





            </div >
            <p className='ma-required'>{calendar.subjects[this.state.selectedSubjectIndex].chaptersErr}</p>

          </div>
        </>)}



      </div >
    </>)
  }
}
