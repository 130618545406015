import * as ActionTypes from './../actionTypes';

const checkSelectedQuestionExistance = (state, action) => {
    const selectedQuestionIds = action.res.questionIds.split(',');
    let repeatedQuestionIds = false;
    let differentSubjectQuestion = false;
    state.questionsInfo && state.questionsInfo.questions && state.questionsInfo.questions.forEach(question => {
        selectedQuestionIds.includes(question.questionId) && (repeatedQuestionIds=question.questionId);
    });
    action.res.selectedSubjectId && action.res.data.questions.length > 0 && action.res.data.questions.forEach(question => {
        (question.subjectId !== action.res.selectedSubjectId) && (differentSubjectQuestion=question.questionId)
    })
    if(repeatedQuestionIds || differentSubjectQuestion || action.res.data.questions.length===0) {
        const repeatedQuestionError = `Selected questionId(s) - ${repeatedQuestionIds} already exists in one of the selected subjects tab`;
        const noQuestionsError = `No question(s) found for this questionId(s)- ${action.res.questionIds}`;
        const differentSubjectQuestionError = `Selected questionId(s) - ${action.res.questionIds} not belongs to the current selected subject`;
        return {
            ...state,
            loading:false,
            questionsByIds:[],
            addQuestionActionIndex: !state.addQuestionActionIndex ? 1 : ++state.addQuestionActionIndex,
            error:false,
            errorInfo:repeatedQuestionIds ? repeatedQuestionError : (differentSubjectQuestion ? differentSubjectQuestionError : noQuestionsError),
            warningInfo: null,
            errorForCurriculum: null,
            ownUpdate: true
        }
    } else {
        let fetchQuestions = [];
        let notFoundQuestions = [];
        selectedQuestionIds.forEach((questionId) => {
            let question = action.res.data.questions.find((ques) => ques.questionId === questionId);
            question ? fetchQuestions.push(question) : notFoundQuestions.push(questionId)
        });
        return {
            ...state,
            loading:false,
            questionsByIds:action.res.data.questions,
            questionsInfo: state.questionsInfo && state.questionsInfo.questions ? {...state.questionsInfo, questions: state.questionsInfo.questions.concat(fetchQuestions)} : {questions:fetchQuestions},
            addQuestionActionIndex: !state.addQuestionActionIndex ? 1 : ++state.addQuestionActionIndex,
            error:false,
            errorInfo:null,
            warningInfo: notFoundQuestions.length > 0 ? `Question(s) ${notFoundQuestions.toString(',')} not found, maybe the id(s) provided are invalid or the respective questions deleted` : null,
            errorForCurriculum: null,
            ownUpdate: true
        }
    }
};

const removeSelectedQuestionExistance = (state, action) => {
    const questions = state.questionsInfo && state.questionsInfo.questions && state.questionsInfo.questions.filter(question =>  question.questionId !== action.res.deleteQuestionId);

    return {
        ...state,
        questionsInfo: {...state.questionsInfo, questions},
        loading:false,
        error:false,
        errorInfo:null,
        errorForCurriculum: null,
        ownUpdate: true
    }
};

const initialState = {
    questionsInfo: {questions:[]}
}

export default (state=initialState,action) =>{
    switch(action.type){
        case ActionTypes.REQUEST_CURRICULUM:                
            return {
                ...state,
                loading:true,
                error:false,
                errorInfo:null,
                errorForCurriculum: null
            }        
        case ActionTypes.RECEIVED_CURRICULUM:
            return {
                ...state,
                loading:false,
                response:action.res,
                error:false,
                errorInfo:null,
                errorForCurriculum: null
            }
        case ActionTypes.ERROR_CURRICULUM:
            return{
                ...state,
                loading:false,
                error:true,
                errorInfo:action.err,
                errorForCurriculum: null
            }
        case ActionTypes.REQUEST_CURRICULUM_BRANCH:                
            return {
                ...state,
                loading:true,
                branch:null,
                error:false,
                errorInfo:null,
                errorForCurriculum: null
            }        
        case ActionTypes.RECEIVED_CURRICULUM_BRANCH:
            return {
                ...state,
                loading:false,
                branch:action.res,
                error:false,
                errorInfo:null,
                errorForCurriculum: null
            }
        case ActionTypes.ERROR_CURRICULUM_BRANCH:
            return{
                ...state,
                loading:false,
                error:true,
                branch:null,
                errorInfo:action.err,
                errorForCurriculum: null
            }
        case ActionTypes.SELECTED_SUBJECT_DATA: 
            return {
                ...state,
                subjectInfo: action.data,
                errorForCurriculum: null
            }
        case ActionTypes.SELECTED_SUBJECT_QUESTIONS_DATA:
            return {
                ...state,
                loading:false,
                questionsInfo:action.data,
                errorForCurriculum: null
            }
        case ActionTypes.REQUEST_SELECTED_SUBJECT_QUESTIONS_DATA:                
            return {
                ...state,
                loading:true,
                error:false,
                errorInfo:null,
                errorForCurriculum: null
            }
        case ActionTypes.THROW_ERROR_SELECTED_SUBJECT_QUESTIONS_DATA:
                return{
                    ...state,
                    loading:false,
                    error:true,
                    errorInfo:action.err,
                    errorForCurriculum: null
                }
        case ActionTypes.REQUEST_CREATE_ADHOC_MOCK_TEST:                
            return {
                ...state,
                loading:true,
                error:false,
                errorInfo:null,
                errorForCurriculum: null
            }        
        case ActionTypes.RECIVED_CREATE_ADHOC_MOCK_TEST:
            return {
                ...state,
                loading:false,
                newAdhocTest:action.res,
                error:false,
                errorInfo:null,
                errorForCurriculum: null
            }
        case ActionTypes.THROW_ERROR_CREATE_ADHOC_MOCK_TEST:
            return{
                ...state,
                loading:false,
                error:true,
                errorInfo:action.err,
                errorForCurriculum: null
            }
        case ActionTypes.REQUEST_QUESTIONS_BY_IDS:                
            return {
                ...state,
                loading:true,
                error:false,
                errorInfo:null,
                errorForCurriculum: null
            }        
        case ActionTypes.RECIVED_QUESTIONS_BY_IDS:
            return checkSelectedQuestionExistance(state,action);
        case ActionTypes.REMOVE_QUESTIONS_BY_ID:
            return removeSelectedQuestionExistance(state,action);
            
        case ActionTypes.THROW_ERROR_QUESTIONS_BY_IDS:
            return{
                ...state,
                loading:false,
                error:true,
                errorInfo:action.err,
                errorForCurriculum: null
            }
        case ActionTypes.SELECTED_TOPIC_DATA:
            return {
                ...state,
                topicsInfo:action.data,
                errorForCurriculum: null
            }
        case ActionTypes.CLEAR_CURRICULUM_DATA:
            return {
            }    

        case ActionTypes.THROW_ERROR_FOR_CURRICULUM:

            return {
                ...state,
                errorForCurriculum: action.error
            }    
        
        case ActionTypes.CLEAR_CURRICULUM_DATA:
            return {}
        default: return state;

    }
} 