export default Object.freeze({
    ATTEMPT_DOUBT: {
        permissionName: 'Attempt Doubt',
        groupName: 'Ask A Doubt',
        category: 'General'
    },
    UPLOAD_ATTACHMENT: {
        permissionName: 'Upload Attachment',
        groupName: 'Ask A Doubt',
        category: 'General'
    },
    SEARCH: {
        permissionName: 'Search',
        groupName: 'Ask A Doubt',
        category: 'General'
    },
    SORTANDFILTER: {
        permissionName: 'Sort & Filter',
        groupName: 'Ask A Doubt',
        category: 'General'
    },
    REFRESH: {
        permissionName: 'Refresh',
        groupName: 'Ask A Doubt',
        category: 'General'
    }
});