import * as ActionTypes from './../actionTypes';

const initialState = {
  isLoading: false,
  data: [
    // {
    //   permissions: {
    //     'fa8b15a0-2b1a-458b-ad5c-5bb2841590ac': {
    //       groupName: 'Admin Management',
    //       permissions: [
    //         {
    //           name: 'Create Role',
    //           status: 'Active',
    //           id: 'da1422ae-e933-4807-af8d-cb0f2e08626d'
    //         },
    //         {
    //           name: 'Edit Role',
    //           status: 'Active',
    //           id: '7da7ecca-66a4-4fe1-9c36-813fc594d0b9'
    //         },
    //         {
    //           name: 'Create User',
    //           status: 'Active',
    //           id: 'ac6bcb8a-6409-412b-a64f-dd61ac1d3269'
    //         },
    //         {
    //           name: 'Edit User',
    //           status: 'Active',
    //           id: '65e6cd74-476f-46b1-88bd-bf8baf0bb3ca'
    //         }
    //       ]
    //     }
    //   },
    //   sk: 'Role#205dfb85-9bcc-4e8d-9864-511e84a52a1c',
    //   roleName: 'AdminManager2',
    //   description: 'Manager of admin route2',
    //   pk: 'Role',
    //   id: '205dfb85-9bcc-4e8d-9864-511e84a52a1c',
    //   status: 'Active'
    // },
    // {
    //   permissions: {
    //     'fa8b15a0-2b1a-458b-ad5c-5bb2841590ac': {
    //       groupName: 'Admin Management',
    //       permissions: [
    //         {
    //           name: 'Create Role',
    //           status: 'Active',
    //           id: 'da1422ae-e933-4807-af8d-cb0f2e08626d'
    //         },
    //         {
    //           name: 'Edit Role',
    //           status: 'Active',
    //           id: '7da7ecca-66a4-4fe1-9c36-813fc594d0b9'
    //         },
    //         {
    //           name: 'Create User',
    //           status: 'Active',
    //           id: 'ac6bcb8a-6409-412b-a64f-dd61ac1d3269'
    //         },
    //         {
    //           name: 'Edit User',
    //           status: 'Active',
    //           id: '65e6cd74-476f-46b1-88bd-bf8baf0bb3ca'
    //         }
    //       ]
    //     }
    //   },
    //   sk: 'Role#d771ec35-a06a-4050-92ce-8b74d0114ef7',
    //   roleName: 'AdminManager',
    //   description: 'Manager of admin route',
    //   pk: 'Role',
    //   id: 'd771ec35-a06a-4050-92ce-8b74d0114ef7',
    //   status: 'Active'
    // }
  ],
  permissions: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CURRENT_USER_PERMS_REQUESTED:
      return {
        ...state,
        isLoading: true
      };

    case ActionTypes.GET_CURRENT_USER_PERMS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        discussionUser: action.discussionUser,
        userInfo: action.userInfo,
        permissionIds: action.permissionIds
      };

    case ActionTypes.GET_CURRENT_USER_PERMS_FAILED:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};
