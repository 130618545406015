import * as ActionTypes from '../actionTypes';
import axios from 'axios';
import {
    baseUrlAdmin
} from '../apiConstants';


const dispatchRequestOrganizationData = data => ({
    type: ActionTypes.REQUEST_ORGANIZATION,
    data
});

const dispatchReceivedOrganizationData = (res, etag) => ({
    type: ActionTypes.RECEIVED_ORGANIZATION,
    res,
    etag
});

const dispatchErrorOrganizationData = err => ({
    type: ActionTypes.ERROR_ORGANIZATION,
    err
});

const dispatchClearOrganizationData = () => ({
    type: ActionTypes.CLEAR_ORGANIZATION_DATA
});

export const clearOrganizationData = () => dispatchClearOrganizationData();

const dispatchGetClassFromBoard = board => ({
    type: ActionTypes.SELECTED_CLASS_FROM_BOARD,
    selectedBoard: board
});

const dispatchGetGroupFromClass = classInfo => ({
    type: ActionTypes.SELECT_GROUP_FROM_CLASS,
    selectedClass: classInfo
});

export const getClassesFromBoard = boardId => (dispatch, getState) => {
    const organizationData = getState().organizationData;
    const boards = organizationData && organizationData.response && organizationData.response.Item && organizationData.response.Item.boards;

    if (boards && boards.length) {
        const board = boards.filter(board => board.boardId === boardId);
        dispatch(dispatchGetClassFromBoard(board));
    }
}

export const getGroupsFromClasses = classId => (dispatch, getState) => {
    const organizationData = getState().organizationData;
    const classes = organizationData && organizationData.selectedBoard && organizationData.selectedBoard.length && organizationData.selectedBoard[0] && organizationData.selectedBoard[0].classes || [];
    if (classes && classes.length) {
        const selectedClass = classes.filter(_class => _class.classId === classId);
        dispatch(dispatchGetGroupFromClass(selectedClass));
    }
}

export const getOrganizationData = (withoutMeluhaBoard) => dispatch => {
    let _data;
    dispatch(dispatchRequestOrganizationData(withoutMeluhaBoard));
    axios({
        method: 'get',
        url: `${baseUrlAdmin}/organization`
    }).then((res) => {
        const etag = res.headers && res.headers.etag;
        _data = res.data
        localStorage.setItem('orgEtag', etag);
        if (withoutMeluhaBoard) {
            _data = res.data.Item.boards.filter(b => b.boardId !== ActionTypes.MELUHA_BOARD_ID);
            if (res.data && res.data.Item && res.data.Item.boards && res.data.Item.boards.length) res.data.Item.boards = _data;
        }
        dispatch(dispatchReceivedOrganizationData(res.data, etag));
    }).catch(e => {
        if (e.response && e.response.status == 401) {
            localStorage.clear();
            window.location.reload();
        } else {
            dispatch(dispatchErrorOrganizationData(e));
        }
    });
}

export const getActiveOrganizationData = (withoutMeluhaBoard) => dispatch => {
    let _data;
    dispatch(dispatchRequestOrganizationData(withoutMeluhaBoard));
    axios({
        method: 'get',
        url: `${baseUrlAdmin}/organization/active`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        }
    }).then((res) => {
        const etag = res.headers && res.headers.etag;
        _data = res.data
        localStorage.setItem('orgEtag', etag);
        if (withoutMeluhaBoard) {
            _data = res.data.Item.boards.filter(b => b.boardId !== ActionTypes.MELUHA_BOARD_ID);
            if (res.data && res.data.Item && res.data.Item.boards && res.data.Item.boards.length) res.data.Item.boards = _data;
        }
        dispatch(dispatchReceivedOrganizationData(res.data, etag));
    }).catch(e => {
        if (e.response && e.response.status == 401) {
            localStorage.clear();
            window.location.reload();
        } else {
            dispatch(dispatchErrorOrganizationData(e));
        }
    });
}