import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react'
import { Column } from 'react-formio/lib/types';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import Navbar from '../../navbar';


import {
    getBoardsData, getBranchesLatest
} from '../../../store/actions';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { MultiSelect } from 'primereact/multiselect';
import { baseUrlAdmin } from '../../../store/apiConstants';
import Service from '../../../services';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';


const items = [
    { label: 'Academics' },
    { label: 'Transport Attendance', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

class TransportAttendance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { columnName: 'Chassis No', isVisible: false, id: 'chassisNo' },
                { columnName: 'Engine No', isVisible: false, id: 'engineNo' }
            ],

            selectedCustomers: null,


            sectionsData: '',
            sectionIds: [],

            date: new Date(),
            transportRoutes: [],

            attendance: []
        }
        this.service = new Service();
    }

    getTransportRoutes = () => {

        const url = `${baseUrlAdmin}/transport-route/get-routes`;
        this.service.get(url, true).then(res => {
            
            if (res && res.res && res.res) {

                this.setState({
                    transportRoutes: res.res?.data?.transportRoutes,
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
            console.log(res);


        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });

            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }

    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        } else {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });
                this.setState({
                    boards: boards
                });
            }


        }

        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }

        this.getTransportRoutes();

        // let url = `${baseUrlAdmin}/`

        // this.service.get(url, true).then(res => {
        //     if (res?.status && res?.res?.status) {
        //         this.setState({
        //             isLoading: false,
        //             attendanceDetails: res?.res?.data
        //         })
        //     } else {
        //         this.setState({
        //             isLoading: false
        //         })
        //         this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
        //     }
        // }).catch(err => {
        //     this.setState({
        //         isLoading: false
        //     })
        //     this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

        // })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.boards !== this.props.boards) {
            if (this.props.boards) {
                let boards = [];
                this.props.boards &&
                    this.props.boards.map((item, index) => {
                        boards.push({ value: item.boardId, label: item.boardName });
                    });



                this.setState({
                    boards: boards
                });
            }
        }


        if (prevProps.branchData !== this.props.branchData) {

            let sectionsData = {
                level1: this.props.branchData.filter((a) => a.level == 1)
            }
            this.setState({
                sectionsData
            });
        }

    }


    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Transport Attendance</h2>
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    getSelectedLevelData(data, key) {
        let items = [];
        let selectedNode = data.find(a => a.key == key);
        if (selectedNode && selectedNode.children) {
            selectedNode.children.forEach((c) => {
                items.push(data.find(a => a.key == c))
            })

        }
        // console.log(items)
        return { items, selectedNode };

    }

    onChangeSection = (key, level) => {

        let { sectionsData } = this.state;

        let data = this.getSelectedLevelData(this.props.branchData, key)


        let emptyBeforeLevels = {};

        for (let i = (level + 1); i <= this.props.branchLevels.length; i++) {
            emptyBeforeLevels = {
                ...emptyBeforeLevels,
                ['level' + i]: [],
                ['levelSelected' + i]: null
            }
        }


        let newSectionData = {
            ...sectionsData,
            ...emptyBeforeLevels,
            ['level' + (level + 1)]: data.items,
            ['levelSelected' + (level)]: key
        }

        this.setState({
            sectionsData: newSectionData,
            sectionIds: []
        });




        //   let selectedSection = this.props.branches.find((s) => s.value == sectionId);





    };

    onMultiChange = (value) => {
        this.setState((prevState) => {
            return {
                sectionIds: value
            };
        });

    }

    onSave = () => {
        if (this.state.attendance && this.state.attendance.length) {
            const url = `${baseUrlAdmin}/transport-attendance/save-attendance`;
            this.service.post(url, {
                attendance: this.state.attendance,
                date: this.state.date
            }, true).then(res => {
                
                if (res && res.res && res.res.data) {
                    this.setState({
                        attendance: [],
                        isLoading: false
                    });
                    this.toast.show({ severity: 'success', summary: 'success', detail: 'Attendance saved successfully.', life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })
        }
    }

    onChangeDate = (date, key) => {
        this.setState({
            [key]: date,
            admissions: []
        })
    }

    onSearch = () => {
        if (!this.state.routeId) {
            return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Route is required', life: 3000 });
        }
        if (!this.state.date) {
            return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Date is required', life: 3000 });
        }

        const url = `${baseUrlAdmin}/transport-attendance/get-attendance`;
        this.service.post(url, {
            routeId: this.state.routeId,
            date: this.state.date
        }, true).then(res => {
            
            if (res && res.res && res.res.data) {
                this.setState({
                    attendance: res.res.data || [],
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }


        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });

            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }

    setAttendance = (_id, key, value) => {
        this.setState((prevState) => {
            return {
                attendance: prevState.attendance.map(a => {
                    if (a._id === _id) {
                        a[key] = value;
                    }
                    return a;
                })
            }


        })



    }

    render() {



        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'>Attendance</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={(e) => this.setState({ globalFilter: e.target.value })} />}

                </div>

                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        {/* s */}
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        return (
            <>
                <Navbar>
                    <div className="p-grid ma-toolbar">
                        <div className="p-col-12 p-md-1 ma-no-p">

                        </div>
                        <div className="p-col-12 p-md-11 ma-no-pm">
                            <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        </div>
                    </div>
                    <div className='ma-main'>

                        {/* {
                            this.props.branchLevels ? this.props.branchLevels.map((level, index) => {
                                return (!(this.props.branchLevels.length == index + 1) ?
                                    <Dropdown
                                        className='ma-mr20 ma-mt10 ma-w200'
                                        value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                        // className='ma-w100p'
                                        //    disabled={this.state.hideUpdateButton}
                                        optionLabel={"name"}
                                        optionValue={"key"}
                                        options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}

                                        onChange={(e) => this.onChangeSection(e.value, index + 1)}
                                        placeholder={`--Select ${level.levelName}--`} /> :
                                    <>
                                        <MultiSelect
                                            className='ma-mr20 ma-mt10 ma-w200'
                                            value={this.state.sectionIds ? this.state.sectionIds : []}
                                            // value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                            onChange={(e) => this.onMultiChange(e.value)}
                                            options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}
                                            optionLabel={"name"}
                                            optionValue={"key"}
                                            placeholder={`--Select ${level.levelName}--`}

                                        // onChange={(e) => setCities(e.value)} 
                                        />
                                    </>
                                )
                            }) : null
                        } */}
                        <Dropdown value={this.state.routeId}
                            options={this.state.transportRoutes || []}
                            optionValue="_id"
                            optionLabel="routeName"
                            className='ma-m-lr10 ma-w200'
                            onChange={(e) => this.onChangeDate(e.value, 'routeId')} placeholder="Select Route" />
                        <Calendar
                            value={this.state.date ? new Date(this.state.date) : null}
                            showIcon

                            className='ma-m-lr10 ma-w200'
                            onChange={(e) => this.onChangeDate(e.value, 'date')}


                            placeholder="Date" />
                        <Button label='Search' onClick={this.onSearch} />

                        <div className='card datatable-crud-demo ma-m30'>
                            <DataTable ref={(el) => this.dt = el}
                                value={this.state.attendance || []}
                                dataKey="_id"
                                paginator
                                rows={10}
                                responsiveLayout="scroll"
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                globalFilter={this.state.globalFilter}
                                selection={this.state.selectedCustomers}
                                onSelectionChange={e => this.setState({ selectedCustomers: e.value })}
                                header={header}
                            >
                                <Column field="admission.admissionNo" header="Admission No" />
                                <Column field="admission.studentFirstName" header="Student Name" />
                                <Column field="admission.boardName" header="Board Name" />
                                <Column field="admission.className" header="Class Name" />
                                <Column field="admission.section" header="Section" />

                                <Column header="Stop Name" field="transportStop.stopName" />
                                {/* <Column header="Original Pick Time" field="transportStop.pickUpTime" /> */}

                                <Column header="Pick Up (Attendance)" body={(rowData) => {
                                    return <>
                                        <Checkbox inputId={'item.id'}
                                            checked={rowData.pickUpPresent}
                                            onChange={(e) => {
                                                this.setAttendance(rowData._id, 'pickUpPresent', e.checked)
                                            }}
                                        ></Checkbox>
                                    </>
                                }} />
                                <Column header="Pick Up Time" body={(rowData) => {
                                    return <>
                                        <Calendar
                                            value={rowData.pickupTime ? new Date(rowData.pickupTime) : null}
                                            timeOnly
                                            hourFormat="12"
                                            onChange={(e) => {
                                                this.setAttendance(rowData._id, 'pickupTime', e.value)
                                            }}
                                            className='ma-w100p'
                                        ></Calendar>
                                    </>
                                }} />
                                <Column header="Drop (Attendance)" body={(rowData) => {
                                    return <>
                                        <Checkbox inputId={'item.id'}
                                            checked={rowData.dropPresent}
                                            onChange={(e) => {
                                                this.setAttendance(rowData._id, 'dropPresent', e.checked)
                                            }}
                                        ></Checkbox>
                                    </>
                                }} />
                                <Column header="Drop Time" body={(rowData) => {
                                    return <>
                                        <Calendar
                                            value={rowData.dropTime ? new Date(rowData.dropTime) : null}
                                            timeOnly
                                            hourFormat="12"
                                            onChange={(e) => {
                                                this.setAttendance(rowData._id, 'dropTime', e.value)
                                            }}
                                            className='ma-w100p'
                                        ></Calendar>
                                    </>
                                }} />
                            </DataTable>
                            <div className='p-mt-3'>
                                <Button label='Save' onClick={this.onSave} />
                            </div>
                        </div>
                    </div>

                </Navbar>
                {
                    this.state.isLoading && <LoadingComponent />
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}



const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    branches: state.branchDataLatest.branches,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
});


export default connect(mapStateToProps, {
    getBoardsData,
    getBranchesLatest
})((withRouter(TransportAttendance)));
// Authentication
