import * as ActionType from "../actionTypes";

export default (state = {}, action) => {
  switch(action.type){
    case ActionType.REQUEST_DELETE_EXAMINATION_DETAILS :

        return{
            ...state,
            loading:true,
            errorInfo:null,
            error:false,
            response:null
            
            }
    case ActionType.RECEIVED_DELETE_EXAMINATION_DETAILS :
        return{
            ...state,
            loading:false,
            errorInfo:null,
            error:false,
            response:action.res

        }
    case ActionType.ERROR_DELETE_EXAMINATION_DETAILS :
        return{
            ...state,
            loading:false,
            errorInfo:action.err,
            error:true,
            response:null
        }
    case ActionType.CLEAR_DELETE_EXAMINATION_DETAILS :
        return{
        }
    default : 
      return state    

  }
}