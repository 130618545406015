import React, { Component } from 'react'
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { ResponsiveBar } from '@nivo/bar';
import InLoadingComponent from '../../loadingComponent/inlineLoading';
import { I18Next } from '../../../internationalization';
import { connect } from 'react-redux';
import Authentication from '../../session';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: '',
    sortField: null,
    sortOrder: null,
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'country.name': { value: '', matchMode: 'contains' },
        'company': { value: '', matchMode: 'contains' },
        'representative.name': { value: '', matchMode: 'contains' },
    }
}
const arr2 = [
    {
     "rank": 1,
     "deviceId": "EWGF809710",
     "branch": "HYD SN",
     "lastActive": "35 days ago"
    },
    {
     "rank": 2,
     "deviceId": "DRRE111426",
     "branch": "HYD CMR",
     "lastActive": "35 days ago"
    },
    {
     "rank": 3,
     "deviceId": "IKPX463430",
     "branch": "HYD MYPAB",
     "lastActive": "35 days ago"
    },
    {
     "rank": 4,
     "deviceId": "HLAU890960",
     "branch": "HYD NG",
     "lastActive": "34 days ago"
    },
    {
     "rank": 5,
     "deviceId": "BOWP896696",
     "branch": "KDP DS",
     "lastActive": "33 days ago"
    },
    {
     "rank": 6,
     "deviceId": "BVZI985158",
     "branch": "VJA MC",
     "lastActive": "33 days ago"
    },
    {
     "rank": 7,
     "deviceId": "PXEG290582",
     "branch": "HYD MYPAB",
     "lastActive": "33 days ago"
    },
    {
     "rank": 8,
     "deviceId": "WVHQ619608",
     "branch": "VJA MC",
     "lastActive": "33 days ago"
    },
    {
     "rank": 9,
     "deviceId": "XSBN338083",
     "branch": "TPTDS",
     "lastActive": "33 days ago"
    },
    {
     "rank": 10,
     "deviceId": "BGOM388652",
     "branch": "HYD ECIL",
     "lastActive": "32 days ago"
    },
    {
     "rank": 11,
     "deviceId": "TXNF513624",
     "branch": "HYD DSN",
     "lastActive": "32 days ago"
    },
    {
     "rank": 12,
     "deviceId": "ILCM983609",
     "branch": "HYDRKP",
     "lastActive": "31 days ago"
    },
    {
     "rank": 13,
     "deviceId": "DKDZ556873",
     "branch": "HYD MYPMED",
     "lastActive": "30 days ago"
    },
    {
     "rank": 14,
     "deviceId": "AQOS386977",
     "branch": "VJA RG",
     "lastActive": "30 days ago"
    },
    {
     "rank": 15,
     "deviceId": "UADX954861",
     "branch": "VJA MC",
     "lastActive": "29 days ago"
    },
    {
     "rank": 16,
     "deviceId": "WCIP489051",
     "branch": "HYD MYPAB",
     "lastActive": "29 days ago"
    },
    {
     "rank": 17,
     "deviceId": "QPPY238886",
     "branch": "CO ICCG",
     "lastActive": "29 days ago"
    },
    {
     "rank": 18,
     "deviceId": "BEUZ426625",
     "branch": "HYD MP",
     "lastActive": "28 days ago"
    },
    {
     "rank": 19,
     "deviceId": "QATU604348",
     "branch": "VJA MC",
     "lastActive": "28 days ago"
    },
    {
     "rank": 20,
     "deviceId": "YJSW129279",
     "branch": "HYDKPHB",
     "lastActive": "28 days ago"
    },
    {
     "rank": 21,
     "deviceId": "RDBB131703",
     "branch": "HYD VPG",
     "lastActive": "27 days ago"
    },
    {
     "rank": 22,
     "deviceId": "HCIC878363",
     "branch": "KNL RG",
     "lastActive": "27 days ago"
    },
    {
     "rank": 23,
     "deviceId": "COYJ867761",
     "branch": "HYDDSNG",
     "lastActive": "27 days ago"
    },
    {
     "rank": 24,
     "deviceId": "RVPJ577611",
     "branch": "HYD BPRB4",
     "lastActive": "27 days ago"
    },
    {
     "rank": 25,
     "deviceId": "PJYF603515",
     "branch": "VJA BVPM",
     "lastActive": "26 days ago"
    },
    {
     "rank": 26,
     "deviceId": "GQFD983400",
     "branch": "HYD SRNG",
     "lastActive": "26 days ago"
    },
    {
     "rank": 27,
     "deviceId": "UNSA182091",
     "branch": "HYD BPRB1",
     "lastActive": "26 days ago"
    },
    {
     "rank": 28,
     "deviceId": "EYDL189350",
     "branch": "KRMNG",
     "lastActive": "25 days ago"
    },
    {
     "rank": 29,
     "deviceId": "NUYK785180",
     "branch": "HYD BPRB1",
     "lastActive": "25 days ago"
    },
    {
     "rank": 30,
     "deviceId": "AWGF894705",
     "branch": "VJA BVPM",
     "lastActive": "25 days ago"
    },
    {
     "rank": 31,
     "deviceId": "KGII858254",
     "branch": "CO MCC",
     "lastActive": "25 days ago"
    },
    {
     "rank": 32,
     "deviceId": "SWKP205010",
     "branch": "KNL RG",
     "lastActive": "25 days ago"
    },
    {
     "rank": 33,
     "deviceId": "OOVD173528",
     "branch": "TPTRG",
     "lastActive": "25 days ago"
    },
    {
     "rank": 34,
     "deviceId": "OBWJ597354",
     "branch": "HYD CMR",
     "lastActive": "23 days ago"
    },
    {
     "rank": 35,
     "deviceId": "DZMM624159",
     "branch": "HYD CMRKP",
     "lastActive": "23 days ago"
    },
    {
     "rank": 36,
     "deviceId": "MXSM483450",
     "branch": "HYD HB",
     "lastActive": "22 days ago"
    },
    {
     "rank": 37,
     "deviceId": "FINX908433",
     "branch": "TPTRG",
     "lastActive": "22 days ago"
    },
    {
     "rank": 38,
     "deviceId": "CYBY850049",
     "branch": "VJA PAT",
     "lastActive": "22 days ago"
    },
    {
     "rank": 39,
     "deviceId": "NWQP238084",
     "branch": "HYD SN",
     "lastActive": "22 days ago"
    },
    {
     "rank": 40,
     "deviceId": "OLNS122700",
     "branch": "VJA MC",
     "lastActive": "21 days ago"
    },
    {
     "rank": 41,
     "deviceId": "FEZS787679",
     "branch": "HYD CHT",
     "lastActive": "21 days ago"
    },
    {
     "rank": 42,
     "deviceId": "DICC896699",
     "branch": "HYD HB",
     "lastActive": "21 days ago"
    },
    {
     "rank": 43,
     "deviceId": "CEOX842587",
     "branch": "KNL RG",
     "lastActive": "21 days ago"
    },
    {
     "rank": 44,
     "deviceId": "DWCX433649",
     "branch": "VJA BVPM",
     "lastActive": "20 days ago"
    },
    {
     "rank": 45,
     "deviceId": "KFWV662028",
     "branch": "GNT DS",
     "lastActive": "19 days ago"
    },
    {
     "rank": 46,
     "deviceId": "STAW899388",
     "branch": "HYD BPRB4",
     "lastActive": "19 days ago"
    },
    {
     "rank": 47,
     "deviceId": "SISM747959",
     "branch": "VJA BVPM",
     "lastActive": "19 days ago"
    },
    {
     "rank": 48,
     "deviceId": "FPSZ647200",
     "branch": "HYD DSNMED",
     "lastActive": "17 days ago"
    },
    {
     "rank": 49,
     "deviceId": "HIOJ879992",
     "branch": "CO ICC",
     "lastActive": "17 days ago"
    },
    {
     "rank": 50,
     "deviceId": "EXFP303713",
     "branch": "HYD MYPGAC",
     "lastActive": "17 days ago"
    },
    {
     "rank": 51,
     "deviceId": "BRIF965287",
     "branch": "HYD SNMPC",
     "lastActive": "16 days ago"
    },
    {
     "rank": 52,
     "deviceId": "XHFJ524464",
     "branch": "HYD BPRB2",
     "lastActive": "15 days ago"
    },
    {
     "rank": 53,
     "deviceId": "YKQD160184",
     "branch": "HYD DSNMED",
     "lastActive": "15 days ago"
    },
    {
     "rank": 54,
     "deviceId": "XMOK215777",
     "branch": "HYD HB",
     "lastActive": "14 days ago"
    },
    {
     "rank": 55,
     "deviceId": "GFSU190167",
     "branch": "VJA PAT",
     "lastActive": "14 days ago"
    },
    {
     "rank": 56,
     "deviceId": "VGYA899987",
     "branch": "HYDRKP",
     "lastActive": "14 days ago"
    },
    {
     "rank": 57,
     "deviceId": "PXZX647771",
     "branch": "HYD KKP",
     "lastActive": "14 days ago"
    },
    {
     "rank": 58,
     "deviceId": "UBUF373934",
     "branch": "HYD VPG",
     "lastActive": "13 days ago"
    },
    {
     "rank": 59,
     "deviceId": "LGCQ866605",
     "branch": "HYD MYPBAC",
     "lastActive": "13 days ago"
    },
    {
     "rank": 60,
     "deviceId": "XSPH261558",
     "branch": "KRMNG",
     "lastActive": "13 days ago"
    },
    {
     "rank": 61,
     "deviceId": "SSCH293963",
     "branch": "HYDKPHB",
     "lastActive": "13 days ago"
    },
    {
     "rank": 62,
     "deviceId": "IIHN607944",
     "branch": "HYD NG",
     "lastActive": "13 days ago"
    },
    {
     "rank": 63,
     "deviceId": "UWHO728221",
     "branch": "HYD CHT",
     "lastActive": "12 days ago"
    },
    {
     "rank": 64,
     "deviceId": "SRAX498224",
     "branch": "TPTRG",
     "lastActive": "12 days ago"
    },
    {
     "rank": 65,
     "deviceId": "EAVD710770",
     "branch": "HYD MYPMED",
     "lastActive": "12 days ago"
    },
    {
     "rank": 66,
     "deviceId": "JZEN577001",
     "branch": "HYD NG",
     "lastActive": "12 days ago"
    },
    {
     "rank": 67,
     "deviceId": "QQNT588288",
     "branch": "CO ICCG",
     "lastActive": "12 days ago"
    },
    {
     "rank": 68,
     "deviceId": "BODP550035",
     "branch": "HYD ECIL",
     "lastActive": "12 days ago"
    },
    {
     "rank": 69,
     "deviceId": "ZXMF225406",
     "branch": "HYD CMR",
     "lastActive": "11 days ago"
    },
    {
     "rank": 70,
     "deviceId": "ATTJ431363",
     "branch": "HYD MYPMED",
     "lastActive": "11 days ago"
    },
    {
     "rank": 71,
     "deviceId": "JBJS168064",
     "branch": "KNL DS",
     "lastActive": "11 days ago"
    },
    {
     "rank": 72,
     "deviceId": "HTMV973764",
     "branch": "HYD CMR",
     "lastActive": "10 days ago"
    },
    {
     "rank": 73,
     "deviceId": "IBDJ197052",
     "branch": "VJA RG",
     "lastActive": "10 days ago"
    },
    {
     "rank": 74,
     "deviceId": "SYBR623619",
     "branch": "HYD VPG",
     "lastActive": "10 days ago"
    },
    {
     "rank": 75,
     "deviceId": "RPJJ732796",
     "branch": "CO ICC",
     "lastActive": "10 days ago"
    },
    {
     "rank": 76,
     "deviceId": "QTZJ557686",
     "branch": "HYD MP",
     "lastActive": "10 days ago"
    },
    {
     "rank": 77,
     "deviceId": "HSQL507558",
     "branch": "KRMNG RG",
     "lastActive": "9 days ago"
    },
    {
     "rank": 78,
     "deviceId": "BVGO671226",
     "branch": "VJA RB IC",
     "lastActive": "9 days ago"
    },
    {
     "rank": 79,
     "deviceId": "NXUN707669",
     "branch": "GNT DS",
     "lastActive": "9 days ago"
    },
    {
     "rank": 80,
     "deviceId": "XKCC505724",
     "branch": "HYD MYPMED",
     "lastActive": "8 days ago"
    },
    {
     "rank": 81,
     "deviceId": "LVJC458714",
     "branch": "HYD WMP",
     "lastActive": "8 days ago"
    },
    {
     "rank": 82,
     "deviceId": "LYJI888686",
     "branch": "HYDKPHB",
     "lastActive": "7 days ago"
    },
    {
     "rank": 83,
     "deviceId": "GASV708679",
     "branch": "VJA PAT",
     "lastActive": "6 days ago"
    },
    {
     "rank": 84,
     "deviceId": "SPCX455620",
     "branch": "CO ICCG",
     "lastActive": "6 days ago"
    },
    {
     "rank": 85,
     "deviceId": "QYVC882008",
     "branch": "KNL RB",
     "lastActive": "6 days ago"
    },
    {
     "rank": 86,
     "deviceId": "WHRC785926",
     "branch": "HYD CMR",
     "lastActive": "6 days ago"
    },
    {
     "rank": 87,
     "deviceId": "CMWU300835",
     "branch": "KDP DS",
     "lastActive": "5 days ago"
    },
    {
     "rank": 88,
     "deviceId": "ZWGF109719",
     "branch": "HYD MYPMED",
     "lastActive": "5 days ago"
    },
    {
     "rank": 89,
     "deviceId": "SDLY835127",
     "branch": "HYD BPRB2",
     "lastActive": "5 days ago"
    },
    {
     "rank": 90,
     "deviceId": "TQKX213034",
     "branch": "HYD CN",
     "lastActive": "5 days ago"
    },
    {
     "rank": 91,
     "deviceId": "KXKZ469254",
     "branch": "HYDKPHB",
     "lastActive": "5 days ago"
    },
    {
     "rank": 92,
     "deviceId": "EHAY759796",
     "branch": "HYD MP",
     "lastActive": "5 days ago"
    },
    {
     "rank": 93,
     "deviceId": "SSJP887392",
     "branch": "HYD BPRB4",
     "lastActive": "4 days ago"
    },
    {
     "rank": 94,
     "deviceId": "WAPS781864",
     "branch": "HYDDSNG",
     "lastActive": "4 days ago"
    },
    {
     "rank": 95,
     "deviceId": "DISL946399",
     "branch": "HYD BPRB2",
     "lastActive": "4 days ago"
    },
    {
     "rank": 96,
     "deviceId": "LHJB369860",
     "branch": "HYD DSNRB",
     "lastActive": "4 days ago"
    },
    {
     "rank": 97,
     "deviceId": "OMTN147062",
     "branch": "HYDCPT",
     "lastActive": "4 days ago"
    },
    {
     "rank": 98,
     "deviceId": "JTCO271757",
     "branch": "HYD MP",
     "lastActive": "3 days ago"
    },
    {
     "rank": 99,
     "deviceId": "GCOQ923857",
     "branch": "HYD HB",
     "lastActive": "3 days ago"
    },
    {
     "rank": 100,
     "deviceId": "WVKC903506",
     "branch": "KNL RG",
     "lastActive": "3 days ago"
    },
    {
     "rank": 101,
     "deviceId": "KEWE880626",
     "branch": "HYD MYPMED",
     "lastActive": "2 days ago"
    },
    {
     "rank": 102,
     "deviceId": "QPQM166493",
     "branch": "HYD DSN",
     "lastActive": "2 days ago"
    },
    {
     "rank": 103,
     "deviceId": "KXGO120209",
     "branch": "VJA PAT",
     "lastActive": "2 days ago"
    }
   ]

const arr = [
    {
        "rank": 1,
        "deviceId": "EWGF809710",
        "branch": "HYD SN",
        "lastActive": "35 days ago"
       },
       {
        "rank": 2,
        "deviceId": "DRRE111426",
        "branch": "HYD CMR",
        "lastActive": "35 days ago"
       },
       {
        "rank": 3,
        "deviceId": "IKPX463430",
        "branch": "HYD MYPAB",
        "lastActive": "35 days ago"
       },
]
class DeviceWiseAtiveReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inlineLoading: false,
            inlineLoadingD : false,
            deviceWiseAtiveReport : arr,
            dialogHeader : "",
            showDiolog : false,
            arrToDataTable : arr2,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams
        }



        this.service = new Service();


    }

    



    componentDidMount() {

    }

    onClickMore = (h) => {
        this.setState({
            showDiolog: true,
            dialogHeader : h
        })
    }

    onPage = (event) => {
        this.setState({ lazyParams: event });
    }
    render() {
        const { defaultLanguage } = this.props;
        const {  inlineLoading,deviceWiseAtiveReport } = this.state;
        const langType = defaultLanguage
     

        return (
            <div className='ma-relative'>
                <div className='resource-usage-report' style={{background:"#fae5e1"}}>
                    <h3 className="resource-title ma-mt10">{I18Next('deviceWiseActiveReport', langType).COMMON_KEY}</h3>
                    <div className='p-grid'>
                        <div className='p-col-12 p-md-11'>
                        <div className='trending-table-rup' style={{width: "120%"}}>
                            <table className='trending-table'>
                            <tr>
                                <th style={{color:"#E5346D",font:"normal normal bold 14px/16px Roboto"}}>{I18Next('rank', langType).COMMON_KEY}</th>
                                <th style={{color:"#800080",font:"normal normal bold 14px/16px Roboto"}}>{I18Next('deviceID', langType).COMMON_KEY}</th>
                                <th style={{color:"#2e8b57",font:"normal normal bold 14px/16px Roboto"}}>{I18Next('branch', langType).COMMON_KEY}</th>
                                <th style={{color:"#2e8b57",font:"normal normal bold 14px/16px Roboto"}}>{I18Next('lastActive', langType).COMMON_KEY}</th>
                            </tr>
                            {deviceWiseAtiveReport&&   deviceWiseAtiveReport.map(branch => {
                            return (
                            <>
                            <tr>
                                {/* <td className='t-head-trend'>branch.chapterName</td> */}
                                <td>{branch.rank}</td>
                                <td>{branch.deviceId}</td>
                                <td>{branch.branch}</td>
                                <td>{branch.lastActive}</td>
                            </tr>
                            </>
                            )})}
                            </table>
                            <div className='flex-reverse ma-mr100'>
                                <span className='ma-mt10 ma-pointer' onClick={() => this.onClickMore("Device Wise Active Report")}
                                style={{color:"#004cff",font:"normal normal bold italic 13px Roboto"}}>{`${I18Next('more', langType).COMMON_KEY}`}</span>
                            </div>
                        </div>
                            
                        </div>
                    </div>
                </div>
                <Dialog className='ma-alert-box p-dialog-dash p-overflow-hidden'
                header={<h4>{I18Next('deviceWiseActiveReport', langType).COMMON_KEY}</h4>}
                draggable={false}
                closeOnEscape={true}
                blockScroll={true}
                dismissableMask={false}
                closable={true}
                visible={this.state.showDiolog} style={{ maxWidth: '40vw', minWidth: '40vw' }}
                onHide={() => this.setState({showDiolog : false})}
            >
                <div className='card datatable-crud-demo'>
                <DataTable stripedRows className='asdf' 
                    ref={(el) => this.dt = el}
                    value={this.state.arrToDataTable}
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                    dataKey="id"
                    paginator
                    // lazy
                    onPage={this.onPage}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    totalRecords={this.state.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    responsiveLayout="scroll"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Reports"
                    globalFilter={this.state.globalFilter}
                    header={''}
                >
                    <Column header='User Name' field='rank'/>
                    <Column header='Device ID' field='deviceId'/>
                    <Column header='Branch' field='branch'/>
                    <Column header='Last Active' field='lastActive'/>
                </DataTable>
                </div>
                {
                this.state.inlineLoadingD && <InLoadingComponent />
                }
                </Dialog>
                {
                    inlineLoading && <InLoadingComponent />
                }
            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,
});

export default connect(mapStatesToProps, {})(Authentication(DeviceWiseAtiveReport));
