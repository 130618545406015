import * as ActionTypes from '../actionTypes';
import axios from 'axios';
import { baseUrlForLongRunningTask } from '../apiConstants';

const dispatchRequestBulkUpload = (data, index) => ({
    type: ActionTypes.REQUEST_BULK_UPLOAD,
    data,
    index
})

const dispatchReceivedBulkUpload = (res, arg) => ({
    type: ActionTypes.RECEIVED_BULK_UPLOAD,
    res
})

const dispatchErrorBulkUpload = (err, errInfo) => ({
    type: ActionTypes.ERROR_BULK_UPLOAD,
    err,
    errInfo
})

const dispatchClearBulkUpload = () => ({
    type: ActionTypes.CLEAR_BULK_UPLOAD
})

export const clearBulkUploadData = () => dispatchClearBulkUpload();


export const bulkUpload = (contentInfo) => dispatch => {
    let headers = {};
    const etag = localStorage.getItem('etag');
    if (etag) {
        headers = {
            "If-Match": etag
        }
    }


    const topics = [];
    contentInfo.map(x => {
        topics.push(x.topicId);
    })

    const _topics = [... new Set(topics)]

    const filteredData = [];

    _topics.map(t => {
        let _fData = contentInfo.filter(x => x.topicId === t);
        filteredData.push(_fData);
    })


    const uploadData = [];

    filteredData.map(data => {
        let x = {
            filelist: [],
        }
        data.map(d => {
            x = { ...x, ...d, filelist: [...x.filelist, ...d.filelist] }
        })

        uploadData.push(x);
    })

    if (uploadData && uploadData.length) {
        dispatch(dispatchRequestBulkUpload(uploadData));

        axios({
            method: 'post',
            url: `${baseUrlForLongRunningTask}/long/bulkuploads3`,
            data: uploadData,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
            }
        }).then((res) => {
            dispatch(dispatchReceivedBulkUpload(res.data));
        }).catch(e => {
            const errInfo = e.response && e.response.data && e.response.data.missingFiles && e.response.data.missingFiles.length && e.response.data.missingFiles
            dispatch(dispatchErrorBulkUpload(e, errInfo));
        });
    }


}





