import moment from 'moment';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react'
import { Column } from 'react-formio/lib/types';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import SvgViewer from '../../customComponents/svgViewer';
import LoadingComponent from '../../loadingComponent';
import Navbar from '../../navbar';
import { DeleteIcon, PreviewIcon, WarningIcon } from '../../svgIcons';
import AddBusFee from './addBusFee';



const items = [
  { label: 'Organization' },
  { label: 'Bus Fee Plans', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

export default class BusFeePlans extends Component {
  constructor(props) {
    super(props);
    this.state = {

      globalFilter: null,
      globalSearch: '',
      columns: [
        { columnName: 'Chassis No', isVisible: false, id: 'chassisNo' },
        { columnName: 'Engine No', isVisible: false, id: 'engineNo' }
      ],
      warningDialog: warningDailogInit,
      warningDialogForDelete: warningDailogInit,
      previewDetails: false,
      lazyParams: BasicLazyParams,
      totalRecords: 0,
      showSearch: false,

      showAddBusFee: false,
      busFeePlansList: [],
      isLoading: false

    }

    this.service = new Service()
  }

  componentDidMount() {
    this.getBusPlansData()
  }

  getBusPlansData = () => {

    this.setState({
      isLoading: true
    })

    let lazyParams = this.state.lazyParams;


    let url = `${baseUrlAdmin}/bus-fee-plans?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${this.state.globalSearch}`


    this.service.get(url, true).then(res => {
      if (res?.status && res?.res?.status) {
        this.setState({
          isLoading: false,
          busFeePlansList: res?.res?.data?.feePlans,
          totalRecords: res?.res?.data?.totalRecords
        })
      } else {
        this.setState({
          isLoading: false
        })
        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
      }
    }).catch(err => {
      this.setState({
        isLoading: false
      })
      this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

    })

  }


  rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <h2 className='ma-tab-title'>Bus Fee Plans</h2>
      </React.Fragment>
    )
  }
  leftToolbarTemplate() {
    return (
      <React.Fragment>
        <BreadCrumb model={items} home={home} />
      </React.Fragment>
    )
  }

  exportCSV = () => {
    this.dt.exportCSV();
  }


  isColumnVisible = (id) => {
    return this.state.columns.filter(c => c.id == id)[0].isVisible;
  }

  onGlobalSearch = (e) => {
    this.setState((prevState) => {
      return {
        globalSearch: e.target.value,
        lazyParams: BasicLazyParams
      }
    }, this.getBusPlansData);
  }


  onColumnClick = (column) => {
    let columns = this.state.columns.map(c => {
      if (column.id == c.id) {
        c.isVisible = !c.isVisible;
      }
      return c;
    });
    this.setState({
      columns
    });
  }


  onPage = (event) => {
    this.setState({ lazyParams: event }, this.getBusPlansData);
  }

  onClose = () => {
    this.setState({
      editBusFeeDetails: '',
      showAddBusFee: false,
    })
  }

  onSaveOrUpdate = (isUpdate) => {
    this.toast.show({ severity: 'success', summary: 'Saved', detail: `Bus Fee plan ${isUpdate ? 'updated' : 'created'} successfuly`, life: 3000 });
    this.setState({
      editBusFeeDetails: '',
      showAddBusFee: false,
    }, this.getBusPlansData)
  }


  editTemplate = (rowData, { rowIndex }) => {
    return (<>
      <div className="p-d-flex ma-mlr10 user-edit-t">
        {/* <span data-pr-tooltip="Preview" data-pr-position="bottom" className={`edd${rowIndex}`}
          onClick={() => {
            this.showPreview(rowData)
          }}>
          <PreviewIcon height={15} width={15} />
        </span>
        <Tooltip className="table-li-tooltip" autoHide={false} target={`.edd${rowIndex}`} /> */}

        <span data-pr-tooltip="Edit" data-pr-position="bottom" className={`ma-ml10 p-ml-3 ar${rowIndex}`} onClick={() => this.showEditRoleDialog(rowData)} >
          <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="15" height="15" />
        </span>
        <Tooltip className="table-li-tooltip" autoHide={false} target={`.ar${rowIndex}`} />

        {/* <span data-pr-tooltip="Delete" data-pr-position="bottom" className={`dlete${rowIndex} p-ml-3 ma-ml10`}
          onClick={() => { this.handleDelete(rowData) }}
        >
          <DeleteIcon height={15} width={15} />
        </span>
        <Tooltip className="table-li-tooltip" autoHide={false} target={`.dlete${rowIndex}`} /> */}

      </div>
    </>
    );
  }


  showEditRoleDialog = (editData) => {

    this.setState({
      editBusFeeDetails: editData,
      showAddBusFee: true,
    });
  }

  handleDelete = async (rowData) => {
    this.setState({
      warningDialogForDelete: {
        visible: true,
        headerMsg: 'Are you Sure?',
        message: `Do you want to delete the ${rowData.vehicleRegistrationNo} vehicle.`
      },
      editVehicleData: rowData
    });
  }

  // deleteTimeTable = () => {

  //     let { editVehicleData } = this.state;
  //     const url = `${baseUrlAdmin}/transport-vehicle`;

  //     this.setState({
  //         warningDialogForDelete: warningDailogInit,
  //         isLoading: true
  //     })
  //     this.service.delete(url, true).then((res) => {
  //         if (res && res.status && res.res.status) {
  //             this.setState({
  //                 isLoading: false
  //             },this.getBusPlansData)
  //         } else {
  //             this.setState({
  //                 isLoading: false
  //             });
  //             this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
  //         }
  //     }).catch(e => {
  //         console.log(e);
  //         this.setState({
  //             isLoading: false
  //         });
  //         this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
  //     })
  // }

  onClickAddBusPlan = () => {
    this.setState({
      showAddBusFee: true
    })
  }


  render() {


    const header = (
      <div className="table-header ma-tbl-header">
        <div className="ma-tbl-left">
          <h2 className='ma-table-title'>Bus Fee Plans</h2>
          {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md"
            value={this.state.globalSearch}
            onChange={this.onGlobalSearch} />}

        </div>

        <div className="p-input-icon-left ma-tbl-filter">
          <ul className="ma-tbl-li">
            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
            {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                  <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
            <li><i data-pr-tooltip="View Columns"
              onClick={(event) => this.menu.toggle(event)}
              data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
          </ul>
        </div>
        <Menu
          className='table-filter-w'
          model={[
            {
              template: (<div className='table-filter'>
                <h4 className="filter-title">View Columns</h4>
                <ul>
                  {this.state.columns.map((column) => {
                    return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                      checked={column.isVisible}
                    ></Checkbox>
                      <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                    </div>
                  })}
                </ul>
              </div>)
            }]
          }
          popup
          ref={el => this.menu = el} />
      </div>
    );
    return (
      <>
        <Navbar>

          <div className='p-grid ma-toolbar'>
            <div className="p-col-12 p-md-1 ma-no-p">

            </div>
            <div className="p-col-12 p-md-11 ma-no-pm">
              <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
            </div>
          </div>

          <div className='ma-main'>
            <div className='ma-mtb25'>
              <Button label="+ Add Bus Fee Plan" className='ma-m-lr10' onClick={this.onClickAddBusPlan} />
            </div>
            <div className='card datatable-crud-demo ma-m30'>
              <DataTable ref={(el) => this.dt = el}
                value={this.state.busFeePlansList || []}
                dataKey="_id"
                paginator
                lazy
                onPage={this.onPage}
                rows={this.state.lazyParams.rows}
                first={this.state.lazyParams.first}
                last={this.state.totalRecords}
                totalRecords={this.state.totalRecords}
                responsiveLayout="scroll"
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                globalFilter={this.state.globalFilter}
                header={header}
              >
                <Column header="S.No." body={(rowData, index) => {
                  return (
                    <>
                      <p>{(this.state.lazyParams.page * this.state.lazyParams.rows) + index.rowIndex + 1}</p>
                    </>
                  )
                }} />
                <Column field="name" header="Name" />

                <Column field="" header="Actions" body={this.editTemplate} />
              </DataTable>
              {this.state.showAddBusFee &&
                <AddBusFee

                  editBusFeeDetails={this.state.editBusFeeDetails}
                  visible={this.state.showAddBusFee}
                  onClose={this.onClose}
                  onSaveOrUpdate={this.onSaveOrUpdate}
                />
              }
            </div>

            <Dialog
              draggable={false}
              blockScroll={true}
              closeOnEscape={true}
              className='ma-alert-box'
              dismissableMask={true}
              closable={false}
              visible={this.state.warningDialogForDelete.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
              footer={() => {
                return (<div>
                  <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialogForDelete: warningDailogInit, editVehicleData: null }) }} />
                  <Button label="Yes" className='ma-m-lr10' onClick={this.deleteTimeTable} />

                </div>)
              }}
              onHide={() => this.setState({ warningDialogForDelete: warningDailogInit, editVehicleData: null })}>
              <div>
                <span className="warning-icon" >
                  <WarningIcon width={"100%"} height={"100%"} />
                </span>
              </div>
              <p className='ma-alert-head'>{this.state.warningDialogForDelete.headerMsg}</p>
              <p className='ma-alert-msg'>{this.state.warningDialogForDelete.message}</p>
            </Dialog>
          </div>

          <Toast ref={(el) => this.toast = el} position="bottom-right" />

          {
            this.state.isLoading && <LoadingComponent />
          }
        </Navbar>
      </>
    )
  }
}
