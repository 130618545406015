import { Toast } from 'primereact/toast';
import React, { Component } from 'react'
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';

export default class QuizPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            exam: null

        }

        this.service = new Service();

    }



    getExam = (examId) => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/examination/${examId}/exam-type/Quiz`;
        this.service.get(url, true).then(res => {
            
            if (res && res.res && res.res.Item && res.res.Item.length) {
                this.setState({
                    exam: res.res.Item[0],
                    isLoading: false
                })
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch((e) => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });

    }

    componentDidMount() {
        let { contentId } = this.props.contentInfo;
        
        if (contentId) {
            this.getExam(contentId);
        }

    }


    render() {
        let { exam } = this.state;

      //  console.log('quiz-info', this.props.contentInfo);
        return (<>

            {(!this.state.isLoading && exam) ? <>
                <div className='preview-quiz'>
                    <div className='p-grid ma-no-pm'>
                        <div className='p-col-12 p-md-6 '>
                            <div className=' ma-m20'>
                                <p className='exam-name'>{exam.examName}</p>
                                <div className='p-grid exam-info'>
                                    <div className='p-col-12 p-md-3'>
                                        <div className=' '>
                                            <p className='e-label'>Number Of Questions</p>
                                            <p className='e-value'>{exam.noOfQuestions}</p>
                                        </div>
                                    </div>
                                    <div className='p-col-12 p-md-3'>
                                        <div className=' '>
                                            <p className='e-label'>Total Time</p>
                                            <p className='e-value'>{exam.totalTime} Min</p>
                                        </div>
                                    </div>
                                    <div className='p-col-12 p-md-3'>
                                        <div className=' '>
                                            <p className='e-label'>Total Marks</p>
                                            <p className='e-value'>{exam.totalMarks}</p>
                                        </div>
                                    </div>
                                    <div className='p-col-12 p-md-3'>
                                        <div className=' '>
                                            <p className='e-label'>Question Types</p>
                                            <p className='e-value'>{(exam.questionType && exam.questionType.length) ? exam.questionType.join(', ') : 'All'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </> : <>
                <div>
                    <p className='ma-danger'>No Exam found, it may be deleted, So remove the record from content master. Please recreate quiz and assign. </p></div>

            </>}



            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            {
                this.state.isLoading && <>
                    <LoadingComponent />
                </>
            }

        </>

        )
    }
}
