import * as ActionTypes from './../actionTypes';

export default (state = {
    assignedModules: null,
    logoRectangle: null,
    logoSquare: null,
    orgId: null,
    name: null,
    isLoading: true,
    error: false,
    errorMessage: '',
}, action) => {
    switch (action.type) {
        case ActionTypes.REQUEST_ORG:
            return {
                ...state,
                isLoading: true,
                error: false,
            };
        case ActionTypes.RECEIVED_ORG:

            return {
                ...state,
                isLoading: false,
                assignedModules: action.data.assignedModules,
                logoRectangle: action.data.logoRectangle,
                logoSquare: action.data.logoSquare,
                orgId: action.data._id,
                name: action.data.name,
                isSuper: action.data.isSuper,
                emailConfig: action.data.emailConfig,
                smsConfig: action.data.smsConfig,
                whatsAppConfig: action.data.whatsAppConfig,
                orgCode: action.data.orgCode,
                syncEnabled: action.data.syncEnabled,
                address: action.data.address,
                mobileOperational: action.data.mobileOperational,
                error: false,
                etag: action.etag
            };
        case ActionTypes.ERROR_ORG:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: action.err.message
            };
        case ActionTypes.CLEAR_ORG_DATA:
            return {
                orgInfo: null,
                isLoading: false,
                error: false,
                errorMessage: '',
            };
        default:
            return state;
    }
}