import * as ActionTypes from '../actionTypes';

export default (state = {
    loadings: {},
    snackBars: {
        key: 'a',
        message: '',
        variant: 'info',
        isShow: false
    }
}, action) => {

    switch (action.type) {
        case ActionTypes.ADD_LOADING:
            let loadings = state.loadings;
            loadings[action.data] = true;
            return {
                ...state,
                loadings
            };
        case ActionTypes.REMOVE_LOADING:
            let loadingss = state.loadings;
            delete loadingss[action.data];
            return {
                ...state,
                loadingss
            };
        case ActionTypes.ADD_SNACKBAR:
            return {
                ...state,
                snackBars: {
                    ...state.snackBars,
                    message: action.data.message,
                    isShow: action.data.isShow,
                    variant: action.data.variant
                }
            };
        case ActionTypes.REMOVE_SNACKBAR:
            return {
                ...state,
                snackBars: {
                    ...state.snackBars,
                    message: '',
                    isShow: action.data.isShow
                }
            };
        default:
            return state;
    }
}