import * as ActionTypes from "../actionTypes";

export  default (state = [], action) => {
    switch(action.type){
        case ActionTypes.SET_LANGUAGE_DETAILS :
            return{
                ...state,
                detailsInfo: action.detailsInfo
            }
        case ActionTypes.DELETE_LANGUAGE_COMPONENT :
            return{
                ...state,
                detailsInfo: state.detailsInfo && Array.isArray(state.detailsInfo) && state.detailsInfo.filter(option => option.optionComponentId !== action.id) || []
            }

        case ActionTypes.REMOVE_LANGUAGE_DETAILS :
            return {}    
        default: 
        return state    
    }
}