import React, { Component } from 'react';
import Service from './../service';
import { RadioButton } from 'primereact/radiobutton';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import PERM_CONSTANTS from './../permissionConstants';

import { isUserAuthorized } from '../../../utile/isAuthorized';
import { FilterIcon } from '../../svgIcons';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';


class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            sort: '-createdAt',
            open: false,
            currentTab: 1,
            assignedSubjects: this.props.assignedSubjects ? this.props.assignedSubjects : [],
            isFilterChanged: false
        }

        this.service = new Service();
        this.anchorRef = React.createRef(null);
    }


    onTabChange = (newValue) => {
        this.setState({
            currentTab: newValue
        });
    }


    handleToggle = (event) => {
        this.setState({
            open: !this.state.open,
            anchorRef: event.currentTarget
        });
    };

    handleClose = () => {
        this.setState({
            open: !this.state.open,
            currentTab: 1,
            anchorRef: null
        });
    }



    handleSortChange = (event) => {
        this.setState({
            sort: event.target.value
        });
    };

    onSearch = (e) => {
        let search = e.target.value;
        let subjects = this.state.assignedSubjects.filter((item) => {

            if (item.isSelected) {
                return item;
            }
        });

        let subj = this.state.isFilterChanged ? subjects : this.state.assignedSubjects;

        this.setState({
            search
        }, () => {
            this.props.onsearchApply(search, this.state.sort, subj)
        })


    }

    handleSelect = (subject) => {
        let assignedSubjects = this.state.assignedSubjects.map((s) => {
            if (s.subjectId == subject.subjectId) {
                s.isSelected = s.isSelected ? false : true;
            }

            return s;
        });

        this.setState({
            assignedSubjects,
            isFilterChanged: true
        });


    }



    applyFilter = (event) => {
        this.menu.toggle(event)

        let subjects = this.state.assignedSubjects.filter((item) => {

            if (item.isSelected) {
                return item;
            }
        });

        let subj = this.state.isFilterChanged ? subjects : this.state.assignedSubjects;

        this.setState({
            open: false,
            currentTab: 1
        }, () => {
            this.props.onsearchApply(this.state.search, this.state.sort, subj)
        })

    }

    componentWillReceiveProps(newProps) {
        if (newProps.assignedSubjects != this.props.assignedSubjects) {
            let assignedSubjects = newProps.assignedSubjects ? newProps.assignedSubjects : []
            this.setState({
                assignedSubjects
            }, () => {
                this.props.onsearchApply(this.state.search, this.state.sort, assignedSubjects)
            });
        }
    }


    resetFilter = () => {
        let subjects = this.state.assignedSubjects.map((item) => {
            item.isSelected = false;
            return item;
        });
        this.setState({
            open: false,
            currentTab: 1,
            assignedSubjects: this.props.assignedSubjects,
            sort: '-createdAt',
            search: '',
            isFilterChanged: false
        }, () => {
            this.props.onsearchApply('', '-createdAt', subjects)
        });
    }

    getDisplayName = (subject) => {
        return subject.boardName + '-' + subject.className + '-' + subject.subjectName;
    }


    render() {
        // console.log(this.props, 'search')
        return (
            <div className="descr-search-bar">
                <div className='p-grid'>
                    <Authorizer permId={PERMISSIONS.DISCUSSION_BOARD_SEARCH} >
                        <div className='p-col-11'>
                            <span className="p-input-icon-right ma-w100p">
                                <i className="pi pi-search" />
                                <InputText

                                    placeholder="Search"
                                    value={this.state.search}
                                    onChange={this.onSearch}
                                    className='p-inputtext-style1 ma-w100p'
                                />
                            </span>
                        </div>
                    </Authorizer>
                    <Authorizer permId={PERMISSIONS.DISCUSSION_BOARD_SORT_FILTER} >
                        <div className='p-col-1'>
                            <span className="filter-icon ma-pointer" onClick={(event) => this.menu.toggle(event)}>
                                <FilterIcon width={26} height={26} />
                            </span>
                            {/* <img src={`./images/${this.state.open ? 'filter_selected' : 'filter'}.svg`}
                                //onClick={this.handleToggle}
                                onClick={(event) => this.menu.toggle(event)}
                                className="filter-icon ma-pointer" /> */}
                        </div>
                    </Authorizer>
                </div>

                <Menu

                    style={{ width: "400px" }}
                    model={[
                        {
                            template: (<div className='search-filter'>
                                <div className='p-grid ma-mb10'>
                                    <span className={`sort-title1 ${this.state.currentTab == 1 ? 'tab-active-p1' : ''}`} onClick={() => { this.onTabChange(1) }}>Sort By</span>
                                    <span className={`sort-title1 ${this.state.currentTab == 2 ? 'tab-active-p1  ' : ''}`} onClick={() => { this.onTabChange(2) }}>Filter By</span>
                                </div>
                                {
                                    this.state.currentTab == 1 && <div>
                                        <div className="p-field-radiobutton p-col-12 ">
                                            <RadioButton inputId="sort1" name="sort" value="-createdAt" onChange={this.handleSortChange} checked={this.state.sort === '-createdAt'} />
                                            <label className='filter-label' htmlFor="sort1">New to Old</label>
                                        </div>
                                        <div className="p-field-radiobutton p-col-12">
                                            <RadioButton inputId="sort2" name="sort" value="-trending,-createdAt" onChange={this.handleSortChange} checked={this.state.sort === '-trending,-createdAt'} />
                                            <label className='filter-label' htmlFor="sort2">Trending</label>
                                        </div>
                                        <div className="p-field-radiobutton p-col-12">
                                            <RadioButton inputId="sort3" name="sort" value="createdAt" onChange={this.handleSortChange} checked={this.state.sort === 'createdAt'} />
                                            <label className='filter-label' htmlFor="sort3">Old to New</label>
                                        </div>
                                        <div className="p-field-radiobutton p-col-12">
                                            <RadioButton inputId="sort4" name="sort" value="-likeCount,-createdAt" onChange={this.handleSortChange} checked={this.state.sort === '-likeCount,-createdAt'} />
                                            <label className='filter-label' htmlFor="sort4">Most Likes</label>
                                        </div>
                                        <div className="p-field-radiobutton p-col-12">
                                            <RadioButton inputId="sort5" name="sort" value="-answerCount,-createdAt" onChange={this.handleSortChange} checked={this.state.sort === '-answerCount,-createdAt'} />
                                            <label className='filter-label' htmlFor="sort5">Most Answers</label>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.currentTab == 2 && <>
                                        <div className="assigned-grades ma-scroll-vr-smoke">
                                            {this.state.assignedSubjects && this.state.assignedSubjects.length ? this.state.assignedSubjects.map((subject, index) => {
                                                return <div key={index + 39} className="cnt-lbl p-col-12">
                                                    <Checkbox inputId={"c1" + index} value={subject.subjectId}
                                                        onChange={() => this.handleSelect(subject)}
                                                        checked={subject.isSelected}
                                                    ></Checkbox>
                                                    <label htmlFor={"c1" + index} className="p-checkbox-label">{this.getDisplayName(subject)}</label>
                                                </div>
                                            }) : <p>No Subjects assigned to you</p>}
                                        </div>
                                    </>
                                }

                                <Toolbar className="ma-toolbard ma-mt20" right={() => <><Button label="Reset" className='p-button-outlined' onClick={this.resetFilter} />
                                    <Button label='Done' className='ma-m-lr10'
                                        onClick={this.applyFilter}
                                    /></>}></Toolbar>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        )
    }
}


const mapStatesToProps = (state) => ({
    discussionUser: state.currentUserPerms && state.currentUserPerms.discussionUser && state.currentUserPerms.discussionUser,
    assignedSubjects: state.currentUserPerms && state.currentUserPerms.userInfo && state.currentUserPerms.userInfo.assignedSubjects,
});

export default connect(mapStatesToProps, {})(SearchBar);
