import * as ActionTypes from '../actionTypes';

export const dispatchAddLoading = data => ({
    
    type: ActionTypes.ADD_LOADING,
    data
});


export const dispatchRemoveLoading = (data) => ({
    type: ActionTypes.REMOVE_LOADING,
    data
});


export const dispatchAddSnackBar = (data) => ({
    type: ActionTypes.ADD_SNACKBAR,
    data
});


export const dispatchRemoveSnackBar = (data) => ({
    type: ActionTypes.REMOVE_SNACKBAR,
    data
});


export const addLoading = (data) => dispatch => {
    dispatch(dispatchAddLoading(data));
}
export const removeLoading = (data) => dispatch => {
    dispatch(dispatchRemoveLoading(data));
}
export const addSnackBar = (data) => dispatch => {
    dispatch(dispatchAddSnackBar(data));
}
export const removeSnackBar = (data) => dispatch => {
    dispatch(dispatchRemoveSnackBar(data));
}