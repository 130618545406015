import React, { Component } from 'react';
import { discussionBoardEndpoint } from '../../../store/apiConstants';
import Comment from '../common/comment';
import Service from '../service';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getProfileURL } from './../../../utile';

class CreateComment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: {
                content: (this.props.comment && this.props.comment.content) ? this.props.comment.content : '',
                for: "Answer",
                postId: this.props.postId
            },
            errorMsg: ''
        }
        this.service = new Service();
        this.myRef = React.createRef();
    }

    onCommentChange = (event) => {
        let e = event.currentTarget.value;
        this.setState((prevState) => ({
            comment: {
                ...prevState.comment,
                content: e
            }
        }));
    }

    postComment = () => {

        const url = `${discussionBoardEndpoint}/answers/${this.props.answerId}/comments`
        if (this.state.comment.content == "") {
            this.setState({
                errorMsg: 'Type a comment'
            });
            return;
        } else {
            if (this.props.comment) {
                const url = `${discussionBoardEndpoint}/comments/${this.props.comment._id}`;
                this.service.patch(url, { content: this.state.comment.content }, true).then(data => {
                    if (data && data.status) {
                        this.setState((prevState) => ({
                            comment: {
                                ...prevState.comment,
                                content: '',
                            },
                        }), () => {
                            this.props.onEditComment(data.res.data.doc)
                        });
                    } else {
                        this.setState({ isLoading: false, stopRequests: true, });
                    }
                });
            } else {
                this.service.post(url, this.state.comment, true).then(data => {
                    if (data && data.status) {
                        this.setState((prevState) => ({
                            comment: {
                                ...prevState.comment,
                                content: '',
                            }
                        }), () => {
                            this.props.onCommentPost(data.res.data.doc)
                        });
                    } else {
                        this.setState({ isLoading: false, errorMsg: data.errMessage });
                    }
                });
            }
        }
    }

    render() {
        return (
            <div className="discussion-item disc-comment">
                <div style={{ width: '100%', overflow: 'hidden', marginTop: '10px' }}>
                    <div style={{ width: '40px', float: 'left' }}>
                        <img src={getProfileURL(this.props?.discussionUser ? this.props?.discussionUser?.photo : '')} alt="n" className="profile-img" />
                    </div>
                    <div style={{ marginLeft: '40px' }} className="answer-box">
                        <div style={{ width: '100%', overflow: 'hidden' }}>
                            <div style={{ width: '60px', float: 'right' }}>
                                <span style={{fontWeight:'bold'}}>@</span><span className="post-txt" onClick={this.postComment}>Post</span>
                            </div>
                            <div style={{ marginRight: '60px' }} >
                                <textarea className="answer-txtarea" value={this.state.comment.content} placeholder="Leave your thoughts here...." onChange={this.onCommentChange} rows={2} />
                            </div>
                        </div>

                    </div>
                </div>
                <p className="create-err">{this.state.errorMsg}</p>
            </div>)
    }
}

const mapStateToProps = state => {
    return {
        discussionUser: state.currentUserPerms && state.currentUserPerms.discussionUser && state.currentUserPerms.discussionUser,
    }
}

export default connect(mapStateToProps, {})(withRouter(CreateComment));