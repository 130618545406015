import React, { Component } from 'react'
import { Steps } from 'primereact/steps';
import { Accordion, AccordionTab } from 'primereact/accordion';
import LoadingComponent from '../../../loadingComponent';
import { Dropdown } from 'primereact/dropdown';
import { getFormFields, isFormValid, isFieldValid, onTextChange, isValidMobile, onNumberChange, onDropDownChange, onChoiceChange, onEmailChange, isValidEmail, isValidUrl } from '../../../../utile/formHelper';
import { isDigit, trimObj } from '../../../../utile'
import { InputText } from 'primereact/inputtext';
import CkEditorComponent from '../../../questionBank/common/ckEditor';
import { QuestionTypes } from '../../../questionBank/constants';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import uuidv4 from 'uuid/v4';
import Service from '../../../../services';
import { baseUrlAdmin } from '../../../../store/apiConstants';
import _ from 'lodash';
import { QuestionTypesWithAQ } from '../../../questionBank/constants';
const getSubjectsDrpDwn = () => {
    let subjectsCount = [];

    for (let i = 1; i <= 10; i++) {
        subjectsCount.push({ label: i + ' Subject' + (i > 1 ? 's' : ''), value: i })

    }

    return subjectsCount

}


let templateFields = require('./adhoc.json');



let markingScheme = {
    questionType: '',
    totalMarkedQuestions: '',
    optionalQuestions: '',
    totalQuestions: '',


    positiveMarking: '',
    negativeMarking: '',
    partialMarking: '',
    priority: ''
};

let sectionT = {
    totalQuestions: '',
    markingScheme: []
}


let subjectT = {

    sections: [{ ...sectionT, id: uuidv4(), questionTypes: [] }]
}




export default class CreateTemplate extends Component {
    constructor(props) {
        super(props);

        this.formFields = getFormFields(templateFields, this.props.duplicateTemplate || this.props.editTemplate || {
            subjects: [{ ...subjectT, id: uuidv4() }],

            // ...tempExam
        });

        this.state = {

            currentStep: 0,
            template: this.formFields.data,
            formValidations: this.formFields.formValidations,
            questionTypes: QuestionTypes.map((q) => { return { ...q, isSelected: false } }),
            subjectsCount: this.props.editTemplate ? this.props.editTemplate.subjects.length : 1,
            // template: {
            //     name: '',
            //     description: '',
            //     totalQuestions: '',
            //     totalMarkedQuestions: '',
            //     totalTime: '',
            //     instructions: '',
            //     questionTypes: [],
            //     subjects: [],

            // },
            //  ...prevState
            //  ...require('./_dummyState.json')
        }
        this.service = new Service();

    }

    handleCheckBoxChange = (subjectIndex, id, value) => (event) => {
        let { template, formValidations } = this.state;


        let { subjects } = template;


        let subject = subjects[subjectIndex];
        subject = _.cloneDeep(subject);

        subject.sections = subject.sections.map(s => {
            if (s.id == id) {
                if (event.target.checked) {
                    if (!s.questionTypes.includes(value)) {
                        s.questionTypes.push(value)
                    }
                } else {
                    s.questionTypes.splice(s.questionTypes.indexOf(value), 1)
                }
                let questionTypes = s.questionTypes;
                let markSchema = [];
                questionTypes.forEach((qType) => {
                    markSchema.push({
                        ...markingScheme,
                        questionType: qType
                    })
                });

                s = {
                    ...sectionT, ...s, markingScheme: markSchema
                    //, id: uuidv4()
                };


            }
            return s;
        });


        subjects[subjectIndex] = subject

        template.subjects = subjects;


        this.setState({
            template: template
        })
    };


    isChecked = (id, questionTypes) => {

        return questionTypes.includes(id);
    };

    onSubjectCountChange = (e) => {
        let subjects = []
        for (let i = 1; i <= e.value; i++) {
            // let questionTypes = this.state.template.questionTypes;


            let subjectTemplate = {


                sections: [{ ...sectionT, id: uuidv4(), questionTypes: [] }]


                , id: uuidv4()
            };
            subjects.push(subjectTemplate)
        }
        this.setState((prevState) => {
            return {
                subjectsCount: e.value,
                template: { ...prevState.template, subjects }
            }
        });
    }

    onTotalQuestionChange = (subjectIndex, ind, value) => {
        let template = this.state.template;



        let { subjects } = template;


        let subject = subjects[subjectIndex];
        subject = _.cloneDeep(subject);

        subject.sections = subject.sections.map((s, index) => {
            if (ind == index) {
                if (!isDigit(value.trim())) {
                    s.totalQuestions = value;
                    s.totalQuestionsErr = ''
                } else {
                    s.totalQuestionsErr = 'Only numbers are allowed.'
                }
            }
            return s;
        })


        subjects[subjectIndex] = subject

        template.subjects = subjects;


        this.setState({
            template
        });


    }

    deleteSectionInsubject = (subjectIndex, seId) => {


        let template = this.state.template;

        if (template.subjects[subjectIndex].sections.length > 1) {
            template.subjects[subjectIndex].sections = template.subjects[subjectIndex].sections.filter(s => s.id != seId);
            this.setState({
                template
            });


        } else {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Unable to delete, atleast section is requried', life: 3000 });
        }
    }


    onSectionInstructionChange = (subjectIndex, ind, value, field) => {
        let template = this.state.template;
        let subject = template.subjects[subjectIndex];
        subject = _.cloneDeep(subject);


        subject.sections = subject.sections.map((s, index) => {
            if (ind == index) {
                s[field] = value;
                if (value.trim() != '') {
                    s[field + 'Err'] = ''
                } else {
                    s[field + 'Err'] = 'Instructions are required.'
                }
            }
            return s;
        })

        template.subjects[subjectIndex] = subject


        this.setState({
            template
        });


    }


    onSchemaValueChange = (field, value, questionType, subjectIndex, sectionIndex) => {
        
        let template = this.state.template;
        if (!isDigit(value.trim())) {
            template.subjects[subjectIndex].sections[sectionIndex].markingScheme.forEach((scheme) => {
                if (scheme.questionType == questionType) {
                    scheme[field] = value;
                    scheme[field + 'Err'] = ''

                }
                return scheme;
            })
        } else {
            template.subjects[subjectIndex].sections[sectionIndex].markingScheme.forEach((scheme) => {
                if (scheme.questionType == questionType) {
                    scheme[field + 'Err'] = 'Only numbers are allowed.'
                }
                return scheme;
            })
        }

        this.setState({
            template
        });


    }

    isTemplateAdditionalValid = () => {

        let isError = false;
        let { template, formValidations } = this.state;
        if (template.totalMarkedQuestions > template.totalNoOfQuestions) {
            formValidations.fields.totalMarkedQuestions = {
                ...formValidations.fields.totalMarkedQuestions,
                isValid: false,
                errorMsg: '"Marked questions" should be less than "Number Of Questions"'
            }
        }

        let subjectsTotalQuestionCount = 0;

        template.subjects.forEach((s) => {
            subjectsTotalQuestionCount += s.sections.reduce((acc, curr) => {
                acc += parseInt(curr.totalQuestions, 10);
                return acc;
            }, 0);
        })



        if (!isError) {
            if (parseInt(template.totalNoOfQuestions) != subjectsTotalQuestionCount) {
                formValidations.fields.subjects = {
                    ...formValidations.fields.subjects,
                    isValid: false,
                    errorMsg: `"Total Questions" of all sections of subjects don't match "Total Number Of Questions"`
                }
                isError = true;
            } else {
                formValidations.fields.subjects = {
                    ...formValidations.fields.subjects,
                    isValid: true,
                    errorMsg: ``
                }
            }
        }
        // const questionTypeValues = template.questionType.map((q) => q.value);
        let totalMarkedQuestionsCount = 0;

        template.subjects = template.subjects.map((subject) => {
            subject.sections = subject.sections.map((section) => {


                let markingSchemeCount = 0;
                section.markingScheme.forEach((schema) => {
                    totalMarkedQuestionsCount += parseInt(schema.totalMarkedQuestions);
                    schema.totalQuestions = parseInt(schema.optionalQuestions.length ? schema.optionalQuestions : 0) +
                        parseInt(schema.totalMarkedQuestions.length ? schema.totalMarkedQuestions : 0);
                    markingSchemeCount += schema.totalQuestions
                });

                if (parseInt(section.totalQuestions) != markingSchemeCount) {
                    isError = true
                    section.totalQuestionsErr = 'Sum of all questions types is not same as "Total Questions"'
                } else {
                    section.totalQuestionsErr = ''
                }

                if (!(section.questionTypes && section.questionTypes.length)) {
                    isError = true
                    section.questionTypesErr = 'Question Types are required'
                } else {
                    section.questionTypesErr = ''
                }

                if (!(section.instructions && section.instructions.length)) {
                    isError = true
                    section.instructionsErr = 'Instructions are required'
                } else {
                    section.instructionsErr = ''
                }

                if (!(section.sectionName && section.sectionName.length)) {
                    isError = true
                    section.sectionNameErr = 'Section name is required'
                } else {
                    section.sectionNameErr = ''
                }



                return section
            });

            return subject;
        });

        if (!isError) {
            if (totalMarkedQuestionsCount != parseInt(template.totalMarkedQuestions, 10)) {
                formValidations.fields.subjects = {
                    ...formValidations.fields.subjects,
                    isValid: false,
                    errorMsg: `"Mandatory" count of all sections of all subjects doesn't match "Total Marked Questions"`
                }
                isError = true;
            } else {
                formValidations.fields.subjects = {
                    ...formValidations.fields.subjects,
                    isValid: true,
                    errorMsg: ``
                }
            }

        }

        this.setState({
            formValidations
        })

        return isError;
    }

    markingValid = () => {
        let { template } = this.state;

        let isError = false;
        template.subjects = template.subjects.map((subject) => {
            subject.sections = subject.sections.map((section) => {
                section.markingScheme.forEach((schema) => {
                    if (!(schema.positiveMarking.trim().length)) {
                        isError = true;
                        schema.positiveMarkingErr = 'Required'
                    } else {
                        schema.positiveMarkingErr = ''
                    }

                    if (!(schema.negativeMarking.trim().length)) {
                        isError = true;
                        schema.negativeMarkingErr = 'Required'
                    } else {
                        schema.negativeMarkingErr = ''
                    }

                    if (!(schema.partialMarking.trim().length)) {
                        isError = true;
                        schema.partialMarkingErr = 'Required'
                    } else {
                        schema.partialMarkingErr = ''
                    }
                });
                return section
            });
            return subject
        });


        this.setState({
            template
        })

        return isError;

    }

    processTemplateSaveData = () => {

        let { template } = this.state;
        let saveTemplate = _.cloneDeep(template);
        let totalMarks = 0;
        saveTemplate.subjects.forEach((subject, index) => {
            subject.sections.forEach((item, index) => {
                item.markingScheme.forEach((schemaItem, markIndex) => {
                    if (schemaItem) {
                        totalMarks += schemaItem.positiveMarking * schemaItem.totalMarkedQuestions;
                    }
                    schemaItem.totalSelectedQuestions = 0;
                    delete schemaItem.totalMarkedQuestionsErr;
                    delete schemaItem.optionalQuestionsErr;
                    delete schemaItem.positiveMarkingErr;
                    delete schemaItem.negativeMarkingErr;
                    delete schemaItem.partialMarkingErr;
                });
                delete item.totalQuestionsErr;
                delete item.sectionNameErr;
                delete item.instructionsErr;
                delete item.questionTypesErr;



                item.priority = index + 1;
                item.questions = [];

            });

            subject.subjectId = '';
            subject.subjectName = '';
            subject.priority = index + 1;
            subject.questions = [];

        });

        saveTemplate.totalMarks = totalMarks;
        saveTemplate.examType = 'AdhocMockTestT2';

        return saveTemplate;
    }


    onSubmitClick = () => {

        const { currentStep, template } = this.state;


        if (currentStep == 0) {
            const formStatus = isFormValid(templateFields, this.formFields.formValidations, trimObj(this.state.template));
            if (!formStatus.formValidations.isFormValid || (this.isTemplateAdditionalValid())) {
                this.setState({
                    isSubmitClick: true,
                    formValidations: formStatus.formValidations,
                });
            } else {
                this.setState({
                    currentStep: 1
                });
            }
        } else if (currentStep == 1) {
            if (!this.markingValid()) {
                let saveTempateData = this.processTemplateSaveData();
                if (this.props.editTemplate) {
                    this.setState({
                        isLoading: true
                    })

                    let url = `${baseUrlAdmin}/examtemplate/updatetemplate`;
                    this.service.put(url, saveTempateData, true).then(res => {

                        if (res && res.res && res.res.success) {
                            this.setState({
                                isLoading: false
                            }, () => { this.props.onCreateOrEditSuccess(true) });
                        } else {
                            this.setState({
                                isLoading: false
                            });
                            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                        }
                    }).catch((e) => {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                    });
                } else {
                    this.setState({
                        isLoading: true
                    })

                    let url = `${baseUrlAdmin}/examtemplate/savetemplate`;
                    this.service.post(url, saveTempateData, true).then(res => {

                        if (res && res.res && res.res.success) {
                            this.setState({
                                isLoading: false
                            }, () => { this.props.onCreateOrEditSuccess() });
                        } else {
                            this.setState({
                                isLoading: false
                            });
                            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                        }
                    }).catch((e) => {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                    });
                }
            }
        }
    }


    goBackOrCancel = () => {
        if (this.state.currentStep == 0) {
            this.props.onCreateCancel()
        } else {
            this.setState({
                currentStep: 0
            })
        }
    }

    addSection = (ind) => {

        let template = this.state.template;

        template.subjects = template.subjects.map((s, index) => {
            if (ind == index) {
                s.sections.push({ ...sectionT, id: uuidv4(), questionTypes: [] })
            }
            return s;
        })

        this.setState({
            template
        });



    }

    render() {
        console.log(this.state, 'state')
        const { currentStep, template, formValidations, questionTypes } = this.state;
        return (
            <>
                {/* <p className='back-arrow' onClick={this.props.onCreateCancel}><span><i className="pi pi-arrow-left"></i> </span> Back</p> */}
                <div className="ma-box-style2 create-org-w">
                    <span className='create-org-title'>{this.props.editTemplate ? 'Edit' : 'Create'} Template</span>
                    <Steps
                        className='org-steps'
                        model={[

                            { label: 'Basic Info', className: 'start-1' },
                            // { label: 'Curriculums' },
                            { label: 'Marks Schema', className: 'end-1' }
                        ]} activeIndex={currentStep}
                        readOnly={false}
                    />
                    <div className='ma-clearFix'>

                    </div>
                    {currentStep == 0 && <>
                        {/* <p className='craete-org-subtitle'>Exam Details</p>
                        <hr className="ma-hr-light" /> */}
                        <div className="p-grid ma-w100p"  >
                            <div className='p-col-3 p-md-3'>
                                <p className='ma-label-s2'>Name of the Template<span className='ma-required'>*</span></p>
                                <InputText value={template.name}
                                    onChange={(e) => { onTextChange(e.target.value, 'name', this, templateFields, template, formValidations, 'template', 'formValidations') }}
                                    className='p-inputtext-style1 ma-w100p' />
                                {formValidations && !formValidations.fields['name'].isValid && <p className="p-error">{formValidations.fields['name'].errorMsg}</p>}
                            </div>
                            <div className='p-col-3 p-md-3'>
                                <p className='ma-label-s2'>Number of Questions<span className='ma-required'>*</span></p>
                                <InputText value={template.totalNoOfQuestions}

                                    onChange={(e) => { onNumberChange(e.target.value, 'totalNoOfQuestions', this, templateFields, template, formValidations, 'template', 'formValidations') }}
                                    className='p-inputtext-style1 ma-w100p' />
                                {formValidations && !formValidations.fields['totalNoOfQuestions'].isValid && <p className="p-error">{formValidations.fields['totalNoOfQuestions'].errorMsg}</p>}
                            </div>
                            <div className='p-col-3 p-md-3'>
                                <p className='ma-label-s2'>Marked Questions<span className='ma-required'>*</span></p>
                                <InputText value={template.totalMarkedQuestions}

                                    onChange={(e) => { onNumberChange(e.target.value, 'totalMarkedQuestions', this, templateFields, template, formValidations, 'template', 'formValidations') }}
                                    className='p-inputtext-style1 ma-w100p' />
                                {formValidations && !formValidations.fields['totalMarkedQuestions'].isValid && <p className="p-error">{formValidations.fields['totalMarkedQuestions'].errorMsg}</p>}
                            </div>
                            <div className='p-col-3 p-md-3'>
                                <p className='ma-label-s2'>Total Time(In minutes)<span className='ma-required'>*</span></p>
                                <InputText value={template.totalTime}

                                    onChange={(e) => { onNumberChange(e.target.value, 'totalTime', this, templateFields, template, formValidations, 'template', 'formValidations') }}
                                    className='p-inputtext-style1 ma-w100p' />
                                {formValidations && !formValidations.fields['totalTime'].isValid && <p className="p-error">{formValidations.fields['totalTime'].errorMsg}</p>}
                            </div>
                        </div>
                        <div className="p-grid ma-w100p"  >
                            <div className='p-md-6'>
                                <p className='ma-label-s2'>Template Description<span className='ma-required'>*</span></p>
                                <CkEditorComponent
                                    value={template.description}
                                    onChange={(value) => { onTextChange(value, 'description', this, templateFields, template, formValidations, 'template', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['description'].isValid && <p className="p-error">{formValidations.fields['description'].errorMsg}</p>}
                            </div>
                            <div className='p-md-6'>
                                <p className='ma-label-s2'>Exam Instructions<span className='ma-required'>*</span></p>
                                <CkEditorComponent
                                    value={template.instructions}
                                    onChange={(value) => { onTextChange(value, 'instructions', this, templateFields, template, formValidations, 'template', 'formValidations') }}
                                />
                                {formValidations && !formValidations.fields['instructions'].isValid && <p className="p-error">{formValidations.fields['instructions'].errorMsg}</p>}
                            </div>
                        </div>

                        <div className="p-grid ma-w100p"  >
                            <div className='p-md-12'>
                                <p className='ma-label-s2'>Total Subjects<span className='ma-required'>*</span></p>
                                <div className="p-grid ma-w100p ma-m0"  >
                                    <Dropdown value={this.state.subjectsCount}

                                        options={getSubjectsDrpDwn()}
                                        className='ma-w200 ma-mt10'
                                        onChange={this.onSubjectCountChange}
                                        placeholder="Select Exam Count" />

                                </div>
                            </div>
                        </div>
                        <div className="p-grid ma-w100p"  >
                            <div className='p-md-12'>
                                <p className='ma-label-s2'>Subjects<span className='ma-required'>*</span></p>
                                <Accordion className=''>
                                    {
                                        template.subjects.map((subject, subjectIndex) => {
                                            return <AccordionTab key={subject.id} id={subject.id} header={`Subject ${subjectIndex + 1}`}>

                                                <h2>Sections</h2>
                                                {
                                                    subject.sections.map((section, sectionIndex) => {
                                                        return <div className='ma-ml1 sub-sections' key={'s' + sectionIndex}>
                                                            <h3>Section {sectionIndex + 1} <span onClick={() => { this.deleteSectionInsubject(subjectIndex, section.id) }} className='ma-right ma-pointer'> <i className='pi pi-times' /> </span></h3>
                                                            <p className='ma-label-s2'>Section Name<span className='ma-required'>*</span></p>
                                                            <InputText value={section.sectionName}

                                                                onChange={(e) => { this.onSectionInstructionChange(subjectIndex, sectionIndex, e.target.value, 'sectionName') }}
                                                                className='p-inputtext-style1 ma-w200' />
                                                            {section.sectionNameErr && <p className="p-error">{section.sectionNameErr}</p>}

                                                            <p className='ma-label-s2'>Total Questions<span className='ma-required'>*</span></p>
                                                            <InputText value={section.totalQuestions}

                                                                onChange={(e) => { this.onTotalQuestionChange(subjectIndex, sectionIndex, e.target.value) }}
                                                                className='p-inputtext-style1 ma-w200' />
                                                            {section.totalQuestionsErr && <p className="p-error">{section.totalQuestionsErr}</p>}
                                                            <div className="p-grid ma-w100p"  >
                                                                <div className='p-md-6'>
                                                                    <p className='ma-label-s2 ma-mt10'>Instructions<span className='ma-required'>*</span></p>
                                                                    <CkEditorComponent
                                                                        value={section.instructions}
                                                                        onChange={(value) => { this.onSectionInstructionChange(subjectIndex, sectionIndex, value, 'instructions') }}
                                                                    />
                                                                    {section.instructionsErr && <p className="p-error">{section.instructionsErr}</p>}
                                                                </div>
                                                            </div>
                                                            <p className='ma-label-s2 ma-mt10'>Question Types<span className='ma-required'>*</span></p>
                                                            <div className="p-grid ma-w100p ma-m0"  >
                                                                {QuestionTypesWithAQ.map((item, index) => (
                                                                    <div id={item.value + 's' + index} key={`check-${item.value + 's' + index}`}>
                                                                        <Checkbox inputId={item.value + 's' + index}
                                                                            checked={this.isChecked(item.value, section.questionTypes)}
                                                                            value={item.value}
                                                                            onChange={this.handleCheckBoxChange(subjectIndex, section.id, item.value)}
                                                                        ></Checkbox>
                                                                        <label htmlFor={item.value + 's' + index} className="p-checkbox-label p-name">{item.label}</label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {section.questionTypesErr && <p className="p-error">{section.questionTypesErr}</p>}
                                                            <div className='ma-mt20'>
                                                                {
                                                                    section.markingScheme.map((scheme, index) => {
                                                                        return <div className="p-grid ma-w100p" key={'a' + index}  >
                                                                            <div className='p-md-4'>
                                                                                <p  >{scheme.questionType}</p>
                                                                            </div>
                                                                            <div className='p-md-4'><span className=''>Mandatory</span><span className='ma-required ma-mr10'>*</span>
                                                                                <InputText value={scheme.totalMarkedQuestions}

                                                                                    onChange={(e) => { this.onSchemaValueChange('totalMarkedQuestions', e.target.value, scheme.questionType, subjectIndex, sectionIndex) }}
                                                                                    className='p-inputtext-style1 ma-w200' />
                                                                                {scheme.totalMarkedQuestionsErr && <p className="p-error">{scheme.totalMarkedQuestionsErr}</p>}
                                                                            </div>
                                                                            <div className='p-md-4'>
                                                                                <span className=''>Optional</span><span className='ma-required ma-mr10'>*</span>
                                                                                <InputText value={scheme.optionalQuestions}

                                                                                    onChange={(e) => { this.onSchemaValueChange('optionalQuestions', e.target.value, scheme.questionType, subjectIndex, sectionIndex) }}
                                                                                    className='p-inputtext-style1 ma-w200' />
                                                                                {scheme.optionalQuestionsErr && <p className="p-error">{scheme.optionalQuestionsErr}</p>}
                                                                            </div>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>

                                                        </div>
                                                    })
                                                }

                                                <Button className='ma-mt20' label='' icon="pi pi-plus" onClick={() => { this.addSection(subjectIndex, subject) }} />

                                            </AccordionTab>
                                        })
                                    }

                                </Accordion>
                                {formValidations && !formValidations.fields['subjects'].isValid && <p className="p-error">{formValidations.fields['subjects'].errorMsg}</p>}
                            </div>
                        </div>
                    </>}
                    {currentStep == 1 && <>
                        <div className="p-grid ma-w100p"  >
                            <div className='p-md-12'>
                                {/* <Button icon="pi pi-arrow-left" className="p-button-rounded p-button-secondary" onClick={() => { this.setState({ currentStep: 0 }) }} /> */}
                                <Accordion className='ma-m16'>
                                    {
                                        template.subjects.map((subject, subjectIndex) => {
                                            return <AccordionTab key={subject.id} id={subject.id} header={`Subject ${subjectIndex + 1}`}>
                                                <h2>Sections</h2>
                                                {
                                                    subject.sections.map((section, sectionIndex) => {
                                                        return <div className='ma-ml1 sub-sections2' key={'s' + sectionIndex}>
                                                            <>
                                                                {
                                                                    section.markingScheme.map((scheme, index) => {
                                                                        return <div className="p-grid ma-w100p"  >
                                                                            <div className='p-md-3'>
                                                                                <p  >{scheme.questionType}</p>
                                                                            </div>
                                                                            <div className='p-md-3'>
                                                                                <span className=''>Positive</span><span className='ma-required ma-mr10'>*</span>
                                                                                <InputText value={scheme.positiveMarking}

                                                                                    onChange={(e) => { this.onSchemaValueChange('positiveMarking', e.target.value, scheme.questionType, subjectIndex, sectionIndex) }}
                                                                                    className='p-inputtext-style1 ma-w200' />
                                                                                {scheme.positiveMarkingErr && <p className="p-error">{scheme.positiveMarkingErr}</p>}
                                                                            </div>
                                                                            <div className='p-md-3'>
                                                                                <span className=''>Negative</span><span className='ma-required ma-mr10'>*</span>
                                                                                <InputText value={scheme.negativeMarking}

                                                                                    onChange={(e) => { this.onSchemaValueChange('negativeMarking', e.target.value, scheme.questionType, subjectIndex, sectionIndex) }}
                                                                                    className='p-inputtext-style1 ma-w200' />
                                                                                {scheme.negativeMarkingErr && <p className="p-error">{scheme.negativeMarkingErr}</p>}
                                                                            </div>
                                                                            <div className='p-md-3'>
                                                                                <span className=''>Partial</span><span className='ma-required ma-mr10'>*</span>
                                                                                <InputText value={scheme.partialMarking}

                                                                                    onChange={(e) => { this.onSchemaValueChange('partialMarking', e.target.value, scheme.questionType, subjectIndex, sectionIndex) }}
                                                                                    className='p-inputtext-style1 ma-w200' />
                                                                                {scheme.partialMarkingErr && <p className="p-error">{scheme.partialMarkingErr}</p>}
                                                                            </div>
                                                                        </div>
                                                                    })
                                                                }

                                                            </>
                                                        </div>
                                                    })
                                                }
                                            </AccordionTab>
                                        })
                                    }

                                </Accordion>
                                {formValidations && !formValidations.fields['subjects'].isValid && <p className="p-error">{formValidations.fields['subjects'].errorMsg}</p>}
                            </div>
                        </div>
                    </>}
                </div>

                <Toolbar className="ma-toolbar"
                    right={() => { return (<><Button label={`${this.state.currentStep == 0 ? 'Cancel' : 'Previous'}`} className='p-button-outlined' onClick={() => { this.goBackOrCancel() }} /> <Button label={this.state.currentStep == 2 ? `Submit` : 'Save & Proceed'} className='ma-m-lr10' onClick={this.onSubmitClick} /></>) }}></Toolbar>
                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}



