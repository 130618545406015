import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import XLSX from 'xlsx';
import Authentication from './../session';
import Service from '../../services';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../store/apiConstants';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Toolbar } from 'primereact/toolbar';
import Navbar from './../navbar/index';
import { Toast } from 'primereact/toast';
import { BreadCrumb } from 'primereact/breadcrumb';
import {
    getBoardsData,
} from './../../store/actions';
import './styles.scss'
import LoadingComponent from '../loadingComponent';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import SearchBar from './searchBar';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import moment from 'moment';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { exportToCSV, getServerTime } from '../../utile';

const items = [
    { label: 'Attendance' },
    { label: 'Bulk Attendance', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }


/* list of supported file types */
const SheetJSFT = ['xlsx', 'xlsb', 'xlsm', 'xls', 'csv']
    .map(function (x) {
        return '.' + x;
    })
    .join(',');

/* generate an array of column objects */
const make_cols = (refstr) => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
};

const AbsentReasons = [
    {
        label: 'R1',
        value: 'R1'
    },
    {
        label: 'R2',
        value: 'R2'
    },
]

class BulkAttendance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            groups: [],
            curriculumInfo: {},
            isShowBoardCreate: false,
            editBoardData: null,
            isBoardAdd: true,
            isShowBoards: true,
            isShowClasses: false,
            editChapterData: null,
            isShowExportToOffline: false,
            globalSearch: '',
            users: [],
            columns: [{ columnName: 'Board Id', isVisible: false, id: 'boardId' }],
            date: getServerTime()

        }
        this.service = new Service();
    }




    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }


        //exportToCSV();
    }







    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }


    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Attendance</h2>
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    downLoadAttendance = (items) => {
        let { filterData } = this.state;
        let { sectionName } = filterData;

        let headers = {
            user: 'User Id',
            username: 'User Name',
            given_name: 'Given Name',
            sectionId: "Section Id",
            sectionName: "Section Name",
            morning: "Morning",
            afternoon: "Afternoon",
            informed: "Informed",
        }

        items = items.map(a => {
            return {
                user: a.user,
                username: a.username.substring(3),
                given_name: a.given_name,
                sectionId: a.sectionId,
                sectionName: sectionName ? sectionName : '',
                morning: a.morning == true ? 'Y' : 'N',
                afternoon: a.afternoon == true ? 'Y' : 'N',
                informed: a.reason == '' ? '' : (a.reason == 'Informed' ? 'Y' : 'N')
            }
        });

        exportToCSV(items, headers, 'bulksheet-' + moment(this.state.filterData.date).format('YYYY-MM-DD'));

    }
    downLoadAttendanceStatus = (items) => {

        let headers = {
            user: 'User Id',
            username: 'User Name',
            given_name: 'Given Name',
            sectionId: "Section Id",
            morning: "Morning",
            afternoon: "Afternoon",
            informed: "Informed",
            message: 'Error Message',
            status: "Status"
        }

        items = items.map(a => {
            return {
                user: a.user,
                username: a.username,
                given_name: a.given_name,
                sectionId: a.sectionId,
                morning: a.morning == true ? 'Y' : 'N',
                afternoon: a.afternoon == true ? 'Y' : 'N',
                informed: a.reason == '' ? '' : (a.reason == 'Informed' ? 'Y' : 'N'),
                message: a.message,
                status: a.status

            }
        });
        
     //   console.log('aaaaa',)
        exportToCSV(items, headers, 'bulksheet-status' + moment().format('YYYY-MM-DD'));

    }


    getStudentsAttendance = () => {
        // return

        this.setState({
            isLoading: true,
            users: []
        })


        let { filterData } = this.state;
        const url = `${baseUrlForLongRunningTask}/attendance/get?limit=100000&page=1`;
        this.service.post(url, { ...filterData, date: moment(filterData.date).format('YYYY-MM-DD') }, true).then(res => {
            
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    // attendance: res.res.data,
                    // totalRecords: res.res.totalRecords
                });
                if (res.res.data && res.res.data.length) {
                    this.downLoadAttendance(res.res.data)
                }

            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            console.log(e);
        })
    }

    onSearchClick = (filterData) => {

      //  console.log(filterData);

        this.setState({
            filterData
        }, this.getStudentsAttendance)



    }




    timingsTemplate = (rowData, { rowIndex }) => {
        return <div>
            <div>
                <Checkbox inputId={'a' + rowIndex}
                    checked={rowData.morning}
                    onChange={this.handleCheckBoxChange(rowIndex, 'morning')}
                ></Checkbox>
                <label htmlFor={'a' + rowIndex} className="p-checkbox-label p-name">Morning</label>
            </div>
            <div className='ma-mt5'>
                <Checkbox inputId={'ab' + rowIndex}
                    checked={rowData.afternoon}
                    onChange={this.handleCheckBoxChange(rowIndex, 'afternoon')}
                ></Checkbox>
                <label htmlFor={'ab' + rowIndex} className="p-checkbox-label p-name">Afternoon</label>
            </div>
            <div>
                <p className='ma-label-s2'>Resource Type<span className='ma-required'>*</span></p>
                <Dropdown value={rowData.reason}
                    options={AbsentReasons}
                    className=' ma-w200'
                    onChange={this.handleReasonChange(rowIndex, 'reason')}
                    placeholder="Select FileType" />
            </div>
        </div>

    }

    saveAttendance = () => {

        
        this.setState({
            isLoading: true,
            fileSelected: '',
            users: []
        })



        let { users } = this.state;
        const url = `${baseUrlForLongRunningTask}/attendance/bulkupload`;
        this.service.post(url, { users, date: moment().format('YYYY-MM-DD') }, true).then(res => {
            
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                });
                if (res.res.data && res.res.data.length) {
                    this.downLoadAttendanceStatus(res.res.data)
                }

                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Successfully saved.', life: 3000 });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })


    }


    handleFile = (file) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        let data = [];
        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            data = XLSX.utils.sheet_to_json(ws, { raw: false });
            this.setState({ data: data, cols: make_cols(ws['!ref']) });

            const newJsonSheet = [];
            for (let i = 0; i < data.length; i++) {
                let excelRowData = {
                    ...data[i],
                    __rowNum__: undefined
                };

                excelRowData = JSON.parse(JSON.stringify(excelRowData));

                newJsonSheet.push(excelRowData);
            }

            function getInformedTxt(informed) {
                let txt = '';

                if (informed && informed.length) {
                    if (informed.toUpperCase() == 'Y') {
                        txt = 'Informed';
                    }

                    if (informed.toUpperCase() == 'N') {
                        txt = 'UnInformed';
                    }
                }
                return txt;
            }
            function getTrueOrFalse(informed) {
                let txt = false;

                if (informed && informed.length) {
                    if (informed.toUpperCase() == 'Y') {
                        txt = true;
                    }

                    if (informed.toUpperCase() == 'N') {
                        txt = false;
                    }
                }
                return txt;
            }
           

            let users = newJsonSheet.map(a => {
                return {
                    user: a['User Id'],
                    username: a['User Name'],
                    given_name: a['Given Name'],
                    sectionId: a["Section Id"],
                    morning: getTrueOrFalse(a["Morning"]),
                    afternoon: getTrueOrFalse(a["Afternoon"]),
                    reason: getInformedTxt(a["Informed"])
                }

            })

            
            this.setState({ users: users });
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    };

    handleChange = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            this.setState({ fileSelected: files[0]['name'] });
            this.handleFile(files[0]);
            e.target.value = null;
        }
    };

    render() {

        return (
            < >
                <Navbar >
                    <div className="p-grid ma-toolbar">
                        <div className="p-col-12 p-md-1 ma-no-p">
                            {/* {(this.state.isShowClasses) && <>
                                <p className='back-arrow' onClick={this.onGoBack}><span><i className="pi pi-arrow-left"></i> </span> Back</p>
                            </>
                            } */}
                        </div>
                        <div className="p-col-12 p-md-11 ma-no-pm">
                            <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        </div>
                    </div>
                    <div className='ma-main'>
                        <SearchBar searchLabel="Download Sheet" filterData={this.state.filterData} filterUsersData={this.onSearchClick} />


                    </div>

                    <div className="bulk-attendace-u bulk-upload ma-mt30 ma-main ">
                        <p className='spread-title'>Users Spreadsheet</p>
                        <InputText
                            className='p-inputtext-style1'
                            value={this.state.fileSelected} disabled />
                        {/* <span className='file-attach-name'>{this.state.fileSelected} </span> */}
                        <label htmlFor="file" className='select-file'>Select File</label>
                        <input
                            type="file"
                            className="form-control"
                            id="file"
                            hidden={true}
                            accept={SheetJSFT}
                            onChange={this.handleChange}
                        //  disabled={this.props.bulkUserUploadRequested}
                        />
                        <div className='ma-clearFix'></div>

                        <Button label="Upload data" className='ma-mt10'
                            //  disabled={this.props.bulkUserUploadRequested}
                            onClick={() => {
                                if (this.state.users && this.state.users.length) {
                                    this.saveAttendance();
                                }
                            }}
                            icon="pi pi-cloud-upload"
                        />
                    </div>


                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                    {
                        (this.props.isLoading || this.state.isLoading) && <>
                            <LoadingComponent />
                        </>
                    }
                </Navbar>
            </>
        )
    }
}



const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,

});


export default connect(mapStateToProps, {
    getBoardsData
})(Authentication(withRouter(BulkAttendance)));

//Authentication