import * as ActionType from "../actionTypes";
import axios from 'axios';
import {
    baseUrlAdmin,
    baseUrlForLongRunningTask
} from "../apiConstants";
import {
    getAllExamIdNameList,
    examResultReport1,
    examResultReport2
} from "./mockData";
import { dispatchAddLoading, dispatchRemoveLoading , dispatchAddSnackBar, } from './commonActions';


const dispatchRequestGetSingleExamDetails = (data) => ({
    type: ActionType.REQUEST_GET_EXAMINATION_DETAILS,
    data,
});

const dispatchReceivedGetSingleExamDetails = (res) => ({
    type: ActionType.RECEIVED_GET_EXAMINATION_DETAILS,
    res
});

const dispatchErrorGetSingleExamDetails = (err) => ({
    type: ActionType.ERROR_GET_EXAMINATION_DETAILS,
    err
});

const dispatchClearGetSingleExamDetails = () => ({
    type: ActionType.CLEAR_GET_EXAMINATION_DETAILS
});

const dispatchRequestGetAllExamsIdNameList = () => ({
    type: ActionType.REQUEST_GET_EXAMS_ID_NAME_LIST,
});

const dispatchReceivedGetAllExamsIdNameList = (res) => ({
    type: ActionType.RECEIVED_GET_EXAMS_ID_NAME_LIST,
    res
});

const dispatchErrorGetAllExamsIdNameList = (err) => ({
    type: ActionType.ERROR_GET_EXAMS_ID_NAME_LIST,
    err
});

const dispatchClearGetAllExamsIdNameList = () => ({
    type: ActionType.CLEAR_GET_EXAMS_ID_NAME_LIST
});

const dispatchRequestExamResultReport = () => ({
    type: ActionType.REQUEST_GET_EXAM_RESULT_REPORT,
});

const dispatchReceivedExamResultReport = (res) => ({
    type: ActionType.RECEIVED_GET_EXAM_RESULT_REPORT,
    res
});

const dispatchErrorExamResultReport = (err) => ({
    type: ActionType.ERROR_GET_EXAM_RESULT_REPORT,
    err: err && err.data && err.data.errorResponse && err.data.errorResponse[0] && err.data.errorResponse[0].message
});

const dispatchClearExamResultReport = () => ({
    type: ActionType.CLEAR_GET_EXAM_RESULT_REPORT
});

const dispatchRequestExamResult = () => ({
    type: ActionType.REQUEST_GET_EXAM_RESULT,
});

const dispatchReceivedExamResult = (res) => ({
    type: ActionType.RECEIVED_GET_EXAM_RESULT,
    res
});

const dispatchErrorExamResult = (err) => ({
    type: ActionType.ERROR_GET_EXAM_RESULT,
    err: err && err.data && err.data.errorResponse && err.data.errorResponse[0] && err.data.errorResponse[0].message
});

const dispatchClearExamResult = () => ({
    type: ActionType.CLEAR_GET_EXAM_RESULT
});



const dispatchRequestGetSingleExamsByFilter = (data) => ({
    type: ActionType.REQUEST_GET_EXAMINATION_BY_FILTER,
    data,
});

const dispatchReceivedGetSingleExamsByFilter = (res) => ({
    type: ActionType.RECEIVED_GET_EXAMINATION_BY_FILTER,
    res
});

const dispatchErrorGetSingleExamsByFilter = (err) => ({
    type: ActionType.ERROR_GET_EXAMINATION_BY_FILTER,
    err
});

const dispatchClearGetSingleExamsByFilter = () => ({
    type: ActionType.CLEAR_GET_EXAMINATION_BY_FILTER
});





export const getAllExamDetails = (examType) => dispatch => {
    dispatch(dispatchRequestGetSingleExamDetails(examType));
    axios({
        method: 'POST',
        url: `${baseUrlAdmin}/examination/filterby`,
        data: {
            examType,
        }

    }).then((res) => {
        dispatch(dispatchReceivedGetSingleExamDetails(res.data));
    }).catch(e => {
        dispatch(dispatchErrorGetSingleExamDetails(e));
    });
}

export const clearGetAllExamDetails = () => dispatch => {
    dispatch(dispatchClearGetSingleExamDetails());
}

export const getAllExamsIdNameList = (examType) => dispatch => {
    dispatch(dispatchRequestGetAllExamsIdNameList());
    axios({
        method: 'GET',
        url: `${baseUrlAdmin}/examination/getallexams/examtype/${examType}`,
    }).then((res) => {
        dispatch(dispatchReceivedGetAllExamsIdNameList(res.data));
    }).catch(e => {
        dispatch(dispatchErrorGetAllExamsIdNameList(e));
    });
}

export const clearGetAllExamsIdNameList = () => dispatch => {
    dispatch(dispatchClearGetAllExamsIdNameList());
}

export const getExamResultReport = (examId, type) => dispatch => {
    dispatch(dispatchRequestExamResultReport());
    let url = `${baseUrlForLongRunningTask}/results/get-all/test/${examId}/results`;
    if (type === 2) {
        url = `${baseUrlForLongRunningTask}/results/get/test/${examId}/marking`
    } else if (type === 3) {
        url = `${baseUrlForLongRunningTask}/results/get/test/${examId}/report3`
    }
    axios({
        method: 'GET',
        url,
    }).then((res) => {
        dispatch(dispatchReceivedExamResultReport(res.data));
    }).catch(e => {
        dispatch(dispatchErrorExamResultReport(e.response));
    });
}

export const clearExamResultReport = () => dispatch => {
    dispatch(dispatchClearExamResultReport());
}

export const getExamResult = (examresultId) => dispatch => {
    dispatch(dispatchRequestExamResult());
    let url = `${baseUrlAdmin}/results/examresult/${examresultId}`;
    axios({
        method: 'GET',
        url,
    }).then((res) => {
        dispatch(dispatchReceivedExamResult(res.data));
    }).catch(e => {
        dispatch(dispatchErrorExamResult(e.response));
    });
}


export const exportExamResult = (examId, examType) => dispatch => {
    // dispatch(dispatchRequestDeleteExamination({
    //     examId,
    //     examType
    // }));
    
    axios({
        method: 'GET',
        url: `${baseUrlForLongRunningTask}/results/get/test/examId/${examId}/examType/${examType}/export`,

    }).then((res) => {

        //  dispatch(dispatchReceivedDeleteExamination(res));
    }).catch(e => {
        // dispatch(dispatchErrorDeleteExamination(e));
    });
}




export const clearExamResult = () => dispatch => {
    dispatch(dispatchClearExamResult());
}




export const clearExamsByFilterResult = () => dispatch => {
    dispatch(dispatchClearGetSingleExamsByFilter());
}



export const getAllExamsByFilter = (filter) => dispatch => {
   

    //dispatch(dispatchRequestGetSingleExamsByFilter(''));

    dispatch(dispatchAddLoading('getAllExamsByFilter'));
    axios({
        method: 'POST',
        url: `${baseUrlAdmin}/examination/getexamsbyfilter`,
        data: filter

    }).then((res) => {
        dispatch(dispatchReceivedGetSingleExamsByFilter(res.data));
        dispatch(dispatchRemoveLoading('getAllExamsByFilter'));
    }).catch(e => {
        dispatch(dispatchErrorGetSingleExamsByFilter(e));
        dispatch(dispatchRemoveLoading('getAllExamsByFilter'));
    });
}