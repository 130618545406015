import { Dialog } from 'primereact/dialog'
import React, { Component } from 'react'

export default class PreViewDetials extends Component {
    render() {
        const { viewRouteDetails } = this.props
        console.log(viewRouteDetails)
        return (
            <>
                <Dialog
                    visible={this.props.previewDetails}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={true}
                    style={{ maxWidth: '25%', minWidth: '25%' }}
                    footer={this.footer}
                    header='Route Details'
                    onHide={this.props.onClosePreview}
                >
                    <div className="p-grid subject-add-dialog">
                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Route Name</b></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-6 ma-mt2">
                            <p className=''> :{viewRouteDetails.routeName}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Vehicle Number</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6 ma-mt2">
                            <p className=''> :{viewRouteDetails.vehicle}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Incharge Name</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6 ma-mt2">
                            <p className=''> :{viewRouteDetails.incharge}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Incharge Mobile Number</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6 ma-mt2">
                            <p className=''> :{viewRouteDetails.inchargeMobileNumber}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Driver Name</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6 ma-mt2">
                            <p className=''> :{viewRouteDetails.driver}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Driver Mobile Number</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6 ma-mt2">
                            <p className=''> :{viewRouteDetails.driverMobileNumber}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Cleaner Name</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6 ma-mt2">
                            <p className=''> :{viewRouteDetails.cleaner}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>Cleaner Mobile Number</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6 ma-mt2">
                            <p className=''> :{viewRouteDetails.cleanerMobileNumber}</p>
                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>No. of Stops</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6 ma-mt2">
                            <p className=''> :{viewRouteDetails.stops}</p>

                        </div>

                        <div className="p-col-4 p-md-4 p-lg-6">
                            <p className='text-label p-mt-1'><b>No. of Studnets</b></p>
                        </div>

                        <div className="p-col-8 p-md-8 p-lg-6 ma-mt2">
                            <p className=''> :{viewRouteDetails.admissions}</p>
                        </div>
                    </div>
                </Dialog>
            </>
        )
    }
}
