import React, { Component } from 'react'
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import _ from 'lodash';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import Navbar from '../navbar';
import { Toast } from 'primereact/toast';
import { BreadCrumb } from 'primereact/breadcrumb';
import Authentication from './../session';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import CreateEnquiry from './createEnquiry';
import './styles.scss';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import LoadingComponent from '../loadingComponent';
import EnquirySearch from './enquirySearch';
//import Receipt from './receipt';
import { I18Next } from './../../internationalization';

const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: '',
    sortField: null,
    sortOrder: null,
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'title': { value: '', matchMode: 'contains' },
        'subDomain': { value: '', matchMode: 'contains' },
        // 'tenureEndDate': { value: '', matchMode: 'contains' },
        // 'isActive': { value: '', matchMode: 'contains' },
    }
}

const organizationItems = [
    { key: 'o1', label: 'Support' },
    { key: 'o2', label: 'Create Enquiry', className: 'ma-active-item' }
];

const createOrganizationItems = [
    { key: 'co1', label: 'Support' },
    { key: 'co2', label: 'Create Enquiry' },
    { key: 'co3', label: 'Create Addmission', className: 'ma-active-item' }

];
const editOrganizationItems = [
    { key: 'co1', label: 'Support' },
    { key: 'co2', label: 'Create Enquiry' },
    { key: 'co3', label: 'Edit Addmission', className: 'ma-active-item' }

];

const editConfigItems = [
    { key: 'co1', label: 'Support' },
    { key: 'co2', label: 'Create Enquiry' },
    { key: 'co3', label: 'Configurations', className: 'ma-active-item' }

];

const home = { icon: 'pi pi-home' }


class EnquiryForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowCreateOrEdit: false,
            applicationData: null,
            items: organizationItems,
            organizations: [],
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams
        }

        this.service = new Service();
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
                isShowConfigChange: false,
            }
        }, this.getOrganizations);
    }

    onStatusChange = (e, rowData) => {
        let organizations = this.state.organizations.map((i) => {
            if (i._id == rowData._id) {
                i.isActive = e.value;
            }
            return i;
        });
        this.setState({
            organizations,
            isShowConfigChange: false,
        })
    }

    getOrganizations = () => {
        this.setState({
            isLoading: true,
            organizations: [],
            totalRecords: 0,
            isShowConfigChange: false,
            applicationData: null
        });
        let { lazyParams, globalSearch } = this.state;



        let url = `${baseUrlAdmin}/organization/getall?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.service.post(url, lazyParams.filters, true).then(res => {
            if (res && res.status && res.res.success) {
                this.setState((prevState) => {
                    return {
                        organizations: res.res.data.organizations,
                        totalRecords: res.res.data.totalRecords - 1,
                        isLoading: false
                    };
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }

    getAdmissionMetaData = () => {
        let url = `${baseUrlAdmin}/admissions/meta-data`;
        this.service.get(url, true).then(res => {
            
            if (res && res.status && res.res.status) {
                this.setState((prevState) => {
                    return {
                        academicYears: res?.res?.data?.academicYears || [],
                        admissionTypes: res?.res?.data?.admissionTypes || []
                    };
                });
            } else {
                // this.setState({
                //     isLoading: false
                // });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            // this.setState({
            //     isLoading: false
            // });
            console.log(e);
        })
    }

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                },
                isShowConfigChange: false
            };
        }, this.getOrganizations);
    }

    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                },
                isShowConfigChange: false
            };
        }, this.getOrganizations);
    }

    onFilter = (event) => {
        event['first'] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters,
                },
                isShowConfigChange: false
            };
        }, this.getOrganizations);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }



    componentDidMount() {

        //  this.getOrganizations();
        this.getAdmissionMetaData();
    }


    componentDidUpdate() {




    }

    showCreateOrEdit = () => {
        this.setState({
            isShowCreateOrEdit: true,
            applicationData: null,
            items: createOrganizationItems,
            isShowConfigChange: false
        });


    }

    onCreateCancel = () => {

        this.setState({
            isShowCreateOrEdit: false,
            items: organizationItems,
            isShowConfigChange: false
        });
    }

    showCreate = () => {

        this.setState({
            applicationData: null,
            isShowCreateOrEdit: true,
            items: createOrganizationItems,
        });
    }



    onCreateOrEditSuccess = () => {
        this.setState({

            isShowCreateOrEdit: false,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            items: organizationItems
        })
    }

    onEditConfigClick = (orgData) => {

        this.setState({
            applicationData: orgData,
            isShowConfigChange: true,
            items: editConfigItems
        })
    }

    onContinueClick = (rowData) => {
        this.setState({
            applicationData: rowData,
            isShowCreateOrEdit: true,
            items: editOrganizationItems,
            isShowConfigChange: false
        });

    }


    render() {
        const { defaultLanguage } = this.props
        const langType = defaultLanguage
        return (<>
            <Navbar >
                <div className="p-grid ma-toolbar">
                    <div className="p-col-12 p-md-1 ma-no-p">
                        <>
                           {this.state.isShowCreateOrEdit &&  <p className='back-arrow' onClick={() => {
                                this.setState({
                                    isShowCreateOrEdit: false,
                                    items: organizationItems,
                                    isShowConfigChange: false,

                                })
                            }}><span><i className="pi pi-arrow-left"></i> </span> Back</p>}
                        </>

                    </div>
                    <div className="p-col-12 p-md-11 ma-no-pm">
                        <Toolbar className="ma-toolbard"
                            left={() => {
                                return (<>  <BreadCrumb model={this.state.items} home={home} />
                                </>)
                            }}
                            right={() => {
                                return (<h2 className='ma-tab-title'>{I18Next('enquiry', langType).COMMON_KEY}</h2>)
                            }}
                        ></Toolbar>
                    </div>
                </div>
                <div className='ma-main'>
                    {
                        this.state.isShowCreateOrEdit ? <>
                            <CreateEnquiry

                                applicationData={this.state.applicationData}
                                academicYears={this.state.academicYears}
                                admissionTypes={this.state.admissionTypes}
                                onCreateOrEditSuccess={this.onCreateOrEditSuccess}
                                onCreateCancel={this.onCreateCancel}
                                setApplicationData={(applicationData) => {
                                    this.setState({
                                        applicationData: applicationData
                                    });
                                }}
                            // editOrganization={_.cloneDeep(this.state.editOrganization)}
                            />

                            {/* <Receipt /> */}
                        </> : <>
                            <EnquirySearch
                                showCreate={this.showCreate}
                                onContinueClick={this.onContinueClick}
                            />
                        </>
                    }

                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }
            </Navbar>
        </>
        )
    }
}



const mapStateToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,
});



export default connect(mapStateToProps, {})(Authentication(withRouter(EnquiryForm)));

//Authentication