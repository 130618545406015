var connectionTimer;
const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

function connectionState(state) {
    switch (state) {
        case 0:
            return 'CONNECTING';
        case 1:
            return 'OPEN';
        case 2:
            return 'CLOSING';
        case 3:
            return 'CLOSED';
    }
}

const CONNECTION_STATE = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3
}

export default class SocketService {
    constructor(connectionEndPoint, onConnectionOpen, onMessageRecieve) {
        this.connectionEndPoint = connectionEndPoint;
        this.onConnectionOpen = onConnectionOpen;
        this.onMessageRecieve = onMessageRecieve;
        this.websocket = new WebSocket(connectionEndPoint);
        this.websocket.onclose = this.onConnectionClose;
        this.websocket.onerror = this.onConnectionError;
        this.websocket.onmessage = onMessageRecieve;
        this.websocket.onopen = onConnectionOpen;

        this.close = false;
        this.connectionPoolCount = 0;

        this.checker();
    }

    closeConnection() {
        console.log('close request called...');
        clearInterval(connectionTimer);
        this.close = true
        this.websocket.close();
    }

    checker() {
        connectionTimer = setInterval(async () => {
            console.log('socket state---', connectionState(this.websocket.readyState))
            if (this.websocket.readyState == CONNECTION_STATE.CLOSED) {
                await this.reconnect();
            }
        }, 2000);
    }

    onConnectionClose = ({ wasClean, code, reason }) => {
        console.log('connection close called', { wasClean, code, reason })

        if (!this.close) {
            this.reconnect();
        }

    };

    onConnectionError = (error) => {
        console.log('connection error called', error)
        this.connectionPoolCount += 1;
        if (this.connectionPoolCount < 3) {
            this.reconnect();
        }
    };

    reconnect = async () => {
        console.log("reconnect called");
        this.websocket.close();
        this.websocket = new WebSocket(this.connectionEndPoint);
        this.websocket.onclose = this.onConnectionClose;
        this.websocket.onerror = this.onConnectionError;
        this.websocket.onmessage = this.onMessageRecieve;
        this.websocket.onopen = this.onConnectionOpen;
    }

    socketSend = async (data) => {
        console.log('data to socket', data);
        try {
            if (this.websocket.readyState == 1) {
                this.websocket.send(JSON.stringify(data));
                return true;
            } else if (this.websocket.readyState == CONNECTION_STATE.CONNECTING) {
                await sleep(2000);
                await this.socketSend(data);
            } else if (this.websocket.readyState == CONNECTION_STATE.CLOSING) {
                await sleep(2000);
                await this.socketSend(data);
            } else if (this.websocket.readyState == CONNECTION_STATE.CLOSED) {
                await this.reconnect();
                await sleep(2000);
                await this.socketSend(data);
            }
        } catch (e) {
            console.log(e);
        }
    }
}