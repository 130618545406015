import { Tooltip } from 'primereact/tooltip';
import './styles.scss';

function EMailText(props) {

    return (
            <div style={{ display: 'flex' }} className='validate-otp'><>
                <input type="text" disabled={props.otpVerified} value={props.value} className='input-text' onChange={props.onChange} />
                {!props.otpVerified && <span className='right ma-pointer action-text' onClick={props.onActionClick}>{props.rightText}</span>}</>
            </div>
    );
}



export default EMailText;