import React, { Component } from 'react';
import Service from './../service';
import AttachmentPlayer from './attachmentPlayer';
import { ProgressBar } from 'primereact/progressbar';
import './styles.scss'
import { DownloadIcon, PaperClipIcon, PdfColorIcon, PdfIcon } from '../../svgIcons';
import axios from 'axios';

class AttachmentWithIcons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            d: 'd',
            attachments: this.props.attachments,
            showAttachment: false,
            selectedAttachment: null
        }
        this.service = new Service();
    }

    showAttachment = (a) => {
        if (a.uri) {
            this.setState({
                showAttachment: true,
                selectedAttachment: a
            });
        }
    }


    handleDownload = (url, filename) => {
   
        axios.get(url, {
            responseType: 'blob',
          
        })
            .then((res) => {

                const url = window.URL.createObjectURL(
                    new Blob([res.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    filename,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                // fileDownload(res.data, filename)
            })
    }


    getIcon = (attachment) => {
        if (attachment.type.includes('image')) {
            return
        } else if (attachment.type.includes('video')) {
            return

        } else if (attachment.type.includes('audio')) {
            return

        } else if (attachment.type.includes('pdf')) {
            return

        } else {
            return
        }
    }

    getControl = (attachment) => {



        if (!this.props.noPreview && attachment.type && attachment.uri) {

            if (attachment.type.includes('image')) {
                return <div className="attachment-icon-w">
                    <img className="ma-attch-image" src={attachment.uri} />
                    <div class="ma-top-right">
                        {
                            !this.props.noRemove &&

                            <span onClick={() => { this.props.removeAttachment(attachment) }}>
                                <i className="pi pi-times"></i>
                            </span>}
                    </div>
                </div>
            } else if (attachment.type.includes('video')) {
                return <div className="attachment-icon-w">
                    <video className="ma-attch-video" width="100%" height="100%" autoPlay={false} controls
                        controlsList="nodownload"
                    >
                        <source src={attachment.uri} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div class="ma-top-right">
                        {
                            !this.props.noRemove &&

                            <span onClick={() => { this.props.removeAttachment(attachment) }}>
                                <i className="pi pi-times"></i>
                            </span>}
                    </div>
                </div>

            } else if (attachment.type.includes('audio')) {
                return <div className="attachment-icon-w">
                    <audio className="ma-attch-audio" width="80%" height="100%" autoPlay={false} controls
                        controlsList="nodownload"
                    >
                        <source src={attachment.uri} />
                        Your browser does not support the audio tag.
                    </audio>
                    <div class="ma-top-right">
                        {!this.props.noRemove &&
                            <span onClick={() => { this.props.removeAttachment(attachment) }}>
                                <i className="pi pi-times"></i>
                            </span>
                        }
                    </div>
                </div>

            } else if (attachment.type.includes('pdf')) {
                return <>
                    {this.getView(attachment)}
                </>

            } else {
                return <>
                    Not Sopported  {!this.props.noRemove && <span onClick={() => { this.props.removeAttachment(attachment) }}>
                        <i className="pi pi-times"></i>
                    </span>}
                </>
            }
        } else {
            return <>{this.getView(attachment)} </>
        }


    }

    getView = (attachment) => {
        return <div className="ma-attachment-s1">
            <div style={{ width: '20px', float: 'right', marginTop: '5px' }}>
                {!attachment.type.includes('image') && <>
                    <span onClick={() => { this.handleDownload(attachment.uri, attachment.name) }}><DownloadIcon width={15} height={15} /></span>
                </>}
            </div>
            <div style={{ marginRight: '40px' }}>
                <div style={{ width: '100%', overflow: 'hidden' }} onClick={() => { this.showAttachment(attachment) }}>
                    <div style={{ width: '20px', float: 'left' }}>
                        <PaperClipIcon width={20} height={26} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <p className='attach-n'  > {attachment.name}
                        </p>
                    </div>

                </div>
            </div>

        </div>
    }



    render() {
        const { attachments } = this.props;
        return (
            <>
                {
                    attachments && attachments.length ? attachments.map((attachment, index) => {
                        return <div key={index} id={attachment._id}> {this.getControl(attachment)} </div>
                    }) : <></>
                }
                {this.state.showAttachment && <AttachmentPlayer onCloseDialog={() => { this.setState({ showAttachment: false }) }} attachment={this.state.selectedAttachment} />}
            </>
        )
    }
}

export default AttachmentWithIcons;