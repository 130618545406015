
import * as ActionTypes from './../actionTypes';

export default (state={},action) => {

    switch(action.type){
        case ActionTypes.REQUEST_CONTENT:                
            return {
                ...state,
                loading:true,
                error:false,
                errorInfo:null,
                response: null,
                successStatus: null
            }        
        case ActionTypes.RECEIVED_CONTENT:
            return {
                ...state,
                loading:false,
                response:action.res,
                error:false,
                errorInfo:null,
                successStatus: action.status
            }
        case ActionTypes.ERROR_CONTENT:
            return{
                ...state,
                loading:false,
                error:true,
                errorInfo:action.err,
                successStatus: null
            }
        case ActionTypes.CLEAR_CONTENT_DATA:
            return {}
        default: return state;
    }
} 