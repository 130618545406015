import * as ActionType from '../actionTypes';
import axios from 'axios';
import { baseUrlAdmin } from '../apiConstants';

const dispatchRequestAddQuestionData = (data) => ({
  type: ActionType.REQUEST_ADD_QUESTION,
  data
});

const dispatchReceivedAddQuestionData = (res) => ({
  type: ActionType.RECIVED_ADD_QUESTION,
  res
});

const dispatchErrorAddQuestionData = (err) => ({
  type: ActionType.ERROR_ADD_QUESTION,
  err
});

const dispatchCleareAddQuestionData = () => ({
  type: ActionType.CLEAR_ADD_QUESTION
});

export const addQuestion = (data) => (dispatch) => {
  dispatch(dispatchRequestAddQuestionData(data));

  axios({
    method: 'POST',
    url: `${baseUrlAdmin}/question/saveQuestion`,
    data: data
  })
    .then((res) => {
      dispatch(dispatchReceivedAddQuestionData(res.data));
    })
    .catch((e) => {
      dispatch(dispatchErrorAddQuestionData(e));
    });
};

export const clearAddQuestion = () => (dispatch) => {
  dispatch(dispatchCleareAddQuestionData());
};