import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Service from '../../../services';
import SvgViewer from './../../customComponents/svgViewer';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { Menu } from 'primereact/menu';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import CreateChapter from './createChapter';
import cloneDeep from 'lodash/cloneDeep';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { warningDailogInit } from '../../../utile';
import _ from 'lodash';
import './styles.scss'
import { WarningIcon } from '../../svgIcons';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';

class ChaptersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearch: false,
            isShowCreate: false,
            editChapterData: null,
            isAdd: true,
            columns: [{ columnName: 'Chapter Id', isVisible: false, id: 'chapterId' }],
            warningDialog: warningDailogInit,
            warningDialog2: warningDailogInit
        }

        this.service = new Service();
    }

    ///templates start----
    chaptersCountTemplate = (rowData) => {
        return (
            <p >{rowData.chapters.length}</p>
        );
    }

    statusTemplate = (rowData) => {
        return (<div className="ma-status">
            <span className="ma-status-text">{rowData.isChapterActive ? 'Active' : 'InActive'} </span>
            <Authorizer permId={PERMISSIONS.CURRICULUM_CHAPTERS_EDIT_CHAPTER} >  <InputSwitch checked={rowData.isChapterActive} onChange={(e) => { this.changeStatus(e, rowData) }} />
            </Authorizer>
        </div>
        );


    }

    editTemplate = (rowData) => {
        return (<><Authorizer permId={PERMISSIONS.CURRICULUM_CHAPTERS_EDIT_CHAPTER} >
            <span data-pr-tooltip="Edit Chapter" data-pr-position="bottom" className={`edit${rowData.chapterId.substr(0, 5)}`}
                onClick={() => this.showEditChapterDialog(rowData)}
            >
                <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" />
            </span>
            <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData.chapterId.substr(0, 5)}`} />
        </Authorizer>
            {
                this.props.syncEnabled &&
                <Authorizer permId={PERMISSIONS.CURRICULUM_CHAPTERS_EDIT_CHAPTER} >
                    <span data-pr-tooltip="Sync Data" data-pr-position="bottom" className={`ma-ml10 sync${rowData.chapterId.substr(0, 5)}`}
                        onClick={() => this.showSyncData(rowData)}
                    >
                        <i className='pi pi-replay ma-icon-svg ma-pointer' width="20" height="20" />
                    </span>
                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.sync${rowData.chapterId.substr(0, 5)}`} />
                </Authorizer>
            }
        </>);
    }

    showChaptersTemplate = (rowData) => {
        return (
            <Authorizer permId={PERMISSIONS.CURRICULUM_CHAPTERS_GOTO_TOPICS} >
                <Button label="Show Topics" className='p-button-outlined' onClick={() => { this.props.showTopics(rowData) }} />
            </Authorizer>
        );
    }

    ///templates end----

    checkIsDuplicate(chapters, chapterInfo, isEdit) {
        let err, errMsg;
        chapters &&
            chapters.length &&
            chapters.forEach((chapter) => {
                if (isEdit) {
                    if (chapter.chapterId != chapterInfo.chapterId) {
                        if (
                            chapter.chapterCode === chapterInfo.chapterCode ||
                            chapter.chapterName === chapterInfo.chapterName ||
                            chapter.chapterNumber === chapterInfo.chapterNumber
                        ) {
                            err = true;
                            errMsg =
                                chapter.chapterCode.toLowerCase() === chapterInfo.chapterCode.toLowerCase()
                                    ? 'Duplicate Chapter Code'
                                    : chapter.chapterName.toLowerCase() === chapterInfo.chapterName.toLowerCase()
                                        ? 'Duplicate Chapter Name'
                                        : chapter.chapterNumber.toLowerCase() === chapterInfo.chapterNumber.toLowerCase()
                                            ? 'Duplicate Chapter Number'
                                            : 'Enter Valid Details';
                            return;
                        }
                    }
                } else {
                    if (
                        chapter.chapterCode === chapterInfo.chapterCode ||
                        chapter.chapterName === chapterInfo.chapterName ||
                        chapter.chapterNumber === chapterInfo.chapterNumber
                    ) {
                        err = true;
                        errMsg =
                            chapter.chapterCode.toLowerCase() === chapterInfo.chapterCode.toLowerCase()
                                ? 'Duplicate Chapter Code'
                                : chapter.chapterName.toLowerCase() === chapterInfo.chapterName.toLowerCase()
                                    ? 'Duplicate Chapter Name'
                                    : chapter.chapterNumber.toLowerCase() === chapterInfo.chapterNumber.toLowerCase()
                                        ? 'Duplicate Chapter Number'
                                        : 'Enter Valid Details';
                        return;
                    }
                }
            });

        return { err, errMsg };
    }

    addChapter = (chapterInfo) => {

        if (this.state.isAdd) {
            let { err, errMsg } = this.checkIsDuplicate(this.props.chapters, chapterInfo);
            if (err) {
                this.toast.show({ severity: 'error', summary: 'Error occured', detail: errMsg, life: 3000 });
            } else {
                let chapters = cloneDeep(this.props.chapters);
                chapters.push({
                    chapterId: chapterInfo.chapterId,
                    chapterNumber: chapterInfo.chapterNumber,
                    chapterName: chapterInfo.chapterName,
                    chapterCode: chapterInfo.chapterCode,
                    chapterDescription: chapterInfo.chapterDescription,
                    chapterLongDescription: chapterInfo.chapterLongDescription,
                    isChapterActive: chapterInfo.isChapterActive,
                    topics: []
                });

                this.props.addOrUpdateChapterInfo(chapters);



                this.setState({
                    isShowCreate: false
                });
            }

        } else {
            let chapters = [];
            if (this.props.chapters && this.props.chapters.length) {

                let oldChapters = _.cloneDeep(this.props.chapters);


                chapters = oldChapters.map(chapter => {
                    if (chapter.chapterId === chapterInfo.chapterId) {
                        chapter.chapterNumber = chapterInfo.chapterNumber;
                        chapter.chapterName = chapterInfo.chapterName;
                        chapter.chapterCode = chapterInfo.chapterCode;
                        chapter.chapterDescription = chapterInfo.chapterDescription;
                        chapter.chapterLongDescription = chapterInfo.chapterLongDescription;
                        chapter.isChapterActive = chapterInfo.isChapterActive;
                    }
                    return chapter
                });
            }
            let { err, errMsg } = this.checkIsDuplicate(this.props.chapters, chapterInfo, true);
            if (err) {
                this.toast.show({ severity: 'error', summary: 'Error occured', detail: errMsg, life: 3000 });
            } else {


                this.props.addOrUpdateChapterInfo(chapters);

                this.setState({
                    isShowCreate: false
                });
            }

            //   console.log(chapters);
        }
    }


    changeStatus = (e, rowData) => {
        let data = { ...rowData }
        if (e.value == true) {
            data.isChapterActive = true
        } else {
            data.isChapterActive = false
        }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You are changing this Chapter status ${e.value ? 'InActive' : 'Active'} to ${e.value ? 'Active' : 'InActive'}`
            },
            editChapterData: data
        });

    }

    showSyncData = (rowData) => {
        this.setState({
            warningDialog2: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `It will sync data with content repository and any deleted resources or questions will added again from content repository.`
            },
            syncData: rowData
        });

    }

    onSyncConfirm = () => {
        this.setState({
            warningDialog2: warningDailogInit,
            isLoading: true
        });



        let obj = {
            level: 'chapter',
            chapterId: this.state.syncData.chapterId,
            topics: this.state.syncData.topics?.map(a => a.topicId),
            parentChapterId: this.state.syncData.parentChapterId,
            ...this.props.boardsHeirarchy
        }

        console.log(obj, 'syncdata')

        const url = `${baseUrlForLongRunningTask}/content/synccontent`;
        this.service.post(url, obj, true, { "If-Match": this.props.etag }).then((res) => {

            if (res && res.status && res.res.status) {

                this.setState({
                    isLoading: false
                }, () => {

                    this.props.getCurriculumData()
                })

            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })


    }



    onConfirmChange = () => {
        this.setState({
            warningDialog: warningDailogInit
        });

        let { editChapterData } = this.state;
        if (this.props.chapters && this.props.chapters.length) {
            let oldChapters = _.cloneDeep(this.props.chapters);
            let chapters = oldChapters.map(chapter => {
                if (chapter.chapterId === editChapterData.chapterId) {
                    chapter.isChapterActive = editChapterData.isChapterActive;
                }
                return chapter
            });

            this.props.addOrUpdateChapterInfo(chapters);
        }

    }


    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }


    exportCSV = () => {
        this.dt.exportCSV();
    }


    showCreateChapterDialog = () => {
        this.setState({
            isShowCreate: true,
            isAdd: true,
            editChapterData: null
        });
    }


    showEditChapterDialog = (editChapterData) => {
        this.setState({
            editChapterData,
            isShowCreate: true,
            isAdd: false
        });
    }

    hideCreateChapterDialog = () => {
        this.setState({
            isShowCreate: false,
            editChapterData: null
        });
    }

    render() {
        const { chapter, formValidations } = this.state;
        //  console.log(this.state)

        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 >Chapters List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" className="ma-tbl-search p-inputtext-md" onChange={(e) => this.setState({ globalFilter: e.target.value })} />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        <Authorizer permId={PERMISSIONS.CURRICULUM_SEARCH} >
                            <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <Authorizer permId={PERMISSIONS.CURRICULUM_VIEWCOLUMNS} >
                            <li><i data-pr-tooltip="View Columns" onClick={(event) => this.menu.toggle(event)} data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.CURRICULUM_DOWNLOADCSV} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        return (<div >
            <div className='ma-mtb25'>
                <Authorizer permId={PERMISSIONS.CURRICULUM_CHAPTERS_ADD_CHAPTER} >
                    <Button label="+ Add Chapter" className='ma-m-lr10'
                        onClick={this.showCreateChapterDialog} />
                </Authorizer>
            </div>
            <div className="card datatable-crud-demo ma-m30">
                <DataTable ref={(el) => this.dt = el}
                    //  lazy
                    value={this.props.chapters}
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={this.state.globalFilter}
                    header={header}
                    sortField="chapterNumber" 
                    sortOrder={1} 
                >

                    <Column filter filterMatchMode='contains' sortable field="chapterNumber" header="Chapter Number" />
                    <Column filter filterMatchMode='contains' sortable field="chapterName" header="Chapter Name" />
                    <Column filter filterMatchMode='contains' sortable field="chapterCode" header="Chapter Code" />
                    {
                        this.isColumnVisible('chapterId') && <Column filter filterMatchMode='contains' sortable field="chapterId" header="Chapter Id" />
                    }
                    <Column filter filterMatchMode='contains' sortable field="chapterDescription" header="Chapter Description" />
                    <Column filter filterMatchMode='contains' sortable field="chapterLongDescription" header="Chapter Long Description" />
                    <Column field="isChapterActive" header="Status" body={this.statusTemplate} />
                    <Column header="Edit" body={this.editTemplate} ></Column>
                    <Column header="Show Topics" body={this.showChaptersTemplate}></Column>
                </DataTable>
                {this.state.isShowCreate && <CreateChapter isAdd={this.state.isAdd} hideCreateDialog={this.hideCreateChapterDialog} editChapterData={this.state.editChapterData} addChapter={this.addChapter} />}

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div>
            <Dialog
                header={<></>}
                draggable={false}
                closeOnEscape={true}
                className='ma-alert-box'
                blockScroll={true}
                dismissableMask={true}
                closable={false}
                visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                footer={() => {
                    return (<div>
                        <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editRoleData: null }) }} />
                        <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmChange} />
                    </div>)
                }}
                onHide={() => this.setState({ warningDialog: warningDailogInit, editRoleData: null })}>
                <div>

                    <span className="warning-icon" >
                        <WarningIcon width={"100%"} height={"100%"} />
                    </span>
                </div>
                <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                <p className='ma-alert-msg'>{this.state.warningDialog.message}</p>
            </Dialog>

            <Dialog
                header={<></>}
                blockScroll={true}
                draggable={false}
                closeOnEscape={false}
                className='ma-alert-box'
                dismissableMask={false}
                closable={false}
                visible={this.state.warningDialog2.visible}
                style={{ maxWidth: '22vw', minWidth: '300px' }}
                footer={() => {
                    return (<div>
                        <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog2: warningDailogInit, syncData: null }) }} />
                        <Button label="Yes" className='ma-m-lr10' onClick={this.onSyncConfirm} />

                    </div>)
                }}
                onHide={() => this.setState({ warningDialog2: warningDailogInit, syncData: null })}>
                <div>

                    <span className="warning-icon" >
                        <WarningIcon width={"100%"} height={"100%"} />
                    </span>
                </div>
                <p className='ma-alert-head'>{this.state.warningDialog2.headerMsg}</p>
                <p className='ma-alert-msg'>{this.state.warningDialog2.message}</p>
            </Dialog>

            {this.state.isLoading && <LoadingComponent />}
        </div>
        )
    }
}




export default ChaptersList;