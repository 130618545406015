import React from 'react';
import AttachmentPlayer from '../../discussionBoard/common/attachmentPlayer';
import DeleteMessage from './deleteMessage';
import moment from "moment";

import { MESSAGE_STATUS } from '../../../utile';

const TEACHER_ID = localStorage.getItem('discussion_uId');

class PDFView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAttachment: false,
        }
    }

    showAttachment = (a) => {
        if (a.content) {
            this.setState({
                showAttachment: true,
            });
        }
    }

    render() {
        const { conversation } = this.props;

        return (
            <div className={`ask-wr conversation-attachment ${conversation.direction == 2 ? 'ma-right' : 'ma-left1'}`}>
                <div style={{ width: '40px', float: 'right' }}>
                    <img src="./images/PDF_Doc_icon.svg" className="ma-attach-icon" />
                </div>
                <div style={{ marginRight: '40px' }}>
                    <div style={{ width: '100%', overflow: 'hidden' }} onClick={() => { this.showAttachment(conversation) }}>
                        <div style={{ width: '20px', float: 'left' }}>
                            {/* <img src="./images/Voice_recorder.svg" className="ma-attach-icon" onClick={() => { }} /> */}
                        </div>
                        <div className='attach-txt'>
                           Click here to see attachment
                        </div>
                    </div>
                </div>
                <div className="ma-clearFix"> </div>
                <span className={`message-time  ${conversation.direction == 2 ? '' : 'ma-right'}`}>{moment(conversation.createdAt).format('lll')} {conversation.direction == 2 && (conversation.status == MESSAGE_STATUS.sent ? <i className="pi pi-check sent-icon" /> : conversation.status == MESSAGE_STATUS.sending ? <i className="pi pi-spinner sent-icon" /> : ' ')}</span>
                {this.state.showAttachment && <AttachmentPlayer onCloseDialog={() => { this.setState({ showAttachment: false }) }} attachment={{ type: 'pdf', name: 'Document', uri: conversation.content }} />}
                {conversation.direction == 2 && <DeleteMessage conversation={conversation} deleteMessage={this.props.deleteMessage} />}
            </div>
        );
    }
}


export default PDFView;