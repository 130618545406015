import React from 'react';
import { connect } from 'react-redux';
import PDFViewer from 'mgr-pdf-viewer-react';
import { Dialog } from 'primereact/dialog';

const CustomPrevButton = (props) => {
    const {
        page,
        pages,
        handlePrevClick
    } = props;
    if (page === 1) return <div></div>;

    return <h3 style={{ cursor: 'pointer', display: 'inline-block', marginRight: 24, marginTop: 0, color: '' }} onClick={handlePrevClick}>Previous page</h3>;
};


const CustomNextButton = (props) => {
    const {
        page,
        pages,
        handleNextClick
    } = props;
    if (page === pages) return <div></div>;

    return <h3 style={{ cursor: 'pointer', display: 'inline-block', marginLeft: 24, marginTop: 0, color: '' }} onClick={handleNextClick}>Next page</h3>;
};


const CustomPages = (props) => {
    const {
        page,
        pages
    } = props;
    return <h3 style={{ display: 'inline-block', marginTop: 0, color: '' }}>Page {page} from {pages}</h3>;
};



class AttachmentPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scale: 1,
        }
    }


    render() {
        const { attachment } = this.props;

        return (<>
            <Dialog
                visible={true}

                blockScroll={true}
                onHide={this.props.onCloseDialog}
                header={attachment.name}
            >
                {(attachment.type == 'application/pdf' || attachment.type == 'pdf') && <PDFViewer
                    document={{
                        fileType: 'pdf',
                        url: attachment.uri,
                    }}

                    scale={this.state.scale}
                    css="customViewer"
                    navigation={{
                        elements: {
                            previousPageBtn: CustomPrevButton,
                            nextPageBtn: CustomNextButton,
                            pages: CustomPages
                        }
                    }} />
                }

                {(attachment.type.includes('image')) &&
                    < img className="ma-attch-image" src={attachment.uri} />
                }
                {(attachment.type.includes('video')) &&
                    <video className="ma-attch-video" width="100%" height="100%" autoPlay={false} controls
                        controlsList="nodownload"
                    >
                        <source src={attachment.uri} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                }

                {(attachment.type.includes('audio')) &&
                    <audio className="ma-attch-audio" width="80%" height="100%" autoPlay={false} controls
                        controlsList="nodownload"
                    >
                        <source src={attachment.uri} />
                        Your browser does not support the audio tag.
                    </audio>
                }
            </Dialog>
        </>
        );
    }
}





// const mapsStatesToProps = (state) => {
//     return {

//     };
// };


export default AttachmentPlayer;
// connect(mapsStatesToProps, {

// })(AttachmentPlayer);