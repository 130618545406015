import React, { Component } from 'react'
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';
import uuidv4 from 'uuid/v4';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import InputTextB from '../customComponents/inputTextB';
import { FIELDS_INFO } from '../../constants';
import _ from 'lodash';
import { getFormFields, onTextChange, isFormValid } from '../../utile/formHelper';
import { trimObj } from '../../utile';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import LoadingComponent from '../loadingComponent';
import moment from 'moment';
import PeriodResources from './periodResources';
import ResourcesLinks from '../virtualClass/resources';

const formFields = [
    {
        "Type": "Text",
        "Label": "Teacher Name",
        "FieldName": "teacherId",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Subject Name",
        "FieldName": "subjectId",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Chapter Name",
        "FieldName": "chapterId",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Description",
        "FieldName": "description",
        "Required": true
    },
    {
        "Type": "Text",
        "Label": "Learning Objective",
        "FieldName": "learningObjective",
        "Required": false
    },
    {
        "Type": "Text",
        "Label": "Learning Outcome",
        "FieldName": "learningOutcome",
        "Required": false
    },
    {
        "Type": "Array",
        "Label": "Teaching Aids",
        "FieldName": "resources",
        "Required": false
    },
    {
        "Type": "Array",
        "Label": "Add Links",
        "FieldName": "addLinks",
        "Required": false
    }

]


export default class Period extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, this.props.section.periods[this.props.periodIndex] || this.props.section.periods[this.props.periodIndex]);
        this.state = {
            period: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isShowAddOrEdit: false,
            teachers: [],
            subjects: [],
            chapters: []

        }


        this.service = new Service();

    }

    componentWillUnmount() {
        

    }
    componentDidMount() {
        
    }
    componentDidUpdate(prevProps) {
        if (prevProps.section != this.props.section) {


            this.setState({
                period: this.props.section.periods[this.props.periodIndex]
            })

        }


    }
    cancelAdd = () => {
        

        if (this.state.period._id) {
            this.setState((prevState) => {
                return {
                    period: prevState.periodOldData,
                    isShowAddOrEdit: false
                };
            });
        } else {

            let temp = getFormFields(formFields, {});
            this.setState((prevState) => {
                return {
                    isShowAddOrEdit: false,
                    period: {
                        ...prevState.period,
                        "teacherName": "",
                        "teacherId": "",
                        "subjectId": "",
                        "subjectName": "",
                        "chapterName": "",
                        "chapterId": "",
                        "description": "",
                        "learningObjective": "",
                        "learningOutcome": "",
                        "resources": [],
                        addLinks: []
                        // "teachingAids": []
                    },
                    formValidations: temp.formValidations

                };
            });
        }

    }


    addPeriodDetails = () => {

        let period = trimObj(this.state.period);

        if (!period.addLinks) {
            period.addLinks = []
        }


        if (!period.resources) {
            period.resources = []
        }

        const formStatus = isFormValid(formFields, this.formFields.formValidations, period);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {


            this.setState({
                isLoading: true
            });
            const url = `${baseUrlAdmin}/timetable/saveperiod`;

            this.service[period._id ? 'put' : 'post'](url, {
                ...period,
                sectionId: this.props.section.sectionId,
                timeTableId: this.props.section.timeTableId,
                date: moment(period.date).format('YYYY-MM-DD')

            }, true).then(res => {

                if (res && res.res && res.res.status) {
                    this.setState({
                        period: res.res.data,
                        isShowAddOrEdit: false,
                        isLoading: false,
                        teachers: [],
                        subjects: [],
                        chapters: []
                    })
                    this.toast.show({ severity: 'success', summary: 'Success', detail: `Period ${period._id ? 'Edited' : 'Created'} Successfully.`, life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            }).catch(e => {
                console.log(e);
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });

                this.setState({
                    isLoading: false
                })
            })
        }

    }


    showAdd = () => {

        let _oldPeriod = _.cloneDeep(this.state.period);

        let update = {}
        if (_oldPeriod && _oldPeriod._id) {
            const formStatus = isFormValid(formFields, this.formFields.formValidations, _oldPeriod);

            update = { formValidations: formStatus.formValidations }

        }



        this.setState((prevState) => {
            return {
                isLoading: true,
                periodOldData: _oldPeriod,
                ...update

            };
        });
        const url = `${baseUrlAdmin}/admin/getteachersofsection/${this.props.section.sectionId}`;

        this.service.get(url, true).then(res => {

            if (res && res.res && res.res.status) {

                this.setState({
                    teachers: res.res.data,
                    isShowAddOrEdit: true,
                    isLoading: false,


                }, () => {

                    if (this.state.period && this.state.period._id) {

                        this.getSubjectsAndChapters();

                    }
                })

            } else {


                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            console.log(e);
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });

            this.setState({
                isLoading: false
            })
        })

        // this.setState({
        //     isShowAddOrEdit: true
        // })
    }


    onChangeTeacher = (teacherId) => {

        let teacherName = this.state.teachers.find((t) => t.user_id == teacherId).userName;
        if (this.state.period.resources && this.state.period.resources.length) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: `Chapter can not be modified when resources added.`, life: 3000 });
        } else {

            this.setState((prevState) => {
                return {
                    period: {
                        ...prevState.period,
                        teacherId,
                        teacherName: teacherName,
                        chapterId: null,
                        subjectId: null,
                    },
                    subjects: [],
                    chapters: []
                };
            }, () => this.getSubjects(teacherId));
        }

    }


    getSubjectsAndChapters = () => {
        const { subjectId, teacherId, } = this.state.period;
        const { teachers } = this.state;


        let isTeacherFoundInList = false
        if (teachers.findIndex(a => a.user_id == teacherId) >= 0) {
            isTeacherFoundInList = true;
        }



        this.setState({
            isLoading: true
        });
        const url = `${baseUrlAdmin}/timetable/getsubjects`;

        this.service.post(url, { teacherId, sectionId: this.props.section.sectionId }, true).then(res => {
            if (res && res.res && res.res.status) {
                let selected = res.res.data.find((t) => t.subjectId == subjectId);

                this.setState((prevState) => {
                    return {
                        period: {
                            ...prevState.period,
                            subjectName: selected ? selected.subjectName : '',
                            subjectId: selected ? subjectId : '',
                            chapterId: selected ? prevState.period.chapterId : '',
                            teacherId: isTeacherFoundInList ? teacherId : ''
                        },
                        subjects: res.res.data,
                        isLoading: false,
                        chapters: selected ? selected.chapters : []
                    }
                });
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            })
        })

        // this.setState({
        //     isShowAddOrEdit: true
        // })
    }



    getSubjects = (teacherId) => {


        this.setState({
            isLoading: true
        })
        const url = `${baseUrlAdmin}/timetable/getsubjects`;

        this.service.post(url, { teacherId, sectionId: this.props.section.sectionId }, true).then(res => {
            if (res && res.res && res.res.status) {

                this.setState({
                    subjects: res.res.data,
                    isLoading: false
                })

            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            })
        })

        // this.setState({
        //     isShowAddOrEdit: true
        // })
    }

    onChangeSubject = (subjectId) => {
        let selected = this.state.subjects.find((t) => t.subjectId == subjectId);;

        if (this.state.period.resources && this.state.period.resources.length) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: `Chapter can not be modified when resources added.`, life: 3000 });
        } else {
            this.setState((prevState) => {
                return {
                    period: {
                        ...prevState.period,
                        subjectId,
                        subjectName: selected.subjectName,
                        chapterId: null,
                        chapterName: null
                    },
                    chapters: selected.chapters
                };
            });
        }
    }


    onChangeChapter = (chapterId) => {
        let selected = this.state.chapters.find((t) => t.chapterId == chapterId);;

        if (this.state.period.resources && this.state.period.resources.length) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: `Chapter can not be modified when resources added.`, life: 3000 });
        } else {
            this.setState((prevState) => {
                return {
                    period: {
                        ...prevState.period,
                        chapterId: chapterId,
                        chapterName: selected.chapterName,
                    },
                };
            });
        }
    }

    onSelectContent = (checkedContetForAssign, contentRepo) => {
        this.setState({
            checkedContetForAssign, contentRepo
        })
    };


    onAssignVideos = (videos) => {
        let { resources } = this.state.period;

        if (!resources) {
            resources = []

        }
        videos.forEach(v => {
            if (!resources.find((vid) => vid.contentId == v.contentId)) {
                delete v.childCurriculumContentKeys;
                delete v.questionIds;
                resources.push(v);
            };
        })

        this.setState((prevState) => {
            return {
                period: {
                    ...prevState.period,
                    resources
                },
                isShowLinkVideo: false
            }
        });

    }


    removeLinkedVideo = (video) => {
        let { resources } = this.state.period;
        resources = resources.filter((v) => v.contentId != video.contentId);

        this.setState((prevState) => {
            return {
                period: {
                    ...prevState.period,
                    resources
                }
            }
        });
    }

    addMore = () => {
        
        if (this.state.period.addLinks.every((s) => s.assigned === true)) {

            this.setState((prevState) => {
                return {
                    period: {
                        ...prevState.period,
                        addLinks: [...prevState.period.addLinks, { id: uuidv4(), assigned: false }],
                    }
                }
            }
            );
        } else {
            this.toast.show({ severity: 'error', summary: 'Warning', detail: 'Please save resource before adding another one', life: 3000 });

        }
    }
    onSave = (data) => {
        let addLinks = this.state.period.addLinks.map((item) => {
            if (item.id == data.id) {
                return data;
            } else {
                return item;
            }
        });

        
        this.setState((prevState) => {
            return {
                period: {
                    ...prevState.period,
                    addLinks: addLinks
                },
            }
        });
    }

    removeResourcesRow = (id) => {
        let formValidations = this.state.formValidations;

        formValidations.fields['addLinks'] = { ...formValidations.fields['addLinks'], isValid: true, errorMsg: `` }
        if (this.state.period.addLinks.length) {
            let addLinks = this.state.period.addLinks.filter(item => item.id != id);
            this.setState((prevState) => {
                return {
                    period: {
                        ...prevState.period,
                        addLinks
                    },
                    formValidations
                }
            });
        }
    }


    render() {
        const { period, formValidations } = this.state;

        console.log(period)

        return (<div >
            <div className='period-wrapper'>
                {
                    period.type == 'break' ? <>-
                    </> : <>
                        {
                            period._id && <>
                                <label className='ma-label-s1'>Teacher</label>
                                <p className='ma-data-value'>{period.teacherName}</p>
                                <label className='ma-label-s1'>Subject</label>
                                <p className='ma-data-value'>{period.subjectName}</p>
                            </>
                        }
                        <Button label={period._id ? 'Edit Details' : "Add Details"} icon='pi pi-plus' className={`ma-small-btn ${period._id ? 'p-button-warning' : ''}`} onClick={this.showAdd} />
                    </>
                }
            </div>
            <Dialog
                draggable={false}
                blockScroll={true}
                closeOnEscape={true}
                className='ma-alert-box'
                //  dismissableMask={true}
                closable={false}
                header={`${period._id ? 'Edit' : 'Add'}  Period Details`}
                visible={this.state.isShowAddOrEdit}
                style={{ maxWidth: '90%', minWidth: '90%', height: "90vh" }}
                footer={() => {
                    return (<div>
                        <Button label="Cancel" className='p-button-outlined' onClick={this.cancelAdd} />
                        <Button label={period._id ? 'Update' : 'Save'} className='ma-m-lr10' onClick={this.addPeriodDetails} />
                    </div>)
                }}
            >
                <div className="p-grid chapter-add-dialog">
                    <div className="p-col-2 p-md-2 p-lg-2">
                        <p className='text-label'>Teacher Name <span className='ma-required'>*</span></p>
                    </div>
                    <div className="p-col-10 p-md-10 p-lg-10 ma-mt2">
                        <InputTextB info={FIELDS_INFO.TEACHER_NAME} id="n7">
                            <Dropdown value={period.teacherId}
                                className='ma-w200 '
                                options={this.state.teachers || []}
                                optionLabel="userName"
                                optionValue="user_id"
                                onChange={(e) => this.onChangeTeacher(e.value)}
                                placeholder="Select Teacher" />
                        </InputTextB>
                        {formValidations && !formValidations.fields['teacherId'].isValid && <p className="p-error">{formValidations.fields['teacherId'].errorMsg}</p>}
                    </div>
                </div>

                <div className="p-grid chapter-add-dialog">
                    <div className="p-col-2 p-md-2 p-lg-2">
                        <p className='text-label'>Subject Name <span className='ma-required'>*</span></p>
                    </div>
                    <div className="p-col-10 p-md-10 p-lg-10 ma-mt2">
                        <InputTextB info={FIELDS_INFO.SUBJECT_NAME} id="n6">
                            <Dropdown value={period.subjectId}
                                className='ma-w200'
                                options={this.state.subjects || []}
                                optionLabel="subjectName"
                                optionValue="subjectId"
                                onChange={(e) => this.onChangeSubject(e.value)}
                                placeholder="Select Subject" />
                        </InputTextB>
                        {formValidations && !formValidations.fields['subjectId'].isValid && <p className="p-error">{formValidations.fields['subjectId'].errorMsg}</p>}
                    </div>
                </div>

                <div className="p-grid chapter-add-dialog">
                    <div className="p-col-2 p-md-2 p-lg-2">
                        <p className='text-label'>Chapter Name <span className='ma-required'>*</span></p>
                    </div>
                    <div className="p-col-10 p-md-10 p-lg-10 ma-mt2">
                        <InputTextB info={FIELDS_INFO.CHAPTER_NAME} id="n5">
                            <Dropdown value={period.chapterId}
                                className='ma-w200'
                                options={this.state.chapters || []}
                                optionLabel="chapterName"
                                optionValue="chapterId"
                                onChange={(e) => this.onChangeChapter(e.value)}
                                placeholder="Select Chapter" />
                        </InputTextB>
                        {formValidations && !formValidations.fields['chapterId'].isValid && <p className="p-error">{formValidations.fields['chapterId'].errorMsg}</p>}
                    </div>
                </div>
                <div className="p-grid chapter-add-dialog">
                    <div className="p-col-2 p-md-2 p-lg-2">
                        <p className='text-label'>Description<span className='ma-required'>*</span></p>
                    </div>
                    <div className="p-col-10 p-md-10 p-lg-10 ma-mt2">
                        <InputTextB info={FIELDS_INFO.DESCRIPTION} id="n4">
                            <InputTextarea value={period.description}
                                onChange={(e) => { onTextChange(e.target.value, 'description', this, formFields, period, formValidations, 'period', 'formValidations') }}
                                className='p-inputtext-style1' rows={5} cols={30} />
                        </InputTextB>
                        {formValidations && !formValidations.fields['description'].isValid && <p className="p-error">{formValidations.fields['description'].errorMsg}</p>}
                    </div>
                </div>
                <div className="p-grid chapter-add-dialog">
                    <div className="p-col-2 p-md-2 p-lg-2">
                        <p className='text-label'>Learning Objective</p>
                    </div>
                    <div className="p-col-10 p-md-10 p-lg-10 ma-mt2">
                        <InputTextB info={FIELDS_INFO.LEARNING_OBJECTIVE} id="n3">
                            <InputTextarea value={period.learningObjective}
                                onChange={(e) => { onTextChange(e.target.value, 'learningObjective', this, formFields, period, formValidations, 'period', 'formValidations') }}
                                className='p-inputtext-style1' rows={5} cols={30} />
                        </InputTextB>
                        {formValidations && !formValidations.fields['learningObjective'].isValid && <p className="p-error">{formValidations.fields['learningObjective'].errorMsg}</p>}
                    </div>
                </div>
                <div className="p-grid chapter-add-dialog">
                    <div className="p-col-2 p-md-2 p-lg-2">
                        <p className='text-label'>Learning Outcome</p>
                    </div>
                    <div className="p-col-10 p-md-10 p-lg-10 ma-mt2">
                        <InputTextB info={FIELDS_INFO.LEARNING_OUTCOME} id="n2">
                            <InputTextarea value={period.learningOutcome}
                                onChange={(e) => { onTextChange(e.target.value, 'learningOutcome', this, formFields, period, formValidations, 'period', 'formValidations') }}
                                className='p-inputtext-style1' rows={5} cols={30} />
                        </InputTextB>
                        {formValidations && !formValidations.fields['learningOutcome'].isValid && <p className="p-error">{formValidations.fields['learningOutcome'].errorMsg}</p>}
                    </div>
                </div>

                <div className="p-grid chapter-add-dialog">
                    <div className="p-col-2 p-md-2 p-lg-2">
                        <p className='text-label'>Resources</p>
                    </div>
                    <div className="p-col-10 p-md-10 p-lg-10 ma-mt2">
                        <PeriodResources chapterId={period.chapterId}
                            onSelectContent={this.onSelectContent}
                            onAssignVideos={this.onAssignVideos}
                            videoLinking={period.resources}
                            removeLinkedVideo={this.removeLinkedVideo}

                        />
                    </div>
                </div>
                <div className="p-grid chapter-add-dialog">
                    <div className="p-col-2 p-md-2 p-lg-2">
                        <p className='text-label'>Additional Resources</p>
                    </div>
                    <div className="p-col-10 p-md-10 p-lg-10 ma-mt2">
                        <div className='virtual-resources'>
                            {
                                period && period.addLinks && period.addLinks.map((temp) => {
                                    return <div key={temp.id} >
                                        <ResourcesLinks data={temp.data}
                                            resources={period.addLinks}
                                            id={temp.id}
                                            onSave={this.onSave}
                                            removeResourcesRow={this.removeResourcesRow} />
                                    </div>
                                })
                            }
                            <span onClick={this.addMore} className='ma-addmore ma-pointer'>
                                <i className="pi pi-plus-circle"></i>
                            </span>
                        </div>
                        <p className='upload-note'><span>Note :</span> Paste the video resources using url links</p>
                    </div>
                </div>

                {formValidations && formValidations.fields && !formValidations.fields['resources'].isValid && <p className="p-error">{formValidations.fields['resources'].errorMsg}</p>}

                {/* <div className="p-grid chapter-add-dialog">
                    <div className="p-col-2 p-md-2 p-lg-2">
                        <p className='text-label'>Teaching Aids</p>
                    </div>
                    <div className="p-col-10 p-md-10 p-lg-10 ma-mt2">
                        <InputTextB info={FIELDS_INFO.CHAPTER_NAME} id="n1">

                        </InputTextB>
                    </div>
                </div> */}
            </Dialog>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            {
                this.state.isLoading && <LoadingComponent />
            }
        </div>
        )
    }
}