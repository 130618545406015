import React, { useEffect, useState } from "react";
import { Dialog } from 'primereact/dialog';
import { OrganizationChart } from 'primereact/organizationchart'
import { Button } from 'primereact/button';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { ProgressSpinner } from 'primereact/progressspinner';
import Service from './../../../services';
import { Toast } from 'primereact/toast';

const HierarchyChartModal = ({ isOpen, handleModalState }) => {
    const [treeData, setTreeData] = useState([]);
    const [dataArrays, setDataArray] = useState([]);
    const [isLoading, setLoader] = useState(false);
    let service = new Service();
    const [toast, setToast] = useState(null);


    const getAllRoles = () => {
        setLoader(true)
        let url = `${baseUrlAdmin}/authorize/getAllRoles`;
        service.get(url, true).then(res => {
            if (res && res.res && res.res.data) {
                let allRoles = res.res.data.map((r, index) => {
                    r.slNo = index + 1;
                    return r;
                })
                setDataArray(allRoles)
                setLoader(false)
            } else {
                setLoader(false)
                toast && toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch((e) => {
            setLoader(false)
            toast && toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });
    }

    useEffect(() => {
        getAllRoles()
    }, [])

    useEffect(() => {
        const parentChildMap = {};

        for (const item of dataArrays) {
            const parentId = item.parentId;
            if (parentId) {
                if (!parentChildMap[parentId]) {
                    parentChildMap[parentId] = [];
                }
                const newItem = {
                    ...item,
                    label: item.roleName,
                    expanded: true,
                    children: [],
                };
                delete newItem.permissions;
                parentChildMap[parentId].push(newItem);
            }
        }


        const buildHierarchy = (item) => {
            const children = parentChildMap[item._id] || [];
            item.children = children.map(child => buildHierarchy(child));
            item.label = item.roleName
            item.expanded = true
            return item;
        };

        const roots = dataArrays
            .filter(item => !item.parentId)
            .map(item => buildHierarchy(item));
        console.log("roots", roots);

        setTreeData([{
            label: 'Root',
            expanded: true,
            children: roots
        }]);
    }, [dataArrays]);

    const footerContent = (
        <div>
            <Button label="Close" icon="pi pi-times" onClick={() => handleModalState()} className="p-button-text" />
        </div>
    );

    const containerStyles = {
        width: '100%',
        height: '600px',
        border: '1px solid black',
    };

    return (
        <>
            <Toast ref={(el) => setToast(el)} />

            {isLoading ? <div className="loader">
                <ProgressSpinner />
            </div> : treeData && treeData.length ? <Dialog header="Header" style={{ width: '100%' }} visible={isOpen} onHide={() => handleModalState()} footer={footerContent}>
                <div id="treeContainer" style={containerStyles}>
                    <OrganizationChart value={treeData} />
                </div>
            </Dialog> : null}
        </>
    );
};

export default HierarchyChartModal;

