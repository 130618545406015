import * as ActionTypes  from '../actionTypes';

const dispatchSelectedBoard = (boardId, boardName) => ({
    type: ActionTypes.SELECT_BOARD,
    boardId,
    boardName
})


const dispatchSelectedClass = (classId, className) => ({
    type: ActionTypes.SELECT_CLASS,
    classId,
    className
})
const dispatchSelectedGroup = (groupId, groupName) => ({
    type: ActionTypes.SELECT_GROUP,
    groupId,
    groupName
})
const dispatchSelectedSubject = (subjectId, subjectName) => ({
    type: ActionTypes.SELECT_SUBJECT,
    subjectId,
    subjectName
})

const dispatchSelectedMultipleSubjects = subjects => ({
    type: ActionTypes.SELECT_MULTIPLE_SUBJECTS,
    subjects
})

const dispatchSelectedTopic = (topicId, topicName) => ({
    type: ActionTypes.SELECT_TOPIC,
    topicId,
    topicName
})

const dispatchSelectedMultipleTopics = topics => ({
    type: ActionTypes.SELECT_MULTIPLE_TOPICS,
    topics
})

const dispatchSelectedChapter = (chapterId, chapterName) => ({
    type: ActionTypes.SELECT_CHAPTER,
    chapterId,
    chapterName
})

const dispatchQuestionTarget = (questionTarget) => ({
    type: ActionTypes.SELECT_QUESTION_TARGET,
    questionTarget
})

const dispatchSelectedMultipleChapters = chapters => ({
    type: ActionTypes.SELECT_MULTIPLE_CHAPTERS,
    chapters
})


export const clearSelectedOrganizationData = () => ({
    type: ActionTypes.CLEAR_SELECTED_ORGANIZATION_DATA
})

export const clearSelectedOrganizationDataSelections = () => ({
    type: ActionTypes.CLEAR_SELECTED_ORGANIZATION_DATA_SELECTIONS
})



export const selectBoard = (boardId, boardName) => dispatch => dispatch(dispatchSelectedBoard(boardId, boardName));
export const selectClass = (classId, className) => dispatch => dispatch(dispatchSelectedClass(classId, className));
export const selectGroup = (groupId, groupName) => dispatch => dispatch(dispatchSelectedGroup(groupId, groupName));
export const selectSubject = (subjectId, subjectName) => dispatch => dispatch(dispatchSelectedSubject(subjectId, subjectName));
export const selectTopic = (topicId, topicName) => dispatch => dispatch(dispatchSelectedTopic(topicId, topicName));
export const selectChapter = (chapterId, chapterName) => dispatch => dispatch(dispatchSelectedChapter(chapterId, chapterName));
export const selectQuestionTarget = (questionTarget) => dispatch => dispatch(dispatchQuestionTarget(questionTarget));

export const selectedMultipleSubjects = subjects => dispatch => {
    return dispatch(dispatchSelectedMultipleSubjects(subjects))
}

export const selectedMultipleChapters = chapters => dispatch => {      
    return dispatch(dispatchSelectedMultipleChapters(chapters))
}

export const selectedMultipleTopics = topics => dispatch => {
    return dispatch(dispatchSelectedMultipleTopics(topics))
}