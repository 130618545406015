import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import SingleQuestion from '../../../questionBank/common/singleQuestion';
import CkEditorComponent from '../../../questionBank/common/ckEditor';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import _ from 'lodash';
import { isDigit } from '../../../../utile';
import Service from '../../../../services';
import { baseUrlAdmin, questionTypeForAdd } from '../../../../store/apiConstants';
import LoadingComponent from './../../../loadingComponent'

import ImageEditor from '@toast-ui/react-image-editor';
import './styles.scss'
import 'tui-image-editor/dist/tui-image-editor.css';
import { S3_UPLOAD_CONFIG } from '../../../../constants';
import { QuestionTypeKeysWithAQ } from './../../../questionBank/constants';
import AWS from 'aws-sdk';
import { InputTextarea } from 'primereact/inputtextarea';
import { IndexToAlphabet, OptionToAlphabet } from '../../../../utile';
const s3Bucket = new AWS.S3({
    accessKeyId: S3_UPLOAD_CONFIG.accessKeyId,
    secretAccessKey: S3_UPLOAD_CONFIG.secretAccessKey,
    region: S3_UPLOAD_CONFIG.region,

});



const myTheme = {
    "header.display": "none",
    // "common.backgroundColor": 'white',
    // "loadButton.backgroundColor": 'white',
    // "downloadButton.backgroundColor": 'white',
    // "submenu.backgroundColor": 'white',
    // "checkbox.backgroundColor": 'white',
    // "range.value.backgroundColor": 'white'
};


export function base64ToBlob(data) {
    const rImageType = /data:(image\/.+);base64,/;
    let mimeString = '';
    let raw, uInt8Array, i;

    raw = data.replace(rImageType, (header, imageType) => {
        mimeString = imageType;

        return '';
    });

    raw = atob(raw);
    const rawLength = raw.length;
    uInt8Array = new Uint8Array(rawLength); // eslint-disable-line

    for (i = 0; i < rawLength; i += 1) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: mimeString });
}


const getPSGQType = (type) => {
    switch (type) {
        case 'PQS':
            return "PSGS";
        case 'PQM':
            return "PSGM";
        default:
            return type;
    }
}


function processExamResult(db) {
    let examResult = _.cloneDeep(db)
    let questions = [];
    examResult.sections.forEach((section, i) => {
        let markingScheme = section.markingScheme;
        let processed = section.questions.map(q => {
            let scheme = markingScheme.find(s => s.questionType == getPSGQType(q.questionType));

            q.positiveMarking = scheme.positiveMarking;
            q.negativeMarking = scheme.negativeMarking;
            q.partialMarking = scheme.partialMarking;
            q.sectionName = section.sectionName;
            q.sectionIndex = i;

            if (!q.userResponse) {
                q.userResponse = {
                    marks: 0,
                    actualAnswer: q.key
                };
            } else {
                q.userResponse = {
                    ...q.userResponse,
                    actualAnswer: q.key,

                };

                if (!q.userResponse.marks) {
                    q.userResponse.marks = 0
                }

            }


            return q;
        });
        questions = [...questions, ...processed]
    });
    return {
        ...examResult,
        questions: questions
    }

}



export default class ExamCorrection extends Component {
    constructor(props) {
        super(props);
        this.examresult = processExamResult(this.props.examResultForCorrection)

        this.state = {
            examResult: this.examresult,
            questions: this.examresult.questions,
            currentQuestionIndex: 0,
            comments: (this.examresult.sections[0].questions[0].userResponse && this.examresult.sections[0].questions[0].userResponse.comments) ? this.examresult.sections[0].questions[0].userResponse.comments : '',
            marks: (this.examresult.sections[0].questions[0].userResponse && this.examresult.sections[0].questions[0].userResponse.marks) ? this.examresult.sections[0].questions[0].userResponse.marks : '',
            isPreview: false,
            teacherComment: this.examresult.teacherComment || '',
            currentAnswerImageIndex: 0,
            isShowImageCorrection: false,
            //...state

        }
        this.service = new Service();
    }

    goToNextQuestion = async (isNext) => {
        let questions = this.state.questions;
        let examResult = this.state.examResult;

        this.setState({
            isLoading: true
        })

        let abc = this.editorRef;
        let userResponse = questions[this.state.currentQuestionIndex].userResponse || {}
        if (abc && (questions[this.state.currentQuestionIndex].questionType == QuestionTypeKeysWithAQ.VSAQ || questions[this.state.currentQuestionIndex].questionType == QuestionTypeKeysWithAQ.SAQ || questions[this.state.currentQuestionIndex].questionType == QuestionTypeKeysWithAQ.LAQ)) {

            let fileInfo;
            if (userResponse && userResponse.attachments && userResponse.attachments[this.state.currentAnswerImageIndex]) {
                fileInfo = userResponse.attachments[this.state.currentAnswerImageIndex];
                this.setState({
                    isLoading: true
                })

                abc.imageEditorInst.resetZoom()
                const dataURL = abc.imageEditorInst.toDataURL()
                let blob = base64ToBlob(dataURL);
                let data = await this.uploadTeacherCorrectionImageToS3(blob, { key: fileInfo && fileInfo.correctionUri })
                if (data && data.Location) {
                    userResponse.attachments[this.state.currentAnswerImageIndex].correctionUri = data.Location;
                }
            }
        }


        if (userResponse && userResponse.attachments && userResponse.attachments.length) {
            userResponse.attachments.forEach(a => {
                if (!a.correctionUri) {
                    a.correctionUri = a.uri;
                }
            })


        }



        let index = this.state.currentQuestionIndex;
        let isPreview = false;

        if (isNext) {
            if (questions.length == (this.state.currentQuestionIndex + 1)) {
                isPreview = true;

            } else {
                index = this.state.currentQuestionIndex + 1;
            }



        } else {
            index = this.state.currentQuestionIndex - 1;

        }


        // userResponse = questions[index].userResponse || {}


        if (questions[this.state.currentQuestionIndex].questionType == QuestionTypeKeysWithAQ.VSAQ || questions[this.state.currentQuestionIndex].questionType == QuestionTypeKeysWithAQ.SAQ || questions[this.state.currentQuestionIndex].questionType == QuestionTypeKeysWithAQ.LAQ) {
            questions[this.state.currentQuestionIndex].userResponse =
            {
                ...questions[this.state.currentQuestionIndex].userResponse,
                marks: parseInt(this.state.marks) > 0 ? parseInt(this.state.marks) : 0,
                comments: this.state.comments
            }
        } else {
            questions[this.state.currentQuestionIndex].userResponse =
            {
                ...questions[this.state.currentQuestionIndex].userResponse,
                comments: this.state.comments,

            }
        }



        if (isPreview) {

            questions.map(q => {
                examResult.sections[q.sectionIndex].questions.forEach(sq => {
                    if (sq.questionId = q.questionId) {
                        return q;
                    } else {
                        return sq;
                    }

                })
            })
            let totalMarksSecured = 0;
            examResult.sections.map(sec => {
                sec.markingScheme.forEach(scheme => {
                    let questions = sec.questions.filter(q => getPSGQType(q.questionType) == scheme.questionType);
                    //  questions = _.cloneDeep(questions);
                    questions.sort((a, b) => b.userResponse.marks - a.userResponse.marks);
                    questions = questions.map((q, qI) => {
                        if (qI < scheme.totalMarkedQuestions) {
                            q.isUnMarked = false;
                            totalMarksSecured += q.userResponse.marks;
                        } else {
                            q.isUnMarked = true;
                        }


                        return q
                    });

                    // questions.sort((a, b) => a.qNo = b.qNo);

                    // sec.questions.forEach((q) => {
                    //     let findQ = questions.find(sQ => sQ._id == q._id);
                    //     q.isUnMarked = findQ.isUnMarked;

                    // })


                })
            })



            examResult.totalMarksSecured = totalMarksSecured;
        }

        const question = questions[index];
        let isShowImageCorrection = false;
        let dataa = question.userResponse && question.userResponse.attachments && question.userResponse.attachments[0]

        if (dataa) {
            // abc.imageEditorInst.zoom(1.0, 1.0)
            // abc.imageEditorInst.resetZoom()
            abc.imageEditorInst.loadImageFromURL(dataa.correctionUri ? dataa.correctionUri : dataa.uri, dataa.name);
            abc.imageEditorInst.resetZoom()
            isShowImageCorrection = true;

        } else {
            // abc.imageEditorInst.zoom(1.0, 1.0)
            //  abc.imageEditorInst.resetZoom()
            abc.imageEditorInst.loadImageFromURL('', '')
            abc.imageEditorInst.resetZoom()
            isShowImageCorrection = false;
        }


        this.setState((prevState) => {
            return {
                currentQuestionIndex: index,
                marks: questions[index].userResponse.marks != undefined ? questions[index].userResponse.marks : '',
                comments: questions[index].userResponse.comments || '',
                examResult,
                isPreview: isPreview,
                currentAnswerImageIndex: 0,
                isShowImageCorrection,
                isLoading: false
            };
        });
    }

    goToNextAnswerImage = async (isNext) => {
        let abc = this.editorRef;
        let examResult = this.state.examResult;


        if (isNext) {
            let userResponse = this.state.questions[this.state.currentQuestionIndex].userResponse || {}
            let attachments = userResponse && userResponse.attachments && userResponse.attachments;

            if (!(this.state.currentAnswerImageIndex + 1 < attachments.length)) {
                return true
            }
        } else {
            if (this.state.currentAnswerImageIndex == 0) {
                return true;
            }
        }


        this.setState({
            isLoading: true
        });

        if (abc) {
            abc.imageEditorInst.resetZoom()
            const dataURL = abc.imageEditorInst.toDataURL()
            let blob = base64ToBlob(dataURL);
            let userResponse = this.state.questions[this.state.currentQuestionIndex].userResponse || {}
            let fileInfo = userResponse && userResponse.attachments && userResponse.attachments[this.state.currentAnswerImageIndex];
            let data = await this.uploadTeacherCorrectionImageToS3(blob, { key: fileInfo && fileInfo.correctionUri })
            if (data && data.Location) {
                userResponse.attachments[this.state.currentAnswerImageIndex].correctionUri = data.Location;
            }
        }

        let index = this.state.currentAnswerImageIndex;
        if (isNext) {
            index += 1;

        } else {
            index -= 1;
        }

        const question = this.state.questions[this.state.currentQuestionIndex];

        let dataa = question.userResponse.attachments[index]

        let isShowImageCorrection = false;

        if (dataa) {
            // abc.imageEditorInst.zoom(1.0, 1.0)
            //   abc.imageEditorInst.resetZoom()
            abc.imageEditorInst.loadImageFromURL(dataa.correctionUri ? dataa.correctionUri : dataa.uri, dataa.name);
            abc.imageEditorInst.resetZoom()
            isShowImageCorrection = true;
        } else {
            isShowImageCorrection = false;
        }

        this.setState({
            examResult,
            currentAnswerImageIndex: index,
            isShowImageCorrection,
            isLoading: false
        })

    };

    goBackFromPreview = () => {
        let index = this.state.currentQuestionIndex;
        let userResponse = this.state.questions[index].userResponse || {}
        this.setState({
            marks: userResponse.marks || '',
            comments: userResponse.comments || '',
            isPreview: false
        })
    }


    uploadTeacherCorrectionImageToS3 = async (blob, fileInfo) => {
        const uuidv4 = require('uuid/v4');
        let folderName = 'examresults/'
        let fileName = uuidv4() + '.png';

        const params = {
            Bucket: S3_UPLOAD_CONFIG.bucketName,
            Key: fileInfo && fileInfo.name ? fileInfo.name : folderName + fileName,
            Body: blob,
            ContentType: 'image/jpeg'
        };


        let data = await new Promise((res, rej) => {
            return s3Bucket.upload(params, function (err, data) {
                if (err) {
                    //         console.log('There was an error uploading your file: ', err);
                    rej(err)
                }

                res(data);
                //    console.log('Successfully uploaded file.', data);
                return true;
            });
        });

        return data;

    }

    saveTeacherCorrections = () => {
        this.setState({
            isLoading: true,
        });

        let thisObj = this;

        let examResult = _.cloneDeep(this.state.examResult);

        delete examResult.questions;
        examResult.teacherComment = this.state.teacherComment;

        if (this.props.Reevaluation) {
            examResult.examStatus = 'REEVALUATED';
        } else {
            examResult.examStatus = 'EVALUATED';
        }
        this.service.post(`${baseUrlAdmin}/results/subjective/savecorrection`, examResult, true).then((data) => {


            if (data && data.status && data?.res?.status) {
                this.setState({
                    isLoading: false
                }, () => {
                    this.props.onCorrectionDone()
                })
            } else {
                this.setState({
                    isLoading: false
                });

                thisObj.toast.show({ severity: 'error', summary: 'Error', detail: "Some error occured", life: 3000 });
            }


        }).catch(e => {
            this.setState({
                isLoading: false
            })

            thisObj.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            console.log(e);

        });
    }

    onChallengeStatusChange = (isChallengeAccepted) => {
        this.state.questions[this.state.currentQuestionIndex].isChallengeAccepted = isChallengeAccepted;
        this.setState({
            questions: this.state.questions
        });
    }

    onValidateClick = (isCorrect) => {

        if (isCorrect) {
            this.state.questions[this.state.currentQuestionIndex].userResponse = {
                ...this.state.questions[this.state.currentQuestionIndex].userResponse,
                isCorrect: true,
                marks: this.state.questions[this.state.currentQuestionIndex].positiveMarking

            };
        } else {
            this.state.questions[this.state.currentQuestionIndex].userResponse = {
                ...this.state.questions[this.state.currentQuestionIndex].userResponse,
                isCorrect: false,
                marks: -this.state.questions[this.state.currentQuestionIndex].negativeMarking
            };
        }


        this.setState({
            questions: this.state.questions
        })

    }


    componentDidMount() {
        let { questions, currentQuestionIndex } = this.state;
        
        if (questions && questions.length) {
            let questionType = questions[currentQuestionIndex].questionType
            if ((questionType == QuestionTypeKeysWithAQ.LAQ || questionType == QuestionTypeKeysWithAQ.SAQ || questionType == QuestionTypeKeysWithAQ.VSAQ) &&
                (questions[currentQuestionIndex].userResponse && questions[currentQuestionIndex].userResponse.attachments
                    && questions[currentQuestionIndex].userResponse.attachments.length)
            ) {

                

                this.setState({
                    isShowImageCorrection: true
                });
                let abc = this.editorRef;
                // abc.imageEditorInst.zoom(1.0, 1.0)
                // abc.imageEditorInst.resetZoom()
                abc.imageEditorInst.loadImageFromURL(questions[currentQuestionIndex].userResponse.attachments[0].correctionUri ? questions[currentQuestionIndex].userResponse.attachments[0].correctionUri : questions[currentQuestionIndex].userResponse.attachments[0].uri, questions[currentQuestionIndex].userResponse.attachments[0].name);
                abc.imageEditorInst.resetZoom()
            }
        }
    }

    resetCorrection = () => {
        let abc = this.editorRef;
        this.state.questions[this.state.currentQuestionIndex].userResponse.attachments[this.state.currentAnswerImageIndex].correctionUri = undefined;

        let dataa = this.state.questions[this.state.currentQuestionIndex].userResponse.attachments[this.state.currentAnswerImageIndex]
        if (dataa) {
            // abc.imageEditorInst.zoom(1.0, 1.0)
            //  abc.imageEditorInst.resetZoom()
            abc.imageEditorInst.loadImageFromURL(dataa.uri, dataa.name);
            abc.imageEditorInst.resetZoom()

        }

        this.setState({
            questions: this.state.questions
        })

    }

    onResetZoom = () => {

        let abc = this.editorRef;
        abc.imageEditorInst.resetZoom() //(0, 0, 1.0)
    }

    getImage = (question, ) => {
        let abc = 'https://classet-profiles-old.s3.ap-south-1.amazonaws.com/uploads/2022-07-29T06%3A33%3A51.825Zabc.PNG';


        if (question?.userResponse?.attachments?.length) {
            abc = question.userResponse.attachments[0].correctionUri ? question.userResponse.attachments[0].correctionUri : question.userResponse.attachments[0].uri

        }


        return abc;
    }

    render() {
        //  console.log(this.state, 'saved')
        const { examResult, currentQuestionIndex, comments, marks, isPreview, teacherComment, currentAnswerImageIndex } = this.state;
        const question = examResult.questions[currentQuestionIndex];
    //    console.log(this.state, 'abcs')
        return (<div className='question-in-tbl exam-correction'>
            {!isPreview ? <>
                <div className='ma-paper ma-mt20'>
                    <p className='question-label'>Question {currentQuestionIndex + 1}  ({question.questionType})</p>

                    <SingleQuestion mathJaxValue={question.question} attachment={question.attachmentUrl} />

                </div>
                <div className='p-grid ma-mt10'>
                    <div className='p-col-12 p-md-6 '>
                        <div className='ma-mr5 ma-paper'>
                            <p className='question-label'>User Answer</p>
                            <p className='ma-poppins'>{
                                question.userResponse?.userAnswer
                            }
                            </p>

                            {
                                this.state.isShowImageCorrection && <Button className='ma-m10' label="Reset Corrections" onClick={this.resetCorrection} />
                            }
                            {
                                <div className={`${this.state.isShowImageCorrection ? '' : 'ma-hidden'}`}>
                                    <ImageEditor
                                        includeUI={{
                                            loadImage: {
                                                path: this.getImage(question, currentQuestionIndex),
                                                name: 'test img',
                                            },
                                            theme: myTheme,

                                            menu: ['draw', 'text', 'shape', 'Rotate'],
                                            initMenu: 'draw',
                                            uiSize: {
                                                "width": '100%',
                                                height: '700px',
                                            },
                                            menuBarPosition: 'bottom',
                                        }}
                                        cssMaxHeight={500}
                                        cssMaxWidth={700}
                                        selectionStyle={{
                                            cornerSize: 20,
                                            rotatingPointOffset: 70,
                                        }}
                                        usageStatistics={false}
                                        // ref={this.editorRef}
                                        ref={(el) => this.editorRef = el}
                                    />
                                    {
                                        (question.userResponse && question.userResponse.attachments && question.userResponse.attachments.length) ? <p className='ma-center ma-cr-pagin'>
                                            <span className='cr-pagi-l' onClick={() => { this.goToNextAnswerImage(false) }}><i className='pi pi-angle-double-left' /> </span>
                                            <span className='cr-pagi-v'>Page {currentAnswerImageIndex + 1} /{question.userResponse.attachments.length} </span>
                                            <span className='cr-pagi-r' onClick={() => { this.goToNextAnswerImage(true) }}> <i className='pi pi-angle-double-right' /> </span>
                                        </p> : null
                                    }

                                </div>}
                        </div>
                    </div>
                    <div className='p-col-12 p-md-6 '>
                        <div className='ma-ml5 ma-paper'>
                            <p className='question-label'>Answer</p>
                            <SingleQuestion mathJaxValue={question.key} attachment={question.keyUrl} />
                            {
                                (question.questionType == QuestionTypeKeysWithAQ.FB) && <>
                                    <p className='question-label'>Alternate Answer</p>
                                    <SingleQuestion mathJaxValue={question.alternateKey} attachment={question.alternateKeyUrl} />
                                </>}
                            {
                                (question.questionType == QuestionTypeKeysWithAQ.VSAQ || question.questionType == QuestionTypeKeysWithAQ.SAQ || question.questionType == QuestionTypeKeysWithAQ.LAQ) && <>
                                    <p className='question-label'>Scheme Of Validation</p>
                                    <SingleQuestion mathJaxValue={question.schemeOfValidation} attachment={question.schemeOfValidationUrl} />
                                </>
                            }

                            {(question.questionType == QuestionTypeKeysWithAQ.SCCT || question.questionType == QuestionTypeKeysWithAQ.MCCT || question.questionType == QuestionTypeKeysWithAQ.PSGS || question.questionType == QuestionTypeKeysWithAQ.PSGM) && <>
                                <p className='question-label'>Options</p>
                                {
                                    question.options && question.options.length && question.options.map((a, oIndex) => {
                                        return <div className={`option-w ${oIndex % 2 == 0 ? 'sp-clearFix' : ''}`} key={a.id}>
                                            <SingleQuestion pclassName="question" optionN={IndexToAlphabet(oIndex + 1)} mathJaxValue={a.value} attachment={a.imageUrl} />
                                        </div>
                                    })
                                }
                            </>}
                            {
                                question.isChallenged && <>
                                    <p className='question-label'>Student Comments</p>
                                    {/* <div className='sp-ml10 e-instructions' dangerouslySetInnerHTML={{ __html: question.comments ? question.comments : '' }}> </div> */}
                                    <p className='student-comments'>{(question.challengeComments && question.challengeComments != '') ? question.challengeComments : 'No Comments'}</p>
                                </>
                            }
                            <hr />
                            <p className='question-label ma-mt20'>Teacher Comments</p>
                            {/* <CkEditorComponent
                                value={comments}
                                onChange={(value) => {
                                    if (value && value != '') {
                                        this.setState({
                                            comments: value,
                                        })
                                    }
                                }
                                }
                            /> */}
                            <InputTextarea
                                rows={4}
                                placeholder="" className='p-inputtext-style1 ma-w100p'
                                onChange={(e) => {
                                    this.setState({
                                        comments: e.target.value,
                                    })
                                }
                                }
                                //disabled={!(question.isChallenged && (question.isChallengeAccepted ? true : false))}
                                //disabled={(question.isChallenged && (question.isChallengeAccepted ? false : true))}
                                disabled={(question.isChallenged && (question.isChallengeAccepted ? false : true) || (this.props.Reevaluation && !(question.isChallenged ? true : false)))}
                                value={comments}
                            />


                            {
                                (question.questionType == QuestionTypeKeysWithAQ.PQS || question.questionType == QuestionTypeKeysWithAQ.PQM || question.questionType == QuestionTypeKeysWithAQ.SCCT || question.questionType == QuestionTypeKeysWithAQ.MCCT || question.questionType == QuestionTypeKeysWithAQ.PSGS || question.questionType == QuestionTypeKeysWithAQ.PSGM || question.questionType == QuestionTypeKeysWithAQ.INT || question.questionType == QuestionTypeKeysWithAQ.FB || question.questionType == QuestionTypeKeysWithAQ.NUM) && <div className='ma-mt10'>
                                    <Button label='CORRECT'
                                        disabled={(question.isChallenged && (question.isChallengeAccepted ? false : true) || (this.props.Reevaluation && !(question.isChallenged ? true : false)) ||
                                            (!(question?.userResponse?.isAnswered)))}
                                        className={`${(question.userResponse.isCorrect ? '' : 'p-button-outlined ')} p-button-success`} onClick={() => { this.onValidateClick(true) }} />
                                    <Button label='WRONG'
                                        disabled={(question.isChallenged && (question.isChallengeAccepted ? false : true) || (this.props.Reevaluation && !(question.isChallenged ? true : false)) ||
                                            (!(question?.userResponse?.isAnswered)))}



                                        className={`${(question.userResponse.isCorrect == false ? ' ' : 'p-button-outlined ')}  p-button-danger ma-ml20`} onClick={() => { this.onValidateClick(false) }} />

                                </div>
                            }


                            {(question.questionType == QuestionTypeKeysWithAQ.VSAQ || question.questionType == QuestionTypeKeysWithAQ.SAQ || question.questionType == QuestionTypeKeysWithAQ.PSGS || question.questionType == QuestionTypeKeysWithAQ.LAQ) && <>
                                <p className='question-label ma-mt20'>Marks</p>
                                <div>
                                    <InputText placeholder="" className='p-inputtext-style1 ma-w100'
                                        onChange={(e) => {
                                            if (e.target.value == '' || !isDigit(e.target.value)) {
                                                if (e.target.value == '') {
                                                    this.setState({
                                                        marks: e.target.value,
                                                    })
                                                } else if (question.positiveMarking >= parseInt(e.target.value)) {

                                                    this.setState({
                                                        marks: e.target.value,
                                                    })
                                                } else {
                                                    this.toast.show({ severity: 'warn', summary: 'Warning', detail: 'you can not give more than assigned marks.', life: 3000 });
                                                }

                                            } else {
                                                this.toast.show({ severity: 'warn', summary: 'Warning', detail: 'Only numbers are allowed.', life: 3000 });
                                            }
                                        }
                                        }
                                        disabled={(question.isChallenged && (question.isChallengeAccepted ? false : true) ||
                                            (this.props.Reevaluation && !(question.isChallenged ? true : false)) ||
                                            (!(question?.userResponse?.isAnswered))
                                        )}
                                        value={marks}

                                    /> <span className='p-marks'> / {question.positiveMarking} </span>
                                </div>
                            </>}
                            {
                                question.isChallenged && <div className='ma-mt10'>
                                    <span className='ma-poppins'> Marks Challenge </span>
                                    <Button label='ACCEPT' className={`${question.isChallengeAccepted == true ? '' : 'p-button-outlined'} p-button-success`} onClick={() => { this.onChallengeStatusChange(true) }} />
                                    <Button label='REJECT' className={`${question.isChallengeAccepted == false ? '' : 'p-button-outlined'} p-button-danger ma-ml20`} onClick={() => { this.onChallengeStatusChange(false) }} />

                                </div>
                            }
                            {/* <Button label='Save' className='ma-mt10' onClick={this.onResetZoom} /> */}
                            {/* {this.state.isMarksSaved && <i className='pi pi-check' />} */}
                            <Toolbar className="ma-toolbard ma-mt20"
                                right={() => {
                                    return <>
                                        {/* {
                            this.state.isShowPreview ? <Button label={`${this.props.editPlannerData ? 'Update' : 'Add'}`} className=' ma-mr20 ' onClick={this.savePlanner} /> :
                                <Button label={'Preview'} className=' ma-mr20 ' onClick={this.onSubmitClick} />
                        } */}
                                        {
                                            currentQuestionIndex ? <Button label="Save & Prev" className='p-button-outlined p-button-secondary ma-mr10' onClick={() => this.goToNextQuestion()} /> : null
                                        }
                                        <Button label={(currentQuestionIndex + 1) == examResult.questions.length ? 'Save & Preview' : "Save & Next"} className='p-button-secondary' onClick={() => this.goToNextQuestion(true)}
                                        />
                                    </>
                                }}
                            ></Toolbar>
                        </div>
                    </div>

                </div>
            </> : <div className='scoring-preview'>
                <h3 className='sc-title' >Scoring Preview</h3>
                <div className='ma-paper'>
                    <Button icon="pi pi-arrow-left" className="p-button-rounded p-button-text ma-bg-white" aria-label="Back" onClick={this.goBackFromPreview} />
                    <p className='sc-name'><span>Student Name: {examResult.user_name}</span> <span className='ma-ml30'>Phone Number: {examResult.phone_number}</span></p>
                    <p className='sc-name'><span>Test Name: {examResult.examName}</span>   <span className='ma-ml30'>Test Type: {examResult.testType}</span> <span className='ma-ml30'>Duration: {examResult.totalTime}</span> <span className='ma-ml30'>Total Marks: {examResult.totalMarks}</span></p>
                    <h2 className='ma-poppins ma-no-pm'>Marks</h2>
                    <div className='p-grid'>
                        {examResult.sections.map((sec, k) => {
                            return <div key={k} className='p-col-3'>
                                <p className='ma-poppins '>{sec.sectionName}</p>
                                <div >
                                    {
                                        sec.questions.map((q, qIndex) => {
                                            return <div key={'a' + qIndex}><div className='p-grid sc-mark-c'> <div className='ma-inline-block sc-mark-l ma-poppins'>Q{q.qNo} <span className='q-unmarked'>{q.isUnMarked ? '*' : ''}</span><span className='ma-danger'>{q.isChallenged ? '*' : ''}</span></div>
                                                <div className={`sc-marks ma-bold ${q.isUnMarked ? '' : 'ma-txt-primary'}`}>{q.userResponse.marks} </div> <span className='sc-outof'>/{q.positiveMarking}</span> </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        })}
                    </div>
                    <div>
                        <p className='ma-poppins'><span className='ma-txt-primary'>* </span> Unmarked Questions  <span className='ma-danger ma-pl50'>* </span> Review Requested</p>
                        <div className='p-grid sc-mark-c'>
                            <div className='ma-inline-block sc-mark-total ma-poppins '>Total Marks</div>  <div className='ma-inline-block sc-marks ma-txt-primary ma-bold'>{examResult.totalMarksSecured}</div><div className='ma-inline-block sc-outof'>/ {examResult.totalMarks}</div>
                        </div>
                        <div className='p-grid'>
                            <div className='p-col-12 p-md-5'>
                                <p className='question-label ma-mt20'>Comments</p>
                                {/* <CkEditorComponent
                                    value={teacherComment}
                                    onChange={(value) => {
                                        if (value && value != '') {
                                            this.setState({
                                                teacherComment: value,
                                            })
                                        }
                                    }}

                                /> */}
                                <InputTextarea
                                    rows={4}
                                    placeholder="" className='p-inputtext-style1 ma-w100p'
                                    onChange={(e) => {
                                        this.setState({
                                            teacherComment: e.target.value,
                                        })
                                    }
                                    }
                                    value={teacherComment}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            <Toolbar className="ma-toolbard ma-mt20"
                right={() => {
                    return <>
                        {
                            this.state.isPreview && <Button label={'Submit'} className=' ma-mr20 ' onClick={this.saveTeacherCorrections} />
                        }
                        {/* {
                            this.state.isShowPreview ? <Button label={`${this.props.editPlannerData ? 'Update' : 'Add'}`} className=' ma-mr20 ' onClick={this.savePlanner} /> :
                                <Button label={'Preview'} className=' ma-mr20 ' onClick={this.onSubmitClick} />
                        } */}
                        <Button label="Cancel" className='p-button-outlined' onClick={this.props.onCancel} />
                    </>
                }}
            ></Toolbar>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            {
                this.state.isLoading && <LoadingComponent />
            }
        </div>
        )
    }
}

