import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'
import { baseUrlAdmin } from '../../../store/apiConstants';
import Service from '../../../services';
import { baseUrlForLongRunningTask } from '../../../store/apiConstants';

class ReportDownload extends Component {

    constructor(props) {
        super(props);
        const { userId, examId } = this.props.match.params
        this.state = {
            userId,
            examId,
            objRep: [],
            isLoading: false,
            showingReports: true,
            objReport:{},


            series: [],
            options: {}
        }
        this.service = new Service();
    }

    componentDidMount() {
     
        if (this.state.userId && this.state.examId) {
            this.reports(this.state.examId, this.state.userId)
        }
    }



    reports = (examId, userId) => {


        //let url = `${baseUrlAdmin}/results/get/adhoctest/${examId}/${userId}`
        // this.setState({ isLoading: true })
        // this.service.post(url, { "isChallenged": { "$ne": true } }, true).then(res => {
      
        const url = `${baseUrlForLongRunningTask}/results/get/test/${examId}/results`
        this.service.get(url, true).then(res => {
            if (res && res.res && res.res.examResult) {

                const series = [];
                const objRep = [];
                //const objReport = {};
                let destinationObject;
                
                const options = {
                    xaxis: { categories: [] },
                    yaxis: { title: { text: 'Marks' } },
                    chart: { toolbar: { show: false } }
                };

                for (let i = 0; i < res.res.examResult.length; i++) {
                    const obj = res.res.examResult[i];

                    
                    series.push({
                        name: 'Max marks', data: [...obj?.maxMarks],
                        name: 'Sub.high score', data: [...obj?.subHighScore],
                        name: 'Obtained marks', data: [...obj && obj?.obtainedMarks]

                    })

                    options.xaxis.categories.push(...obj?.subjectsCategories);
                     destinationObject = JSON.parse(JSON.stringify(obj));
               
                }


                this.setState({
                    isLoading: false,
                    showingReports: true,
                    objReport: destinationObject,
                   
                     series: series,
                     options: options
                })

            } else {
                // this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(err => {
            console.log('err', err)
            // this.setState({ isLoading: false })
            // this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
        })
    }

    render() {
        const { objReport } = this.state
        console.log( this.state.objReport , "objReport")
        return (

          
                <div  style={{ border: "1px black solid" }} className='progress-report ma-w100p'>
                    <div className='ma-p10' style={{ paddingBottom: "0px" }}>
                        <img src={`${objReport?.orgImageWithDetails?.storageLocation ? objReport?.orgImageWithDetails?.storageLocation : ""}`} width={'100%'} height={'80'} className='logo-with-details' />
                    </div>
                    
                    <h3 className='ma-text-center'>STUDENT PROFILE</h3>
                    <div className=' p-grid'>
                        <div className='stud-details span-font-size p-col-9 p-grid ma-ml20'>
                            <div className='p-col-4 ma-mt10' style={{ padding: "0.2rem" }}>
                                <span style={{ fontWeight: "bold" }}>Name of the Student </span>
                            </div>
                            <div className='p-col-8 ma-mt10' style={{ padding: "0.2rem" }}>
                                <span style={{ fontWeight: "bold" }}>: {objReport?.nameOfTheStudent}</span>
                            </div>
                            <div className='p-col-4' style={{ padding: "0.2rem" }}>
                                <span style={{ fontWeight: "bold" }}>Father's Name </span>
                            </div>
                            <div className='p-col-8' style={{ padding: "0.2rem" }}>
                                <span style={{ fontWeight: "bold" }}>: {objReport?.fathersName}</span>
                            </div>
                            <div className='p-col-4' style={{ padding: "0.2rem" }}>
                                <span style={{ fontWeight: "bold" }}>Mother's Name </span>
                            </div>
                            <div className='p-col-8' style={{ padding: "0.2rem" }}>
                                <span style={{ fontWeight: "bold" }}>: {objReport?.mothersName}</span>
                            </div>
                            <div className='p-col-4' style={{ padding: "0.2rem" }}>
                                <span style={{ fontWeight: "bold" }}>Class </span>
                            </div>
                            <div className='p-col-8' style={{ padding: "0.2rem" }}>
                                <span style={{ fontWeight: "bold" }}>: {objReport?.class}</span>
                            </div>
                            <div className='p-col-4' style={{ padding: "0.2rem" }}>
                                <span style={{ fontWeight: "bold" }}>Admin No. </span>
                            </div>
                            <div className='p-col-8' style={{ padding: "0.2rem" }}>
                                <span style={{ fontWeight: "bold" }}>: 1234</span>
                            </div>
                        </div>
                        <div className='p-col-2'>
                            <img src={`${objReport?.studentProfile?.storageLocation ? objReport?.studentProfile?.storageLocation : ""}`} height={'130'} width={'120'} className='ma-mt5' />
                        </div>
                    </div>
                    <div className='list-of-tables ma-ml10 ma-mr10'>
                        <table border="1" width={"100%"} className='test-table' style={{ borderCollapse: "collapse" }}>
                            <thead>
                                <tr style={{ fontWeight: "bold" }}>
                                    <td colSpan="12" width={"100%"} className='test-head ma-text-center' >PERIODIC TEST - I EXAMINATION REPORT</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='test-head'>
                                    <th width={"20%"}>Subjects</th>
                                    <th width={"15%"}>Total marks</th>
                                    <th width={"15%"}>Obtained marks</th>
                                    <th width={"10%"}>Grade</th>
                                    <th width={"10%"}>Grade point</th>
                                    <th width={"25%"}>Remarks</th>
                                </tr>
                                {objReport?.reportData?.map((e, i) => {
                                    return (
                                        <tr className='test-data ma-text-center' width={"20px"} height={e?.subjectName ? "" : "15px"}>
                                            <td>{e?.subjectName}</td>
                                            <td>{objReport?.maxMarks[i]}</td>
                                            <td>{e?.obtainedMarks}</td>
                                            <td>{e?.grade}</td>
                                            <td>{e?.gradePoint}</td>
                                            {/* <td>{e?.remarks}</td> */}
                                            <td>1234567890</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr className='test-head' style={{ fontWeight: "bold" }}>
                                    <td className='ma-text-center'>GRAND TOTAL</td>
                                    <td className='ma-text-center'>{objReport?.totalSujectsMarks}</td>
                                    <td className='ma-text-center'>{objReport?.totalObtainedMarks}</td>
                                    <td className='ma-text-center'>{objReport?.studentGrade}</td>
                                    <td className='ma-text-center'>{objReport?.studentGradePoint}</td>
                                    <td className='ma-text-center'>{objReport?.overallRemarks}</td>
                                </tr>
                            </tfoot>
                        </table>
                        <table border='1' className='test-table ma-mt10' width={"100%"} style={{ borderCollapse: "collapse" }}>
                            <tbody>
                                <tr className='test-head' style={{ fontWeight: "bold" }}>
                                    <th width={"20%"}>Grade</th>
                                    <th >A+</th><th >A</th><th >B+</th><th >B</th><th >C</th><th >D</th><th >E</th>
                                </tr>
                                <tr className='test-data ma-text-center'>
                                    <td width={"20%"}>Range (%)</td>
                                    <td >90-100</td><td >80-89</td><td >70-79</td><td >60-69</td><td >45-59</td>
                                    <td >44-33</td><td >BELOW 33</td>
                                </tr>
                            </tbody>
                        </table>
                        <table border='1' className='test-table ma-mt10' width={"100%"} style={{ borderCollapse: "collapse" }}>
                            <tbody>
                                <tr className='test-head ma-text-center'>
                                    <td width={"15%"}>Month</td>
                                    <td >APR</td><td >MAY</td><td >JUN</td><td >JUL</td><td >AUG</td><td >SEP</td>
                                    <td >OCT</td><td >NOV</td><td >DEC</td><td >JAN</td><td >FEB</td><td >MAR</td>
                                    <td>TOTAL</td><td>PER(%)</td>
                                </tr>
                                <tr className='test-data ma-text-center'>
                                    <td width={"15%"}>Working Days</td>
                                    <td ></td><td ></td><td ></td><td ></td><td ></td><td ></td><td ></td>
                                    <td ></td><td ></td><td ></td><td ></td><td ></td><td ></td><td ></td>
                                </tr>
                                <tr className='test-data ma-text-center'>
                                    <td width={"15%"}>Days Present</td>
                                    <td ></td><td ></td><td ></td><td ></td><td ></td><td ></td><td ></td>
                                    <td ></td><td ></td><td ></td><td ></td><td ></td><td ></td><td ></td>
                                </tr>
                            </tbody>
                        </table>
                        <table border='1' className='test-table ma-mt10' width={"100%"} style={{ borderCollapse: "collapse" }}>
                            <tr className='test-head ma-text-center'>
                                <td width={'50%'}>CLASS TEACHER REMARK</td>
                                <td>PARENTS REMARK</td>
                            </tr>
                            <tbody>
                                <tr>
                                    <td className='remarks-height' height={'100px'}></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h3 className='graph-head ma-text-center ma-mt10'>Graphycal Analysis of {objReport?.nameOfTheStudent} Marks</h3>
                    <div>
                       {this.state?.options &&this.state?.series ? <ReactApexChart className='graph-height' options={this.state?.options} series={this.state?.series} type="bar" height={250} />:null}
                    </div>
                    <div className='ma-ml20 ma-mr20 ma-mb10' style={{ display: "flex", justifyContent: "space-between", marginTop: "50px" }}>
                        <h3>Class Teacher's Sign</h3>
                        <h3>Bigmaster's Sign</h3>
                        <h3>Parent/Guardian's Sign</h3>
                    </div>
                </div>



           

        )
    }
}

export default ReportDownload