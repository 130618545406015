import React from "react";
import "./index.css";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveLine } from '@nivo/line';
import "./index.scss";
import Service from './../../services';
import { baseUrlAdmin } from "../../store/apiConstants";
import { Dropdown } from "primereact/dropdown";
import { getRegex } from "../../utile";
const _ = require("lodash")

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    getContentAnchorEl: null
};

const data = [
    {
        "id": "Marks",
        "color": "hsl(59, 70%, 50%)",
        "data": [
            {
                "x": "plane",
                "y": 235
            },
            {
                "x": "helicopter",
                "y": 167
            },
            {
                "x": "boat",
                "y": 132
            },
            {
                "x": "train",
                "y": 220
            },
            {
                "x": "subway",
                "y": 145
            },
            {
                "x": "bus",
                "y": 33
            },
            {
                "x": "car",
                "y": 166
            },
            {
                "x": "moto",
                "y": 124
            },
            {
                "x": "bicycle",
                "y": 152
            },
            {
                "x": "horse",
                "y": 243
            },
            {
                "x": "skateboard",
                "y": 32
            },
            {
                "x": "others",
                "y": 300
            }
        ]
    }
];

let comparativeAnalysisDummy = [
    {
        "yaxis": "Yours",
        "Your Score": 0,
        "Average Score": 0,
        "Highest Score": 0
    },
    {
        "yaxis": 'Average',
        "Your Score": 0,
        "Average Score": 0,
        "Highest Score": 0
    },
    {
        "yaxis": "Highest",
        "Your Score": 0,
        "Average Score": 0,
        "Highest Score": 0
    }
];

class MockTestResult extends React.Component {
    constructor(props) {
        super(props);
        const { examId, userId } = this.props.match.params;
        this.state = {
            examId,
            userId,
            comparativeAnalysis: [],
            comparativeAnalysisSubjectWise: [],
            subjects: [],
            timeSlots: [],
            selectedTimeSlots: ["0-30",
                "30-60",
                "60-90",
                "90-120",
                "120-150",
                "150-180"],
            examReport: {},
            timeMagementData: [],
            timeManagementSubjectWiseData: [],
            breakDownAnalysis: {},
            timeSlots: [
                "0-30",
                "30-60",
                "60-90",
                "90-120",
                "120-150",
                "150-180"
            ],
            breakDownAnalysisPieData: [],
            examReport: {},
            currrentView: 0,
            lineChartData: [],
            reportByPreviousExams: null,
            lineChartCurrentView: 'marks',
            lineChartViews: [],

        }
        this.service = new Service();
    }


    getBreakDownAnalysisData = (examReport, selectedTimeSlots) => {
        let breakDownAnalysis = { attempted: 0, perfect: 0, overtime: 0, wasted: 0 };;

        selectedTimeSlots.forEach((slot) => {
            breakDownAnalysis.perfect += Number(examReport[`${slot}_PerfectTime`] ? examReport[`${slot}_PerfectTime`] : 0);
            breakDownAnalysis.overtime += Number(examReport[`${slot}_OverTime`] ? examReport[`${slot}_OverTime`] : 0);
            breakDownAnalysis.wasted += Number(examReport[`${slot}_Wasted`] ? examReport[`${slot}_Wasted`] : 0);
            breakDownAnalysis.attempted += (Number(examReport[`${slot}_PerfectTime`] ? examReport[`${slot}_PerfectTime`] : 0) + Number(examReport[`${slot}_OverTime`] ? examReport[`${slot}_OverTime`] : 0) + Number(examReport[`${slot}_Wasted`] ? examReport[`${slot}_Wasted`] : 0));
        });

        return breakDownAnalysis;
    }




    generateAnalyticsReports(examReport, subjects, timeSlots, reportByPreviousExams) {
        // window.scroll(0, 0);
        // if(!this.props.resultDetailsInfo) {
        //   this.props.history.push('/mock-assessments');
        // }

        let comparativeAnalysis = [];
        comparativeAnalysisDummy.forEach((r, index) => {
            let record = _.cloneDeep(r);

            if (index == 0) {
                record['Your Score'] = examReport.totalMarks
            }
            if (index == 1) {
                record['Average Score'] = examReport.averageMarks
            }

            if (index == 2) {
                record['Highest Score'] = examReport.highestMarks
            }
            comparativeAnalysis.push(record);
        });

        let comparativeAnalysisSubjectWise = [];

        subjects.forEach((subject) => {
            let subjectReport = [];
            comparativeAnalysisDummy.forEach((r, index) => {
                let record = _.cloneDeep(r);
                if (index == 0) {
                    record['Your Score'] = examReport[`${subject.replace(getRegex(' '), '_')}_marks`]
                }
                if (index == 1) {
                    record['Average Score'] = examReport[`${subject.replace(getRegex(' '), '_')}_averageMarks`]
                }
                if (index == 2) {
                    record['Highest Score'] = examReport[`${subject.replace(getRegex(' '), '_')}_highestMarks`]
                }

                subjectReport.push(record);
            });

            comparativeAnalysisSubjectWise.push({ subject, report: subjectReport });

        });

        let perfect = 0;
        let overtime = 0;
        let wasted = 0;

        let timeManagementSubjectWiseData = [];
        subjects.forEach((subject) => {
            perfect += Number(examReport[`${subject.replace(getRegex(' '), '_')}_correctPerfectTime`]) + Number(examReport[`${subject.replace(getRegex(' '), '_')}_wrongPerfectTime`]);
            overtime += Number(examReport[`${subject.replace(getRegex(' '), '_')}_correctOverTime`]) + Number(examReport[`${subject.replace(getRegex(' '), '_')}_wrongOverTime`]);
            wasted = examReport[`${subject.replace(getRegex(' '), '_')}_unAttemptedWasted`];


            let obj = [];
            obj.push(subject)
            obj.push(examReport[`${subject.replace(getRegex(' '), '_')}_correctPerfectTime`])
            obj.push(examReport[`${subject.replace(getRegex(' '), '_')}_wrongPerfectTime`])
            obj.push(examReport[`${subject.replace(getRegex(' '), '_')}_correctOverTime`])
            obj.push(examReport[`${subject.replace(getRegex(' '), '_')}_wrongOverTime`])
            obj.push(examReport[`${subject.replace(getRegex(' '), '_')}_unAttemptedWasted`])
            obj.push(Number(examReport[`${subject.replace(getRegex(' '), '_')}_accuracy`]).toFixed(0));
            obj.push(examReport[`${subject.replace(getRegex(' '), '_')}_classAccuracy`])

            timeManagementSubjectWiseData.push({ subject, values: obj })
        });





        let timeMagementData = [{ key: 'Perfect', value: perfect }, { key: 'OverTime', value: overtime, }, { key: 'Wasted', value: wasted }, { key: 'My Accuracy', value: Number(examReport.accuracy).toFixed(0) }, { key: 'Class Accuracy', value: examReport.classAccuracy }]


        let breakDownAnalysis = this.getBreakDownAnalysisData(examReport, this.state.selectedTimeSlots);

        let breakDownAnalysisPieData = [
            {
                "id": "Wasted",
                "value": breakDownAnalysis.wasted
            },
            {
                "id": "Overtime",
                "value": breakDownAnalysis.overtime
            },
            {
                "id": "Perfect",
                "value": breakDownAnalysis.perfect
            }
        ]


        let reportForLineChart = reportByPreviousExams[this.state.lineChartCurrentView];
        let lineChartData = [{ id: this.state.lineChartCurrentView, color: "rgb(100,100,100)", data: reportForLineChart }];

        let lineChartViews = [];

        Object.keys(reportByPreviousExams).forEach((key => {
            lineChartViews = [...lineChartViews, { view: key, viewDisplayName: this.getViewDisplayNames(key) }];

        }))


        this.setState({
            comparativeAnalysis,
            comparativeAnalysisSubjectWise,
            timeMagementData,
            timeManagementSubjectWiseData,
            breakDownAnalysis,
            breakDownAnalysisPieData,
            examReport: examReport,
            subjects: subjects,
            timeSlots: timeSlots,
            reportByPreviousExams,
            lineChartData,
            lineChartViews,
        });
    }


    // componentDidUpdate(prevProps) {
    //     console.log(this.props);
    //     
    //     if (!this.props.examResult) {
    //         
    //         this.props.history.push('/dashboard');
    //     }
    //     if (prevProps.examResult !== this.props.examResult && this.props.examResult && this.props.examResult.length) {
    //         
    //         this.generateAnalyticsReports(this.props.examResult[0], this.props.subjects, this.props.timeSlots);
    //     }
    // }

    getExamAnalytics = (examId) => {
        this.props.getUserExamsAnlyticsReport(examId, 'AdhocMockTest');
    }
    componentDidMount() {
        window.scroll(0, 0);
        let url = `${baseUrlAdmin}/analytics/getuserexamanalyticsreport/examId/${this.state.examId}/userId/${this.state.userId}`;
        this.service.get(url, false).then(data => {
            if (data.status) {
                if (data.res.examResult && data.res.examResult.length) {
                    this.generateAnalyticsReports(data.res.examResult[0], data.res.subjects, data.res.timeSlots, data.res.reportByPreviousExams);
                }
            }
        }).catch(e => {


        })

        // if (this.props.examResult && this.props.examResult.length) {
        //     this.generateAnalyticsReports(this.props.examResult[0], this.props.subjects, this.props.timeSlots, this.props.reportByPreviousExams);
        // }
    }


    componentWillUnmount() {
    }

    goBack = () => {

        if (this.state.currrentView == 0) {
            window.history.back();
        } else {

            this.setState({
                currrentView: 0
            });
        }

        //this.props.history.push('/mock-assessments');
    }

    getViewDisplayNames(view) {
        switch (view) {
            case 'rank':
                return ' My Rank vs Past Rank'
                break;
            case 'percentile':
                return "My Percentile vs Past Percentile";
            default:
                return `${view.charAt(0).toUpperCase() + view.slice(1)} Marks vs Past ${view.charAt(0).toUpperCase() + view.slice(1)} Marks`;
        }
    }

    onLineChartViewChange = (view) => {
        let reportForLineChart = this.state.reportByPreviousExams[view];
        let lineChartData = [{ id: view, color: "rgb(100,100,100)", data: reportForLineChart }];

        this.setState({
            lineChartData,
            lineChartCurrentView: view
        });
    }


    onSlotChange = (timeSlot) => {

        let selectedTimeSlots = this.state.selectedTimeSlots;
        if (!selectedTimeSlots.includes(timeSlot)) {
            selectedTimeSlots = [...selectedTimeSlots, timeSlot]
        }
        else if (selectedTimeSlots.indexOf(timeSlot) >= 0) {

            selectedTimeSlots.splice(selectedTimeSlots.indexOf(timeSlot), 1);

        }

        let breakDownAnalysis = this.getBreakDownAnalysisData(this.state.examReport, selectedTimeSlots,);


        let breakDownAnalysisPieData = [
            {
                "id": "Wasted",
                "value": breakDownAnalysis.wasted
            },
            {
                "id": "Overtime",
                "value": breakDownAnalysis.overtime
            },
            {
                "id": "Perfect",
                "value": breakDownAnalysis.perfect
            }
        ]

        this.setState({
            selectedTimeSlots,
            breakDownAnalysis,
            breakDownAnalysisPieData
        });
    }


    getBreakDownAnasis = (examReport, timeSlot) => {
        let breakDownAnalysis = this.getBreakDownAnalysisData(examReport, [timeSlot]);
        let breakDownAnalysisPieData = [
            {
                "id": "Wasted",
                "value": breakDownAnalysis.wasted
            },
            {
                "id": "Overtime",
                "value": breakDownAnalysis.overtime
            },
            {
                "id": "Perfect",
                "value": breakDownAnalysis.perfect
            }
        ]
        return { breakDownAnalysis, breakDownAnalysisPieData }

    }

    breakdDownRender = ({ breakDownAnalysis, breakDownAnalysisPieData }, timeSlot) => {
        return <>
            <div className="time-slot-w"> <p className={`time-slot ${true ? 't-b-active' : ''}`}> <span className={`t-btn ${true ? 't-active' : ''}`}> </span>  {`${timeSlot} mins`} </p> </div>
            <div className="sp-clearFix"></div>
            <div className="p-grid">

                <div className="p-col-6 p-md-6">
                    <div className="breakdown-analysis">

                        <div className="p-grid">
                            <div className="p-col-6 p-md-6">
                                <p className="b-c-title">Attempted  <span className="b-count attempted-c">{breakDownAnalysis.attempted} </span></p>
                            </div>
                            <div className="p-col-6 p-md-6">
                                <p className="b-c-title">Overtime  <span className="b-count overtime-c">{breakDownAnalysis.overtime} </span></p>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-6 p-md-6">
                                <p className="b-c-title">Perfect  <span className="b-count perfect-c">{breakDownAnalysis.perfect} </span></p>
                            </div>
                            <div className="p-col-6 p-md-6">
                                <p className="b-c-title">Wasted  <span className="b-count wasted-c">{breakDownAnalysis.wasted} </span></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-6 p-md-6">
                    <div style={{ height: '300px', minHeight: "300px", width: '100%', marginTop: '-100px' }}>
                        <ResponsivePie
                            data={breakDownAnalysisPieData}
                            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                            innerRadius={0.5}
                            cornerRadius={0}
                            colors={['#2C67E8', '#16BCEA', '#6BF9E2']}
                            borderWidth={1}
                            enableArcLabels={true}
                            enableArcLinkLabels={true}
                            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                            radialLabelsSkipAngle={10}
                            radialLabelsTextColor="#333333"
                            radialLabelsLinkColor={{ from: 'color' }}
                            sliceLabelsSkipAngle={10}
                            sliceLabelsTextColor="#333333"
                            padAngle={4}
                            enableSliceLabels={false}
                        />
                    </div>
                </div>
            </div ></>
    }


    render() {
        const { selectedTimeSlots, examReport } = this.state;
        // console.log(this.props.examResult)
        // const {totalMarks, totalNoOfQuestions, totalUserMarks, userAnswered} = this.props.resultDetailsInfo && this.props.resultDetailsInfo[0] || {};
        return (
            <>
                <div className="container-fluid report-c1" id='summary' >
                    <style>
                        {`body {
    margin:0px;
}`}

                    </style>
                    <div className="p-grid ma-no-pm">
                        <div className="p-col-12 p-md-12">
                            <>
                                <div className="p-grid report-title-c" style={{ paddingLeft: '1rem' }} >
                                    <div className="p-col-4 p-md-4" style={{ paddingBottom: 0 }}>
                                        <div >
                                            <p className="report-title"> {(examReport) ? examReport.userName : ''}</p>
                                            <p className="report-info">Phone Number: {(examReport) ? examReport.phoneNumber : ''}</p>
                                            <p className="report-info">Email: {(examReport) ? examReport.email : ''}</p>
                                            {/* <p className="report-info">Section: {(examReport) ? examReport.sectionName : ''}</p> */}
                                        </div>
                                    </div>
                                    <div className="p-col-8 p-md-8" style={{ paddingBottom: 0 }}>
                                        <div >
                                            <p className="report-exam-name"><i className="pi pi-calendar-minus" />{examReport.examname}</p>
                                            <p className="report-exam-name">Submitted At: {moment(new Date(parseInt(examReport.submittedat))).format("DD-MM-YYYY h:mm A")}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* <Grid container spacing={24} style={{ paddingLeft: '1rem' }}>
                                    <Grid item xs={12} style={{ paddingBottom: 0 }}>
                                        
                                         <p className="report-info">Board: {(examReport) ? examReport.boardname : ''}</p>
                                        <p className="report-info">Class: {(examReport) ? examReport.classname : ''}</p>
                                        <p className="report-info">Group: {(examReport) ? examReport.groupname : ''}</p>
                                       
                                    </Grid>
                                </Grid> */}


                                <div className="p-grid" style={{ padding: '1rem' }}>
                                    <div className="p-col-3 p-md-3">
                                        <div className="report-stats">
                                            <div className="p-grid" >
                                                <div className="p-col-4">
                                                    <div className="stats-icon stats-icon1">
                                                        <img className="stats-img1" src="/images/Group_1.png" />
                                                    </div>
                                                </div>
                                                <div className="p-col-8">
                                                    <p className="stat-count stat-count1">{examReport.rank}</p>
                                                    <p className="stat-title">Rank <span>{examReport.hikeRank}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-3 p-md-3">
                                        <div className="report-stats">
                                            <div className='p-grid'>
                                                <div className="p-col-4">
                                                    <div className="stats-icon stats-icon2">
                                                        <img className="stats-img2" src="/images/ic_trending_down_24px.png" />
                                                    </div>
                                                </div>
                                                <div className="p-col-8">
                                                    <p className="stat-count stat-count2">{examReport.totalmarks}</p>
                                                    <p className="stat-title">Marks <span>{examReport.hikeMarks}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-3 p-md-3">
                                        <div className="report-stats">
                                            <div className="p-grid">
                                                <div className="p-col-4">
                                                    <div className="stats-icon stats-icon3">
                                                        <img className="stats-img3" src="/images/Group_777.png" />
                                                    </div>
                                                </div>
                                                <div className="p-col-8">
                                                    <p className="stat-count stat-count3">{examReport.highestmarks}</p>
                                                    <p className="stat-title">Highest Marks</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-3 p-md-3">
                                        <div className="report-stats">
                                            <div className="p-grid">
                                                <div className="p-col-4 ">
                                                    <div className="stats-icon stats-icon4">
                                                        <img className="stats-img1" src="/images/Group_778.png" />
                                                    </div>
                                                </div>
                                                <div className="p-col-8">
                                                    <p className="stat-count stat-count4">{examReport.averagemarks}</p>
                                                    <p className="stat-title">Average Marks</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid" style={{ padding: '1rem' }}>
                                    <div className="p-col-12">
                                        <table className="subjects-table">
                                            <tr>
                                                <th>Subject</th>
                                                <th>Rank</th>
                                                <th>Your Score</th>
                                                <th>Average</th>
                                                <th>Highest</th>
                                            </tr>

                                            {this.state.subjects.map((subject) => {
                                                return <>
                                                    <tr>
                                                        <td>{subject}</td>
                                                        <td> {examReport[`${subject.replace(getRegex(' '), '_')}_rank`]}</td>
                                                        <td> {examReport[`${subject.replace(getRegex(' '), '_')}_marks`]}</td>
                                                        <td> {examReport[`${subject.replace(getRegex(' '), '_')}_averageMarks`]}</td>
                                                        <td> {examReport[`${subject.replace(getRegex(' '), '_')}_highestMarks`]}</td>
                                                    </tr>
                                                </>
                                            })}
                                        </table>
                                    </div>

                                </div>
                                {this.state.lineChartData && this.state.lineChartData.length && this.state.lineChartData.length > 1 &&
                                    <div className="p-grid" style={{ padding: '1rem' }}>
                                        <div className='p-col-12'>
                                            <div className="line-chart paper-s1">
                                                <div>
                                                    {/* <FormControl variant="outlined" className={"form-control"}>
                                                        <Select
                                                            className={"text-field"}
                                                            value={this.state.lineChartCurrentView}
                                                            onChange={(e) => this.onLineChartViewChange(e.target.value)}
                                                            input={
                                                                <OutlinedInput
                                                                    labelWidth={this.state.labelWidth}
                                                                    id="graphType"
                                                                //  classes={{ input: classes.noPadding }}
                                                                />
                                                            }
                                                            MenuProps={MenuProps}
                                                        >
                                                            <MenuItem className={"MuiMenuItem"} value="">
                                                                --Select--
                                                            </MenuItem>
                                                            {this.state.lineChartViews && this.state.lineChartViews.map(option => (
                                                                <MenuItem className={"MuiMenuItem"}
                                                                    key={option.view}
                                                                    value={option.view}>
                                                                    {option.viewDisplayName}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl> */}

                                                    <Dropdown value={this.state.lineChartCurrentView}
                                                        className='ma-w140 ma-w100p'
                                                        options={this.state.lineChartViews}
                                                        optionLabel="viewDisplayName"
                                                        optionValue="view"
                                                        onChange={(e) => { this.onLineChartViewChange(e.target.value) }}
                                                        placeholder="Select Type" />
                                                </div>
                                                <div className="line-chart-c">
                                                    <ResponsiveLine
                                                        data={this.state.lineChartData}
                                                        margin={{ top: 50, right: 50, bottom: 20, left: 60 }}
                                                        xScale={{ type: 'point' }}
                                                        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                                                        yFormat=" >-.2f"
                                                        axisTop={null}
                                                        axisRight={null}
                                                        curve="monotoneX"
                                                        enableArea={true}
                                                        pointSize={10}
                                                        pointColor={{ theme: 'background' }}
                                                        pointBorderWidth={2}
                                                        pointBorderColor={{ from: 'serieColor' }}
                                                        pointLabelYOffset={-12}
                                                        useMesh={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>


                            {/* Multiselect exam graph is pending -sp */}

                            <>
                                <div style={{ height: '0px' }}>
                                    <svg>
                                        <defs>
                                            <linearGradient id="yours" >
                                                <stop offset="0%" stopColor="#98FFEE" />
                                                <stop offset="100%" stopColor="#03E9C3" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <svg>
                                        <defs>
                                            <linearGradient id="average" >
                                                <stop offset="0%" stopColor="#85E5FF" />
                                                <stop offset="100%" stopColor="#00BCF2" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <svg>
                                        <defs>
                                            <linearGradient id="highest" >
                                                <stop offset="0%" stopColor="#82A9FF" />
                                                <stop offset="100%" stopColor="#004EF9" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>

                                <p className='report-title1'>All Subjects</p>
                                <div className="p-grid" style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                                    <div className="p-col-12">
                                        {/* elevation={2} style={{ height: '300px' }} */}
                                        <div className="comparative-analysis" >
                                            <p className='comparative-title'>Comparative Analysis (Total Marks)</p>

                                            <div className="comparativ-bar">
                                                <ResponsiveBar
                                                    data={this.state.comparativeAnalysis}
                                                    keys={['Your Score', 'Average Score', 'Highest Score']}
                                                    indexBy="yaxis"
                                                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                                    padding={0.5}
                                                    layout="horizontal"
                                                    valueScale={{ type: 'linear' }}
                                                    indexScale={{ type: 'band', round: true }}
                                                    // colors={{ scheme: 'paired' }}
                                                    //colors={['url(#someGradientId)','#03E9C3', '#00BCF2', '#004EF9']}
                                                    colors={['url(#yours)', 'url(#average)', 'url(#highest)']}
                                                    borderRadius={4}
                                                    axisTop={null}
                                                    maxValue={examReport.totalExamMarks}
                                                    axisRight={null}
                                                    axisBottom={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: '',
                                                        legendPosition: 'middle',
                                                        legendOffset: 32
                                                    }}
                                                    axisLeft={{
                                                        tickSize: 5,
                                                        tickPadding: 5,
                                                        tickRotation: 0,
                                                        legend: '',
                                                        legendPosition: 'middle',
                                                        legendOffset: 32
                                                    }}
                                                    labelSkipWidth={12}
                                                    labelSkipHeight={12}
                                                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                    legends={[
                                                        {
                                                            dataFrom: 'keys',
                                                            anchor: 'bottom',
                                                            direction: 'row',
                                                            justify: false,
                                                            translateX: 12,
                                                            translateY: 50,
                                                            itemsSpacing: 10,
                                                            itemWidth: 100,
                                                            itemHeight: 20,
                                                            itemDirection: 'left-to-right',
                                                            itemOpacity: 0.85,
                                                            symbolSize: 20,
                                                            effects: [
                                                                {
                                                                    on: 'hover',
                                                                    style: {
                                                                        itemOpacity: 1
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]}
                                                    animate={true}
                                                    motionStiffness={90}
                                                    motionDamping={15}
                                                    enableGridY={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="page-break"></div>
                                <p className='report-title2'>Subject Wise</p>
                                <div className="p-grid" style={{ marginLeft: '1rem', marginRight: '1rem' }}>

                                    {this.state.comparativeAnalysisSubjectWise.length && this.state.comparativeAnalysisSubjectWise.map((r, indexR) => {
                                        return <div className="p-col-12">
                                            <div className="comparative-analysis" >
                                                <p className='comparative-title'>{r.subject}</p>
                                                <div className="comparativ-bar">
                                                    <ResponsiveBar
                                                        data={r.report}
                                                        keys={['Your Score', 'Average Score', 'Highest Score']}
                                                        indexBy="yaxis"
                                                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                                        padding={0.5}
                                                        maxValue={100}

                                                        layout="horizontal"
                                                        valueScale={{ type: 'linear' }}
                                                        indexScale={{ type: 'band', round: true }}
                                                        // colors={{ scheme: 'paired' }}
                                                        //colors={['#03E9C3', '#00BCF2', '#004EF9']}
                                                        colors={['url(#yours)', 'url(#average)', 'url(#highest)']}
                                                        borderRadius={4}
                                                        axisTop={null}
                                                        axisRight={null}
                                                        axisBottom={{
                                                            tickSize: 5,
                                                            tickPadding: 5,
                                                            tickRotation: 0,
                                                            legend: '',
                                                            legendPosition: 'middle',
                                                            legendOffset: 32
                                                        }}
                                                        axisLeft={{
                                                            tickSize: 10,
                                                            tickPadding: 0,
                                                            tickRotation: 0,
                                                            legend: '',
                                                            legendPosition: 'middle',
                                                            legendOffset: 40
                                                        }}
                                                        labelSkipWidth={12}
                                                        labelSkipHeight={12}
                                                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                        legends={[
                                                            {
                                                                dataFrom: 'keys',
                                                                anchor: 'bottom',
                                                                direction: 'row',
                                                                justify: false,
                                                                translateX: 35,
                                                                translateY: 52,
                                                                itemsSpacing: 6,
                                                                itemWidth: 100,
                                                                itemHeight: 20,
                                                                itemDirection: 'left-to-right',
                                                                itemOpacity: 0.85,
                                                                symbolSize: 20,
                                                                effects: [
                                                                    {
                                                                        on: 'hover',
                                                                        style: {
                                                                            itemOpacity: 1
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        ]}
                                                        animate={true}
                                                        motionStiffness={90}
                                                        motionDamping={15}
                                                        enableGridY={false}
                                                    />
                                                </ div>
                                            </ div>
                                        </div>
                                    })
                                    }
                                </div>
                            </>

                            {/* <div className="page-break"></div>
                            <>
                                <div className="p-grid" style={{ padding: '1rem' }}>
                                    <div className="p-col-12">
                                        <div className="time-management-c" >
                                            <div className="p-grid" style={{ padding: '1rem' }}>

                                                <div className="p-col-11">
                                                    <div className="time-management">
                                                        <p className="time-m-title">TIME MANAGEMENT</p>
                                                        <div className="sp-clearFix"></div>

                                                        <div className="time-c">
                                                            <table className="exam-tbl">
                                                                <tr>
                                                                    <th colspan={8}>
                                                                        <div className="t-highlights">
                                                                            <div className="sp-grid">
                                                                                {this.state.timeMagementData.length && this.state.timeMagementData.map((r, index) => {
                                                                                    return <div className={`${index > 2 ? 'sp-25p' : 'sp-15p'}`}>
                                                                                        <p className="time-m-stats"> {r.key}<span className={`count count-${index}`}> {r.value}{index > 2 ? '%' : ''} </span></p>
                                                                                    </div>
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th rowSpan="2">
                                                                        Subject
                                                                    </th>
                                                                    <th colSpan="2">
                                                                        Perfect
                                                                    </th>
                                                                    <th colSpan="2">
                                                                        Overtime
                                                                    </th>
                                                                    <th rowSpan="2">
                                                                        Wasted
                                                                    </th>
                                                                    <th rowSpan="2">
                                                                        My Accuracy
                                                                    </th>
                                                                    <th rowSpan="2">
                                                                        Class Accuracy
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Correct
                                                                    </th>
                                                                    <th>
                                                                        Incorrect
                                                                    </th>
                                                                    <th>
                                                                        Correct
                                                                    </th>
                                                                    <th>
                                                                        Incorrect
                                                                    </th>
                                                                </tr>

                                                                {this.state.timeManagementSubjectWiseData.length && this.state.timeManagementSubjectWiseData.map((r) => {
                                                                    return <tr>
                                                                        {r.values.map((field, index) => <td className={`${index == 6 ? (Number(r.values[index + 1]) - Number(field) ? 'sp-success' : 'sp-danger') : ''}`}>{field}{index > 5 ? '%' : ''}</td>)}
                                                                    </tr>
                                                                })}
                                                            </table>
                                                        </div>

                                                        <div className="sp-clearFix"></div>
                                                                    
                                                        <p className="b-title">Breakdown Analysis <span className='b-times'></span></p>
                                                        {

                                                            this.state.timeSlots.map((timeSlot, index) => {
                                                                return <>
                                                                    {(index == 2 || index == 6 || index == 10) && <div className="page-break" ></div>
                                                                    }
                                                                    {this.breakdDownRender(this.getBreakDownAnasis(this.state.examReport, timeSlot), timeSlot)}


                                                                </>

                                                            })
                                                        }


                                                    </div>
                                                </div>
                                            </div>
                                        </ div>
                                    </div>
                                </div>

                            </> */}


                        </div>

                    </div>

                </div>
            </>
        );
    }
}

let dummyData = require('./dummy.json')
const mapStateToProps = state => {
    return dummyData;
    return {

    }
}

export default connect(mapStateToProps, {})(withRouter(MockTestResult));

