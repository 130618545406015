
import * as ActionTypes from './../actionTypes';

export default (state = {}, action) => {

    switch (action.type) {
        case ActionTypes.REQUEST_UPDATE_SUBJECT2:
            return {
                ...state,
                loading: true,
                error: false,
                errorInfo: null,
                response: null,
            }

        case ActionTypes.RECEIVED_UPDATE_SUBJECT2:
            return {
                ...state,
                loading: false,
                response: action.res,
                error: false,
                errorInfo: null
            }

        case ActionTypes.ERROR_UPDATE_SUBJECT2:
            return {
                ...state,
                loading: false,
                error: true,
                errorInfo: action.err
            }

        case ActionTypes.CLEAR_UPDATE_SUBJECT_DATA2:
            return {}
        default: return state;

    }
}