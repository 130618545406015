import React, { Component } from 'react'
import Service from './../../discussionBoard/service';
import { askADoubtEndpoint, baseUrlAdmin } from '../../../store/apiConstants';
import { ResponsivePie } from '@nivo/pie';
import { PieChart } from 'react-minimal-pie-chart';
import InLoadingComponent from '../../loadingComponent/inlineLoading';
import { connect } from 'react-redux';

import Authentication from './../../session';
import { I18Next } from '../../../internationalization';
class AskADoubtStats extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inlineLoading: true,
            "pieData": null,
            "stats": null
            // {
            //     "total": 79,
            //     "Progress": "40.51",
            //     "Closed": "31.65",
            //     "Initiated": "27.85"
            // }
        };


        this.service = new Service();



    }

    getReport = () => {

        this.setState({
            inlineLoading: true
        })

        const url = `${askADoubtEndpoint}/doubts/org-stats`;
        this.service.get(url, true).then(res => {

            if (res && res.status) {
                this.setState({
                    ...res.res.data,
                    inlineLoading: false
                })

            } else {
                this.setState({
                    inlineLoading: false
                });
            }
        }).catch(e => {
            this.setState({
                inlineLoading: false

            });
        })

    }



    componentDidMount() {

        if (this.props.discussionUser) {
            this.getReport();
        }

    }

    componentWillReceiveProps(newProps) {
        if (this.props.discussionUser != newProps.discussionUser) {
            this.getReport();
        }
    }


    render() {
        const { stats, pieData, inlineLoading } = this.state;
        const { defaultLanguage } = this.props;
        const langType = defaultLanguage
        console.log(stats, 'stats', pieData)

        return (
            <div className='ma-relative'>
                <div style={{marginRight: '-1px'}} className='resource-usage-report ask-a-doubt-stats'>

                    <div className='p-grid'>
                        <div className='p-col-12 p-md-4'>
                            {

                                <div className='ask-a-d-graph-w'>
                                    <PieChart
                                        data={pieData?pieData:[]}
                                        radius={20}
                                        lineWidth={18}

                                    //segmentsStyle= {{backgroundColor:'red'}}
                                    />

                                </div>


                            }

                        </div>
                        {

                            <div className='p-col-12 p-md-8'>
                                <h3 className="ask-title">{I18Next('askADoubt', langType).COMMON_KEY}</h3>
                                <div className='p-grid'>
                                    <div className='p-col-12 p-md-4'>
                                        <p className='dot-open'></p> <p className='doubt-label l1'>Open Doubts</p>
                                        <p className='doubt-value'>{stats?.Initiated}%</p>
                                    </div>
                                    <div className='p-col-12 p-md-4'>
                                        <p className='dot-closed'></p>  <p className='doubt-label l2'>Closed Doubts</p>
                                        <p className='doubt-value'>{stats?.Closed}%</p>
                                    </div>
                                    <div className='p-col-12 p-md-4'>
                                        <p className='dot-pending'></p>  <p className='doubt-label l3'>Pending Doubts</p>
                                        <p className='doubt-value'>{stats?.Progress}%</p>
                                    </div>
                                </div>
                                <p className='doubt-label l1 ma-ml15'>No of Doubts Asked</p>
                                <p className='doubt-value'>{stats?.total}</p>
                            </div>

                        }


                    </div>

                </div>
                {
                    inlineLoading && <InLoadingComponent />
                }
            </div>

        )
    }
}


const mapStatesToProps = (state) => ({
    defaultLanguage: state.languageReducer.language,
    discussionUser: state.currentUserPerms && state.currentUserPerms.discussionUser && state.currentUserPerms.discussionUser,
});

export default connect(mapStatesToProps, {})(Authentication(AskADoubtStats));
