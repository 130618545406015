import React, { Component } from 'react';
// import Navbar from './../navbar/index';
import { connect } from 'react-redux';
import _ from 'lodash';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { withRouter } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Authentication from '../../session';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
// import {
//     getBoardsData
// } from './../../store/actions';
// import './styles.scss'
// import AddFromRepo from './addFromRepo';
// import EditContent from './editContent';
// import ContentPlayer from './contentPlayers';
// import { DeleteIcon, PreviewIcon, WarningIcon } from '../svgIcons';
import Service from '../../../services';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../../store/apiConstants';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import LoadingComponent from '../../loadingComponent';
import PreviewQuestion from '../../questionBank/common/previewQuestion';
import QuestionInTable from '../../questionBank/common/questionInTable';
import { QuestionTargets } from '../../questionBank/constants';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { DeleteIcon, PreviewIcon, WarningIcon } from '../../svgIcons';
import { getBoardsData } from '../../../store/actions';
import Navbar from '../../navbar';
import AddFromRepo from '../../assignQuestions/addFromRepo';
import ContentPlayer from '../../contentMaster/contentPlayers';
import EditContent from '../../assignQuestions/editContent';
const items = [
    { label: 'Academics' },
    { label: 'Exams' },
    { label: 'Assign Questions', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }



class ChooseQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            boardId: null,
            classId: this.props.defaultData?.qClass,
            groupId: null,
            boards: [],
            classes: [],
            groups: [],
            subjects: [],
            subjectId: null,
            chapterId: null,
            topicId: null,
            content: null,
            isShowAddDialog: false,
            resourseOrigin: null,
            filteredContentDetails: [],
            warningDialog: warningDailogInit,
            isShowEditContent: false,
            selectedContent: null,
            globalFilter: '',
            //contests[3],
            columns: [
                { columnName: 'Question Type', isVisible: true, id: 'questionType' },
                { columnName: 'Video Linked', isVisible: false, id: 'videoLinking' },
                { columnName: 'Difficulty', isVisible: false, id: 'difficulty' },
                { columnName: 'Skill Type', isVisible: false, id: 'skillType' },
                { columnName: 'Exam Type', isVisible: false, id: 'examType' },
                { columnName: 'Source', isVisible: false, id: 'source' },
                { columnName: 'Status', isVisible: false, id: 'status' },

            ],
            showSearch: false,
            globalFilter: null,
            questionTarget: null,
            globalSearch: '',
            totalRecords: 0,
            lazyParams: BasicLazyParams,
            isShowAssignCountDialog: false,
            assignedCount: null,
            selectedQuestions: [],


        }
        this.service = new Service();
    }



    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }
        if (this.props.boards?.length && this.state.boardId == null && this.state.questionTarget == null) {
            this.setState({
                questionTarget: this.props.defaultData?.qtarget,
            }, () => { this.onChangeBoard(this.props.defaultData?.qboard, true) })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.state.curriculumInfo && this.state.curriculumInfo.offlineExportStatus && this.state.curriculumInfo.offlineExportStatus.storageKey && this.props.getAllImagesUrlInfo && this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey] && (prevProps.getAllImagesUrlInfo && (this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey] != prevProps.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey]))) {
            window.open(this.props.getAllImagesUrlInfo[this.state.curriculumInfo.offlineExportStatus.storageKey]);
        }
    }



    // grades selection --- start
    onChangeBoard = (boardId, forFirstTime) => {
        this.setState({
            classes: [], groups: [],
            classId: null, groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            questions: [],
            totalRecords: 0,
            content: null,
            filteredContentDetails: []
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
            isBaseBoard: selectedBoard.isBase,
            resourseOrigin: selectedBoard.isBase ? 'upload' : null

        }, () => {
            if (forFirstTime) {
                this.onChangeClass(this.props.defaultData?.qClass)
            }
        });

    };

    onChangeClass = (classId) => {
        this.setState({

            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            questions: [],
            totalRecords: 0,
            content: null,
            filteredContentDetails: [],

        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                groupId: _groups[0].value
            }, () => {
                this.getCurriculumData();
            });
        }
    };

    onChangeGroup = (groupId) => {
        this.setState(
            {
                groupId,
                content: null,
                filteredContentDetails: [],
                questions: [],
                totalRecords: 0,
            },
            () => {
                this.getCurriculumData();
            }
        );
    };
    // grades selection --- end


    //subjects --- start


    getCurriculumData = () => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, false).then((data) => {

                if (data && data.res && data.res.Item) {
                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    };


    onChangeSubject = (subjectId) => {
        let selectedSubject = this.state.subjects.filter((subject) => subject.subjectId == subjectId);

        this.setState((prevState) => {
            return {
                subjectId,
                chapterId: null,
                topicId: null,
                chapters: selectedSubject[0].chapters.sort((a, b) => a.chapterNumber - b.chapterNumber),
                topics: [],
                questions: [],
                totalRecords: 0,
                content: null,
                filteredContentDetails: []
            };
        });
    };

    onChangeChapter = (chapterId) => {
        let selectedChapter = this.state.chapters.filter((chapter) => chapter.chapterId == chapterId);
        this.setState(
            {
                topics: selectedChapter[0].topics,
                chapterId,
                topicId: null,
                content: null,
                filteredContentDetails: [],
                questions: [],
                totalRecords: 0
            });
    };

    onChangeTopic = (topicId) => {
        let selectedTopicResource = [];
        let selectedTopic = this.state.topics.filter(t => t.topicId == topicId)
        let selectedTopicResourceTypes = selectedTopic[0].resources ? selectedTopic[0].resources.filter(r => r.isActive) : [];
        if (selectedTopicResourceTypes && selectedTopicResourceTypes.length) {
            selectedTopicResourceTypes = selectedTopicResourceTypes.map((a, index) => {
                if (index == 0) {
                    a.isSelected = true;
                } else {
                    a.isSelected = false;
                }
                return a;
            })
        }

        this.setState(
            {
                topicId,
                selectedTopicResourceTypes: selectedTopic[0].resources ? selectedTopic[0].resources.filter(r => r.isActive) : [],
                content: null,
                filteredContentDetails: [],
                questions: [],
                totalRecords: 0
            });
    };


    filterResource = (contents, sTR) => {
        let selectedTopicResourceTypes = _.cloneDeep(sTR)

        let filter = selectedTopicResourceTypes.find((a) => a.isSelected);

        if (filter && filter.value) {
            contents = contents.filter((c) => c.contentFileType == filter.value)
            return contents.sort((a, b) => parseInt(a.priority) - parseInt(b.priority))

        } else {
            return []
        }
    }

    filterBySelected = (contents) => {
        return this.filterResource(contents, this.state.selectedTopicResourceTypes);
    }





    getContentDetails = () => {
        const { boardId, classId, groupId, subjectId, chapterId, topicId } = this.state;

        this.setState({
            isLoading: true,

        });
        const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/subject/${subjectId}/chapter/${chapterId}/topic/${topicId}?etag=true`;
        this.service.get(url, true).then((res) => {

            const etag = res && res.etag;



            if (res && res.res && res.res.Item) {
                let filteredContentDetails = [];
                if (res.res.Item.contentDetails) {
                    filteredContentDetails = this.filterBySelected(res.res.Item.contentDetails);
                }
                this.setState((prevState) => {
                    return {
                        content: { boardId, classId, groupId, subjectId, chapterId, topicId, ...res.res.Item },
                        filteredContentDetails,
                        isLoading: false,
                        etag
                    }
                });

            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: ' ', life: 3000 });
            }
        }).catch(e => {
            console.log(e)
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });
    }

    updateContent = (content) => {
        this.setState({
            isLoading: true
        });


        const url = `${baseUrlForLongRunningTask}/long/content`;
        this.service.put(url, content, true, { "If-Match": this.state.etag }).then((data) => {


            if (data && data.res && data.res.success) {

                this.toast.show({ severity: 'success', summary: 'Modified', detail: 'Quiz Assigned successfully, It may reflect in 10-15 mins', life: 6000 });
                this.setState({
                    isLoading: false,
                    isShowAddDialog: false,
                    resourseOrigin: this.state.isBaseBoard ? 'upload' : null
                }, this.getContentDetails);
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });


        })
    }

    addClick = (type) => {

        this.setState({
            selectedType: type,
            isShowAddDialog: true
        });


    }



    onSelectQuestion = (checkedQuestionsForAssign) => {
        this.setState({
            checkedQuestionsForAssign
        })
    };


    assignContentTo = () => {
        const { boardId,
            classId,
            groupId,
            subjectId,
            chapterId,
            resourseOrigin,
            topicId,
            checkedQuestionsForAssign,
        } = this.state;

        if (!checkedQuestionsForAssign || checkedQuestionsForAssign.length == 0) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select questions to assign', life: 3000 });
            return;
        }

        const content = {
            boardId,
            classId,
            groupId,
            subjectId,
            chapterId,
            topicId,
            source: resourseOrigin,
            questions: checkedQuestionsForAssign.map((q) => { return { questionId: q.question.questionId, question: q.question._id, questionType: q.question.questionType, questionTarget: q.question.questionTarget } })
        };


        this.setState({
            isLoading: true
        });

        this.service.post(`${baseUrlAdmin}/questionlinking/assignQuestions`, content, true).then((data) => {
            if (data && data.res && data.res.data) {
                this.setState({
                    isLoading: false,
                    isShowAddDialog: false,
                    resourseOrigin: null,
                    assignedCount: data.res.data,
                    isShowAssignCountDialog: true
                }
                    //, this.fetchQuestions
                );
            } else {
                this.setState({
                    isLoading: false
                });
            }

        }).catch(e => {

            this.setState({
                isLoading: false
            });


        })

    };

    deleteLinking = (rowData) => {
        this.setState({
            isLoading: true
        });
        this.service.delete(`${baseUrlAdmin}/questionlinking/delete/${rowData._id}`, true).then((data) => {
            if (data?.res?.success) {

                this.setState({
                    isLoading: false,
                    isShowAddDialog: false
                }, () => {
                    this.toast.show({ severity: 'success', summary: 'Deleted', detail: 'Question deleted successfully.', life: 3000 });
                    this.fetchQuestions()
                });
            } else {
                this.setState({
                    isLoading: false
                });

                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured.', life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured.', life: 3000 });
        });

    }

    exportCSV = () => {
        this.dt.exportCSV();
    }


    createRoleDialogFooter = () => {
        return (<div className='ma-mt20'>
            <Button label="Cancel" className='p-button-outlined' onClick={() => { this.setState({ isShowAddDialog: false, resourseOrigin: this.state.isBaseBoard ? 'upload' : null }) }} />
            <Button label={`Assign`} className='ma-m-lr10' onClick={this.assignContentTo} />
        </div>)
    };


    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Assign Questions</h2>
            </React.Fragment>
        )
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    moveRecords = (records, old_index, new_index) => {
        let arr = _.cloneDeep(records);
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        arr = arr.map((a, index) => {
            a.priority = index + 1;
            return a;
        })
        return arr; // for testing
    };

    updatePriorityAndUpdateContent = () => {
        let { filteredContentDetails, content } = this.state;
        let conten = _.cloneDeep(content)


        filteredContentDetails.forEach(c => {
            conten.contentDetails = conten.contentDetails.map(cont => {
                if (cont.contentId == c.contentId) {
                    cont.priority = parseInt(c.priority);
                }
                return cont;
            });
        })



        let { boardId, classId, groupId, subjectId, chapterId, topicId,
            contentDetails } = conten;


        this.updateContent({ boardId, classId, groupId, subjectId, chapterId, topicId, contents: contentDetails });

    }

    onMoveClick = (rowData, isUp) => {
        let contentDetail = this.state.filteredContentDetails;
        let findIndex = contentDetail.findIndex((a) => a.contentId == rowData.contentId);
        if (findIndex > 0 && isUp) {
            let newArray = this.moveRecords(contentDetail, findIndex, isUp ? findIndex - 1 : findIndex + 1);

            this.setState((prevState) => {
                return {
                    filteredContentDetails: newArray
                }
            }, this.updatePriorityAndUpdateContent);
        }
        else if ((findIndex + 1) < contentDetail.length && !isUp) {
            let newArray = this.moveRecords(contentDetail, findIndex, isUp ? findIndex - 1 : findIndex + 1);
            // 

            this.setState((prevState) => {
                return {
                    filteredContentDetails: newArray
                }
            }, this.updatePriorityAndUpdateContent);
        }
    }

    onSelectResourceType = (type) => {

        if (type.value == 'ppt') {
            type.value = 'zip'
        }

        let selectedTopicResourceTypes = this.state.selectedTopicResourceTypes;
        let selectedTopicResourceType;
        selectedTopicResourceTypes = selectedTopicResourceTypes.map(r => {
            if (type.value == r.value) {
                r.isSelected = true;

                selectedTopicResourceType = r.value
            } else {
                r.isSelected = false
            }
            return r;
        });




        let contentDetails = (this.state.content && this.state.content.contentDetails) ? this.state.content.contentDetails : [];

        contentDetails = this.filterResource(contentDetails, selectedTopicResourceTypes)

        this.setState({
            selectedTopicResourceTypes,
            filteredContentDetails: contentDetails
        });
    }


    onEditContent = (content) => {
        this.setState({
            selectedContent: content,
            isShowEditContent: true
        });
    }

    onDeleteContent = (content) => {

        this.setState({
            selectedContent: content,
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `${content.contentName} will be deleted, it can't be undone.`
            },
        });
    }



    onYesClick = () => {
        this.setState({
            warningDialog: warningDailogInit,
            //isLoading: true
        }, this.deleteAndUpdate);
    }

    onNoClick = () => {
        this.setState({ warningDialog: warningDailogInit, selectedContent: null })


    }


    deleteAndUpdate = () => {
        let { content, selectedContent } = this.state;
        content.contentDetails = content.contentDetails.filter(c => c.contentId != selectedContent.contentId);
        const { boardId, classId, groupId, subjectId, chapterId, topicId, contentDetails } = content;
        this.updateContent({
            boardId, classId, groupId, subjectId, chapterId, topicId,
            contents: contentDetails
        });

    }

    onUpdateContent = (selectedContent) => {
        let { content } = this.state;
        content.contentDetails = content.contentDetails.map(c => {
            if (c.contentId == selectedContent.contentId) {
                c = selectedContent;
            }
            return c;
        });

        const { boardId, classId, groupId, subjectId, chapterId, topicId, contentDetails } = content;

        this.setState({ isShowEditContent: false }, () => {
            this.updateContent({
                boardId, classId, groupId, subjectId, chapterId, topicId,
                contents: contentDetails
            })
        });
    }

    showPreview = (contentInfo) => {

        this.setState({
            isShowPreview: true,
            selectedContent: contentInfo
        });


    }

    isGradeValid = () => {
        let { boardId, classId, groupId, subjectId, chapterId, topicId, questionTarget } = this.state;

        if (!questionTarget) {
            return false
        } else if (!boardId || !classId || !groupId || !subjectId || (chapterId && !topicId)) {
            return false
        } else if (questionTarget == 'Normal' && (!chapterId || !topicId)) {
            return false
        } else {
            return true
        }
    }


    fetchQuestions = () => {


        if (!this.isGradeValid()) {
            this.toast.show({ severity: 'error', summary: 'Grades Required', detail: 'Please select grades', life: 3000 });
        } else {

            // this.toast.show({ severity: 'success', summary: 'Success', detail: 'valid', life: 3000 });


            // return true;

            const { questionTarget,
                boardId, classId,
                groupId, subjectId,
                chapterId, topicId,

                lazyParams, globalSearch
            } = this.state;


            let filters = { questionTarget };

            // if (!questionTarget) {
            //     this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select question Target', life: 3000 });
            //     return

            // }


            if (questionTarget == 'Normal') {
                if (topicId) {
                    filters = { ...filters, topicId: [topicId] }
                } else if (chapterId) {
                    filters = { ...filters, chapterId: [chapterId] }
                } else if (subjectId) {
                    filters = { ...filters, subjectId: [subjectId] }
                } else if (groupId) {
                    filters = { ...filters, groupId: [groupId] }
                } else if (classId) {
                    filters = { ...filters, gradeId: [classId] }
                } else if (boardId) {
                    filters = { ...filters, boardId: [boardId] }
                }
            } else {
                if (topicId) {
                    filters = { ...filters, topicId: [topicId] }
                } else if (chapterId) {
                    filters = { ...filters, chapterId: [chapterId] }
                } else if (subjectId) {
                    filters = { ...filters, subjectId: [subjectId] }
                } else if (groupId) {
                    filters = { ...filters, groupId: [groupId] }
                } else if (classId) {
                    filters = { ...filters, gradeId: [classId] }
                } else if (boardId) {
                    filters = { ...filters, boardId: [boardId] }
                }
            }


            this.setState({
                isLoading: true,
                questions: [],
                totalRecords: 0
            });


            this.service.post(`${baseUrlAdmin}/questionlinking/filter/questions/page?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`, filters, true).then((data) => {

                if (data && data.status && data?.res?.data) {
                    this.setState({
                        totalRecords: data.res.data.totalRecords,
                        questions: data.res.data.questions,
                        isLoading: false
                    })
                } else {
                    this.setState({
                        isLoading: false,
                    });
                }


            }).catch(e => {
                this.setState({
                    isLoading: false,

                });
                console.log(e);

            });



        }

    }
    onQuestionTargetChange = (questionTarget) => {
        this.setState({
            questionTarget,
            questions: [],
            totalRecords: 0
        }, () => {
        })
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }


    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.fetchQuestions);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.fetchQuestions);
    }



    onSort = (event) => {


        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.fetchQuestions);
    }

    onFilter = (event) => {
        event['first'] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.fetchQuestions);
    }



    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }


    onConfirmOk = () => {

        this.setState({
            isShowAssignCountDialog: false,
            assignedCount: null
        }, this.fetchQuestions)


    }



    questionsAvailableR = () => {
        return <>
            <p className='avail-q'> Available Questions:  <span>{this.state.totalRecords}</span> </p>
        </>
    }

    saveSelectedquestions = () => {

        if (this.state.selectedQuestions?.length) {
            let dataQids = this.state.selectedQuestions.map(e => e.questionId)
            this.props.setQuestionIds(dataQids)
            this.props.closePopupOfChoose()
        } else {
            this.toast.show({ severity: 'error', summary: 'Please Choose at least one question', detail: ' ', life: 3000 });

        }
    }
    isSelected = (rowData, selectedItems, uniqField) => {
        return selectedItems.find(a => a[uniqField] == rowData[uniqField]) ? true : false;
    }
    selectOrdeselect = (type, selectedItems, items, isSelect, selectedItemsField, uniqField) => {
        if (this.props.defaultData.questionTypes.includes(items.question.questionType)) {
            let selectedIts = _.cloneDeep(selectedItems);
            if (isSelect) {
                selectedIts.push(items)
            } else {
                selectedIts = selectedIts.filter((i) => i[uniqField] != items[uniqField]);
            }
            this.setState({
                [selectedItemsField]: selectedIts
            })
        } else {
            this.toast.show({ severity: 'error', summary: `${items.question.questionType} Question type is not part of this schema`, detail: `Only ${this.props.defaultData.questionTypes} types are allowed`, life: 3000 });
        }
    }
    render() {
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'>Questions List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" value={this.state.globalSearch} className="ma-tbl-search p-inputtext-md" onChange={this.onGlobalSearch} />}
                </div>
                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        {/* <Authorizer permId={PERMISSIONS.ORG_VIEW_COLUMNS} > */}
                        <li><i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        {/* </Authorizer> */}
                        {/* <Authorizer permId={PERMISSIONS.ORG_DOWNLOAD} >
                            <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer> */}
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <li onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </li>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        // console.log('content-master', this.state)

        const { boardId, classId, groupId, subjectId, chapterId, topicId } = this.state;
        return <div >
            <div className="ask-a-doubt ma-main">
                <Authorizer permId={PERMISSIONS.EXAM_QUESTIONS_LIST} >
                    <Dropdown value={this.state.questionTarget}
                        className='ma-w200 ma-mt10'
                        options={QuestionTargets}
                        optionLabel="value"
                        optionValue="id"
                        onChange={(e) => this.onQuestionTargetChange(e.value)}
                        placeholder="Question Target" /><span className='ma-required-mark '>*</span>
                    <Dropdown value={this.state.boardId}
                        className='ma-w200  ma-mt10'
                        //options={this.state.boards}
                        options={this.props.boards}
                        optionLabel="boardName"
                        optionValue="boardId"
                        onChange={(e) => this.onChangeBoard(e.value)}
                        placeholder="Select Board" />
                    <span className='ma-required-mark '>*</span>
                    <Dropdown value={this.state.classId}
                        options={this.state.classes}
                        className='ma-w200  ma-mt10'
                        onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                    <span className='ma-required-mark '>*</span>
                    <Dropdown value={this.state.subjectId}
                        options={this.state.subjects}
                        optionLabel='subjectName'
                        optionValue='subjectId'
                        className='ma-w200  ma-mt10'
                        onChange={(e) => this.onChangeSubject(e.value)} placeholder="Select Subject" />
                    <span className='ma-required-mark '>*</span>
                    <Dropdown value={this.state.chapterId}
                        options={this.state.chapters}
                        optionLabel='chapterName'
                        optionValue='chapterId'
                        className='ma-w200  ma-mt10'
                        onChange={(e) => this.onChangeChapter(e.value)} placeholder="Select Chapter" />
                    {this.state.questionTarget == 'Normal' ? <span className='ma-required-mark '>*</span> : <span className='ma-required-mark '></span>}
                    <Dropdown value={this.state.topicId}
                        options={this.state.topics}
                        optionLabel='topicName'
                        optionValue='topicId'
                        className='ma-w200  ma-mt10'
                        onChange={(e) => this.onChangeTopic(e.value)} placeholder="Select Topic" />
                    {this.state.questionTarget == 'Normal' ? <span className='ma-required-mark '>*</span> : <span className='ma-required-mark '></span>}

                    <Button label='Fetch Questions' className='ma-mt20' onClick={this.fetchQuestions} />
                </Authorizer>

                <div className='ma-clearFix'>
                </div>
                <div className="card datatable-crud-demo fetch-q-tabled fetch-q-table ma-mt20">
                    <DataTable ref={(el) => this.dt = el}
                        scrollable scrollHeight="500px"
                        responsiveLayout="scroll"
                        dataKey="_id"
                        value={this.state.questions}
                        filters={this.state.lazyParams.filters}
                        // loading={this.props.isLoading}

                        className="ma-table-d"
                        tableClassName='ma-table-tbl'

                        paginatorLeft={this.questionsAvailableR}


                        paginator
                        lazy
                        onPage={this.onPage}
                        onSort={this.onSort}
                        onFilter={this.onFilter}
                        first={this.state.lazyParams.first}
                        last={this.state.totalRecords}
                        rows={this.state.lazyParams.rows}
                        sortField={this.state.lazyParams.sortField}
                        sortOrder={this.state.lazyParams.sortOrder}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}

                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        header={header}>
                        <Column headerStyle={{ width: '4em' }} field='questionId'
                            body={(rowData) => {
                                return <Checkbox inputId={'item.id'}
                                    checked={this.isSelected(rowData, this.state.selectedQuestions, 'questionId')}
                                    onChange={(e) => { this.selectOrdeselect('single', this.state.selectedQuestions, rowData, e.checked, 'selectedQuestions', 'questionId') }}
                                ></Checkbox>
                            }}>
                        </Column>
                        <Column headerClassName='question-id' field="questionId" header="Question ID" />

                        <Column headerClassName='question' header="Question and solution" body={(rowData) => {
                            return (<>
                                {
                                    rowData.question && <QuestionInTable question={rowData.question} />
                                }

                            </>)
                        }} />
                        {
                            this.isColumnVisible('questionType') && <Column headerClassName='difficulty' field="question.questionType" header="Question Type" />}

                        {
                            this.isColumnVisible('videoLinking') && <Column headerClassName='difficulty' field="videoLinking" header="Video Linked"
                                body={(rowData) => {
                                    return (<>
                                        <InputSwitch
                                            checked={(rowData?.question?.videoLinking && rowData?.question?.videoLinking.length) ? true : false} />

                                    </>)
                                }}

                            />
                        }

                        {
                            this.isColumnVisible('difficulty') && <Column headerClassName='difficulty' field="question.difficulty" header="Difficulty"

                            />}
                        {
                            this.isColumnVisible('skillType') && <Column headerClassName='difficulty' field="question.skillType" header="Skill Type"

                            />}
                        {
                            this.isColumnVisible('examType') && <Column headerClassName='difficulty' field="question.examType" header="Exam Type"
                                body={(rowData) => {
                                    return (<>
                                        {rowData.examType && rowData.examType.length ? rowData.examType.join(', ') : ''}
                                    </>)
                                }}
                            />}
                        {
                            this.isColumnVisible('source') && <Column headerClassName='difficulty' field="question.source" header="Source"
                            />}
                        {
                            this.isColumnVisible('status') && <Column headerClassName='difficulty' field="question.status" header="Status"
                            />}

                        <Column headerClassName='difficulty' headerStyle={{ width: '140px' }} header="Actions" body={(rowData) => {
                            return (<>

                                {/* <span data-pr-tooltip="Edit Question" data-pr-position="bottom" className={`edit${rowData._id} ma-mr20`} onClick={() => { this.props.onEditQuestion(rowData) }}>
                                    <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" />
                                </span>
                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData._id}`} /> */}

                                <Authorizer permId={PERMISSIONS.EXAM_QUESTIONS_PREVIEW} >
                                    <span data-pr-tooltip="Preview Question" data-pr-position="bottom" className={`preview${rowData._id} ma-mr20`}
                                        onClick={() => {
                                            this.setState({
                                                isShowQuestionPreview: true,
                                                questionToPreview: rowData.question
                                            })
                                        }}>
                                        {/* <i className='pi pi-eye ma-mr10' /> */}
                                        <PreviewIcon height={20} width={20} />
                                        {/* <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/edit.svg' width="20" height="20" /> */}
                                        {/* <i className="pi pi-folder-open"></i>  */}
                                    </span>
                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.preview${rowData._id}`} />
                                </Authorizer>

                            </>
                            );
                        }} ></Column>
                        {/* <Column header="Topics" body={this.showChaptersTemplate}></Column> */}
                    </DataTable>
                    <div className='ma-right' style={{ margin: 25 }}>
                        <Button label='Add Questions To Exam' className='add-questions'
                            onClick={this.saveSelectedquestions}
                        />
                    </div>
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                </div>


                {
                    this.state.isShowQuestionPreview && <PreviewQuestion
                        question={this.state.questionToPreview}
                        onHide={() => {
                            this.setState({
                                isShowQuestionPreview: false
                            })
                        }}
                    />
                }

            </div>

            <Dialog
                //visible={this.state.isShowCreate}
                style={{ width: '96%', height: '96vh' }}
                //  header={this.props.isAdd ? "Create Role" : "Edit Role"}
                modal
                blockScroll={true}
                footer={this.createRoleDialogFooter}
                draggable={false}
                // footer={this.footer}
                header={null}
                closeOnEscape={false}

                dismissableMask={false}
                className='add-a-resource-dialog'
                visible={this.state.isShowAddDialog}
                closable={false}
                //header="Add a Resource"
                onHide={() => { this.setState({ isShowAddDialog: false, resourseOrigin: this.state.isBaseBoard ? 'upload' : null }) }}>
                <div className='add-a-resource' >
                    {

                        this.state.resourseOrigin == null ? <>
                            <p className='title'>Assign Questions</p>
                            <div className="p-grid  ">
                                <div className="p-col-12 p-md-6 line-v">
                                    <div className='section section-1'>
                                        <div className='add-ico'>
                                            <span className='add-ic add-ic1' onClick={() => { this.setState({ resourseOrigin: 'repo' }) }} >
                                                <i className="pi pi-plus"></i>
                                            </span>
                                        </div>
                                        <p className='section-text'>Add From Content Library</p>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <div className='section section-2'>
                                        <div className='add-ico'>
                                            <span className='add-ic add-ic2' onClick={() => { this.setState({ resourseOrigin: 'upload' }) }} >
                                                <i className="pi pi-upload"></i>
                                            </span>
                                        </div>
                                        <p className='section-text'>Assign From Curriculum</p>
                                    </div>
                                </div>
                            </div>
                        </> : <AddFromRepo
                            questionTarget={this.state.questionTarget || 'Normal'}
                            // selectedTopicResourceTypes={this.state.selectedTopicResourceTypes}
                            // contentInfo={this.state.qustions ? this.state.qustions : []}
                            resourseOrigin={this.state.resourseOrigin} selectedType={this.state.selectedType}

                            onSelectQuestion={this.onSelectQuestion}
                        />}

                </div>
            </Dialog >
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            {this.state.isLoading && <LoadingComponent />}

            <Dialog
                //header={<></>}
                draggable={false}
                blockScroll={true}
                closeOnEscape={true}
                className='ma-alert-box'
                dismissableMask={true}
                closable={false}
                visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                footer={() => {
                    return (<div>
                        <Button label="No" className='p-button-outlined' onClick={this.onNoClick} />
                        <Button label="Yes" className='ma-m-lr10' onClick={this.onYesClick} />

                    </div>)
                }}
                onHide={this.onNoClick}>
                <div>

                    <span className="warning-icon" >
                        <WarningIcon width={"100%"} height={"100%"} />
                    </span>
                </div>
                <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                <p className='ma-alert-msg'>{this.state.warningDialog.message}</p>
            </Dialog>

            {
                this.state.isShowEditContent && <EditContent
                    editContent={this.state.selectedContent}
                    onCancelEdit={() => { this.setState({ isShowEditContent: false, selectedContent: null }) }}
                    onUpdateContent={this.onUpdateContent}
                />
            }
            {
                this.state.isShowPreview && <ContentPlayer onCloseDialog={() => {
                    this.setState({
                        isShowPreview: false
                    })
                }} contentInfo={this.state.selectedContent} />
            }

            <Dialog
                header={<>Status</>}
                draggable={false}
                // closeOnEscape={true}
                className='ma-alert-box'
                blockScroll={true}
                //  dismissableMask={true}
                closable={false}
                visible={this.state.isShowAssignCountDialog}

                style={{ maxWidth: '22vw', minWidth: '300px' }}
                footer={() => {
                    return (<div>
                        <Button label="Ok" className='ma-m-lr10' onClick={this.onConfirmOk} />

                    </div>)
                }}
                onHide={this.onConfirmOk}>

                <p className='ma-alert-msg p-button-success'>
                    <b>  {this.state.assignedCount && this.state.assignedCount.added}</b>  questions assigned to selected hierarchy</p>
                <p className='ma-alert-msg p-button-success'><b>{this.state.assignedCount && this.state.assignedCount.notAdded}</b>
                    {' '} questions not assigned to selected hierarchy due to duplicate</p>
            </Dialog>

        </div>
    }
}



const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});

export default connect(mapStateToProps, {
    getBoardsData
})(Authentication(withRouter(ChooseQuestions)));