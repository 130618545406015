import React from 'react';
import moment from 'moment';
import { getProfileURL } from '../../../utile/index';
class DoubtItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //    doubt: this.props.doubt,
            unseen: this.props.doubt ? this.props.doubt.unseen : 0,
            isSeen: false
        };
    }


    onDoubtSelect = (doubt) => {
        if (this.props.currentTab == 1) {
            this.setState((prevState) => ({
                unseen: 0,
                isSeen: true
            }));
        }

        this.props.onDoubtSelect(doubt);
    }


    componentWillReceiveProps(newProps) {

        if (newProps.doubt && (newProps.doubt.unseen != this.state.unseen)) {
            this.setState({
                unseen: newProps.doubt.unseen
            });
        }
    }


    render() {
        const { doubt } = this.props;

        return (
            <div key={doubt._id} onClick={() => {
                this.onDoubtSelect(doubt)
            }} className={`doubt-item ${(this.props.selectedDoubt && this.props.selectedDoubt._id == doubt._id) ? 'hightlight-selected' : ''}`}>
                <div className="p-grid ma-no-m ">
                    <div style={{ width: '100%', overflow: 'hidden' }}>
                        <div style={{ width: '36px', float: 'left' }}>
                            <img src={getProfileURL(doubt?.askedBy?.photo)} className="prfl-img" />
                        </div>
                        <div style={{ marginLeft: '36px' }}>
                            <p className="prfl-name">{doubt.askedBy.name}, {doubt?.organizationName} <span className="time">{doubt.lastMessage ? moment(doubt.lastMessage.createdAt).format('lll') : ''}</span></p>
                            <p className="prfl-grade"  >
                                {doubt.boardName}, {doubt.className}, {doubt?.groupName}, {doubt.subjectName}
                            </p>
                            <p className="prfl-name1">Ticket No: {doubt?.ticketNumber}</p>
                            <p className="prfl-name1">Status: {doubt?.ticketStatus}</p>
                            <p className="prfl-name1">Exp.Deadline: {doubt?.expectedDeadline?moment(doubt?.expectedDeadline).format("DD-MM-YY"):""}</p>

                        </div>
                    </div>
                </div>
                <div className="p-grid ma-no-m ">
                    <div className="p-col-12">
                        <div className="p-grid ">
                            <div className="p-col-10 ma-no-pm">
                                <p className={`doubt-s ${doubt.unseen > 0 ? 'style-unseen' : ''}`}> {doubt?.lastMessage?.content || doubt?.doubt}</p>
                            </div >
                            <div className="p-col-2">
                                {doubt.isSecondTeacher && <img className="pending-doubt" src="./Pending_doubut.svg" />}
                                {this.state.unseen > 0 && <span className="unseen-count">{this.state.unseen}</span>}
                            </div >
                        </div >
                    </div >
                </div >
            </div >

        );
    }
}



export default DoubtItem;
