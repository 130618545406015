import * as ActionTypes from '../actionTypes';
import axios from 'axios';
import { baseUrlAdmin } from '../apiConstants';

export const getUserPermissionsRequested = () => ({ type: ActionTypes.GET_CURRENT_USER_PERMS_REQUESTED });
const getUserPermissionsReqSucceeded = (data, discussionUser, userInfo, permissionIds) => ({ type: ActionTypes.GET_CURRENT_USER_PERMS_SUCCEEDED, data, discussionUser, userInfo, permissionIds });
const getUserPermissionsRequestFailed = () => ({ type: ActionTypes.GET_CURRENT_USER_PERMS_FAILED });

const getAdminUserPermissionDetails = (id) => {
  return axios({
    method: 'get',
    url: `${baseUrlAdmin}/authorize/getAdminUserRolesPermissions/${id}`
  });
};

export const getAdminUserPermission = (id) => (dispatch) => {

  dispatch(getUserPermissionsRequested());
  getAdminUserPermissionDetails(id)
    .then((res) => {


      if (res.data) {

        localStorage.setItem('perms', JSON.stringify(permsToStorage(res.data.data)));

        localStorage.setItem('perms', JSON.stringify(permsToStorage(res.data.data)));

        if (res.data && res.data.discussBoardSession) {
          localStorage.setItem('discussion_token', res?.data?.discussBoardSession?.token);
          localStorage.setItem('discussion_uId', res?.data?.discussBoardSession?.data?.user._id);
        }
       
        if (res.data.userInfo && res.data.userInfo.email) {
          localStorage.setItem('emailId', res.data.userInfo.email)

        }
        if (res.data.userInfo && res.data.userInfo.phone_number) {
          localStorage.setItem('phone_number', res.data.userInfo.phone_number)

        }


        if (res.data.userInfo && res.data.userInfo.boardId) {
          localStorage.setItem('boardId', res.data.userInfo.boardId)

        }

        let permissionIds = [];
        res.data.data.forEach(r => {
          Object.keys(r.permissions).forEach(p => {
            permissionIds.push(p)
            r.permissions[p].permissions.forEach((ep) => {
              permissionIds.push(ep.id);
            })
          })
        });



        dispatch(getUserPermissionsReqSucceeded(res.data.data, res.data?.discussBoardSession?.data?.user, res.data.userInfo, permissionIds));
      } else {
        dispatch(getUserPermissionsRequestFailed());
      }
    })
    .catch((e) => {
      localStorage.setItem('perms', 'basic-role');
      dispatch(getUserPermissionsRequestFailed());
    });
};

const setDefaultRoleForExistingRoles = () => JSON.stringify({ perm: 'basic-role' });

const permsToStorage = (data) =>
  data.map((item) => {
    return Object.keys(item.permissions).map((grpId) => {
      return item['permissions'][grpId];
    });
  });
