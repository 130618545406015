import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import Authentication from './../../session';
import Navbar from './../../navbar';
import { ResponsiveBar } from '@nivo/bar';
import Service from '../../../services';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../../store/apiConstants';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { getDatesBetweenDates, getDateString, getServerTime } from '../../../utile';
import './styles.scss'
import LoadingComponent from '../../loadingComponent';


const items = [
    { label: 'Analytics' },
    { label: 'Teacher Analytics', className: 'ma-active-item' },
    // { label: 'Role Creation', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }



 class ExamAnalytics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTab: 1,
            editExamType: null,
            editExamId: null,
            editedExamType: null,
            teacherId: null,
            teachers: [],
            dateRange: [],
            periodWiseAnalytics: [],
            totalPeriods: []


        }

        this.service = new Service();
    }


    componentDidMount() {

        this.getTeachers();
    }

    getTeachers = () => {


        this.setState({
            isLoading: true
        })
        const url = `${baseUrlAdmin}/admin/getteachers`;
        this.service.get(url, true).then((data) => {

            if (data && data.res && data.res) {
                this.setState({
                    teachers: data.res,
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false
                })
            }
        }).catch(e => {

            this.setState({
                isLoading: false
            })
        });
    }

    getTeacherAnalytics = (dateRanges) => {

        let {
            teacherId,
            dateRange
        } = this.state;

        this.setState({
            isLoading: true,
        });

        let thisObj = this;

        let url = `${baseUrlForLongRunningTask}/analytics/getteacheranalytics`;
        this.service.post(url, { teacherId, dateRange }, true).then(res => {
            
            if (res && res.res) {

                thisObj.setState({
                    isLoading: false,
                    periodWiseAnalytics: res.res.periodWiseAnalytics,
                    isNotFound: true,
                    totalPeriods: res.res.totalPeriods
                })


            } else {
                thisObj.setState({
                    isLoading: false
                });
                thisObj.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
            // console.log(res);


        }).catch(e => {
            console.log(e);
            thisObj.setState({
                isLoading: false
            });

            thisObj.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    }




    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>Teacher Analytics</h2>
            </React.Fragment>
        )
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }

    onChangeTeacher = (teacherId) => {
        this.setState({
            teacherId
        })

    }
    getReport = () => {

        if (!this.state.teacherId) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select Teacher', life: 3000 });
        } else {
            this.getTeacherAnalytics()
        }
    }

    handleSelect = (dateRange) => {

        this.setState({

            dateRange,

        });
    }


    render() {
        const { teacherId, dateRange } = this.state;

        console.log(this.state, 'state')
        return (<>
            <Navbar >
                <div className="p-grid ma-toolbar">
                    <div className="p-col-12 p-md-1 ma-no-p">
                    </div>
                    <div className="p-col-12 p-md-11 ma-no-pm">
                        <Toolbar className="ma-toolbard" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>
                </div>
                <div className="ask-a-doubt ma-main question-bank">
                    <div>
                        <Dropdown value={teacherId}
                            className='ma-w200 '
                            options={this.state.teachers || []}
                            optionLabel="userName"
                            optionValue="user_id"
                            onChange={(e) => this.onChangeTeacher(e.value)}
                            placeholder="Select Teacher" />


                        <Calendar showIcon={true}
                            // style={{ width: '100%' }}
                            placeholder="Select Dates"
                            selectionMode="range"
                            className='ma-ml10'
                            // readOnlyInput
                            value={dateRange}
                            maxDate={getServerTime()}
                            onChange={(e) => this.handleSelect(e.value)}
                        ></Calendar>


                    </div>


                    <div className='ma-mt10'>
                        <Button label='Get Report' className='ma-mt20' onClick={() => {
                            this.getReport()
                        }} />
                        <Button label="Clear" className='ma-m-lr10 p-button-outlined white-bg' onClick={() => {
                            this.setState({
                                dateRange: [],
                                teacherId: null
                            })
                        }} />
                    </div>
                    {(this.state.periodWiseAnalytics && this.state.periodWiseAnalytics.length) ?
                        <div className='periods-graph-w'>

                            <div className="periods-graph">
                                <ResponsiveBar
                                    data={this.state.periodWiseAnalytics}
                                    keys={['Time Spent']}
                                    indexBy="name"
                                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                    padding={0.85}
                                    innerPadding={4}
                                    groupMode="grouped"
                                    valueScale={{ type: 'linear' }}
                                    indexScale={{ type: 'band', round: true }}
                                    colors={['var(--primary-color)', 'var(--analyitcs-color3)', 'var(--analyitcs-color4)']}
                                    borderRadius={3}
                                    borderColor={{ from: 'color', modifiers: [['brighter', 1.6]] }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        //   legend: 'subjectName',
                                        legendPosition: 'left',
                                        legendOffset: 32
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: 'Time in Hours(H:M)',
                                        // legend: 'food',
                                        legendPosition: 'middle',
                                        legendOffset: -40
                                    }}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                    legends={[
                                        {
                                            dataFrom: 'keys',
                                            anchor: 'bottom',
                                            direction: 'row',
                                            justify: false,
                                            translateX: 0,
                                            translateY: 50,
                                            itemsSpacing: 2,
                                            itemWidth: 100,
                                            itemHeight: 20,
                                            itemDirection: 'left-to-right',
                                            itemOpacity: 0.85,
                                            symbolSize: 18,
                                            symbolShape: 'circle',
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemOpacity: 1
                                                    }
                                                }
                                            ]
                                        }
                                    ]}

                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                />
                            </div>
                        </div>
                        : <>
                            {
                                this.state.isNotFound && <div className='periods-graph-w'>
                                    <div className="periods-graph">
                                        <p className="no-data">No analytics to display</p>
                                    </div>
                                </div>

                            }


                        </>}

                </div>

            </Navbar>
            {this.state.isLoading && <LoadingComponent />}
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </>
        )
    }
}



export default Authentication(ExamAnalytics);