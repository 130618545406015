import React from 'react';
import DeleteMessage from './deleteMessage';
import moment from "moment";
import { MESSAGE_STATUS } from './../../../utile';
const TEACHER_ID = localStorage.getItem('discussion_uId');

class ListView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { conversation } = this.props;
        // console.log('conversation-listview', conversation)
        return (
            <div className={`ask-wr message-buttons-c ${conversation.direction == 2 ? 'ma-right' : 'ma-left1'}`}>

                {conversation.content.map((action) => {
                    return <button className={`chat-button ${(conversation.selectedButtonKey && conversation.selectedButtonKey == action.value) ? '' : ''}`} key={action.key}> {action.value}</button>
                })}
                {(conversation.direction == 2 && conversation.from._id == this.props.discussionUser._id) && <DeleteMessage conversation={conversation} deleteMessage={this.props.deleteMessage} />}
                <div className="ma-clearFix"> </div>
                <span className={`message-time  ${conversation.direction == 2 ? '' : 'ma-right'}`}>{moment(conversation.createdAt).format('lll')} {conversation.direction == 2 && (conversation.status == MESSAGE_STATUS.sent ? <i className="pi pi-check sent-icon" /> : conversation.status == MESSAGE_STATUS.sending ? <i className="pi pi-spinner sent-icon" /> : ' ')}</span>
            </div>
        );
    }
}


export default ListView;
