import * as ActionType from "../actionTypes";

export default (state = {}, action) => {
  switch(action.type){
    case ActionType.REQUEST_EDIT_TEMPLATE_DETAILS :

        return{
            ...state,
            loading:true,
            error:false,
            errorInfo:null,
            response: null
            }
    case ActionType.RECEIVED_EDIT_TEMPLATE_DETAILS :
        return{
            ...state,
            loading:false,
            response:action.res,
            error:false,
            errorInfo:null
        }
    case ActionType.ERROR_EDIT_TEMPLATE_DETAILS:
        return{
            ...state,
            loading:false,
            error:true,
            errorInfo:action.err,
            response: null
        }
    case ActionType.GET_EDIT_TEMPLATE_DETAILS:

        return{
            ...state,
             isTemplate:true,
             editTemplateInfo: action.editTemplateInfo
        }   
    case ActionType.GET_TEMPLATE_DETAILS_FOR_VIEW:
        return {
            ...state,
            templateDetailsForView:action.templateView
        }       
    case ActionType.CLEAR_EDIT_TEMPLATE_DETAILS:
        return {
        }
        case ActionType.CLEAR_GET_TEMPLATE_DETAILS_FOR_VIEW:
        return {
        }    
    case ActionType.CLEAR_GET_EDIT_TEMPLATE_DETAILS:
         return{
             ...state,
             isTemplate:false,
             editTemplateInfo:{},
            
         }    
    default : 
      return state    

  }
}