import React, { Component } from 'react'
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';

import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { BreadCrumb } from 'primereact/breadcrumb';
import Authentication from '../../../session';
import Authorizer, { PERMISSIONS } from '../../../session/authorizer';
import TemplateList from './templateList';
import CreateTemplate from './createTemplate';
import Service from '../../../../services';
import { baseUrlAdmin } from '../../../../store/apiConstants';
import LoadingComponent from '../../../loadingComponent';
import _ from 'lodash';

const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: '',
    sortField: null,
    sortOrder: null,
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'title': { value: '', matchMode: 'contains' },
        'subDomain': { value: '', matchMode: 'contains' },
        // 'tenureEndDate': { value: '', matchMode: 'contains' },
        // 'isActive': { value: '', matchMode: 'contains' },
    }
}

const home = { icon: 'pi pi-home' }


class AdhocTemplate2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowCreateOrEdit: false,
            editTemplate: null,
            templates: [],
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            isLoading: false
        }

        this.service = new Service();
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.gettemplates);
    }

    onStatusChange = (e, rowData) => {
        let templates = this.state.templates.map((i) => {
            if (i._id == rowData._id) {
                i.isActive = e.value;
            }
            return i;
        });
        this.setState({
            templates
        })
    }

    gettemplates = () => {
        this.setState({
            isLoading: true,
            templates: [],
            totalRecords: 0
        });
        let { lazyParams, globalSearch } = this.state;

        let url = `${baseUrlAdmin}/examtemplate/getTemplates?examType=AdhocMockTestT2&limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.service.post(url, lazyParams.filters, true).then(res => {

            if (res && res.status && res.res.success) {
                this.setState((prevState) => {
                    return {
                        templates: res.res.data.examTemplates,
                        totalRecords: res.res.data.totalRecords,
                        isLoading: false
                    };
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }


    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.gettemplates);
    }



    onSort = (event) => {


        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.gettemplates);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.gettemplates);
        //  this.setState({ lazyParams: event }, this.gettemplates);
    }



    componentDidMount() {

        this.gettemplates();
    }

    componentDidUpdate() {




    }

    showCreateOrEdit = () => {

        this.setState({
            isShowCreateOrEdit: true,
            editTemplate: null,
            duplicateTemplate: null,
        });


    }

    onCreateCancel = () => {

        this.setState({
            isShowCreateOrEdit: false,
            duplicateTemplate: null,
        });
    }

    onEditTemplateClick = (editTemplate) => {
        this.setState({
            editTemplate,
            isShowCreateOrEdit: true,
            duplicateTemplate: null,
        });
    }


    duplicateTemplate = (duplicateTemplate) => {
        let obj = _.cloneDeep(duplicateTemplate);
        delete obj.name;
        delete obj.description;
        delete obj._id;



        this.setState({
            duplicateTemplate: obj,
            isShowCreateOrEdit: true,
        });
    }


    onCreateOrEditSuccess = (isEdit) => {

        this.setState({
            duplicateTemplate: null,
            editTemplate: null,
            isShowCreateOrEdit: false,
            globalSearch: '',
            lazyParams: BasicLazyParams,
        }, this.gettemplates)

        this.toast.show({ severity: 'success', summary: 'Created', detail: `${isEdit ? 'Edited' : 'Created'} template successfully`, life: 3000 });
    }

    render() {
        return (<>

            <div >
                {
                    this.state.isShowCreateOrEdit ? <>
                        <CreateTemplate
                            onCreateOrEditSuccess={this.onCreateOrEditSuccess}
                            onCreateCancel={this.onCreateCancel}
                            editTemplate={this.state.editTemplate}
                            duplicateTemplate={this.state.duplicateTemplate}
                        />
                    </> : <>
                        <Authorizer permId={PERMISSIONS.ADHOCT2_TEMPLATE_CREATE} >
                            <Button label="+ Add Template" className=''
                                onClick={this.showCreateOrEdit} />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.ADHOCT2_TEMPLATE_LIST} >
                            <TemplateList
                                getNextRecords={this.getNextRecords}
                                lazyParams={this.state.lazyParams}
                                onPage={this.onPage}
                                onSort={this.onSort}
                                onFilter={this.onFilter}
                                onStatusChange={this.onStatusChange}
                                templates={this.state.templates}
                                totalRecords={this.state.totalRecords}
                                isLoading={this.state.isLoading}
                                onGlobalSearch={this.onGlobalSearch}
                                globalSearch={this.state.globalSearch}
                                onEditTemplateClick={this.onEditTemplateClick}
                                duplicateTemplate={this.duplicateTemplate}
                            />
                        </Authorizer>
                    </>
                }

            </div>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            {
                this.state.isLoading && <>
                    <LoadingComponent />
                </>
            }
        </>
        )
    }
}



const mapStateToProps = (state) => ({

});



export default connect(mapStateToProps, {})(Authentication(withRouter(AdhocTemplate2)));

//Authentication