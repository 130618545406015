import React, { Component } from 'react';
import Service from './../service';
import AttachmentPlayer from './attachmentPlayer';
import { Dialog } from 'primereact/dialog';

class Attachments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attachments: this.props.attachments,
            showAttachment: false,
            selectedAttachment: null,
            showImagePop: false,
            attachmentURI: null
        }
        this.service = new Service();
    }



    showAttachment = (a) => {
        if (a.uri) {
            this.setState({
                showAttachment: true,
                selectedAttachment: a
            });
        }
    }


    getControl = (attachment) => {

        switch (attachment.type) {
            case 'image':
                return <div className="view-image"> <img src={attachment.uri} onClick={() => { this.setState({ showImagePop: true, attachmentURI: attachment.uri }) }} /></div>;
            case 'video':
                return <div className="view-video"> <video autoPlay={false} controls
                    //  onPause={() => { this.stopTimer() }}
                    //onPlaying={() => { this.logAnalytics('playing') }}
                    controlsList="nodownload"
                >
                    <source src={attachment.uri} />
                    Your browser does not support the video tag.
                </video> </div>;
            case 'audio':
                return <div className="view-video"> <audio autoPlay={false} controls
                    //  onPause={() => { this.stopTimer() }}
                    //onPlaying={() => { this.logAnalytics('playing') }}
                    controlsList="nodownload"
                >
                    <source src={attachment.uri} />
                    Your browser does not support the video tag.
                </audio> </div>;
            case 'pdf':
                return this.getView(attachment)
            default:
                return <></>;
        }
    }

    getView = (attachment) => {
        return <div className="ma-attachment">
            <div style={{ width: '100%', overflow: 'hidden' }} onClick={() => { this.showAttachment(attachment) }}>
                <div style={{ width: '20px', float: 'left' }}>
                    <img src="./images/PDF_Doc_icon.svg" className="ma-attach-icon" />
                </div>
                <div style={{ marginLeft: '20px', cursor:'pointer' }}>
                    <p>Click here to see attachment </p>
                </div>
            </div>
        </div>
    }

    render() {
        const { attachments } = this.props;
        return (
            <>
                {
                    attachments && attachments.length ? attachments.map((attachment, index) => {
                        return <div key={index} id={attachment._id}> {this.getControl(attachment)}
                        </div>
                    }) : <></>
                }
                {this.state.showAttachment && <AttachmentPlayer onCloseDialog={() => { this.setState({ showAttachment: false }) }} attachment={{ ...this.state.selectedAttachment, name: 'Attachment' }} />}
                {
                    this.state.showImagePop && <Dialog
                        visible={this.state.showImagePop}
                        blockScroll={true}
                        closable={true}
                        onHide={() => { this.setState({ showImagePop: false }) }}
                    >
                        <img src={this.state.attachmentURI} className="popup-img" />
                    </Dialog>
                }
            </>
        )
    }
}

export default Attachments;